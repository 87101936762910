import React from 'react';
import { Controller } from 'react-hook-form';
import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  TextField,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

export function PlaceForm({ errors, place, control, department }) {
  return (
    <div className="flex flex-row flex-wrap">
      <div className="w-full px-1 py-2">
        <Controller
          name={'name'}
          control={control}
          defaultValue={place ? place.name : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="ชื่อสถานที่"
              fullWidth
              size={'small'}
              required
              helperText={errors.name && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'address'}
          control={control}
          defaultValue={place ? place.address : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="ที่อยู่"
              fullWidth
              size={'small'}
              multiline={true}
              helperText={errors.address && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'allow_all_department'}
          control={control}
          defaultValue={place ? place.allow_all_department : true}
          render={({ field }) => (
            <FormControlLabel
              label="อนุญาตทุกแผนก"
              control={<Checkbox {...field} defaultChecked={field.value} />}
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-2">
        {!_.isEmpty(department?.rows) && (
          <Controller
            name="allow_departments"
            control={control}
            defaultValue={place?.allow_departments}
            render={({ field }) => (
              <Autocomplete
                {...field}
                multiple
                disablePortal
                options={department?.rows}
                size="small"
                placeholder="แผนก"
                onChange={(e, newValue) => {
                  field.onChange(newValue);
                }}
                className="z-40"
                // prettier-ignore
                getOptionLabel={(option) => `${option?.name || ''}`
          }
                renderInput={(params) => (
                  <TextField label="แผนกที่อนุญาต" {...params} />
                )}
              />
            )}
          />
        )}
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'wip_place'}
          control={control}
          defaultValue={place?.wip_place}
          render={({ field }) => (
            <FormControlLabel
              label="คลังสินค้าระหว่างการผลิต (คลัง WIP)"
              control={<Checkbox {...field} defaultChecked={field.value} />}
            />
          )}
        />
      </div>
    </div>
  );
}

PlaceForm.propTypes = {
  errors: PropTypes.shape({
    name: PropTypes.object,
    address: PropTypes.object,
  }),
  place: PropTypes.object,
  control: PropTypes.object,
  department: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
};

PlaceForm.defaultProps = {
  place: null,
};

export default PlaceForm;
