import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { Button, Link as MUILink, TextField } from '@mui/material';
import PropTypes from 'prop-types';

import LogoList from '../../assets/images/logo-list.png';
import { PassportAuth } from '../../contexts/AuthContext';
import version from '../../version.json';

export default function Login({ information }) {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const { handleSignin } = useContext(PassportAuth);
  const history = useHistory();
  const onSubmit = async (data, e) => {
    e.preventDefault();
    try {
      await handleSignin(data.username, data.password);
      alert('สำเร็จ');
      history.push('/');
    } catch (error) {
      window.alert(`ไม่สามารถเข้าสู่ระบบ ${error?.message}`);
    }
  };

  return (
    <div className="container mx-auto px-4 h-full">
      <div className="flex content-center items-center justify-center h-full">
        <div className="w-full lg:w-4/12 px-4">
          <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white border-0">
            <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
              <div className="flex justify-center py-4">
                <img src={information?.logo?.url} className="h-24" />
              </div>
              <div className="rounded-t mb-0 px-6 py-2">
                <div className="text-center mb-3">
                  <h6 className="text-gray-600 text-sm font-bold font-display">
                    เข้าสู่ระบบ
                  </h6>
                </div>
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="my-2">
                  <TextField
                    name="username"
                    label="ชื่อผู้ใช้"
                    variant="outlined"
                    fullWidth
                    {...register('username', { required: true })}
                    helperText={errors.username && 'กรุณาใส่ข้อมูล'}
                  />
                </div>

                <div className="my-2">
                  <TextField
                    label="รหัสผ่าน"
                    variant="outlined"
                    type="password"
                    fullWidth
                    {...register('password', { required: true })}
                    helperText={errors.password && 'กรุณาใส่ข้อมูล'}
                  />
                </div>
                <div className="text-center mt-6">
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    fullWidth
                    type="submit"
                  >
                    เข้าสู่ระบบ
                  </Button>
                </div>
              </form>
              <div className="my-4">
                <div className="text-center">
                  ไม่มีบัญชีผู้ใช้ใช่หรือไม่ ?{' '}
                  <Link to="/auth/register">
                    <MUILink>ขอสมัครสมาชิก</MUILink>
                  </Link>
                </div>
              </div>
              <div className="my-4">
                <div className="text-center">
                  สนับสนุนโดย {information?.sponsor?.name || ''}
                </div>
                <div className="text-sm my-4 text-center">
                  พัฒนาระบบโดย ศูนย์วิจัยระบบอัตโนมัติอัจฉริยะ คณะวิศวกรรมศาสตร์
                  มหาวิทยาลัยสงขลานครินทร์
                </div>

                <div className="flex justify-center">
                  <img src={LogoList} className="h-16" />
                </div>
                <div className="my-4 text-sm text-center">
                  &copy; 2023 E-Manufac {version.version}
                </div>
                <div className="my-4 text-sm text-center">
                  <a
                    href="https://emanufac.efactory.biz"
                    className="hover:text-blue-600 hover:underline"
                  >
                    https://emanufac.efactory.biz
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

Login.propTypes = {
  information: PropTypes.object,
};
