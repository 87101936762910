import React, { Fragment, useRef, useState } from 'react';
import {
  ChevronDown,
  Edit as EditIcon,
  Printer,
  Repeat as RepeatIcon,
  Trash,
} from 'react-feather';
import { Link, useHistory } from 'react-router-dom';
import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { WORK_SHIFT } from '../../../utils/constants';
import {
  converseMaterialOrProductUnit,
  currencyFormatter,
} from '../../../utils/functions';
import MultiUnitProdOrMatBox from '../Rendering/MultiUnitProdOrMatBox';

/**
 * @function ProcessInfoBox
 * @memberof MMS
 * @description แสดงรายละเอียดโดยทั่วไปของ Process หรือไลน์การผลิตไลน์หนึ่ง ๆ
 */
const ProcessInfoBox = ({
  me,
  information,
  selectedProcess,
  handlePrint,
  handleCancle,
}) => {
  const mmsSetting = information?.setting?.mms;
  const anchorRef = useRef(null);
  const [buttonOpen, setIsButtonOpen] = useState(false);
  const history = useHistory();

  const renderDetail = () => (
    <div className="px-4 my-4 ">
      <div className="w-full mb-2 ">
        <div className="font-semibold  font-display">รายละเอียด</div>
        <p className="font-system">{selectedProcess?.remark}</p>
      </div>
      <div className="grid md:grid-cols-4 lg:grid-cols-12 gap-2 ">
        {selectedProcess?.customer && (
          <div className="col-span-6 row-span-2 ">
            <div className="font-semibold font-display">ลูกค้า</div>{' '}
            <ul className="list-inside list-disc my-2 ml-2">
              <li>
                {selectedProcess?.customer?.type_code || ''} -{' '}
                {selectedProcess?.customer?.name}
              </li>
              {_.map(
                selectedProcess?.other_customers,
                (eachOtherCustomer, index) => (
                  <li key={index}>
                    {eachOtherCustomer?.type_code} {eachOtherCustomer?.name}
                  </li>
                ),
              )}
            </ul>
          </div>
        )}
        {selectedProcess?.machine && (
          <div className="col-span-3">
            <div className="font-semibold font-display">เครื่องจักร</div>{' '}
            <div>{selectedProcess?.machine?.name || ''}</div>
          </div>
        )}
        {selectedProcess?.work_shift && (
          <div className="col-span-3">
            <div className="font-semibold font-display">ช่วงของการทำงาน</div>{' '}
            {WORK_SHIFT[selectedProcess?.work_shift]?.description}
          </div>
        )}
        {mmsSetting?.manufacturingOrder?.showPrice && (
          <div className="col-span-3">
            <div className="font-semibold  font-display">ราคาต่อชิ้น</div>
            <p>
              {currencyFormatter.format(selectedProcess?.price_per_unit || 0)}{' '}
              บาท
            </p>
          </div>
        )}
        {mmsSetting?.manufacturingOrder?.showPrice && (
          <div className="col-span-3">
            <div className="font-semibold  font-display">ราคารวม</div>
            <p>
              {currencyFormatter.format(
                (selectedProcess?.price_per_unit || 0) *
                  selectedProcess.quantity,
              )}
              บาท
            </p>
          </div>
        )}
      </div>
    </div>
  );

  const renderProdAsMaterialQuantityDisplay = () => {
    if (selectedProcess?.quantity > 0) {
      if (selectedProcess?.product_as_material?.use_unit_conversion) {
        return (
          <span>
            จำนวน {currencyFormatter.format(selectedProcess?.quantity)}{' '}
            {selectedProcess?.product_as_material?.unit_input?.short_sign} (
            {currencyFormatter.format(
              converseMaterialOrProductUnit(
                selectedProcess?.product_as_material,
                selectedProcess?.quantity,
              ),
            )}{' '}
            {selectedProcess?.product_as_material?.unit_output?.short_sign})
          </span>
        );
      }
      return (
        <span>
          จำนวน {currencyFormatter.format(selectedProcess?.quantity)}{' '}
          {selectedProcess?.additonal_unit ||
            selectedProcess?.product_as_material?.unit}{' '}
        </span>
      );
    }
    return <span></span>;
  };

  const renderProdQuantityDisplay = () => {
    if (selectedProcess?.quantity > 0) {
      if (selectedProcess?.product?.use_unit_conversion) {
        return (
          <span>
            จำนวน {currencyFormatter.format(selectedProcess?.quantity)}{' '}
            {selectedProcess?.product?.unit_input?.short_sign} (
            {currencyFormatter.format(
              converseMaterialOrProductUnit(
                selectedProcess?.product,
                selectedProcess?.quantity,
              ),
            )}{' '}
            {selectedProcess?.product?.unit_output?.short_sign})
          </span>
        );
      }
      return (
        <span>
          จำนวน {currencyFormatter.format(selectedProcess?.quantity)}{' '}
          {selectedProcess?.additional_unit || selectedProcess?.product?.unit}{' '}
        </span>
      );
    }
    return <span></span>;
  };

  return (
    <div>
      <div className="flex justify-between flex-wrap my-2 px-4 ">
        {!selectedProcess?.produce_base_project_instead &&
          (selectedProcess?.produce_material_instead ? (
            <h4 className="text-lg mx-2 flex gap-2 my-2">
              <span className="bg-gray-200 p-1 px-2 rounded-md font-bold">
                {selectedProcess?.product_as_material?.type_code}{' '}
                {selectedProcess?.product_as_material?.name}
              </span>{' '}
              {renderProdAsMaterialQuantityDisplay()}
            </h4>
          ) : (
            <h4 className="text-lg mx-2 flex gap-2">
              <span className="bg-gray-200 p-1 px-2 rounded-md font-bold">
                {selectedProcess?.product?.type_code}{' '}
                {selectedProcess?.product?.name}
              </span>{' '}
              {renderProdQuantityDisplay()}
            </h4>
          ))}
        {selectedProcess?.produce_base_project_instead && (
          <h4 className="text-lg mx-2 flex gap-2">
            <span className="bg-gray-200 p-1 px-2 rounded-md font-bold">
              {selectedProcess?.base_project?.type_code}{' '}
              {selectedProcess?.base_project?.name}
            </span>{' '}
            <MultiUnitProdOrMatBox
              foundMaterial={selectedProcess?.base_project}
              noWrap
              quantity={selectedProcess?.quantity}
            />
          </h4>
        )}
        <div>
          <div className="flex gap-2 my-4 lg:my-2 flex-wrap">
            <Fragment>
              <ButtonGroup
                variant="contained"
                ref={anchorRef}
                aria-label="split button"
                color="warning"
                size="small"
              >
                <Button
                  onClick={() => {
                    history.push(`/mms/process/edit/${selectedProcess?._id}`);
                  }}
                  startIcon={<EditIcon size={16} />}
                  hidden={
                    me?.userData?.role?.level < 1 ||
                    selectedProcess?.completed ||
                    selectedProcess?.deleted
                  }
                >
                  แก้ไข
                </Button>

                <Button
                  size="small"
                  aria-controls={buttonOpen ? 'split-button-menu' : undefined}
                  aria-expanded={buttonOpen ? 'true' : undefined}
                  aria-label="select merge strategy"
                  aria-haspopup="menu"
                  onClick={() => setIsButtonOpen((prevOpen) => !prevOpen)}
                >
                  <ChevronDown size={16} />
                </Button>
              </ButtonGroup>
              <Popper
                sx={{
                  zIndex: 1,
                }}
                open={buttonOpen}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === 'bottom' ? 'center top' : 'center bottom',
                    }}
                  >
                    <Paper>
                      <ClickAwayListener
                        onClickAway={() => setIsButtonOpen(false)}
                      >
                        <MenuList id="split-button-menu" autoFocusItem>
                          <MenuItem
                            key="1"
                            onClick={() => {
                              history.push(
                                `/mms/material-usage/edit/${selectedProcess?._id}`,
                              );
                            }}
                          >
                            แก้การใช้วัตถุดิบ
                          </MenuItem>
                          <MenuItem
                            key="1"
                            onClick={() => {
                              history.push(
                                `/mms/process/measurement/pre-edit/${selectedProcess?._id}`,
                              );
                            }}
                          >
                            แก้ค่าวัดผล
                          </MenuItem>

                          <MenuItem
                            key="1"
                            onClick={() => {
                              history.push(
                                `/mms/process/select-current-step/${selectedProcess?._id}`,
                              );
                            }}
                          >
                            ปรับขั้นตอนงานปัจจุบัน
                          </MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </Fragment>
            <Link
              to={`/mms/process/assign/${selectedProcess?._id}`}
              hidden={
                me?.userData?.role?.level < 1 ||
                selectedProcess?.completed ||
                selectedProcess?.deleted
              }
            >
              <Button
                size="small"
                variant="contained"
                color="secondary"
                disabled={
                  me?.userData?.role?.level < 1 ||
                  selectedProcess?.completed ||
                  selectedProcess?.deleted
                }
                startIcon={<RepeatIcon size={16} />}
              >
                แจกจ่ายงาน
              </Button>
            </Link>

            <Button
              size="small"
              variant="contained"
              color="teal"
              startIcon={<Printer size={16} />}
              onClick={() => handlePrint(selectedProcess)}
            >
              พิมพ์
            </Button>
            <Button
              size="small"
              variant="contained"
              disabled={
                me?.userData?.role?.level < 1 ||
                selectedProcess?.deleted ||
                selectedProcess?.completed
              }
              startIcon={<Trash size={16} />}
              color="error"
              onClick={() => handleCancle()}
            >
              ยกเลิก
            </Button>
          </div>
        </div>
      </div>
      {renderDetail()}
    </div>
  );
};

ProcessInfoBox.propTypes = {
  me: PropTypes.object,
  information: PropTypes.object,
  selectedProcess: PropTypes.object,
  handlePrint: PropTypes.func,
  handleCancle: PropTypes.func,
};

export default ProcessInfoBox;
