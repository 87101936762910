import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { gql } from 'graphql-request';
import _ from 'lodash';
import { Card, CardContent } from '@mui/material';
import dayjs from 'dayjs';

import {
  ViewTitle,
  Loading,
  Error,
  BackButton,
  TotalMaterialBomTable,
  DesktopDatepicker,
  BomCostBox,
} from '../../../components';
import * as actions from '../../../redux/actions';
import graphqlClient from '../../../configs/graphqlClient';
import { app as appConfig } from '../../../configs';
import { currencyFormatter } from '../../../utils/functions';

/**
 * @function ReportAllBom
 * @description Display a collections or a list of ReportAllBom from database
 */

export default function ReportAllBom({ title, subtitle }) {
  const dispatch = useDispatch();
  const bomChecking = useSelector((state) => state.bomChecking);
  const [selectedStartDate, setSelectedStartDate] = useState(
    dayjs().startOf('month').toDate(),
  );
  const [selectedEndDate, setSelectedEndDate] = useState(dayjs().toDate());

  const query = gql`
    query FindReportAllBom($bomCheckinginput: BomCheckingInput) {
      findBomCheckings(input: $bomCheckinginput) {
        analyzed {
          material {
            _id
            name
            type_code
            use_unit_conversion
            unit
            conversion_from {
              unit_a
              unit_b
              conversion_factor
            }
            conversion_to {
              unit_b
              unit_a
              conversion_factor
            }
            unit_input {
              _id
              short_sign
              name
            }
            unit_output {
              _id
              short_sign
              name
            }
          }
          totalPrefer
          totalActual
          totalDeposit
          difference
          overDepositToActual
          lessDepositToActual
          formularCost
          depositCost
          actualCost
        }
      }
    }
  `;

  const queryDataFromServer = async () => {
    try {
      const queryResult = await graphqlClient.request(query, {
        bomCheckingInput: {
          page: 1,
          size: appConfig.maxFetchSize,
          deleted: false,
          startDate: dayjs(selectedStartDate).format('YYYY-MM-DD'),
          endDate: dayjs(selectedEndDate).format('YYYY-MM-DD'),
        },
      });
      const bomCheckingData = queryResult?.findBomCheckings;
      dispatch(actions.bomCheckingStateSet(bomCheckingData));
    } catch (error) {
      console.error('Error Fetch QUery', error);
      dispatch(actions.bomCheckingError());
    }
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [selectedEndDate, selectedStartDate]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const renderSearch = () => (
    <div className="my-2">
      <Card>
        <CardContent>
          <div className="flex flex-wrap">
            <div className="w-full lg:w-3/5 py-1"></div>
            <div className="w-full lg:w-1/5 px-1 py-1">
              <DesktopDatepicker
                setValue={setSelectedStartDate}
                value={selectedStartDate}
                label="เริ่มต้น"
                size="small"
              />
            </div>
            <div className="w-full lg:w-1/5 px-1 py-1">
              <DesktopDatepicker
                setValue={setSelectedEndDate}
                value={selectedEndDate}
                label="สิ้นสุด"
                size="small"
              />
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );

  if (bomChecking.isLoading) {
    return <Loading />;
  }
  if (!bomChecking.isLoading && bomChecking.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div className="my-2">
          <BackButton />
        </div>
        {renderSearch()}
        <div className="my-2">
          <BomCostBox allAnalyzed={bomChecking?.analyzed} />
        </div>
        <TotalMaterialBomTable bomChecking={bomChecking} />
      </div>
    );
  }
  return <Error />;
}

ReportAllBom.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

ReportAllBom.defaultProps = {
  title: '',
  subtitle: '',
};
