import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import {
  Alert,
  Autocomplete,
  Button,
  Card,
  Checkbox,
  FormControlLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import dayjs from 'dayjs';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { MATERIAL_STOCK_STATUS } from '@/utils/constants';
import {
  converseMaterialOrProductUnit,
  currencyFormatter,
} from '@/utils/functions';

import { MultiUnitProdOrMatBox } from '../../Box';
import { DesktopDatepicker } from '../../DatePicker';
import ProductAmountInput from '../../Inputs/ProdOrMatQuantityInput';

export function AddMaterialForm({
  material,
  selectMaterial,
  setSelectMaterial,
  customer,
}) {
  const information = useSelector((state) => state.information);
  const {
    setting: { ims: imsSetting },
  } = information;
  const resetValues = {
    quantity: 0,
    price: 0,
    material: material[0],
    production_date: new Date(),
    expiration_date: new Date(),
    lot_number: '',
    input_supplier: false,
    material_supplier: null,
  };
  const [reload, setReload] = useState(false);

  const {
    control,
    formState: { errors },
    reset,
    getValues,
    handleSubmit,
    watch,
    setValue,
  } = useForm();
  useEffect(() => () => {}, [reload]);

  const handleAddMaterial = () => {
    if (
      (getValues('material') || getValues('price') || getValues('quantity')) ===
      ''
    ) {
      alert('กรุณาใส่ข้อมูลให้ครบถ้วน');
    } else {
      const data = {
        material: getValues('material')._id,
        price: getValues('price'),
        quantity: getValues('quantity'),
        amount: getValues('quantity'),
        lot_number: getValues('lot_number'),
        production_date: getValues('production_date'),
        expiration_date: getValues('expiration_date'),
        supplier: getValues('material_supplier'),
        status: MATERIAL_STOCK_STATUS.IN_STOCK.status_code,
        displaySupplier: getValues('input_supplier'),
      };
      selectMaterial.push(data);
      setSelectMaterial(selectMaterial);
      reset(resetValues);
      setValue('input_supplier', true);
      setReload(!reload);
    }
  };

  const handleRemoveMaterial = (index) => {
    selectMaterial.splice(index, 1);
    setSelectMaterial(selectMaterial);
    setReload(!reload);
  };

  const materialOptions = () => (
    <Card>
      <form onSubmit={handleSubmit(handleAddMaterial)}>
        <div className="w-full flex flex-row flex-wrap p-4">
          <div className="w-full px-1 py-2">
            <Controller
              name={'material'}
              control={control}
              rules={{ required: true }}
              defaultValue={material[0]}
              render={({ field }) => (
                <Autocomplete
                  size={'small'}
                  disableClearable
                  {...field}
                  onChange={(e, newValue) => {
                    field.onChange(newValue);
                    setReload(!reload);
                  }}
                  options={material}
                  getOptionLabel={(option) =>
                    `${option?.type_code ? `${option?.type_code} - ` : ''} ${
                      option?.name || ''
                    } (${option?.material_type?.name || ''})`
                  }
                  required
                  renderInput={(params) => (
                    <TextField {...params} label="วัตถุดิบ" required />
                  )}
                />
              )}
            />
          </div>
          {imsSetting?.display?.lot_number && (
            <div className="w-full px-1 py-2">
              <Controller
                name={'lot_number'}
                control={control}
                defaultValue={''}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="หมายเลขล็อต (ถ้ามี)"
                    fullWidth
                    size={'small'}
                    helperText={errors.lot_number && 'กรุณาใส่ข้อมูล'}
                  />
                )}
              />
            </div>
          )}
          <div className="w-full  px-1 ">
            {watch('use_full_price') ? (
              <Controller
                name={'price'}
                control={control}
                defaultValue={0}
                rules={{ required: false, min: 0 }}
                render={({ field }) => (
                  <TextField
                    type="Number"
                    {...field}
                    onChange={(event) => {
                      field.onChange(event);
                      const selectedQuantity = watch('quantity');
                      const pricePerUnit =
                        event.target.value / selectedQuantity;
                      setValue('price_per_unit', pricePerUnit);
                    }}
                    label="ราคารวมทั้งหมด (บาท)"
                    fullWidth
                    size={'small'}
                    helperText={errors.price && 'กรุณาใส่ข้อมูล'}
                  />
                )}
              />
            ) : (
              <Controller
                name={'price_per_unit'}
                control={control}
                defaultValue={0}
                rules={{ required: false, min: 0 }}
                render={({ field }) => (
                  <TextField
                    type="Number"
                    {...field}
                    onChange={(event) => {
                      field.onChange(event);
                      const selectedQuantity = watch('quantity');
                      const fullPrice = selectedQuantity * event.target.value;
                      setValue('price', fullPrice);
                    }}
                    label={`ราคาต่อหน่วย (บาท / ${
                      watch('material')?.use_unit_conversion
                        ? watch('material')?.unit_input?.name || ''
                        : watch('material')?.unit || ''
                    })`}
                    fullWidth
                    size={'small'}
                    helperText={errors.price && 'กรุณาใส่ข้อมูล'}
                  />
                )}
              />
            )}

            <Controller
              name="use_full_price"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  label="ใส่ราคาเต็ม"
                  control={
                    <Checkbox
                      {...field}
                      defaultChecked={field.value}
                      size="small"
                    />
                  }
                />
              )}
            />
          </div>

          <div className="w-full px-1 py-2">
            {!_.isEmpty(customer?.rows) && watch('input_supplier') && (
              <Controller
                name="material_supplier"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    disablePortal
                    options={customer?.rows}
                    size="small"
                    placeholder="เลือกคู่ค้า"
                    onChange={(e, newValue) => {
                      field.onChange(newValue);
                    }}
                    className="z-40"
                    // prettier-ignore
                    getOptionLabel={(option) => `${option?.name || ''}`
                    }
                    renderInput={(params) => (
                      <TextField label="เลือกคู่ค้า" {...params} />
                    )}
                  />
                )}
              />
            )}

            <Controller
              name="input_supplier"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  label="ใส่คู่ค้า"
                  control={
                    <Checkbox {...field} checked={field.value} size="small" />
                  }
                />
              )}
            />
          </div>
          <div className="w-full  px-1 py-2">
            <ProductAmountInput
              controllerName="quantity"
              index={0}
              artificialControllerName="quantity_arificial"
              selectedUnitControllerName="quantity_unit"
              setValue={setValue}
              watch={watch}
              control={control}
              selectedProduct={watch('material')}
              onChangeFunction={() => {
                const selectedQuantity = watch('quantity');
                const fullPrice = watch('price_per_unit') * selectedQuantity;
                setValue('price', fullPrice);
              }}
            />
          </div>
          {imsSetting?.display?.production_date && (
            <div className="w-full lg:w-1/2 px-1 my-2">
              <label className="text-sm">วันที่ผลิต</label>
              <Controller
                name={'production_date'}
                control={control}
                defaultValue={new Date()}
                rules={{ required: true }}
                render={({ field }) => (
                  <DesktopDatepicker
                    {...field}
                    size={'small'}
                    fullWidth
                    label="วันผลิต"
                  />
                )}
              />
            </div>
          )}

          {imsSetting?.display?.expiration_date && (
            <div className="w-full lg:w-1/2 px-1  my-2">
              <label className="text-sm">วันหมดอายุ</label>
              <Controller
                name={'expiration_date'}
                control={control}
                defaultValue={new Date()}
                rules={{ required: true }}
                render={({ field }) => (
                  <DesktopDatepicker
                    {...field}
                    size={'small'}
                    fullWidth
                    label="วันผลิต"
                  />
                )}
              />
            </div>
          )}
          <div className="w-full my-2">
            <Alert severity="info">
              ราคารวม {currencyFormatter.format(watch('price'))} บาท (
              {currencyFormatter.format(watch('price_per_unit'))} บาท/หน่วย)
            </Alert>
          </div>
          <div className="px-1">
            <Button
              type="button"
              variant="outlined"
              size={'small'}
              color={'success'}
              onClick={handleSubmit(handleAddMaterial)}
            >
              เพิ่ม
            </Button>
          </div>
        </div>
      </form>
    </Card>
  );
  const renderMaterialQuantity = (foundMaterial, quantity) => {
    if (foundMaterial?.use_unit_conversion) {
      return (
        <div>
          {currencyFormatter.format(quantity)}{' '}
          {foundMaterial?.unit_input?.short_sign}({' '}
          {currencyFormatter.format(
            converseMaterialOrProductUnit(foundMaterial, quantity),
          )}{' '}
          {foundMaterial?.unit_output?.short_sign} )
        </div>
      );
    }
    return (
      <div>
        {currencyFormatter.format(quantity)} {foundMaterial?.unit}
      </div>
    );
  };
  const displayMaterial = () => (
    <Card>
      {' '}
      <TableContainer>
        <Table size="small" className="border-lg">
          <TableHead className="bg-blue-100">
            <TableRow>
              <TableCell>
                <div className="font-bold">ลำดับที่</div>
              </TableCell>
              {imsSetting?.display?.lot_number && (
                <TableCell>
                  <div className="font-bold"> หมายเลขล็อต</div>
                </TableCell>
              )}
              <TableCell>
                <div className="font-bold"> ชื่อวัตถุดิบ</div>
              </TableCell>
              <TableCell>
                <div className="font-bold"> ราคา</div>
              </TableCell>
              <TableCell>
                <div className="font-bold"> จำนวน</div>
              </TableCell>
              {imsSetting?.display?.production_date && (
                <TableCell>
                  <div className="font-bold"> วันที่ผลิต</div>
                </TableCell>
              )}
              {imsSetting?.display?.expiration_date && (
                <TableCell>
                  <div className="font-bold"> วันที่หมดอายุ</div>
                </TableCell>
              )}
              <TableCell>
                <div className="font-bold"> ดำเนินการ</div>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!_.isEmpty(selectMaterial) ? (
              selectMaterial.map((_material, index) => {
                const findMaterial = _.find(material, {
                  id: _material?.material,
                });
                return (
                  <TableRow key={index}>
                    <TableCell>{index + 1}</TableCell>
                    {imsSetting?.display?.lot_number && (
                      <TableCell>{_material?.lot_number || '-'}</TableCell>
                    )}
                    <TableCell>
                      <div className="flex flex-wrap">
                        <span className="font-bold mr-2">
                          {findMaterial?.type_code}
                        </span>
                        <span>{findMaterial?.name}</span>
                      </div>
                      <div className="text-xs">
                        หมวดหมู่ {findMaterial?.material_type?.name}
                      </div>
                      {_material?.displaySupplier && _material?.supplier?.name}
                    </TableCell>
                    <TableCell>{_material?.price || '-'} บาท</TableCell>
                    <TableCell>
                      <MultiUnitProdOrMatBox
                        foundMaterial={findMaterial}
                        quantity={_material?.quantity}
                      />
                    </TableCell>
                    {imsSetting?.display?.production_date && (
                      <TableCell>
                        {dayjs(_material?.production_date)
                          .locale('th')
                          .format('DD MMM BBBB')}
                      </TableCell>
                    )}
                    {imsSetting?.display?.expiration_date && (
                      <TableCell>
                        {dayjs(_material?.expiration_date)
                          .locale('th')
                          .format('DD MMM BBBB')}
                      </TableCell>
                    )}
                    <TableCell>
                      <Button
                        color={'error'}
                        variant="contained"
                        size={'small'}
                        onClick={() => handleRemoveMaterial(index)}
                      >
                        ลบ
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow key={''}>
                <TableCell colSpan={6}>
                  <div className="text-center">ไม่มีข้อมูล</div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );

  const showSelectMaterial = () => displayMaterial();

  return (
    <div>
      <div className="flex flex-wrap">
        <div className="w-full lg:w-1/3">{materialOptions()}</div>
        <div className="w-full lg:w-2/3 lg:pl-2">{showSelectMaterial()}</div>
      </div>
    </div>
  );
}

AddMaterialForm.propTypes = {
  material: PropTypes.object,
  selectMaterial: PropTypes.object,
  setSelectMaterial: PropTypes.func,
  customer: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
};

AddMaterialForm.defaultProps = {
  material: null,
  selectMaterial: null,
};

export default AddMaterialForm;
