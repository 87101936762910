import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { gql } from 'graphql-request';
import _ from 'lodash';
import PropTypes from 'prop-types';

import {
  BackButton,
  Error,
  Loading,
  PluginEditRenderingComponent,
  ViewTitle,
} from '../../../components';
import { config } from '../../../configs';
import graphqlClient from '../../../configs/graphqlClient';
import AllPluginInfo from '../../../plugins';
import * as actions from '../../../redux/actions';

/**
 * @function PluginEditData
 * @description Display a collections or a list of PluginEditData from database
 */

export default function PluginEditData({ title, subtitle }) {
  const dispatch = useDispatch();
  const step = useSelector((state) => state.step);
  const currentProcess = useSelector((state) => state.process);
  const params = useParams();
  const { pluginId } = params || { pluginId: '' };
  const foundedPlugin = _.find(
    AllPluginInfo,
    (eachPlugin) => eachPlugin?.id === pluginId,
  );

  const query = gql`
    query FindOneStep($stepInput: StepInput) {
      findOneStep(input: $stepInput) {
        _id
        name
        process {
          _id
          completed
          deleted
          manufacturing_order {
            _id
            running_number
            prefix
          }
        }
        plugin
        use_plugin_instead
        completed
        deleted
      }
    }
  `;

  const queryDataFromServer = async () => {
    const queryResult = await graphqlClient.request(query, {
      stepInput: { id: params.id },
      customerInput: { page: 1, size: config.maxFetchSize },
    });
    const stepData = queryResult?.findOneStep;
    dispatch(actions.stepStateOneSet(stepData));
  };

  const processQuery = gql`
    query FindOneProcess($processInput: ProcessInput) {
      findOneProcess(input: $processInput) {
        _id
        steps {
          measurements {
            measurement_type {
              name
              type_code
              data_type
            }
            value
            boolean_value
            additional_value
            date_value
          }
        }
        machine {
          name
          is_conveyor
          conveyor_width
          conveyor_length
          conveyor_parallel_number
          conveyor_reference_point {
            enable
            reference_every
          }
        }
      }
    }
  `;

  const queryProcessFromServer = async () => {
    const queryResult = await graphqlClient.request(processQuery, {
      processInput: {
        id: step?.process?._id,
        fetchMachine: true,
        fetchInsideProcess: true,
      },
    });
    const processData = queryResult?.findOneProcess;
    dispatch(actions.processStateOneSet(processData));
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [params]);

  useEffect(() => {
    if (step?._id) {
      queryProcessFromServer();
    }

    return () => {};
  }, [step]);

  const renderTitle = () => (
    <ViewTitle
      title={`${foundedPlugin?.buttonTitle || title}`}
      subtitle={subtitle}
    />
  );

  if (step.isLoading || currentProcess.isLoading) {
    return <Loading />;
  }

  if (
    !step.isLoading &&
    step.isCompleted &&
    !currentProcess.isLoading &&
    currentProcess.isCompleted
  ) {
    return (
      <div>
        {renderTitle()}
        <BackButton />
        <div className="my-2">
          <PluginEditRenderingComponent
            currentProcess={currentProcess}
            currentStep={step}
            manufacturingOrder={step?.process?.manufacturing_order}
          />
        </div>
      </div>
    );
  }
  return <Error />;
}

PluginEditData.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

PluginEditData.defaultProps = {
  title: '',
  subtitle: '',
};
