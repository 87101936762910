import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Button, Card } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as actions from '../../redux/actions';
import dayjs from 'dayjs';

import {
  ProductStockForm,
  AddProductForm,
  DecreaseProductForm,
} from '../../components/Forms';
import Loading from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';
import { BackButton } from '../../components/Button';
import {
  PRODUCT_TRANSACTION_TYPE,
  PRODUCT_STOCK_STATUS,
  PRODUCT_TRANSACTION_JSON_TYPE,
} from '../../utils/constants';

const defaultValues = {
  product_transaction_type: '',
  // production_number: '',
  warehouse: '',
  type: '',
  supplier: '',
  customer: '',
  quantity: '',
  price: '',
  discount: '',
  product: '',
  remark: '',
  payment: '',
  shipping: '',
  tracking_number: '',
  shipping_cost: '',
  seller: '',
  production_date: new Date(),
  expiration_date: new Date(),
  inventory_date: new Date(),
  issue_date: new Date(),
  sale_date: new Date(),
};

const AddProductStockLot = ({ title, subtitle }) => {
  const dispatch = useDispatch();
  const product = useSelector((state) => state.product);
  const warehouse = useSelector((state) => state.warehouse);
  const productTransactionType = useSelector(
    (state) => state.productTransactionType,
  );
  const customer = useSelector((state) => state.customer);
  const me = useSelector((state) => state.me);
  const paymentType = useSelector((state) => state.paymentType);
  const shippingType = useSelector((state) => state.shippingType);

  const history = useHistory();
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    defaultValues: {
      inventory_date: dayjs().format('YYYY-MM-DD'),
    },
  });
  const [name] = useState('');
  const [page] = useState(1);
  const [size] = useState(5);
  const [type, setType] = useState(null);
  const [selectProduct, setSelectProduct] = useState([]);
  const [selectProductLot, setSelectProductLot] = useState([]);
  const [filterProduct, setFilterProduct] = useState([]);
  const [filterWarehouse, setFilterWarehouse] = useState(null);
  const [discount, setDiscount] = useState(0);

  useEffect(() => {
    dispatch(actions.productAll({ name: '', size: '', page: 1 }));
    dispatch(actions.productTransactionTypeAll(''));
    dispatch(actions.warehouseAll(''));
    dispatch(actions.customerAll(''));
    dispatch(actions.meGet(''));
    dispatch(actions.paymentTypeAll(''));
    dispatch(actions.shippingTypeAll(''));
    return () => {};
  }, [name, page, size]);

  useEffect(() => {
    // Add
    if (type?.direction === PRODUCT_TRANSACTION_JSON_TYPE.add.direction) {
      dispatch(actions.productAll({ name: '', size: '', page: 1 }));
    } else if (
      type?.direction === PRODUCT_TRANSACTION_JSON_TYPE.desc.direction
    ) {
      dispatch(
        actions.productAll({
          name: '',
          size: '',
          page: 1,
          fetchStockLot: true,
          stockStatus: 'IN_STOCK',
        }),
      );
    }
    return () => {};
  }, [type?.direction]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const onSubmit = async (data, event) => {
    try {
      if (
        type?.direction === PRODUCT_TRANSACTION_TYPE[0].direction &&
        _.isEmpty(selectProduct)
      ) {
        alert('กรุณาเลือกสินค้า');
      } else if (
        type?.direction === PRODUCT_TRANSACTION_TYPE[1].direction &&
        _.isEmpty(selectProductLot)
      ) {
        alert('กรุณาเลือกล็อตสินค้า');
      } else if (
        type?.direction === PRODUCT_TRANSACTION_TYPE[0].direction &&
        !_.isEmpty(selectProduct)
      ) {
        data.product = selectProduct;
        data.status = PRODUCT_STOCK_STATUS.IN_STOCK.status_code;
        data.inventory_date = new Date();
        console.log('data', data);
        event.preventDefault();
        await dispatch(actions.productStockLotCreate(data));
        reset(defaultValues);
        setSelectProduct([]);
        alert('สำเร็จ');
        await dispatch(actions.productStockLotAll({ name, page, size }));
        history.goBack();
      } else if (
        type?.direction === PRODUCT_TRANSACTION_TYPE[1].direction &&
        !_.isEmpty(selectProductLot)
      ) {
        data.productLots = selectProductLot;
        data.discount = parseInt(discount, 10);
        data.price = _.sumBy(selectProductLot, (lot) => lot.price);
        data.total_price =
          _.sumBy(selectProductLot, (lot) => lot.price) - discount;
        console.log('data', data);
        event.preventDefault();
        await dispatch(actions.productStockLotDecrease(data));
        reset(defaultValues);
        setSelectProductLot([]);
        alert('สำเร็จ');
        await dispatch(actions.productStockLotAll({ name, page, size }));
        history.goBack();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const renderForm = () => (
    <Card className="p-6">
      <div className="py-2">
        <ProductStockForm
          Controller={Controller}
          control={control}
          errors={errors}
          warehouse={warehouse.rows}
          productTransactionType={productTransactionType.rows}
          customer={customer.rows}
          me={me.userData}
          paymentType={paymentType.rows}
          shippingType={shippingType.rows}
          type={type}
          setType={setType}
          setFilterWarehouse={setFilterWarehouse}
          setSelectProduct={setSelectProduct}
          setSelectProductLot={setSelectProductLot}
        />
      </div>
    </Card>
  );

  const renderSelectProduct = () => {
    if (type?.direction === PRODUCT_TRANSACTION_TYPE[0].direction) {
      return (
        <AddProductForm
          product={product.rows}
          selectProduct={selectProduct}
          setSelectProduct={setSelectProduct}
        />
      );
    }
    if (type?.direction === PRODUCT_TRANSACTION_TYPE[1].direction) {
      return (
        <DecreaseProductForm
          product={product.rows}
          warehouse={warehouse.rows}
          type={type}
          selectProductLot={selectProductLot}
          setSelectProductLot={setSelectProductLot}
          filterProduct={filterProduct}
          setFilterProduct={setFilterProduct}
          filterWarehouse={filterWarehouse}
          setFilterWarehouse={setFilterWarehouse}
          discount={discount}
          setDiscount={setDiscount}
        />
      );
    }
  };

  if (product.isLoading) {
    return <Loading />;
  }
  if (!product.isLoading && product.isCompleted) {
    return (
      <div>
        <div className="flex justify-between">
          <div>{renderTitle()}</div>
        </div>
        <div className="flex flex-row justify-start pb-4">
          <div>
            <BackButton />
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-row flex-wrap">
            <div className="w-full px-1 py-2">{renderForm()}</div>
            <div className="w-full px-1 py-2">{renderSelectProduct()}</div>
          </div>
          <div className="flex flex-row justify-end gap-1 py-4">
            <Button variant="contained" type="submit">
              บันทึก
            </Button>
          </div>
        </form>
      </div>
    );
  }
  return <Error message={product?.message} />;
};

AddProductStockLot.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

AddProductStockLot.defaultProps = {
  title: '',
  subtitle: '',
};

export default AddProductStockLot;
