import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button } from '@mui/material';
import { gql } from 'graphql-request';
import PropTypes from 'prop-types';

import {
  BackButton,
  Error,
  Loading,
  MMSDashboardForm,
  ViewTitle,
} from '@/components';
import graphqlClient from '@/configs/graphqlClient';
import * as actions from '@/redux/actions';

/**
 * @function CreateMMSDashboard
 * @description Display a collections or a list of CreateMMSDashboard from database
 */

export default function CreateMMSDashboard({ title, subtitle }) {
  const dispatch = useDispatch();
  const dashboardElement = useSelector((state) => state.dashboardElement);
  const history = useHistory();
  const [page] = useState(1);
  const [size, setSize] = useState(10);
  const {
    control,
    formState: { errors },
    setValue,
    watch,
    handleSubmit,
  } = useForm({});

  const query = gql`
    query FindCreateMMSDashboard(
      $dashboardElementinput: DashboardElementInput
    ) {
      findDashboardElements(input: $dashboardElementinput) {
        currPage
        lastPage
        total
        rows {
          _id
          name
          dashboard_type
          is_mo_level
        }
      }
    }
  `;

  const queryDataFromServer = async () => {
    try {
      const queryResult = await graphqlClient.request(query, {
        dashboardElementinput: { page, size },
      });
      const dashboardElementData = queryResult?.findDashboardElements;
      dispatch(actions.dashboardElementStateSet(dashboardElementData));
    } catch (error) {
      dispatch(actions.dashboardElementError());
    }
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [page, size]);

  const handleCreateMMSDashboard = async (data) => {
    try {
      await dispatch(actions.mmsDashboardCreate(data));
      alert('สำเร็จ');
      history.goBack();
    } catch (error) {
      alert(`สร้างแดชบอร์ดใหม่ไม่สำเร็จ ${error?.message}`);
      console.error(error);
    }
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (dashboardElement.isLoading) {
    return <Loading />;
  }
  if (!dashboardElement.isLoading && dashboardElement.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <BackButton />
        <div className="my-2">
          <form onSubmit={handleSubmit(handleCreateMMSDashboard)}>
            <MMSDashboardForm
              control={control}
              dashboardElement={dashboardElement}
              errors={errors}
              setValue={setValue}
              watch={watch}
              size={size}
              setSize={setSize}
            />
            <div className="flex justify-end">
              <Button variant="contained" color="success" type="submit">
                บันทึก
              </Button>
            </div>
          </form>
        </div>
      </div>
    );
  }
  return <Error />;
}

CreateMMSDashboard.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

CreateMMSDashboard.defaultProps = {
  title: '',
  subtitle: '',
};
