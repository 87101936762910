import React from 'react';

import { CampaignModal } from '@/components';

import LogoList from '../assets/images/logo-list.png';
import Santa from '../assets/images/santa.png';

export default function HNY2024() {
  return (
    <CampaignModal campaignCode="HNY_2024" title="สวัสดีปีใหม่ 2567">
      <div>
        <div className="flex justify-center">
          <img src={Santa} className="w-64" />
        </div>
        <div className="text-xl font-semibold text-center my-2">
          สวัสดีปีใหม่ 2567 <br />
          Happy New year 2024
        </div>
        <div className="text-center">
          ขอให้ปี 2567 นี้เป็นปีที่มีความสุข <br />
          และประสบความสำเร็จกับสิ่งที่ตั้งเป้าหมายไว้
        </div>
        <div className="flex justify-center my-4">
          <img src={LogoList} className="h-12" />
        </div>
      </div>
    </CampaignModal>
  );
}
