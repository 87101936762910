import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Button, Card } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import _ from 'lodash';

import * as actions from '../../redux/actions';
import {
  OrderForm,
  OrderSelectMaterialForm,
  SelectProductForm,
  OrderTotalSummaryForm,
} from '../../components/Forms';
import Loading from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';
import { BackButton } from '../../components/Button';
import * as CONFIG from '../../utils/constants';

const defaultValues = {
  type_code: '',
  name: '',
  description: '',
};

const CreateOrder = ({ title, subtitle }) => {
  const dispatch = useDispatch();
  const shippingType = useSelector((state) => state.shippingType);
  const paymentType = useSelector((state) => state.paymentType);
  const customer = useSelector((state) => state.customer);
  const history = useHistory();
  const product = useSelector((state) => state.product);
  const me = useSelector((state) => state.me);
  const productTransactionType = useSelector(
    (state) => state.productTransactionType,
  );
  const materialTransactionType = useSelector(
    (state) => state.materialTransactionType,
  );
  const material = useSelector((state) => state.material);
  const [selectProduct, setSelectProduct] = useState([]);
  const [selectMaterial, setSelectMaterial] = useState([]);
  const [discount, setDiscount] = useState(0);
  const [reload, setReload] = useState(false);

  // Setting React hook form
  const {
    formState: { errors },
    handleSubmit,
    control,
    reset,
    getValues,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      status: CONFIG.ORDER_STATUS.ORDER_CREATE.status_code,
      material_of_order: [
        {
          material: material?.[0],
          quantity: 0,
          price: 0,
        },
      ],
      product_of_order: [
        {
          product: product?.[0],
          quantity: 0,
          price: 0,
        },
      ],
    },
  });

  const [name] = useState('');
  const [page] = useState(1);
  const [size] = useState('');

  useEffect(() => {
    const fetchCustomers = async () => {
      try {
        await dispatch(actions.customerAll({ name, page, size }));
      } catch (error) {
        console.error('Customers get all error', error);
      }
    };
    const fetchShippingType = async () => {
      try {
        await dispatch(actions.shippingTypeAll({ name, page, size }));
      } catch (error) {
        console.error('Cannot Fetch Shipping Type');
      }
    };

    const fetchPaymentType = async () => {
      try {
        await dispatch(actions.paymentTypeAll({ name, page, size }));
      } catch (error) {
        console.error('Cannot Fetch Payment Type');
      }
    };

    const fetchProducts = async () => {
      try {
        await dispatch(
          actions.productAll({
            name: '',
            page,
            size: '',
            fetchStockLot: false,
          }),
        );
      } catch (error) {
        console.error('Cannot Fetch Product');
      }
    };

    const fetchMaterial = async () => {
      try {
        await dispatch(
          actions.materialAll({
            name: '',
            page,
            size: '',
            fetchStockLot: false,
          }),
        );
      } catch (error) {
        console.error('Cannot Fetch Material');
      }
    };

    const fetchProductTransactionTypes = async () => {
      try {
        await dispatch(
          actions.productTransactionTypeAll({ name: '', page, size: '' }),
        );
      } catch (error) {
        console.error('Cannot Fetch Product');
      }
    };

    const fetchMaterialTxnType = async () => {
      try {
        await dispatch(
          actions.materialTransactionTypeAll({ name: '', page, size: '' }),
        );
      } catch (error) {
        console.error('Cannot Fetch Material Transaction Type');
      }
    };

    fetchCustomers();
    fetchShippingType();
    fetchPaymentType();
    fetchProducts();
    fetchProductTransactionTypes();
    fetchMaterial();
    fetchMaterialTxnType();
    return () => {};
  }, [name, page, size]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const onSubmit = async (data, event) => {
    try {
      data.product = _.map(data.product, (_product) => _product._id);
      data.material = _.map(data.material, (_material) => _material._id);
      data.commerce.discount = parseInt(discount, 10);
      data.commerce.price =
        _.sumBy(selectProduct, (_product) => parseInt(_product.price, 10)) +
        _.sumBy(selectMaterial, (_material) => parseInt(_material.price, 10));
      data.commerce.total_price =
        _.sumBy(selectProduct, (_product) => parseInt(_product.price, 10)) +
        _.sumBy(selectMaterial, (_material) => parseInt(_material.price, 10)) -
        parseInt(discount, 10);
      const preprocessedData = {
        ...data,
        product_of_order: selectProduct,
        material_of_order: selectMaterial,
      };
      event.preventDefault();
      await dispatch(actions.orderCreate(preprocessedData));
      reset(defaultValues);
      alert('สำเร็จ');

      await dispatch(actions.orderAll({ name, page, size }));
      history.goBack();
    } catch (error) {
      console.error(error);
    }
  };

  // prettier-ignore
  const isPageLoading = () => customer.isLoading ||
    shippingType.isLoading ||
    paymentType.isLoading || productTransactionType.isLoading || product.isLoading;

  // prettier-ignore
  const isPageCompleted = () => customer.isCompleted &&
    shippingType.isCompleted && paymentType.isCompleted
     && productTransactionType.isCompleted && product.isCompleted;

  const renderForm = () => (
    <Card className="p-4">
      <OrderForm
        Controller={Controller}
        control={control}
        errors={errors}
        customers={customer}
        paymentType={paymentType}
        shippingType={shippingType}
        productTransactionType={productTransactionType.rows}
        materialTransactionType={materialTransactionType.rows}
        me={me}
      />
    </Card>
  );

  const renderSelectProduct = () => (
    <div>
      <div className="mb-2">
        <Card>
          <SelectProductForm
            product={product.rows}
            setSelectProduct={setSelectProduct}
            discount={discount}
            setDiscount={setDiscount}
            reload={reload}
            setReload={setReload}
            control={control}
            errors={errors}
            getValues={getValues}
            setValue={setValue}
            watch={watch}
          />
        </Card>
      </div>
      <div className="my-2">
        <Card>
          <OrderSelectMaterialForm
            material={material.rows}
            setSelectMaterial={setSelectMaterial}
            discount={discount}
            setDiscount={setDiscount}
            reload={reload}
            setReload={setReload}
            control={control}
            errors={errors}
            getValues={getValues}
            setValue={setValue}
            watch={watch}
          />
        </Card>
      </div>
      <div className="my-2">
        <Card>
          <OrderTotalSummaryForm
            control={control}
            errors={errors}
            getValues={getValues}
            reload={reload}
            setReload={setReload}
            setDiscount={setDiscount}
          />
        </Card>
      </div>
    </div>
  );

  if (isPageLoading()) {
    return <Loading />;
  }
  if (isPageCompleted()) {
    return (
      <div>
        <div className="flex justify-between">
          <div>{renderTitle()}</div>
        </div>
        <div className="flex flex-row justify-start pb-4">
          <div>
            <BackButton />
          </div>
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-row flex-wrap">
            <div className="w-full lg:w-1/3 px-1">{renderForm()}</div>
            <div className="w-full lg:w-2/3 px-1">{renderSelectProduct()}</div>
          </div>
          <div className="flex flex-row justify-end gap-1">
            <Button variant="contained" type="submit">
              บันทึก
            </Button>
          </div>
        </form>
      </div>
    );
  }
  return <Error message={customer?.message} />;
};

CreateOrder.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

CreateOrder.defaultProps = {
  title: '',
  subtitle: '',
};

export default CreateOrder;
