import React from 'react';
import { Controller, useFieldArray } from 'react-hook-form';
import {
  Autocomplete,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { DATA_TYPE, MATH_OPERATION } from '../../../utils/constants';

const ResultingFunctionTypeForm = ({
  control,
  errors,
  measurementType,
  organizationConstant,
  resultingFunctionType,
  watch,
  setValue,
  resultingFunctionTypesArray,
}) => {
  const { append, fields, remove } = useFieldArray({
    control,
    name: 'executor',
  });

  const renderStaticValueOrORGConstant = (propertyIndex) => {
    if (watch(`executor[${propertyIndex}].using_constant`)) {
      return (
        <div className="my-2">
          <Controller
            control={control}
            name={`executor[${propertyIndex}].using_static_value`}
            defaultValue={
              resultingFunctionType?.executor?.[propertyIndex]
                ?.using_static_value
            }
            render={({ field }) => (
              <FormControl>
                <FormControlLabel
                  control={
                    <Switch
                      {...field}
                      defaultChecked={field.value}
                      onChange={(event) => {
                        if (event.target.checked === true) {
                          field.onChange(event.target.checked);
                          console.log('Organization Constant has cleared');
                          setValue(
                            `executor[${propertyIndex}].organization_constant`,
                            null,
                          );
                        } else {
                          field.onChange(event.target.checked);
                        }
                      }}
                    />
                  }
                  label="พิมพ์ค่าเอง"
                ></FormControlLabel>
              </FormControl>
            )}
          />
        </div>
      );
    }
    return <div></div>;
  };

  const renderVariableOrAnotherFunction = (propertyIndex) => {
    if (!watch(`executor[${propertyIndex}].using_constant`)) {
      return (
        <div className="my-2">
          <Controller
            control={control}
            name={`executor[${propertyIndex}].using_another_rft`}
            defaultValue={
              resultingFunctionType?.executor?.[propertyIndex]
                ?.using_another_rft
            }
            render={({ field }) => (
              <FormControl>
                <FormControlLabel
                  control={
                    <Switch
                      {...field}
                      defaultChecked={field.value}
                      onChange={(event) => {
                        if (event.target.checked === true) {
                          field.onChange(event.target.checked);
                          console.log('Measurement Type has cleared');
                          setValue(
                            `executor[${propertyIndex}].measurement_type`,
                            null,
                          );
                        } else {
                          field.onChange(event.target.checked);
                        }
                      }}
                    />
                  }
                  label="ใช้ผลจากฟังก์ชันอื่น"
                ></FormControlLabel>
                <FormHelperText>
                  ท่านต้องเรียกใช้ฟังก์ชันนั้น ในขั้นตอนการผลิตด้วย
                  ไม่เช่นนั้นฟังก์ชันการประมวลผลนี้อาจจะทำงานผิดพลาดได้
                </FormHelperText>
              </FormControl>
            )}
          />
        </div>
      );
    }
    return <div></div>;
  };
  const renderUsingStaticNumberOrNot = (propertyIndex) => {
    if (watch(`executor[${propertyIndex}].using_static_value`)) {
      return (
        <div>
          <Controller
            control={control}
            name={`executor[${propertyIndex}].static_value`}
            render={({ field }) => (
              <FormControl>
                <TextField {...field} label="ค่าคงที่ที่ต้องการ" size="small" />
              </FormControl>
            )}
          />
        </div>
      );
    }
    if (!watch(`executor[${propertyIndex}].using_static_value`)) {
      return (
        <div>
          {!_.isEmpty(organizationConstant?.rows) && (
            <Controller
              control={control}
              name={`executor[${propertyIndex}].organization_constant`}
              defaultValue={
                resultingFunctionType?.executor?.[propertyIndex]
                  ?.organization_constant
              }
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  disablePortal
                  options={organizationConstant?.rows}
                  placeholder="เลือกค่าคงที่"
                  size="small"
                  onChange={(e, newValue) => {
                    field.onChange(newValue);
                  }}
                  // prettier-ignore
                  getOptionLabel={(option) => `${option?.name} `}
                  renderInput={(params) => (
                    <TextField label="ค่าคงที่" {...params} />
                  )}
                  renderOption={(props, option) => (
                    <div {...props}>{option.name}</div>
                  )}
                />
              )}
            />
          )}
        </div>
      );
    }
    return <div></div>;
  };

  const renderVariableOrNot = (propertyIndex) => {
    if (watch(`executor[${propertyIndex}].using_another_rft`)) {
      return (
        <div>
          {!_.isEmpty(resultingFunctionTypesArray?.rows) && (
            <Controller
              control={control}
              name={`executor[${propertyIndex}].another_rft`}
              defaultValue={
                resultingFunctionType?.executor?.[propertyIndex]?.another_rft
              }
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  disablePortal
                  options={resultingFunctionTypesArray?.rows}
                  placeholder="เลือกฟังก์ชันการแสดงผล"
                  size="small"
                  onChange={(e, newValue) => {
                    field.onChange(newValue);
                  }}
                  // prettier-ignore
                  getOptionLabel={(option) => `${option?.name} `
                }
                  renderInput={(params) => (
                    <TextField label="ฟังก์ชันการแสดงผล" {...params} />
                  )}
                  renderOption={(props, option) => (
                    <div {...props}>{option.name}</div>
                  )}
                />
              )}
            />
          )}
        </div>
      );
    }
    if (!watch(`executor[${propertyIndex}].using_another_rft`)) {
      return (
        <div>
          {!_.isEmpty(measurementType?.rows) && (
            <Controller
              control={control}
              name={`executor[${propertyIndex}].measurement_type`}
              defaultValue={
                resultingFunctionType?.executor?.[propertyIndex]
                  ?.measurement_type
              }
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  disablePortal
                  options={measurementType?.rows}
                  placeholder="เลือกตัวแปร"
                  size="small"
                  onChange={(e, newValue) => {
                    field.onChange(newValue);
                  }}
                  // prettier-ignore
                  getOptionLabel={(option) => `${option?.name} `
                }
                  renderInput={(params) => (
                    <TextField label="ตัวแปร" {...params} />
                  )}
                  renderOption={(props, option) => (
                    <div {...props}> {option.name}</div>
                  )}
                />
              )}
            />
          )}
        </div>
      );
    }
    return <div></div>;
  };

  return (
    <div className="flex flex-row flex-wrap">
      <div className="w-full px-1 py-2">
        <Controller
          name={'name'}
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="ชื่อฟังก์ชัน"
              fullWidth
              required
              size={'small'}
              helperText={errors?.name && 'ชื่อฟังก์ชัน'}
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-2">
        <div className="flex gap-2">
          <div className="self-center">การทำงานของฟังก์ชัน </div>
          <div>
            <Button
              size="small"
              type="button"
              variant="contained"
              onClick={() => {
                append({});
              }}
            >
              เพิ่ม
            </Button>
          </div>
        </div>
      </div>
      {_.map(fields, (eachField, index) => (
        <div className="w-full flex  flex-wrap px-1 py-2" key={eachField?.id}>
          <div className="w-1/2 lg:w-1/12 px-1 py-2">
            <Controller
              control={control}
              name={`executor[${index}].index`}
              defaultValue={index}
              render={({ field }) => (
                <TextField
                  {...field}
                  size="small"
                  inputMode="decimal"
                  label="ลำดับที่"
                  disabled
                  value={index + 1}
                />
              )}
            />
          </div>
          <div className="w-1/2 lg:w-3/12 text-center px-3 py-2">
            <Controller
              control={control}
              name={`executor[${index}].using_constant`}
              render={({ field }) => (
                <FormControl>
                  <FormControlLabel
                    control={<Switch {...field} defaultChecked={field.value} />}
                    label="ใช้ค่าคงที่"
                  ></FormControlLabel>
                </FormControl>
              )}
            />
          </div>
          <div className="w-1/2 lg:w-3/12 px-3 py-2">
            {watch(`executor[${index}].using_constant`) &&
              renderStaticValueOrORGConstant(index)}
            {watch(`executor[${index}].using_constant`) &&
              renderUsingStaticNumberOrNot(index)}
            {!watch(`executor[${index}].using_constant`) &&
              renderVariableOrNot(index)}{' '}
            {!watch(`executor[${index}].using_constant`) &&
              renderVariableOrAnotherFunction(index)}
          </div>
          <div className="w-1/2 lg:w-3/12 px-3 py-2">
            <Controller
              control={control}
              name={`executor[${index}].operation`}
              render={({ field }) => (
                <FormControl fullWidth size="small">
                  <InputLabel>การดำเนินการ</InputLabel>
                  <Select
                    {...field}
                    fullWidth
                    size="small"
                    label="การดำเนินการ"
                  >
                    {_.map(MATH_OPERATION, (eachOperation, operIndex) => (
                      <MenuItem key={operIndex} value={eachOperation.type_code}>
                        {eachOperation.description}{' '}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
          </div>
          <div className="w-1/2 lg:w-1/12 px-1 py-2">
            <Button
              size="small"
              type="button"
              color="error"
              variant="contained"
              onClick={() => remove(index)}
            >
              ลบ
            </Button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ResultingFunctionTypeForm;

ResultingFunctionTypeForm.propTypes = {
  control: PropTypes.func,
  errors: PropTypes.func,
  resultingFunctionType: PropTypes.object,
  organizationConstant: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  measurementType: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  watch: PropTypes.func,
  setValue: PropTypes.func,
  resultingFunctionTypesArray: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
};
