import React from 'react';
import {
  CheckSquare,
  Clipboard as ClipboardIcon,
  CornerDownLeft as CornerDownLeftIcon,
  Edit as EditIcon,
  Edit2,
  MinusCircle,
  Play as PlayIcon,
  UserPlus as UserPlusIcon,
} from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Button } from '@mui/material';
import dayjs from 'dayjs';
import BuddhistEra from 'dayjs/plugin/buddhistEra';
import hash from 'object-hash';
import PropTypes from 'prop-types';

import 'dayjs/locale/th';

import * as actions from '../../../redux/actions';
import { MMS } from '../../../utils/functions';

dayjs.extend(BuddhistEra);

const StepControlBox = ({ step, process, handleSendwork }) => {
  const dispatch = useDispatch();
  const me = useSelector((state) => state.me);
  const information = useSelector((state) => state.information);
  const history = useHistory();

  const handleStepGet = async () => {
    await dispatch(actions.stepGet(step?._id));
  };

  const {
    handleStartWork,
    handleAcceptWork,
    handleRejectWork,
    StepStatusCheck: stepStatusCheck,
  } = MMS;

  // หาค่า Status ต่าง ๆ ของ Step จาก Function
  const stepStatus = stepStatusCheck(step, me, process);

  const renderControlForMywork = () => (
    <div className="my-1 mt-4 flex w-full justify-end flex-wrap gap-2">
      {stepStatus.isMyDepartmentAndSeeking && (
        <Button
          variant="contained"
          color="success"
          startIcon={<UserPlusIcon size={18} />}
          className="w-full md:w-32"
          onClick={() =>
            handleAcceptWork({
              dispatch,
              step,
              employeeId: me?.userData?._id,
              handleSuccess: handleStepGet,
              fetchManufacTxn: false,
            })
          }
          disabled={
            !stepStatus.isCurrentStep ||
            !stepStatus.isMyDepartmentAndSeeking ||
            stepStatus.isDelete
          }
        >
          <div className="font-display">รับงาน</div>
        </Button>
      )}

      {stepStatus.buttonStartAllow && (
        <Button
          variant="contained"
          color="success"
          className="w-full md:w-32"
          onClick={() =>
            handleStartWork({
              dispatch,
              step,
              employeeId: me?.userData?._id,
              handleSuccess: handleStepGet,
            })
          }
          disabled={!stepStatus.buttonStartAllow}
          startIcon={<PlayIcon size={18} />}
        >
          <div className="font-display">เริ่มงาน</div>
        </Button>
      )}

      {stepStatus.materialAssociateButtonAllow && (
        <Button
          onClick={() => {
            history.push(
              `/mms/process/withdraw-material/${process?._id}?step=${step?._id}`,
            );
          }}
          variant="contained"
          color="warning"
          disabled={!stepStatus.materialAssociateButtonAllow}
          className="w-full md:w-40"
          startIcon={<ClipboardIcon size={18} />}
        >
          <div className="font-display">เบิกวัตถุดิบเพิ่ม</div>
        </Button>
      )}

      {stepStatus.materialAssociateButtonAllow && (
        <Link
          to={`/mms/process/return-material/${process?._id}`}
          hidden={!stepStatus.materialAssociateButtonAllow}
        >
          <Button
            variant="contained"
            disabled={!stepStatus.materialAssociateButtonAllow}
            className="w-full md:w-32"
            startIcon={<CornerDownLeftIcon size={18} />}
          >
            <div className="font-display">คืนของ</div>
          </Button>
        </Link>
      )}

      {stepStatus.materialAssociateButtonAllow && (
        <Link to={`/mms/process/handle-next-step/${process?._id}`}>
          <Button
            variant="contained"
            color="teal"
            disabled={!stepStatus.materialAssociateButtonAllow}
            className="w-full md:w-64"
            startIcon={<Edit2 size={18} />}
          >
            <div className="font-display">บันทึกการใช้วัตถุดิบและส่งงาน</div>
          </Button>
        </Link>
      )}

      {stepStatus.isLastStep &&
      information?.setting?.mms?.allowLastStepHandleProduct ? (
        stepStatus.buttonAllow && (
          <Link
            to={`/mms/manufacturing-orders/view/${process?.manufacturing_order?._id}/${process?._id}`}
          >
            <Button
              variant="contained"
              disabled={!stepStatus.buttonAllow}
              startIcon={<CheckSquare size={18} />}
              className="w-full md:w-44"
              color="secondary"
            >
              <div className="font-display">
                เตรียม
                {
                  information?.setting?.mms?.productionHandler
                    ?.productionHandlerName
                }{' '}
              </div>
            </Button>
          </Link>
        )
      ) : (
        <div></div>
      )}
      {stepStatus.buttonAllow &&
        !stepStatus.materialAssociateButtonAllow &&
        !stepStatus.measurementAssociateButtonAllow && (
          <Button
            variant="contained"
            disabled={!stepStatus.buttonAllow}
            startIcon={<CheckSquare size={18} />}
            color="secondary"
            className="w-full md:w-32"
            onClick={() => {
              const confirm = window.confirm('ยืนยันการส่งงาน');
              if (confirm) {
                const tempRefNo = hash({
                  user: me?.userData?._id,
                  date: new Date(),
                });

                handleSendwork(tempRefNo);
              }
            }}
          >
            <div className="font-display">ส่งงาน</div>
          </Button>
        )}

      {stepStatus.buttonAllow &&
        !stepStatus.materialAssociateButtonAllow &&
        stepStatus.measurementAssociateButtonAllow && (
          <Link to={`/mms/process/handle-next-step/${process?._id}`}>
            <Button
              variant="contained"
              color="teal"
              disabled={!stepStatus.measurementAssociateButtonAllow}
              className="w-full md:w-64"
              startIcon={<Edit2 size={18} />}
            >
              <div className="font-display">บันทึกค่าแล้วส่งงาน</div>
            </Button>
          </Link>
        )}

      {stepStatus.isMywork && !stepStatus.isSuccess && (
        <Button
          variant="contained"
          disabled={!stepStatus.isMywork || stepStatus.isSuccess}
          startIcon={<MinusCircle size={18} />}
          color="error"
          onClick={() =>
            handleRejectWork({
              dispatch,
              step,
              employeeId: me?.userData?._id,
              handleSuccess: handleStepGet,
              fetchManufacTxn: false,
            })
          }
        >
          <div className="font-display">ถอนตัว</div>
        </Button>
      )}
      {stepStatus.isMywork && stepStatus.isSuccess && (
        <Link to={`/mms/material-usage/edit/${process?._id}/`}>
          <Button
            variant="contained"
            disabled={!stepStatus.isMywork || !stepStatus.isSuccess}
            startIcon={<EditIcon size={18} />}
            color="warning"
            onClick={() => {}}
          >
            <div className="font-display">แก้ไขการใช้วัตถุดิบ</div>
          </Button>{' '}
        </Link>
      )}
      {stepStatus.isMywork && stepStatus.isSuccess && (
        <Link to={`/mms/works/measurement/edit/${step?._id}/`}>
          <Button
            variant="contained"
            disabled={!stepStatus.isMywork || !stepStatus.isSuccess}
            startIcon={<EditIcon size={18} />}
            color="info"
            onClick={() => {}}
          >
            <div className="font-display">แก่ไขค่าวัดผล</div>
          </Button>{' '}
        </Link>
      )}
    </div>
  );

  return <div className="py-2 w-full">{renderControlForMywork()}</div>;
};

export default StepControlBox;

StepControlBox.propTypes = {
  step: PropTypes.object,
  process: PropTypes.object,
  handleSendwork: PropTypes.func,
};
