import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Button, Card, TextField, InputAdornment } from '@mui/material';

import _ from 'lodash';
import PropTypes from 'prop-types';
import { gql } from 'graphql-request';
import dayjs from 'dayjs';
import { useForm } from 'react-hook-form';

import * as actions from '../../redux/actions';
import Loading from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';
import { app as appConfig } from '../../configs';
import { DesktopDatepicker } from '../../components/DatePicker';
import * as CONSTANT from '../../utils/constants';
import { BackButton } from '../../components/Button';
import { MultiPayrollForm } from '../../components/Forms';

function CreateMonthyPayroll({ title, subtitle }) {
  const dispatch = useDispatch();
  const employee = useSelector((state) => state.employee);
  const information = useSelector((state) => state.information);
  const history = useHistory();

  const {
    setting: { pams: pamsSetting },
  } = information;

  const [searchTerm, setSearchTerm] = useState('');
  const [name, setName] = useState('');
  const [total, setTotal] = useState(undefined);
  const [selectedDate, setSelectedDate] = useState(
    dayjs().startOf('month').toDate(),
  );

  const fetchAllEmployee = async () => {
    try {
      const query = gql`
      query FindAllEmployee {
        findEmployees(input:{page:1,size:${appConfig.maxFetchSize},earnings_type:"${CONSTANT.PAYROLL_TYPE.MONTHY.status_code}",fetchPayroll:true}){
          rows {
            _id
            id
          firstname
          lastname
          earnings {
              is_pay_monthy
              amount
          }
          payrolls{
            date
            payroll_type
            total_cost
          }
          }
        }
      } 
    `;
      //   console.log('Query', query);
      await dispatch(actions.employeeAllQuery({ query }));
    } catch (error) {
      console.error('Error on Get Employee ', error);
    }
  };

  useEffect(() => {
    fetchAllEmployee();
    return () => {};
  }, [name]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchTerm);
    }, 700);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm();

  useEffect(() => {
    setTotal(employee?.total);
    // ส่งไปภายใต้ Object arr แล้วให้ BE วนสร้างทีเดียวหลาย ๆ รายการ
    if (employee?.rows) {
      setValue(
        'arr',
        _.map(employee?.rows, (_employee) => ({ employee: _employee })),
      );
    }
    return () => {};
  }, [employee]);

  const onSubmit = async (data) => {
    try {
      const filteredPayload = _.filter(
        data?.arr,
        (eachPayroll) => eachPayroll?.enable === true,
      );
      const editedPayload = _.map(filteredPayload, (eachPayroll) => ({
        ...eachPayroll,
        date: dayjs(selectedDate)
          .startOf('month')
          .add(1, 'days')
          .format('YYYY-MM-DDTHH:mm'),
        payroll_type: CONSTANT.PAYROLL_TYPE.MONTHY.status_code,
      }));
      console.log('Edtied Payroll Payload', editedPayload);
      await dispatch(actions.payrollCreate({ arr: editedPayload }));
      alert('สร้างการจ่ายค่าตอบแทนสำเร็จ');
      history.goBack();
    } catch (error) {
      alert(`การสร้างการจ่ายค่าตอบแทนมีปัญห่า ${error?.message}`);
      console.error('Error Create Payroll', error);
    }
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const renderBackButton = () => (
    <div className="flex">
      <BackButton />
    </div>
  );

  const renderSearch = () => (
    <Card className="my-2">
      <div className="p-4 flex flex-row justify-between">
        <div className="w-full md:w-1/2">
          <TextField
            label="ค้นหา"
            fullWidth
            size={'small'}
            id="outlined-start-adornment"
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <i className="fas fa-search"></i>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className="w-full md:w-1/4">
          <DesktopDatepicker
            value={selectedDate}
            setValue={setSelectedDate}
            label="เดือนที่ต้องการค้นหา"
            inputFormat="MMMM BBBB"
          />
        </div>
      </div>
    </Card>
  );

  const renderTable = () => (
    <div className="my-2">
      <form onSubmit={handleSubmit(onSubmit)}>
        <MultiPayrollForm
          control={control}
          errors={errors}
          isDaily={false}
          watch={watch}
          setValue={setValue}
          selectedDate={selectedDate}
          hourPerDay={pamsSetting?.daily_payroll?.working_hour}
          breakPerDay={pamsSetting?.daily_payroll?.break_hour}
          brushOfThreshold={pamsSetting?.daily_payroll?.brushoff_threshold}
          lateThreshold={pamsSetting?.daily_payroll?.late_threshold}
        />
        <div className="flex justify-end my-2">
          <Button variant="contained" type="submit">
            จ่ายค่าตอบแทน
          </Button>
        </div>
      </form>
    </div>
  );

  if (employee.isLoading) {
    return <Loading />;
  }
  if (!employee.isLoading && employee.isCompleted) {
    return (
      <div>
        <div className="flex justify-between">
          <div>{renderTitle()}</div>
        </div>
        {renderBackButton()}
        {renderSearch()}
        {renderTable()}
      </div>
    );
  }
  return <Error message={employee?.message} />;
}

CreateMonthyPayroll.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

CreateMonthyPayroll.defaultProps = {
  title: '',
  subtitle: '',
};

export default CreateMonthyPayroll;
