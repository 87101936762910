import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Card,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { Error, Loading, StepTable, ViewTitle } from '@/components';
import * as actions from '@/redux/actions';

const WorkHistory = ({ title, subtitle }) => {
  // Setting
  const dispatch = useDispatch();
  const history = useHistory();
  const step = useSelector((state) => state.step);
  const me = useSelector((state) => state.me);
  const department = useSelector((state) => state.department);
  const [searchTerm, setSearchTerm] = useState('');
  const [name, setName] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(undefined);
  const [selectedDepartment, setSelectedDepartment] = useState(
    me?.userData?.role?.level >= 1 ? '' : me?.userData?.department?._id,
  );

  // Initialization
  useEffect(() => {
    dispatch(
      actions.stepAll({
        name,
        page,
        size,
        department: selectedDepartment,
        completed: true,
        deleted: true,
        incompleted: false,
      }),
    );
    return () => {};
  }, [name, page, size, selectedDepartment]);

  useEffect(() => {
    const fetchDepartment = async () => {
      try {
        await dispatch(actions.departmentAll({ name: '' }));
      } catch (error) {
        console.error('Fetch Department Error', error);
      }
    };

    fetchDepartment();
    return () => {};
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchTerm);
      setPage(1);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  useEffect(() => {
    setTotal(step?.total);
    return () => {};
  }, [step]);

  // Functions

  const handleToInfoPage = (id) => {
    history.push(`/mms/works/view/${id}`);
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const tableProps = {
    step,
    page,
    size,
    total,
    name,
    handleToInfoPage,
    handleChangeRowsPerPage,
    handleChangePage,
  };

  // Component Rendering
  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;
  const renderSearch = () => (
    <Card>
      <div className="p-4 flex flex-row">
        <div className="w-full md:w-1/2 px-2">
          <TextField
            label="ค้นหา"
            fullWidth
            size={'small'}
            id="outlined-start-adornment"
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <i className="fas fa-search"></i>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className="w-full md:w-1/2 px-2">
          {me?.userData?.role?.level >= 1 && (
            <FormControl sx={{ minWidth: 120 }} fullWidth={true}>
              <InputLabel id="type" size={'small'}>
                ค้นหาด้วยแผนก
              </InputLabel>
              <Select
                label="ค้นหาด้วยแผนก"
                size={'small'}
                fullWidth
                onChange={(event) => {
                  setSelectedDepartment(event.target.value);
                }}
              >
                <MenuItem value="">ทั้งหมด</MenuItem>
                {_.map(department?.rows, (_department, index) => (
                  <MenuItem value={_department?._id} key={index}>
                    {_department?.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </div>
      </div>
    </Card>
  );

  const renderTable = () => <StepTable {...tableProps} />;

  if (step.isLoading || department.isLoading) {
    return <Loading />;
  }

  if (
    !step.isLoading &&
    step.isCompleted &&
    !department.isLoading &&
    department.isCompleted
  ) {
    return (
      <div>
        {renderTitle()}
        {renderSearch()}
        {renderTable()}
      </div>
    );
  }

  return <Error />;
};

WorkHistory.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

WorkHistory.defaultProps = {
  title: '',
  subtitle: '',
};
export default WorkHistory;
