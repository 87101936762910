import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import {
  Button,
  Card,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from '@mui/material';
import { gql } from 'graphql-request';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { Error, Loading, ViewTitle } from '@/components';
import graphqlClient from '@/configs/graphqlClient';
import * as actions from '@/redux/actions';
import { menuList as MODULE_LIST } from '@/utils/constants';

/**
 * @function PDFReports
 * @description Display a collections or a list of PDFReport from database
 */

export default function PDFReport({ title, subtitle }) {
  const dispatch = useDispatch();
  const pdfReport = useSelector((state) => state.pdfReport);
  const history = useHistory();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const [name, setName] = useState('');
  const [total, setTotal] = useState(0);

  const query = gql`
    query FindPDFReport($pdfReportinput: PDFReportInput) {
      findPDFReports(input: $pdfReportinput) {
        currPage
        lastPage
        total
        rows {
          _id
          name
          module
        }
      }
    }
  `;

  const queryDataFromServer = async () => {
    try {
      const queryResult = await graphqlClient.request(query, {
        pdfReportinput: { page, size, name },
      });
      const pdfReportData = queryResult?.findPDFReports;
      dispatch(actions.pdfReportStateSet(pdfReportData));
    } catch (error) {
      dispatch(actions.pdfReportError());
    }
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchTerm);
      setPage(1);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [page, size]);

  useEffect(() => {
    if (pdfReport?.total) {
      setTotal(pdfReport?.total);
    }
    return () => {};
  }, [pdfReport]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(+event.target.value);
    setPage(1);
  };

  const handleDeletePDFReport = async (id) => {
    try {
      await dispatch(actions.pdfReportDelete(id));
      queryDataFromServer();
    } catch (error) {
      alert(`ไม่สามารถลบรายงานได้ ${error.message}`);
    }
  };

  const renderTitle = () => (
    <div className="flex flex-row justify-between items-center">
      <ViewTitle title={title} subtitle={subtitle} />
      <Button
        variant="contained"
        color="primary"
        onClick={() => history.push('/setting/pdf-report/create')}
      >
        เพิ่ม
      </Button>
    </div>
  );
  if (pdfReport.isLoading) {
    return <Loading />;
  }

  if (!pdfReport.isLoading && pdfReport.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div>
          <Card>
            <div className="p-4 flex flex-row">
              <div className="w-full md:w-1/2">
                <TextField
                  label="ค้นหา"
                  fullWidth
                  size="small"
                  id="outlined-start-adornment"
                  onChange={(e) => {
                    setSearchTerm(e.target.value);
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <i className="fas fa-search"></i>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </div>
          </Card>
          <Paper className="my-2">
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }}>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <div className="font-bold">ลำดับที่</div>
                    </TableCell>
                    <TableCell>
                      <div className="font-bold">ชื่อฟอร์ม</div>
                    </TableCell>

                    <TableCell>
                      <div className="font-bold">โมดูล</div>
                    </TableCell>
                    <TableCell>
                      <div className="font-bold">ดำเนินการ</div>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!_.isEmpty(pdfReport?.rows) ? (
                    _.map(pdfReport?.rows, (row, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          <div className="text-xs">{index + 1}</div>
                        </TableCell>
                        <TableCell>{row?.name}</TableCell>
                        <TableCell>
                          {_.find(MODULE_LIST, { moduleName: row?.module })
                            ?.title || ''}
                        </TableCell>
                        <TableCell>
                          <div className="flex gap-2">
                            <Link to={`/setting/pdf-report/edit/${row?._id}`}>
                              <Button color="warning" variant="contained">
                                แก้ไข
                              </Button>
                            </Link>
                            <Button
                              color="error"
                              variant="contained"
                              onClick={() => {
                                handleDeletePDFReport(row?._id);
                              }}
                            >
                              ลบ
                            </Button>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={6}>
                        <div className="text-center">ไม่มีข้อมูล</div>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              page={page - 1}
              count={total || 1}
              rowsPerPage={size}
              onRowsPerPageChange={handleChangeRowsPerPage}
              onPageChange={handleChangePage}
            />
          </Paper>
        </div>
      </div>
    );
  }
  return <Error />;
}

PDFReport.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

PDFReport.defaultProps = {
  title: '',
  subtitle: '',
};
