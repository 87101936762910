import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import {
  Alert,
  AlertTitle,
  Backdrop,
  Button,
  Card,
  Fade,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from '@mui/material';
import { gql } from 'graphql-request';
import _ from 'lodash';
import PropTypes from 'prop-types';

import {
  CSVUpload,
  Error,
  ExportExcelContainer,
  Loading,
  ProductForm,
  ViewTitle,
} from '@/components';
import { ProductUtil } from '@/utils/modelutils';

import { app as appConfig } from '../../configs';
import * as actions from '../../redux/actions';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90vw',
  boxShadow: 24,
  p: 4,
};

const defaultValue = {
  name: '',
  unit: '',
  packing_size: '',
  packing_unit: '',
  net: '',
  unit_net: '',
  detail: '',
  type_code: '',
};

function Product({ title, subtitle }) {
  const dispatch = useDispatch();
  const product = useSelector((state) => state.product);
  const productType = useSelector((state) => state.productType);
  const unit = useSelector((state) => state.unit);
  const information = useSelector((state) => state.information);
  const availableModule = useSelector((state) => state.availableModule);
  const history = useHistory();
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    watch,
  } = useForm();
  const [searchTerm, setSearchTerm] = useState('');
  const [name, setName] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(undefined);
  const [open, setOpen] = useState(false);
  const [csvUploadOpen, setCsvUploadOpen] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [selectProductTypeType, setSelectProductType] = useState('');
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleCSVOpen = () => setCsvUploadOpen(true);
  const handleCSVClose = () => {
    setCsvUploadOpen(false);
    setCsvData([]);
  };

  useEffect(() => {
    const getAllUnit = async () => {
      try {
        const query = gql`
        query FindUnits {
          findUnits(input:{page:1,size:${appConfig.maxFetchSize},}){
            rows {
            _id
            name
            }
          }
        } 
      `;
        dispatch(actions.unitAllQuery({ query }));
      } catch (error) {
        console.error('Get All Units Error', error);
      }
    };

    dispatch(
      actions.productAll({
        name,
        page,
        size,
        selectProductTypeType,
        fetchStockLot: false,
      }),
    );
    dispatch(actions.productTypeAll(''));
    getAllUnit();
    return () => {};
  }, [name, page, size, selectProductTypeType]);

  useEffect(() => {
    setTotal(product?.total);
    return () => {};
  }, [product]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchTerm);
      setPage(1);
    }, 700);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const onSubmit = async (data) => {
    try {
      console.log(data);
      // if (_.isEmpty(data.inventory_type)) {
      //   data.inventory_type = false;
      // }
      await dispatch(actions.productCreate(data));
      reset(defaultValue);
      alert('สำเร็จ');
      handleClose();
      await dispatch(
        actions.productAll({ name, page, size, fetchStockLot: false }),
      );
    } catch (error) {
      console.error(error);
    }
  };

  const handleAddFromFile = async () => {
    if (!_.isEmpty(csvData)) {
      try {
        await dispatch(actions.productCreate({ arr: csvData }));
        alert('สำเร็จ');
        handleCSVClose();
        await dispatch(
          actions.productAll({ name, page, size, fetchStockLot: false }),
        );
      } catch (error) {
        alert('เพิ่มสินค้าไม่สำเร็จ');
        console.error(error);
      }
    } else {
      alert('ไม่สามารถอ่านไฟล์รายการสินค้าได้');
    }
  };

  const handleDeleteproduct = async (id) => {
    const confirm = window.confirm('ยืนยันการลบข้อมูล');
    if (confirm) {
      try {
        await dispatch(actions.productDelete(id));
        await dispatch(
          actions.productAll({ name, page, size, fetchStockLot: false }),
        );
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handlePushToDetailProduct = async (id) => {
    history.push(`product/${id}`);
  };

  const handlePushToEditProduct = async (id) => {
    history.push(`product/edit/${id}`);
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };

  const handleSelectProductType = (type) => {
    setSelectProductType(type);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const renderAddButtom = () => (
    <div className="flex flex-row justify-end pb-4 gap-2">
      <Button variant="contained" onClick={handleOpen}>
        เพิ่ม
      </Button>
      <Button variant="contained" onClick={handleCSVOpen} color="success">
        อัพโหลด
      </Button>
      {/* <Link
        to={{
          pathname: '/wms/product/edit-many',
          state: {
            name,
            page,
            size,
          },
        }}
      >
        <Button variant="contained" color="warning">
          แก้ไขหลายชนิด
        </Button>
      </Link> */}
    </div>
  );

  const renderSearch = () => (
    <Card>
      <div className="p-4 flex flex-row gap-2">
        <div className="w-full md:w-1/2">
          <TextField
            label="ค้นหา"
            fullWidth
            size={'small'}
            id="outlined-start-adornment"
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <i className="fas fa-search"></i>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className="w-full md:w-1/2">
          <Controller
            name={'status_code'}
            control={control}
            defaultValue={''}
            rules={{ required: false }}
            render={({ field }) => (
              <FormControl sx={{ minWidth: 120 }} fullWidth={true}>
                <InputLabel id="type" size={'small'}>
                  ค้นหาด้วยประเภทสินค้า
                </InputLabel>
                <Select
                  {...field}
                  label="ค้นหาด้วยประเภทสินค้า"
                  size={'small'}
                  fullWidth
                  onChange={(e) => {
                    field.onChange(e.target.value);
                    handleSelectProductType(e.target.value);
                  }}
                >
                  <MenuItem value="">ทั้งหมด</MenuItem>
                  {_.map(productType.rows, (_productType) => (
                    <MenuItem value={_productType?._id} key={_productType?._id}>
                      {_productType?.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />
        </div>
        <div className="w-full md:w-1/4 flex justify-end">
          <ExportExcelContainer
            columnList={ProductUtil.columnWithoutStock}
            currentData={product?.rows}
            dataAPIEndPoint="material"
            dataQuery={{
              fetchStockLot: false,
              selectProductTypeType,
              stockStatus: '',
              fetchProcess: false,
              page: 1,
              size: appConfig.maxFetchSize,
            }}
            sheetName="Product"
          />
        </div>
      </div>
    </Card>
  );

  const renderModal = () => (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Card sx={style} className="max-w-4xl">
          <div className="py-2">เพิ่มข้อมูล</div>
          <div className="py-2">
            <form onSubmit={handleSubmit(onSubmit)}>
              <ProductForm
                control={control}
                Controller={Controller}
                errors={errors}
                product={product}
                productType={productType}
                unit={unit}
                watch={watch}
              />
              <div className="flex flex-row justify-end gap-1 py-4">
                <Button variant="contained" type="submit">
                  บันทึก
                </Button>
                <Button variant="outlined" onClick={handleClose}>
                  ยกเลิก
                </Button>
              </div>
            </form>
          </div>
        </Card>
      </Fade>
    </Modal>
  );

  const renderCSVUploadModal = () => (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={csvUploadOpen}
      onClose={handleCSVClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={csvUploadOpen}>
        <Card sx={style} className="max-w-4xl">
          <div className="pt-2">อัพโหลดข้อมูล</div>
          <div className="py-2 font-system">
            อัพโหลดไฟล์ .csv โดยมีโครงสร้างตามไฟล์เทมเพลต แถวที่ 1-2
            และแถวสุดท้าย จะไม่ถูกนำมาคิดในระบบ <br />
            <Button>
              <Link to="/filetemplate/Product.csv" target="_blank">
                ดาวน์โหลดเทมเพลต
              </Link>
            </Button>
          </div>
          <div className="my-2"></div>
          <div className="py-2">
            <div className="flex justify-center">
              <div>
                <CSVUpload setCsvData={setCsvData} />
                {!_.isEmpty(csvData) && (
                  <div>
                    <p className="font-system my-1">
                      พบข้อมูล {_.size(csvData)} รายการ
                    </p>
                    <Button
                      color="primary"
                      variant="contained"
                      type="button"
                      onClick={handleAddFromFile}
                    >
                      บันทึก
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Card>
      </Fade>
    </Modal>
  );

  const renderTable = () => (
    <div className="my-2">
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <div className="font-bold">ลำดับที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> รหัสสินค้า</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ชื่อสินค้า</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> หน่วย</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ประเภทสินค้า</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ดำเนินการ</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(product.rows) ? (
                product.rows.map((row, index) => (
                  <TableRow
                    key={row.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {(page - 1) * size + index + 1}
                    </TableCell>
                    <TableCell>{row.type_code || row?.tag || '-'}</TableCell>
                    <TableCell>
                      {row.name}
                      {information?.external_software?.ewarehouse?.enable && (
                        <div className="text-gray-500 text-sm">
                          น้ำหนักสินค้าต่อหน่วย {row?.weight || '-'} กก.
                        </div>
                      )}
                    </TableCell>
                    <TableCell>
                      {row?.use_unit_conversion ? (
                        <div>
                          <div>{row?.unit_input?.name}</div>{' '}
                          <div>{row?.unit_output?.name}</div>{' '}
                        </div>
                      ) : (
                        row?.unit
                      )}
                    </TableCell>
                    <TableCell>{`${
                      row.product_type?.name || row?.type?.name || ''
                    }`}</TableCell>
                    <TableCell>
                      <div className="flex flex-row flex-wrap gap-1">
                        <Button
                          variant="contained"
                          color={'info'}
                          size={'small'}
                          onClick={() => handlePushToDetailProduct(row.id)}
                        >
                          รายละเอียด
                        </Button>
                        {availableModule?.MMS && (
                          <Link
                            to={`/setting/mms/product-process/edit/${row?.id}`}
                          >
                            <Button
                              variant="contained"
                              color="teal"
                              size={'small'}
                            >
                              แก้ไขสูตรการผลิต
                            </Button>
                          </Link>
                        )}
                        <Button
                          variant="contained"
                          color={'warning'}
                          size={'small'}
                          onClick={() => handlePushToEditProduct(row.id)}
                        >
                          แก้ไข
                        </Button>
                        <Button
                          variant="contained"
                          color={'error'}
                          size={'small'}
                          onClick={() => {
                            handleDeleteproduct(row?.id);
                          }}
                        >
                          ลบ
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <div className="text-center">ไม่มีข้อมูล</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={page - 1}
          count={total || 1}
          rowsPerPage={size}
          onPageChange={handleChangePage}
        />
      </Paper>
    </div>
  );

  if (product.isLoading) {
    return <Loading />;
  }
  if (!product.isLoading && product.isCompleted) {
    return (
      <div>
        {renderModal()}
        {renderCSVUploadModal()}
        <div className="flex justify-between">
          <div>{renderTitle()}</div>
          <div className="mt-6">{renderAddButtom()}</div>
        </div>
        {renderSearch()}
        {renderTable()}
      </div>
    );
  }
  return <Error message={product?.message} />;
}

Product.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

Product.defaultProps = {
  title: '',
  subtitle: '',
};

export default Product;
