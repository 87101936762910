import React from 'react';
import { Controller } from 'react-hook-form';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import PropTypes from 'prop-types';

import { backConverseMaterialOrProductUnit } from '../../utils/functions';

const OnCreateMOMaterialQuantityInput = ({
  productIndex,
  materialIndex,
  reload,
  setReload,
  errors,
  control,
  watch,
  setValue,
}) => {
  const selectedMaterial = watch(
    `processes[${productIndex}].materials[${materialIndex}].material`,
  );
  // console.log('Selected Material', selectedMaterial);

  const unitSelector = () => (
    <Controller
      name={`processes[${productIndex}].materials[${materialIndex}].selected_unit`}
      defaultValue={selectedMaterial?.unit_input?._id}
      control={control}
      render={({ field }) => (
        <FormControl fullWidth>
          <InputLabel>หน่วย</InputLabel>
          <Select
            fullWidth
            label="หน่วย"
            size="small"
            {...field}
            defaultValue={field.value}
          >
            <MenuItem value={selectedMaterial?.unit_input?._id}>
              {selectedMaterial?.unit_input?.name}
            </MenuItem>
            <MenuItem value={selectedMaterial?.unit_output?._id}>
              {selectedMaterial?.unit_output?.name}
            </MenuItem>
          </Select>
        </FormControl>
      )}
    />
  );
  const renderRealQuantityInput = () => (
    <Controller
      name={`processes[${productIndex}].materials[${materialIndex}].begin`}
      control={control}
      rules={{ required: true }}
      render={({ field }) => (
        <TextField
          {...field}
          onChange={(e) => {
            field.onChange(e.target.value);
            setReload(!reload);
          }}
          label={`จำนวน  ${
            selectedMaterial?.use_unit_conversion ? '' : selectedMaterial?.unit
          }
          `}
          fullWidth
          required
          inputProps={{
            min: 0,
            style: { textAlign: 'center' },
          }}
          size={'small'}
          helperText={errors.begin && 'กรุณาใส่ข้อมูล'}
        />
      )}
    />
  );

  const renderArtificialQuantityInput = () => (
    <Controller
      name={`processes[${productIndex}].materials[${materialIndex}].begin_artificial`}
      control={control}
      rules={{ required: true }}
      render={({ field }) => (
        <TextField
          {...field}
          onChange={(e) => {
            field.onChange(e.target.value);
            setValue(
              `processes[${productIndex}].materials[${materialIndex}].begin`,
              backConverseMaterialOrProductUnit(
                selectedMaterial,
                parseFloat(e.target.value),
              ),
            );
            setReload(!reload);
          }}
          label="จำนวน"
          fullWidth
          required
          inputProps={{
            min: 0,
            style: { textAlign: 'center' },
          }}
          size={'small'}
          helperText={errors.begin && 'กรุณาใส่ข้อมูล'}
        />
      )}
    />
  );

  // using variable conversion
  if (selectedMaterial?.use_unit_conversion) {
    return (
      <div className="my-2">
        <div>{unitSelector()}</div>
        {watch(
          `processes[${productIndex}].materials[${materialIndex}].selected_unit`,
        ) === selectedMaterial?.unit_input?._id ? (
          <div>
            {/* ใช้หน่วยหลัก  */}
            <div className="flex gap-2">
              <div className="my-2">{renderRealQuantityInput()}</div>
              <div className="text-sm self-center">
                {selectedMaterial?.unit_input?.name}
              </div>
            </div>
          </div>
        ) : (
          <div>
            {' '}
            {/* ใช้หน่วยรอง */}
            <div className="flex gap-2">
              <div className="my-2">{renderArtificialQuantityInput()}</div>
              <div className="text-sm self-center">
                {selectedMaterial?.unit_output?.name}
              </div>
            </div>
            <div className="text-xs text-center">
              คิดเป็น{' '}
              {parseFloat(
                watch(
                  `processes[${productIndex}].materials[${materialIndex}].begin`,
                ),
              ).toFixed(2)}{' '}
              {selectedMaterial?.unit_input?.name}
            </div>
          </div>
        )}
      </div>
    );
  }
  // not use Variable conversion
  return <div>{renderRealQuantityInput()}</div>;
};

OnCreateMOMaterialQuantityInput.propTypes = {
  productIndex: PropTypes.number,
  materialIndex: PropTypes.number,
  reload: PropTypes.bool,
  setReload: PropTypes.func,
  errors: PropTypes.object,
  control: PropTypes.func,
  watch: PropTypes.func,
  setValue: PropTypes.func,
};

export default OnCreateMOMaterialQuantityInput;
