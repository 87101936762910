/* eslint-disable simple-import-sort/imports */
// Customer Relation
import CustomerForm from './CRM/CustomerForm';
import CustomerTypeForm from './CRM/CustomerTypeForm';

// Human Resource
import DepartmentForm from './HRMS/DepartmentForm';
import RoleTypeForm from './HRMS/RoleTypeForm';
import EmployeeForm from './HRMS/EmployeeForm';
import UserForm from './HRMS/UserForm';

// Payroll
import MultiPayrollForm from './PAMS/MultiPayrollForm';
import PAMSSettingForm from './PAMS/PAMSSettingForm';

// Material Inventory
import MaterialTypeForm from './IMS/MaterialTypeForm';
import MaterialForm from './IMS/MaterialForm';
import MaterialTransactionTypeForm from './IMS/MaterialTransactionTypeForm';
import PlaceForm from './IMS/PlaceForm';
import MaterialStockForm from './IMS/MaterialStockForm';
// eslint-disable-next-line import/no-cycle
import AddMaterialForm from './IMS/AddMaterialForm';
import DecreaseMaterialForm from './IMS/DecreaseMaterialForm';
import ApprovalMaterialWithdrawForm from './IMS/ApprovalMaterialWithdrawForm';
import MoveMaterialForm from './IMS/MoveMaterialForm';

// Product Warehouse
import ProductTypeForm from './WMS/ProductTypeForm';
import ProductForm from './WMS/ProductForm';
import ProductTransactionTypeForm from './WMS/ProductTransactionTypeForm';
import WarehouseForm from './WMS/WarehouseForm';
import ProductStockForm from './WMS/ProductStockForm';
import AddProductForm from './WMS/AddProductForm';
import DecreaseProductForm from './WMS/DecreaseProductForm';
import WarehouseApproveRequestForm from './WMS/WarehouseApproveRequestForm';
import EditManyProductForm from './WMS/EditManyProductForm';

// Manufacturing
import ProcessTemplateForm from './MMS/ProcessTemplateForm';
import ManufacturingOrderForm from './MMS/ManufacturingOrderForm';
import MaterialYieldForm from './MMS/MaterialYieldForm';
import HandleProductFromManufacForm from './MMS/HandleProductForm';
import ManufacturingOrderInfoForm from './MMS/ManufacturingOrderInfoForm';
import EmployeeAssignmentForm from './MMS/EmployeeAssignmentForm';
import StepInfoForm from './MMS/StepInfoForm';
import ProcessInfoForm from './MMS/ProcessInfoForm';
import EditManufacturingMaterialForm from './MMS/EditManufacturingMaterialForm';
import MeasurementTypeForm from './MMS/MeasurementTypeForm';
import ProcessTemplateResultingFunctionTypeForm from './MMS/ProcessTemplateResultingFunctionForm';
import MOTemplateForm from './MMS/MOTemplateForm';
import MOFromTemplateLoadingForm from './MMS/MOFromTemplateLoadingForm';
import ProductAndMaterialSelectForm from './MMS/ProductAndMaterialSelectorForm';
import AddAsMaterialForm from './MMS/AddAsMaterialForm';
import MeasurementFilledForm from './MMS/MeasurementFilledForm';
import MaterialWithdrawForm from './MMS/MaterialWithdrawForm';
import ProductBomForm from './MMS/ProductBomForm';
import BomCheckingForm from './MMS/BomCheckingForm';
import ReturnMaterialForm from './MMS/ReturnMaterialForm';
import MaterialSearchAndWithdrawForm from './MMS/MaterialSearchAndWithdrawForm';
import BaseProjectForm from './MMS/BaseProjectForm';
import RequestAddWarehouseForm from './MMS/RequestAddWarehouseForm';
import DashboardElementForm from './MMS/DashboardElementForm';
import MMSDashboardForm from './MMS/MMSDashboardForm';
import ProcessTemplateNewForm from './MMS/ProcessTemplateNewForm';
import RecieveMaterialForm from './MMS/RecieveMaterialForm';
import SplittingProcessForm from './MMS/SplittingProcessForm';
import MergingProcessForm from './MMS/MergingProcessForm';
import ProcessTemplateTableForm from './MMS/ProcessTemplateTableForm';

// Sales and Purchase Management
import OrderForm from './SPM/OrderForm';
import PaymentTypeForm from './SPM/PaymentTypeForm';
import ShippingTypeForm from './SPM/ShippingTypeForm';
import SelectProductForm from './SPM/SelectProductForm';
import DecreaseOrderForm from './SPM/DecreaseOrderForm';
import OrderSelectMaterialForm from './SPM/SelectMaterialForm';
import OrderTotalSummaryForm from './SPM/TotalSummaryForm';

// Information
import InformationForm from './Information/Information';
import AvailableModuleForm from './Information/AvailableModuleForm';
import NotifySettingForm from './Information/NotifySettingForm';
import QuickMenuForm from './Information/QuickMenuForm';
import ExternalSoftwareSettingForm from './Information/ExternalSoftwareSettingForm';
import PDFReportForm from './Information/PDFReportForm';

// Purchase Management
import MaterialRequestForm from './PMS/MaterialRequestForm';
import SelectMaterialForm from './PMS/SelectMaterialForm';
import ApprovalForm from './PMS/ApprovalForm';
import SelectMaterialRequestForm from './PMS/SelectMaterialRequestForm';
import PurchaseOrderForm from './PMS/PurchaseOrderForm';
import EditMaterialInPurhaseOrderForm from './PMS/EditMaterialInPurhaseOrderForm';
import PMSAddMaterialForm from './PMS/PMSAddMaterialForm';

// Knowledge Management
import UnitForm from './KM/UnitForm';
import ConversionForm from './KM/ConversionForm';
import OrganizationConstantForm from './KM/OrganizationConstantForm';
import ResultingFunctionTypeForm from './KM/ResultingFunctionTypeForm';
import AddToWarehouseForm from './MMS/AddToWarehouseForm';
import MachineForm from './MAMS/MachineForm';
import MMSSelectMaterialForm from './MMS/SelectMaterialForm';

export {
  AddAsMaterialForm,
  AddMaterialForm,
  AddProductForm,
  AddToWarehouseForm,
  ApprovalForm,
  ApprovalMaterialWithdrawForm,
  AvailableModuleForm,
  BaseProjectForm,
  BomCheckingForm,
  ConversionForm,
  CustomerForm,
  CustomerTypeForm,
  DashboardElementForm,
  DecreaseMaterialForm,
  DecreaseOrderForm,
  DecreaseProductForm,
  DepartmentForm,
  EditManufacturingMaterialForm,
  EditManyProductForm,
  EditMaterialInPurhaseOrderForm,
  EmployeeAssignmentForm,
  EmployeeForm,
  ExternalSoftwareSettingForm,
  HandleProductFromManufacForm,
  InformationForm,
  MachineForm,
  ManufacturingOrderForm,
  ManufacturingOrderInfoForm,
  MaterialForm,
  MaterialRequestForm,
  MaterialSearchAndWithdrawForm,
  MaterialStockForm,
  MaterialTransactionTypeForm,
  MaterialTypeForm,
  MaterialWithdrawForm,
  MaterialYieldForm,
  MeasurementFilledForm,
  MeasurementTypeForm,
  MergingProcessForm,
  MMSDashboardForm,
  MMSSelectMaterialForm,
  MOFromTemplateLoadingForm,
  MOTemplateForm,
  MoveMaterialForm,
  MultiPayrollForm,
  NotifySettingForm,
  OrderForm,
  OrderSelectMaterialForm,
  OrderTotalSummaryForm,
  OrganizationConstantForm,
  PAMSSettingForm,
  PaymentTypeForm,
  PDFReportForm,
  PlaceForm,
  PMSAddMaterialForm,
  ProcessInfoForm,
  ProcessTemplateForm,
  ProcessTemplateNewForm,
  ProcessTemplateResultingFunctionTypeForm,
  ProcessTemplateTableForm,
  ProductAndMaterialSelectForm,
  ProductBomForm,
  ProductForm,
  ProductStockForm,
  ProductTransactionTypeForm,
  ProductTypeForm,
  PurchaseOrderForm,
  QuickMenuForm,
  RecieveMaterialForm,
  RequestAddWarehouseForm,
  ResultingFunctionTypeForm,
  ReturnMaterialForm,
  RoleTypeForm,
  SelectMaterialForm,
  SelectMaterialRequestForm,
  SelectProductForm,
  ShippingTypeForm,
  SplittingProcessForm,
  StepInfoForm,
  UnitForm,
  UserForm,
  WarehouseApproveRequestForm,
  WarehouseForm,
};

export default {
  AddAsMaterialForm,
  AddMaterialForm,
  AddProductForm,
  AddToWarehouseForm,
  ApprovalForm,
  ApprovalMaterialWithdrawForm,
  AvailableModuleForm,
  BaseProjectForm,
  BomCheckingForm,
  ConversionForm,
  CustomerForm,
  CustomerTypeForm,
  DecreaseMaterialForm,
  DecreaseOrderForm,
  DecreaseProductForm,
  DepartmentForm,
  EditManufacturingMaterialForm,
  EditMaterialInPurhaseOrderForm,
  EmployeeAssignmentForm,
  EmployeeForm,
  HandleProductFromManufacForm,
  InformationForm,
  MachineForm,
  ManufacturingOrderForm,
  ManufacturingOrderInfoForm,
  MaterialForm,
  MaterialRequestForm,
  MaterialStockForm,
  MaterialTransactionTypeForm,
  MaterialTypeForm,
  MaterialWithdrawForm,
  MaterialYieldForm,
  MeasurementFilledForm,
  MeasurementTypeForm,
  MOFromTemplateLoadingForm,
  MOTemplateForm,
  MultiPayrollForm,
  NotifySettingForm,
  OrderForm,
  OrderSelectMaterialForm,
  OrderTotalSummaryForm,
  OrganizationConstantForm,
  PAMSSettingForm,
  PaymentTypeForm,
  PlaceForm,
  PMSAddMaterialForm,
  ProcessInfoForm,
  ProcessTemplateForm,
  ProcessTemplateResultingFunctionTypeForm,
  ProductAndMaterialSelectForm,
  ProductBomForm,
  ProductForm,
  ProductStockForm,
  ProductTransactionTypeForm,
  ProductTypeForm,
  PurchaseOrderForm,
  QuickMenuForm,
  ResultingFunctionTypeForm,
  ReturnMaterialForm,
  RoleTypeForm,
  SelectMaterialForm,
  SelectMaterialRequestForm,
  SelectProductForm,
  ShippingTypeForm,
  StepInfoForm,
  UnitForm,
  UserForm,
  WarehouseForm,
  MaterialSearchAndWithdrawForm,
  RequestAddWarehouseForm,
  WarehouseApproveRequestForm,
  DashboardElementForm,
  MMSDashboardForm,
  ProcessTemplateNewForm,
  ExternalSoftwareSettingForm,
  RecieveMaterialForm,
  PDFReportForm,
  MoveMaterialForm,
  SplittingProcessForm,
  MergingProcessForm,
  ProcessTemplateTableForm,
  EditManyProductForm,
  MMSSelectMaterialForm,
};
