import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Alert, AlertTitle, Breadcrumbs, Button, Card } from '@mui/material';
import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import _ from 'lodash';
import PropTypes from 'prop-types';

import 'dayjs/locale/th';

import * as actions from '../../../redux/actions';
import StepInfoForm from '../../Forms/MMS/StepInfoForm';
import NameBox from '../Common/NameBox';

dayjs.extend(buddhistEra);

/**
 * @function StepInfoBox
 * @memberof Component/Card
 * @description รายละเอียดของงาน(Work) หรือ Step รวมถึงสามารถแก้ไขรายละเอียดของ Step นั้นๆ ได้ด้วย
 *  มีการส่งค่าระดับ API ข้างใน
 * @returns {React.Component} React Component
 */
const StepInfoBox = ({ step }) => {
  const dispatch = useDispatch();
  const [isEditorMode, setIsEditorMode] = useState(false);
  const information = useSelector((state) => state.information);
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: step?.name,
      description: step?.description,
      responsible: {
        employee: step?.responsible?.employee?._id,
        department: step?.responsible?.department?._id,
      },
      material_associate: step?.material_associate,
    },
  });

  const onSubmit = async (data) => {
    try {
      await dispatch(actions.stepPut(step?._id, data));
      await dispatch(actions.stepGet(step?._id));
      setIsEditorMode(false);
    } catch (error) {
      window.alert('แก้ไขข้อมูลงานไม่สำเร็จ');
    }
  };

  const generatePathForMOView = (manufacturingOrderId) => {
    if (information?.setting?.mms?.dashboard?.showDashboardC) {
      return `/mms/manufacturing-orders-c/view/${manufacturingOrderId}`;
    }
    return `/mms/manufacturing-orders/view/${manufacturingOrderId}`;
  };

  const renderStepHandler = () => {
    if (
      step?.responsible?.employee &&
      !_.isEmpty(step?.responsible?.employee)
    ) {
      return (
        <div className="my-2">
          <NameBox user={step?.responsible?.employee} />
        </div>
      );
    }
    if (step?.completed) {
      return (
        <div className="flex gap-2 my-2">
          <div className="self-center text-green-500">
            สำเร็จโดยผู้ควบคุมการผลิต
          </div>
        </div>
      );
    }
    if (step?.deleted) {
      return (
        <div className="flex gap-2 my-2">
          <div className="self-center text-red-500">
            งานถูกยกเลิกก่อนที่จะมีผู้รับผิดชอบ
          </div>
        </div>
      );
    }

    return (
      <div className="flex gap-2 my-2">
        <div className="self-center">ยังไม่มีผู้รับผิดชอบในงานนี้</div>
      </div>
    );
  };

  const renderDisplayMode = () => (
    <div className="flex flex-wrap">
      <div className="w-full px-2 py-2 ">
        <h5 className="text-xl font-semibold font-display">{step?.name}</h5>
      </div>{' '}
      {step?.completed && (
        <div className="w-full p-2">
          <Alert severity="success" className="mt-2">
            <AlertTitle>
              <b>งานชิ้นนี้สำเร็จแล้ว</b> ปิดงานเมื่อ{'  '}
              {dayjs(step?.updatedAt).format('D MMMM BBBB เวลา HH.mm น.')}{' '}
            </AlertTitle>
          </Alert>{' '}
        </div>
      )}{' '}
      {step?.deleted ||
        (step?.process?.deleted && (
          <div className="w-full p-2">
            <Alert severity="error" className="mt-2">
              <AlertTitle>
                <b>งานชิ้นนี้ได้ถูกยกเลิกไปแล้ว </b> เมื่อ{'  '}
                {dayjs(step?.updatedAt).format(
                  'D MMMM BBBB เวลา HH.mm น.',
                )}{' '}
              </AlertTitle>
            </Alert>{' '}
          </div>
        ))}
      {!step.completed &&
        !step?.deleted &&
        !step?.process?.completed &&
        !step?.process?.deleted &&
        step?.process?.current?.index < step?.index && (
          <div className="w-full p-2">
            <Alert severity="info" className="mt-2">
              <AlertTitle>
                <b>การผลิตนี้ยังไม่ถึงขั้นตอนของคุณ</b> เมื่องานถึงขั้นตอนของคุณ
                คุณจะสามารถเริ่มงานได้{'  '}
              </AlertTitle>
            </Alert>{' '}
          </div>
        )}
      <div className="w-full py-2 px-2 font-system">
        {step?.description || ''}
      </div>
      <div className="w-full  py-2 px-2  ">
        <div className="flex flex-wrap">
          <div className="lg:w-1/3">
            <span className="font-semibold font-display">ลูกค้า</span>{' '}
            {step?.process?.manufacturing_order?.customer?.type_code || ''}{' '}
            {step?.process?.manufacturing_order?.customer?.name || '-'}
          </div>{' '}
          <div className="lg:w-1/3">
            <span className="font-semibold font-display">
              วันที่ต้องการสินค้า
            </span>{' '}
            {step?.process?.manufacturing_order?.expected_date
              ? dayjs(step?.process?.manufacturing_order?.expected_date).format(
                  'D MMM BBBB',
                )
              : ''}
          </div>
        </div>
      </div>
      <div className="w-full lg:w-2/6 py-2 px-2 font-semibold font-display ">
        ผู้รับผิดชอบ
        {renderStepHandler()}
      </div>
      <div className="w-full lg:w-2/6 py-2 px-2 font-semibold font-display ">
        แผนก
        <div className="my-2 font-normal">
          {step?.responsible?.department?.name || '-'}
        </div>{' '}
      </div>
    </div>
  );

  const renderEditorMode = () => (
    <div className="my-2">
      <form onSubmit={handleSubmit(onSubmit)}>
        <StepInfoForm
          Controller={Controller}
          control={control}
          errors={errors}
          step={step}
        />
        <div className="flex justify-end gap-1 mt-2">
          <Button
            color="success"
            variant="contained"
            size="small"
            type="submit"
          >
            บันทึก
          </Button>
          <Button
            color="inherit"
            size="small"
            variant="contained"
            type="button"
            onClick={() => setIsEditorMode(false)}
          >
            ยกเลิก
          </Button>
        </div>
      </form>
    </div>
  );
  return (
    <div>
      <Card className="px-3 md:px-4 py-2">
        <div className="flex justify-between  ">
          <Breadcrumbs separator="›" className="py-2">
            <Link
              to={generatePathForMOView(
                step?.process?.manufacturing_order?._id,
              )}
              className="font-display text-blue-600 bg-blue-50 p-1 rounded-md hover:font-semibold  hover:text-blue-800  cursor-pointer text-xs md:text-sm"
            >
              {step?.process?.manufacturing_order?.prefix}
              {step?.process?.manufacturing_order?.running_number}{' '}
            </Link>
            <div className="font-display text-sm md:text-base">
              <span className="font-bold">
                {step?.process?.produce_material_instead
                  ? step?.process?.product_as_material?.type_code
                  : step?.process?.product?.type_code}
              </span>{' '}
              <span>
                {' '}
                {step?.process?.produce_material_instead
                  ? step?.process?.product_as_material?.name
                  : step?.process?.product?.name}
              </span>{' '}
              <span className="font-bold">
                {step?.process?.produce_base_project_instead &&
                  step?.process?.base_project?.type_code}
              </span>{' '}
              <span>
                {' '}
                {step?.process?.produce_base_project_instead &&
                  step?.process?.base_project?.name}
              </span>{' '}
              {step?.process?.quantity > 0 && (
                <span>
                  จำนวน {step?.process?.quantity}{' '}
                  {step?.process?.additional_unit &&
                  step?.process?.additional_unit !== ''
                    ? step?.process?.additional_unit
                    : step?.process?.produce_material_instead
                    ? step?.process?.product_as_material?.unit
                    : step?.process?.product?.unit}
                  {(!step?.process?.additional_unit &&
                    step?.process?.produce_base_project_instead &&
                    step?.process?.base_project?.unit) ||
                    ''}
                </span>
              )}
            </div>
          </Breadcrumbs>
          <div>
            <Button
              variant="contained"
              color="warning"
              size="small"
              disabled={isEditorMode || step?.completed || step?.deleted}
              onClick={() => setIsEditorMode(true)}
            >
              แก้ไข
            </Button>
          </div>
        </div>
        {isEditorMode ? renderEditorMode() : renderDisplayMode()}
      </Card>
    </div>
  );
};

StepInfoBox.propTypes = {
  step: PropTypes.object,
};

export default StepInfoBox;
