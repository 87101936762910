import React, { useEffect, useState } from 'react';
import {
  ChevronDown,
  ChevronUp,
  Grid as GridIcon,
  List as ListIcon,
} from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import {
  Button,
  Card,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import {
  BackButton,
  CardListProductOrMaterial,
  Error,
  ExportExcelContainer,
  LoadingLinear,
  ViewTitle,
} from '@/components';
import { app as appConfig } from '@/configs';
import * as actions from '@/redux/actions';
import { DISPLAY_TYPE } from '@/utils/constants';
import {
  converseMaterialOrProductUnit,
  currencyFormatter,
} from '@/utils/functions';
import { ProductUtil } from '@/utils/modelutils';

function DetailProductStock({ title, subtitle }) {
  const dispatch = useDispatch();
  const warehouse = useSelector((state) => state.warehouse);
  const product = useSelector((state) => state.product);
  const productType = useSelector((state) => state.productType);
  const { id } = useParams();
  const [searchTerm, setSearchTerm] = useState('');
  const [name, setName] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(5);
  const [total, setTotal] = useState(undefined);
  const [selectedProductType, setSelectedProductType] = useState('');
  const [orderByField, setOrderByField] = useState('type_code');
  const [orderBy, setOrderBy] = useState('asc');
  const [selectedView, setSelectedView] = useState(
    DISPLAY_TYPE.CARD.status_code,
  );
  const history = useHistory();

  useEffect(() => {
    dispatch(actions.productReset());
    const getWarehouse = async () => {
      try {
        await dispatch(actions.warehouseGet(id));
      } catch (error) {
        console.log('Cannot Get Warehouse or non specify warehouse');
      }
    };
    getWarehouse();
    dispatch(
      actions.productAll({
        name,
        size,
        page,
        warehouse: id || '',
        selectProductTypeType: selectedProductType,
        fetchStockLot: true,
        orderByField,
        orderBy,
      }),
    );
    dispatch(actions.productTypeAll({ size: appConfig.maxFetchSize }));
    return () => {};
  }, [name, page, size, selectedProductType, orderBy, orderByField]);

  useEffect(() => {
    setTotal(product?.total);
    return () => {};
  }, [product]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchTerm);
      setPage(1);
    }, 700);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const handleSetSelectView = (tempSelectedView) => {
    if (tempSelectedView !== null) {
      if (tempSelectedView === DISPLAY_TYPE.CARD.status_code) {
        setSize(20);
        setPage(1);
      }
      setSelectedView(tempSelectedView);
    }
  };

  const renderTitle = () => (
    <ViewTitle title={title + (warehouse?.name || '')} subtitle={subtitle} />
  );

  const handlePushToDetailProductStock = async (productId) => {
    history.push(`/wms/product-stock/${productId}/${id || ''}`);
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const productQuery = ProductUtil.query({ id, selectedProductType });

  const renderAddButtom = () => (
    <div className="flex flex-row justify-end pb-4">
      <div>
        <Button
          variant="contained"
          onClick={() => history.push(`/wms/product-stock/create/${id}`)}
        >
          เพิ่ม/ลด สินค้า
        </Button>
      </div>
    </div>
  );

  const renderSearch = () => (
    <Card>
      <div className="p-4 flex flex-row">
        <div className="w-full md:w-1/2">
          <TextField
            label="ค้นหา"
            fullWidth
            size={'small'}
            id="outlined-start-adornment"
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <i className="fas fa-search"></i>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className="lg:w-3/12 px-2">
          <FormControl fullWidth>
            <InputLabel>ประเภทสินค้า</InputLabel>{' '}
            <Select
              fullWidth
              defaultValue=""
              label="ประเภทสินค้า"
              placeholder="ประเภทสินค้า"
              size="small"
              value={selectedProductType}
              onChange={(event) => setSelectedProductType(event.target.value)}
            >
              <MenuItem key={0} value={''}>
                ทุกประเภท
              </MenuItem>
              {_.map(productType?.rows, (eachProductType, index) => (
                <MenuItem key={index} value={eachProductType?._id}>
                  {eachProductType?.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="lg:w-2/12 flex justify-end ">
          <ExportExcelContainer
            columnList={ProductUtil.columns}
            currentData={product?.rows}
            dataAPIEndPoint="product"
            dataQuery={productQuery}
            sheetName="ProductStock"
          />
        </div>
        <div className="lg:w-1/12 justify-end flex">
          <ToggleButtonGroup
            value={selectedView}
            exclusive
            size="small"
            onChange={(event, value) => {
              handleSetSelectView(value);
            }}
          >
            <ToggleButton value={DISPLAY_TYPE.TABLE.status_code}>
              <ListIcon size="16" />
            </ToggleButton>
            <ToggleButton value={DISPLAY_TYPE.CARD.status_code}>
              <GridIcon size="16" />
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
      </div>
    </Card>
  );

  const renderTable = () => (
    <div className="my-2">
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <div className="font-bold">ลำดับที่</div>
                </TableCell>
                <TableCell
                  onClick={() => {
                    setOrderByField('type_code');
                    if (orderBy === 'asc') {
                      setOrderBy('desc');
                    } else {
                      setOrderBy('asc');
                    }
                  }}
                >
                  <div className="font-bold flex gap-2">
                    ชื่อสินค้า
                    {orderByField === 'type_code' && orderBy === 'desc' ? (
                      <ChevronDown size="16" className="self-center" />
                    ) : (
                      <ChevronUp size="16" className="self-center" />
                    )}
                  </div>
                </TableCell>
                <TableCell
                  onClick={() => {
                    setOrderByField('total');
                    if (orderBy === 'asc') {
                      setOrderBy('desc');
                    } else {
                      setOrderBy('asc');
                    }
                  }}
                >
                  <div className="font-bold flex gap-2">
                    {' '}
                    คงเหลือ
                    {orderByField === 'total' && orderBy === 'desc' ? (
                      <ChevronDown size="16" className="self-center" />
                    ) : (
                      <ChevronUp size="16" className="self-center" />
                    )}
                  </div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ดำเนินการ</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(product?.rows) ? (
                product?.rows.map((row, index) => (
                  <TableRow
                    key={row._id}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {(page - 1) * size + index + 1}
                    </TableCell>
                    <TableCell>
                      <div>
                        <span className="font-display font-bold">
                          {row?.type_code}
                        </span>{' '}
                        <span>{row?.name}</span>
                      </div>
                    </TableCell>
                    <TableCell>
                      {currencyFormatter.format(row?.total)}{' '}
                      {row?.use_unit_conversion
                        ? row?.unit_input?.short_sign
                        : row?.unit}
                      {row?.use_unit_conversion && (
                        <div>
                          ({' '}
                          {currencyFormatter.format(
                            converseMaterialOrProductUnit(row, row?.total),
                          )}{' '}
                          {row?.unit_output?.short_sign} )
                        </div>
                      )}
                    </TableCell>
                    <TableCell>
                      {' '}
                      <div className="flex flex-row flex-wrap gap-1">
                        <Button
                          variant="contained"
                          color={'info'}
                          size={'small'}
                          onClick={() =>
                            handlePushToDetailProductStock(row._id)
                          }
                        >
                          รายละเอียด
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <div className="text-center">ไม่มีข้อมูล</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          rowsPerPageOptions={[5, 10, 20, 50, 100]}
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={page - 1}
          count={total || 1}
          rowsPerPage={size}
          onPageChange={handleChangePage}
        />
      </Paper>
    </div>
  );

  const renderCard = () => (
    <div className="my-2">
      <CardListProductOrMaterial
        payload={product}
        page={page}
        size={size}
        total={total}
        setSize={setSize}
        handlePushToStock={handlePushToDetailProductStock}
      />
    </div>
  );

  const renderProduct = () => (
    <div>
      {selectedView === DISPLAY_TYPE.TABLE.status_code
        ? renderTable()
        : renderCard()}
    </div>
  );

  if (!product?.isLoading && !product?.isCompleted) {
    return <Error message={product?.message} />;
  }

  return (
    <div>
      {renderTitle()}
      <div className="flex justify-between">
        <div className="flex flex-row justify-start pb-4">
          <div>
            <BackButton />
          </div>
        </div>
        <div>{renderAddButtom()}</div>
      </div>
      {renderSearch()}
      {product?.isLoading ? (
        <div>
          <LoadingLinear label="กำลังดาวน์โหลดข้อมูล" />
        </div>
      ) : (
        renderProduct()
      )}
    </div>
  );
}

DetailProductStock.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

DetailProductStock.defaultProps = {
  title: '',
  subtitle: '',
};

export default DetailProductStock;
