// DATA MUST BE THE SAME BOTH FRONTEND AND BACKEND

const id = "precasting";
const name = "BJ Precast - Precasting and Metal Devide";
const slug = "pr";
const information = {
  title: "ตัดเหล็กและงานพรีคาสติ้ง",
  description: "ระบบบาร์คัดเหล็ก และบันทึกการตัดเหล็ก ของ BJ Precast",
  icon: "fas fa-cut",
  color: "bg-green-700",
};

const version = "0.1.0";
const moduleLevel = true;

const plugins = {
  id,
  name,
  slug,
  version,
  information,
  moduleLevel,
};

export default plugins;
