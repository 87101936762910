import React, { useState } from 'react';
import { Controller, useFieldArray } from 'react-hook-form';
import {
  Autocomplete,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { WORK_SHIFT } from '../../../utils/constants';
import { DesktopDatepicker } from '../../DatePicker';
import MeasurementAddingRenderingComponent from '../../Measurement/MeasurementAddingRenderingComponent';

export function ProcessInfoForm({
  errors,
  control,
  watch,
  information,
  customer,
  currentProcess,
  machine,
}) {
  const [reload, setReload] = useState(false);
  const { fields, remove, append } = useFieldArray({
    control,
    name: 'other_customers',
  });
  return (
    <div>
      {!_.isEmpty(customer?.rows) && (
        <div className="w-full py-1 px-2">
          <Controller
            name="customer"
            control={control}
            defaultValue={currentProcess?.customer}
            render={({ field }) => (
              <Autocomplete
                size={'small'}
                {...field}
                onChange={(e, newValue) => {
                  field.onChange(newValue);
                  setReload(!reload);
                }}
                defaultValue={field.value}
                options={customer?.rows}
                // prettier-ignore
                getOptionLabel={(option) => `${option?.type_code} | ${option?.name}`
                        }
                renderInput={(params) => (
                  <TextField {...params} label="เลือกลูกค้า" />
                )}
              />
            )}
          />
        </div>
      )}
      {!_.isEmpty(customer?.rows) && (
        <div className="w-full py-1 px-2">
          {_.map(fields, (eachField, fieldIndex) => (
            <div className="w-full flex py-1" key={eachField?.id}>
              <div className="w-10/12 px-1">
                <Controller
                  name={`other_customers[${fieldIndex}]`}
                  control={control}
                  defaultValue={currentProcess?.other_customers?.[fieldIndex]}
                  render={({ field }) => (
                    <Autocomplete
                      size={'small'}
                      {...field}
                      onChange={(e, newValue) => {
                        field.onChange(newValue);
                        setReload(!reload);
                      }}
                      options={customer?.rows}
                      // prettier-ignore
                      getOptionLabel={(option) => `${option?.type_code} | ${option?.name}`
                        }
                      required
                      renderInput={(params) => (
                        <TextField {...params} label="เลือกลูกค้า" />
                      )}
                    />
                  )}
                />
              </div>
              <div className="w-2/12 px-1">
                <Button
                  size="small"
                  color="error"
                  variant="contained"
                  onClick={() => remove(fieldIndex)}
                >
                  ลบ
                </Button>
              </div>
            </div>
          ))}
          <Button size="small" variant="outlined" onClick={() => append({})}>
            เพิ่มลูกค้าคนอื่น
          </Button>
        </div>
      )}
      <div className="flex flex-wrap">
        <div className="w-full  px-1 py-2">
          <Controller
            name="remark"
            control={control}
            rules={{ required: false }}
            render={({ field }) => (
              <TextField
                {...field}
                label="รายละเอียด"
                fullWidth
                size="small"
                multiline
                rows={3}
              />
            )}
          />{' '}
        </div>
      </div>
      {information?.setting?.mms?.manufacturingOrder?.showPrice && (
        <div className="w-full flex flex-wrap">
          <div className="w-full lg:w-1/2 px-1 py-2">
            <Controller
              name={'price_per_unit'}
              control={control}
              defaultValue={currentProcess?.price_per_unit}
              rules={{ required: false }}
              render={({ field }) => (
                <TextField
                  {...field}
                  onChange={(e) => {
                    field.onChange(e.target.value);
                    setReload(!reload);
                  }}
                  label="ราคาต่อหน่วย"
                  fullWidth
                  defaultValue={1}
                  inputProps={{
                    min: 0,
                  }}
                  size="small"
                />
              )}
            />{' '}
          </div>
          <div className="w-full lg:w-1/2 px-1 py-2">
            <TextField
              label="ราคารวม"
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                readOnly: true,
              }}
              value={(
                (parseFloat(watch('quantity')) || 1) *
                parseFloat(watch('price_per_unit') || 0)
              ).toFixed(2)}
              size="small"
            />
          </div>
        </div>
      )}
      <div className="w-full flex flex-wrap">
        <div className="w-full lg:w-6/12 px-1 py-2">
          <Controller
            name={'quantity'}
            control={control}
            defaultValue={currentProcess?.quantity || 0}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                label="จำนวน"
                fullWidth
                inputProps={{
                  min: 0,
                }}
                size="small"
                helperText={'หากไม่ต้องการระบุจำนวนกรุณาใส่เป็นตัวเลข 0'}
              />
            )}
          />
        </div>
        <div className="w-full lg:w-6/12 px-1 py-2">
          <Controller
            name={'use_additional_unit'}
            defaultValue={currentProcess?.additional_unit}
            control={control}
            render={({ field }) => (
              <FormControlLabel
                label="เปลี่ยนหน่วย"
                control={<Checkbox {...field} defaultChecked={field.value} />}
              />
            )}
          />
          {watch('use_additional_unit') && (
            <Controller
              name={'additional_unit'}
              defaultValue={currentProcess?.additional_unit}
              control={control}
              rules={{ required: false }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="หน่วย"
                  fullWidth
                  size="small"
                  helperText={errors.additional_unit && 'กรุณาใส่ข้อมูล'}
                />
              )}
            />
          )}
        </div>
      </div>
      {information?.setting?.mms?.machinery?.attachMachine && (
        <div className="w-full flex flex-wrap">
          <div className="w-full lg:w-1/2 px-1 py-2">
            {!_.isEmpty(machine?.rows) && (
              <Controller
                name={'machine'}
                control={control}
                defaultValue={currentProcess?.machine}
                rules={{ required: false }}
                render={({ field }) => (
                  <Autocomplete
                    size={'small'}
                    {...field}
                    onChange={(e, newValue) => {
                      field.onChange(newValue);
                    }}
                    defaultValue={field.value}
                    options={machine?.rows}
                    // prettier-ignore
                    getOptionLabel={(option) => ` ${option?.name}`
                        }
                    renderInput={(params) => (
                      <TextField {...params} label="เครื่องจักร" />
                    )}
                  />
                )}
              />
            )}{' '}
          </div>
        </div>
      )}
      {information?.setting?.mms?.machinery?.attachShiftWorkSelector && (
        <div className="w-full flex flex-wrap">
          <div className="w-full lg:w-1/2 px-1 py-2">
            <Controller
              name={'work_shift'}
              control={control}
              defaultValue={currentProcess?.work_shift}
              rules={{ required: false }}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel>ช่วงเวลาทำงาน</InputLabel>
                  <Select
                    {...field}
                    size="small"
                    fullWidth
                    label="ช่วงเวลาทำงาน"
                  >
                    {_.map(WORK_SHIFT, (eachShift, index) => (
                      <MenuItem key={index} value={eachShift?.status_code}>
                        {eachShift?.description}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
          </div>
        </div>
      )}{' '}
      <div className="w-full flex flex-wrap">
        {information?.setting?.mms?.showBatchNumber && (
          <div className="w-full lg:w-1/2 px-1 py-2">
            <Controller
              name={'batch_no'}
              control={control}
              defaultValue={currentProcess?.batch_no}
              rules={{ required: false }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="หมายเลข Batch"
                  fullWidth
                  size="small"
                />
              )}
            />
          </div>
        )}
        {information?.setting?.mms?.showManufacturingDate && (
          <div className="w-full lg:w-1/2 px-1 py-2">
            <Controller
              name={'manufacturing_date'}
              control={control}
              defaultValue={currentProcess?.manufacturing_date}
              render={({ field }) => (
                <DesktopDatepicker
                  {...field}
                  fullWidth
                  size="small"
                  label="วันผลิต"
                />
              )}
            />
          </div>
        )}{' '}
        {information?.setting?.mms?.showExpireDate && (
          <div className="w-full lg:w-1/2 px-1 py-2">
            <Controller
              name={'expired_date'}
              control={control}
              defaultValue={currentProcess?.expired_date}
              render={({ field }) => (
                <DesktopDatepicker
                  {...field}
                  fullWidth
                  size="small"
                  label="วันหมดอายุ"
                />
              )}
            />
          </div>
        )}
        {!_.isEmpty(information?.setting?.mms?.processAdditionalAttribute) &&
          _.map(
            information?.setting?.mms?.processAdditionalAttribute,
            (each, marcIndex) => (
              <div className="w-full lg:w-1/2 px-1">
                <MeasurementAddingRenderingComponent
                  control={control}
                  measurementControllerName={`additional_attribute.${each?.name}`}
                  measurementType={{
                    data_type: each?.type,
                    label: each?.name,
                  }}
                  defaultValue={
                    currentProcess?.additional_attribute?.[each?.name]?.value
                  }
                  watch={watch}
                  key={marcIndex}
                />
              </div>
            ),
          )}
      </div>
    </div>
  );
}

ProcessInfoForm.propTypes = {
  errors: PropTypes.object,
  control: PropTypes.object,
  watch: PropTypes.func,
  information: PropTypes.object,
  customer: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  currentProcess: PropTypes.object,
  machine: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
};

export default ProcessInfoForm;
