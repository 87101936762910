import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { gql } from 'graphql-request';
import { Link, useHistory } from 'react-router-dom';
import _ from 'lodash';
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  TextField,
} from '@mui/material';
import dayjs from 'dayjs';

import {
  ViewTitle,
  Loading,
  Error,
  BackButton,
  MultiUnitProdOrMatBox,
} from '../../components';
import * as actions from '../../redux/actions';
import graphqlClient from '../../configs/graphqlClient';
import { WAREHOUSE_STOCK_REQ_STATUS } from '../../utils/constants';

/**
 * @function WarehouseStockRequests
 * @memberof WMS
 * @description รายการ การขอใส่สินค้าลงคลังสินค้าจากการผลิต ถ้าหากเปิดใช้การขออนุมัติ
 * เช่น ของ Letter P
 * @author Theethawat Savastham
 */

export default function WarehouseStockRequests({ title, subtitle }) {
  const dispatch = useDispatch();
  const warehouseStockRequest = useSelector(
    (state) => state.warehouseStockRequest,
  );
  const history = useHistory();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState();
  const [cancleModalOpen, setCancleModalOpen] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState();
  const [rejectComment, setRejectComment] = useState('');

  const query = gql`
    query FindWarehouseStockRequests(
      $warehouseStockRequestInput: WarehouseStockRequestInput
    ) {
      findWarehouseStockRequests(input: $warehouseStockRequestInput) {
        currPage
        lastPage
        total
        rows {
          _id
          requester {
            _id
            firstname
            lastname
          }
          approver {
            _id
            firstname
            lastname
          }
          manufacturing_order {
            _id
            running_number
          }
          products {
            product {
              _id
              type_code
              name
              use_unit_conversion
              unit
              conversion_from {
                unit_a
                unit_b
                conversion_factor
              }
              conversion_to {
                unit_b
                unit_a
                conversion_factor
              }
              unit_input {
                _id
                short_sign
                name
              }
              unit_output {
                _id
                short_sign
                name
              }
            }
            quantity
          }
          status
          createdAt
        }
      }
    }
  `;

  const queryDataFromServer = async () => {
    const queryResult = await graphqlClient.request(query, {
      warehouseStockRequestInput: { page, size },
    });
    const warehouseStockRequestData = queryResult?.findWarehouseStockRequests;
    dispatch(actions.warehouseStockRequestStateSet(warehouseStockRequestData));
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [page, size]);

  useEffect(() => {
    if (warehouseStockRequest?.rows) {
      setTotal(warehouseStockRequest?.total);
    }

    return () => {};
  }, [warehouseStockRequest]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };

  const handleReject = async () => {
    try {
      await dispatch(
        actions.warehouseStockRequestPut(selectedRequest?._id, {
          status: WAREHOUSE_STOCK_REQ_STATUS.REJECT.status_code,
          approver_remark: rejectComment,
        }),
      );

      queryDataFromServer();
      setSelectedRequest();
      setRejectComment('');
    } catch (error) {
      alert(`ปฏิเสธไม่สำเร็จ ${error?.message}`);
    } finally {
      setCancleModalOpen(false);
    }
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const renderCancleDialog = () => (
    <Dialog
      open={cancleModalOpen}
      onClose={() => {
        setCancleModalOpen(false);
        setSelectedRequest();
        setRejectComment('');
      }}
    >
      <DialogTitle>ปฏิเสธคำขอเพิ่มเข้าคลังสินค้า</DialogTitle>
      <DialogContent>
        <TextField
          className="py-2 my-1"
          size="small"
          fullWidth
          label="เหตุผลการปฏิเสธ"
          onChange={(e) => setRejectComment(e.target.value)}
          multiline
          rows={3}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="error" onClick={handleReject}>
          ยืนยันการปฏิเสธ
        </Button>
        <Button
          variant="contained"
          color="inherit"
          onClick={() => {
            setCancleModalOpen(false);
            setSelectedRequest();
            setRejectComment('');
          }}
        >
          ยกเลิก
        </Button>
      </DialogActions>
    </Dialog>
  );

  const renderTable = () => (
    <div className="my-2">
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell>
                <div className="font-bold">ลำดับที่</div>
              </TableCell>{' '}
              <TableCell>
                <div className="font-bold">วันที่</div>
              </TableCell>
              <TableCell>
                <div className="font-bold">สินค้า</div>
              </TableCell>{' '}
              <TableCell>
                <div className="font-bold">คำสั่งผลิต</div>
              </TableCell>{' '}
              <TableCell>
                <div className="font-bold">ผู้ขอ</div>
              </TableCell>
              <TableCell>
                <div className="font-bold">สถานะ</div>
              </TableCell>
              <TableCell>
                <div className="font-bold">ดำเนินการ</div>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!_.isEmpty(warehouseStockRequest?.rows) ? (
              _.map(warehouseStockRequest?.rows, (row, index) => (
                <TableRow
                  key={index}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                  }}
                >
                  <TableCell component="th" scope="row">
                    <div className="text-xs">
                      {(page - 1) * size + index + 1}
                    </div>
                  </TableCell>
                  <TableCell>
                    {dayjs(row?.createdAt).format('D MMM BBBB HH:mm')}
                  </TableCell>
                  <TableCell>
                    {_.map(row?.products, (eachProduct, prodIndex) => (
                      <div key={`prod${prodIndex}`} className="my-1">
                        {prodIndex + 1}.{' '}
                        <b>{eachProduct?.product?.type_code || ''}</b>{' '}
                        {eachProduct?.product?.name || ''}
                        <MultiUnitProdOrMatBox
                          foundMaterial={eachProduct?.product}
                          quantity={eachProduct?.quantity}
                          noWrap
                        />
                      </div>
                    ))}
                  </TableCell>
                  <TableCell>
                    <Link
                      to={`/mms/manufacturing-orders/view/${row?.manufacturing_order?._id}`}
                      className="hover:underline hover:font-normal font-semibold"
                    >
                      {row?.manufacturing_order?.running_number || ''}
                    </Link>
                  </TableCell>
                  <TableCell>
                    {row?.requester?.firstname} {row?.requester?.lastname}
                  </TableCell>
                  <TableCell>
                    <div
                      className={`rounded-md p-1  ${
                        WAREHOUSE_STOCK_REQ_STATUS?.[row?.status]?.css || ''
                      }`}
                    >
                      {WAREHOUSE_STOCK_REQ_STATUS?.[row?.status]?.description}
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className="flex gap-1">
                      <Button
                        variant="contained"
                        color="success"
                        disabled={
                          row?.status ===
                            WAREHOUSE_STOCK_REQ_STATUS.SUCCESS.status_code ||
                          row?.status ===
                            WAREHOUSE_STOCK_REQ_STATUS.REJECT.status_code
                        }
                        onClick={() =>
                          history.push(
                            `/wms/warehouse-stock-request/approve/${row?._id}`,
                          )
                        }
                      >
                        อนุมัติ
                      </Button>
                      <Button
                        variant="contained"
                        color="error"
                        onClick={() => {
                          setSelectedRequest(row);
                          setCancleModalOpen(true);
                        }}
                        disabled={
                          row?.status ===
                            WAREHOUSE_STOCK_REQ_STATUS.SUCCESS.status_code ||
                          row?.status ===
                            WAREHOUSE_STOCK_REQ_STATUS.REJECT.status_code
                        }
                      >
                        ปฏิเสธ
                      </Button>
                    </div>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={6}>
                  <div className="text-center">ไม่มีข้อมูล</div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>{' '}
          <TablePagination
            page={page - 1}
            count={total || 1}
            rowsPerPage={size}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowPerPage}
          />
        </Table>
      </TableContainer>
    </div>
  );

  if (warehouseStockRequest.isLoading) {
    return <Loading />;
  }
  if (!warehouseStockRequest.isLoading && warehouseStockRequest.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div className="my-2">
          <BackButton />
        </div>
        {renderTable()}
        {renderCancleDialog()}
      </div>
    );
  }
  return <Error />;
}

WarehouseStockRequests.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

WarehouseStockRequests.defaultProps = {
  title: '',
  subtitle: '',
};
