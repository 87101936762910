import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { gql } from 'graphql-request';
import { Link } from 'react-router-dom';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  TablePagination,
  Paper,
  Card,
  TextField,
  InputAdornment,
  TableContainer,
} from '@mui/material';
import _ from 'lodash';

import { ViewTitle, Loading, Error } from '../../components';
import * as actions from '../../redux/actions';
import graphqlClient from '../../configs/graphqlClient';

/**
 * @function Machine
 * @description Display a collections or a list of Machine from database
 */

export default function Machine({ title, subtitle }) {
  const dispatch = useDispatch();
  const machine = useSelector((state) => state.machine);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState();
  const [searchTerm, setSearchTerm] = useState('');
  const [name, setName] = useState('');

  const query = gql`
    query FindMachine($machineinput: MachineInput) {
      findMachines(input: $machineinput) {
        currPage
        lastPage
        total
        rows {
          _id
          name
          brand
          image {
            url
          }
          is_conveyor
          serial_number
        }
      }
    }
  `;

  const queryDataFromServer = async () => {
    const queryResult = await graphqlClient.request(query, {
      machineinput: { page, size, name },
    });
    const machineData = queryResult?.findMachines;
    dispatch(actions.machineStateSet(machineData));
  };

  useEffect(() => {
    dispatch(actions.machineReset());
    return () => {};
  }, []);

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [page, size, name]);

  useEffect(() => {
    setTotal(machine?.total);
    return () => {};
  }, [machine]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchTerm);
      setPage(1);
    }, 700);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };

  const renderTitle = () => (
    <div className="flex justify-between">
      <ViewTitle title={title} subtitle={subtitle} />
      <div className="self-center">
        <Link to="/mams/machine/create">
          <Button variant="contained">เพิ่ม</Button>
        </Link>
      </div>
    </div>
  );

  const renderSearch = () => (
    <Card className="my-2">
      <div className="p-4 flex flex-row">
        <div className="w-full md:w-1/2">
          <TextField
            label="ค้นหา"
            fullWidth
            size={'small'}
            id="outlined-start-adornment"
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <i className="fas fa-search"></i>
                </InputAdornment>
              ),
            }}
          />
        </div>
      </div>
    </Card>
  );

  const renderTable = () => (
    <div>
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <div className="font-bold">ลำดับที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">ชื่อเครื่องจักร / รางการผลิต</div>
                </TableCell>{' '}
                <TableCell>
                  <div className="font-bold">ประเภท</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">รูปภาพ</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">ดำเนินการ</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(machine?.rows) ? (
                _.map(machine?.rows, (row, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      <div className="text-xs">{index + 1}</div>
                    </TableCell>
                    <TableCell>
                      <div className="font-display text-lg">{row?.name}</div>

                      <div>{row?.brand}</div>
                      <div>{row?.serial_number}</div>
                    </TableCell>
                    <TableCell className="text-center">
                      {row?.is_conveyor ? 'รางการผลิต / สายพาน' : 'เครื่องจักร'}
                    </TableCell>
                    <TableCell>
                      {row?.image ? (
                        <img src={row?.image?.url} className="w-24" />
                      ) : (
                        '-'
                      )}
                    </TableCell>
                    <TableCell>
                      <div className="flex flex-wrap gap-1">
                        <Link to={`machine/view/${row?._id}`}>
                          <Button size="small" variant="contained" color="info">
                            รายละเอียด
                          </Button>
                        </Link>{' '}
                        <Link to={`machine/edit/${row?._id}`}>
                          <Button
                            size="small"
                            variant="contained"
                            color="warning"
                          >
                            แก้ไข
                          </Button>
                        </Link>
                        <Button size="small" variant="contained" color="error">
                          ลบ
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <div className="text-center">ไม่มีข้อมูล</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
            <TablePagination
              page={page - 1}
              count={total || 1}
              rowsPerPage={size}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />{' '}
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );

  if (machine.isLoading) {
    return <Loading />;
  }
  if (!machine.isLoading && machine.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div>
          {renderSearch()}
          {renderTable()}
        </div>
      </div>
    );
  }
  return <Error />;
}

Machine.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

Machine.defaultProps = {
  title: '',
  subtitle: '',
};
