import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Button, Card, CardContent, TextField } from '@mui/material';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import _ from 'lodash';
import PropTypes from 'prop-types';

import {
  BackButton,
  DesktopDatepicker,
  DesktopDateTimepicker,
  DesktopTimePicker,
  Error,
  Loading,
  ViewTitle,
} from '@/components';

import * as actions from '../../redux/actions';
import * as CONSTANT from '../../utils/constants';
import { MMS } from '../../utils/functions';

dayjs.extend(duration);
dayjs.extend(relativeTime);

function DetailResultingFunctionType({ title, subtitle }) {
  const params = useParams();
  const resultingFunctionType = useSelector(
    (state) => state.resultingFunctionType,
  );
  const dispatch = useDispatch();
  const { control, handleSubmit } = useForm({});
  const { resultingFunctionExecutor } = MMS;
  const [resultingFunctionValue, setResultingFunctionValue] = useState('-');

  useEffect(() => {
    dispatch(actions.resultingFunctionTypeGet(params.id));
    return () => {};
  }, [params.id]);

  const variableOnlyExecutor = _.filter(
    resultingFunctionType?.executor,
    (eachExecutor) => !eachExecutor?.using_constant,
  );

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;
  const renderBackButton = () => (
    <div>
      <BackButton />
    </div>
  );

  const onSubmit = (data) => {
    console.log('Data.variable', data.variable);
    // prettier-ignore
    const dataAsNumber = _.map(data.variable, (eachData) =>
      (typeof eachData === 'string' || typeof eachData === 'number'
        ? parseFloat(eachData)
        : eachData),);

    try {
      const executorResult = resultingFunctionExecutor({
        resultingFunctionType,
        input: dataAsNumber,
        enableLog: true,
      });
      if (
        _.includes(
          _.map(data.variable, (each) => typeof each),
          'object',
        )
      ) {
        setResultingFunctionValue(
          dayjs.duration(executorResult).format('D [วัน] H [ชั่วโมง] m [นาที]'),
        );
      } else {
        console.log('Execute Result', executorResult);
        setResultingFunctionValue(executorResult);
      }
    } catch (error) {
      console.error('Execute Resulting Function Error', error);
      setResultingFunctionValue('-');
    }
  };

  const renderConstantOrMeasurementValue = (eachExecutor) => {
    if (eachExecutor?.using_constant) {
      if (eachExecutor?.using_static_value) {
        return eachExecutor?.static_value;
      }
      return `${eachExecutor?.organization_constant?.name} (${eachExecutor?.organization_constant?.value})`;
    }
    if (eachExecutor?.using_another_rft) {
      return `ผลจากฟังก์ชัน${eachExecutor?.another_rft?.name}`;
    }
    return eachExecutor?.measurement_type?.name;
  };

  const renderVariableDataOnExecutor = (eachExecutor, index) => {
    if (eachExecutor?.using_another_rft) {
      return (
        <div className="mb-4" key={index}>
          <span className="mx-2">
            ผลจากฟังก์ชัน{eachExecutor?.another_rft?.name}
          </span>
        </div>
      );
    }
    return (
      <div className="mb-4" key={index}>
        <span className="mx-2">{eachExecutor?.measurement_type?.name}</span>
        <span className="p-1  px-2 mx-2 rounded-lg bg-gray-200">
          {
            CONSTANT.DATA_TYPE[eachExecutor?.measurement_type?.data_type]
              .description
          }
        </span>
        {eachExecutor?.measurement_type?.unit?.name}
      </div>
    );
  };

  const renderEachMeasurementDemoInput = (eachExecutor, index) => {
    if (eachExecutor?.using_another_rft) {
      return (
        <div className="mb-4 flex gap-2 " key={index}>
          <p className="self-center">{eachExecutor?.another_rft?.name}</p>
          <div>
            <Controller
              control={control}
              name={`variable[${index}]`}
              render={({ field }) => (
                <TextField
                  {...field}
                  size="small"
                  label={eachExecutor?.another_rft?.name || ''}
                />
              )}
            />
          </div>
        </div>
      );
    }

    if (
      eachExecutor?.measurement_type?.data_type ===
      CONSTANT.DATA_TYPE.DATE.type_code
    ) {
      return (
        <div className="mb-4 flex gap-2 " key={index}>
          <p className="self-center">{eachExecutor?.measurement_type?.name}</p>
          <div>
            <Controller
              control={control}
              name={`variable[${index}]`}
              render={({ field }) => (
                <DesktopDatepicker
                  {...field}
                  size="small"
                  label={eachExecutor?.measurement_type?.name || ''}
                  type="date"
                />
              )}
            />
          </div>
        </div>
      );
    }

    if (
      eachExecutor?.measurement_type?.data_type ===
      CONSTANT.DATA_TYPE.DATETIME.type_code
    ) {
      return (
        <div className="mb-4 flex gap-2 " key={index}>
          <p className="self-center">{eachExecutor?.measurement_type?.name}</p>
          <div>
            <Controller
              control={control}
              name={`variable[${index}]`}
              render={({ field }) => (
                <DesktopDateTimepicker
                  {...field}
                  size="small"
                  label={eachExecutor?.measurement_type?.name || ''}
                />
              )}
            />
          </div>
        </div>
      );
    }

    if (
      eachExecutor?.measurement_type?.data_type ===
      CONSTANT.DATA_TYPE.TIME.type_code
    ) {
      return (
        <div className="mb-4 flex gap-2 " key={index}>
          <p className="self-center">{eachExecutor?.measurement_type?.name}</p>
          <div>
            <Controller
              control={control}
              name={`variable[${index}]`}
              render={({ field }) => (
                <DesktopTimePicker
                  {...field}
                  size="small"
                  label={eachExecutor?.measurement_type?.name || ''}
                  type="date"
                />
              )}
            />
          </div>
        </div>
      );
    }

    return (
      <div className="mb-4 flex gap-2 " key={index}>
        <p className="self-center">{eachExecutor?.measurement_type?.name}</p>
        <div>
          <Controller
            control={control}
            name={`variable[${index}]`}
            render={({ field }) => (
              <TextField
                {...field}
                size="small"
                label={eachExecutor?.measurement_type?.name || ''}
              />
            )}
          />
        </div>
        <p className="self-center">
          {eachExecutor?.measurement_type?.unit?.name}
        </p>
      </div>
    );
  };

  const renderDetail = () => (
    <div className="my-4">
      <Card>
        <CardContent>
          <div className="flex flex-wrap divide-y">
            <div className="w-full py-4 text-lg font-semibold font-display ">
              {resultingFunctionType?.name}
            </div>
            <div className="w-full md:w-1/3 py-4 px-2 font-semibold font-display">
              ตัวแปรวัดค่า
            </div>
            <div className="w-full md:w-2/3 py-4 px-2 ">
              {_.map(variableOnlyExecutor, (eachExecutor, index) =>
                renderVariableDataOnExecutor(eachExecutor, index),
              )}
            </div>
            <div className="w-full md:w-1/3 py-4 px-2 font-semibold font-display">
              การคำนวณค่า
            </div>
            <div className="w-full md:w-2/3 py-4 px-2 ">
              {_.map(resultingFunctionType?.executor, (eachExecutor, index) => (
                <span key={index}>
                  {renderConstantOrMeasurementValue(eachExecutor)}
                  {
                    CONSTANT.MATH_OPERATION[eachExecutor?.operation]?.symbol
                  }{' '}
                </span>
              ))}
            </div>
            <div className="w-full md:w-1/3 py-4 px-2 font-semibold font-display">
              ทดลองใช้งาน
            </div>
            <div className="w-full md:w-2/3 py-4 px-2 ">
              <form onSubmit={handleSubmit(onSubmit)}>
                {_.map(variableOnlyExecutor, (eachExecutor, index) =>
                  renderEachMeasurementDemoInput(eachExecutor, index),
                )}
                <div>
                  <Button variant="contained" type="submit">
                    คำนวณ
                  </Button>
                </div>
              </form>
              <div>
                <div className="text-xl my-2">
                  {' '}
                  ผลการคำนวณ {resultingFunctionValue}{' '}
                </div>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );

  if (resultingFunctionType.isLoading) {
    return <Loading />;
  }
  if (!resultingFunctionType.isLoading && resultingFunctionType.isCompleted) {
    return (
      <div>
        <div className="flex flex-wrap justify-between">{renderTitle()}</div>
        {renderBackButton()}
        {renderDetail()}
      </div>
    );
  }
  return <Error />;
}

DetailResultingFunctionType.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

export default DetailResultingFunctionType;
