import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { Button, Card } from '@mui/material';
import { gql } from 'graphql-request';
import _ from 'lodash';
import hash from 'object-hash';
import PropTypes from 'prop-types';

import {
  AddMaterialForm,
  BackButton,
  DecreaseMaterialForm,
  Error,
  Loading,
  LoadingDialogWithTimeout,
  MaterialStockForm,
  SocketIOLoading,
  ViewTitle,
} from '@/components';
import { config } from '@/configs';
import graphqlClient from '@/configs/graphqlClient';
import * as actions from '@/redux/actions';
import {
  MATERIAL_STOCK_STATUS,
  MQ_TASK,
  TRANSACTION_TYPE,
} from '@/utils/constants';
import { useQuery } from '@/utils/functions';

const defaultValues = {
  material_transaction_type: '',
  bill_number: '',
  place: '',
  type: '',
  supplier: '',
  quantity: '',
  price: '',
  material: '',
  remark: '',
  source: '',
  production_date: new Date(),
  expiration_date: new Date(),
  purchase_date: new Date(),
  receipt_date: new Date(),
  issue_date: new Date(),
};

const WithdrawMaterialStockLot = ({ title, subtitle }) => {
  const dispatch = useDispatch();
  const material = useSelector((state) => state.material);
  const place = useSelector((state) => state.place);
  const materialTransactionType = useSelector(
    (state) => state.materialTransactionType,
  );
  const query = useQuery();
  const customer = useSelector((state) => state.customer);
  const department = useSelector((state) => state.department);
  const withdrawDestination = useSelector((state) => state.withdrawDestination);
  const me = useSelector((state) => state.me);
  const params = useParams();

  const history = useHistory();
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    watch,
  } = useForm();

  const [name] = useState('');
  const [page] = useState(1);
  const [size] = useState(config.maxFetchSize);
  const [type, setType] = useState(null);
  const [selectMaterial, setSelectMaterial] = useState([]);
  const [selectMaterialLot, setSelectMaterialLot] = useState([]);
  const [filterMaterial, setFilterMaterial] = useState([]);
  const [filterPlace, setFilterPlace] = useState();
  const [referenceNumber, setReferenceNumber] = useState('');
  const [isDialogLoadingOn, setIsDialogLoadingOn] = useState(false);

  useEffect(() => {
    dispatch(actions.materialAll({ name: '', size, page: 1 }));
    dispatch(
      actions.materialTransactionTypeAll({
        direction: 'desc',
      }),
    );
    dispatch(
      actions.placeAll({ name: '', page: 1, size: config.maxFetchSize }),
    );
    dispatch(actions.customerAll(''));
    dispatch(actions.departmentAll(''));
    dispatch(actions.meGet(''));
    dispatch(
      actions.withdrawDestinationAll({
        name: '',
        page: 1,
        size: config.maxFetchSize,
      }),
    );
    return () => {};
  }, [name, page, size]);

  useEffect(() => {
    console.log('Place Setting');
    console.log('Place.rows', place?.rows);
    console.log('Me', me);

    const allowedPlace = _.filter(
      place?.rows,
      (eachPlace) =>
        eachPlace?.allow_all_department ||
        _.includes(
          _.map(eachPlace?.allow_departments, (each) => each?._id),
          me?.userData?.department?._id,
        ),
    );

    console.log('Allowed Place', allowedPlace);

    // If Exist Place ID in URL
    if (params.id) {
      const selectedPlace = _.find(
        place?.rows,
        (eachPlace) => eachPlace?.id === params.id,
      );
      console.log('Selected Place', selectedPlace);
      setFilterPlace(selectedPlace);
    } else {
      setFilterPlace(allowedPlace?.[0]);
      console.log('Set Filter Place to ', allowedPlace?.[0]);
    }

    return () => {};
  }, [place]);

  const gqlQuery = gql`
    query FindMaterial($materialInput: MaterialInput) {
      findMaterials(input: $materialInput) {
        currPage
        lastPage
        total
        rows {
          id
          _id
          type_code
          name
          material_type {
            name
          }
          stocklots {
            _id
            supplier {
              name
            }
            status
            place {
              _id
            }
            lot_number
            production_date
            expiration_date
            quantity
            recording_date
            amount
            price
          }
          use_unit_conversion
          unit
          conversion_from {
            unit_a
            unit_b
            conversion_factor
          }
          conversion_to {
            unit_b
            unit_a
            conversion_factor
          }
          unit_input {
            _id
            short_sign
            name
          }
          unit_output {
            _id
            short_sign
            name
          }
        }
      }
    }
  `;

  const queryDataFromServer = async () => {
    try {
      const queryResult = await graphqlClient.request(gqlQuery, {
        materialInput: {
          page: 1,
          size: config.maxFetchSize,
          fetchStockLot: true,
          status: 'IN_STOCK',
        },
      });

      const elementData = queryResult?.findMaterials;
      dispatch(actions.materialStateSet(elementData));
    } catch (error) {
      alert(error?.message);
      dispatch(actions.materialReset());
    }
  };

  useEffect(() => {
    queryDataFromServer();

    return () => {};
  }, [type?.direction, type]);

  useEffect(() => {
    const refNo = hash({
      date: new Date(),
      user: me?.userData?._id,
    });

    setReferenceNumber(refNo);

    return () => {};
  }, []);

  useEffect(() => {
    if (materialTransactionType?.rows) {
      if (query.get('txnType')) {
        const selectedTxnType = _.find(
          materialTransactionType?.rows,
          (each) => each?._id === query.get('txnType'),
        );
        setType(selectedTxnType);
      } else {
        setType(materialTransactionType?.rows[0]);
      }
    }

    return () => {};
  }, [materialTransactionType]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const socketioHandleFunction = {
    onSuccess: () => {
      setIsDialogLoadingOn(false);
      history.goBack();
    },
    onFail: (args) => {
      setIsDialogLoadingOn(false);
      //  alert(args?.message);
    },
  };

  const onSubmit = async (data, event) => {
    try {
      // Verification
      if (
        type?.direction === TRANSACTION_TYPE.add.status_code && // add
        _.isEmpty(selectMaterial)
      ) {
        alert('กรุณาเลือกวัตถุดิบ');
      } else if (
        type?.direction === TRANSACTION_TYPE.desc.status_code &&
        _.isEmpty(selectMaterialLot)
      ) {
        alert('กรุณาเลือกล็อตวัตถุดิบ');
      }

      // Create Transaction
      else if (
        type?.direction === TRANSACTION_TYPE.add.status_code && // add
        !_.isEmpty(selectMaterial)
      ) {
        data.material = selectMaterial;
        if (query.get('txnType')) {
          data.material_transaction_type = query.get('txnType');
        }
        data.status = MATERIAL_STOCK_STATUS.IN_STOCK.status_code;
        data.recording_date = new Date();
        event.preventDefault();

        await dispatch(
          actions.materialStockLotCreate({ ...data, referenceNumber }),
        );

        console.log('Material Stock Lot Payload', data);
        reset(defaultValues);
        setSelectMaterial([]);
        setIsDialogLoadingOn(true);
      } else if (
        type?.direction === TRANSACTION_TYPE.desc.status_code &&
        !_.isEmpty(selectMaterialLot)
      ) {
        if (query.get('txnType')) {
          data.material_transaction_type = query.get('txnType');
        }
        data.materialLots = selectMaterialLot;
        event.preventDefault();

        await dispatch(
          actions.materialStockLotDecrease({ ...data, referenceNumber }),
        );
        reset(defaultValues);
        setSelectMaterialLot([]);

        setIsDialogLoadingOn(true);
      }
    } catch (error) {
      alert(error?.message);
      console.error(error);
    }
  };

  const renderForm = () => (
    <Card className="p-4">
      <div className="py-2">
        <MaterialStockForm
          control={control}
          errors={errors}
          place={place.rows}
          materialTransactionType={materialTransactionType.rows}
          customer={customer.rows}
          department={department.rows}
          me={me.userData}
          type={type}
          setType={setType}
          setFilterPlace={setFilterPlace}
          setSelectMaterial={setSelectMaterial}
          setSelectMaterialLot={setSelectMaterialLot}
          watch={watch}
          withdrawDestinations={withdrawDestination?.rows}
        />
      </div>
    </Card>
  );

  const renderSelectMaterial = () => (
    <DecreaseMaterialForm
      material={material.rows}
      place={place.rows}
      type={type}
      selectMaterialLot={selectMaterialLot}
      setSelectMaterialLot={setSelectMaterialLot}
      filterMaterial={filterMaterial}
      setFilterMaterial={setFilterMaterial}
      filterPlace={filterPlace}
      setFilterPlace={setFilterPlace}
    />
  );

  if (material.isLoading) {
    return <Loading />;
  }
  if (!material.isLoading && material.isCompleted) {
    return (
      <div>
        <SocketIOLoading
          referenceNumber={referenceNumber}
          taskCode={MQ_TASK.WITHDRAW_MATERIAL_WITH_LOT.status_code}
          handleFail={socketioHandleFunction.onFail}
          handleSuccess={socketioHandleFunction.onSuccess}
        />

        <SocketIOLoading
          referenceNumber={referenceNumber}
          taskCode={MQ_TASK.INSERT_MATERIAL.status_code}
          handleFail={socketioHandleFunction.onFail}
          handleSuccess={socketioHandleFunction.onSuccess}
        />
        {isDialogLoadingOn && <LoadingDialogWithTimeout />}
        <div className="flex justify-between">
          <div>{renderTitle()}</div>
        </div>
        <div className="flex flex-row justify-start pb-4">
          <div>
            <BackButton />
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-row flex-wrap">
            <div className="w-full px-1 py-2">{renderForm()}</div>
            <div className="w-full  px-1 py-2">{renderSelectMaterial()}</div>
          </div>
          <div className="flex flex-row justify-end gap-1 py-4">
            <Button variant="contained" type="submit">
              บันทึก
            </Button>
          </div>
        </form>
      </div>
    );
  }
  return <Error message={material?.message} />;
};

WithdrawMaterialStockLot.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

WithdrawMaterialStockLot.defaultProps = {
  title: '',
  subtitle: '',
};

export default WithdrawMaterialStockLot;
