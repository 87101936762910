import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Card } from '@mui/material';
import { gql } from 'graphql-request';
import _ from 'lodash';
import PropTypes from 'prop-types';

import {
  BackButton,
  Error,
  Loading,
  MaterialForm,
  ViewTitle,
} from '@/components';
import { app as appConfig } from '@/configs';
import graphqlClient from '@/configs/graphqlClient';
import * as actions from '@/redux/actions';

const defaultValues = {
  type_code: '',
  name: '',
  unit: '',
  material_type: '',
  net: '',
  unit_net: '',
  minimum_amount: '',
  detail: '',
  customer_type: '',
  register_number: '',
};
export default function CreateMaterial({ title, subtitle }) {
  const dispatch = useDispatch();
  const material = useSelector((state) => state.material);
  const materialType = useSelector((state) => state.materialType);
  const customerType = useSelector((state) => state.customerType);
  const customer = useSelector((state) => state.customer);
  const unit = useSelector((state) => state.unit);
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    watch,
  } = useForm();
  const history = useHistory();

  const [selectCustomerType, setSelectCustomerType] = useState();
  const [selectCustomer, setSelectCustomer] = useState([]);
  const [otherMaterialList, setOtherMaterialList] = useState([]);

  const query = gql`
    query FindMaterialRelatedElement(
      $unitInput: UnitInput
      $otherMaterialInput: MaterialInput
    ) {
      findUnits(input: $unitInput) {
        rows {
          _id
          name
        }
      }
      findMaterials(input: $otherMaterialInput) {
        rows {
          _id
          type_code
          name
        }
      }
    }
  `;

  const queryDataFromServer = async () => {
    try {
      const queryResult = await graphqlClient.request(query, {
        unitInput: { page: 1, size: appConfig.maxFetchSize },
        otherMaterialInput: { page: 1, size: appConfig.maxFetchSize },
      });
      const unitResult = queryResult?.findUnits;
      const otherMaterialResult = queryResult?.findMaterials;
      setOtherMaterialList(otherMaterialResult?.rows);
      dispatch(actions.unitStateSet(unitResult));
    } catch (error) {
      dispatch(actions.materialError());
    }
  };

  useEffect(() => {
    dispatch(actions.materialAll({}));
    dispatch(actions.materialTypeAll(''));
    dispatch(actions.customerTypeAll({}));
    queryDataFromServer();

    return () => {};
  }, []);

  useEffect(() => {
    if (selectCustomerType) {
      dispatch(actions.customerAll({ customerTypeName: selectCustomerType }));
    }
    return () => {};
  }, [selectCustomerType]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const onSubmit = async (data) => {
    try {
      if (_.isEmpty(selectCustomer)) {
        const supplier = [];
        await dispatch(actions.materialCreate({ ...data, supplier }));
      } else {
        const supplier = _.map(selectCustomer, (select) => select?._id);
        await dispatch(actions.materialCreate({ ...data, supplier }));
      }
      alert('สำเร็จ');
      setSelectCustomer([]);
      setSelectCustomerType('');
      reset(defaultValues);
      history.goBack();
    } catch (error) {
      console.log(error);
    }
  };

  if (material.isLoading) {
    return <Loading />;
  }

  if (!material.isLoading && material.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div className="flex flex-row justify-start pb-4">
          <div>
            <BackButton />
          </div>
        </div>
        <div>
          <Card className="p-4">
            <form onSubmit={handleSubmit(onSubmit)}>
              <MaterialForm
                errors={errors}
                material={material}
                control={control}
                Controller={Controller}
                materialType={materialType}
                customerType={customerType}
                selectCustomerType={selectCustomerType}
                setSelectCustomerType={setSelectCustomerType}
                customer={customer}
                setSelectCustomer={setSelectCustomer}
                selectCustomer={selectCustomer}
                unit={unit}
                watch={watch}
                otherMaterialList={otherMaterialList}
              />
              <div className="flex flex-row justify-end gap-1 py-4">
                <Button variant="contained" type="submit">
                  บันทึก
                </Button>
              </div>
            </form>
          </Card>
        </div>
      </div>
    );
  }

  return <Error message={material?.message} />;
}

CreateMaterial.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

CreateMaterial.defaultProps = {
  title: '',
  subtitle: '',
};
