import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { gql } from 'graphql-request';
import { useHistory, useParams } from 'react-router-dom';
import { Card, CardContent, Chip } from '@mui/material';

import {
  ViewTitle,
  Loading,
  Error,
  BackButton,
  ProcessTable,
  ConveyorSketchGeneraror,
} from '../../components';
import * as actions from '../../redux/actions';
import graphqlClient from '../../configs/graphqlClient';

/**
 * @function DetailMachine
 * @description Display a collections or a list of DetailMachine from database
 */

export default function DetailMachine({ title, subtitle }) {
  const dispatch = useDispatch();
  const machine = useSelector((state) => state.machine);
  const processes = useSelector((state) => state.process);
  const params = useParams();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState();

  const query = gql`
    query FindDetailMachine(
      $machineinput: MachineInput
      $processInput: ProcessInput
    ) {
      findOneMachine(input: $machineinput) {
        _id
        name
        brand
        buy_year
        description
        image {
          url
        }
        is_conveyor
        conveyor_length
        conveyor_width
        conveyor_parallel_number
      }
      findProcesses(input: $processInput) {
        total
        rows {
          _id
          manufacturing_order {
            running_number
            start_date
          }
          product {
            type_code
            name
          }
          base_project {
            type_code
            name
          }
          produce_material_instead
          produce_base_project_instead
          product_as_material {
            type_code
            name
          }
          completed
          deleted
        }
      }
    }
  `;

  const queryDataFromServer = async () => {
    try {
      console.log('params.id', params.id);
      const queryResult = await graphqlClient.request(query, {
        machineinput: {
          id: params.id,
        },
        processInput: {
          machine: params.id,
          fetchProduct: true,
          page,
          size,
          fetchBaseProject: true,
          fetchMaterial: true,
        },
      });
      const machineData = queryResult?.findOneMachine;
      const processData = queryResult?.findProcesses;
      dispatch(actions.machineStateOneSet(machineData));
      dispatch(actions.processStateSet(processData));
    } catch (error) {
      console.error('Query Error', error);
      dispatch(actions.machineError());
    }
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [page, size, params]);

  useEffect(() => {
    if (!_.isEmpty(processes?.rows)) {
      setTotal(processes?.total);
    }

    return () => {};
  }, [processes]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const renderBackButton = () => (
    <div className="flex my-2">
      <BackButton />
    </div>
  );

  const renderConveyorSketch = () => (
    <div className="my-4">
      <Card>
        <CardContent>
          <h4 className="font-semibold font-display">
            ตัวอย่างแบบจำลองรางการผลิต / สายพานลำเลียง
          </h4>
          <ConveyorSketchGeneraror
            length={machine?.conveyor_length}
            width={machine?.conveyor_width}
            numberOfParallel={machine?.conveyor_parallel_number}
          />
        </CardContent>
      </Card>
    </div>
  );

  const renderMachineInfo = () => (
    <div className="my-2">
      <Card>
        <CardContent>
          <div className="flex">
            <div className="w-full lg:w-1/4 px-2">
              <img
                src={
                  machine?.image?.url ||
                  'https://cdn.pixabay.com/photo/2015/03/23/16/58/gears-686316_960_720.jpg'
                }
                className="w-48"
              />
            </div>
            <div className="w-full lg:w-3/4 px-2">
              <div className="font-display font-semibold text-xl ">
                {machine?.name}{' '}
              </div>
              <div>
                {machine?.is_conveyor ? (
                  <Chip
                    color="secondary"
                    label="รางการผลิต/สายพานลำเลียง"
                    size="small"
                  />
                ) : (
                  <Chip color="primary" label="เครื่องจักร" size="small" />
                )}
              </div>
              <br />
              <div className="my-2 flex flex-wrap">
                <div className="w-1/3 lg:w-1/6 font-semibold">ยี่ห้อ :</div>
                <div className="w-2/3 lg:w-2/6 px-2">
                  {machine?.brand || '-'}
                </div>
                <div className="w-1/3 lg:w-1/6 font-semibold">ปีที่ซื้อ :</div>
                <div className="w-2/3 lg:w-2/6 px-2">
                  {machine?.buy_year || '-'}
                </div>
                <div className="w-1/3 lg:w-1/6 font-semibold">
                  Serial Number :
                </div>
                <div className="w-2/3 lg:w-2/6 px-2">
                  {machine?.serial_number || '-'}
                </div>
              </div>
              <div className="my-2">{machine?.description || ''} </div>
              {machine?.is_conveyor && (
                <div>
                  <hr />
                  <div className="font-semibold font-display my-2">
                    รายละเอียดรางการผลิต / สายพานลำเลียง
                  </div>
                  <div className="my-3 flex flex-wrap">
                    <div className="w-1/3 lg:w-2/6 font-semibold">
                      ความกว้าง :
                    </div>
                    <div className="w-2/3 lg:w-1/6 px-2">
                      {machine?.conveyor_width || '-'} เมตร
                    </div>
                    <div className="w-1/3 lg:w-2/6 font-semibold">
                      ความยาว :
                    </div>
                    <div className="w-2/3 lg:w-1/6 px-2">
                      {machine?.conveyor_length || '-'} เมตร
                    </div>
                    <div className="w-1/3 lg:w-2/6 font-semibold">
                      จำนวนรางที่ขนานกัน :
                    </div>
                    <div className="w-2/3 lg:w-1/6 px-2">
                      {machine?.conveyor_parallel_number || '1'} ราง
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );

  const renderTable = () => (
    <div className="my-2">
      <Card>
        <CardContent>
          <h4 className="font-semibold font-display mb-2">
            ประวัติการใช้เครื่องจักร / รางการผลิต
          </h4>
          <ProcessTable
            handleChangePage={handleChangePage}
            handleChangeRowPerPage={handleChangeRowsPerPage}
            page={page}
            processData={processes?.rows}
            size={size}
            total={total}
          />
        </CardContent>
      </Card>
    </div>
  );

  if (machine.isLoading) {
    return <Loading />;
  }

  if (!machine.isLoading && machine.isCompleted) {
    return (
      <div>
        {renderTitle()}
        {renderBackButton()}
        <div>
          {renderMachineInfo()}
          {machine?.is_conveyor && renderConveyorSketch()}
          {renderTable()}
        </div>
      </div>
    );
  }
  return <Error />;
}

DetailMachine.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

DetailMachine.defaultProps = {
  title: '',
  subtitle: '',
};
