import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import { NotAuthorized, NotFound } from '../components/Error';
import { MainFooter } from '../components/Footers';
import { Loading } from '../components/Loading';
import { HomeNavbar } from '../components/Navbars';
import { MainSidebar } from '../components/Sidebar';
import * as actions from '../redux/actions';
import accessRight from '../utils/functions/accessRight';
import {
  CreateCustomer,
  Customers,
  DetailCustomer,
  EditCustomer,
} from '../views/CRM';

export function CRM() {
  const module = 'CRM';
  const prefix = '/crm';
  const name = 'ลูกค้าสัมพันธ์';
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const me = useSelector((state) => state.me);
  const availableModule = useSelector((state) => state.availableModule);
  const information = useSelector((state) => state.information);

  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const handleOnMobileNavOpen = () => {
    setMobileNavOpen(true);
  };
  const handleOnMobileNavClose = () => {
    setMobileNavOpen(false);
  };

  useEffect(() => {
    console.log('Layout : CRM');
    dispatch(actions.meGet());
    dispatch(actions.informationAll());
    dispatch(actions.availableModuleAll());

    return () => {};
  }, []);

  if (!me?.userData) {
    return <Loading />;
  }

  if (accessRight(me, module, 0, availableModule)) {
    return (
      <div className="min-h-screen">
        <Helmet>
          <meta charSet="utf-8" />
          <title>ลูกค้าสัมพันธ์ - {information?.name || 'E-Manufac'}</title>
        </Helmet>
        <MainSidebar
          onMobileClose={handleOnMobileNavClose}
          openMobile={isMobileNavOpen}
          me={me}
        />
        <HomeNavbar onMobileNavOpen={handleOnMobileNavOpen} />
        <div className="lg:ml-64  min-h-screen  pt-16 px-8 ">
          <div className="py-4">
            <Switch>
              <Redirect exact from={`${prefix}`} to={`${prefix}/customers`} />
              <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />

              <Route exact path={`${prefix}/customers`}>
                <Customers
                  title="จัดการข้อมูลลูกค้าและคู่ค้า"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/customer/detail/:id`}>
                <DetailCustomer
                  title="รายละเอียดข้อมูลลูกค้า"
                  subtitle={name}
                />
              </Route>
              {/**  สิทธิที่เพิ่มขึ้นมาสำหรับ User ที่เป็นผู้จัดการ */}
              {accessRight(me, module, 1, availableModule) && (
                <Switch>
                  <Route exact path={`${prefix}/customer/edit/:id`}>
                    <EditCustomer title="แก้ไขข้อมูลลูกค้า" subtitle={name} />
                  </Route>
                  <Route exact path={`${prefix}/customer/create`}>
                    <CreateCustomer title="เพิ่มข้อมูลลูกค้า" subtitle={name} />
                  </Route>
                  <Route path="*">
                    <NotFound />
                  </Route>
                </Switch>
              )}
              <Route path="*">
                <NotFound />
              </Route>
            </Switch>
          </div>
        </div>
        <div className="lg:ml-64">
          <MainFooter />
        </div>
      </div>
    );
  }
  return (
    <div>
      <div className="min-h-screen">
        <MainSidebar
          onMobileClose={handleOnMobileNavClose}
          openMobile={isMobileNavOpen}
          me={me}
        />
        <HomeNavbar onMobileNavOpen={handleOnMobileNavOpen} />
        <NotAuthorized />
        <div className="lg:ml-64">
          <MainFooter />
        </div>
      </div>
    </div>
  );
}

export default CRM;
