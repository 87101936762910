/* eslint-disable arrow-body-style */
import React from 'react';
import { Controller, useFieldArray } from 'react-hook-form';
import {
  Autocomplete,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import {
  backConverseMaterialOrProductUnit,
  converseMaterialOrProductUnit,
} from '../../../utils/functions';
import { ProdOrMatQuantityInput } from '../../Inputs';

const ProductBomForm = ({
  control,
  watch,
  setValue,
  errors,
  material,
  product,
  showProduct = true,
}) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'bill_of_materials',
  });

  // Logic ต้องสลับกันกับปกติ เพราะมันคือการเอา Conversion Factor ไปหาร
  const forwardConversionAllMaterial = () => {
    const currentMaterial = watch('bill_of_materials');
    const editedMaterial = _.map(currentMaterial, (eachMaterial) => {
      const newMaterialAmount = backConverseMaterialOrProductUnit(
        product,
        eachMaterial.amount,
      );

      return { ...eachMaterial, amount: newMaterialAmount };
    });
    setValue('bill_of_materials', editedMaterial);
  };

  const backwardConversionAllMaterial = () => {
    const currentMaterial = watch('bill_of_materials');
    const editedMaterial = _.map(currentMaterial, (eachMaterial) => {
      const newMaterialAmount = converseMaterialOrProductUnit(
        product,
        eachMaterial.amount,
      );

      return { ...eachMaterial, amount: newMaterialAmount };
    });
    setValue('bill_of_materials', editedMaterial);
  };

  const handleChangeUnit = (selectedUnit) => {
    // unit input
    if (
      (watch('selected_unit') === 'unit_input' ||
        !watch('selected_unit') ||
        watch('selected_unit') === '') &&
      selectedUnit === 'unit_output'
    ) {
      forwardConversionAllMaterial(); // set All Value with conversion factor
    }

    // unit output
    else if (
      watch('selected_unit') === 'unit_output' &&
      selectedUnit === 'unit_input'
    ) {
      backwardConversionAllMaterial(); // set All Value with conversion factor
    } else {
      alert('Unit not detected');
    }
  };

  const renderEachMaterialForm = (eachField, index) => (
    <div key={eachField?.id} className="w-full my-2">
      <div className="flex flex-wrap p-2 border w-full">
        <div className=" px-2 self-center">{index + 1}</div>
        <div className="w-full lg:w-2/6 self-center">
          {!_.isEmpty(material?.rows) && (
            <Controller
              name={`bill_of_materials[${index}].material`}
              control={control}
              defaultValue={eachField?.material || material?.rows?.[0]}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  size={'small'}
                  disableClearable
                  defaultValue={field.value}
                  onChange={(e, newValue) => {
                    field.onChange(newValue);
                  }}
                  options={material?.rows}
                  getOptionLabel={(option) =>
                    `${option?.type_code || ''} ${option?.name} `
                  }
                  renderInput={(params) => (
                    <TextField {...params} label="เลือกวัตถุดิบ" required />
                  )}
                />
              )}
            />
          )}
          {errors?.bill_of_materials?.[index]?.material && (
            <FormHelperText>กรุณาใส่ข้อมูล</FormHelperText>
          )}
        </div>
        <div className="w-full lg:w-1/3 px-2 self-center">
          <ProdOrMatQuantityInput
            control={control}
            controllerName={`bill_of_materials[${index}].amount`}
            artificialControllerName={`bill_of_materials[${index}].amount_artifact`}
            selectedUnitControllerName={`bill_of_materials[${index}].unit_select`}
            setValue={setValue}
            watch={watch}
            selectedProduct={watch(`bill_of_materials[${index}].material`)}
          />
        </div>
        <div className="flex ">
          <Controller
            control={control}
            name={`bill_of_materials[${index}].tag`}
            render={({ field }) => (
              <TextField {...field} label="Tag" size="small" fullWidth />
            )}
          />
          <div className="w-full lg:w-1/12 px-2 self-center">
            <Button
              size="small"
              color="error"
              variant="contained"
              onClick={() => {
                remove(index);
              }}
            >
              ลบ
            </Button>
          </div>
        </div>
      </div>
    </div>
  );

  const renderProductUnitSelect = () => (
    <div className="border p-2 my-2 rounded-sm">
      <div className="flex gap-2">
        <div className="self-center">
          <b>สินค้า</b> {product?.type_code || ''} {product?.name} จำนวน 1{' '}
        </div>
        <div className="self-center">
          {product?.use_unit_conversion ? (
            <div className="w-48">
              <Controller
                name="selected_unit"
                control={control}
                defaultValue="unit_input"
                render={({ field }) => (
                  <FormControl fullWidth size="small">
                    <InputLabel>หน่วย</InputLabel>
                    <Select
                      size="small"
                      label="หน่วย"
                      {...field}
                      fullWidth
                      onChange={(event) => {
                        handleChangeUnit(event.target.value);
                        field.onChange(event.target.value);
                      }}
                    >
                      <MenuItem value="unit_input">
                        {product?.unit_input?.name}{' '}
                      </MenuItem>
                      <MenuItem value="unit_output">
                        {product?.unit_output?.name}{' '}
                      </MenuItem>
                    </Select>
                  </FormControl>
                )}
              />
            </div>
          ) : (
            product?.unit
          )}
        </div>
      </div>{' '}
    </div>
  );
  return (
    <div>
      {showProduct && renderProductUnitSelect()}
      {_.map(fields, (eachField, index) =>
        renderEachMaterialForm(eachField, index),
      )}
      <div className="my-2">
        <Button
          size="small"
          variant="outlined"
          onClick={() =>
            append({
              material: material?.rows?.[0],
            })
          }
        >
          เพิ่ม
        </Button>
      </div>
    </div>
  );
};

ProductBomForm.propTypes = {
  control: PropTypes.object,
  watch: PropTypes.func,
  setValue: PropTypes.func,
  errors: PropTypes.object,
  material: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  product: PropTypes.object,
  showProduct: PropTypes.bool,
};

export default ProductBomForm;
