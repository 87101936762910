import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  TextField,
} from '@mui/material';
import PropTypes from 'prop-types';

import { Error, Loading, ProcessTemplateTable, ViewTitle } from '@/components';
import * as actions from '@/redux/actions';

export default function ProcessTemplate({ title, subtitle }) {
  const dispatch = useDispatch();
  const processTemplate = useSelector((state) => state.processTemplate);

  // Setting React hook form
  const [searchTerm, setSearchTerm] = useState('');
  const [name, setName] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(undefined);
  const [duplicateModalOpen, setIsDuplicateModalOpen] = useState(false);
  const [selectedProcessTempalte, setSelectedProcessTemplate] = useState();
  const [newProcessTemplateName, setNewProcessTemplateName] = useState('');
  const [dialogCreateOpen, setDialogCreateOpen] = useState(false);

  useEffect(() => {
    const fetchProcessTemplate = async () => {
      try {
        await dispatch(actions.processTemplateAll({ name, page, size }));
      } catch (error) {
        console.error(error);
      }
    };

    fetchProcessTemplate();

    return () => {};
  }, [name, page, size]);

  useEffect(() => {
    setTotal(processTemplate?.total);
    return () => {};
  }, [processTemplate]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchTerm);
      setPage(1);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const handleDelete = async (id) => {
    const confirm = window.confirm('ยืนยันการลบข้อมูล');
    if (confirm) {
      try {
        await dispatch(actions.processTemplateDelete(id));
        await dispatch(actions.processTemplateAll({ name, page, size }));
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleDuplicateProcessTemplate = async () => {
    try {
      await dispatch(
        actions.processTemplateCreate({
          fromDuplicate: true,
          originalItem: selectedProcessTempalte?._id,
          name: newProcessTemplateName,
        }),
      );
      await dispatch(actions.processTemplateAll({ name, page, size }));
      alert('สำเร็จ');
      setNewProcessTemplateName('');
      setSelectedProcessTemplate();
      setIsDuplicateModalOpen(false);
    } catch (error) {
      alert(`ไม่สามารถทำซ้ำสูตรการผลิตได้ ${error?.message || ''}`);
    }
  };

  const handleCreateProcessTemplate = async () => {
    try {
      await dispatch(
        actions.processTemplateCreate({
          name: newProcessTemplateName,
        }),
      );
      await dispatch(actions.processTemplateAll({ name, page, size }));
      setNewProcessTemplateName('');
      setDialogCreateOpen(false);
    } catch (error) {
      alert(`ไม่สามารถทำการสร้างสูตรการผลิตได้ ${error?.message || ''}`);
    }
  };

  const renderSearch = () => (
    <Card>
      <div className="p-4 flex flex-row">
        <div className="w-full md:w-1/2">
          <TextField
            label="ค้นหา"
            fullWidth
            size={'small'}
            id="outlined-start-adornment"
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <i className="fas fa-search"></i>
                </InputAdornment>
              ),
            }}
          />
        </div>
      </div>
    </Card>
  );

  const renderAddButton = () => (
    <div>
      <Button variant="contained" onClick={() => setDialogCreateOpen(true)}>
        เพิ่ม
      </Button>
    </div>
  );

  const renderTable = () => (
    <ProcessTemplateTable
      page={page}
      size={size}
      total={total}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      handleDelete={handleDelete}
      product={processTemplate}
      showDeleteButton
      setModalOpen={setIsDuplicateModalOpen}
      setSelectedProcessTemplate={setSelectedProcessTemplate}
      placeURL="process-template"
    />
  );

  const renderDuplicateModal = () => (
    <Dialog
      open={duplicateModalOpen}
      onClose={() => setIsDuplicateModalOpen(false)}
    >
      <DialogTitle>ทำซ้ำสูตรการผลิต</DialogTitle>
      <DialogContent>
        <div className="flex gap-2 w-72">
          <div className="font-semibold">สูตรการผลิตเดิม</div>
          <div>{selectedProcessTempalte?.name}</div>
        </div>
        <div className="text-2xl flex justify-center my-4">
          <i className="fas fa-arrow-alt-circle-down"></i>
        </div>
        <div>
          <TextField
            fullWidth
            size="small"
            label="ชื่อสูตรการผลิตใหม่"
            onChange={(event) => setNewProcessTemplateName(event.target.value)}
            value={newProcessTemplateName}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          color="success"
          variant="contained"
          onClick={() => {
            handleDuplicateProcessTemplate();
          }}
        >
          สร้าง
        </Button>
      </DialogActions>
    </Dialog>
  );

  const renderCreateDialog = () => (
    <Dialog open={dialogCreateOpen} onClose={() => setDialogCreateOpen(false)}>
      <DialogTitle>สร้างสูตรการผลิต</DialogTitle>
      <DialogContent className="w-96">
        <div>
          <TextField
            fullWidth
            size="small"
            label="ชื่อสูตรการผลิต"
            onChange={(event) => setNewProcessTemplateName(event.target.value)}
            value={newProcessTemplateName}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          color="success"
          variant="contained"
          onClick={() => {
            handleCreateProcessTemplate();
          }}
        >
          สร้าง
        </Button>
      </DialogActions>
    </Dialog>
  );

  if (processTemplate.isLoading) {
    return <Loading />;
  }
  if (!processTemplate.isLoading && processTemplate.isCompleted) {
    return (
      <div>
        <div className="flex justify-between">
          <div>{renderTitle()}</div>
          <div className="self-center">{renderAddButton()}</div>
        </div>
        {renderDuplicateModal()}
        {renderCreateDialog()}
        {renderSearch()}
        {renderTable()}
      </div>
    );
  }
  return <Error message={processTemplate?.message} />;
}

ProcessTemplate.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

ProcessTemplate.defaultProps = {
  title: '',
  subtitle: '',
};
