import React from 'react';
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TextField,
  Checkbox,
} from '@mui/material';
import { Controller, useFieldArray } from 'react-hook-form';
import PropTypes from 'prop-types';
import _ from 'lodash';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

import OverTimeForm from './OverTimeForm';
import { decodeTimeValue } from '../../../utils/functions';

dayjs.extend(duration);
function MultiPayrollForm({
  control,
  watch,
  errors,
  isDaily = true,
  setValue,
  selectedDate,
  hourPerDay,
  lateThreshold,
  brushOfThreshold,
  breakPerDay,
}) {
  // ส่งไปภายใต้ Object arr แล้วให้ BE วนสร้างทีเดียวหลาย ๆ รายการ
  const { fields } = useFieldArray({ control, name: 'arr' });

  const calculateAllOTCost = (index) => {
    const otList = _.map(
      watch(`arr[${index}].overtimes`),
      (otItem) => otItem?.cost,
    );

    return _.sum(otList);
  };

  const calculateManHour = (
    start = dayjs().hour(8).minute(0),
    finish = dayjs().hour(17).minute(0),
  ) => {
    const manMin =
      dayjs(finish).diff(dayjs(start), 'minute') - 60 * breakPerDay;
    const manHour = dayjs(finish).diff(dayjs(start), 'hour') - breakPerDay;

    // ลงตัวเป็นชั่วโมง  หรือ ทำงานไม่ถึง 20 นาที (สายเกิน 40 นาที) ถือว่าไม่ได้ทำงาน ปัดทิ้ง
    if (manMin % 60 < 60 - brushOfThreshold) {
      return manHour;
    }

    // ทำงานสาย / คิดเรทครั่งชั่วโมง
    if (manMin % 60 < 60 - lateThreshold) {
      return manHour + 0.5;
    }

    // น้อยกว่าเรทสาย ถือว่าไม่สาย
    return manHour + 1;
  };

  const hasCurrentDayPayroll = (selectedEmployee) => {
    const hasRecordOfSameDays = _.map(
      selectedEmployee?.payrolls,
      (eachPayroll) => {
        if (dayjs(eachPayroll?.date).isSame(dayjs(selectedDate), 'date')) {
          return true;
        }
        return false;
      },
    );
    if (_.includes(hasRecordOfSameDays, true)) {
      return true;
    }
    return false;
  };

  const hasCurrentMonthPayroll = (selectedEmployee) => {
    const hasRecordOfSameMonths = _.map(
      selectedEmployee?.payrolls,
      (eachPayroll) => {
        if (dayjs(eachPayroll?.date).isSame(dayjs(selectedDate), 'months')) {
          return true;
        }
        return false;
      },
    );
    if (_.includes(hasRecordOfSameMonths, true)) {
      return true;
    }
    return false;
  };

  const changeMainCost = (
    start,
    finish,
    costPerHour,
    basedControllerName,
    index,
  ) => {
    const manHour = calculateManHour(start, finish);
    const cost = costPerHour * manHour; // Main Cost
    setValue(`${basedControllerName}.main_cost`, cost);

    const totalCost = cost + calculateAllOTCost(index) || 0; // Cost with OT
    console.log('Total Cost', totalCost);
    setValue(`${basedControllerName}.total_cost`, totalCost);
  };

  return (
    <div>
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <div className="font-bold">ลำดับที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">จ่ายเงิน</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> พนักงาน</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> การทำงาน</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ค่าล่วงเวลา</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> รวมจำนวนเงิน</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> หมายเหตุ</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {_.map(fields, (_field, index) => (
                <TableRow id={_field.id}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>
                    {/** มีการเช็คมาร์คไว้ว่าใครที่เราจะจ่ายเงินบ้าง ถ้าไม่ Check
                  เราก็จะ Filter ออกก่อนส่งไป BE */}
                    <Controller
                      control={control}
                      name={`arr.[${index}].enable`}
                      render={({ field }) => (
                        <Checkbox {...field} defaultChecked={field.value} />
                      )}
                    />
                  </TableCell>

                  <TableCell>
                    {_field?.employee?.firstname} {_field?.employee?.lastname}
                    <Controller
                      control={control}
                      name={`arr.[${index}].employee`}
                      defaultValue={watch(`arr.[${index}].employee._id`)}
                      render={({ field }) => <input type="hidden" {...field} />}
                    />
                    <div>
                      {isDaily && hasCurrentDayPayroll(_field?.employee) && (
                        <div className="text-sm font-semibold text-red-400">
                          มีการจ่ายค่าตอบแทนของพนักงานคนนี้ในวันนี้แล้ว
                        </div>
                      )}
                      {!isDaily && hasCurrentMonthPayroll(_field?.employee) && (
                        <div className="text-sm font-semibold text-red-400">
                          มีการจ่ายค่าตอบแทนของพนักงานคนนี้ในเดือนนี้แล้ว
                        </div>
                      )}
                    </div>
                  </TableCell>
                  <TableCell>
                    {isDaily && (
                      <div className="flex ">
                        <div className="w-1/2 px-2">
                          <Controller
                            name={`arr[${index}].daily_attribute.start_time_input`}
                            control={control}
                            defaultValue={
                              _field?.daily_attribute?.start_time
                                ? dayjs(
                                    _field?.daily_attribute?.start_time,
                                  ).format('HH:mm')
                                : dayjs().hour(8).minute(0).format('HH:mm')
                            }
                            render={({ field }) => (
                              <TextField
                                type="time"
                                value={field.value}
                                {...field}
                                onChange={(event) => {
                                  field.onChange(event.target.value);
                                  setValue(
                                    `arr[${index}].daily_attribute.start_time`,
                                    decodeTimeValue(event.target.value),
                                  );
                                  changeMainCost(
                                    decodeTimeValue(event.target.value),
                                    watch(
                                      `arr[${index}].daily_attribute.end_time`,
                                    ),
                                    _field?.employee?.earnings?.amount /
                                      hourPerDay,
                                    `arr[${index}]`,
                                    index,
                                  );
                                }}
                                label="เวลาเริ่มงาน"
                              />
                            )}
                          />
                        </div>
                        <div className="w-1/2 px-2">
                          <Controller
                            name={`arr[${index}].daily_attribute.end_time_input`}
                            control={control}
                            defaultValue={
                              _field?.daily_attribute?.end_time
                                ? dayjs(
                                    _field?.daily_attribute?.end_time,
                                  ).format('HH:mm')
                                : dayjs().hour(17).minute(0).format('HH:mm')
                            }
                            render={({ field }) => (
                              <TextField
                                type="time"
                                {...field}
                                onChange={(event) => {
                                  field.onChange(event.target.value);
                                  setValue(
                                    `arr[${index}].daily_attribute.end_time`,
                                    decodeTimeValue(event.target.value),
                                  );
                                  changeMainCost(
                                    watch(
                                      `arr[${index}].daily_attribute.start_time`,
                                    ),
                                    decodeTimeValue(event.target.value),
                                    _field?.employee?.earnings?.amount /
                                      hourPerDay,
                                    `arr[${index}]`,
                                    index,
                                  );
                                }}
                                label="เวลาเลิกงาน"
                              />
                            )}
                          />
                        </div>
                      </div>
                    )}
                    <div className="my-2 px-2">
                      <Controller
                        name={`arr[${index}].main_cost`}
                        control={control}
                        defaultValue={_field?.employee?.earnings?.amount}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            label="ค่าตอบแทน"
                            fullWidth
                            onChange={(event) => {
                              field.onChange(event.target.value);
                              const totalCost =
                                parseFloat(event.target.value) +
                                  calculateAllOTCost(index) || 0;
                              setValue(`arr[${index}].total_cost`, totalCost);
                            }}
                          />
                        )}
                      />
                    </div>
                    {isDaily && (
                      <div className="my-2 px-2">
                        คิดเป็น{' '}
                        {calculateManHour(
                          watch(`arr[${index}].daily_attribute.start_time`),
                          watch(`arr[${index}].daily_attribute.end_time`),
                        )}{' '}
                        ชั่วโมง
                      </div>
                    )}
                    {isDaily && (
                      <div className="my-2 px-2">
                        ค่าตอบแทนรายชั่วโมง{' '}
                        {_field?.employee?.earnings?.amount / hourPerDay} บาท
                      </div>
                    )}
                  </TableCell>
                  <TableCell>
                    <OverTimeForm
                      control={control}
                      index={index}
                      setValue={setValue}
                      watch={watch}
                      costPerHour={
                        _field?.employee?.earnings?.amount / hourPerDay
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <Controller
                      control={control}
                      name={`arr[${index}].total_cost`}
                      defaultValue={_field?.employee?.earnings?.amount}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          disabled
                          label="ค่าใช้จ่ายรวม"
                          fullWidth
                        />
                      )}
                    />
                  </TableCell>
                  <TableCell>
                    <Controller
                      control={control}
                      name={`arr[${index}].reason`}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label="หมายเหตุ"
                          fullWidth
                          multiline
                          rows={3}
                          size="small"
                        />
                      )}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}

MultiPayrollForm.propTypes = {
  control: PropTypes.func,
  watch: PropTypes.func,
  errors: PropTypes.object,
  isDaily: PropTypes.bool,
  setValue: PropTypes.func,
  selectedDate: PropTypes.object,
  hourPerDay: PropTypes.number,
  lateThreshold: PropTypes.number,
  brushOfThreshold: PropTypes.number,
  breakPerDay: PropTypes.number,
};

export default MultiPayrollForm;
