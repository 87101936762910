import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Card, Tab, Tabs } from '@mui/material';
import PropTypes from 'prop-types';

import { BackButton, MRPBox, ViewTitle } from '@/components';

/**
 * @function ManufacturingPlaning
 * @description Display a collections or a list of ManufacturingPlaning from database
 */

export default function ManufacturingPlaning({ title, subtitle }) {
  const [selectedTab, setSelectedTab] = useState(0);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;
  const information = useSelector((state) => state.information);
  const {
    setting: { mms: mmsSetting },
  } = information;

  return (
    <div>
      {renderTitle()}
      <BackButton />
      <div className="my-4">
        <Card>
          <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                value={selectedTab}
                onChange={(e, newValue) => setSelectedTab(newValue)}
                aria-label="basic tabs example"
              >
                <Tab value={0} label="ผลิตสินค้า" />
                {mmsSetting?.allowProduceMaterial && (
                  <Tab value={1} label="ผลิตวัตถุดิบ" />
                )}
                {mmsSetting?.allowUseBaseProject && (
                  <Tab value={2} label="ผลิตโปรเจกต์พื้นฐาน" />
                )}
              </Tabs>
            </Box>
          </Box>
        </Card>
        <div className="my-2">
          {selectedTab === 0 && <MRPBox.ProductManufacturingPlaning />}
          {selectedTab === 1 && mmsSetting?.allowProduceMaterial && (
            <MRPBox.MaterialManufacturingPlanning />
          )}
          {selectedTab === 2 && mmsSetting?.allowUseBaseProject && (
            <MRPBox.BaseProjectManufacturingPlaning />
          )}
        </div>
      </div>
    </div>
  );
}

ManufacturingPlaning.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

ManufacturingPlaning.defaultProps = {
  title: '',
  subtitle: '',
};
