import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Button } from '@mui/material';
import { useForm, useFieldArray } from 'react-hook-form';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Plus as PlusIcon } from 'react-feather';
import _ from 'lodash';
import { gql } from 'graphql-request';

import * as actions from '../../../redux/actions';
import { ProcessTemplateForm } from '../../../components/Forms';
import Loading from '../../../components/Loading';
import { Error } from '../../../components/Error';
import { ViewTitle } from '../../../components/ViewTitle';
import { BackButton } from '../../../components/Button';
import { MMS as MMSMiddleware } from '../../../utils/middleware';
import { app as appConfig } from '../../../configs';

const blankStep = {
  name: '',
  detail: '',
};

const EditProcessTemplate = ({ title, subtitle }) => {
  const dispatch = useDispatch();
  const product = useSelector((state) => state.product);
  const department = useSelector((state) => state.department);
  const information = useSelector((state) => state.information);
  const measurementType = useSelector((state) => state.measurementType);
  const resultingFunctionType = useSelector(
    (state) => state.resultingFunctionType,
  );
  const material = useSelector((state) => state.material);
  const history = useHistory();
  const params = useParams();

  // Setting React hook form
  const {
    formState: { errors },
    handleSubmit,
    control,
    setValue,
    watch,
  } = useForm({ steps: product?.steps });

  const { fields, remove, append, insert, move } = useFieldArray({
    control,
    name: 'steps',
  });

  const [name] = useState('');
  const [page] = useState(1);
  const [size] = useState(5);

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        await dispatch(actions.productGet(params?.id));
      } catch (error) {
        console.error('Product get all error', error);
      }
    };

    const fetchDepartment = async () => {
      try {
        await dispatch(actions.departmentAll({ name: '' }));
      } catch (error) {
        console.error('Error on Fetch Departments', error);
      }
    };

    const fetchMeasurementType = async () => {
      try {
        await dispatch(actions.measurementTypeAll({ name: '', page, size }));
      } catch (error) {
        console.error(error);
      }
    };

    const fetchResultingFunctionType = async () => {
      try {
        const query = gql`
        query FindResultingFunctionTypes {
          findResultingFunctionTypes(input:{page:1,size:${appConfig.maxFetchSize}}){
            rows {
              _id
              name
            }
          }
        }
        `;
        await dispatch(actions.resultingFunctionTypeAllQuery({ query }));
      } catch (error) {
        console.error(error);
      }
    };

    const fetchMaterial = async () => {
      try {
        const query = gql`
        query findMaterials {
          findMaterials(input:{page:1,size:${appConfig.maxFetchSize}}){
            rows {
              _id
              type_code
              name
            }
          }
        }
        `;
        await dispatch(actions.materialAllQuery({ query }));
      } catch (error) {
        console.error(error);
      }
    };

    fetchMaterial();
    fetchResultingFunctionType();
    fetchProduct();
    fetchDepartment();
    fetchMeasurementType();

    return () => {};
  }, [name, page, size, params]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const onSubmit = async (data, event) => {
    try {
      event.preventDefault();
      console.log('Raw Data', data);
      const preprocessedData = {
        ...data,
        steps: MMSMiddleware.Step(data.steps),
      };
      console.log('Pre Step Data', preprocessedData);
      await dispatch(actions.productPut(params.id, preprocessedData));
      alert('สำเร็จ');
      history.goBack();
      await dispatch(actions.productAll({ name, page, size }));
    } catch (error) {
      console.error(error);
    }
  };

  const renderForm = () => (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ProcessTemplateForm
        control={control}
        errors={errors}
        fields={fields}
        remove={remove}
        product={product}
        measurementType={measurementType}
        move={move}
        watch={watch}
        setValue={setValue}
        resultingFunctionType={resultingFunctionType}
        material={material}
      />
      <div className="flex flex-row justify-between gap-1 py-4">
        <Button
          variant="contained"
          type="button"
          color="secondary"
          onClick={() => {
            const arraySize = _.size(fields);
            // last index will be fixed last step
            if (information?.setting?.mms?.fixedLastStep?.enable === true) {
              if (_.last(fields)?.is_fixed_step === true) {
                const insertedIndex = arraySize - 1;
                insert(insertedIndex, blankStep);
              } else {
                append(blankStep);
              }
            } else {
              append(blankStep);
            }
          }}
        >
          <PlusIcon className="mr-2" size={20} />
          เพิ่ม
        </Button>
        <Button variant="contained" type="submit">
          บันทึก
        </Button>
      </div>
    </form>
  );

  console.log('watch', watch('steps'));
  if (
    product.isLoading ||
    product.rows ||
    department.isLoading ||
    measurementType?.isLoading
  ) {
    return <Loading />;
  }
  if (
    !product.isLoading &&
    product.isCompleted &&
    !department.isLoading &&
    department.isCompleted &&
    !measurementType?.isLoading &&
    measurementType?.isCompleted
  ) {
    return (
      <div>
        <div className="flex justify-between">
          <div>{renderTitle()}</div>
        </div>

        <div className="flex flex-row justify-start pb-4">
          <div>
            <BackButton />
          </div>
        </div>
        {renderForm()}
      </div>
    );
  }

  return <Error message={process?.message} />;
};

EditProcessTemplate.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

EditProcessTemplate.defaultProps = {
  title: '',
  subtitle: '',
};

export default EditProcessTemplate;
