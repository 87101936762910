import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Card,
  CardContent,
  TablePagination,
  TableContainer,
  IconButton,
  Snackbar,
} from '@mui/material';
import dayjs from 'dayjs';
import 'dayjs/locale/th';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import { Copy as CopyIcon } from 'react-feather';

import * as CONSTANT from '../../../utils/constants';
import { notNan } from '../../../utils/functions';

dayjs.locale('th');
dayjs.extend(buddhistEra);

const ManufacturingMaterialTable = ({
  manufacturingMaterials,
  size,
  page,
  total,
  handleChangePage,
}) => {
  // Your function Go here
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const numberFormatting = (row) => {
    try {
      if ((row?.amount / row?.begin) * 100 === Infinity) {
        return '100';
      }
      if ((row?.amount / row?.begin) * 100 === -Infinity) {
        return '-';
      }
      return ((row?.amount / row?.begin) * 100)?.toFixed(2);
    } catch (error) {
      return '-';
    }
  };

  return (
    <div className="my-4">
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message="คัดลองไปที่คลิปบอร์ดแล้ว"
      />
      <Card>
        <CardContent>
          <div className="overflow-x-auto">
            <TableContainer>
              <Table sx={{ minWidth: 650 }}>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <div className="font-bold">ลำดับที่</div>
                    </TableCell>
                    <TableCell>
                      <div className="font-bold"> วันที่</div>
                    </TableCell>
                    <TableCell>
                      <div className="font-bold">การดำเนินการ</div>
                    </TableCell>
                    <TableCell>
                      <div className="font-bold">วัตถุดิบ</div>
                    </TableCell>
                    <TableCell>
                      <div className="font-bold">จากขั้นตอน</div>
                    </TableCell>
                    <TableCell>
                      <div className="font-bold">ผู้ดำเนินการ</div>
                    </TableCell>
                    <TableCell>
                      <div className="font-bold">เดิม</div>
                    </TableCell>
                    <TableCell>
                      <div className="font-bold">ดำเนินการ</div>
                    </TableCell>
                    <TableCell>
                      <div className="font-bold">คงเหลือ</div>
                    </TableCell>
                    <TableCell>
                      <div className="font-bold">Yield</div>
                    </TableCell>
                    <TableCell>
                      <div className="font-bold">Transaction ID</div>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!_.isEmpty(manufacturingMaterials) ? (
                    _.map(manufacturingMaterials, (row, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          <div className="text-xs">{index + 1}</div>
                        </TableCell>
                        <TableCell>
                          {dayjs(row?.createdAt)?.format('D MMM BBBB')} <br />
                          {dayjs(row?.createdAt)?.format('HH.mm น.')}
                        </TableCell>
                        <TableCell>
                          <div
                            className={`font-semibold ${
                              row?.transaction_dir === true
                                ? 'text-green-500'
                                : 'text-red-500'
                            }`}
                          >
                            {
                              CONSTANT.MANUFACTURING_TRANSACTION_TYPE[
                                row?.transaction_type
                              ]?.description
                            }
                          </div>
                        </TableCell>
                        <TableCell>
                          {row?.material?.type_code} {row?.material?.name}
                        </TableCell>
                        <TableCell>{row?.step?.name}</TableCell>
                        <TableCell>
                          <div>{row?.employee?.firstname}</div>
                          <div>{row?.employee?.lastname}</div>
                        </TableCell>
                        <TableCell>{notNan(row?.begin)}</TableCell>
                        <TableCell>{notNan(row?.quantity)}</TableCell>
                        <TableCell>{notNan(row?.amount)}</TableCell>
                        <TableCell>{numberFormatting(row)} %</TableCell>
                        <TableCell>
                          <div className="flex">
                            <div className="w-28 overflow-hidden self-center">
                              {_.truncate(row?._id, { length: 15 })}
                            </div>
                            <div>
                              <IconButton
                                onClick={() => {
                                  navigator.clipboard.writeText(row?._id);
                                  setSnackbarOpen(true);
                                }}
                              >
                                <CopyIcon size={14} />
                              </IconButton>
                            </div>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={9}>
                        <div className="text-center">ไม่มีข้อมูล</div>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>{' '}
            </TableContainer>
          </div>
          <TablePagination
            component="div"
            rowsPerPageOptions={[]}
            page={page - 1}
            count={total || 1}
            rowsPerPage={size}
            onPageChange={handleChangePage}
          />
        </CardContent>
      </Card>
    </div>
  );
};

ManufacturingMaterialTable.propTypes = {
  manufacturingMaterials: PropTypes.arrayOf(PropTypes.object),
  size: PropTypes.number,
  page: PropTypes.number,
  total: PropTypes.number,
  handleChangePage: PropTypes.func,
};

export default ManufacturingMaterialTable;
