import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { Card, Button } from '@mui/material';
import { useParams, useHistory } from 'react-router';
import PropTypes from 'prop-types';
import * as actions from '../../redux/actions';
import { BackButton } from '../../components/Button';
import {
  MaterialRequestForm,
  SelectMaterialForm,
} from '../../components/Forms';
import { Loading } from '../../components/Loading';
import { ViewTitle } from '../../components/ViewTitle';
import { MATERIAL_REQUEST_STATUS } from '../../utils/constants';

const defaultValues = {
  material: '',
  remark: '',
};

export default function EditMaterialRequest({ title, subtitle }) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const material = useSelector((state) => state.material);
  const place = useSelector((state) => state.place);
  const department = useSelector((state) => state.department);
  const me = useSelector((state) => state.me);
  const materialRequest = useSelector((state) => state.materialRequest);
  const history = useHistory();
  const [selectMaterial, setSelectMaterial] = useState([]);
  const [name] = useState('');
  const [page] = useState(1);
  const [size] = useState(5);
  const {
    formState: { errors },
    handleSubmit,
    control,
    reset,
  } = useForm();

  useEffect(() => {
    dispatch(actions.materialRequestGet(id));
    dispatch(actions.materialAll({ name: '', size: '', page: 1 }));
    dispatch(actions.placeAll(''));
    dispatch(actions.departmentAll(''));
    dispatch(actions.meGet(''));
    return () => {};
  }, [name, page, size]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const onSubmit = async (data, event) => {
    try {
      data.material = selectMaterial;
      data.status = MATERIAL_REQUEST_STATUS.PENDING_APPROVAL.status_code;
      event.preventDefault();
      await dispatch(actions.materialRequestPut(id, data));
      dispatch(actions.materialRequestAll({ name: '', size: '', page: 1 }));
      reset(defaultValues);
      alert('สำเร็จ');
      history.goBack();
    } catch (error) {
      console.log(error);
    }
  };
  const renderForm = () => (
    <Card className="p-4">
      <div>
        <MaterialRequestForm
          Controller={Controller}
          control={control}
          errors={errors}
          place={place.rows}
          department={department.rows}
          me={me.userData}
          materialRequest={materialRequest}
        />
      </div>
    </Card>
  );

  const renderSelectMaterial = () => (
      <Card>
        <SelectMaterialForm
          material={material.rows}
          setSelectMaterial={setSelectMaterial}
          materialRequest={materialRequest}
        />
      </Card>
    );
  if (materialRequest.isLoading || material.isLoading) {
    return <Loading />;
  }
  if (
    materialRequest.isCompleted &&
    materialRequest.material &&
    material.isCompleted
  ) {
    return (
      <div>
        {renderTitle()}
        <div className="flex flex-row justify-start pb-4">
          <div>
            <BackButton />
          </div>
        </div>
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-row flex-wrap">
              <div className="w-full lg:w-1/3 px-1 py-2">{renderForm()}</div>
              <div className="w-full lg:w-2/3 px-1 py-2">
                {renderSelectMaterial()}
              </div>
            </div>
            <div className="flex flex-row justify-end gap-1 py-4">
              <Button variant="contained" type="submit">
                บันทึก
              </Button>
            </div>
          </form>
        </div>
      </div>
    );
  }
  return <Loading />;
}

EditMaterialRequest.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

EditMaterialRequest.defaultProps = {
  title: '',
  subtitle: '',
};
