import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Button,
  Card,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { Error } from '../../components/Error';
import Loading from '../../components/Loading';
import { ViewTitle } from '../../components/ViewTitle';
import { app as appConfig } from '../../configs';
import * as actions from '../../redux/actions';

export default function ResultingFunctionTypes({ title, subtitle }) {
  const dispatch = useDispatch();
  const resultingFunctionType = useSelector(
    (state) => state.resultingFunctionType,
  );

  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState();
  const [searchTerm, setSearchTerm] = useState('');
  const [name, setName] = useState('');

  const getAllResultingFunctionType = async () => {
    try {
      await dispatch(actions.resultingFunctionTypeAll({ name, page, size }));
    } catch (error) {
      console.error('Cannot fetch all resulting function types', error);
    }
  };

  // TODO: Using graphql instead
  const fetchAllOrganizationalConstant = async () => {
    try {
      await dispatch(
        actions.organizationConstantAll({
          name: '',
          page: 1,
          size: appConfig.maxFetchSize,
        }),
      );
    } catch (error) {
      console.error('Cannot fetch all organization constant');
    }
  };

  // TODO: Using graphql instead
  const fetchAllMeasurementType = async () => {
    try {
      await dispatch(
        actions.measurementTypeAll({
          name: '',
          page: 1,
          size: appConfig.maxFetchSize,
        }),
      );
    } catch (error) {
      console.error('Cannot fetch All measurement types');
    }
  };

  useEffect(() => {
    getAllResultingFunctionType();
    fetchAllMeasurementType();
    fetchAllOrganizationalConstant();
    return () => {};
  }, [name, page, size]);

  useEffect(() => {
    setTotal(resultingFunctionType?.total);
    return () => {};
  }, [resultingFunctionType]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchTerm);
      setPage(1);
    }, 700);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };

  const handleDelete = async (id) => {
    try {
      const confirm = window.confirm('ยืนยันการลบฟังก์ชันการแสดงผล');
      if (confirm) {
        await dispatch(actions.resultingFunctionTypeDelete(id));
      }
      getAllResultingFunctionType();
    } catch (error) {
      alert('ลบฟังก์ชันการแสดงผลไม่สำเร็จ');
      console.error('Cannot remove conversion', error);
    }
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const renderAddButtom = () => (
    <div className="flex flex-row justify-end pb-4">
      <div>
        <Link to="/km/resulting-function-type/create">
          <Button variant="contained">เพิ่ม</Button>
        </Link>
      </div>
    </div>
  );

  const renderSearch = () => (
    <Card className="my-2">
      <div className="p-4 flex flex-row">
        <div className="w-full md:w-1/2">
          <TextField
            label="ค้นหา"
            fullWidth
            size={'small'}
            id="outlined-start-adornment"
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <i className="fas fa-search"></i>
                </InputAdornment>
              ),
            }}
          />
        </div>
      </div>
    </Card>
  );

  const renderTable = () => (
    <Paper>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ลำดับที่</TableCell>
              <TableCell>ชื่อ</TableCell>
              <TableCell>ดำเนินการ</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {_.map(resultingFunctionType?.rows, (_resFnType, index) => (
              <TableRow key={index}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{_resFnType?.name}</TableCell>
                <TableCell>
                  <div className="flex gap-2">
                    <Link
                      to={`/km/resulting-function-type/detail/${_resFnType?._id}`}
                    >
                      <Button variant="contained" color="primary" size="small">
                        รายละเอียด
                      </Button>
                    </Link>
                    <Link
                      to={`/km/resulting-function-type/edit/${_resFnType?._id}`}
                    >
                      <Button size="small" variant="contained" color="warning">
                        แก้ไข
                      </Button>
                    </Link>
                    <Button
                      size="small"
                      variant="contained"
                      color="error"
                      onClick={() => handleDelete(_resFnType?._id)}
                    >
                      ลบ
                    </Button>
                  </div>
                </TableCell>
              </TableRow>
            ))}
            {_.isEmpty(resultingFunctionType?.rows) && (
              <TableRow>
                <TableCell colSpan={5}>
                  <div className="text-center">ไม่มีข้อมูล</div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        page={page - 1}
        //  rowsPerPageOptions={[]}
        onRowsPerPageChange={handleChangeRowsPerPage}
        count={total || 1}
        rowsPerPage={size}
        onPageChange={handleChangePage}
      />
    </Paper>
  );

  if (resultingFunctionType.isLoading) {
    return <Loading />;
  }
  if (!resultingFunctionType.isLoading && resultingFunctionType.isCompleted) {
    return (
      <div>
        <div className="flex flex-wrap justify-between">
          {renderTitle()}
          {renderAddButtom()}
        </div>
        {renderSearch()}
        {renderTable()}
      </div>
    );
  }
  return <Error />;
}

ResultingFunctionTypes.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

ResultingFunctionTypes.defaultProps = {
  title: '',
  subtitle: '',
};
