import React, { useEffect, useState } from 'react';
import {
  Filter as FilterIcon,
  Monitor as MonitorIcon,
  RefreshCw,
  Smartphone as PhoneIcon,
} from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Card,
  FormControl,
  FormControlLabel,
  InputAdornment,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import dayjs from 'dayjs';
import _ from 'lodash';
import PropTypes from 'prop-types';

import {
  CardStepList,
  Error,
  Loading,
  LoadingLinear,
  StepTable,
  ViewTitle,
} from '@/components';
import * as actions from '@/redux/actions';
import * as CONSTANT from '@/utils/constants';

const Works = ({ title, subtitle }) => {
  // Setting
  const dispatch = useDispatch();
  const history = useHistory();
  const step = useSelector((state) => state.step);
  const me = useSelector((state) => state.me);
  const department = useSelector((state) => state.department);
  const information = useSelector((state) => state.information);
  const [searchTerm, setSearchTerm] = useState('');
  const [name, setName] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(undefined);
  const [displayAdvanceFilter, setDisplayAdvanceFilter] = useState(false);
  const [filterAssigned, setFilterAssigned] = useState('ALL');
  const [useRedis, setIsUseRedis] = useState(true);
  const [selectedView, setSelectedView] = useState(
    window.screen.width >= 768
      ? CONSTANT.DISPLAY_TYPE.TABLE.status_code
      : CONSTANT.DISPLAY_TYPE.CARD.status_code,
  );

  const getInitialSelectedDepartment = () => {
    if (me?.userData?.role?.level >= 1) {
      return '';
    }
    if (_.isEmpty(me?.userData?.other_departments)) {
      return me?.userData?.department?._id;
    }
    const combinedDepartment = _.map(
      me?.userData?.other_departments,
      (dept) => dept._id,
    );
    combinedDepartment.push(me?.userData?.department?._id);
    console.log(
      'Combined Department',
      _.map(combinedDepartment, (data) => data),
    );
    return _.map(combinedDepartment, (data) => data);
  };

  const [selectedDepartment, setSelectedDepartment] = useState(
    getInitialSelectedDepartment(),
  );

  const checkAssignStatus = () => {
    switch (filterAssigned) {
      case 'ALL':
        return '';
      case 'ASSIGNED':
        return 'true';
      case 'UNASSIGNED':
        return 'false';
      default:
        return '';
    }
  };

  useEffect(() => {
    dispatch(actions.stepReset());
    return () => {};
  }, [page]);

  const stepAll = async () => {
    try {
      dispatch(
        actions.stepAll({
          name,
          page,
          size,
          department: selectedDepartment,
          assigned: checkAssignStatus(),
          forceWithoutCache: useRedis ? '' : true,
        }),
      );
    } catch (error) {
      console.error('Fetch Step Error', error);
    }
  };

  const handleAuditStep = async (id) => {
    try {
      await dispatch(actions.stepAuditOne(id));
      stepAll();
    } catch (error) {
      alert(`Error on Step Auditing ${error?.message}`);
    }
  };

  // Initialization
  useEffect(() => {
    stepAll();

    return () => {};
  }, [page, size, selectedDepartment, filterAssigned, useRedis, name]);

  useEffect(() => {
    const fetchDepartment = async () => {
      try {
        await dispatch(actions.departmentAll({ name: '' }));
      } catch (error) {
        console.error('Fetch Department Error', error);
      }
    };

    fetchDepartment();
    return () => {};
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchTerm);
      setPage(1);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  useEffect(() => {
    setTotal(step?.total);
    return () => {};
  }, [step]);

  useEffect(() => {
    setPage(1);
    return () => {};
  }, [filterAssigned]);

  // Functions

  const handleToInfoPage = (id) => {
    history.push(`works/view/${id}`);
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleAcceptWork = async (stepInfo) => {
    const confirm = window.confirm('ยืนยันการรับงาน');
    if (confirm) {
      try {
        await dispatch(
          actions.stepPut(stepInfo?._id, {
            responsible: {
              department: stepInfo?.responsible?.department?._id,
              employee: me?.userData?._id,
              accepted_date: dayjs().toISOString(),
            },
            employeeId: me?.userData?._id,
            manufacturing_transaction_type:
              CONSTANT.MANUFACTURING_TRANSACTION_TYPE.WORK_ACCEPT.status_code,
          }),
        );
        dispatch(
          actions.stepAll({
            name,
            page,
            size,
            department: selectedDepartment,
            assigned: checkAssignStatus(),
            forceWithoutCache: true,
          }),
        );
      } catch (error) {
        window.alert('รับงานไม่สำเร็จ');
        console.error('Error on Accept Work', error);
      }
    }
  };

  const tableProps = {
    step,
    page,
    size,
    total,
    name,
    handleToInfoPage,
    handleAcceptWork,
    handleChangeRowsPerPage,
    handleChangePage,
    handleAuditStep,
  };

  // Component Rendering
  const renderTitle = () => (
    <div className="flex justify-between">
      <ViewTitle title={title} subtitle={subtitle} />
      <div>
        <Button
          variant="contained"
          color="teal"
          startIcon={<RefreshCw size={16} />}
          onClick={() => {
            setIsUseRedis(false);
          }}
        >
          รีเฟรช
        </Button>
      </div>
    </div>
  );

  const renderSearch = () => (
    <Card>
      <div className="p-4 flex flex-row flex-wrap">
        <div className="w-full md:w-1/2 px-2 py-1">
          <TextField
            label="ค้นหา"
            fullWidth
            size={'small'}
            id="outlined-start-adornment"
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <i className="fas fa-search"></i>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className="w-full md:w-3/12 px-2 py-1">
          {me?.userData?.role?.level >= 1 && (
            <FormControl sx={{ minWidth: 120 }} fullWidth={true}>
              <InputLabel id="type" size={'small'}>
                ค้นหาด้วยแผนก
              </InputLabel>
              <Select
                label="ค้นหาด้วยแผนก"
                size={'small'}
                fullWidth
                onChange={(event) => {
                  setSelectedDepartment(event.target.value);
                }}
              >
                <MenuItem value="">ทั้งหมด</MenuItem>
                {_.map(department?.rows, (_department, index) => (
                  <MenuItem value={_department?._id} key={index}>
                    {_department?.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </div>
        <div className="w-1/2 md:w-2/12 px-2 py-1">
          <Button
            variant="contained"
            color="info"
            startIcon={<FilterIcon size={16} />}
            onClick={() => setDisplayAdvanceFilter(!displayAdvanceFilter)}
          >
            {displayAdvanceFilter ? 'ปิดตัวกรอง' : 'เปิดตัวกรอง'}
          </Button>
        </div>
        <div className="w-1/3 md:w-1/12 px-1 flex justify-end">
          <ToggleButtonGroup
            value={selectedView}
            exclusive
            size="small"
            onChange={(event, value) => setSelectedView(value)}
          >
            <ToggleButton value={CONSTANT.DISPLAY_TYPE.TABLE.status_code}>
              <MonitorIcon size="16" />
            </ToggleButton>
            <ToggleButton value={CONSTANT.DISPLAY_TYPE.CARD.status_code}>
              <PhoneIcon size="16" />
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
      </div>
      <div className={` ${displayAdvanceFilter ? '' : 'hidden'}`}>
        <div className="flex flex-wrap  mx-4 px-4 my-2">
          <div className="w-full md:w-1/3 px-1">
            <FormControl component="fieldset">
              <RadioGroup>
                <FormControlLabel
                  control={
                    <Radio
                      checked={filterAssigned === 'ALL'}
                      onChange={() => {
                        setFilterAssigned('ALL');
                      }}
                    />
                  }
                  label="ทุกงาน"
                />
                <FormControlLabel
                  control={
                    <Radio
                      checked={filterAssigned === 'ASSIGNED'}
                      onChange={() => {
                        setFilterAssigned('ASSIGNED');
                      }}
                    />
                  }
                  label="เฉพาะที่มีผู้รับผิดชอบ"
                />
                <FormControlLabel
                  control={
                    <Radio
                      checked={filterAssigned === 'UNASSIGNED'}
                      onChange={() => {
                        setFilterAssigned('UNASSIGNED');
                      }}
                    />
                  }
                  label="เฉพาะที่ไม่มีผู้รับผิดชอบ"
                />
              </RadioGroup>
            </FormControl>
          </div>
        </div>
      </div>
    </Card>
  );

  if (!department.isLoading && department.isCompleted) {
    return (
      <div>
        {renderTitle()}
        {renderSearch()}
        {step?.isLoading && <LoadingLinear />}
        {!step.isLoading && step.isCompleted && (
          <div>
            {selectedView === CONSTANT.DISPLAY_TYPE.TABLE.status_code ? (
              <StepTable {...tableProps} />
            ) : (
              <CardStepList {...tableProps} />
            )}
          </div>
        )}
      </div>
    );
  }

  return <Error />;
};

Works.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

Works.defaultProps = {
  title: '',
  subtitle: '',
};
export default Works;
