import React from 'react';
import { Controller, useFieldArray } from 'react-hook-form';
import {
  Autocomplete,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { ProdOrMatQuantityInput } from '@/components/Inputs';

function AddMetalToWarehouseForm({
  area,
  control,
  customer,
  product,
  warehouse,
  watch,
  setValue,
}) {
  const { append, remove, fields } = useFieldArray({
    control,
    name: 'product_stock_lot',
  });
  return (
    <div>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ลำดับที่</TableCell>
              <TableCell>สินค้า</TableCell>
              <TableCell>คลังสินค้า</TableCell>
              <TableCell>จำนวน</TableCell>
              <TableCell>หมายเลขล็อต</TableCell>
              <TableCell>ดำเนินการ</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {_.map(fields, (eachField, index) => (
              <TableRow key={eachField?.id}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>
                  {!_.isEmpty(product?.rows) && (
                    <Controller
                      control={control}
                      name={`product_stock_lot[${index}].product`}
                      render={({ field }) => (
                        <Autocomplete
                          {...field}
                          disablePortal
                          options={product?.rows}
                          size="small"
                          placeholder="สินค้า"
                          onChange={(e, newValue) => {
                            field.onChange(newValue);
                          }}
                          // prettier-ignore
                          getOptionLabel={(option) => `${option?.name || ''}`
                        }
                          renderInput={(params) => (
                            <TextField label="สินค้า" {...params} />
                          )}
                        />
                      )}
                    />
                  )}
                </TableCell>
                <TableCell>
                  {!_.isEmpty(warehouse?.rows) && (
                    <Controller
                      control={control}
                      name={`product_stock_lot[${index}].warehouse`}
                      render={({ field }) => (
                        <Autocomplete
                          {...field}
                          disablePortal
                          options={warehouse?.rows}
                          size="small"
                          placeholder="คลังสินค้า"
                          onChange={(e, newValue) => {
                            field.onChange(newValue);
                          }}
                          // prettier-ignore
                          getOptionLabel={(option) => `${option?.name || ''}`
                      }
                          renderInput={(params) => (
                            <TextField label="คลังสินค้า" {...params} />
                          )}
                        />
                      )}
                    />
                  )}
                </TableCell>
                <TableCell>
                  <ProdOrMatQuantityInput
                    controllerName={`product_stock_lot[${index}].quantity`}
                    artificialControllerName={`product_stock_lot[${index}].quantity_artificial`}
                    selectedUnitControllerName={`product_stock_lot[${index}].selected_unit`}
                    watch={watch}
                    setValue={setValue}
                    control={control}
                    selectedProduct={watch(
                      `product_stock_lot[${index}].product`,
                    )}
                    index={index}
                  />
                </TableCell>
                <TableCell>
                  <Controller
                    control={control}
                    name={`product_stock_lot[${index}].lot_number`}
                    render={({ field }) => (
                      <TextField
                        size="small"
                        label="หมายเลขล็อต"
                        fullWidth
                        {...field}
                      />
                    )}
                  />
                </TableCell>
                <TableCell>
                  <Button
                    size="small"
                    variant="contained"
                    color="error"
                    onClick={() => remove(index)}
                  >
                    ลบ
                  </Button>
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell></TableCell>
              <TableCell>
                <Button
                  onClick={() => append({})}
                  size="small"
                  variant="contained"
                  color="primary"
                >
                  เพิ่ม
                </Button>
              </TableCell>
              <TableCell colSpan={4} />
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

AddMetalToWarehouseForm.propTypes = {};

export default AddMetalToWarehouseForm;
