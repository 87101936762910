/* eslint-disable import/no-named-as-default-member */
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button } from '@mui/material';
import { gql } from 'graphql-request';
import _ from 'lodash';
import hash from 'object-hash';
import PropTypes from 'prop-types';

import {
  BackButton,
  Error,
  Loading,
  LoadingDialogWithTimeout,
  ManufacturingOrderForm,
  MOFromTemplateLoadingForm,
  SocketIOLoading,
  ViewTitle,
} from '@/components';
import { app as appConfig } from '@/configs';
import graphqlClient from '@/configs/graphqlClient';
import * as actions from '@/redux/actions';
import { MQ_TASK } from '@/utils/constants';
import { MMS as MMSMiddleWare } from '@/utils/middleware';

/**
 * @function CreateManufacturingOrder
 * @memberof MMS/ManufacturingOrder
 * @description หน้าสำหรับสร้างคำสั่งผลิต มีการใช้ฟอร์มคำสั่งผลิตที่มีเงื่อนไขมากมายอยู่ในนั้น
 * ตามเงื่อนไขของ Setting หลังจาก Submit หน้านี้แล้วระบบจะ Fetch คำสั่งผลิตที่ได้มาอัตโนมัติ
 * จากนั้นจะ Redirect ไปหน้า Assign งานให้พนักงาน
 */
function CreateManufacturingOrder({ title, subtitle }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const products = useSelector((state) => state.product);
  const materials = useSelector((state) => state.material);
  const customer = useSelector((state) => state.customer);
  const paymentType = useSelector((state) => state.paymentType);
  const information = useSelector((state) => state.information);
  const customerType = useSelector((state) => state.customerType);
  const processTemplate = useSelector((state) => state.processTemplate);
  const machine = useSelector((state) => state.machine);
  const me = useSelector((state) => state.me);
  const moTemplate = useSelector((state) => state.moTemplate);
  const bomTemplate = useSelector((state) => state.bomTemplate);
  const baseProject = useSelector((state) => state.baseProject);
  const [isReady, setIsReady] = useState(true);
  const [dialogLoading, setIsDialogLoadingOn] = useState(false);
  const [referenceNumber, setReferenceNumber] = useState('');
  const [createdStatus, setCreatedStatus] = useState('');

  useEffect(() => {
    const refNo = hash({
      date: new Date(),
      user: me?.userData?._id,
    });

    setReferenceNumber(refNo);

    return () => {};
  }, []);

  const {
    formState: { errors },
    handleSubmit,
    control,
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      processes: [
        {
          product: products.rows && products.rows[0],
          quantity: 1,
          materials: [
            { materials: materials.rows && materials.rows[0], begin: 1 },
          ],
          noQuantity: false,
        },
      ],
    },
  });
  const query = gql`
    query findProcessTemplates(
      $processTemplateInput: ProcessTemplateInput
      $machineinput: MachineInput
      $moTemplateInput: MOTemplateInput
      $bomTemplateInput: BomTemplateInput
      $baseProjectInput: BaseProjectInput
    ) {
      findProcessTemplates(input: $processTemplateInput) {
        rows {
          _id
          name
          steps {
            name
            materials {
              material {
                _id
                name
                type_code
              }
            }
          }
        }
      }
      findMachines(input: $machineinput) {
        rows {
          _id
          name
        }
      }
      findMOTemplates(input: $moTemplateInput) {
        rows {
          _id
          name
        }
      }
      findBomTemplates(input: $bomTemplateInput) {
        total
        rows {
          _id
          name
          tag
          bill_of_materials {
            amount
            tag
            material {
              _id
              name
              type_code
              use_unit_conversion
              unit
              conversion_from {
                unit_a
                unit_b
                conversion_factor
              }
              conversion_to {
                unit_b
                unit_a
                conversion_factor
              }
              unit_input {
                _id
                short_sign
                name
              }
              unit_output {
                _id
                short_sign
                name
              }
            }
          }
        }
      }
      findBaseProjects(input: $baseProjectInput) {
        rows {
          _id
          type_code
          name
          unit
          attach_process_template
          attach_bom_template
          process_template {
            _id
            name
          }
          bom_template {
            _id
            name
          }
        }
      }
    }
  `;
  const onSubmit = async (data) => {
    try {
      if (!_.size(data.processes)) {
        alert('กรุณาเพิ่มรายการอย่างน้อย 1 รายการ');
        return;
      }
      setIsDialogLoadingOn(true);
      const newProcesses = _.map(data.processes, (_process) => ({
        ..._process,
        quantity: _process?.noQuantity === true ? 0 : _process?.quantity,
        produce_base_project_instead:
          information?.setting?.mms?.allowUseBaseProject,
        additional_unit: _process.changeUnit
          ? _process.additional_unit
          : _process?.product?._unit,
        product: information?.setting?.mms?.allowUseBaseProject
          ? null
          : _process?.product,
      }));
      const payload = {
        ...data,
        processes: newProcesses,
        assigner: me?.userData?._id,
        employeeId: me?.userData?._id,
      };
      console.log(
        'Create Manufacturing Order Payload',
        MMSMiddleWare.ManufacturingOrder(payload),
      );

      // Waiting Socket
      // TODO: Implatemented

      await dispatch(
        actions.manufacturingOrderCreate(
          MMSMiddleWare.ManufacturingOrder({ ...payload, referenceNumber }),
        ),
      );

      // setIsDialogLoadingOn(false);
      // setCreated(true);
    } catch (error) {
      window.alert(`ไม่สามารถสร้างคำสั่งผลิตได้ ${error?.message}`);
      console.error('Cannot Create Manufacturing Order', error);
    }
  };

  const handleSocketIOFunction = {
    onFail: (args) => {
      setIsDialogLoadingOn(false);
      alert(args?.message);
    },
    onSuccess: (args) => {
      setIsDialogLoadingOn(false);
      if (information?.setting?.mms?.skipAssignmentPage) {
        if (information?.setting?.mms?.dashboard?.showDashboardC) {
          history.push(
            `/mms/manufacturing-orders-c/view/${args?.payload?._id}`,
          );
        } else {
          history.push(`/mms/manufacturing-orders/view/${args?.payload?._id}`);
        }
      } else {
        history.push(`/mms/manufacturing-orders/assign/${args?.payload?._id}`);
      }
    },
  };

  const queryDataFromServer = async () => {
    try {
      const queryResult = await graphqlClient.request(query, {
        processTemplateInput: { page: 1, size: appConfig.maxFetchSize },
        machineinput: {
          page: 1,
          size: appConfig.maxFetchSize,
        },
        bomTemplateInput: {
          page: 1,
          size: appConfig.maxFetchSize,
        },
        baseProjectInput: {
          page: 1,
          size: appConfig.maxFetchSize,
        },
      });
      const foundedProcessTemplate = queryResult?.findProcessTemplates;
      const foundedMachine = queryResult?.findMachines;
      const foundedMOTemplate = queryResult?.findMOTemplates;
      const foundedBomTemplate = queryResult?.findBomTemplates;
      const foundedBaseProject = queryResult?.findBaseProjects;
      dispatch(actions.processTemplateStateSet(foundedProcessTemplate));
      dispatch(actions.machineStateSet(foundedMachine));
      dispatch(actions.moTemplateStateSet(foundedMOTemplate));
      dispatch(actions.bomTemplateStateSet(foundedBomTemplate));
      dispatch(actions.baseProjectStateSet(foundedBaseProject));
    } catch (error) {
      console.error('Error On Fetching', error);
    }
  };

  const onLoadDataFromTemplate = (controllerValue, payload) => {
    setIsReady(false);
    setValue(controllerValue, payload);
    console.log('payload value', payload);
    console.log('Watch Data', watch(controllerValue));
    setIsReady(true);
  };

  useEffect(() => {
    const fetchMaterials = async () => {
      try {
        await dispatch(
          actions.materialAll({
            name: '',
            size: appConfig.maxFetchSize,
            fetchStockLot: false,
          }),
        );
      } catch (error) {
        console.error('Get Materials Fail', error);
      }
    };

    const fetchProduct = async () => {
      try {
        dispatch(
          actions.productAll({
            name: '',
            size: appConfig.maxFetchSize,
            fetchStockLot: false,
            fetchBOM: true,
          }),
        );
      } catch (error) {
        console.error('Fetch Product Fail', error);
      }
    };

    const fetchCustomer = async () => {
      try {
        dispatch(
          actions.customerAll({ name: '', size: appConfig.maxFetchSize }),
        );
      } catch (error) {
        console.error('Fetch Customers Fail', error);
      }
    };

    const fetchPaymentTransactionType = async () => {
      try {
        dispatch(actions.paymentTypeAll({}));
      } catch (error) {
        console.error('Fetch Payment Transaction Type Fail', error);
      }
    };

    const fetchCustomerType = async () => {
      try {
        dispatch(actions.customerTypeAll({}));
      } catch (error) {
        console.error('Fetch Customer Types Fail', error);
      }
    };

    fetchProduct();
    fetchMaterials();
    fetchCustomer();
    fetchPaymentTransactionType();
    fetchCustomerType();
    queryDataFromServer();
    return () => {};
  }, []);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (
    materials.isLoading ||
    products.isLoading ||
    customer.isLoading ||
    paymentType.isLoading
  ) {
    return <Loading />;
  }

  return (
    <div>
      <div className="flex justify-between">
        <div>{renderTitle()}</div>
      </div>
      <div className="flex flex-row justify-start pb-4">
        <div>
          <BackButton />
        </div>
      </div>
      <SocketIOLoading
        referenceNumber={referenceNumber}
        taskCode={MQ_TASK.INSERT_MANUFAC_ORDER.status_code}
        handleFail={handleSocketIOFunction.onFail}
        handleSuccess={handleSocketIOFunction.onSuccess}
        setStatusMessage={setCreatedStatus}
      />

      <div className="py-2">
        <LoadingDialogWithTimeout
          isLoading={dialogLoading}
          label={`กำลังสร้างคำสั่งผลิตใหม่ ${createdStatus || ''}`}
        />
        <form onSubmit={handleSubmit(onSubmit)}>
          {information?.setting?.mms?.allowUseMOTemplate && (
            <MOFromTemplateLoadingForm
              moTemplate={moTemplate}
              setCreateMO={onLoadDataFromTemplate}
            />
          )}
          <ManufacturingOrderForm
            control={control}
            errors={errors}
            watch={watch}
            setValue={setValue}
            customer={customer}
            materials={materials}
            products={products}
            information={information}
            paymentType={paymentType}
            customerType={customerType}
            processTemplate={processTemplate}
            machine={machine}
            isReady={isReady}
            bomTemplate={bomTemplate}
            baseProject={baseProject}
          />

          <div className="flex flex-row justify-end">
            <Button variant="contained" type="submit">
              บันทึก
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}

CreateManufacturingOrder.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

CreateManufacturingOrder.defaultProps = {
  title: '',
  subtitle: '',
};

export default CreateManufacturingOrder;
