import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Card, CardContent } from '@mui/material';
import PropTypes from 'prop-types';

import { BackButton } from '../../components/Button';
import { Error } from '../../components/Error';
import { ResultingFunctionTypeForm } from '../../components/Forms';
import Loading from '../../components/Loading';
import { ViewTitle } from '../../components/ViewTitle';
import { app as appConfig } from '../../configs';
import api from '../../configs/api';
import * as actions from '../../redux/actions';

export default function CreateResultingFunctionType({ title, subtitle }) {
  const dispatch = useDispatch();
  const measurementType = useSelector((state) => state.measurementType);
  const organizationConstant = useSelector(
    (state) => state.organizationConstant,
  );

  const history = useHistory();
  const [allResutlingFT, setAllResultingFT] = useState({});

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm({});

  const onSubmit = async (data) => {
    try {
      await dispatch(actions.resultingFunctionTypeCreate(data));
      alert('สร้างการแสดงผลสำเร็จ');
      history.goBack();
    } catch (error) {
      window.alert(`สร้างฟังก์ชันการแสดงผลไม่สำเร็จ${error?.message}`);
      console.error('Error on Edit Unit', error);
    }
  };

  // TODO: Using Graphql instead
  useEffect(() => {
    dispatch(
      actions.organizationConstantAll({
        name: '',
        size: appConfig.maxFetchSize,
        page: 1,
      }),
    );
    dispatch(
      actions.measurementTypeAll({
        name: '',
        size: appConfig.maxFetchSize,
        page: 1,
      }),
    );
    return () => {};
  }, []);

  const fetchAnotherRFT = async () => {
    try {
      const { data } = await api.get(
        `${process.env.REACT_APP_API_URL}/resulting-function-type?page=1&size=${appConfig.maxFetchSize}`,
      );
      setAllResultingFT(data);
    } catch (error) {
      console.error('Cannot Fetch All Another Resulting Function Types');
    }
  };

  useEffect(() => {
    fetchAnotherRFT();

    return () => {};
  }, []);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const renderForm = () => (
    <div className="my-2">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card>
          <CardContent>
            <ResultingFunctionTypeForm
              control={control}
              errors={errors}
              measurementType={measurementType}
              organizationConstant={organizationConstant}
              watch={watch}
              setValue={setValue}
              resultingFunctionTypesArray={allResutlingFT}
            />
          </CardContent>
        </Card>
        <div className="flex flex-row justify-end gap-1 py-4">
          <Button variant="contained" type="submit">
            บันทึก
          </Button>
        </div>
      </form>
    </div>
  );

  const renderBackButton = () => (
    <div className="flex justify-start">
      <BackButton />
    </div>
  );

  if (measurementType.isLoading || organizationConstant.isLoading) {
    return <Loading />;
  }

  if (
    !measurementType.isLoading &&
    measurementType.isCompleted &&
    !organizationConstant.isLoading &&
    organizationConstant.isCompleted
  ) {
    return (
      <div>
        {renderTitle()}
        {renderBackButton()}
        {renderForm()}
      </div>
    );
  }

  return <Error />;
}

CreateResultingFunctionType.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

CreateResultingFunctionType.defaultProps = {
  title: '',
  subtitle: '',
};
