import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, CardContent } from '@mui/material';
import { useParams, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';

import { ViewTitle } from '../../components/ViewTitle';
import * as actions from '../../redux/actions';
import { OrganizationConstantForm } from '../../components/Forms';
import Loading from '../../components/Loading';
import { Error } from '../../components/Error';
import { BackButton } from '../../components/Button';
import { app as appConfig } from '../../configs';

export default function EditOrganizationConstant({ title, subtitle }) {
  const dispatch = useDispatch();
  const organizationConstant = useSelector(
    (state) => state.organizationConstant,
  );
  const units = useSelector((state) => state.unit);
  const params = useParams();
  const history = useHistory();

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({ defaultValues: organizationConstant });

  const onSubmit = async (data) => {
    try {
      await dispatch(actions.organizationConstantPut(params.id, data));
      alert('แก้ไขค่าคงที่สำเร็จ');
      history.goBack();
    } catch (error) {
      window.alert(`แก้ไขค่าคงที่ไม่สำเร็จ${error?.message}`);
      console.error('Error on Edit Organization Constant', error);
    }
  };

  const getAllUnit = async () => {
    try {
      dispatch(actions.unitAll({ page: 1, size: appConfig.maxFetchSize }));
    } catch (error) {
      console.error('Get All Units Error', error);
    }
  };

  useEffect(() => {
    dispatch(actions.organizationConstantGet(params.id));
    getAllUnit();
    return () => {};
  }, []);

  useEffect(() => {
    if (organizationConstant) {
      setValue('name', organizationConstant?.name);
      setValue('type_code', organizationConstant?.type_code);
      setValue('value', organizationConstant?.value);
      setValue('unit', organizationConstant?.unit);
    }
    return () => {};
  }, [organizationConstant]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const renderForm = () => (
    <div className="my-2">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card>
          <CardContent>
            <OrganizationConstantForm
              units={units}
              control={control}
              errors={errors}
              organizationConstant={organizationConstant}
            />
          </CardContent>
        </Card>
        <div className="flex flex-row justify-end gap-1 py-4">
          <Button variant="contained" type="submit">
            บันทึก
          </Button>
        </div>
      </form>
    </div>
  );

  const renderBackButton = () => (
    <div className="flex justify-start">
      <BackButton />
    </div>
  );

  if (
    organizationConstant.isLoading ||
    organizationConstant?.rows ||
    units?.isLoading
  ) {
    return <Loading />;
  }

  if (
    !organizationConstant.isLoading &&
    organizationConstant.isCompleted &&
    !units.isLoading &&
    units.isCompleted
  ) {
    return (
      <div>
        {renderTitle()}
        {renderBackButton()}
        {renderForm()}
      </div>
    );
  }
  return <Error />;
}
EditOrganizationConstant.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

EditOrganizationConstant.defaultProps = {
  title: '',
  subtitle: '',
};
