import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  Button,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { gql } from 'graphql-request';
import _ from 'lodash';
import hash from 'object-hash';
import PropTypes from 'prop-types';

import {
  BackButton,
  Error,
  Loading,
  LoadingDialogWithTimeout,
  SocketIOLoading,
  ViewTitle,
} from '@/components';
import { config } from '@/configs';
import graphqlClient from '@/configs/graphqlClient';
import * as actions from '@/redux/actions';
import { MQ_TASK } from '@/utils/constants';

/**
 * @function SplittingProcessClose
 * @description Display a collections or a list of RecieveMaterial from database
 */

export default function SplittingProcessClose({ title, subtitle }) {
  const dispatch = useDispatch();
  const currentProcess = useSelector((state) => state.process);
  const place = useSelector((state) => state.place);
  const me = useSelector((state) => state.me);
  const materialSplitting = useSelector((state) => state.materialSplitting);
  const materialTransactionType = useSelector(
    (state) => state.materialTransactionType,
  );
  const history = useHistory();
  const params = useParams();
  const [loadingDialogOn, setIsLoadingDialogOn] = useState(false);
  const [referenceNumber, setReferenceNumber] = useState('');
  const [statusMessage, setStatusMessage] = useState('');
  const { control, handleSubmit } = useForm();

  useEffect(() => {
    const refNo = hash({
      date: new Date(),
      user: me?.userData?._id,
    });

    setReferenceNumber(refNo);

    return () => {};
  }, []);

  const query = gql`
    query FindRecieveMaterial(
      $processInput: ProcessInput
      $materialSplittingInput: MaterialSplittingProcessInput
      $placeInput: PlaceInput
    ) {
      findOneProcess(input: $processInput) {
        _id
        produce_material_instead
        produce_base_project_instead
        manufacturing_order {
          running_number
        }
        product {
          type_code
          name
        }
        product_as_material {
          type_code
          name
        }
        base_project {
          name
        }
      }

      findOneMaterialSplittingProcess(input: $materialSplittingInput) {
        _id
        steps {
          index
          amount
          name
          main_material {
            _id
            type_code
            name
          }
          material_associate
          is_waste
        }
      }
      findPlaces(input: $placeInput) {
        rows {
          _id
          name
        }
      }
    }
  `;

  const queryDataFromServer = async () => {
    try {
      const queryResult = await graphqlClient.request(query, {
        processInput: {
          id: params.id,
          fetchBaseProject: true,
          fetchProduct: true,
          fetchMaterial: true,
        },
        placeInput: {
          page: 1,
          size: config.maxFetchSize,
        },
        materialSplittingInput: {
          id: params.splittingProcessId,
        },
      });
      const processData = queryResult?.findOneProcess;
      const materialSplittingData =
        queryResult?.findOneMaterialSplittingProcess;
      const placeData = queryResult?.findPlaces;
      dispatch(actions.processStateOneSet(processData));
      dispatch(actions.materialSplittingStateOneSet(materialSplittingData));
      dispatch(actions.placeStateSet(placeData));
      dispatch(actions.materialTransactionTypeAll({}));
    } catch (error) {
      console.error(error);
      dispatch(actions.processError());
    }
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [params]);

  const handleCloseProcess = async (data) => {
    try {
      console.log('Data', data);
      const splittingSteps = _.filter(
        materialSplitting?.steps,
        (each) =>
          each?.is_waste === false &&
          each?.material_associate === true &&
          each?.index !== 0,
      );

      await dispatch(
        actions.processSplittingClose({
          ...data,
          place: data?.place,
          material_transaction_type: data?.material_transaction_type,
          materials: _.map(splittingSteps, (each) => ({
            material: each?.main_material?._id,
            quantity: each?.amount,
          })),
          employee: me?.userData?._id,
          currentProcess: currentProcess?._id,
          material_splitting_process: materialSplitting?._id,
          referenceNumber,
        }),
      );
      setIsLoadingDialogOn(true);
    } catch (error) {
      alert(`ไม่สามารถแก้ไขได้ ${error?.message}`);
    }
  };

  const handleSocketIOFunction = {
    onSuccess: () => {
      setIsLoadingDialogOn(false);
      history.goBack();
    },
    onFail: (args) => {
      setIsLoadingDialogOn(false);
      alert(args?.message);
    },
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const totalWaste = _.sumBy(
    _.filter(materialSplitting?.steps, (each) => each?.is_waste === true),
    'amount',
  );

  const renderProductName = () => {
    if (currentProcess?.produce_material_instead) {
      return (
        <div>
          {currentProcess?.product_as_material?.type_code}{' '}
          {currentProcess?.product_as_material?.name}
        </div>
      );
    }
    if (currentProcess?.produce_base_project_instead) {
      return <div>{currentProcess?.base_project?.name}</div>;
    }

    return (
      <div>
        {currentProcess?.product?.type_code} {currentProcess?.product?.name}
      </div>
    );
  };

  const inputTransactionType = _.filter(
    materialTransactionType?.rows,
    (each) => each?.direction === 'add',
  );
  if (currentProcess.isLoading) {
    return <Loading />;
  }

  if (
    !currentProcess.isLoading &&
    currentProcess.isCompleted &&
    materialTransactionType?.isCompleted &&
    !materialTransactionType?.isLoading
  ) {
    return (
      <div>
        {renderTitle()}
        <div>
          <BackButton />
        </div>
        <LoadingDialogWithTimeout
          label={`กำลังดำเนินการนำเข้าวัตถุดิบ ${statusMessage || ''}`}
          isLoading={loadingDialogOn}
        />
        Ref No. {referenceNumber}
        <SocketIOLoading
          taskCode={MQ_TASK.SPLIT_PROCESS_CLOSE.status_code}
          handleSuccess={handleSocketIOFunction.onSuccess}
          handleFail={handleSocketIOFunction.onFail}
          referenceNumber={referenceNumber}
          setStatusMessage={setStatusMessage}
        />
        <div className="my-2">
          <Card>
            <CardContent>
              <div className="text-xl font-semibold font-display">
                {renderProductName()}
              </div>
            </CardContent>
          </Card>
        </div>
        <div className="my-2">
          <form onSubmit={handleSubmit(handleCloseProcess)}>
            <Card>
              <CardContent>
                <div className="my-2">
                  <div className="font-semibold">คลังวัตถุดิบ</div>
                  <div className="py-2">
                    <Controller
                      control={control}
                      name="place"
                      render={({ field }) => (
                        <FormControl fullWidth size="small">
                          <InputLabel>คลัง</InputLabel>
                          <Select
                            size="small"
                            {...field}
                            fullWidth
                            label="คลัง"
                          >
                            {_.map(place?.rows, (each) => (
                              <MenuItem key={each?._id} value={each?._id}>
                                {each?.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    />
                  </div>
                </div>
                <div className="font-semibold">รูปแบบการนำเข้าสินค้า</div>
                <div className="py-2">
                  <Controller
                    control={control}
                    name="material_transaction_type"
                    defaultValue={inputTransactionType[0]?._id}
                    render={({ field }) => (
                      <FormControl fullWidth size="small">
                        <InputLabel>รูปแบบการนำเข้าสินค้า</InputLabel>
                        <Select size="small" {...field} fullWidth label="คลัง">
                          {_.map(inputTransactionType, (each) => (
                            <MenuItem key={each?._id} value={each?._id}>
                              {each?.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  />
                </div>
                <div className="my-2">
                  <div className="font-semibold">วัตถุดิบที่จะเข้าคลัง</div>
                </div>
                <div className="w-full lg:w-1/2 py-1 ml-2">
                  {_.map(
                    _.orderBy(
                      _.filter(
                        materialSplitting?.steps,
                        (each) =>
                          each?.is_waste === false &&
                          each?.material_associate === true &&
                          each?.index !== 0,
                      ),
                      ['amount'],
                      ['desc'],
                    ),
                    (each, index) => (
                      <div className="my-2 flex gap-3 items-center" key={index}>
                        <div className="w-1/3">
                          <div className="font-semibold">{each?.name}</div>
                          <div>
                            ปริมาณ {parseFloat(each?.amount).toFixed(2)}
                          </div>
                        </div>
                        <div className="w-1/3">
                          <i className="fas fa-arrow-right"></i>
                        </div>
                        <div className="w-1/3">
                          <div className="text-sm">วัตถุดิบ</div>
                          {each?.main_material?.name}
                        </div>
                      </div>
                    ),
                  )}
                  <div className="my-2 text-red-400">
                    <div className="font-semibold text-lg">เศษเหลือ</div>
                    <div>ปริมาณ {parseFloat(totalWaste).toFixed(2)}</div>
                  </div>
                </div>
                <div className="flex my-1 justify-end">
                  <Button variant="contained" type="submit">
                    บันทึก
                  </Button>
                </div>
              </CardContent>
            </Card>
          </form>
        </div>
      </div>
    );
  }
  return <Error />;
}

SplittingProcessClose.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

SplittingProcessClose.defaultProps = {
  title: '',
  subtitle: '',
};
