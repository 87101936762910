import React from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import { ReportRendering, ViewTitle } from '@/components';

/**
 * @function ReportDisplay
 * @description Display a collections or a list of EditProcessTemplateTableView from database
 */

export default function ReportDisplay({ title, subtitle }) {
  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;
  const location = useLocation();

  console.log('state', location.state);
  return (
    <div>
      {renderTitle()}
      <div>
        <ReportRendering
          manufacturingOrder={location?.state?.manufacturingOrder}
          report={location?.state?.report}
        />
      </div>
    </div>
  );
}

ReportDisplay.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

ReportDisplay.defaultProps = {
  title: '',
  subtitle: '',
};
