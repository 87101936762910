import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import { NotAuthorized, NotFound } from '../components/Error';
import { MainFooter } from '../components/Footers';
import { Loading } from '../components/Loading';
import { HomeNavbar } from '../components/Navbars';
import { MainSidebar } from '../components/Sidebar';
import * as actions from '../redux/actions';
import accessRight from '../utils/functions/accessRight';
import {
  CreatePDFReport,
  EditPDFReport,
  ExternalSoftwareSetting,
  GeneralSystemSetting,
  Information,
  ModuleSelectors,
  NotifySetting,
  PDFReport,
  Plugins,
  Preference,
  QuickMenu,
} from '../views/Information';

import { CRM, HRMS, IMS, MMS, PAMS, SPM, WMS } from './SettingLayout';

export function SETTING() {
  const module = 'SETTING';
  const prefix = '/setting';
  const name = 'ตั้งค่าระบบ';
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const me = useSelector((state) => state.me);
  const availableModule = useSelector((state) => state.availableModule);
  const information = useSelector((state) => state.information);

  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const handleOnMobileNavOpen = () => {
    setMobileNavOpen(true);
  };

  const handleOnMobileNavClose = () => {
    setMobileNavOpen(false);
  };

  useEffect(() => {
    console.log('Layout: MMS');
    dispatch(actions.meGet());
    dispatch(actions.informationAll());
    dispatch(actions.availableModuleAll());
    return () => {};
  }, []);

  if (!me?.userData) {
    return <Loading />;
  }

  if (accessRight(me, module, 1, availableModule)) {
    return (
      <div className="min-h-screen">
        <Helmet>
          <meta charSet="utf-8" />
          <title>ตั้งค่าระบบ - {information?.name || 'E-Manufac'}</title>
        </Helmet>
        <MainSidebar
          onMobileClose={handleOnMobileNavClose}
          openMobile={isMobileNavOpen}
          me={me}
        />
        <HomeNavbar onMobileNavOpen={handleOnMobileNavOpen} />
        <div className="lg:ml-64  min-h-screen  pt-16 px-8 ">
          <div className="py-4">
            <Switch>
              <Redirect exact from={`${prefix}`} to={`${prefix}/system`} />
              <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
              <Route exact path={`${prefix}/system`}>
                <Information title="ข้อมูลระบบ" subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/modules`}>
                <ModuleSelectors title="โมดูลที่เปิดใช้งาน" subtitle={name} />
              </Route>
              <Route path={`${prefix}/notify`}>
                <NotifySetting title="ตั้งค่าการแจ้งเตือน" subtitle={name} />
              </Route>
              <Route path={`${prefix}/preference`}>
                <Preference title="หน้าตาระบบ" subtitle={name} />
              </Route>{' '}
              <Route path={`${prefix}/quick-menu`}>
                <QuickMenu title="เมนูด่วนสำหรับโทรศัพท์" subtitle={name} />
              </Route>
              <Route path={`${prefix}/external-software`}>
                <ExternalSoftwareSetting
                  title="การเชื่อมต่อซอฟต์แวร์ภายนอก"
                  subtitle={name}
                />
              </Route>{' '}
              <Route path={`${prefix}/pdf-report/create`}>
                <CreatePDFReport title="สร้างรายงาน PDF" subtitle={name} />
              </Route>
              <Route path={`${prefix}/pdf-report/edit/:id`}>
                <EditPDFReport title="แก้ไขรายงาน PDF" subtitle={name} />
              </Route>{' '}
              <Route path={`${prefix}/pdf-report`}>
                <PDFReport title="รายงาน PDF" subtitle={name} />
              </Route>{' '}
              <Route path={`${prefix}/plugins`}>
                <Plugins title="ปลั๊กอิน" subtitle={name} />
              </Route>
              <Route path={`${prefix}/general-setting`}>
                <GeneralSystemSetting title="ตั้งค่าทั่วไป" subtitle={name} />
              </Route>
              <Route path={`${prefix}/mms`} component={MMS} />
              <Route path={`${prefix}/spm`} component={SPM} />
              <Route path={`${prefix}/ims`} component={IMS} />
              <Route path={`${prefix}/wms`} component={WMS} />
              <Route path={`${prefix}/crm`} component={CRM} />
              <Route path={`${prefix}/hrms`} component={HRMS} />
              <Route path={`${prefix}/pams`} component={PAMS} />
              <Route path="*">
                <NotFound />
              </Route>
            </Switch>
          </div>
        </div>
        <div className="lg:ml-64">
          <MainFooter />
        </div>
      </div>
    );
  }
  return (
    <div>
      <div className="min-h-screen">
        <MainSidebar
          onMobileClose={handleOnMobileNavClose}
          openMobile={isMobileNavOpen}
          me={me}
        />
        <HomeNavbar onMobileNavOpen={handleOnMobileNavOpen} />
        <div className="lg:ml-64  min-h-screen  pt-16 px-8 ">
          <div className="py-4">
            <Switch>
              <Route exact path={`${prefix}/modules`}>
                <ModuleSelectors title="โมดูลที่เปิดใช้งาน" subtitle={name} />
              </Route>
            </Switch>
          </div>
        </div>
        <NotAuthorized />
        <div className="lg:ml-64">
          <MainFooter />
        </div>
      </div>
    </div>
  );
}

export default SETTING;
