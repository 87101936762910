/* eslint-disable import/prefer-default-export */
import pdfMake from 'addthaifont-pdfmake/build/pdfmake';
import 'addthaifont-pdfmake/build/vfs_fonts';
import _ from 'lodash';
import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import THBText from 'thai-baht-text';
import currencyFormatterTH from '../../utils/functions/currencyFormatterTH';
import { ReportHeader } from './common';

dayjs.locale('th');
dayjs.extend(buddhistEra);
dayjs.extend(LocalizedFormat);

pdfMake.fonts = {
  Sarabun: {
    normal: 'Sarabun-Light.ttf',
    bold: 'Sarabun-Regular.ttf',
    italics: 'Sarabun-LightItalic.ttf',
    bolditalics: 'Sarabun-Italic.ttf',
  },
};

const genProductTransaction = (data) => {
  return _.map(data, (_data, index) => [
    {
      text: index + 1,
      border: [true, false, true, true],
      fontSize: 10,
      colSpan: 1,
    },
    {
      text: `${_data?.lotId?.lot_number || '-'}`,
      border: [true, false, true, true],
      fontSize: 10,
      colSpan: 1,
    },
    {
      text: `${_data?.lotId?.product?.type_code || ''} ${
        _data?.lotId?.product?.name || '-'
      }`,
      border: [true, false, true, true],
      fontSize: 10,
      colSpan: 1,
    },
    {
      text: `${_data?.lotId?.warehouse?.name || '-'}`,
      border: [true, false, true, true],
      fontSize: 10,
      colSpan: 1,
    },
    {
      text: `${currencyFormatterTH.format(_data?.quantity)} ${
        _data?.lotId?.product?.unit
      }`,
      border: [true, false, true, true],
      fontSize: 10,
      colSpan: 1,
    },
    {
      text: `${currencyFormatterTH.format(_data?.amount)} ${
        _data?.lotId?.product?.unit
      }`,
      border: [true, false, true, true],
      fontSize: 10,
      colSpan: 1,
    },
    {
      text: `${
        currencyFormatterTH.format(_data?.lotId?.cost_price) ||
        currencyFormatterTH.format(_data?.lotId?.price) ||
        0
      } บาท`,
      border: [true, false, true, true],
      fontSize: 10,
      colSpan: 1,
    },
  ]);
};

export const DetailProductHTypeDescReport = (
  ProductTransaction,
  information,
  isError,
) => {
  console.log('ProductTransaction', ProductTransaction);
  console.log('information', information);

  const ProductData = genProductTransaction(
    ProductTransaction?.product_stock_lot,
  );

  const docDefinition = {
    pageSize: 'A4',
    pageOrientation: 'portrait',
    pageMargins: [20, 30, 40, 20],
    defaultStyle: {
      font: 'Sarabun',
      fontSize: '16',
    },
    info: {
      title: 'ประวิติการจัดการวัตถุดิบ',
    },
    content: [
      isError ? ReportHeader(information, false) : ReportHeader(information),
      {
        // หัวข้อ
        alignment: 'center',
        margin: [0, 5, 0, 10],
        text: `${ProductTransaction?.product_transaction_type?.name}`,
        fontSize: '16',
        bold: true,
      },
      {
        style: 'tableExample',
        table: {
          widths: ['15%', '37%', '15%', '37%'],
          heights: [15],
          body: [
            [
              {
                text: 'วันที่เบิก',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [false, false, false, false],
              },
              {
                text: `${dayjs(ProductTransaction?.createdAt)
                  .locale('th')
                  .format('DD MMMM BBBB')} เวลา ${new Date(
                  ProductTransaction?.createdAt,
                ).toLocaleTimeString('th')} น.`,
                colSpan: 1,
                fontSize: 10,
                border: [false, false, false, false],
              },
              {
                text: 'วันที่ขาย',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [false, false, false, false],
              },
              {
                text: `${dayjs(ProductTransaction?.sale_date)
                  .locale('th')
                  .format('DD MMMM BBBB')}`,
                colSpan: 1,
                fontSize: 10,
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: 'วันที่กำหนดจัดส่ง',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [false, false, false, false],
              },
              {
                text: `${dayjs(ProductTransaction?.order?.expected_date)
                  .locale('th')
                  .format('DD MMMM BBBB')} `,
                colSpan: 1,
                fontSize: 10,
                border: [false, false, false, false],
              },
              {
                text: 'ลูกค้า',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [false, false, false, false],
              },
              {
                text: `${ProductTransaction?.customer?.name || '-'}`,
                colSpan: 1,
                fontSize: 10,
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: 'หมายเลขติดตาม',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [false, false, false, false],
              },
              {
                text: `${ProductTransaction?.tracking_number || '-'} `,
                colSpan: 1,
                fontSize: 10,
                border: [false, false, false, false],
              },
              {
                text: 'พนักงานขาย',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [false, false, false, false],
              },
              {
                text: `${ProductTransaction?.seller || '-'}`,
                colSpan: 1,
                fontSize: 10,
                border: [false, false, false, false],
              },
            ],
          ],
        },
      },
      {
        margin: [0, 15, 30, 0],
        style: 'tableExample',
        table: {
          widths: ['10%', '16%', '25%', '16%', '15%', '15%', '15%'],
          heights: [15],
          body: [
            [
              {
                text: 'ลำดับ',
                colSpan: 1,
                fontSize: 10,
                bold: true,
              },
              {
                text: 'หมายเลขล็อต',
                colSpan: 1,
                fontSize: 10,
                bold: true,
              },
              {
                text: 'ชื่อสินค้า',
                colSpan: 1,
                fontSize: 10,
                bold: true,
              },
              {
                text: 'ชื่อคลัง',
                colSpan: 1,
                fontSize: 10,
                bold: true,
              },

              {
                text: 'จำนวน',
                colSpan: 1,
                fontSize: 10,
                bold: true,
              },
              {
                text: 'คงเหลือ',
                colSpan: 1,
                fontSize: 10,
                bold: true,
              },
              {
                text: 'ราคา',
                colSpan: 1,
                fontSize: 10,
                bold: true,
              },
            ],
            ...ProductData,
            [
              {
                text: `หมายเหตุ : ${ProductTransaction?.remark}`,
                colSpan: 5,
                fontSize: '10',
                border: [true, false, false, false],
              },
              {},
              {},
              {},
              {},
              {
                text: 'ทั้งหมด',
                colSpan: 1,
                fontSize: 10,
                border: [true, false, false, true],
              },
              {
                text: `${ProductTransaction?.price || '0'} บาท`,
                alignment: 'right',
                colSpan: 1,
                fontSize: 10,
                border: [true, false, true, true],
              },
            ],
            [
              {
                text: '',
                colSpan: 5,
                border: [true, false, false, false],
              },
              {},
              {},
              {},
              {},
              {
                text: 'ส่วนลด',
                colSpan: 1,
                fontSize: 10,
                border: [true, false, false, true],
              },
              {
                text: `${ProductTransaction?.discount || '0'} บาท`,
                alignment: 'right',
                colSpan: 1,
                fontSize: 10,
                border: [true, false, true, true],
              },
            ],
            [
              {
                text: `( ${THBText(ProductTransaction?.total_price)} )`,
                alignment: 'center',
                fontSize: 10,
                colSpan: 5,
                border: [true, true, false, true],
              },
              {},
              {},
              {},
              {},
              {
                text: 'รวมราคาสุทธิ',
                colSpan: 1,
                fontSize: 10,
                border: [true, false, false, true],
              },
              {
                text: `${ProductTransaction?.total_price || '0'} บาท`,
                alignment: 'right',
                colSpan: 1,
                fontSize: 10,
                border: [true, false, true, true],
              },
            ],
          ],
        },
      },
    ],
    images: {
      logo: information?.logo?.url,
    },
  };
  pdfMake.createPdf(docDefinition).open();
};
