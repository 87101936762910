import React from 'react';
import PropTypes from 'prop-types';
import { currencyFormatter } from '../../../utils/functions';

const NumberGauge = ({ measurementData }) => (
  <div>
    <div className="text-xl font-semibold">
      {measurementData?.value
        ? currencyFormatter.format(measurementData?.value)
        : '-'}
    </div>
    <div>{measurementData?.measurement_type?.unit?.short_sign}</div>
  </div>
);

NumberGauge.propTypes = {
  measurementData: PropTypes.object,
};

export default NumberGauge;
