import {
  PRODUCT_TRANSACTION_ALL,
  PRODUCT_TRANSACTION_GET,
  PRODUCT_TRANSACTION_DEL,
  PRODUCT_TRANSACTION_PUT,
  PRODUCT_TRANSACTION_POST,
  PRODUCT_TRANSACTION_SEARCH,
  PRODUCT_TRANSACTION_LOADING,
  PRODUCT_TRANSACTION_ERROR,
} from '../types';

import api from '../../../configs/api';

export const productTransactionCreate = (payload) => async (dispatch) => {
  try {
    dispatch({ type: PRODUCT_TRANSACTION_LOADING });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/product-transaction`,
      {
        ...payload,
      },
    );
    dispatch({ type: PRODUCT_TRANSACTION_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: PRODUCT_TRANSACTION_ERROR });
    throw new Error(error);
  }
};

export const productTransactionAll = (params) => async (dispatch) => {
  try {
    const { productName = '', size = '', page = 1, selectType = '' } = params;
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/product-transaction?productName=${productName}&size=${size}&page=${page}&selectType=${selectType}`,
    );
    if (status === 200) {
      dispatch({ type: PRODUCT_TRANSACTION_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: PRODUCT_TRANSACTION_ERROR });
    throw new Error(error);
  }
};

export const productTransactionSearch = (params) => async (dispatch) => {
  try {
    const {
      productName = '',
      size = '',
      page = 1,
      month = '',
      selectType = '',
    } = params;
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/product-transaction?productName=${productName}&size=${size}&page=${page}&month=${month}&selectType=${selectType}`,
    );
    if (status === 200) {
      dispatch({ type: PRODUCT_TRANSACTION_SEARCH, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: PRODUCT_TRANSACTION_ERROR });
    throw new Error(error);
  }
};

export const productTransactionGet = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/product-transaction/${id}`,
    );
    if (status === 200) {
      dispatch({ type: PRODUCT_TRANSACTION_GET, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: PRODUCT_TRANSACTION_ERROR });
    throw new Error(error);
  }
};

export const productTransactionStateSet = (data) => async (dispatch) => {
  dispatch({ type: PRODUCT_TRANSACTION_ALL, payload: data });
};

export const productTransactionPut = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: PRODUCT_TRANSACTION_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/product-transaction/${id}`,
      payload,
    );
    dispatch({ type: PRODUCT_TRANSACTION_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: PRODUCT_TRANSACTION_ERROR });
    throw new Error(error);
  }
};
export const productTransactionDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: PRODUCT_TRANSACTION_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/product-transaction/${id}`,
    );
    dispatch({ type: PRODUCT_TRANSACTION_DEL, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: PRODUCT_TRANSACTION_ERROR });
    throw new Error(error);
  }
};
