import React, { useState, useEffect } from 'react';
import {
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Card,
} from '@mui/material';
import dayjs from 'dayjs';
import { useForm, Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { MATERIAL_STOCK_STATUS } from '../../../utils/constants';

const resetValues = {
  quantity: '',
  price: '',
  material: '',
  production_date: new Date(),
  expiration_date: new Date(),
};

export function AddMaterialForm({
  material,
  selectMaterial,
  setSelectMaterial,
}) {
  const [reload, setReload] = useState(false);
  const {
    control,
    formState: { errors },
    reset,
    getValues,
  } = useForm();
  useEffect(() => () => {}, [reload]);

  const handleAddMaterial = () => {
    if (
      (getValues('material') || getValues('price') || getValues('quantity')) ===
      ''
    ) {
      alert('กรุณาใส่ข้อมูลให้ครบถ้วน');
    } else {
      const data = {
        material: getValues('material'),
        price: getValues('price'),
        quantity: getValues('quantity'),
        amount: getValues('quantity'),
        production_date: getValues('production_date'),
        expiration_date: getValues('expiration_date'),
        status: MATERIAL_STOCK_STATUS.IN_STOCK.status_code,
      };
      selectMaterial.push(data);
      setSelectMaterial(selectMaterial);
      reset(resetValues);
      setReload(!reload);
    }
  };

  const handleRemoveMaterial = (index) => {
    selectMaterial.splice(index, 1);
    setSelectMaterial(selectMaterial);
    setReload(!reload);
  };

  const materialOptions = () => (
    <Card>
      <div className="w-full flex flex-row flex-wrap p-4">
        <div className="w-full px-1 py-2">
          <Controller
            name={'material'}
            control={control}
            defaultValue={''}
            rules={{ required: true }}
            render={({ field }) => (
              <FormControl sx={{ minWidth: 120 }} fullWidth={true}>
                <InputLabel id="type" size={'small'}>
                  วัตถุดิบ
                </InputLabel>
                <Select {...field} label="วัตถุดิบ" size={'small'} fullWidth>
                  {_.size(material) ? (
                    _.map(material, (_material) => (
                      <MenuItem key={_material.id} value={_material.id}>
                        {_material.name} (ขนาด {_material.net}{' '}
                        {_material.unit_net})
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem>
                      <MenuItem disabled value="ไม่มีข้อมูล">
                        <em>ไม่มีข้อมูล</em>
                      </MenuItem>
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            )}
          />
        </div>
        <div className="w-full lg:w-1/2 px-1 py-2">
          <Controller
            name={'price'}
            control={control}
            defaultValue={''}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                label="ราคา (บาท)"
                fullWidth
                size={'small'}
                helperText={errors.price && 'กรุณาใส่ข้อมูล'}
              />
            )}
          />
        </div>
        <div className="w-full lg:w-1/2 px-1 py-2">
          <Controller
            name={'quantity'}
            control={control}
            defaultValue={''}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                label="จำนวน"
                fullWidth
                size={'small'}
                helperText={errors.quantity && 'กรุณาใส่ข้อมูล'}
              />
            )}
          />
        </div>
        <div className="w-full lg:w-1/2 px-1 mb-2">
          <label className="text-sm">วันที่ผลิต</label>
          <Controller
            name={'production_date'}
            control={control}
            defaultValue={new Date()}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                type="date"
                fullWidth
                size={'small'}
                helperText={errors.production_date && 'กรุณาใส่ข้อมูล'}
              />
            )}
          />
        </div>
        <div className="w-full lg:w-1/2 px-1 mb-2">
          <label className="text-sm">วันหมดอายุ</label>
          <Controller
            name={'expiration_date'}
            control={control}
            defaultValue={new Date()}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                type="date"
                fullWidth
                size={'small'}
                helperText={errors.expiration_date && 'กรุณาใส่ข้อมูล'}
              />
            )}
          />
        </div>
        <div className="px-1">
          <Button
            variant="outlined"
            size={'small'}
            color={'success'}
            onClick={() => handleAddMaterial()}
          >
            เพิ่ม
          </Button>
        </div>
      </div>
    </Card>
  );

  const displayMaterial = () => (
    <Card>
      {' '}
      <TableContainer>
        <Table size="small" className="border-lg">
          <TableHead className="bg-blue-100">
            <TableRow>
              <TableCell>
                <div className="font-bold">ลำดับที่</div>
              </TableCell>
              <TableCell>
                <div className="font-bold"> ชื่อวัตถุดิบ</div>
              </TableCell>
              <TableCell>
                <div className="font-bold"> ราคา</div>
              </TableCell>
              <TableCell>
                <div className="font-bold"> จำนวน</div>
              </TableCell>
              <TableCell>
                <div className="font-bold"> วันที่ผลิต</div>
              </TableCell>
              <TableCell>
                <div className="font-bold"> วันที่หมดอายุ</div>
              </TableCell>
              <TableCell>
                <div className="font-bold"> ดำเนินการ</div>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!_.isEmpty(selectMaterial) ? (
              selectMaterial.map((_material, index) => {
                const findMaterial = _.find(material, {
                  id: _material?.material,
                });
                return (
                  <TableRow key={index}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{findMaterial?.name}</TableCell>
                    <TableCell>{_material?.price || '-'} บาท</TableCell>
                    <TableCell>
                      {_material?.quantity || '-'} {findMaterial?.unit}
                    </TableCell>
                    <TableCell>
                      {dayjs(_material?.production_date)
                        .locale('th')
                        .format('DD MMM BBBB')}
                    </TableCell>
                    <TableCell>
                      {dayjs(_material?.expiration_date)
                        .locale('th')
                        .format('DD MMM BBBB')}
                    </TableCell>
                    <TableCell>
                      <Button
                        color={'error'}
                        variant="contained"
                        size={'small'}
                        onClick={() => handleRemoveMaterial(index)}
                      >
                        ลบ
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow key={''}>
                <TableCell colSpan={6}>
                  <div className="text-center">ไม่มีข้อมูล</div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );

  const showSelectMaterial = () => displayMaterial();

  return (
    <div>
      <div className=" ">
        <div className="my-2">{materialOptions()}</div>
        <div className="my-4 ">{showSelectMaterial()}</div>
      </div>
    </div>
  );
}

AddMaterialForm.propTypes = {
  material: PropTypes.object,
  selectMaterial: PropTypes.object,
  setSelectMaterial: PropTypes.func,
};

AddMaterialForm.defaultProps = {
  material: null,
  selectMaterial: null,
};

export default AddMaterialForm;
