// db 1 represent 1 db any
// can be 1-db2  or 1-db12 , etc.
import db1 from "./1-1DB.png";
import db2 from "./1-2DB.png";
import db3 from "./1-3DB.png";
import db4 from "./1-4DB.png";
import db5 from "./1-5DB.png";
import db6 from "./1-6DB.png";
import db7 from "./1-7DB.png";
import db8 from "./1-8DB.png";
import db9 from "./1-9DB.png";

const oneJacket = { db1, db2, db3, db4, db5, db6, db7, db8, db9 };

export default oneJacket;
