import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Button, Card, CardContent } from '@mui/material';
import { gql } from 'graphql-request';
import _ from 'lodash';
import PropTypes from 'prop-types';

import {
  BackButton,
  Error,
  Loading,
  LoadingDialog,
  MaterialWithdrawForm,
  ViewTitle,
} from '../../../components';
import { app as appConfig } from '../../../configs';
import graphqlClient from '../../../configs/graphqlClient';
import * as actions from '../../../redux/actions';
import { converseMaterialOrProductUnit } from '../../../utils/functions';
import MMSProcessFunction from '../../../utils/functions/MMS/processFunction';

/**
 * @function CreateRequestOfMaterial
 * @memberof MMS/Process
 * @description สร้างรายการร้องขอวัตถุดิบ Material Request ให้ฝ่ายคลังอนุมัติอีกที
 * มีใช้ใน Southern Seafood และ Letter P โดยมันจะมาโดยการคลิกเบิกวัตถุดิบ
 * แต่จะต้องมีการตั้งค่าให้อนุมัติการเบิกก่อน
 */

const useQuery = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};

export default function CreateRequestOfMaterial({ title, subtitle }) {
  const dispatch = useDispatch();
  const currentProcess = useSelector((state) => state.process);
  const me = useSelector((state) => state.me);
  const material = useSelector((state) => state.material);
  const materialTransactionType = useSelector(
    (state) => state.materialTransactionType,
  );
  const browserQuery = useQuery();
  const history = useHistory();
  const [dialogLoading, setOpenDialogLoading] = useState(false);
  const params = useParams();

  const query = gql`
    query FindWithdrawMaterial($materialInput: MaterialInput) {
      findMaterials(input: $materialInput) {
        total
        rows {
          _id
          name
          type_code
          use_unit_conversion
          unit
          conversion_from {
            unit_a
            unit_b
            conversion_factor
          }
          conversion_to {
            unit_b
            unit_a
            conversion_factor
          }
          unit_input {
            _id
            short_sign
            name
          }
          unit_output {
            _id
            short_sign
            name
          }
          total
          stocklots {
            _id
            amount
            issue_date
            lot_number
            quantity
            material {
              _id
              name
              type_code
              use_unit_conversion
              unit
              conversion_from {
                unit_a
                unit_b
                conversion_factor
              }
              conversion_to {
                unit_b
                unit_a
                conversion_factor
              }
              unit_input {
                _id
                short_sign
                name
              }
              unit_output {
                _id
                short_sign
                name
              }
            }
          }
        }
      }
    }
  `;

  const queryDataFromServer = async () => {
    try {
      const queryResult = await graphqlClient.request(query, {
        materialInput: {
          fetchStockLot: true,
          status: 'IN_STOCK',
          page: 1,
          size: appConfig.maxFetchSize,
        },
      });
      const materialData = queryResult?.findMaterials;
      dispatch(actions.materialStateSet(materialData));
    } catch (error) {
      console.error('Query Error', error);
    }
  };

  const getProcess = async () => {
    try {
      await dispatch(actions.processGet(params.id));
    } catch (error) {
      console.error('Process Get Error', error);
    }
  };

  const getMaterialTransactionType = async () => {
    try {
      await dispatch(actions.materialTransactionTypeAll({}));
    } catch (error) {
      console.error('Process Get Error', error);
    }
  };

  useEffect(() => {
    queryDataFromServer();
    getMaterialTransactionType();
    getProcess();
    return () => {};
  }, []);

  const processMaterialWithAllMaterialData = _.map(
    currentProcess?.materials,
    (eachProcMaterial) => {
      const selectedMaterial = _.find(
        material?.rows,
        (eachSelectMaterial) =>
          eachSelectMaterial?._id === eachProcMaterial?.material?._id,
      );

      return {
        ...eachProcMaterial,
        artificial_quantity: converseMaterialOrProductUnit(
          eachProcMaterial?.material,
          eachProcMaterial?.begin,
        ),
        material: selectedMaterial,
        runOutOff: !selectedMaterial,
        originalMaterial: eachProcMaterial?.material,
        force: false,
        createRequestOfMaterial: true,
      };
    },
  );

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      materialLots: processMaterialWithAllMaterialData,
    },
  });

  useEffect(() => {
    if (currentProcess?.materials && material?.rows) {
      if (!currentProcess.isRequestMaterial) {
        setValue('materialLots', processMaterialWithAllMaterialData);
      }
    }
    return () => {};
  }, [currentProcess, material]);

  const handleRequestMaterial = async (data) => {
    setOpenDialogLoading(true);

    await MMSProcessFunction.handleCreateRequestOfMaterial(
      dispatch,
      data,
      currentProcess,
      me,
      () => {
        setOpenDialogLoading(false);
        history.goBack();
      },
    );
  };

  const renderForm = () => (
    <form onSubmit={handleSubmit(handleRequestMaterial)}>
      <MaterialWithdrawForm
        control={control}
        errors={errors}
        material={material}
        materialTransactionType={materialTransactionType}
        requestFromProcess
        requireConfirmation
        setValue={setValue}
        watch={watch}
      />
    </form>
  );

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (currentProcess.isLoading) {
    return <Loading />;
  }

  if (!currentProcess.isLoading && currentProcess.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div className="my-2">
          <BackButton />
        </div>
        <LoadingDialog
          isLoading={dialogLoading}
          label="กำลังดำเนินการเบิกวัตถุดิบ"
        />
        <div>
          <Card>
            <CardContent>
              <h4 className="font-semibold text-lg py-2 font-display">
                การขอเบิกวัตถุดิบ
              </h4>
              {renderForm()}
              <div className="flex justify-end gap-2 my-4">
                <Button
                  type="button"
                  onClick={handleSubmit(handleRequestMaterial)}
                  color="primary"
                  variant="contained"
                >
                  ยืนยัน
                </Button>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    );
  }
  return <Error />;
}

CreateRequestOfMaterial.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

CreateRequestOfMaterial.defaultProps = {
  title: '',
  subtitle: '',
};
