import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Autocomplete,
  TextField,
  Button,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';
import _ from 'lodash';
import dayjs from 'dayjs';
import { PRODUCT_STOCK_STATUS } from '../../../utils/constants';
import { ProdOrMatQuantityInput } from '../../Inputs';
import MultiUnitProdOrMatBox from '../../Box/Rendering/MultiUnitProdOrMatBox';

const resetValues = {
  product: '',
  addProductLots: [],
  price: '',
};

export function DecreaseProductForm({
  product,
  warehouse,
  type,
  selectProductLot,
  setSelectProductLot,
  filterProduct,
  setFilterProduct,
  filterWarehouse,
  setFilterWarehouse,
  discount,
  setDiscount,
}) {
  const { id } = useParams();
  const [reload, setReload] = useState(false);
  const information = useSelector((state) => state.information);
  const {
    setting: { wms: wmsSetting },
  } = information;
  const addProductLots = useState([]);
  const {
    control,
    formState: { errors },
    reset,
    getValues,
    watch,
    setValue,
  } = useForm();
  const checkproductLot = () => {
    if (_.isEmpty(filterWarehouse)) {
      const findwarehouse = _.find(warehouse, { id });
      setFilterWarehouse(findwarehouse);
      _.map(product, (_product, index) => {
        const filterLot = _.filter(
          _product?.stocklots,
          function filterLot(lot) {
            return (
              (lot?.warehouse?._id === filterWarehouse?._id ||
                lot.warehouse === filterWarehouse?._id) &&
              lot.status === PRODUCT_STOCK_STATUS.IN_STOCK.status_code
            );
          },
        );
        if (!_.isEmpty(filterLot)) {
          const data = {
            ..._product,
            stocklots: filterLot,
          };
          filterProduct[index] = data;
        } else {
          filterProduct[index] = null;
        }
      });
      const filterNoLot = _.filter(filterProduct, function filterLot(lot) {
        return lot != null;
      });
      setFilterProduct(filterNoLot);
    } else if (filterWarehouse) {
      console.log('Select Filter Warehouse');
      _.map(product, (_product, index) => {
        const filterLot = _.filter(
          _product?.stocklots,
          function filterLot(lot) {
            return (
              (lot?.warehouse?._id === filterWarehouse?._id ||
                lot.warehouse === filterWarehouse?._id) &&
              lot.status === PRODUCT_STOCK_STATUS.IN_STOCK.status_code
            );
          },
        );
        console.log('Product stokclot', _product?.stocklots);
        console.log('Filter Lots', filterLot);
        if (!_.isEmpty(filterLot)) {
          const data = {
            ..._product,
            stocklots: filterLot,
          };
          filterProduct[index] = data;
        } else {
          filterProduct[index] = null;
        }
      });
      const filterNoLot = _.filter(filterProduct, function filterLot(lot) {
        return lot != null;
      });
      setFilterProduct(filterNoLot);
    }
  };
  useEffect(() => {
    checkproductLot();
    return () => {};
  }, [reload, filterWarehouse, type]);

  const handleaddProductLots = () => {
    const getQuantity = [];
    _.map(addProductLots[0]?.stocklots, (lot, index) => {
      if (
        getValues(`quantity${index}`) &&
        getValues(`quantity${index}`) !== '0'
      ) {
        const lotData = {
          quantity: getValues(`quantity${index}`),
          amount:
            parseInt(lot?.amount, 10) -
            parseInt(getValues(`quantity${index}`), 10),
          price: parseInt(getValues(`price${index}`), 10),
          lotId: lot?._id,
          productId: getValues('product'),
          warehouse: filterWarehouse?.id,
          stocklot: lot,
          product: addProductLots[0],
        };
        getQuantity.push(lotData);
      }
    });
    if (getValues('product') === '') {
      alert('กรุณาใส่ข้อมูลให้ครบถ้วน');
    } else {
      _.map(getQuantity, (_getQuantity) => {
        selectProductLot.push(_getQuantity);
        setSelectProductLot(selectProductLot);
      });
      reset(resetValues);
      setReload(!reload);
    }
  };

  const handleRemoveProductLot = (index) => {
    selectProductLot.splice(index, 1);
    setReload(!reload);
  };

  const showProductLots = () => {
    if (getValues('product')) {
      const findStockLots = _.find(filterProduct, {
        id: getValues('product')._id,
      });
      addProductLots[0] = findStockLots;
      return (
        <Card>
          <TableContainer>
            <Table size="small" className="border-lg">
              <TableHead className="bg-blue-100">
                <TableRow>
                  <TableCell>
                    <div className="font-bold">ล็อตที่</div>
                  </TableCell>
                  {wmsSetting?.display?.lot_number && (
                    <TableCell>
                      <div className="font-bold"> หมายเลขล็อต</div>
                    </TableCell>
                  )}
                  {wmsSetting?.display?.production_date && (
                    <TableCell>
                      <div className="font-bold"> วันที่ผลิต</div>
                    </TableCell>
                  )}
                  {wmsSetting?.display?.expiration_date && (
                    <TableCell>
                      <div className="font-bold"> วันที่หมดอายุ</div>
                    </TableCell>
                  )}
                  <TableCell>
                    <div className="font-bold"> คงเหลือ</div>
                  </TableCell>
                  <TableCell>
                    <div className="font-bold"> จำนวนที่เบิก</div>
                  </TableCell>
                  {wmsSetting?.display?.price && (
                    <TableCell>
                      <div className="font-bold"> ราคาขาย</div>
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {!_.isEmpty(findStockLots) ? (
                  findStockLots?.stocklots.map((lot, index) => (
                    <TableRow key={lot.id}>
                      <TableCell>{index + 1}</TableCell>
                      {wmsSetting?.display?.lot_number && (
                        <TableCell>{lot?.lot_number || '-'}</TableCell>
                      )}
                      {wmsSetting?.display?.production_date && (
                        <TableCell>
                          {dayjs(lot?.production_date)
                            .locale('th')
                            .format('DD MMM BBBB')}
                        </TableCell>
                      )}

                      {wmsSetting?.display?.expiration_date && (
                        <TableCell>
                          {dayjs(lot?.expiration_date)
                            .locale('th')
                            .format('DD MMM BBBB')}
                        </TableCell>
                      )}
                      <TableCell>
                        <MultiUnitProdOrMatBox
                          foundMaterial={findStockLots}
                          quantity={lot?.amount}
                        />
                      </TableCell>
                      <TableCell>
                        <ProdOrMatQuantityInput
                          controllerName={`quantity${index}`}
                          artificialControllerName={`artificial_quantity${index}`}
                          selectedUnitControllerName={`unit_quantity${index}`}
                          watch={watch}
                          setValue={setValue}
                          control={control}
                          selectedProduct={findStockLots}
                          index={index}
                        />
                      </TableCell>
                      {wmsSetting?.display?.price && (
                        <TableCell>
                          <Controller
                            name={`price${index}`}
                            control={control}
                            rules={{ required: true }}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                label="ราคาขาย"
                                fullWidth
                                size={'small'}
                                helperText={errors.price && 'กรุณาใส่ข้อมูล'}
                              />
                            )}
                          />
                        </TableCell>
                      )}
                    </TableRow>
                  ))
                ) : (
                  <TableRow key={''}>
                    <TableCell colSpan={7}>
                      <div className="text-center">ไม่มีข้อมูล</div>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      );
    }
  };

  const productOptions = () => (
    <Card className="w-full p-2">
      <div className="w-full flex flex-row flex-wrap ">
        <div className="w-full px-1 py-2">
          <Controller
            name={'product'}
            control={control}
            defaultValue={''}
            render={({ field }) => (
              <Autocomplete
                size={'small'}
                disableClearable
                {...field}
                onChange={(e, newValue) => {
                  field.onChange(newValue);
                  setReload(!reload);
                }}
                options={product}
                getOptionLabel={(option) =>
                  option ? `${option?.type_code} | ${option?.name}` : ''
                }
                renderInput={(params) => (
                  <TextField {...params} label="สินค้า" />
                )}
              />
            )}
          />
        </div>
        <div className="w-full px-1 py-2"> {showProductLots()}</div>
      </div>
      <div className="flex justify-end py-4">
        <Button
          variant="outlined"
          size={'small'}
          color={'secondary'}
          onClick={() => handleaddProductLots()}
        >
          เพิ่ม
        </Button>
      </div>
    </Card>
  );

  const displayProduct = () => (
    <Card>
      <TableContainer>
        <Table size="small" className="border-lg">
          <TableHead className="bg-blue-100">
            <TableRow>
              <TableCell>
                <div className="font-bold">ลำดับที่</div>
              </TableCell>
              {wmsSetting?.display?.lot_number && (
                <TableCell>
                  <div className="font-bold"> หมายเลขล็อต</div>
                </TableCell>
              )}
              <TableCell>
                <div className="font-bold"> ชื่อสินค้า</div>
              </TableCell>{' '}
              {wmsSetting?.display?.production_date && (
                <TableCell>
                  <div className="font-bold"> วันที่ผลิต</div>
                </TableCell>
              )}
              {wmsSetting?.display?.expiration_date && (
                <TableCell>
                  <div className="font-bold"> วันที่หมดอายุ</div>
                </TableCell>
              )}
              <TableCell>
                <div className="font-bold"> จำนวน</div>
              </TableCell>
              {wmsSetting?.display?.price && (
                <TableCell>
                  <div className="font-bold"> ราคาขาย</div>
                </TableCell>
              )}
              <TableCell>
                <div className="font-bold"> ดำเนินการ</div>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!_.isEmpty(selectProductLot) ? (
              selectProductLot.map((_product, index) => (
                <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  {wmsSetting?.display?.lot_number && (
                    <TableCell>
                      {_product?.stocklot?.lot_number || '-'}
                    </TableCell>
                  )}
                  <TableCell>
                    <b>{_product?.product?.type_code}</b>{' '}
                    {_product?.product?.name}
                  </TableCell>{' '}
                  {wmsSetting?.display?.production_date && (
                    <TableCell>
                      {dayjs(_product?.stocklot?.production_date)
                        .locale('th')
                        .format('DD MMM BBBB')}
                    </TableCell>
                  )}
                  {wmsSetting?.display?.expiration_date && (
                    <TableCell>
                      {dayjs(_product?.stocklot?.expiration_date)
                        .locale('th')
                        .format('DD MMM BBBB')}
                    </TableCell>
                  )}
                  <TableCell>
                    <MultiUnitProdOrMatBox
                      foundMaterial={_product?.product}
                      quantity={_product?.quantity}
                    />
                  </TableCell>
                  {wmsSetting?.display?.price && (
                    <TableCell>{_product?.price} บาท</TableCell>
                  )}
                  <TableCell>
                    <Button
                      color={'error'}
                      variant="contained"
                      size={'small'}
                      onClick={() => handleRemoveProductLot(index)}
                    >
                      ลบ
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow key={''}>
                <TableCell colSpan={7}>
                  <div className="text-center">ไม่มีข้อมูล</div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          <TableBody>
            {wmsSetting?.display?.price && (
              <TableRow key={'total'}>
                <TableCell
                  colSpan={
                    2 +
                    (wmsSetting?.display?.expiration_date ? 1 : 0) +
                    (wmsSetting?.display?.production_date ? 1 : 0) +
                    (wmsSetting?.display?.lot_number ? 1 : 0)
                  }
                >
                  <div className="text-right">ทั้งหมด</div>
                </TableCell>
                <TableCell>
                  <div className="text-center">
                    {_.size(selectProductLot) ? (
                      <p>
                        {_.sumBy(selectProductLot, function (lot) {
                          return lot.price;
                        })}
                      </p>
                    ) : (
                      <p>{0}</p>
                    )}
                  </div>
                </TableCell>
                <TableCell>
                  <div className="text-left">บาท</div>
                </TableCell>
              </TableRow>
            )}
            {wmsSetting?.display?.price && (
              <TableRow key={''}>
                <TableCell
                  colSpan={
                    2 +
                    (wmsSetting?.display?.expiration_date ? 1 : 0) +
                    (wmsSetting?.display?.production_date ? 1 : 0) +
                    (wmsSetting?.display?.lot_number ? 1 : 0)
                  }
                >
                  <div className="text-right">ส่วนลด</div>
                </TableCell>
                <TableCell>
                  <div className="text-center">
                    <Controller
                      name={'discount'}
                      control={control}
                      defaultValue={discount ? `${discount}` : '0'}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          onChange={(e) => {
                            field.onChange(e.target.value);
                            setDiscount(e.target.value);
                            setReload(!reload);
                          }}
                          label="ส่วนลด"
                          fullWidth
                          inputProps={{
                            min: 0,
                            style: { textAlign: 'center' },
                          }}
                          size={'small'}
                          helperText={errors.discount && 'กรุณาใส่ข้อมูล'}
                        />
                      )}
                    />
                  </div>
                </TableCell>
                <TableCell>
                  <div className="text-left">บาท</div>
                </TableCell>
              </TableRow>
            )}
            {wmsSetting?.display?.price && (
              <TableRow key={''}>
                <TableCell
                  colSpan={
                    2 +
                    (wmsSetting?.display?.expiration_date ? 1 : 0) +
                    (wmsSetting?.display?.production_date ? 1 : 0) +
                    (wmsSetting?.display?.lot_number ? 1 : 0)
                  }
                >
                  <div className="text-right">รวม</div>
                </TableCell>
                <TableCell>
                  <div className="text-center">
                    {_.size(selectProductLot) ? (
                      <p>
                        {_.sumBy(selectProductLot, function (lot) {
                          return lot.price;
                        }) - discount}
                      </p>
                    ) : (
                      <p>{0}</p>
                    )}
                  </div>
                </TableCell>
                <TableCell>
                  <div className="text-left">บาท</div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );

  const showSelectPoductLot = () =>
    _.size(selectProductLot) ? displayProduct() : '';

  return (
    <div>
      <div className="mb-2">{showSelectPoductLot()}</div>
      <div className="flex flex-row flex-wrap">{productOptions()}</div>
    </div>
  );
}

DecreaseProductForm.propTypes = {
  product: PropTypes.object,
  warehouse: PropTypes.object,
  type: PropTypes.object,
  selectProductLot: PropTypes.object,
  setSelectProductLot: PropTypes.func,
  filterProduct: PropTypes.object,
  setFilterProduct: PropTypes.func,
  filterWarehouse: PropTypes.object,
  setFilterWarehouse: PropTypes.func,
  discount: PropTypes.object,
  setDiscount: PropTypes.func,
};

DecreaseProductForm.defaultProps = {
  product: null,
  warehouse: null,
  type: null,
  selectProductLot: null,
  filterProduct: null,
  filterWarehouse: null,
  discount: null,
};

export default DecreaseProductForm;
