/* eslint-disable no-nested-ternary */
import React from 'react';
import { useSelector } from 'react-redux';
import { FlowAnalysisGraph } from '@ant-design/graphs';
import { useTheme } from '@mui/styles';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { currencyFormatter, MMS } from '@/utils/functions';

const MaterialSplittingBranchingStep = ({
  processSteps,
  allowDrag = true,
  currentProcess,
  currentHoverIndex,
  height = 700,
}) => {
  const theme = useTheme();
  const information = useSelector((state) => state.information);
  const me = useSelector((state) => state.me);
  const filterStep = _.orderBy(
    _.filter(
      processSteps,
      (eachProcessStep) =>
        eachProcessStep?.material_associate &&
        !eachProcessStep?.is_waste &&
        eachProcessStep?.splited_step,
    ),
    ['amount'],
    ['desc'],
  );

  const totalWaste = _.sumBy(
    _.filter(processSteps, (each) => each?.is_waste === true),
    'amount',
  );

  const totalAmount = _.sumBy(filterStep, 'amount') + totalWaste;

  const sumOnSameIndex = (step) =>
    _.sumBy(
      _.filter(
        processSteps,
        (eachStep) => eachStep?.hover_index === step?.hover_index,
      ),
      'amount',
    );
  console.log('PROCESS STEPS', processSteps);
  console.log('-------------');

  const checkBoxEnable = (stepDepartment) => {
    if (me?.userData?.role?.level >= 1) {
      return true;
    }

    if (me?.userData?.department?._id === stepDepartment?._id) {
      return true;
    }

    return false;
  };
  const unzeroStep = _.filter(
    processSteps,
    (each) => each?.amount !== 0 || each?.index === 0,
  );
  const modifiedStep = {
    nodes: _.map(
      information?.setting?.mms?.materialSplitting?.displayStepOnlyForUnZero
        ? unzeroStep
        : processSteps,
      (each) => ({
        id: each?.index?.toString(),
        value: {
          title: `${each?.hover_index + 1}. ${each?.name} ${
            each?.material_associate ? '*' : ''
          }`,
          active: currentHoverIndex === each?.hover_index,
          waste: each?.is_waste,
          items: [
            each?.material_associate &&
            (each?.index !== 0 || (each?.index === 0 && each?.amount !== 0)) &&
            !each.is_waste
              ? {
                  text: 'วัตถุดิบ',
                  value: each?.use_set_of_material
                    ? _.map(
                        each?.materials,
                        (eachMaterial) =>
                          `${eachMaterial?.material?.type_code || ''} ${
                            eachMaterial?.material?.name || '-'
                          }`,
                      )
                    : each?.main_material?.name || '-',
                }
              : { text: 'วัตถุดิบ', value: '-' },
            information?.setting?.mms?.materialSplitting
              ?.displayStepForAllAccess
              ? {
                  text: 'ปริมาณ',
                  value: `${currencyFormatter.format(each?.amount)} ${
                    each?.main_material?.use_unit_conversion
                      ? `${each?.main_material?.unit_input?.name || ''}`
                      : each?.main_material?.unit || ''
                  }`,
                }
              : checkBoxEnable(each?.responsible?.department)
              ? {
                  text: 'ปริมาณ',
                  value: `${currencyFormatter.format(each?.amount)} ${
                    each?.main_material?.use_unit_conversion
                      ? `${each?.main_material?.unit_input?.name || ''}`
                      : each?.main_material?.unit || ''
                  }`,
                }
              : {
                  text: 'แผนก',
                  value: each?.responsible?.department?.name || '-',
                },
            information?.setting?.mms?.materialSplitting
              ?.displayStepForAllAccess
              ? {
                  text: 'คิดเป็น ',
                  value: `${(
                    ((each?.amount || 0) * 100) /
                    sumOnSameIndex(each)
                  ).toFixed(2)} %`,
                }
              : checkBoxEnable(each?.responsible?.department)
              ? {
                  text: 'คิดเป็น ',
                  value: `${(
                    ((each?.amount || 0) * 100) /
                    sumOnSameIndex(each)
                  ).toFixed(2)} %`,
                }
              : {},
            information?.setting?.mms?.materialSplitting
              ?.displayStatisticalBeside &&
            each?.material_associate &&
            each?.index !== 0 &&
            !each.is_waste
              ? {
                  text: 'ปริมาณจากสถิติ',
                  value: ((each?.percent_amount * totalAmount) / 100).toFixed(
                    2,
                  ),
                }
              : {},
          ],
        },
      }),
    ),
    edges: _.map(processSteps, (each) => {
      if (each?.splited_step) {
        return {
          source: each?.from_index?.toString(),
          target: each?.index?.toString(),
        };
      }
      return {
        source: each?.index?.toString(),
        target: (each?.index + 1)?.toString(),
      };
    }),
  };

  const config = {
    data: modifiedStep,
    height,
    nodeCfg: {
      size: [180, 35],

      style: (cfg, group, type) => {
        const styles = {
          fill: cfg?.value?.waste ? '#FFE4E1' : '#E6EAF1',
          stroke: cfg?.value?.active
            ? theme?.palette?.primary?.light
            : '#B2BED5',
          margin: 6,
          lineWidth: cfg?.value?.active ? 2 : 1,

          radius: [2, 2, 2, 2],
          text: {
            fontFamily: ['Noto Sans Thai Looped', 'Noto Sans'],
            fontSize: 10,
          },
        };
        return styles;
      },

      title: {
        containerStyle: {
          fill: 'transparent',
        },
        style: {
          fill: '#000',
          fontSize: 10,
          fontFamily: ['Noto Sans Thai Looped', 'Noto Sans'],
        },
      },
      items: {
        containerStyle: {
          fill: '#fff',
          position: {
            top: 10,
          },
        },
        padding: 6,
        margin: 6,
        style: (cfg, group, type) => {
          const styles = {
            icon: {
              width: 12,
              height: 12,
            },
            text: {
              fill: '#aaa',
              fontFamily: ['Noto Sans Thai Looped', 'Noto Sans'],
              fontSize: 10,
            },
            value: {
              fontFamily: ['Noto Sans Thai Looped', 'Noto Sans'],
              fontSize: 10,
            },
          };
          return styles[type];
        },
      },
    },
    edgeCfg: {
      type: 'polyline',
      endArrow: true,
      label: {
        style: {
          fill: '#aaa',
          fontSize: 12,
          fillOpacity: 1,
          fontFamily: ['Noto Sans Thai Looped', 'Noto Sans'],
        },
      },
    },
    markerCfg: (cfg) => {
      const { edges } = modifiedStep;
      return {
        position: 'right',
        show: edges.find((item) => item.source === cfg.id),
      };
    },
    behaviors: [allowDrag ? 'drag-canvas' : '', allowDrag ? 'zoom-canvas' : ''],
    interactions: [
      {
        type: 'element-selected',
      },
      {
        type: 'element-active',
      },
    ],
  };

  return <FlowAnalysisGraph {...config} />;
};

export default MaterialSplittingBranchingStep;

MaterialSplittingBranchingStep.propTypes = {
  processSteps: PropTypes.arrayOf(PropTypes.object),
  allowDrag: PropTypes.bool,
  currentProcess: PropTypes.object,
  currentHoverIndex: PropTypes.number,
};
