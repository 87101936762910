/* eslint-disable import/prefer-default-export */
import pdfMake from 'addthaifont-pdfmake/build/pdfmake';
import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import _ from 'lodash';

import 'addthaifont-pdfmake/build/vfs_fonts';

import {
  converseMaterialOrProductUnit,
  MMS,
  notNan,
  percentYieldFormat,
} from '../../utils/functions';

import { ReportHeader } from './common';

dayjs.locale('th');
dayjs.extend(buddhistEra);
dayjs.extend(LocalizedFormat);

pdfMake.fonts = {
  Sarabun: {
    normal: 'Sarabun-Light.ttf',
    bold: 'Sarabun-Regular.ttf',
    italics: 'Sarabun-LightItalic.ttf',
    bolditalics: 'Sarabun-Italic.ttf',
  },
};

const {
  manufacturingMaterialAnalyzed,
  materialByMaterialAnalyzer,
  manufacturingMaterialStaticAnalyze,
  manufacturingMaterialAllStepByStepAnalyzer,
} = MMS.MaterialAnalyzer;

export const DetailReportMaterialReport = (
  material,
  manufacturingMaterial,
  selectedDate,
  information,
  isError,
) => {
  const overallAnalyze = manufacturingMaterialAnalyzed(
    manufacturingMaterial?.analyzed,
    true,
  );

  const manufacturingMaterialStaticAnalyzeResult = manufacturingMaterialStaticAnalyze(
    manufacturingMaterial?.rows,
  );

  const materialAnalyze = materialByMaterialAnalyzer(material);

  const renderMultiUnit = (productData, amount) =>
    productData?.use_unit_conversion
      ? `${productData?.unit_input?.short_sign || ''} \n (${notNan(
          converseMaterialOrProductUnit(productData, amount),
        )} ${productData?.unit_output?.short_sign || ''})`
      : productData?.unit;

  const genProductResult = _.map(
    manufacturingMaterialStaticAnalyzeResult.productStockLot,
    (_resultProd, index) => {
      const sumProd = _.sum(_.map(_resultProd, (_prod) => _prod?.quantity));
      return [
        {
          text: `${index + 1}`,
          colSpan: 1,
          fontSize: 10,
          border: [true, true, true, true],
        },
        {
          text: ` ${_resultProd?.[0]?.product?.type_code || ''} ${
            _resultProd?.[0]?.product?.name
          }`,
          colSpan: 1,
          fontSize: 10,
          border: [true, true, true, true],
        },
        {
          text: _.map(
            _.uniqBy(
              _.filter(_resultProd, (each) => each?.lot_number),
              'lot_number',
            ),
            (_prod) => `${_prod.lot_number || ''} \n`,
          ),
          colSpan: 1,
          fontSize: 10,
          border: [true, true, true, true],
        },
        {
          text: `  ${notNan(sumProd)} ${renderMultiUnit(
            _resultProd?.[0]?.product,
            sumProd,
          )}`,
          colSpan: 1,
          fontSize: 10,
          border: [true, true, true, true],
        },
      ];
    },
  );

  const genMaterialResult = _.map(
    materialAnalyze.resultAsMaterials,
    (_resultProd, index) => {
      const sumProd = _.sum(_.map(_resultProd, (_prod) => _prod?.quantity));
      return [
        {
          text: `${index + 1}`,
          colSpan: 1,
          fontSize: 10,
          border: [true, true, true, true],
        },
        {
          text: ` ${_resultProd?.[0]?.material?.type_code || ''} ${
            _resultProd?.[0]?.material?.name || ''
          }`,
          colSpan: 1,
          fontSize: 10,
          border: [true, true, true, true],
        },
        {
          text: _.map(
            _.uniqBy(
              _.filter(_resultProd, (each) => each?.lot_number),
              'lot_number',
            ),
            (_prod) => `${_prod.lot_number || ''} \n`,
          ),
          colSpan: 1,
          fontSize: 10,
          border: [true, true, true, true],
        },
        {
          text: `  ${notNan(sumProd)} ${renderMultiUnit(
            _resultProd?.[0]?.material,
            sumProd,
          )}`,
          colSpan: 1,
          fontSize: 10,
          border: [true, true, true, true],
        },
      ];
    },
  );

  const genStepMaterialUsage = () => {
    const manufacMaterialByStep = _.values(
      _.groupBy(
        manufacturingMaterial?.rows,
        (_manufacMaterial) => _manufacMaterial?.step?.name,
      ),
    );

    const allStepByStepAnalyzedResult = manufacturingMaterialAllStepByStepAnalyzer(
      manufacMaterialByStep,
    );

    const filteredManufacByStep = _.orderBy(
      _.filter(
        manufacMaterialByStep,
        (_manufacMat) => _manufacMat?.[0]?.step?.index !== undefined,
      ),
      ['0.step.index'],
    );

    const beforeStepResult = _.find(
      manufacMaterialByStep,
      (_manufacMat) => _manufacMat?.[0]?.step?.index === undefined,
    );

    const beforeStepAnalyze = manufacturingMaterialStaticAnalyze(
      beforeStepResult,
    );

    const beforeStepTableContent = [
      [
        {
          text: '1',
          colSpan: 1,
          fontSize: 10,
          border: [true, true, true, true],
        },
        {
          text: 'ก่อนเริ่มกระบวนการผลิต',
          colSpan: 1,
          fontSize: 10,
          border: [true, true, true, true],
        },
        {
          text: `${notNan(beforeStepAnalyze.incomingAmount)} `,
          colSpan: 1,
          fontSize: 10,
          border: [true, true, true, true],
        },
        {
          text: `${notNan(beforeStepAnalyze.requestAmount)} `,
          colSpan: 1,
          fontSize: 10,
          border: [true, true, true, true],
        },
        {
          text: `${notNan(beforeStepAnalyze.returnAmount)} `,
          colSpan: 1,
          fontSize: 10,
          border: [true, true, true, true],
        },
        {
          text: `${notNan(beforeStepAnalyze.usedAmount)} `,
          colSpan: 1,
          fontSize: 10,
          border: [true, true, true, true],
        },
        {
          text: `${notNan(beforeStepAnalyze.resultAmount)} `,
          colSpan: 1,
          fontSize: 10,
          border: [true, true, true, true],
        },
        {
          text: `${notNan(beforeStepAnalyze.wasteAmount)} `,
          colSpan: 1,
          fontSize: 10,
          border: [true, true, true, true],
        },
        {
          text: `${percentYieldFormat(beforeStepAnalyze.yield)} `,
          colSpan: 1,
          fontSize: 10,
          border: [true, true, true, true],
        },
      ],
    ];

    const workingStepTableContent = _.map(
      filteredManufacByStep,
      (_manufacMaterialStepSets, index) => {
        const eachMaterialAnalyze = allStepByStepAnalyzedResult[index + 1];

        return [
          {
            text: `${index + 2}`,
            colSpan: 1,
            fontSize: 10,
            border: [true, true, true, true],
          },
          {
            text: `${_manufacMaterialStepSets?.[0]?.step?.name || ''} `,
            colSpan: 1,
            fontSize: 10,
            border: [true, true, true, true],
          },
          {
            text: `${notNan(eachMaterialAnalyze.incomingAmount)} `,
            colSpan: 1,
            fontSize: 10,
            border: [true, true, true, true],
          },
          {
            text: `${notNan(eachMaterialAnalyze.requestAmount)} `,
            colSpan: 1,
            fontSize: 10,
            border: [true, true, true, true],
          },
          {
            text: `${notNan(eachMaterialAnalyze.returnAmount)} `,
            colSpan: 1,
            fontSize: 10,
            border: [true, true, true, true],
          },
          {
            text: `${notNan(eachMaterialAnalyze.usedAmount)} `,
            colSpan: 1,
            fontSize: 10,
            border: [true, true, true, true],
          },
          {
            text: `${notNan(eachMaterialAnalyze.resultAmount)} `,
            colSpan: 1,
            fontSize: 10,
            border: [true, true, true, true],
          },
          {
            text: `${notNan(eachMaterialAnalyze.wasteAmount)} `,
            colSpan: 1,
            fontSize: 10,
            border: [true, true, true, true],
          },
          {
            text: `${percentYieldFormat(eachMaterialAnalyze.yield)} `,
            colSpan: 1,
            fontSize: 10,
            border: [true, true, true, true],
          },
        ];
      },
    );

    return [...beforeStepTableContent, ...workingStepTableContent];
  };

  const docDefinition = {
    pageSize: 'A4',
    pageOrientation: 'portrait',
    pageMargins: [20, 30, 40, 20],
    defaultStyle: {
      font: 'Sarabun',
      fontSize: '16',
    },
    info: {
      title: 'ใบรายงานการใช้วัตถุดิบ',
    },
    content: [
      isError ? ReportHeader(information, false) : ReportHeader(information),
      {
        // หัวข้อ
        alignment: 'center',
        margin: [0, 5, 0, 10],
        text: 'ใบรายงานการใช้วัตถุดิบ',
        fontSize: '16',
        bold: true,
      },
      {
        // หัวข้อ
        alignment: 'center',
        margin: [0, 5, 0, 5],
        text: `${material?.type_code || ''} ${material?.name || '-'}`,
        fontSize: '12',
        bold: true,
      },
      {
        // หัวข้อ
        alignment: 'center',
        margin: [0, 0, 0, 15],
        text: `เดือน ${dayjs(selectedDate).format('MMMM')} พ.ศ. ${dayjs(
          selectedDate,
        ).format('BBBB')}`,
        fontSize: '12',
        bold: true,
      },

      {
        alignment: 'left',
        margin: [0, 10, 0, 10],
        text: 'สรุปการใช้งาน',
        fontSize: '12',
        bold: true,
      },

      {
        style: 'tableExample',
        table: {
          widths: ['16%', '16%', '16%', '16%', '16%', '*'],
          heights: [15],
          body: [
            [
              {
                text: 'เบิกมา',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [true, true, true, false],
              },
              {
                text: 'คืน',
                bold: true,
                colSpan: 1,
                fontSize: 10,
                border: [true, true, true, false],
              },
              {
                text: 'ใช้จริง',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [true, true, true, false],
              },
              {
                text: 'ได้เป็นผลผลิต',
                bold: true,
                colSpan: 1,
                fontSize: 10,
                border: [true, true, true, false],
              },
              {
                text: 'ของเสีย',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [true, true, true, false],
              },
              {
                text: 'Yield ของการผลิต',
                bold: true,
                colSpan: 1,
                fontSize: 10,
                border: [true, true, true, false],
              },
            ],
            [
              {
                text: `${notNan(
                  overallAnalyze?.requestAmount,
                )} ${renderMultiUnit(material, overallAnalyze?.requestAmount)}`,
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [true, true, true, true],
              },
              {
                text: `${notNan(
                  overallAnalyze?.returnAmount,
                )} ${renderMultiUnit(material, overallAnalyze?.returnAmount)}`,
                colSpan: 1,
                fontSize: 10,
                border: [true, true, true, true],
              },
              {
                text: `${notNan(overallAnalyze?.usedAmount)} ${renderMultiUnit(
                  material,
                  overallAnalyze?.usedAmount,
                )}`,
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [true, true, true, true],
              },
              {
                text: `${notNan(
                  overallAnalyze?.resultAmount,
                )} ${renderMultiUnit(material, overallAnalyze?.resultAmount)}`,
                colSpan: 1,
                fontSize: 10,
                border: [true, true, true, true],
              },
              {
                text: `${notNan(
                  overallAnalyze?.wastesAmount,
                )} ${renderMultiUnit(material, overallAnalyze?.wastesAmount)}`,
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [true, true, true, true],
              },
              {
                text: `${percentYieldFormat(overallAnalyze?.yield)} %`,
                colSpan: 1,
                fontSize: 10,
                border: [true, true, true, true],
              },
            ],
          ],
        },
      },
      !_.isEmpty(manufacturingMaterialStaticAnalyzeResult?.productStockLot)
        ? {
            alignment: 'left',
            margin: [0, 10, 0, 10],
            text: 'สินค้าที่ผลิตได้',
            fontSize: '12',
            bold: true,
          }
        : {},

      !_.isEmpty(manufacturingMaterialStaticAnalyzeResult?.productStockLot)
        ? {
            style: 'tableExample',
            table: {
              widths: ['10%', '*', '25%', '25%'],
              heights: [15],
              body: [
                [
                  {
                    text: 'ลำดับที่',
                    colSpan: 1,
                    bold: true,
                    fontSize: 10,
                    border: [true, true, true, false],
                  },
                  {
                    text: 'สินค้าที่ออกมา',
                    colSpan: 1,
                    bold: true,

                    fontSize: 10,
                    border: [true, true, true, false],
                  },
                  {
                    text: 'ล็อตสินค้า',
                    colSpan: 1,
                    bold: true,
                    fontSize: 10,
                    border: [true, true, true, false],
                  },
                  {
                    text: 'จำนวน',
                    colSpan: 1,
                    bold: true,
                    fontSize: 10,
                    border: [true, true, true, false],
                  },
                ],
                ...genProductResult,
              ],
            },
          }
        : {},
      !_.isEmpty(
        manufacturingMaterialStaticAnalyzeResult.materialResultStockLot,
      )
        ? {
            alignment: 'left',
            margin: [0, 10, 0, 10],
            text: 'วัตถุดิบที่ผลิตได้',
            fontSize: '12',
            bold: true,
          }
        : {},

      !_.isEmpty(
        manufacturingMaterialStaticAnalyzeResult.materialResultStockLot,
      )
        ? {
            style: 'tableExample',
            table: {
              widths: ['10%', '*', '25%', '25%'],
              heights: [15],
              body: [
                [
                  {
                    text: 'ลำดับที่',
                    colSpan: 1,
                    bold: true,
                    fontSize: 10,
                    border: [true, true, true, false],
                  },
                  {
                    text: 'วัตถุดิบที่ออกมา',
                    colSpan: 1,
                    bold: true,

                    fontSize: 10,
                    border: [true, true, true, false],
                  },
                  {
                    text: 'ล็อตวัตถุดิบ',
                    colSpan: 1,
                    bold: true,
                    fontSize: 10,
                    border: [true, true, true, false],
                  },
                  {
                    text: 'จำนวน',
                    colSpan: 1,
                    bold: true,
                    fontSize: 10,
                    border: [true, true, true, false],
                  },
                ],
                ...genMaterialResult,
              ],
            },
          }
        : {},

      {
        alignment: 'left',
        margin: [0, 10, 0, 10],
        text: 'การใช้วัตถุดิบในแต่ละขั้นตอน',
        fontSize: '12',
        bold: true,
      },

      {
        style: 'tableExample',
        table: {
          widths: ['7%', '*', '10%', '10%', '10%', '10%', '10%', '10%', '10%'],
          heights: [15],
          body: [
            [
              {
                text: 'ลำดับที่',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [true, true, true, false],
              },
              {
                text: 'ชื่อขั้นตอน',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [true, true, true, false],
              },
              {
                text: 'ยกมา',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [true, true, true, false],
              },
              {
                text: 'เบิกเพิ่ม',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [true, true, true, false],
              },
              {
                text: 'คืน',
                bold: true,
                colSpan: 1,
                fontSize: 10,
                border: [true, true, true, false],
              },
              {
                text: 'ใช้จริง',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [true, true, true, false],
              },
              {
                text: 'ผลผลิต',
                bold: true,
                colSpan: 1,
                fontSize: 10,
                border: [true, true, true, false],
              },
              {
                text: 'ของเสีย',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [true, true, true, false],
              },
              {
                text: 'Yield ',
                bold: true,
                colSpan: 1,
                fontSize: 10,
                border: [true, true, true, false],
              },
            ],
            ...genStepMaterialUsage(),
          ],
        },
      },
    ],
    images: {
      logo: information?.logo?.url,
    },
  };
  pdfMake.createPdf(docDefinition).open();
};
