import React from 'react';
import { useSelector } from 'react-redux';
import { Route } from 'react-router-dom';

import { NotAuthorized } from '../../components/Error';
import accessRight from '../../utils/functions/accessRight';
import { DashboardView, SettingView } from '../../views/MMS';

export function SettingMMS() {
  const module = 'MMS';
  const prefix = '/setting/mms';
  const name = 'ตั้งค่าระบบ';
  const me = useSelector((state) => state.me);
  const availableModule = useSelector((state) => state.availableModule);

  if (accessRight(me, module, 1, availableModule)) {
    return (
      <div>
        <Route exact path={`${prefix}/product-process`}>
          <SettingView.ProductProcess
            title="รายการวิธีการผลิตสินค้า"
            subtitle={name}
          />
        </Route>
        <Route exact path={`${prefix}/product-process/view/:id`}>
          <SettingView.DetailProductProcess
            title="วิธีการผลิตสินค้า"
            subtitle={name}
          />
        </Route>
        <Route exact path={`${prefix}/product-process/edit/:id`}>
          <SettingView.EditProductProcess
            title="แก้ไขวิธีการผลิตสินค้า"
            subtitle={name}
          />
        </Route>
        <Route exact path={`${prefix}/measurement-type`}>
          <SettingView.MeasurementType title="ตัวแปรรับค่า" subtitle={name} />
        </Route>
        <Route exact path={`${prefix}/measurement-type/edit/:id`}>
          <SettingView.EditMeasurementType
            title="แก้ไขตัวแปรรับค่า"
            subtitle={name}
          />
        </Route>{' '}
        <Route exact path={`${prefix}/process-template/create`}>
          <SettingView.CreateProcessTemplate
            title="สร้างสูตรการผลิต"
            subtitle={name}
          />
        </Route>
        <Route exact path={`${prefix}/process-template`}>
          <SettingView.ProcessTemplate title="สูตรการผลิต" subtitle={name} />
        </Route>{' '}
        <Route exact path={`${prefix}/process-template/edit/table/:id`}>
          <SettingView.EditProcessTemplateTableView
            title="แก้ไขสูตรการผลิต"
            subtitle={name}
          />
        </Route>
        <Route exact path={`${prefix}/process-template/edit/:id`}>
          <SettingView.EditProcessTemplate
            title="แก้ไขสูตรการผลิต"
            subtitle={name}
          />
        </Route>
        <Route exact path={`${prefix}/process-template/view/:id`}>
          <SettingView.DetailProcessTemplate
            title="รายละเอียดสูตรการผลิต"
            subtitle={name}
          />
        </Route>
        <Route exact path={`${prefix}/mo-template/`}>
          <SettingView.MOTemplates title="สูตรคำสั่งผลิต" subtitle={name} />
        </Route>
        <Route exact path={`${prefix}/mo-template/view/:id`}>
          <SettingView.DetailMOTemplate
            title="รายละเอียดสูตรคำสั่งผลิต"
            subtitle={name}
          />
        </Route>
        <Route exact path={`${prefix}/mo-template/edit/:id`}>
          <SettingView.EditMOTemplate
            title="แก้ไขสูตรคำสั่งผลิต"
            subtitle={name}
          />
        </Route>
        <Route exact path={`${prefix}/mo-template/create`}>
          <SettingView.CreateMOTemplate
            title="สร้างสูตรคำสั่งผลิต"
            subtitle={name}
          />
        </Route>
        <Route exact path={`${prefix}/product-bom`}>
          <SettingView.ProductBom
            title="รายการส่วนผสมแต่ละสินค้า"
            subtitle={name}
          />
        </Route>
        <Route exact path={`${prefix}/product-bom/edit/:id`}>
          <SettingView.EditProductBom
            title="แก้ไขส่วนผสมแต่ละสินค้า"
            subtitle={name}
          />
        </Route>
        <Route exact path={`${prefix}/product-bom/view/:id`}>
          <SettingView.ViewProductBom
            title="รายละเอียดส่วนผสมแต่ละสินค้า"
            subtitle={name}
          />
        </Route>
        <Route exact path={`${prefix}/bom-template`}>
          <SettingView.BOMTemplates title="รายการสูตรส่วนผสม" subtitle={name} />
        </Route>
        <Route exact path={`${prefix}/bom-template/create`}>
          <SettingView.CreateBOMTemplate
            title="สร้างสูตรส่วนผสม"
            subtitle={name}
          />
        </Route>
        <Route exact path={`${prefix}/bom-template/view/:id`}>
          <SettingView.ViewBOMTemplate
            title="รายละเอียดสูตรส่วนผสม"
            subtitle={name}
          />
        </Route>
        <Route exact path={`${prefix}/bom-template/edit/:id`}>
          <SettingView.EditBOMTemplate
            title="แก้ไชสูตรส่วนผสม"
            subtitle={name}
          />
        </Route>
        <Route exact path={`${prefix}/base-project`}>
          <SettingView.BaseProjects
            title="โปรเจกต์/สินค้าพื้นฐาน"
            subtitle={name}
          />
        </Route>
        <Route exact path={`${prefix}/base-project/edit/:id`}>
          <SettingView.EditBaseProject
            title="แก้ไขโปรเจกต์/สินค้าพื้นฐาน"
            subtitle={name}
          />
        </Route>{' '}
        <Route exact path={`${prefix}/base-project/create`}>
          <SettingView.CreateBaseProject
            title="สร้างโปรเจกต์/สินค้าพื้นฐาน"
            subtitle={name}
          />
        </Route>
        <Route exact path={`${prefix}/module-setting/`}>
          <SettingView.SettingMMS
            title="ตั้งค่าระบบควบคุมการผลิต"
            subtitle={name}
          />
        </Route>
        <Route exact path={`${prefix}/manufacturing-order-setting/`}>
          <SettingView.SettingManufacturingOrder
            title="ตั้งค่าคำสั่งผลิต"
            subtitle={name}
          />
        </Route>
        <Route exact path={`${prefix}/process-setting/`}>
          <SettingView.SettingProcess
            title="ตั้งค่าการดำเนินการผลิต"
            subtitle={name}
          />
        </Route>
        <Route exact path={`${prefix}/dashboard-element/`}>
          <DashboardView.DashboardElements
            title="ส่วนประกอบของแดชบอร์ด"
            subtitle={name}
          />
        </Route>
        <Route exact path={`${prefix}/dashboard-element/create`}>
          <DashboardView.CreateDashboardElement
            title="สร้างส่วนประกอบของแดชบอร์ด"
            subtitle={name}
          />
        </Route>
        <Route exact path={`${prefix}/dashboard-element/edit/:id`}>
          <DashboardView.EditDashboardElement
            title="แก้ไขส่วนประกอบของแดชบอร์ด"
            subtitle={name}
          />
        </Route>
        <Route exact path={`${prefix}/dashboard-element/view/:id`}>
          <DashboardView.ViewDashbaordElement
            title="รายละเอียดส่วนประกอบของแดชบอร์ด"
            subtitle={name}
          />
        </Route>
        <Route exact path={`${prefix}/mms-dashboard/`}>
          <DashboardView.MMSDashboards
            title="แดชบอร์ดการผลิต"
            subtitle={name}
          />
        </Route>
        <Route exact path={`${prefix}/mms-dashboard/create`}>
          <DashboardView.CreateMMSDashboard
            title="สร้างแดชบอร์ดการผลิต"
            subtitle={name}
          />
        </Route>
        <Route exact path={`${prefix}/mms-dashboard/edit/:id`}>
          <DashboardView.EditMMSDashboard
            title="แก้ไขแดชบอร์ดการผลิต"
            subtitle={name}
          />
        </Route>
        <Route exact path={`${prefix}/mms-dashboard/view/:id`}>
          <DashboardView.ViewMMSDashboard
            title="รายละเอียดแดชบอร์ด"
            subtitle={name}
          />
        </Route>
        <Route exact path={`${prefix}/audit`}>
          <SettingView.SystemAudit title="ปรับข้อมูลในระบบ" subtitle={name} />
        </Route>
      </div>
    );
  }
  return (
    <div>
      <Route path="*">
        <NotAuthorized />
      </Route>
    </div>
  );
}

export default SettingMMS;
