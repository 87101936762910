import React from 'react';
import { ChevronDown, ChevronUp } from 'react-feather';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { currencyFormatter } from '../../../utils/functions';
import MultiUnitProdOrMatBox from '../../Box/Rendering/MultiUnitProdOrMatBox';

const DisplayDifference = ({ amount, children }) => {
  if (amount > 0) {
    return (
      <div className="text-xs font-semibold text-red-500 flex gap-2 my-2">
        {children}
        <ChevronUp size="16" className="self-center" />
      </div>
    );
  }
  if (amount < 0) {
    return (
      <div className="text-xs font-semibold text-yellow-500 flex gap-2 my-2">
        {children}

        <ChevronDown size="16" className="self-center" />
      </div>
    );
  }
  return <div></div>;
};

const MOMaterialBomTable = ({ allAnalyzed }) => {
  const formularCost = _.sum(
    _.map(allAnalyzed, (eachAnalyze) => eachAnalyze?.formularCost),
  );

  const depositCost = _.sum(
    _.map(allAnalyzed, (eachAnalyze) => eachAnalyze?.depositCost),
  );

  const actualCost = _.sum(
    _.map(allAnalyzed, (eachAnalyze) => eachAnalyze?.actualCost),
  );

  return (
    <div className="my-2">
      <TableContainer component={Paper}>
        <Paper>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <div className="font-bold">ลำดับที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> วัตถุดิบ</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">ปริมาณตามสูตร</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">ปริมาณที่เบิกไป</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">ปริมาณที่ใช้จริง</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">ราคาตามสูตร</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">ราคาจากที่เบิกไป</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">ราคาที่ใช้จริง</div>
                </TableCell>

                <TableCell>
                  <div className="font-bold">เบิกเกินจากใช้จริง</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">เบิกน้อยกว่าใช้จริง</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(allAnalyzed) ? (
                _.map(allAnalyzed, (row, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      <div className="text-xs">{index + 1}</div>
                    </TableCell>
                    <TableCell>
                      {row?.material?.type_code || ''} {row?.material?.name}{' '}
                    </TableCell>
                    <TableCell>
                      <MultiUnitProdOrMatBox
                        foundMaterial={row?.material}
                        quantity={row?.totalPrefer}
                      />
                    </TableCell>
                    <TableCell>
                      <MultiUnitProdOrMatBox
                        foundMaterial={row?.material}
                        quantity={row?.totalDeposit}
                      />
                    </TableCell>
                    <TableCell>
                      {' '}
                      <MultiUnitProdOrMatBox
                        foundMaterial={row?.material}
                        quantity={row?.totalActual}
                      />
                      <DisplayDifference amount={row?.difference}>
                        <MultiUnitProdOrMatBox
                          foundMaterial={row?.material}
                          quantity={row?.difference}
                        />
                      </DisplayDifference>
                    </TableCell>
                    <TableCell>
                      {currencyFormatter.format(row?.formularCost)} บาท
                    </TableCell>
                    <TableCell>
                      {currencyFormatter.format(row?.depositCost)} บาท
                      <DisplayDifference amount={row?.difference}>
                        {currencyFormatter.format(
                          row?.depositCost - row?.formularCost,
                        )}{' '}
                        บาท
                      </DisplayDifference>
                    </TableCell>
                    <TableCell>
                      {currencyFormatter.format(row?.actualCost)} บาท
                      <DisplayDifference amount={row?.difference}>
                        {currencyFormatter.format(
                          row?.actualCost - row?.formularCost,
                        )}{' '}
                        บาท
                      </DisplayDifference>
                    </TableCell>

                    <TableCell>
                      {' '}
                      <MultiUnitProdOrMatBox
                        foundMaterial={row?.material}
                        quantity={row?.overDepositToActual}
                      />{' '}
                    </TableCell>
                    <TableCell>
                      {' '}
                      <MultiUnitProdOrMatBox
                        foundMaterial={row?.material}
                        quantity={row?.lessDepositToActual}
                      />{' '}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <div className="text-center">ไม่มีข้อมูล</div>
                  </TableCell>
                </TableRow>
              )}
              <TableRow>
                <TableCell colSpan={5}>รวม</TableCell>
                <TableCell>
                  <div className="font-semibold text-lg">
                    {currencyFormatter.format(formularCost)}{' '}
                  </div>
                </TableCell>
                <TableCell>
                  {' '}
                  <div className="font-semibold text-lg">
                    {currencyFormatter.format(depositCost)}{' '}
                  </div>
                  <DisplayDifference amount={depositCost - formularCost}>
                    {currencyFormatter.format(depositCost - formularCost)}{' '}
                  </DisplayDifference>
                </TableCell>
                <TableCell>
                  <div className="font-semibold text-lg">
                    {currencyFormatter.format(actualCost)}{' '}
                  </div>
                  <DisplayDifference amount={actualCost - formularCost}>
                    {currencyFormatter.format(actualCost - formularCost)}{' '}
                  </DisplayDifference>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Paper>
      </TableContainer>
    </div>
  );
};

DisplayDifference.propTypes = {
  amount: PropTypes.number,
  children: PropTypes.object,
};

MOMaterialBomTable.propTypes = {
  allAnalyzed: PropTypes.arrayOf(PropTypes.object),
};

export default MOMaterialBomTable;
