import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Card,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  TextField,
} from '@mui/material';
import { gql } from 'graphql-request';
import PropTypes from 'prop-types';

import {
  Error,
  Loading,
  ProcessWithMeasurementTable,
  ViewTitle,
} from '../../../components';
import graphqlClient from '../../../configs/graphqlClient';
import * as actions from '../../../redux/actions';

/**
 * @function ProductionLines
 * @description Display a collections or a list of ProductionLines from database
 */

export default function ProductionLines({ title, subtitle }) {
  const dispatch = useDispatch();
  const allProcess = useSelector((state) => state.process);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [name, setName] = useState('');
  const [total, setTotal] = useState();
  const [searchTerm, setSearchTerm] = useState('');
  const [showCompleted, setShowCompleted] = useState(false);
  const [showDeleted, setShowDeleted] = useState(false);

  const query = gql`
    query FindProductionLines($processInput: ProcessInput) {
      findProcesses(input: $processInput) {
        currPage
        lastPage
        total
        rows {
          _id
          produce_material_instead
          product {
            _id
            name
            type_code
            use_unit_conversion
            unit
            conversion_from {
              unit_a
              unit_b
              conversion_factor
            }
            conversion_to {
              unit_b
              unit_a
              conversion_factor
            }
            unit_input {
              _id
              short_sign
              name
            }
            unit_output {
              _id
              short_sign
              name
            }
          }
          product_as_material {
            _id
            name
            type_code
            use_unit_conversion
            unit
            conversion_from {
              unit_a
              unit_b
              conversion_factor
            }
            conversion_to {
              unit_b
              unit_a
              conversion_factor
            }
            unit_input {
              _id
              short_sign
              name
            }
            unit_output {
              _id
              short_sign
              name
            }
          }
          customer {
            _id
            type_code
            name
          }
          manufacturing_order {
            _id
            running_number
          }
          quantity
          steps {
            name
            measurements {
              measurement_type {
                name
                data_type
              }
              value
            }
            resultingFunctionTypes {
              _id
              name
              value
            }
          }
          completed
          deleted
        }
      }
    }
  `;

  const queryDataFromServer = async () => {
    try {
      const queryResult = await graphqlClient.request(query, {
        processInput: {
          page,
          size,
          fetchInsideProcess: false,
          completed: showCompleted,
          deleted: showDeleted,
        },
      });
      const processData = queryResult?.findProcesses;
      dispatch(actions.processStateSet(processData));
    } catch (error) {
      console.error('Error on Query', error);
      dispatch(actions.processError());
    }
  };

  const queryInsideDataFromServer = async () => {
    try {
      const queryResult = await graphqlClient.request(query, {
        processInput: {
          page,
          size,
          fetchInsideProcess: true,
          productName: name,
          fetchProduct: true,
          fetchCustomer: true,
          completed: showCompleted,
          deleted: showDeleted,
        },
      });
      const processData = queryResult?.findProcesses;
      dispatch(actions.processStateSet(processData));
    } catch (error) {
      console.error('Error on Query', error);
      dispatch(actions.processError());
    }
  };

  useEffect(() => {
    setTotal(allProcess?.total);
    return () => {};
  }, [allProcess]);

  useEffect(() => {
    queryDataFromServer();
    console.log('Waiting for time to fetch inside');
    const fetchInsideTimeout = setTimeout(() => {
      queryInsideDataFromServer();
    }, 1000);
    return () => {
      console.log('Clear Fetch inside Timeout');
      clearTimeout(fetchInsideTimeout);
    };
  }, [page, size, name, showCompleted, showDeleted]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchTerm);
      setPage(1);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const renderSearch = () => (
    <Card>
      <div className="p-4 flex flex-row flex-wrap justify-between">
        <div className="w-full md:w-1/2">
          <TextField
            label="ค้นหา"
            fullWidth
            size="small"
            id="outlined-start-adornment"
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <i className="fas fa-search"></i>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className="w-full lg:w-1/3">
          <div>
            <FormControlLabel
              label="แสดงรายการที่สำเร็จ"
              control={
                <Checkbox
                  checked={showCompleted}
                  onChange={(e) => setShowCompleted(e.target.checked)}
                />
              }
            />
          </div>
          <div>
            <FormControlLabel
              label="แสดงรายการที่ยกเลิก"
              control={
                <Checkbox
                  checked={showDeleted}
                  onChange={(e) => setShowDeleted(e.target.checked)}
                />
              }
            />
          </div>
        </div>
      </div>
    </Card>
  );

  if (allProcess.isLoading) {
    return <Loading />;
  }
  if (!allProcess.isLoading && allProcess.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div className="my-2">{renderSearch()}</div>
        <div className="my-2">
          <ProcessWithMeasurementTable
            processData={allProcess?.rows}
            page={page}
            size={size}
            total={total}
            handleChangePage={handleChangePage}
            handleChangeRowPerPage={handleChangeRowPerPage}
          />
        </div>
      </div>
    );
  }
  return <Error />;
}

ProductionLines.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

ProductionLines.defaultProps = {
  title: '',
  subtitle: '',
};
