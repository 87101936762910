import React, { useEffect, useState } from 'react';
import { Check as CheckIcon } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Card, CardContent, Chip } from '@mui/material';
import dayjs from 'dayjs';
import BuddhistEra from 'dayjs/plugin/buddhistEra';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { BackButton } from '../../../components/Button';
import { Error } from '../../../components/Error';
import { Loading } from '../../../components/Loading';
import { ManufacturingMaterialTable } from '../../../components/Tables';
import { ViewTitle } from '../../../components/ViewTitle';
import * as actions from '../../../redux/actions';
import { manufacturingMaterialAnalyzed } from '../../../utils/functions/MMS/materialAnalyzer';
import notNan from '../../../utils/functions/notNan';

dayjs.extend(BuddhistEra);

const DetailMaterialOnProcess = ({ title, subtitle }) => {
  const dispatch = useDispatch();
  const params = useParams();
  // หลีกคำว่า process เพราะมันไปตรงกับตัวแปรอื่นใน js
  const currentProcess = useSelector((state) => state.process);
  const material = useSelector((state) => state.material);
  const manufacturingMaterial = useSelector(
    (state) => state.manufacturingMaterial,
  );

  const [size] = useState(10);
  const [page, setPage] = useState(1);
  const [error, setError] = useState({ message: '' });
  const [total, setTotal] = useState(undefined);
  const [filterStep, setFilteredStep] = useState(undefined);

  const materialResult = manufacturingMaterialAnalyzed(
    manufacturingMaterial?.analyzed,
    !filterStep,
  );

  // Data initialization
  useEffect(() => {
    const fetchProcess = async () => {
      try {
        await dispatch(actions.processGet(params.processid));
      } catch (err) {
        setError(err);
        console.error(err);
      }
    };

    const fetchManufacturingMaterials = async () => {
      try {
        await dispatch(
          actions.manufacMaterialAll({
            processId: params.processid,
            step: filterStep,
            material: params.materialid,
            size,
            page,
          }),
        );
      } catch (err) {
        setError(err);
        console.error('Error on Fetching Manufacturing Materials', err);
      }
    };

    const getMaterial = async () => {
      try {
        await dispatch(
          actions.materialGet(params.materialid, {
            placeId: '',
            fetchStockLot: false,
            stockStatus: '',
          }),
        );
      } catch (err) {
        setError(err);
        console.error('Error on get material', err);
      }
    };

    getMaterial();
    fetchProcess();
    fetchManufacturingMaterials();
    setTotal(manufacturingMaterial?.total);

    return () => {};
  }, [params, page, size, filterStep]);

  useEffect(() => {
    setPage(1);

    return () => {};
  }, [filterStep]);

  const handleChangePage = (selectedPage) => {
    setPage(selectedPage);
  };

  useEffect(() => {
    if (manufacturingMaterial?.total) {
      setTotal(manufacturingMaterial?.total);
    }
    return () => {};
  }, [manufacturingMaterial]);

  const numberFormatting = (row) => {
    try {
      if (row === Infinity) {
        return '100';
      }
      if (row === -Infinity) {
        return '-';
      }
      return row?.toFixed(2);
    } catch (err) {
      return '-';
    }
  };
  // UI Render
  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const renderInfoCard = () => (
    <Card className="p-4 py-6">
      <h4 className="text-lg mx-2">
        <span className="bg-gray-200 p-1 px-2 rounded-md font-bold">
          {material?.type_code} {material?.name}
        </span>{' '}
      </h4>
      <div className="flex flex-wrap w-full my-2 ">
        <div className="w-full md:w-1/6 py-1 md:py-2 px-2 font-semibold font-display">
          เลขที่คำสั่งผลิต
        </div>
        <div className="w-full md:w-5/6 py-1 md:py-2 px-2 font-bold">
          {currentProcess?.manufacturing_order?.prefix}
          {currentProcess?.manufacturing_order?.running_number}
        </div>
        <div className="w-full md:w-1/6 py-1 md:py-2 px-2 font-semibold font-display">
          สินค้า
        </div>
        <div className="w-full md:w-5/6 py-1 md:py-2 px-2">
          {currentProcess?.product?.type_code} {currentProcess?.product?.name}{' '}
          {currentProcess?.quantity !== 0
            ? `จำนวน ${notNan(currentProcess?.quantity)} ${
                currentProcess?.product?.unit
              }
`
            : ''}{' '}
        </div>
      </div>
    </Card>
  );

  const renderGraphCard = () => (
    <Card className="my-4">
      <CardContent>
        <div className="flex flex-wrap ">
          <div className="w-full  px-4">
            <h6 className="my-4 justify-center  text-xl font-semibold font-display">
              สรุปการใช้วัตถุดิบ
            </h6>
            <div className="rounded-md p-2">
              <div className="my-1 px-2 text-sm">จากกระบวนการ</div>
              <div className="flex gap-2 flex-wrap">
                <Chip
                  icon={
                    filterStep === undefined ? <CheckIcon size={16} /> : <></>
                  }
                  color={filterStep === undefined ? 'primary' : 'default'}
                  label="กระบวนการทั้งหมด"
                  onClick={() => setFilteredStep(undefined)}
                ></Chip>
                {_.map(currentProcess?.steps, (_step, index) => (
                  <Chip
                    icon={
                      filterStep === _step?._id ? (
                        <CheckIcon size={16} />
                      ) : (
                        <></>
                      )
                    }
                    color={filterStep === _step?._id ? 'primary' : 'default'}
                    label={_step?.name}
                    key={index}
                    onClick={() => setFilteredStep(_step?._id)}
                  ></Chip>
                ))}
              </div>
            </div>
            <div className="grid lg:grid-cols-7 md:grid-cols-4  sm:grid-cols-2 p-2">
              <div className="my-2">
                <h4>ยกมา</h4>
                <div className="flex gap-2">
                  <div className="font-bold text-2xl">
                    {!filterStep ? '-' : notNan(materialResult.handoverAmount)}
                  </div>
                  <div className="text-sm self-end">{material?.unit} </div>
                </div>
              </div>{' '}
              <div className="my-2">
                <h4>เบิกเพิ่ม</h4>
                <div className="flex gap-2">
                  <div className="font-bold text-2xl">
                    {notNan(materialResult.requestAmount)}
                  </div>
                  <div className="text-sm self-end">{material?.unit} </div>
                </div>
              </div>{' '}
              <div className="my-2">
                <h4>คืน</h4>
                <div className="flex gap-2">
                  <div className="font-bold text-2xl">
                    {notNan(materialResult.returnAmount)}
                  </div>
                  <div className="text-sm self-end">{material?.unit} </div>
                </div>
              </div>
              <div className="my-2">
                <h4>ใช้จริง</h4>
                <div className="flex gap-2">
                  <div className="font-bold text-2xl">
                    {notNan(materialResult.usedAmount)}
                  </div>
                  <div className="text-sm self-end">{material?.unit} </div>
                </div>
              </div>
              {materialResult?.mergedAmount > 0 && (
                <div className="my-2">
                  <h4>รวมเป็นวัตถุดิบอื่น</h4>
                  <div className="flex gap-2">
                    <div className="font-bold text-2xl">
                      {notNan(materialResult.mergedAmount)}
                    </div>
                    <div className="text-sm self-end">{material?.unit} </div>
                  </div>
                </div>
              )}
              {materialResult?.mergingAmount > 0 && (
                <div className="my-2">
                  <h4>มาจากการรวมวัตถุดิบอื่นๆ</h4>
                  <div className="flex gap-2">
                    <div className="font-bold text-2xl">
                      {notNan(materialResult.mergingAmount)}
                    </div>
                    <div className="text-sm self-end">{material?.unit} </div>
                  </div>
                </div>
              )}
              <div className="my-2">
                <h4>ได้เป็นผลผลิต</h4>
                <div className="flex gap-2">
                  <div className="font-bold text-2xl">
                    {notNan(materialResult.resultAmount)}
                  </div>
                  <div className="text-sm self-end">{material?.unit} </div>
                </div>
              </div>
              <div className="my-2">
                <h4>ของเสีย</h4>
                <div className="flex gap-2">
                  <div className="font-bold text-2xl">
                    {notNan(materialResult.wastesAmount)}
                  </div>
                  <div className="text-sm self-end">{material?.unit} </div>
                </div>
              </div>
              <div className="my-2">
                <h4>Yield ของการผลิต</h4>
                <div className="flex gap-2">
                  <div className="font-bold text-2xl">
                    {numberFormatting(materialResult.yield)} %
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <CardContent>
          <h6 className="my-4 justify-center font-semibold font-displaytext-xl ">
            รายการการใช้วัตถุดิบ
          </h6>
          <ManufacturingMaterialTable
            handleChangePage={handleChangePage}
            page={page}
            size={size}
            total={total}
            manufacturingMaterials={manufacturingMaterial?.rows}
          />
        </CardContent>
      </CardContent>
    </Card>
  );

  if (currentProcess?.isLoading || material?.isLoading) {
    return <Loading />;
  }

  if (
    !currentProcess?.isLoading &&
    currentProcess?.isCompleted &&
    !material?.isLoading &&
    material?.isCompleted
  ) {
    return (
      <div>
        {renderTitle()}
        <div className="flex flex-row justify-start pb-4">
          <div>
            <BackButton />
          </div>
        </div>
        <div>
          {renderInfoCard()}
          {renderGraphCard()}
        </div>
      </div>
    );
  }

  return <Error message={error?.message} />;
};

DetailMaterialOnProcess.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

DetailMaterialOnProcess.defaultProps = {
  title: '',
  subtitle: '',
};
export default DetailMaterialOnProcess;
