import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Card, CardContent } from '@mui/material';
import { gql } from 'graphql-request';
import _ from 'lodash';
import PropTypes from 'prop-types';

import {
  BackButton,
  Error,
  Loading,
  ProductBomForm,
  ViewTitle,
} from '../../../components';
import { app as appConfig } from '../../../configs';
import graphqlClient from '../../../configs/graphqlClient';
import * as actions from '../../../redux/actions';
import { converseMaterialOrProductUnit } from '../../../utils/functions';
/**
 * @function SettingEditProductBom
 * @description Display a collections or a list of SettingEditProductBom from database
 */

export default function EditProductBom({ title, subtitle }) {
  const dispatch = useDispatch();
  const product = useSelector((state) => state.product);
  const material = useSelector((state) => state.material);
  const history = useHistory();
  const params = useParams();

  const {
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    control,
  } = useForm({
    defaultValues: {
      bill_of_materials: product?.bill_of_materials || [],
    },
  });

  const query = gql`
    query FindSettingEditProductBom(
      $productInput: ProductInput
      $materialInput: MaterialInput
    ) {
      findOneProduct(input: $productInput) {
        _id
        name
        type_code
        bill_of_materials {
          amount
          tag
          material {
            _id
            name
            type_code
            use_unit_conversion
            unit
            conversion_from {
              unit_a
              unit_b
              conversion_factor
            }
            conversion_to {
              unit_b
              unit_a
              conversion_factor
            }
            unit_input {
              _id
              short_sign
              name
            }
            unit_output {
              _id
              short_sign
              name
            }
          }
        }
        use_unit_conversion
        unit
        conversion_from {
          unit_a
          unit_b
          conversion_factor
        }
        conversion_to {
          unit_b
          unit_a
          conversion_factor
        }
        unit_input {
          _id
          short_sign
          name
        }
        unit_output {
          _id
          short_sign
          name
        }
      }
      findMaterials(input: $materialInput) {
        total
        rows {
          _id
          name
          type_code
          use_unit_conversion
          unit
          conversion_from {
            unit_a
            unit_b
            conversion_factor
          }
          conversion_to {
            unit_b
            unit_a
            conversion_factor
          }
          unit_input {
            _id
            short_sign
            name
          }
          unit_output {
            _id
            short_sign
            name
          }
        }
      }
    }
  `;

  const queryDataFromServer = async () => {
    try {
      const queryResult = await graphqlClient.request(query, {
        productInput: { id: params.id, fetchBOM: true },
        materialInput: {
          page: 1,
          size: appConfig.maxFetchSize,
          fetchStockLot: false,
          fetchProcess: false,
        },
      });
      const productData = queryResult?.findOneProduct;
      const materialData = queryResult?.findMaterials;
      dispatch(actions.productStateOneSet(productData));
      dispatch(actions.materialStateSet(materialData));
    } catch (err) {
      console.error('Error on Quering', err);
    }
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [params]);

  useEffect(() => {
    if (product?.bill_of_materials) {
      setValue('bill_of_materials', product.bill_of_materials);
    }
    return () => {};
  }, [product]);

  const handleEditProductBom = async (data) => {
    try {
      if (data.selected_unit === 'unit_output') {
        const currentMaterial = data.bill_of_materials;
        const editedMaterial = _.map(currentMaterial, (eachMaterial) => {
          const newMaterialAmount = converseMaterialOrProductUnit(
            product,
            eachMaterial.amount,
          );

          return { ...eachMaterial, amount: newMaterialAmount };
        });
        await dispatch(
          actions.productPut(params.id, { bill_of_materials: editedMaterial }),
        );
        alert('สำเร็จ');
        history.goBack();
      } else {
        await dispatch(actions.productPut(params.id, data));
        alert('สำเร็จ');
        history.goBack();
      }
    } catch (error) {
      alert('การใส่ส่วนผสมสินค้าเกิดข้อผิดพลาด', error?.message);
      console.error('Error on Edit Product Bom', error);
    }
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (product.isLoading) {
    return <Loading />;
  }

  if (!product.isLoading && product.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div className="my-2">
          <BackButton />
        </div>
        <div className="my-2">
          <Card>
            <CardContent>
              <div className="text-lg font-semibold font-display">
                {product?.type_code} {product?.name}{' '}
              </div>
              <div>ปริมาณวัตถุดิบต่อหนึ่งหน่วยสินค้า</div>
              <form onSubmit={handleSubmit(handleEditProductBom)}>
                <ProductBomForm
                  control={control}
                  errors={errors}
                  material={material}
                  setValue={setValue}
                  watch={watch}
                  product={product}
                />
                <div className="flex justify-end">
                  <Button type="submit" variant="contained">
                    บันทึก
                  </Button>
                </div>
              </form>
            </CardContent>
          </Card>
        </div>
      </div>
    );
  }
  return <Error />;
}

EditProductBom.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

EditProductBom.defaultProps = {
  title: '',
  subtitle: '',
};
