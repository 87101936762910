import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Card, InputAdornment, TextField } from '@mui/material';
import PropTypes from 'prop-types';

import { Error } from '../../../components/Error';
import Loading from '../../../components/Loading';
import { ProcessTemplateTable } from '../../../components/Tables';
import { ViewTitle } from '../../../components/ViewTitle';
import * as actions from '../../../redux/actions';

export default function ProductProcess({ title, subtitle }) {
  const dispatch = useDispatch();
  const product = useSelector((state) => state.product);
  const history = useHistory();

  // Setting React hook form
  const [searchTerm, setSearchTerm] = useState('');
  const [name, setName] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(undefined);

  useEffect(() => {
    const fetchProcessTemplate = async () => {
      try {
        await dispatch(actions.productAll({ name, page, size }));
      } catch (error) {
        console.error(error);
      }
    };

    fetchProcessTemplate();

    return () => {};
  }, [name, page, size]);

  useEffect(() => {
    setTotal(product?.total);
    return () => {};
  }, [product]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchTerm);
      setPage(1);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const handleDelete = async (id) => {
    const confirm = window.confirm('ยืนยันการลบข้อมูล');
    if (confirm) {
      try {
        await dispatch(actions.processTemplateDelete(id));
        await dispatch(actions.processTemplateAll({ name, page, size }));
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handlePushToEditPage = async (id) => {
    history.push(`process-templates/edit/${id}`);
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const renderSearch = () => (
    <Card>
      <div className="p-4 flex flex-row">
        <div className="w-full md:w-1/2">
          <TextField
            label="ค้นหา"
            fullWidth
            size={'small'}
            id="outlined-start-adornment"
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <i className="fas fa-search"></i>
                </InputAdornment>
              ),
            }}
          />
        </div>
      </div>
    </Card>
  );

  const renderTable = () => (
    <ProcessTemplateTable
      page={page}
      size={size}
      total={total}
      handleChangePage={handleChangePage}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      handleDelete={handleDelete}
      handlePushToEditPage={handlePushToEditPage}
      product={product}
    />
  );

  if (product.isLoading) {
    return <Loading />;
  }
  if (!product.isLoading && product.isCompleted) {
    return (
      <div>
        <div className="flex justify-between">
          <div>{renderTitle()}</div>
        </div>
        {renderSearch()}
        {renderTable()}
      </div>
    );
  }
  return <Error message={product?.message} />;
}

ProductProcess.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

ProductProcess.defaultProps = {
  title: '',
  subtitle: '',
};
