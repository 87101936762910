/* eslint-disable import/prefer-default-export */
import pdfMake from 'addthaifont-pdfmake/build/pdfmake';
import 'addthaifont-pdfmake/build/vfs_fonts';
import _ from 'lodash';
import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import currencyFormatterTH from '../../utils/functions/currencyFormatterTH';
import { ReportHeader } from './common';

dayjs.locale('th');
dayjs.extend(buddhistEra);
dayjs.extend(LocalizedFormat);

pdfMake.fonts = {
  Sarabun: {
    normal: 'Sarabun-Light.ttf',
    bold: 'Sarabun-Regular.ttf',
    italics: 'Sarabun-LightItalic.ttf',
    bolditalics: 'Sarabun-Italic.ttf',
  },
};

const genProductTransaction = (data) => {
  return _.map(data, (_data, index) => [
    {
      text: index + 1,
      border: [true, false, true, true],
      fontSize: 10,
      colSpan: 1,
    },
    {
      text: `${_data?.lotId?.lot_number || '-'}`,
      border: [true, false, true, true],
      fontSize: 10,
      colSpan: 1,
    },
    {
      text: `${_data?.lotId?.product?.type_code || ''} ${
        _data?.lotId?.product?.name
      }`,
      border: [true, false, true, true],
      fontSize: 10,
      colSpan: 1,
    },
    {
      text: `${
        currencyFormatterTH.format(_data?.lotId?.cost_price) ||
        currencyFormatterTH.format(_data?.lotId?.price)
      } บาท`,
      border: [true, false, true, true],
      fontSize: 10,
      colSpan: 1,
    },
    {
      text: `${currencyFormatterTH.format(_data?.quantity)} ${
        _data?.lotId?.product?.unit
      }`,
      border: [true, false, true, true],
      fontSize: 10,
      colSpan: 1,
    },
    {
      text: `${dayjs(_data?.lotId?.production_date)
        .locale('th')
        .format('DD MMM BBBB')}`,
      border: [true, false, true, true],
      fontSize: 10,
      colSpan: 1,
    },
    {
      text: `${dayjs(_data?.lotId?.expiration_date)
        .locale('th')
        .format('DD MMM BBBB')}`,
      border: [true, false, true, true],
      fontSize: 10,
      colSpan: 1,
    },
  ]);
};

export const DetailProductHTypeAddReport = (
  ProductTransaction,
  information,
  isError,
) => {
  console.log('ProductTransaction', ProductTransaction);
  console.log('information', information);

  const ProductData = genProductTransaction(
    ProductTransaction?.product_stock_lot,
  );
  const docDefinition = {
    pageSize: 'A4',
    pageOrientation: 'portrait',
    pageMargins: [20, 30, 40, 20],
    defaultStyle: {
      font: 'Sarabun',
      fontSize: '16',
    },
    info: {
      title: 'ประวิติการจัดการวัตถุดิบ',
    },
    content: [
      isError ? ReportHeader(information, false) : ReportHeader(information),
      {
        // หัวข้อ
        alignment: 'center',
        margin: [0, 5, 0, 10],
        text: `${ProductTransaction?.product_transaction_type?.name}`,
        fontSize: '16',
        bold: true,
      },
      {
        style: 'tableExample',
        table: {
          widths: ['15%', '37%', '15%', '37%'],
          heights: [15],
          body: [
            [
              {
                text: 'วันที่เข้าคลัง',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [false, false, false, false],
              },
              {
                text: `${dayjs(ProductTransaction?.createdAt)
                  .locale('th')
                  .format('DD MMMM BBBB')} เวลา ${new Date(
                  ProductTransaction?.createdAt,
                ).toLocaleTimeString('th')} น.`,
                colSpan: 1,
                fontSize: 10,
                border: [false, false, false, false],
              },
              {
                text: 'คลัง',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [false, false, false, false],
              },
              {
                text: `${ProductTransaction?.warehouse?.name}`,
                colSpan: 1,
                fontSize: 10,
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: 'หมายเลขล็อต',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [false, false, false, false],
              },
              {
                text: `${
                  ProductTransaction?.product_stock_lot[0]?.lotId?.lot_number ||
                  '-'
                }`,
                colSpan: 1,
                fontSize: 10,
                border: [false, false, false, false],
              },
              {
                text: 'เลขที่สั่งผลิต',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [false, false, false, false],
              },
              {
                text: `${
                  ProductTransaction?.product_stock_lot[0]?.lotId
                    ?.production_number?.prefix || ''
                }${
                  ProductTransaction?.product_stock_lot[0]?.lotId
                    ?.production_number?.running_number || '-'
                }`,
                colSpan: 1,
                fontSize: 10,
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: 'คู่ค้า',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [false, false, false, false],
              },
              {
                text: `${
                  ProductTransaction?.product_stock_lot[0]?.lotId?.supplier
                    ?.name || '-'
                }`,
                colSpan: 1,
                fontSize: 10,
                border: [false, false, false, false],
              },
              {
                text: 'ผู้รับสินค้า',
                colSpan: 1,
                bold: true,
                fontSize: 10,
                border: [false, false, false, false],
              },
              {
                text: `${
                  ProductTransaction?.product_stock_lot[0]?.lotId
                    ?.consignee_name || '-'
                }`,
                colSpan: 1,
                fontSize: 10,
                border: [false, false, false, false],
              },
            ],
          ],
        },
      },
      {
        margin: [0, 15, 30, 0],
        style: 'tableExample',
        table: {
          widths: ['8%', '15%', '30%', '13%', '13%', '17%', '17%'],
          heights: [15],
          body: [
            [
              {
                text: 'ลำดับ',
                colSpan: 1,
                fontSize: 10,
                bold: true,
              },
              {
                text: 'หมายเลขล็อต',
                colSpan: 1,
                fontSize: 10,
                bold: true,
              },
              {
                text: 'ชื่อสินค้า',
                colSpan: 1,
                fontSize: 10,
                bold: true,
              },
              {
                text: 'ราคาต้นทุน',
                colSpan: 1,
                fontSize: 10,
                bold: true,
              },
              {
                text: 'จำนวน',
                colSpan: 1,
                fontSize: 10,
                bold: true,
              },
              {
                text: 'วันที่ผลิต',
                colSpan: 1,
                fontSize: 10,
                bold: true,
              },
              {
                text: 'วันที่หมดอายุ',
                colSpan: 1,
                fontSize: 10,
                bold: true,
              },
            ],
            ...ProductData,
          ],
        },
      },
      {
        alignment: '่justify',
        margin: [0, 20, 0, 10],
        text: `หมายเหตุ : ${ProductTransaction?.remark}`,
        fontSize: '10',
      },
    ],
    images: {
      logo: information?.logo?.url,
    },
  };
  pdfMake.createPdf(docDefinition).open();
};
