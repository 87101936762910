/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Card, CardContent } from '@mui/material';
import dayjs from 'dayjs';
import _ from 'lodash';
import PropTypes from 'prop-types';

import {
  BackButton,
  Error,
  Loading,
  MultiUnitProdOrMatBox,
  ViewTitle,
} from '@/components';
import { ProdOrMatQuantityInput } from '@/components/Inputs';
import * as actions from '@/redux/actions';

/**
 * @function EditMaterialHistory
 * @description Display a collections or a list of MaterialHistoryEdit from database
 */

function RenderMaterialStockLotEdit({
  materialStockLot,
  materialTransaction,
  index,
}) {
  const dispatch = useDispatch();
  const { control, watch, setValue, handleSubmit } = useForm({
    defaultValues: {
      ...materialStockLot,
    },
  });

  const handleUpdateMaterialLotAndTransaction = async (data) => {
    try {
      await dispatch(
        actions.materialStockLotPut(materialStockLot?.lotId?._id, {
          quantity: data?.lotId?.quantity,
          amount: data?.lotId?.amount,
          status:
            parseFloat(data?.lotId?.amount) === 0 ? 'OUT_STOCK' : 'IN_STOCK',
        }),
      );

      console.log('Update Material Stocklot Success');
      const newMaterialStockLot = {
        ...materialStockLot,
        quantity: data?.quantity,
        amount: data?.amount,
      };

      const newMaterialTransaction = materialTransaction;
      newMaterialTransaction.material_stock_lot[index] = newMaterialStockLot;

      await dispatch(
        actions.materialTransactionPut(materialTransaction?._id, {
          material_stock_lot: newMaterialTransaction?.material_stock_lot,
        }),
      );

      alert('แก้ไขสำเร็จ');
      dispatch(actions.materialTransactionGet(materialTransaction?._id));
    } catch (error) {
      alert(`ดำเนินการแก้ไขไม่สำเร็จ ${error?.message}`);
    }
  };

  return (
    <Card className="my-2">
      <CardContent>
        <form onSubmit={handleSubmit(handleUpdateMaterialLotAndTransaction)}>
          <div className="p-2 font-semibold font-display text-lg">
            ล็อตวันที่{' '}
            {dayjs(materialStockLot?.lotId?.recipt_date).format('D MMM BBBB')}
            {materialStockLot?.lotId?.lot_number && (
              <span> (หมายเลขล็อต {materialStockLot?.lotId?.lot_number}) </span>
            )}
          </div>
          <div className="p-2">
            <div className="flex flex-wrap">
              <div className="w-full md:w-1/4 py-2">
                <b>วัตถุดิบ</b>
              </div>
              <div className="w-full md:w-3/4 py-2">
                {materialStockLot?.lotId?.material?.type_code}{' '}
                {materialStockLot?.lotId?.material?.name}
              </div>
              <div className="w-full md:w-1/4 py-2">
                <b>คลังวัตถุดิบ</b>
              </div>
              <div className="w-full md:w-3/4 py-2">
                {materialStockLot?.lotId?.place?.name}{' '}
              </div>

              <div className="w-full md:w-1/4 py-2">
                <b>ปริมาณในคลัง</b>
              </div>
              <div className="w-full md:w-3/4 py-2">
                <div className="flex gap-2">
                  <div>
                    <MultiUnitProdOrMatBox
                      foundMaterial={materialStockLot?.lotId?.material}
                      quantity={materialStockLot?.lotId?.quantity}
                      noWrap
                    />
                  </div>
                  <div>คงเหลือในคลัง</div>
                  <div>
                    <MultiUnitProdOrMatBox
                      foundMaterial={materialStockLot?.lotId?.material}
                      quantity={materialStockLot?.lotId?.amount}
                      noWrap
                    />
                  </div>
                </div>
              </div>

              <div className="w-full md:w-1/4 py-2">
                <b>
                  {materialTransaction?.material_transaction_type?.direction ===
                  'add'
                    ? 'จำนวนที่เพิ่มเข้ามา'
                    : 'จำนวนที่เบิกออกไป'}{' '}
                  ในการจัดการครั้งนี้
                </b>
              </div>
              <div className="w-full md:w-3/4 py-2">
                <div className="flex gap-2">
                  {materialTransaction?.material_transaction_type?.name}
                  <div>
                    <MultiUnitProdOrMatBox
                      foundMaterial={materialStockLot?.lotId?.material}
                      quantity={materialStockLot?.quantity}
                      noWrap
                    />
                  </div>
                  <div>คงเหลือในคลัง ณ ตอนทำการ</div>
                  <div>
                    <MultiUnitProdOrMatBox
                      foundMaterial={materialStockLot?.lotId?.material}
                      quantity={materialStockLot?.amount}
                      noWrap
                    />
                  </div>
                </div>
              </div>

              <div className="w-full  py-2">
                <div className="flex items-center">
                  <hr className="md:w-1/3" />
                  <div className="mx-5">แก้ไขเป็น</div>
                  <hr className="md:w-1/3" />
                </div>
              </div>

              <div className="w-full md:w-1/4 py-2">
                <b>ปริมาณในคลัง</b>
              </div>
              <div className="w-full md:w-3/4 py-2">
                <div className="flex gap-2">
                  <div>
                    <MultiUnitProdOrMatBox
                      foundMaterial={materialStockLot?.lotId?.material}
                      quantity={watch('lotId.quantity')}
                      noWrap
                    />
                  </div>
                  <div>คงเหลือในคลัง</div>
                  <div
                    className={
                      watch('lotId.amount') > materialStockLot?.lotId?.amount
                        ? 'text-green-500 font-semibold'
                        : watch('lotId.amount') ===
                          materialStockLot?.lotId?.amount
                        ? 'text-black'
                        : 'text-red-500 font-semibold'
                    }
                  >
                    <MultiUnitProdOrMatBox
                      foundMaterial={materialStockLot?.lotId?.material}
                      quantity={watch('lotId.amount')}
                      noWrap
                    />
                  </div>
                </div>
              </div>

              <div className="w-full md:w-1/4 py-2">
                <b>
                  {materialTransaction?.material_transaction_type?.direction ===
                  'add'
                    ? 'จำนวนที่เพิ่มเข้ามา'
                    : 'จำนวนที่เบิกออกไป'}{' '}
                  ในการจัดการครั้งนี้
                </b>
              </div>
              <div className="w-full md:w-3/4 py-2">
                <div className="flex gap-2 items-center">
                  {materialTransaction?.material_transaction_type?.name}
                  <div>
                    <ProdOrMatQuantityInput
                      controllerName="quantity"
                      artificialControllerName="quantity_artificial"
                      selectedUnitControllerName="quantity_unit"
                      setValue={setValue}
                      watch={watch}
                      control={control}
                      selectedProduct={materialStockLot?.lotId?.material}
                      defaultValue={materialStockLot?.quantity}
                      onChangeFunction={() => {
                        const diff =
                          (parseFloat(watch('quantity')) -
                            materialStockLot?.quantity) *
                          (materialTransaction?.material_transaction_type
                            ?.direction === 'add'
                            ? 1
                            : -1);
                        // Edit Amount
                        setValue('amount', materialStockLot?.amount + diff);

                        const quantityDiff =
                          (materialStockLot?.lotId?.quantity -
                            materialStockLot?.quantity) *
                          (materialTransaction?.material_transaction_type
                            ?.direction === 'add'
                            ? 1
                            : -1);
                        const newQuantity =
                          parseFloat(watch('quantity')) + quantityDiff;
                        // Edit Quantity of Material Stocklot
                        setValue('lotId.quantity', newQuantity);

                        const amountDiff =
                          materialStockLot?.lotId?.amount -
                          materialStockLot?.amount *
                            (materialTransaction?.material_transaction_type
                              ?.direction === 'add'
                              ? 1
                              : -1);

                        const newAmount =
                          amountDiff + materialStockLot?.amount + diff;
                        setValue('lotId.amount', newAmount);
                      }}
                    />
                  </div>
                  <div>คงเหลือในคลัง ณ ตอนทำการ</div>
                  <div
                    className={
                      watch('amount') > materialStockLot?.amount
                        ? 'text-green-500 font-semibold'
                        : watch('amount') === materialStockLot?.amount
                        ? 'text-black'
                        : 'text-red-500 font-semibold'
                    }
                  >
                    <MultiUnitProdOrMatBox
                      foundMaterial={materialStockLot?.lotId?.material}
                      quantity={watch('amount')}
                      noWrap
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-end">
            <Button variant="contained" color="primary" type="submit">
              บันทึก
            </Button>
          </div>
        </form>
      </CardContent>
    </Card>
  );
}

export default function EditMaterialHistory({ title, subtitle }) {
  const dispatch = useDispatch();
  const materialTransaction = useSelector((state) => state.materialTransaction);
  const history = useHistory();
  const params = useParams();

  const queryDataFromServer = async () => {
    try {
      await dispatch(actions.materialTransactionGet(params.id));
    } catch (error) {
      alert(`ไม่สามารถดึงข้อมูลการดำเนินการกับวัตถุดิบได้ ${error.message}}`);
    }
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [params]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const renderInformation = () => (
    <Card>
      <CardContent>
        <div className="flex flex-wrap divide-y">
          <div className="w-full   py-4 font-display font-semibold">
            รายละเอียด
          </div>
          <div className="w-full  px-2 md:w-1/2 py-4 font-display font-semibold">
            เลขที่การดำเนินการ
          </div>
          <div className="w-full  md:w-1/2 py-4">
            {materialTransaction?.running_number}
          </div>
          <div className="w-full  px-2 md:w-1/2 py-4 font-display font-semibold">
            วันที่ดำเนินการ
          </div>
          <div className="w-full md:w-1/2 py-4">
            {dayjs(materialTransaction?.createdAt).format('D MMMM BBBB')}
          </div>
          <div className="w-1/2 py-4 px-2 font-semibold font-display ">
            ประเภท
          </div>
          <div
            className={`w-1/2 py-4 text-red-600 ${
              materialTransaction?.material_transaction_type?.direction ===
              'add'
                ? 'text-green-500'
                : 'text-red-500'
            }`}
          >
            {materialTransaction?.material_transaction_type?.name}
          </div>
        </div>
      </CardContent>
    </Card>
  );

  if (materialTransaction.isLoading) {
    return <Loading />;
  }

  if (!materialTransaction.isLoading && materialTransaction.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <BackButton />
        <div className="my-2">
          {renderInformation()}
          <div className="font-display font-semibold text-lg my-4">
            รายการล็อตวัตถุดิบ
          </div>
          {_.map(
            materialTransaction?.material_stock_lot,
            (materialStockLot, index) => (
              <RenderMaterialStockLotEdit
                materialStockLot={materialStockLot}
                materialTransaction={materialTransaction}
                key={index}
                index={index}
              />
            ),
          )}
        </div>
      </div>
    );
  }
  return <Error />;
}

EditMaterialHistory.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

EditMaterialHistory.defaultProps = {
  title: '',
  subtitle: '',
};

RenderMaterialStockLotEdit.propTypes = {
  materialStockLot: PropTypes.object,
  materialTransaction: PropTypes.object,
  index: PropTypes.number,
};
