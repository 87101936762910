/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { gql } from 'graphql-request';
import { Link, useParams } from 'react-router-dom';
import { Card, CardContent, Button } from '@mui/material';
import dayjs from 'dayjs';

import {
  ViewTitle,
  Loading,
  BackButton,
  Error,
  PayrollTable,
  DesktopDatepicker,
  MonthyUpAndDownButton,
  OneEmployeePayrollReport,
} from '../../components';
import * as actions from '../../redux/actions';
import graphqlClient from '../../configs/graphqlClient';
import { app as appConfig } from '../../configs';
import { currencyFormatter } from '../../utils/functions';
import { PAYROLL_TYPE } from '../../utils/constants';

/**
 * @function DetailEmployeePayroll
 * @description Display a collections or a list of DetailEmployeePayroll from database
 */

export default function DetailEmployeePayroll({ title, subtitle }) {
  const dispatch = useDispatch();
  const employee = useSelector((state) => state.employee);
  const payroll = useSelector((state) => state.payroll);
  const information = useSelector((state) => state.information);

  const {
    setting: { pams: pamsSetting },
  } = information;
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState();
  const [selectedStartDate, setSelectedStartDate] = useState(
    dayjs().date(pamsSetting?.period_date).subtract(1, 'month').toDate(),
  );

  const [selectedEndDate, setSelectedEndDate] = useState(
    dayjs().date(pamsSetting?.period_date).toDate(),
  );

  const params = useParams();

  const query = gql`
    query FindDetailEmployeePayroll(
      $employeeInput: EmployeeInput
      $payrollInput: PayrollInput
    ) {
      findOneEmployee(input: $employeeInput) {
        firstname
        lastname
        department {
          name
        }
        earnings {
          amount
          is_pay_monthy
        }
        other_departments {
          name
        }
      }
      findPayrolls(input: $payrollInput) {
        totalAmount
        rows {
          _id
          payroll_type
          date
          overtimes {
            start_time
            end_time
            cost
          }
          daily_attribute {
            start_time
            end_time
          }
          total_overtime
          main_cost
          total_cost
        }
        currPage
        lastPage
        total
      }
    }
  `;

  const queryDataFromServer = async () => {
    const queryResult = await graphqlClient.request(query, {
      employeeInput: {
        id: params.id,
      },
      payrollInput: {
        employee: params.id,
        page,
        size,
        date_start: dayjs(selectedStartDate).format('YYYY-MM-DD'),
        date_end: dayjs(selectedEndDate).format('YYYY-MM-DD'),
      },
    });
    const employeeData = queryResult?.findOneEmployee;
    const payrollData = queryResult?.findPayrolls;
    dispatch(actions.employeeStateOneSet(employeeData));
    dispatch(actions.payrollStateSet(payrollData));
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [page, size, selectedEndDate, selectedStartDate]);

  useEffect(() => {
    setTotal(payroll?.total);
    return () => {};
  }, []);

  const handlePrint = async () => {
    try {
      const queryResult = await graphqlClient.request(query, {
        payrollInput: {
          employee: params.id,
          page: 1,
          size: appConfig.maxFetchSize,
          date_start: dayjs(selectedStartDate).format('YYYY-MM-DD'),
          date_end: dayjs(selectedEndDate).format('YYYY-MM-DD'),
        },
      });
      const payrollData = queryResult?.findPayrolls;

      OneEmployeePayrollReport(payrollData?.rows, employee, information, {
        startDate: selectedStartDate,
        endDate: selectedEndDate,
        payroll_type: employee?.earnings?.is_pay_monthy
          ? PAYROLL_TYPE?.MONTHY?.status_code
          : PAYROLL_TYPE.DAILY.status_code,
      });
    } catch (err) {
      alert(`ไม่สามารถบันทึกเป็น PDF ได้ ${err?.message}`);
    }
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (employee.isLoading) {
    return <Loading />;
  }
  if (!employee.isLoading && employee.isCompleted) {
    return (
      <div>
        <div className="flex justify-between">
          {renderTitle()}
          <div className="self-center flex gap-2">
            <Link to={`/pams/employee/create-payroll/${params.id}`}>
              <Button variant="contained">สร้าง</Button>
            </Link>
            <div>
              <Button variant="contained" color="teal" onClick={handlePrint}>
                พิมพ์
              </Button>
            </div>
          </div>
        </div>
        <div className="flex justify-between">
          <BackButton />
        </div>
        <div className="my-2">
          <Card>
            <CardContent>
              <div className="flex w-full flex-wrap ">
                <div className="w-1/2">
                  <div className="">
                    <div className="text-xl font-display ">
                      {employee?.firstname} {employee?.lastname}{' '}
                    </div>
                  </div>
                  <div className="flex gap-2">
                    <div className="font-semibold font-display">แผนก</div>
                    <div>{employee?.department?.name}</div>
                  </div>
                </div>
                <div className="w-1/2 flex flex-wrap ">
                  <div className="w-1/2 px-2">
                    <div className="font-semibold font-display">
                      ประเภทการจ่ายเงิน
                    </div>
                    <div className="text-2xl">
                      {employee?.earnings?.is_pay_monthy
                        ? 'รายเดือน'
                        : 'รายวัน'}{' '}
                      {currencyFormatter.format(employee?.earnings?.amount)} บาท
                    </div>
                  </div>{' '}
                  <div className="w-1/2 px-2">
                    <div className="font-semibold font-display">รวมเงิน</div>
                    <div className="text-2xl">
                      {currencyFormatter.format(payroll?.totalAmount)} บาท{' '}
                    </div>
                  </div>
                </div>
              </div>
              <div className="my-4">
                <div className="flex gap-2 flex-wrap">
                  <div>
                    <DesktopDatepicker
                      value={selectedStartDate}
                      setValue={setSelectedStartDate}
                      label="ตั้งแต่"
                      inputFormat="DD MMM BBBB"
                    />
                  </div>
                  <div>
                    <DesktopDatepicker
                      value={selectedEndDate}
                      setValue={setSelectedEndDate}
                      label="จนถึง"
                      inputFormat="DD MMM BBBB"
                    />
                  </div>
                </div>
                <div>
                  <MonthyUpAndDownButton
                    cursorDate={pamsSetting?.period_date}
                    selectedStartDate={selectedStartDate}
                    setSelectedEndDate={setSelectedEndDate}
                    setSelectedStartDate={setSelectedStartDate}
                  />
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
        <div className="my-2">
          <PayrollTable
            handleChangePage={(event, currPage) => {
              setPage(currPage + 1);
            }}
            handleChangeRowsPerPage={(event) => {
              setSize(event.target.value);
              setPage(1);
            }}
            isDailyPayroll={!employee?.earnings?.is_pay_monthy}
            payroll={payroll}
            page={page}
            size={size}
            total={total}
            showActionButtons={true}
            showEmployee={false}
          />
        </div>
      </div>
    );
  }
  return <Error />;
}

DetailEmployeePayroll.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

DetailEmployeePayroll.defaultProps = {
  title: '',
  subtitle: '',
};
