import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { WORK_SHIFT } from '../../../utils/constants';
import { currencyFormatter } from '../../../utils/functions';

const ProcessDetailBox = ({ selectedProcess }) => {
  const information = useSelector((state) => state.information);
  const mmsSetting = information?.setting?.mms;

  const customerRenderBox = (customerInfo) => (
    <div className="flex p-1 shadow-md my-1 rounded-md">
      <i className="w-1/5 text-center self-center fas fa-user-tie text-lg"></i>
      <div className="w-4/5">
        <div className="text-xs">{customerInfo?.type_code}</div>
        <div>{customerInfo?.name}</div>
      </div>
    </div>
  );

  return (
    <div>
      <div className="w-full mb-2 ">
        <div className="font-semibold  font-display">รายละเอียด</div>
        <p className="font-system">{selectedProcess?.remark || '-'}</p>
      </div>
      {selectedProcess?.customer && (
        <div className=" ">
          <div className="font-semibold font-display">ลูกค้า</div>{' '}
          <ul className="list-inside list-disc my-2 ml-2">
            <div>{customerRenderBox(selectedProcess?.customer)}</div>
            {_.map(
              selectedProcess?.other_customers,
              (eachOtherCustomer, index) => (
                <div key={index}>{customerRenderBox(eachOtherCustomer)}</div>
              ),
            )}
          </ul>
        </div>
      )}

      <div className="grid md:grid-cols-4 lg:grid-cols-12 gap-2 pt-2 ">
        {selectedProcess?.machine && (
          <div className=" px-1 col-span-6">
            <div className="font-semibold font-display">เครื่องจักร</div>{' '}
            <div className="text-2xl ">
              {selectedProcess?.machine?.name || ''}
            </div>
          </div>
        )}
        {selectedProcess?.work_shift && (
          <div className="col-span-3">
            <div className="font-semibold font-display">ช่วงของการทำงาน</div>{' '}
            <div className="text-2xl ">
              {WORK_SHIFT[selectedProcess?.work_shift]?.description}
            </div>
          </div>
        )}
        {mmsSetting?.manufacturingOrder?.showPrice && (
          <div className="col-span-3 px-1">
            <div className="font-semibold  font-display">ราคาต่อชิ้น</div>
            <p>
              {currencyFormatter.format(selectedProcess?.price_per_unit || 0)}{' '}
              บาท
            </p>
          </div>
        )}
        {mmsSetting?.manufacturingOrder?.showPrice && (
          <div className="col-span-3">
            <div className="font-semibold  font-display">ราคารวม</div>
            <p className="text-xl">
              {currencyFormatter.format(
                (selectedProcess?.price_per_unit || 0) *
                  selectedProcess.quantity,
              )}
              บาท
            </p>
          </div>
        )}
        {mmsSetting?.showManufacturingDate && (
          <div className="col-span-6">
            <div className="font-semibold  font-display">วันผลิต</div>
            <p className="">
              {selectedProcess?.manufacturing_date &&
                dayjs(selectedProcess?.manufacturing_date).format('D MMM BBBB')}
            </p>
            <p className="text-xs">
              {' '}
              {selectedProcess?.manufacturing_date &&
                dayjs(selectedProcess?.manufacturing_date).format('DD/MM/YYYY')}
            </p>
          </div>
        )}
        {mmsSetting?.showExpireDate && (
          <div className="col-span-6">
            <div className="font-semibold  font-display">วันหมดอายุ</div>
            <p className="">
              {selectedProcess?.expired_date &&
                dayjs(selectedProcess?.expired_date).format('D MMM BBBB')}
            </p>{' '}
            <p className="text-xs">
              {selectedProcess?.expired_date &&
                dayjs(selectedProcess?.expired_date).format('DD/MM/YYYY')}
            </p>
          </div>
        )}
        <ErrorBoundary fallback={<div></div>}>
          {_.map(
            selectedProcess?.additional_attribute,
            (eachAttribute, index) => (
              <div className="col-span-6">
                <div className="font-semibold  font-display">{index}</div>
                <p className="">{eachAttribute?.value || '-'}</p>
              </div>
            ),
          )}
        </ErrorBoundary>
      </div>
    </div>
  );
};

ProcessDetailBox.propTypes = { selectedProcess: PropTypes.object };

export default ProcessDetailBox;
