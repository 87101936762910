import ArrangeProcessTemplateMeasurement from './ArrangeProcessTemplateMeasurement';
import BaseProjects from './BaseProjects';
import BOMTemplates from './BomTemplates';
import CreateBaseProject from './CreateBaseProject';
import CreateBOMTemplate from './CreateBomTemplate';
import CreateProcessTemplate from './CreateProcessTemplate';
import DetailBomTemplate from './DetailBomTemplate';
import DetailProcessTemplate from './DetailProcessTemplate';
import EditBaseProject from './EditBaseProject';
import EditBOMTemplate from './EditBomTemplate';
import EditProcessTemplate from './EditProcessTemplate';
import EditProcessTemplateMeasurement from './EditProcessTemplateMeasurement';
import EditProcessTemplateOld from './EditProcessTemplateOld';
import EditProcessTemplateResultingFunction from './EditProcessTemplateResultingFunction';
import EditProcessTemplateTableView from './EditProcessTemplateTableView';
import EditProductBom from './EditProductBom';
import ProcessTemplate from './ProcessTemplate';
import CreateMOTemplate from './SettingCreateMOTemplate';
import DetailMOTemplate from './SettingDetailMOTemplate';
import DetailProductProcess from './SettingDetailProductProcess';
import EditMeasurementType from './SettingEditMeasurementForm';
import EditMOTemplate from './SettingEditMOTemplate';
import EditProductProcess from './SettingEditProductProcess';
import SettingManufacturingOrder from './SettingManufacturingOrder';
import MeasurementType from './SettingMeasurementType';
import SettingMMS from './SettingMMS';
import MOTemplates from './SettingMOTemplates';
import SettingProcess from './SettingProcess';
import ProductBom from './SettingProductBOM';
import ProductProcess from './SettingProductProcess';
import ViewProductBom from './SettingViewProductBOM';
import SystemAudit from './SystemAudit';

/**
 * @namespace MMS/Setting
 * @description การดำเนินการตั้งค่าทักอย่างของ MMS เช่นตั้งค่า Process Template
 * ตั้งค่าส่วนผสม ตั้งค่าโมดูลการผลิต อยู่ในโฟลเดอร์ /views/mms/Setting
 */

export {
  ArrangeProcessTemplateMeasurement,
  BaseProjects,
  BOMTemplates,
  CreateBaseProject,
  CreateBOMTemplate,
  CreateMOTemplate,
  CreateProcessTemplate,
  DetailBomTemplate,
  DetailMOTemplate,
  DetailProcessTemplate,
  DetailProductProcess,
  EditBaseProject,
  EditBOMTemplate,
  EditMeasurementType,
  EditMOTemplate,
  EditProcessTemplate,
  EditProcessTemplateMeasurement,
  EditProcessTemplateOld,
  EditProcessTemplateResultingFunction,
  EditProcessTemplateTableView,
  EditProductBom,
  EditProductProcess,
  MeasurementType,
  MOTemplates,
  ProcessTemplate,
  ProductBom,
  ProductProcess,
  SettingManufacturingOrder,
  SettingMMS,
  SettingProcess,
  SystemAudit,
  ViewProductBom,
};

export default {
  BaseProjects,
  BOMTemplates,
  CreateBOMTemplate,
  CreateMOTemplate,
  CreateProcessTemplate,
  DetailMOTemplate,
  DetailProcessTemplate,
  DetailProductProcess,
  EditBOMTemplate,
  EditMeasurementType,
  EditProcessTemplate,
  EditProductBom,
  EditProductProcess,
  MeasurementType,
  SettingMMS,
  MOTemplates,
  ProcessTemplate,
  ProductBom,
  DetailBomTemplate,
  ViewProductBom,
  ProductProcess,
  EditMOTemplate,
  EditBaseProject,
  SettingManufacturingOrder,
  SettingProcess,
  CreateBaseProject,
  SystemAudit,
  EditProcessTemplateOld,
  EditProcessTemplateTableView,
  EditProcessTemplateMeasurement,
  EditProcessTemplateResultingFunction,
  ArrangeProcessTemplateMeasurement,
};
