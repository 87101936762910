import React from 'react';
import { Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import {
  Autocomplete,
  createFilterOptions,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { useQuery } from '@/utils/functions';

import { DesktopDatepicker } from '../../DatePicker';

export function MaterialStockForm({
  errors,
  control,
  place,
  materialTransactionType,
  customer,
  department,
  me,
  type,
  setType,
  setFilterPlace,
  setSelectMaterial,
  setSelectMaterialLot,
  watch,
  withdrawDestinations,
}) {
  const { id } = useParams();
  const information = useSelector((state) => state.information);
  const query = useQuery();
  const filter = createFilterOptions();
  const {
    setting: { ims: imsSetting },
  } = information;

  const selectedTxnType = _.find(
    materialTransactionType,
    (each) => each?._id === query.get('txnType'),
  );

  const filteredPlace = _.filter(
    place,
    (eachPlace) =>
      eachPlace?.allow_all_department ||
      _.includes(
        _.map(eachPlace?.allow_departments, (each) => each?._id),
        me?.department?._id,
      ),
  );

  const checkType = (data) => {
    const foundType = _.find(materialTransactionType, { _id: data });
    console.log('Found Type', foundType);
    setType(foundType);
    setSelectMaterial([]);
    setSelectMaterialLot([]);
  };

  const materialOptions = () => {
    if (type?.direction === 'add') {
      return (
        <div className="w-full flex flex-row flex-wrap">
          <div className="w-full px-1 py-2">
            <Controller
              name={'bill_number'}
              control={control}
              defaultValue={''}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="เลขที่บิล (ถ้ามี)"
                  fullWidth
                  size={'small'}
                  helperText={errors.bill_number && 'กรุณาใส่ข้อมูล'}
                />
              )}
            />
          </div>
          <div className="w-full px-1 py-2">
            {!watch('many_supply') && (
              <Controller
                name={'supplier'}
                control={control}
                defaultValue={customer?.[0]}
                render={({ field }) => (
                  <Autocomplete
                    size={'small'}
                    disableClearable
                    {...field}
                    onChange={(e, newValue) => {
                      field.onChange(newValue);
                    }}
                    options={customer}
                    getOptionLabel={(option) =>
                      `${option?.type_code || ''} | ${option?.name || ''}`
                    }
                    required
                    renderInput={(params) => (
                      <TextField {...params} label="คู่ค้า" />
                    )}
                  />
                )}
              />
            )}
            <Controller
              name={'many_supply'}
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  control={<Switch {...field} defaultChecked={field.value} />}
                  label="หลายคู่ค้า"
                />
              )}
            />
          </div>
          {imsSetting?.display?.source && (
            <div className="w-full px-1 py-2">
              <Controller
                name={'source'}
                control={control}
                defaultValue={''}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="แหล่งวัตถุดิบ"
                    fullWidth
                    size={'small'}
                    helperText={errors.source && 'กรุณาใส่ข้อมูล'}
                  />
                )}
              />
            </div>
          )}
          {imsSetting?.display?.purchase_date && (
            <div className="w-full lg:w-1/2 px-1 mb-2">
              <label className="text-sm">วันที่ซื้อ*</label>
              <Controller
                name={'purchase_date'}
                control={control}
                defaultValue={new Date()}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    type="date"
                    fullWidth
                    size={'small'}
                    required
                    helperText={errors.purchase_date && 'กรุณาใส่ข้อมูล'}
                  />
                )}
              />
            </div>
          )}

          <div className="w-full  px-1 mb-2">
            <Controller
              name={'receipt_date'}
              control={control}
              defaultValue={new Date()}
              rules={{ required: true }}
              render={({ field }) => (
                <DesktopDatepicker
                  {...field}
                  label="วันรับวัตถุดิบ"
                  fullWidth
                  size="small"
                />
              )}
            />
          </div>

          <div className="w-full px-1 py-2">
            <Controller
              name={'recipient_name'}
              control={control}
              rules={{ required: true }}
              defaultValue={me ? `${me?.firstname} ${me?.lastname}` : ''}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="ชื่อผู้รับวัตถุดิบ"
                  fullWidth
                  size={'small'}
                  required
                  helperText={errors.recipient_name && 'กรุณาใส่ข้อมูล'}
                />
              )}
            />
          </div>
          <div className="w-full px-1 py-2">
            <Controller
              name={'order_department'}
              control={control}
              defaultValue={me ? me?.department?.id : ''}
              rules={{ required: true }}
              render={({ field }) => (
                <FormControl sx={{ minWidth: 120 }} fullWidth={true} required>
                  <InputLabel id="type" size={'small'}>
                    แผนกผู้สั่งซื้อ
                  </InputLabel>
                  <Select {...field} label="คู่ค้า" size={'small'} fullWidth>
                    {_.size(department) ? (
                      _.map(department, (_department) => (
                        <MenuItem key={_department.id} value={_department.id}>
                          {_department.name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem>
                        <MenuItem disabled value="">
                          <em>ไม่มีข้อมูล</em>
                        </MenuItem>
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
              )}
            />
          </div>
          <div className="w-full lg:w-full px-1 py-2">
            <Controller
              name={'remark'}
              control={control}
              defaultValue={''}
              rules={{ required: false }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="หมายเหตุ"
                  fullWidth
                  size={'small'}
                  multiline={true}
                  rows={3}
                  helperText={errors.remark && 'กรุณาใส่ข้อมูล'}
                />
              )}
            />
          </div>
        </div>
      );
    }
    if (type?.direction === 'desc') {
      return (
        <div className="w-full flex flex-row flex-wrap my-2">
          <div className="w-full px-1 mb-2">
            <Controller
              name={'issue_date'}
              control={control}
              defaultValue={new Date()}
              rules={{ required: true }}
              render={({ field }) => (
                <DesktopDatepicker
                  {...field}
                  label="วันที่เบิก"
                  fullWidth
                  size="small"
                />
              )}
            />
          </div>
          {imsSetting?.allowWithdrawDestination &&
            _.isArray(withdrawDestinations) && (
              <div className="w-full px-1 mb-2">
                <Controller
                  name={'withdraw_destination'}
                  control={control}
                  defaultValue={null}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      onChange={(event, newValue) => {
                        if (typeof newValue === 'string') {
                          field.onChange({
                            name: newValue,
                            createNew: true,
                          });
                        } else if (newValue && newValue.inputValue) {
                          // Create a new value from the user input
                          field.onChange({
                            name: newValue.inputValue,
                            createNew: true,
                          });
                        } else {
                          field.onChange(newValue);
                        }
                      }}
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        const { inputValue } = params;
                        // Suggest the creation of a new value
                        const isExisting = options.some(
                          (option) => inputValue === option.name,
                        );
                        if (inputValue !== '' && !isExisting) {
                          filtered.push({
                            inputValue,
                            name: `เพิ่ม "${inputValue}"`,
                          });
                        }

                        return filtered;
                      }}
                      selectOnFocus
                      clearOnBlur
                      handleHomeEndKeys
                      options={withdrawDestinations}
                      getOptionLabel={(option) => {
                        // Value selected with enter, right from the input
                        if (typeof option === 'string') {
                          return option;
                        }
                        // Add "xxx" option created dynamically
                        if (option.inputValue) {
                          return option.inputValue;
                        }
                        // Regular option
                        return option.name;
                      }}
                      renderOption={(props, option) => (
                        <li {...props}>{option.name || ''}</li>
                      )}
                      sx={{ width: 300 }}
                      freeSolo
                      fullWidth
                      size="small"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="เป้าหมายของการเบิก"
                          fullWidth
                        />
                      )}
                    />
                  )}
                />
              </div>
            )}
          <div className="w-full px-1 py-2">
            <Controller
              name={'request_name'}
              control={control}
              rules={{ required: true }}
              defaultValue={me ? `${me?.firstname} ${me?.lastname}` : ''}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="ชื่อพนักงานผู้เบิก"
                  fullWidth
                  size={'small'}
                  required
                  helperText={errors.recipient_name && 'กรุณาใส่ข้อมูล'}
                />
              )}
            />
          </div>
          <div className="w-full px-1 py-2">
            <Controller
              name={'request_department'}
              control={control}
              defaultValue={me ? me?.department?.id : ''}
              rules={{ required: true }}
              render={({ field }) => (
                <FormControl sx={{ minWidth: 120 }} fullWidth={true} required>
                  <InputLabel id="type" size={'small'}>
                    แผนกที่ขอเบิก
                  </InputLabel>
                  <Select {...field} label="คู่ค้า" size={'small'} fullWidth>
                    {_.size(department) ? (
                      _.map(department, (_department) => (
                        <MenuItem key={_department.id} value={_department.id}>
                          {_department.name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem>
                        <MenuItem disabled value="">
                          <em>ไม่มีข้อมูล</em>
                        </MenuItem>
                      </MenuItem>
                    )}
                  </Select>
                </FormControl>
              )}
            />
          </div>
          <div className="w-full lg:w-full px-1 py-2">
            <Controller
              name={'remark'}
              control={control}
              defaultValue={''}
              rules={{ required: false }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="หมายเหตุ"
                  fullWidth
                  size={'small'}
                  multiline={true}
                  rows={3}
                  helperText={errors.remark && 'กรุณาใส่ข้อมูล'}
                />
              )}
            />
          </div>
        </div>
      );
    }
  };

  return (
    <div className="flex flex-row flex-wrap">
      <div className="w-full px-1 py-2">
        {selectedTxnType ? (
          <div>
            <div
              className={`text-2xl font-bold ${
                selectedTxnType?.direction === 'add'
                  ? 'text-green-500'
                  : 'text-red-500'
              }`}
            >
              {selectedTxnType?.name}
            </div>
          </div>
        ) : (
          <Controller
            name={'material_transaction_type'}
            control={control}
            rules={{ required: true }}
            defaultValue={materialTransactionType?.[0]?._id}
            render={({ field }) => (
              <FormControl sx={{ minWidth: 120 }} fullWidth={true} required>
                <InputLabel id="type" size={'small'}>
                  ประเภทการดำเนินการ
                </InputLabel>
                <Select
                  {...field}
                  label="ประเภทการดำเนินการ"
                  size={'small'}
                  fullWidth
                  onChange={(e) => {
                    field.onChange(e.target.value);
                    checkType(e.target.value);
                  }}
                >
                  {_.size(materialTransactionType) ? (
                    _.map(
                      materialTransactionType,
                      (_materialTransactionType) => (
                        <MenuItem
                          key={_materialTransactionType.id}
                          value={_materialTransactionType.id}
                        >
                          {_materialTransactionType.name}
                        </MenuItem>
                      ),
                    )
                  ) : (
                    <MenuItem>
                      <MenuItem disabled value="">
                        <em>ไม่มีข้อมูล</em>
                      </MenuItem>
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            )}
          />
        )}
      </div>
      <div className="w-full lg:w-1/2">
        <div className="w-full px-1 py-2">
          {!_.isEmpty(filteredPlace) && (
            <Controller
              name={'place'}
              control={control}
              defaultValue={
                _.find(filteredPlace, (eachPlace) => eachPlace?._id === id) ||
                filteredPlace?.[0]
              }
              rules={{ required: true }}
              render={({ field }) => (
                <Autocomplete
                  {...field}
                  disablePortal
                  options={filteredPlace}
                  size="small"
                  placeholder="เลือกคลัง"
                  onChange={(e, newValue) => {
                    setFilterPlace(newValue);
                    field.onChange(newValue);
                  }}
                  className="z-40"
                  // prettier-ignore
                  getOptionLabel={(option) => `${option?.name || ''}`
}
                  renderInput={(params) => (
                    <TextField label="เลือกคลัง" {...params} required focused />
                  )}
                />
              )}
            />
          )}
        </div>
      </div>
      <div className="w-full lg:w-1/2">{materialOptions()}</div>
    </div>
  );
}

MaterialStockForm.propTypes = {
  errors: PropTypes.shape({
    material_transaction_type: PropTypes.object,
    bill_number: PropTypes.object,
    supplier: PropTypes.object,
    source: PropTypes.object,
    place: PropTypes.object,
    purchase_date: PropTypes.object,
    receipt_date: PropTypes.object,
    issue_date: PropTypes.object,
    remark: PropTypes.object,
    recipient_name: PropTypes.object,
    order_department: PropTypes.object,
    request_department: PropTypes.object,
    request_name: PropTypes.object,
  }),
  materialTransactionType: PropTypes.object,
  place: PropTypes.object,
  customer: PropTypes.object,
  department: PropTypes.object,
  me: PropTypes.object,
  type: PropTypes.object,
  setType: PropTypes.func,
  setFilterPlace: PropTypes.func,
  setSelectMaterial: PropTypes.func,
  setSelectMaterialLot: PropTypes.func,
  Controller: PropTypes.func,
  control: PropTypes.object,
  watch: PropTypes.func,
  withdrawDestinations: PropTypes.arrayOf(PropTypes.object),
};

MaterialStockForm.defaultProps = {
  materialTransactionType: null,
  place: null,
  customer: null,
  department: null,
  me: null,
  type: null,
  filterPlace: null,
};

export default MaterialStockForm;
