import React from 'react';
import { Alert, LinearProgress } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { MATERIAL_COMING_METHOD } from '../../../utils/constants';
import { converseMaterialOrProductUnit, MMS } from '../../../utils/functions';
import FlowingChart from '../../Chart/FlowingChart';

const { findMergeIncomingResult } = MMS;

function MaterialMergingDisplay({ currentProcess, watchedMaterial }) {
  const mergedIncomingAndResult = findMergeIncomingResult(
    currentProcess,
    watchedMaterial,
  );

  const currentProcessIndex = currentProcess?.current?.index;

  const nextProcess = _.find(
    currentProcess?.steps,
    (each) => each?.index === currentProcessIndex + 1,
  );

  const nodes = _.map(mergedIncomingAndResult, (each, index) => ({
    id: index.toString(),
    value: {
      title:
        `${each?.material?.material?.name || ''} x ${each?.fraction || '1'}` ||
        '-',
      items: [
        {
          text: 'ปริมาณ',
          value: `${each?.material?.end || '-'} ${
            each?.material?.material?.use_unit_conversion
              ? `${
                  each?.material?.material?.unit_input?.short_sign
                } (${parseFloat(
                  converseMaterialOrProductUnit(
                    each?.material?.material,
                    each?.material?.end,
                  ).toFixed(2),
                )} ${each?.material?.material?.unit_output?.short_sign})`
              : `${each?.material?.material?.unit}`
          }`,
        },
      ],
    },
  }));
  if (
    nextProcess?.material_incoming_method !==
    MATERIAL_COMING_METHOD.MERGING.status_code
  ) {
    return <div></div>;
  }
  return (
    <div>
      <div className="my-2">มีการรวมกันของวัตถุดิบ</div>
      <Alert severity="info" className="my-2">
        อัตราส่วนการผสม เป็นอัตราที่อ้างอิงจากหน่วย <b>หลัก</b>{' '}
        ของวัตถุดิบแต่ละชิ้น หากมองว่ามีการคำนวณผิดพลาด
        กรุณาตรวจเช็คในระบบคลังวัตถุดิบอีกครั้ง
      </Alert>
      <div>
        {!_.isEmpty(nodes) ? (
          <FlowingChart nodes={nodes} />
        ) : (
          <LinearProgress />
        )}
      </div>
    </div>
  );
}

export default MaterialMergingDisplay;

MaterialMergingDisplay.propTypes = {
  currentProcess: PropTypes.object,
  watchedMaterial: PropTypes.arrayOf(PropTypes.object),
};
