import MaterialWithOrWithoutLotQuantityInput from './MaterialWithOrWithoutLotQuantityInput';
import OnCreateMOMaterialQuantityInput from './OnCreateMOMaterialQuantityInput';
import ProdOrMatQuantityInput from './ProdOrMatQuantityInput';

export {
  MaterialWithOrWithoutLotQuantityInput,
  OnCreateMOMaterialQuantityInput,
  ProdOrMatQuantityInput,
};

export default {
  MaterialWithOrWithoutLotQuantityInput,
  OnCreateMOMaterialQuantityInput,
  ProdOrMatQuantityInput,
};
