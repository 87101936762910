import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Card, CardContent } from '@mui/material';
import { gql } from 'graphql-request';
import PropTypes from 'prop-types';

import {
  BackButton,
  DashboardElementRenderingComponent,
  Error,
  Loading,
  ViewTitle,
} from '@/components';
import graphqlClient from '@/configs/graphqlClient';
import * as actions from '@/redux/actions';

/**
 * @function ViewDashbaordElement
 * @description Display a collections or a list of ViewDashbaordElement from database
 */

export default function ViewDashbaordElement({ title, subtitle }) {
  const dispatch = useDispatch();
  const dashboardElement = useSelector((state) => state.dashboardElement);
  const params = useParams();

  const query = gql`
    query FindSettingEditProductBom(
      $dashboardElementInput: DashboardElementInput
    ) {
      findOneDashboardElement(input: $dashboardElementInput) {
        _id
        name
        dashboard_type
        is_mo_level
        elements {
          name
          index
        }
      }
    }
  `;

  const queryDataFromServer = async () => {
    const queryResult = await graphqlClient.request(query, {
      dashboardElementInput: { id: params.id },
    });
    const dashboardElementData = queryResult?.findOneDashboardElement;
    dispatch(actions.dashboardElementStateOneSet(dashboardElementData));
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [params]);

  const renderTitle = () => (
    <ViewTitle
      title={`รายละเอียด${dashboardElement?.name}` || title}
      subtitle={subtitle}
    />
  );

  if (dashboardElement.isLoading) {
    return <Loading />;
  }

  if (!dashboardElement.isLoading && dashboardElement.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <BackButton />
        <div className="my-2">
          <Card>
            <CardContent>
              <div className="font-semibold font-display">
                ตัวอย่างแดชบอร์ที่สร้าง
              </div>
              <DashboardElementRenderingComponent
                showAsExample
                type={dashboardElement?.dashboard_type}
                dashboardElement={dashboardElement}
              />
            </CardContent>
          </Card>
        </div>
      </div>
    );
  }
  return <Error />;
}

ViewDashbaordElement.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

ViewDashbaordElement.defaultProps = {
  title: '',
  subtitle: '',
};
