/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import _ from 'lodash';
import ImageUpload from '../../components/ImageUpload/ImageUpload';
import { BackButton } from '../../components/Button';
import { ViewTitle } from '../../components/ViewTitle';
import * as actions from '../../redux/actions';
import {} from '../../components/Forms';
import Loading from '../../components/Loading';
import { Error } from '../../components/Error';

function EditPersonalProfile({ title, subtitle }) {
  const dispatch = useDispatch();
  const me = useSelector((state) => state.me);
  const {
    formState: { errors },
    handleSubmit,
    control,
  } = useForm();

  const [selectedImage, setSelectedImage] = useState([]);

  useEffect(() => {
    dispatch(actions.meGet());

    return () => {};
  }, []);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const onSubmit = async (data) => {
    try {
      if (!_.isEmpty(selectedImage)) {
        data.image = {
          image: selectedImage[0]?.data_url,
          imageType: 'profile',
          alt: '',
        };
      }

      await dispatch(actions.employeePut(me.userData?._id, data));
      setSelectedImage([]);
      alert('สำเร็จ');
      dispatch(actions.meGet());
    } catch (error) {
      console.log(error);
    }
  };

  const renderForm = () => {
    const {
      firstname = ' ',
      lastname = ' ',
      phone_number = '',
      department: { name: department_name = '' },
      image = '',
    } = me.userData;
    return (
      <div className="w-full ">
        <Card className="p-4 ">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-row flex-wrap justify-center ">
              <div className="flex justify-center px-1 py-2 w-full">
                <img src={image.url} className="w-60 rounded-lg" />
              </div>
              <div className="flex justify-center px-1 py-2 w-full">
                <ImageUpload
                  images={selectedImage}
                  maxNumber={1}
                  previewSize={400}
                  setImages={setSelectedImage}
                />
              </div>
              <div className="px-1 py-2 w-1/2">
                <Controller
                  name={'firstname'}
                  control={control}
                  defaultValue={firstname || ''}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="ชื่อ"
                      fullWidth
                      size={'small'}
                    />
                  )}
                />
              </div>
              <div className="px-1 py-2 w-1/2 ">
                <Controller
                  name={'lastname'}
                  control={control}
                  defaultValue={lastname || ''}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="นามสกุล"
                      fullWidth
                      size={'small'}
                    />
                  )}
                />
              </div>
              <div className="px-1 py-2 w-1/2">
                <Controller
                  name={'phone_number'}
                  control={control}
                  defaultValue={phone_number || ''}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="เบอร์โทร"
                      fullWidth
                      size={'small'}
                    />
                  )}
                />
              </div>
              <div className="px-1 py-2 w-1/2">
                <Controller
                  name={'departments'}
                  control={control}
                  defaultValue={department_name || ''}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="แผนก"
                      fullWidth
                      size={'small'}
                      disabled
                    />
                  )}
                />
              </div>
            </div>
            <div className="flex flex-row justify-end gap-1 py-4">
              <Button variant="contained" type="submit">
                บันทึก
              </Button>
            </div>
          </form>
        </Card>
      </div>
    );
  };

  if (me.isLoading) {
    return <Loading />;
  }
  if (!me.isLoading && me.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div className="flex flex-row justify-start pb-4">
          <div>
            <BackButton />
          </div>
        </div>
        {renderForm()}
      </div>
    );
  }
  return <Error />;
}
EditPersonalProfile.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

EditPersonalProfile.defaultProps = {
  title: '',
  subtitle: '',
};

export default EditPersonalProfile;
