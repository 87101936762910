import React from 'react';
import { Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import {
  Autocomplete,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import MeasurementAddingRenderingCompanent from '../../Measurement/MeasurementAddingRenderingComponent';

export function ProductForm({
  errors,
  product,
  control,
  productType,
  unit,
  watch,
}) {
  const information = useSelector((state) => state.information);
  const {
    setting: { wms: wmsSetting },
  } = information;
  return (
    <div className="flex flex-row flex-wrap">
      <div className="w-full px-1 py-2">
        <Controller
          name={'type_code'}
          control={control}
          defaultValue={product ? product.type_code : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="รหัสสินค้า"
              fullWidth
              size={'small'}
              helperText={errors.type_code && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'name'}
          control={control}
          defaultValue={product ? product.name : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="ชื่อสินค้า*"
              fullWidth
              size={'small'}
              helperText={errors.name && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      {watch('use_unit_conversion') === true ? (
        <div className="w-full lg:w-2/4 px-1 py-2 flex">
          <div className="w-1/2 px-1">
            {!_.isEmpty(unit?.rows) && (
              <Controller
                name={'unit_input'}
                control={control}
                defaultValue={product ? product.unit_input : ''}
                rules={{ required: false }}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    options={unit?.rows}
                    size={'small'}
                    disableClearable
                    onChange={(e, newValue) => {
                      field.onChange(newValue);
                    }}
                    getOptionLabel={(option) => `${option?.name} `}
                    required
                    renderInput={(params) => (
                      <TextField {...params} label="หน่วยขาเข้า" />
                    )}
                  />
                )}
              />
            )}
          </div>
          <div className="w-1/2 px-1">
            {!_.isEmpty(unit?.rows) && (
              <Controller
                name={'unit_output'}
                control={control}
                defaultValue={product ? product.unit_output : ''}
                rules={{ required: false }}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    options={unit?.rows}
                    size={'small'}
                    disableClearable
                    onChange={(e, newValue) => {
                      field.onChange(newValue);
                    }}
                    getOptionLabel={(option) => `${option?.name} `}
                    required
                    renderInput={(params) => (
                      <TextField {...params} label="หน่วยขาออก" />
                    )}
                  />
                )}
              />
            )}
          </div>
        </div>
      ) : (
        <div className="w-full lg:w-1/4 px-1 py-2">
          <Controller
            name={'unit'}
            control={control}
            defaultValue={product ? product.unit : ''}
            rules={{ required: true }}
            className="text-gray-500"
            render={({ field }) => (
              <TextField
                {...field}
                label="หน่วยสินค้า"
                fullWidth
                size={'small'}
                helperText={errors.unit && 'กรุณาใส่ข้อมูล'}
              />
            )}
          />
        </div>
      )}{' '}
      {information?.external_software?.ewarehouse?.enable && (
        <div className="w-full lg:w-1/4 px-1 py-2">
          <Controller
            name={'weight'}
            control={control}
            defaultValue={product ? product.weight : ''}
            render={({ field }) => (
              <TextField {...field} size="small" label="น้ำหนัก" fullWidth />
            )}
          />
        </div>
      )}
      <div className="w-full lg:w-1/4 px-1 py-2">
        <Controller
          name={'use_unit_conversion'}
          control={control}
          defaultValue={product ? product.unit : ''}
          render={({ field }) => (
            <FormControlLabel
              control={<Checkbox checked={field.value} {...field} />}
              label="ใช้การแปลงหน่วย"
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'product_type'}
          control={control}
          defaultValue={product?.product_type ? product?.product_type?._id : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <FormControl sx={{ minWidth: 120 }} fullWidth={true}>
              <InputLabel id="type" size={'small'}>
                ประเภทสินค้า*
              </InputLabel>
              <Select
                {...field}
                label="ประเภทสินค้า*"
                size={'small'}
                fullWidth
                required
              >
                {_.size(productType?.rows) ? (
                  _.map(productType.rows, (row) => (
                    <MenuItem key={row.id} value={row.id}>
                      {row.name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem>
                    <MenuItem disabled value="">
                      <em>ไม่มีข้อมูล</em>
                    </MenuItem>
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          )}
        />
      </div>
      {wmsSetting?.productAttribute?.net && (
        <div className="w-full lg:w-1/2 px-1 py-2">
          <Controller
            name={'net'}
            control={control}
            defaultValue={product ? product.net : ''}
            render={({ field }) => (
              <TextField
                {...field}
                label="ปริมาตรสุทธิ"
                fullWidth
                type="number"
                size={'small'}
                helperText={errors.net && 'กรุณาใส่ข้อมูล'}
              />
            )}
          />
        </div>
      )}{' '}
      {wmsSetting?.productAttribute?.net && (
        <div className="w-full lg:w-1/2 px-1 py-2">
          <Controller
            name={'unit_net'}
            control={control}
            defaultValue={product ? product.unit_net : ''}
            render={({ field }) => (
              <TextField
                {...field}
                label="หน่วยของปริมาตรสุทธิ"
                fullWidth
                size={'small'}
                helperText={errors.unit_net && 'กรุณาใส่ข้อมูล'}
              />
            )}
          />
        </div>
      )}
      {wmsSetting?.productAttribute?.packing_size && (
        <div className="w-full lg:w-1/2 px-1 py-2">
          <Controller
            name={'packing_size'}
            control={control}
            defaultValue={product ? product.packing_size : ''}
            render={({ field }) => (
              <TextField
                {...field}
                label="ขนาดบรรจุ"
                fullWidth
                type="number"
                size={'small'}
                helperText={errors.packing_size && 'กรุณาใส่ข้อมูล'}
              />
            )}
          />
        </div>
      )}
      {wmsSetting?.productAttribute?.packing_unit && (
        <div className="w-full lg:w-1/2 px-1 py-2">
          <Controller
            name={'packing_unit'}
            control={control}
            defaultValue={product ? product.packing_unit : ''}
            render={({ field }) => (
              <TextField
                {...field}
                label="หน่วยของการบรรจุ"
                fullWidth
                size={'small'}
                helperText={errors.packing_unit && 'กรุณาใส่ข้อมูล'}
              />
            )}
          />
        </div>
      )}
      <div className="w-full px-1 py-2">
        <Controller
          name={'detail'}
          control={control}
          defaultValue={product ? product.detail : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="รายละเอียด"
              fullWidth
              rows={2}
              multiline={true}
              size={'small'}
              helperText={errors.detail && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      {information?.setting?.wms?.additionalAttribute &&
        _.map(
          information?.setting?.wms?.additionalAttribute,
          (eachAttribute, index) => (
            <div className="w-full lg:w-1/2 px-1 py-2" key={index}>
              <MeasurementAddingRenderingCompanent
                control={control}
                measurementControllerName={`additional.${eachAttribute?.name}`}
                measurementType={{
                  data_type: eachAttribute?.type,
                  label: eachAttribute?.name,
                }}
                watch={watch}
                defaultValue={product?.additional?.[eachAttribute?.name]?.value}
              />
            </div>
          ),
        )}
    </div>
  );
}

ProductForm.propTypes = {
  errors: PropTypes.shape({
    name: PropTypes.object,
    unit: PropTypes.object,
    packing_size: PropTypes.object,
    packing_unit: PropTypes.object,
    net: PropTypes.object,
    unit_net: PropTypes.object,
    detail: PropTypes.object,
    type_code: PropTypes.object,
    minimum_amount: PropTypes.object,
    inventory_type: PropTypes.bool,
  }),
  product: PropTypes.object,
  productType: PropTypes.object,
  control: PropTypes.object,
  unit: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  watch: PropTypes.func,
};

ProductForm.defaultProps = {
  product: null,
  productType: null,
};

export default ProductForm;
