import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { gql } from 'graphql-request';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { Button, Card, CardContent } from '@mui/material';

import {
  ViewTitle,
  Loading,
  Error,
  BackButton,
  MOTemplateForm,
} from '../../../components';
import * as actions from '../../../redux/actions';
import graphqlClient from '../../../configs/graphqlClient';
import { app as appConfig } from '../../../configs';

/**
 * @function SettingCreateMOTemplate
 * @description Display a collections or a list of SettingCreateMOTemplate from database
 */

export default function SettingCreateMOTemplate({ title, subtitle }) {
  const dispatch = useDispatch();
  const product = useSelector((state) => state.product);
  const material = useSelector((state) => state.material);
  const processTemplate = useSelector((state) => state.processTemplate);

  const history = useHistory();
  const {
    control,
    watch,
    formState: { errors },
    setValue,
    handleSubmit,
  } = useForm();

  const query = gql`
    query FindSettingCreateMOTemplate(
      $materialInput: MaterialInput
      $productInput: ProductInput
      $processTemplateInput: ProcessTemplateInput
    ) {
      findMaterials(input: $materialInput) {
        total
        rows {
          _id
          type_code
          name
        }
      }
      findProducts(input: $productInput) {
        total
        rows {
          _id
          type_code
          name
        }
      }
      findProcessTemplates(input: $processTemplateInput) {
        total
        rows {
          _id
          name
        }
      }
    }
  `;

  const queryDataFromServer = async () => {
    try {
      const queryResult = await graphqlClient.request(query, {
        materialInput: {
          size: appConfig.maxFetchSize,
          fetchStockLot: false,
          status: '',
        },
        productInput: {
          size: appConfig.maxFetchSize,
          fetchStockLot: false,
          status: '',
        },
        processTemplateInput: {
          size: appConfig.maxFetchSize,
        },
      });
      const materialData = queryResult?.findMaterials;
      const productData = queryResult?.findProducts;
      const processTemplateData = queryResult?.findProcessTemplates;
      dispatch(actions.materialStateSet(materialData));
      dispatch(actions.productStateSet(productData));
      dispatch(actions.processTemplateStateSet(processTemplateData));
    } catch (error) {
      console.error('Cannot Fetch Element', error);
      // ให้ตัวใดตัวหนึ่ง Error เพื่อที่จะให้หน้าแสดงเป็๋น Error Page
      dispatch(actions.processTemplateError());
    }
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, []);

  const onSubmitMOTemplate = async (data) => {
    try {
      await dispatch(actions.moTemplateCreate(data));
      alert('สำเร็จ');
      history.goBack();
    } catch (error) {
      alert('สร้างสูตรคำสั่งผลิตไม่สำเร็จ');
    }
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (processTemplate.isLoading) {
    return <Loading />;
  }
  if (!processTemplate.isLoading && processTemplate.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div>
          <BackButton />
        </div>
        <div className="my-2">
          <Card>
            <CardContent>
              <form onSubmit={handleSubmit(onSubmitMOTemplate)}>
                <MOTemplateForm
                  control={control}
                  errors={errors}
                  material={material}
                  processTemplate={processTemplate}
                  product={product}
                  watch={watch}
                  setValue={setValue}
                />
                <div className="flex justify-end">
                  <Button variant="contained" type="submit">
                    บันทึก
                  </Button>
                </div>
              </form>
            </CardContent>
          </Card>
        </div>
      </div>
    );
  }
  return <Error />;
}

SettingCreateMOTemplate.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

SettingCreateMOTemplate.defaultProps = {
  title: '',
  subtitle: '',
};
