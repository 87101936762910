import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Card, CardContent } from '@mui/material';
import { gql } from 'graphql-request';
import _ from 'lodash';
import PropTypes from 'prop-types';

import {
  BackButton,
  Error,
  Loading,
  MachineForm,
  ViewTitle,
} from '../../components';
import graphqlClient from '../../configs/graphqlClient';
import * as actions from '../../redux/actions';

/**
 * @function EditMachine
 * @description Display a collections or a list of EditMachine from database
 */

export default function EditMachine({ title, subtitle }) {
  const dispatch = useDispatch();
  const machine = useSelector((state) => state.machine);
  const history = useHistory();
  const params = useParams();
  const [image, setImage] = useState([]);
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm();
  const query = gql`
    query FindEditMachine($machineinput: MachineInput) {
      findOneMachine(input: $machineinput) {
        _id
        name
        brand
        description
        buy_year
        serial_number
        image {
          url
        }
        is_conveyor
        conveyor_length
        conveyor_width
        conveyor_parallel_number
        conveyor_reference_point {
          enable
          reference_every
        }
      }
    }
  `;

  const queryDataFromServer = async () => {
    const queryResult = await graphqlClient.request(query, {
      machineinput: {
        id: params.id,
      },
    });
    const machineData = queryResult?.findOneMachine;
    dispatch(actions.machineStateOneSet(machineData));
  };

  const onSubmit = async (data) => {
    try {
      // eslint-disable-next-line no-param-reassign
      console.log(data);

      if (!_.isEmpty(image)) {
        // eslint-disable-next-line no-param-reassign
        data.image = {
          image: image[0]?.data_url,
          imageType: 'profile',
          alt: '',
        };
      }

      console.log('data', data);

      await dispatch(actions.machinePut(params.id, data));
      setImage([]);
      alert('สำเร็จ');
      history.goBack();
    } catch (error) {
      console.log(error);
      alert('เพิ่มเครื่องจักร / รางการผลิตใหม่ไม่สำเร็จ');
    }
  };
  useEffect(() => {
    dispatch(actions.machineReset());
    queryDataFromServer();
    return () => {};
  }, []);

  useEffect(() => {
    if (machine && !machine?.rows) {
      setValue('is_conveyor', machine?.is_conveyor);
      setValue('conveyor_width', machine?.conveyor_width);
      setValue('conveyor_length', machine?.conveyor_length);
      setValue('conveyor_parallel_number', machine?.conveyor_parallel_number);
      setValue('conveyor_reference_point', machine?.conveyor_reference_point);
    }

    return () => {};
  }, [machine]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (machine.isLoading) {
    return <Loading />;
  }
  if (!machine.isLoading && machine.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div className="my-2">
          <BackButton />
        </div>
        <div>
          <Card>
            <CardContent>
              <form onSubmit={handleSubmit(onSubmit)}>
                <MachineForm
                  control={control}
                  images={image}
                  setImages={setImage}
                  machine={machine}
                  errors={errors}
                  watch={watch}
                />
                <div className="flex justify-end">
                  <Button color="success" variant="contained" type="submit">
                    บันทึก
                  </Button>
                </div>
              </form>
            </CardContent>
          </Card>
        </div>
      </div>
    );
  }
  return <Error />;
}

EditMachine.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

EditMachine.defaultProps = {
  title: '',
  subtitle: '',
};
