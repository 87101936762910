import React from 'react';
import { ChevronDown, ChevronUp } from 'react-feather';
import { Card, CardContent } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { currencyFormatter } from '../../../utils/functions';

const BomCostBox = ({ allAnalyzed }) => {
  const formularCost = _.sum(
    _.map(allAnalyzed, (eachAnalyze) => eachAnalyze.formularCost),
  );
  const depositCost = _.sum(
    _.map(allAnalyzed, (eachAnalyze) => eachAnalyze.depositCost),
  );
  const actualCost = _.sum(
    _.map(allAnalyzed, (eachAnalyze) => eachAnalyze.actualCost),
  );

  const showDifference = (compared, based) => {
    if (compared > based) {
      return (
        <div className="flex gap-2 font-semibold text-red-500 text-lg">
          <div> {currencyFormatter.format(compared - based)}</div>
          <div className="self-center">
            <ChevronUp size={16} />
          </div>
        </div>
      );
    }
    if (compared < based) {
      return (
        <div className="flex gap-2 font-semibold text-yellow-500 text-lg">
          <div> {currencyFormatter.format(based - compared)}</div>
          <div className="self-center">
            <ChevronDown size={16} />
          </div>
        </div>
      );
    }
    return <div></div>;
  };

  return (
    <Card className="h-full">
      <CardContent>
        <div className="flex flex-wrap">
          <div className="w-full lg:w-1/3">
            <div className="text-xl">ต้นทุนจากสูตร</div>
            <div className="text-3xl font-semibold my-1">
              {currencyFormatter.format(formularCost)}{' '}
              <span className="text-base">บาท</span>
            </div>
          </div>
          <div className="w-full lg:w-1/3">
            <div className="text-xl">ต้นทุนจากการเบิก</div>
            <div className="text-3xl font-semibold my-1">
              {currencyFormatter.format(depositCost)}{' '}
              <span className="text-base">บาท</span>
            </div>
            {showDifference(depositCost, formularCost)}
          </div>
          <div className="w-full lg:w-1/3">
            <div className="text-xl">ต้นทุนที่ใช้จริง</div>
            <div className="text-3xl font-semibold my-1">
              {currencyFormatter.format(actualCost)}{' '}
              <span className="text-base">บาท</span>
            </div>
            {showDifference(actualCost, formularCost)}
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

BomCostBox.propTypes = { allAnalyzed: PropTypes.arrayOf(PropTypes.object) };

export default BomCostBox;
