import React from 'react';
import {
  Button,
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import MultiUnitProdOrMatBox from '../../Box/Rendering/MultiUnitProdOrMatBox';

const MaterialOrProductStockLotTable = ({
  payload,
  handlePushToDetail,
  handleChangeRowsPerPage,
  handleChangePage,
  page,
  size,
  total,
  title,
  showActionButton = true,
}) => (
  <div>
    {' '}
    <Paper>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <div className="font-bold">ลำดับที่</div>
              </TableCell>
              <TableCell>
                <div className="font-bold"> ชื่อ{title}</div>
              </TableCell>
              <TableCell>
                <div className="font-bold"> คงเหลือ</div>
              </TableCell>
              {showActionButton && (
                <TableCell>
                  <div className="font-bold"> ดำเนินการ</div>
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {!_.isEmpty(payload?.rows) ? (
              payload?.rows.map((row, index) => (
                <TableRow
                  key={row._id}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                  }}
                >
                  <TableCell component="th" scope="row">
                    {(page - 1) * size + index + 1}
                  </TableCell>
                  <TableCell>
                    <div
                      className={`font-display ${
                        showActionButton ? '' : 'hover:underline cursor-pointer'
                      } `}
                      onClick={
                        showActionButton
                          ? () => {}
                          : () => handlePushToDetail(row?._id)
                      }
                    >
                      <span className="font-bold">{row?.type_code}</span>{' '}
                      <span>{row?.name}</span>
                      {row?.virtual && (
                        <Chip label="Virtual" size="small" color="success" />
                      )}
                    </div>
                    <div className="text-sm italic text-gray-500">
                      {row?.material_type?.name}
                    </div>
                  </TableCell>
                  <TableCell>
                    <MultiUnitProdOrMatBox
                      foundMaterial={row}
                      quantity={row?.total}
                    />
                    {row?.warning && (
                      <div className="text-red-500 font-semibold">
                        ตำกว่าจำนวนขั้นต่ำ{' '}
                        <MultiUnitProdOrMatBox
                          foundMaterial={row}
                          quantity={row?.minimum_amount - row?.total}
                          noWrap
                        />
                      </div>
                    )}
                  </TableCell>
                  {showActionButton && (
                    <TableCell>
                      {' '}
                      <div className="flex flex-row flex-wrap gap-1">
                        <Button
                          variant="contained"
                          color={'info'}
                          size={'small'}
                          onClick={() => handlePushToDetail(row._id)}
                        >
                          รายละเอียด
                        </Button>
                      </div>
                    </TableCell>
                  )}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={6}>
                  <div className="text-center">ไม่มีข้อมูล</div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        onRowsPerPageChange={handleChangeRowsPerPage}
        page={page - 1}
        count={total || 1}
        rowsPerPage={size}
        onPageChange={handleChangePage}
      />
    </Paper>
  </div>
);

MaterialOrProductStockLotTable.propTypes = {
  /** Material Rows (In Material Get All) */
  payload: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  handlePushToDetail: PropTypes.func,
  handleChangeRowsPerPage: PropTypes.func,
  handleChangePage: PropTypes.func,
  page: PropTypes.number,
  size: PropTypes.number,
  total: PropTypes.number,
  title: PropTypes.string,
  showActionButton: PropTypes.bool,
};

export default MaterialOrProductStockLotTable;
