import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, CardContent } from '@mui/material';
import { useParams, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';

import { ViewTitle } from '../../components/ViewTitle';
import * as actions from '../../redux/actions';
import { UnitForm } from '../../components/Forms';
import Loading from '../../components/Loading';
import { Error } from '../../components/Error';
import { BackButton } from '../../components/Button';

export default function EditUnit({ title, subtitle }) {
  const dispatch = useDispatch();
  const unit = useSelector((state) => state.unit);
  const params = useParams();
  const history = useHistory();

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({ defaultValues: unit });

  const onSubmit = async (data) => {
    try {
      await dispatch(actions.unitPut(params.id, data));
      alert('แก้ไขหน่วยสำเร็จ');
      history.goBack();
    } catch (error) {
      window.alert(`แก้ไขหน่วยไม่สำเร็จ${error?.message}`);
      console.error('Error on Edit Unit', error);
    }
  };

  useEffect(() => {
    dispatch(actions.unitGet(params.id));
    return () => {};
  }, []);

  useEffect(() => {
    if (unit) {
      setValue('name', unit?.name);
      setValue('short_sign', unit?.short_sign);
      setValue('type_code', unit?.type_code);
    }
    return () => {};
  }, [unit]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const renderForm = () => (
    <div className="my-2">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card>
          <CardContent>
            <UnitForm control={control} errors={errors} />
          </CardContent>
        </Card>
        <div className="flex flex-row justify-end gap-1 py-4">
          <Button variant="contained" type="submit">
            บันทึก
          </Button>
        </div>
      </form>
    </div>
  );
  const renderBackButton = () => (
    <div className="flex justify-start">
      <BackButton />
    </div>
  );
  if (unit.isLoading || unit?.rows) {
    return <Loading />;
  }
  if (!unit.isLoading && unit.isCompleted) {
    return (
      <div>
        {renderTitle()}
        {renderBackButton()}
        {renderForm()}
      </div>
    );
  }
  return <Error />;
}
EditUnit.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

EditUnit.defaultProps = {
  title: '',
  subtitle: '',
};
