import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Card, CardContent } from '@mui/material';
import { gql } from 'graphql-request';
import hash from 'object-hash';
import PropTypes from 'prop-types';

import {
  BackButton,
  Error,
  Loading,
  LoadingDialogWithTimeout,
  RecieveMaterialForm,
  SocketIOLoading,
  ViewTitle,
} from '@/components';
import { config } from '@/configs';
import graphqlClient from '@/configs/graphqlClient';
import * as actions from '@/redux/actions';
import { MATERIAL_COMING_METHOD, MQ_TASK } from '@/utils/constants';

/**
 * @function RecieveMaterial
 * @description Display a collections or a list of RecieveMaterial from database
 */

export default function RecieveMaterial({ title, subtitle }) {
  const dispatch = useDispatch();
  const currentProcess = useSelector((state) => state.process);
  const step = useSelector((state) => state.step);
  const customer = useSelector((state) => state.customer);
  const me = useSelector((state) => state.me);
  const history = useHistory();
  const params = useParams();
  const [loadingDialogOn, setIsLoadingDialogOn] = useState(false);
  const [referenceNumber, setReferenceNumber] = useState('');
  const [statusMessage, setStatusMessage] = useState('');
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    const refNo = hash({
      date: new Date(),
      user: me?.userData?._id,
    });

    setReferenceNumber(refNo);

    return () => {};
  }, []);

  const query = gql`
    query FindRecieveMaterial(
      $processInput: ProcessInput
      $stepInput: StepInput
      $customerInput: CustomerInput
    ) {
      findOneProcess(input: $processInput) {
        _id
        produce_material_instead
        produce_base_project_instead
        manufacturing_order {
          running_number
        }
        product {
          type_code
          name
        }
        product_as_material {
          type_code
          name
        }
        base_project {
          name
        }
      }
      findOneStep(input: $stepInput) {
        _id
        name
        material_incoming_method
        main_material {
          _id
          name
          type_code
          use_unit_conversion
          unit
          conversion_from {
            unit_a
            unit_b
            conversion_factor
          }
          conversion_to {
            unit_b
            unit_a
            conversion_factor
          }
          unit_input {
            _id
            short_sign
            name
          }
          unit_output {
            _id
            short_sign
            name
          }
        }
      }
      findCustomers(input: $customerInput) {
        rows {
          _id
          name
          type_code
        }
      }
    }
  `;

  const queryDataFromServer = async () => {
    try {
      const queryResult = await graphqlClient.request(query, {
        processInput: {
          id: params.id,
          fetchBaseProject: true,
          fetchProduct: true,
          fetchMaterial: true,
        },
        stepInput: { id: params.stepId },
        customerInput: {
          page: 1,
          size: config.maxFetchSize,
        },
      });
      const processData = queryResult?.findOneProcess;
      const stepData = queryResult?.findOneStep;
      const customerData = queryResult?.findCustomers;
      dispatch(actions.processStateOneSet(processData));
      dispatch(actions.stepStateOneSet(stepData));
      dispatch(actions.customerStateSet(customerData));
    } catch (error) {
      console.error(error);
      dispatch(actions.processError());
    }
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [params]);

  const handleRecieveMaterial = async (data) => {
    try {
      console.log('Data', data);

      await dispatch(
        actions.processRecieveMaterial({
          ...data,
          materials: [
            {
              material: step?.main_material?._id,
              quantity: data?.quantity,
            },
          ],
          employee: me?.userData?._id,
          process: currentProcess?._id,
          step: step?._id,
          referenceNumber,
        }),
      );
      setIsLoadingDialogOn(true);
    } catch (error) {
      alert(`ไม่สามารถแก้ไขได้ ${error?.message}`);
    }
  };

  const handleSocketIOFunction = {
    onSuccess: () => {
      setIsLoadingDialogOn(false);
      history.goBack();
    },
    onFail: (args) => {
      setIsLoadingDialogOn(false);
      alert(args?.message);
    },
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const renderProductName = () => {
    if (currentProcess?.produce_material_instead) {
      return (
        <div>
          {currentProcess?.product_as_material?.type_code}{' '}
          {currentProcess?.product_as_material?.name}
        </div>
      );
    }
    if (currentProcess?.produce_base_project_instead) {
      return <div>{currentProcess?.base_project?.name}</div>;
    }

    return (
      <div>
        {currentProcess?.product?.type_code} {currentProcess?.product?.name}
      </div>
    );
  };

  // Another Method Recheck
  if (
    step?.material_incoming_method !==
    MATERIAL_COMING_METHOD.RECIEVE.status_code
  ) {
    return (
      <div>
        {renderTitle()}
        <div>ระบบนี้สำหรับการรับวัตถุดิบ แบบรับเข้าไม่ผ่านคลังเท่านั้น</div>
      </div>
    );
  }

  if (currentProcess.isLoading) {
    return <Loading />;
  }

  if (!currentProcess.isLoading && currentProcess.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div>
          <BackButton />
        </div>
        <LoadingDialogWithTimeout
          label={`กำลังดำเนินการนำเข้าวัตถุดิบ ${statusMessage || ''}`}
          isLoading={loadingDialogOn}
        />
        Ref No. {referenceNumber}
        <SocketIOLoading
          taskCode={MQ_TASK.RECIEVE_MATERIAL.status_code}
          handleSuccess={handleSocketIOFunction.onSuccess}
          handleFail={handleSocketIOFunction.onFail}
          referenceNumber={referenceNumber}
          setStatusMessage={setStatusMessage}
        />
        <div className="my-2">
          <Card>
            <CardContent>
              <div className="text-xl font-semibold font-display">
                {renderProductName()}
              </div>
              <div className="my-2">
                <span className="font-semibold">ขั้นตอนปัจจุบัน:</span>
                {'  '}
                {step?.name}
              </div>
            </CardContent>
          </Card>
        </div>
        <div className="my-2">
          <form onSubmit={handleSubmit(handleRecieveMaterial)}>
            <Card>
              <CardContent>
                <RecieveMaterialForm
                  control={control}
                  customer={customer}
                  selectedMaterial={step?.main_material}
                  setValue={setValue}
                  watch={watch}
                  errors={errors}
                />
                <div className="flex my-1 justify-end">
                  <Button variant="contained" type="submit">
                    บันทึก{' '}
                  </Button>
                </div>
              </CardContent>
            </Card>
          </form>
        </div>
      </div>
    );
  }
  return <Error />;
}

RecieveMaterial.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

RecieveMaterial.defaultProps = {
  title: '',
  subtitle: '',
};
