import React, { useEffect, useRef } from 'react';
import { Controller } from 'react-hook-form';
import MonacoEditor from 'react-monaco-editor';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { menuList } from '../../../utils/constants';

function PDFReportForm({ control, pdfReport }) {
  return (
    <div className="flex flex-wrap">
      <div className="w-full py-2">
        <Controller
          name="name"
          control={control}
          defaultValue={pdfReport?.name}
          render={({ field }) => (
            <TextField label="ชื่อรายงาน" fullWidth {...field} size="small" />
          )}
        />
      </div>
      <div className="w-full py-2">
        <Controller
          name="module"
          control={control}
          defaultValue={pdfReport?.module}
          render={({ field }) => (
            <FormControl fullWidth size="small">
              <InputLabel>โมดูลของระบบ</InputLabel>
              <Select {...field} fullWidth size="small" label="โมดูลของระบบ">
                {_.map(menuList, (menu, index) => (
                  <MenuItem key={index} value={menu?.moduleName}>
                    {menu?.title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />
      </div>
      <div className="w-full py-2 font-semibold font-display">
        โค้ดที่ใช้สร้างรายงาน
      </div>
      <div className="w-full py-2">
        <Controller
          name="structure"
          control={control}
          defaultValue={pdfReport?.structure}
          render={({ field }) => (
            <div>
              <MonacoEditor
                height="600"
                language="javascript"
                theme="vs"
                value={field.value}
                options={{}}
                onChange={(value) => field.onChange(value)}
              />
            </div>
          )}
        />
      </div>
    </div>
  );
}

export default PDFReportForm;

PDFReportForm.propTypes = {
  control: PropTypes.object,
  pdfReport: PropTypes.object,
};
