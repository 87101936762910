import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import PropTypes from 'prop-types';

import { PieChart } from '../../Chart';
import MultiUnitProdOrMatBox from '../Rendering/MultiUnitProdOrMatBox';

export default function MaterialUsageCard({
  isRequested,
  selectedMaterial,
  process,
}) {
  const materialInfo = () => {
    if (isRequested || process?.current?.index !== -1) {
      return (
        <div className="flex flex-wrap">
          <div className="w-full xl:w-1/2">
            {selectedMaterial?.resultAmount && (
              <PieChart
                labels={['เป็นผลผลิต', 'เสีย']}
                series={[
                  parseFloat(selectedMaterial?.resultAmount),
                  parseFloat(selectedMaterial?.wastesAmount),
                ]}
                height={200}
                donut
                anotherOptions={{
                  legend: {
                    show: false,
                  },
                  colors: ['#00E396', '#E5E5E5'],
                }}
              />
            )}
          </div>
          <div className="w-full xl:w-1/2">
            <div className="grid lg:grid-cols-2  p-2">
              <div className="my-2">
                <h4 className="text-sm">เบิกมา</h4>
                <div className="font-semibold">
                  <MultiUnitProdOrMatBox
                    foundMaterial={selectedMaterial?.materialInfo}
                    quantity={selectedMaterial?.requestAmount}
                  />
                </div>
              </div>
              <div className="my-2">
                <h4 className="text-sm">ใช้จริง</h4>
                <div className="font-semibold">
                  <MultiUnitProdOrMatBox
                    foundMaterial={selectedMaterial?.materialInfo}
                    quantity={selectedMaterial?.usedAmount}
                  />
                </div>
              </div>
              <div className="my-2">
                <h4 className="text-sm">ได้เป็นผลผลิต</h4>
                <div className="font-semibold">
                  <MultiUnitProdOrMatBox
                    foundMaterial={selectedMaterial?.materialInfo}
                    quantity={selectedMaterial?.resultAmount}
                  />
                </div>
              </div>
              <div className="my-2">
                <h4 className="text-sm">คืน</h4>
                <div className="font-semibold">
                  <MultiUnitProdOrMatBox
                    foundMaterial={selectedMaterial?.materialInfo}
                    quantity={selectedMaterial?.returnAmount}
                  />
                </div>
              </div>
              <div className="my-2">
                <h4 className="text-sm">ของเสีย</h4>
                <div className="font-semibold">
                  <MultiUnitProdOrMatBox
                    foundMaterial={selectedMaterial?.materialInfo}
                    quantity={selectedMaterial?.wastesAmount}
                  />
                </div>
              </div>
              <div className="my-2 col-span-2">
                <h4 className="text-sm">Yield ของการผลิต</h4>
                <div className="flex gap-2 items-center">
                  <div
                    className="font-bold
                  text-lg"
                  >
                    {' '}
                    {parseFloat(selectedMaterial?.yield).toFixed(2)} %
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div>
        {' '}
        <h4 className=" text-lg text-yellow-500 py-4">ยังไม่ได้เบิกวัตถุดิบ</h4>
      </div>
    );
  };

  return (
    <div className="my-2">
      <div className="self-center rounded-md  px-4 py-2 min-w-56 border border-gray-300 shadow-md">
        <div className="flex justify-between">
          <h4 className="font-semibold font-display text-lg my-2">
            {selectedMaterial?.materialInfo?.type_code}{' '}
            {selectedMaterial?.materialInfo?.name}{' '}
          </h4>
          <Link
            className="self-center"
            to={`/mms/manufacturing-orders/material/${process?._id}/${selectedMaterial?.materialInfo?._id}`}
          >
            <Button size="small">รายละเอียด</Button>
          </Link>
        </div>

        {materialInfo()}
      </div>
    </div>
  );
}

MaterialUsageCard.propTypes = {
  /**
   * เบิกวัตถุดิบมาแล้วยัง
   */
  isRequested: PropTypes.bool,
  /**
   *  วัตถุดิบที่เลือกมา (ข้อมูลวัตถุดิบอยู่ใน materialInfo และมีแนบปริมาณมาด้วย)
   */
  selectedMaterial: PropTypes.object,
  /**
   * ข้อมูลของ process ที่เลือก ว่า Current Index อยู่ที่ไหน
   */
  process: PropTypes.object,
};
