/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Button } from '@mui/material';
import { gql } from 'graphql-request';
import _ from 'lodash';
import hash from 'object-hash';
import PropTypes from 'prop-types';

import {
  BackButton,
  BoxAllMOMeasurementVariableBySteps,
  Error,
  InfoManufacturingOrder,
  Loading,
  LoadingDialog,
  ProcessHandleProductOnlyControlBox,
  SocketIOLoading,
  ViewTitle,
} from '@/components';
import { app as appConfig } from '@/configs';
import graphqlClient from '@/configs/graphqlClient';
import * as actions from '@/redux/actions';
import { MQ_TASK } from '@/utils/constants';

/**
 * @function MainDetailMO
 * @description Display a collections or a list of MainDetailMO from database
 */

export default function DetailManufacOrderB({ title, subtitle }) {
  const dispatch = useDispatch();
  const params = useParams();
  const manufacturingOrder = useSelector((state) => state.manufacturingOrder);
  const information = useSelector((state) => state.information);

  const me = useSelector((state) => state.me);
  const [isLoadingDialogOn, setIsDialogLoadingOn] = useState(false);
  const [referenceNumber, setReferenceNumber] = useState('');

  const findMainDetailMO = gql`
    query FindMainDetailMO(
      $input: ManufacturingOrderInput
      $paymentTypeInput: PaymentTypeInput
      $customerInput: CustomerInput
    ) {
      findOneManufacturingOrder(input: $input) {
        _id
        running_number
        prefix
        start_date
        expected_date
        completed
        deleted
        remark
        delete_note
        customer {
          type_code
          name
        }
        assigner {
          firstname
          lastname
          image {
            url
          }
        }
        process {
          _id
          deleted
          completed
          product {
            _id
            type_code
            name
          }
          produce_material_instead
          product_as_material {
            _id
            type_code
            name
          }
          current {
            index
            step {
              _id
              index
              completed
              deleted
            }
          }
          steps {
            _id
            name
            index
            responsible {
              employee {
                firstname
                lastname
                image {
                  url
                }
              }
            }
            measurements {
              value
              measurement_type {
                _id
                name
                description
                data_type
              }
            }
            resultingFunctionTypes {
              _id
              name
              is_process_level
              value
              executor {
                index
                using_constant
                using_static_value
                static_value
                using_another_rft
                operation
                measurement_type {
                  _id
                  name
                  data_type
                  type_code
                }
                constant {
                  _id
                  name
                  type_code
                  value
                  data_type
                  unit {
                    name
                  }
                }
                another_rft {
                  _id
                  name
                  is_process_level
                  executor {
                    index
                    using_constant
                    using_static_value
                    using_another_rft
                    static_value
                    operation
                    measurement_type {
                      _id
                      name
                      data_type
                      type_code
                    }
                    constant {
                      _id
                      name
                      type_code
                      value
                      data_type
                      unit {
                        name
                      }
                    }
                  }
                }
              }
            }
            completed
            deleted
          }
          resulting_function_types {
            _id
            name
            is_process_level
            value
            executor {
              index
              using_constant
              using_static_value
              static_value
              using_another_rft
              operation
              measurement_type {
                _id
                name
                data_type
                type_code
              }
              constant {
                _id
                name
                type_code
                value
                data_type
                unit {
                  name
                }
              }
              another_rft {
                _id
                name
                is_process_level
                executor {
                  index
                  using_constant
                  using_static_value
                  static_value
                  using_another_rft
                  operation
                  measurement_type {
                    _id
                    name
                    data_type
                    type_code
                  }
                  constant {
                    _id
                    name
                    type_code
                    value
                    data_type
                    unit {
                      name
                    }
                  }
                }
              }
            }
          }
          customer {
            name
            _id
            type_code
          }
          completed
          deleted
        }
      }

      findPaymentTypes(input: $paymentTypeInput) {
        rows {
          _id
          name
        }
      }
      findCustomers(input: $customerInput) {
        rows {
          _id
          type_code
          name
        }
      }
    }
  `;

  const queryDataFromServer = async () => {
    dispatch(actions.manufacturingOrderReset());
    try {
      const queryResult = await graphqlClient.request(findMainDetailMO, {
        input: {
          enableFetchInside: false,
          fetchCurrentStep: false,
          fetchCustomer: false,
          fetchMaterial: false,
          fetchProduct: true,
          id: params.id,
          fetchProcess: false,
        },
        paymentTypeInput: {
          page: 1,
          size: appConfig.maxFetchSize,
        },
        customerInput: {
          page: 1,
          size: appConfig.maxFetchSize,
        },
      });
      const manufacturingOrderData = queryResult?.findOneManufacturingOrder;
      const paymentTypeData = queryResult?.findPaymentTypes;
      const customerData = queryResult?.findCustomers;
      dispatch(actions.manufacturingOrderStateOneSet(manufacturingOrderData));
      dispatch(actions.paymentTypeStateSet(paymentTypeData));
      dispatch(actions.customerStateSet(customerData));
      const insideQueryResult = await graphqlClient.request(findMainDetailMO, {
        input: {
          enableFetchInside: true,
          fetchCurrentStep: true,
          fetchCustomer: true,
          fetchMaterial: false,
          fetchProduct: true,
          id: params.id,
          fetchProcess: true,
        },
        paymentTypeInput: {
          page: 1,
          size: appConfig.maxFetchSize,
        },
        customerInput: {
          page: 1,
          size: appConfig.maxFetchSize,
        },
      });
      const insideMOData = insideQueryResult?.findOneManufacturingOrder;
      dispatch(actions.manufacturingOrderStateOneSet(insideMOData));
    } catch (err) {
      console.error('Error On Fetch Inside Process', err);
    }
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [params]);

  useEffect(() => {
    const refNo = hash({
      date: new Date(),
      user: me?.userData?._id,
    });

    setReferenceNumber(refNo);

    return () => {};
  }, []);

  const handleSocketIOFunction = {
    onFail: (args) => {
      setIsDialogLoadingOn(false);
      alert(args?.message);
    },
    onSuccess: () => {
      setIsDialogLoadingOn(false);
      queryDataFromServer();
    },
  };

  const handleMOToSuccess = async ({
    manufacturingOrderId,
    handleSuccess = () => {},
  }) => {
    try {
      await dispatch(
        actions.manufacturingOrderPut(manufacturingOrderId, {
          completed: true,
        }),
      );
      handleSuccess();
      queryDataFromServer();
    } catch (error) {
      window.alert(`ไม่สามารถปิดคำสั่งผลิตได้ ${error?.message}`);
      console.error('Handle Manufacturing Order Success Fail', error);
    }
  };

  const handleMOToCancle = async ({
    manufacturingOrderId,
    handleSuccess = () => {},
  }) => {
    try {
      await dispatch(
        actions.manufacturingOrderPut(manufacturingOrderId, { deleted: true }),
      );
      handleSuccess();
      queryDataFromServer();
    } catch (error) {
      window.alert(`ไม่สามารถยกเลิกคำสั่งผลิตได้ ${error?.message}`);
      console.error('Handle Manufacturing Order Cancle Fail', error);
    }
  };

  const handleStepToSuccess = async (stepId, processId) => {
    try {
      const confirm = window.confirm('ยืนยันการเปลี่ยนสถานะ');
      if (confirm) {
        await dispatch(
          actions.stepPost({
            process: processId,
            employeeId: me?.userData?._id,
            referenceNumber,
          }),
        );

        setIsDialogLoadingOn(true);
      }
    } catch (error) {
      window.alert(`เปลี่ยนสถานะขั้นตอนไม่สำเร็จ ${error?.message}`);
    }
  };

  const handleProcessToComplete = async (processId) => {
    try {
      const confirm = window.confirm('ยืนยันการเปลี่ยนสถานะ');
      if (confirm) {
        await dispatch(
          actions.processPut(processId, {
            completed: true,
            referenceNumber,
          }),
        );

        setIsDialogLoadingOn(true);
      }
    } catch (error) {
      window.alert(`เปลี่ยนสถานะไลน์การผลิตไม่สำเร็จ ${error?.message}`);
    }
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (manufacturingOrder.isLoading) {
    return <Loading />;
  }
  if (!manufacturingOrder.isLoading && manufacturingOrder.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <LoadingDialog isLoading={isLoadingDialogOn} label="กำลังดำเนินการ" />
        <SocketIOLoading
          referenceNumber={referenceNumber}
          taskCode={MQ_TASK.MOVING_STEP_UP.status_code}
          handleFail={handleSocketIOFunction.onFail}
          handleSuccess={handleSocketIOFunction.onSuccess}
        />
        <SocketIOLoading
          referenceNumber={referenceNumber}
          taskCode={MQ_TASK.UPDATE_PROCESS.status_code}
          handleFail={handleSocketIOFunction.onFail}
          handleSuccess={handleSocketIOFunction.onSuccess}
        />
        <div className="my-2">
          <BackButton />
        </div>
        <div className="my-2">
          <InfoManufacturingOrder
            information={information}
            manufacturingOrder={manufacturingOrder}
            me={me}
            handleMOToCancle={handleMOToCancle}
            handleMOToSuccess={handleMOToSuccess}
          />
        </div>
        <div className="flex justify-end">
          {_.map(
            information?.setting?.mms?.customDashboard,
            (eachDashboard, index) => (
              <Link
                to={`/mms/manufacturing-orders/custom/${eachDashboard?._id}/${params.id}`}
              >
                <Button key={index} variant="contained" color="secondary">
                  {eachDashboard?.name}
                </Button>
              </Link>
            ),
          )}
        </div>
        <div className="my-2">
          {_.map(manufacturingOrder?.process, (eachProcess, index) => (
            <ProcessHandleProductOnlyControlBox
              key={index}
              selectedProcess={eachProcess}
              handleProcessSuccess={handleProcessToComplete}
            />
          ))}
        </div>
        {/** Suppose Must be the same product and the same step */}
        <div className="my-2">
          {_.map(manufacturingOrder?.process?.[0]?.steps, (eachStep, index) => (
            <BoxAllMOMeasurementVariableBySteps
              key={index}
              processArray={manufacturingOrder?.process}
              stepIndex={eachStep?.index}
              stepInfo={eachStep}
              handleSuccess={handleStepToSuccess}
            />
          ))}
        </div>
      </div>
    );
  }
  return <Error />;
}

DetailManufacOrderB.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

DetailManufacOrderB.defaultProps = {
  title: '',
  subtitle: '',
};
