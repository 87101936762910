import React from 'react';
import {
  TextField,
  Autocomplete,
  Button,
  FormControlLabel,
  Switch,
  IconButton,
  Checkbox,
} from '@mui/material';
import { Controller, useFieldArray } from 'react-hook-form';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ChevronUp, Trash, ChevronDown } from 'react-feather';

export function MOTempalteForm({
  errors,
  control,
  watch,
  moTemplate,
  product,
  material,
  processTemplate,
  setValue,
}) {
  const { fields, append, move, remove } = useFieldArray({
    control,
    name: 'processes',
  });

  const renderEachProcess = (selectedField, index) => (
    <div
      key={selectedField?.id}
      className="flex flex-wrap border rounded-md p-2"
    >
      <div className="w-1/12 px-1 self-center">ลำดับที่ {index + 1}</div>
      <div className="w-3/12 px-1">
        <Controller
          control={control}
          name={`processes[${index}].produce_material_instead`}
          defaultValue={
            moTemplate?.processes?.[index]?.produce_material_instead
          }
          render={({ field }) => (
            <div className="flex gap-1">
              <div className="self-center">ผลิตสินค้า</div>
              <FormControlLabel
                label="ผลิตวัตถุดิบ"
                control={<Switch {...field} defaultChecked={field?.value} />}
              />
            </div>
          )}
        />
        <div>
          <Controller
            control={control}
            name={`processes[${index}].no_product`}
            defaultValue={moTemplate?.[index]?.no_product}
            render={({ field }) => (
              <FormControlLabel
                label="ไม่ระบบสินค้า / วัตถุดิบ"
                control={
                  <Checkbox
                    {...field}
                    onChange={(event) => {
                      const isChecked = event.target.checked;
                      field.onChange(event.target.checked);
                      if (isChecked) {
                        setValue(
                          `processes[${index}].product_as_material`,
                          null,
                        );
                        setValue(`processes[${index}].product`, null);
                      }
                    }}
                    defaultChecked={field.value}
                  />
                }
              />
            )}
          />
        </div>
      </div>
      {!watch(`processes[${index}].no_product`) && (
        <div className="w-3/12 px-1 self-center">
          {watch(`processes[${index}].produce_material_instead`) ? (
            <Controller
              control={control}
              name={`processes[${index}].product_as_material`}
              defaultValue={
                moTemplate
                  ? moTemplate?.processes?.[index]?.product_as_material
                  : null
              }
              render={({ field }) => (
                <div>
                  {!_.isEmpty(material?.rows) && (
                    <Autocomplete
                      size={'small'}
                      disableClearable
                      defaultValue={field?.value}
                      {...field}
                      onChange={(e, newValue) => {
                        field.onChange(newValue);
                      }}
                      options={material?.rows}
                      getOptionLabel={(option) => {
                        if (option?.type_code) {
                          return `${option?.type_code} | ${option?.name}`;
                        }
                        return `${option?.name}`;
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="วัตถุดิบ" />
                      )}
                    />
                  )}
                </div>
              )}
            />
          ) : (
            <Controller
              control={control}
              name={`processes[${index}].product`}
              defaultValue={
                moTemplate ? moTemplate?.processes?.[index]?.product : null
              }
              render={({ field }) => (
                <div>
                  {!_.isEmpty(product?.rows) && (
                    <Autocomplete
                      size={'small'}
                      disableClearable
                      {...field}
                      onChange={(e, newValue) => {
                        field.onChange(newValue);
                      }}
                      defaultValue={field?.value}
                      options={product?.rows}
                      getOptionLabel={(option) => {
                        if (option?.type_code) {
                          return `${option?.type_code} | ${option?.name}`;
                        }
                        return `${option?.name}`;
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="สินค้า" />
                      )}
                    />
                  )}
                </div>
              )}
            />
          )}
        </div>
      )}
      <div className="w-3/12 px-1 self-center">
        <Controller
          control={control}
          name={`processes[${index}].process_template`}
          defaultValue={
            moTemplate ? moTemplate?.processes?.[index]?.process_template : null
          }
          render={({ field }) => (
            <div>
              {!_.isEmpty(processTemplate?.rows) && (
                <Autocomplete
                  size={'small'}
                  disableClearable
                  {...field}
                  onChange={(e, newValue) => {
                    field.onChange(newValue);
                  }}
                  defaultValue={field?.value}
                  options={processTemplate?.rows}
                  required
                  getOptionLabel={(option) => {
                    if (option?.type_code) {
                      return `${option?.type_code} | ${option?.name}`;
                    }
                    return `${option?.name}`;
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="สูตรการผลิต"
                      required
                      helperText={
                        (errors?.processes?.[index]?.process_tempalte &&
                          'กรุณาเลือกสูตรการผลิต') ||
                        ''
                      }
                    />
                  )}
                />
              )}
            </div>
          )}
        />
      </div>
      <div className="w-1/12 px-1 self-center">
        <div className="flex ">
          <IconButton
            variant="contained"
            color="inherit"
            onClick={() => {
              if (index === 0) {
                // Do Nothing
              } else {
                move(index, index - 1);
              }
            }}
          >
            <ChevronUp size="16" />
          </IconButton>
          <IconButton
            variant="contained"
            color="inherit"
            onClick={() => {
              if (index === _.size(fields) - 1) {
                // Do Nothing
              } else {
                move(index, index + 1);
              }
            }}
          >
            <ChevronDown size="16" />
          </IconButton>{' '}
          <IconButton
            variant="contained"
            color="error"
            onClick={() => {
              remove(index);
            }}
          >
            <Trash size="16" />
          </IconButton>
        </div>{' '}
      </div>
    </div>
  );

  return (
    <div className="flex flex-wrap">
      <div className="w-full py-2">
        <Controller
          control={control}
          name="name"
          defaultValue={moTemplate?.name}
          render={({ field }) => (
            <TextField
              {...field}
              label="ชื่อ"
              fullWidth
              size="small"
              required
              helperText={(errors?.name && 'กรุณาระบุชื่อ') || ''}
            />
          )}
        />
      </div>
      <div className="w-full py-2">
        {_.map(fields, (eachField, index) =>
          renderEachProcess(eachField, index),
        )}
      </div>
      <div className="w-full py-2">
        <Button variant="contained" size="small" onClick={() => append({})}>
          เพิ่มขั้นตอน
        </Button>
      </div>
    </div>
  );
}

MOTempalteForm.propTypes = {
  errors: PropTypes.object,
  control: PropTypes.object,
  watch: PropTypes.func,
  moTemplate: PropTypes.object,
  material: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  product: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  processTemplate: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  setValue: PropTypes.func,
};

export default MOTempalteForm;
