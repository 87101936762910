import React, { Suspense, lazy } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ErrorBoundary } from 'react-error-boundary';
import { Loading } from '../../Loading';

import AllPluginInfo from '../../../plugins';
// const AllPluginInfo = lazy(() => import('../../plugins'));

export default function PluginEditRenderingComponent({
  manufacturingOrder,
  currentProcess,
  currentStep,
}) {
  if (!currentStep?.plugin || currentStep?.use_plugin_instead === false) {
    return <div></div>;
  }

  const FoundedPlugin = _.find(
    AllPluginInfo,
    (eachPlugin) => eachPlugin?.id === currentStep?.plugin,
  );

  if (!FoundedPlugin) {
    return <div></div>;
  }

  return (
    <div>
      <ErrorBoundary FallbackComponent={<div>Error </div>}>
        <Suspense fallback={<Loading />}>
          <FoundedPlugin.editOnStepContainer
            manufacturingOrder={manufacturingOrder}
            currentProcess={currentProcess}
            currentStep={currentStep}
          />
        </Suspense>
      </ErrorBoundary>
    </div>
  );
}

PluginEditRenderingComponent.propTypes = {
  manufacturingOrder: PropTypes.object,
  currentProcess: PropTypes.object,
  currentStep: PropTypes.object,
};
