import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import _ from 'lodash';
import PropTypes from 'prop-types';
import * as actions from '../../redux/actions';
import Loading from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';

function ProductStock({ title, subtitle }) {
  const dispatch = useDispatch();
  const warehouse = useSelector((state) => state.warehouse);
  const history = useHistory();

  useEffect(() => {
    dispatch(actions.warehouseAll(''));
    return () => {};
  }, []);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const renderWarehouse = () => (
    <div className="flex flex-wrap py-4">
      {!_.isEmpty(warehouse.rows) && (
        <div
          className="w-full lg:4/12 xl:w-2/12 p-2 cursor-pointer"
          onClick={() => {
            history.push('/wms/product-stock/warehouse/');
          }}
        >
          <div className="items-center text-center  p-4 bg-white rounded-lg  shadow-xl">
            <i className="fas fa-warehouse fa-3x text-gray-500" />
            <div className="text-base my-1 font-display">รวมทุกคลัง</div>
          </div>
        </div>
      )}{' '}
      {!_.isEmpty(warehouse.rows) ? (
        warehouse.rows.map((row, index) => (
          <div
            key={index}
            className="w-full lg:4/12 xl:w-2/12 p-2 cursor-pointer"
            onClick={() => {
              history.push(`/wms/product-stock/warehouse/${row.id}`);
            }}
          >
            <div className="items-center text-center  p-4 bg-white rounded-lg  shadow-xl">
              <i className="fas fa-warehouse fa-3x text-gray-500" />
              <div className="text-base my-1 font-display">{row.name}</div>
            </div>
          </div>
        ))
      ) : (
        <div className="flex justify-center p-4">
          <div>ไม่มีข้อมูล</div>
        </div>
      )}
    </div>
  );

  if (warehouse.isLoading) {
    return <Loading />;
  }
  if (!warehouse.isLoading && warehouse.isCompleted) {
    return (
      <div>
        <div className="flex justify-between">
          <div>{renderTitle()}</div>
        </div>
        {renderWarehouse()}
      </div>
    );
  }
  return <Error message={warehouse?.message} />;
}

ProductStock.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

ProductStock.defaultProps = {
  title: '',
  subtitle: '',
};

export default ProductStock;
