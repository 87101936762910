import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { gql } from 'graphql-request';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Card, CardContent } from '@mui/material';
import _ from 'lodash';

import {
  ViewTitle,
  Loading,
  Error,
  BackButton,
  NameBox,
} from '../../../components';
import * as actions from '../../../redux/actions';
import graphqlClient from '../../../configs/graphqlClient';

/**
 * @function SelectCurrentStep
 * @description Display a collections or a list of SelectCurrentStep from database
 */

export default function SelectCurrentStep({ title, subtitle }) {
  const dispatch = useDispatch();
  const currentProcess = useSelector((state) => state.process);
  const history = useHistory();
  const params = useParams();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);

  const query = gql`
    query FindSelectCurrentStep($processInput: ProcessInput) {
      findOneProcess(input: $processInput) {
        _id
        current {
          index
          step {
            _id
            name
            index
          }
        }
        steps {
          _id
          name
          index
          responsible {
            department {
              name
            }
            employee {
              image {
                url
              }
              firstname
              lastname
            }
          }
        }
      }
    }
  `;

  const queryDataFromServer = async () => {
    const queryResult = await graphqlClient.request(query, {
      processInput: {
        id: params.id,
        fetchInsideProcess: true,
        fetchCurrentStep: true,
      },
    });
    const processData = queryResult?.findOneProcess;
    dispatch(actions.processStateOneSet(processData));
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [params]);

  const handleChangeCurrentStep = async (selectedStepId, selectedStepIndex) => {
    try {
      const confirm = window.confirm(
        'ระบบนี้มีไว้ใช้สำหรับแก้ปัญหากรณีที่ระบบหางานปัจจุบันไม่เจอ การแก้ไขโดยที่ยังมีงานปัจจุบันอยู่ อาจเป็นข้ามขั้นตอนบางขั้นตอนไป หรืออาจทำให้การส่งต่อวัตถุดิบไม่ถูกต้อง ดังนั้นท่านจำเป็นต้องรับผิดชอบในความถี่นี้ ',
      );
      if (confirm) {
        await dispatch(
          actions.processPut(params.id, {
            current: { step: selectedStepId, index: selectedStepIndex },
          }),
        );
        alert('แก้ไขงานปัจจุบันสำเร็จ');
        history.goBack();
      }
    } catch (error) {
      alert(`ไม่สามารถที่จะแก้ไขงานปัจจุบันได้ ${error?.message}`);
      console.error('Cannot Edit Current Work', error);
    }
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (currentProcess.isLoading) {
    return <Loading />;
  }

  if (!currentProcess.isLoading && currentProcess.isCompleted) {
    return (
      <div>
        {renderTitle()}

        <div>
          <BackButton />
        </div>
        <div className="my-2">
          <Card>
            <CardContent>
              <div className="flex flex-wrap">
                <div className="w-full lg:w-1/2">
                  <div className="font-semibold font-display">
                    ขั้นตอนปัจจุบัน
                  </div>

                  {currentProcess?.current?.step ? (
                    <div className="border p-2 m-2 rounded-md">
                      <div className="text-xl font-semibold">
                        {currentProcess?.current?.step?.name}
                      </div>
                    </div>
                  ) : (
                    <div className="my-2">ไม่มีงานปัจจุบัน</div>
                  )}
                </div>
                <div className="w-full lg:w-1/2 px-2">
                  <div>งานทั้งหมด</div>
                  {_.map(currentProcess?.steps, (eachStep, index) => (
                    <div key={index} className="my-2 p-2 border rounded-md ">
                      <div className="font-semibold text-lg">
                        {eachStep?.index + 1} {eachStep?.name}
                      </div>
                      <div>แผนก {eachStep?.responsible?.department?.name}</div>
                      <div className="flex flex-wrap">
                        <div className="w-full lg:w-2/3">
                          {' '}
                          <div className="my-2 text-sm font-semibold">
                            ผู้รับผิดชอบ
                          </div>
                          {eachStep?.responsible?.employee ? (
                            <NameBox user={eachStep?.responsible?.employee} />
                          ) : (
                            <div>ยังไม่มีผู้รับผิดชอบ</div>
                          )}
                        </div>
                        <div className="w-full lg:w-1/3">
                          <div className="my-2">
                            <Button
                              variant="contained"
                              disabled={
                                eachStep?._id ===
                                currentProcess?.current?.step?._id
                              }
                              onClick={() =>
                                handleChangeCurrentStep(
                                  eachStep?._id,
                                  eachStep?.index,
                                )
                              }
                            >
                              เลือกงานนี้
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    );
  }
  return <Error />;
}

SelectCurrentStep.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

SelectCurrentStep.defaultProps = {
  title: '',
  subtitle: '',
};
