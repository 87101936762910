import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import {
  Backdrop,
  Button,
  Card,
  Fade,
  InputAdornment,
  Modal,
  TextField,
} from '@mui/material';
import { gql } from 'graphql-request';
import PropTypes from 'prop-types';

import {
  Error,
  Loading,
  MeasurementTypeForm,
  MeasurementTypeTable,
  ViewTitle,
} from '@/components';
import { app as appConfig } from '@/configs';
import * as actions from '@/redux/actions';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90vw',
  boxShadow: 24,
  p: 4,
};

// ในระบบเราจะเรียกว่า Measurement Type เพราะในการเก็บโครงสร้างข้อมูลมันมีทั้ง
// ที่เป็นตัวนี้คือ Template และที่เป็นค่า
// แต่ในทาง UI เราจะใช้มันว่า ตัวแปรวัดค่า Measurement Variable เพราะว่ามันจะทำให้เข้าใจง่ายกว่า
// สำหรับ User
export default function SettingMeasurementType({ title, subtitle }) {
  const dispatch = useDispatch();
  const measurementType = useSelector((state) => state.measurementType);
  const history = useHistory();

  // Setting React hook form
  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm();
  const [searchTerm, setSearchTerm] = useState('');
  const [name, setName] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(undefined);
  const [modalOpen, setModalOpen] = useState(false);
  const unit = useSelector((state) => state.unit);
  useEffect(() => {
    const fetchMeasurementType = async () => {
      try {
        await dispatch(actions.measurementTypeAll({ name, page, size }));
      } catch (error) {
        console.error(error);
      }
    };

    fetchMeasurementType();

    return () => {};
  }, [name, page, size]);

  useEffect(() => {
    const getAllUnit = async () => {
      try {
        const query = gql`
        query FindUnits {
          findUnits(input:{page:1,size:${appConfig.maxFetchSize}}){
            rows {
            _id
            name
            }
          }
        } 
      `;
        dispatch(actions.unitAllQuery({ query }));
      } catch (error) {
        console.error('Get All Units Error', error);
      }
    };

    getAllUnit();

    return () => {};
  }, []);

  useEffect(() => {
    setTotal(measurementType?.total);
    return () => {};
  }, [measurementType]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchTerm);
      setPage(1);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const onSubmit = async (data) => {
    try {
      await dispatch(actions.measurementTypeCreate(data));
      await dispatch(actions.measurementTypeAll({ name, page, size }));
      setModalOpen(false);
      reset();
    } catch (error) {
      window.alert('สร้างตัวแปรไม่สำเร็จ', error?.message);
    }
  };

  const tableProps = {
    size,
    page,
    total,
    handleDelete: async (id) => {
      const confirm = window.confirm('ยืนยันการลบข้อมูล');
      if (confirm) {
        try {
          await dispatch(actions.measurementTypeDelete(id));
          await dispatch(actions.measurementTypeAll({ name, page, size }));
        } catch (error) {
          console.error(error);
        }
      }
    },
    handlePushToEditPage: async (id) => {
      history.push(`measurement-type/edit/${id}`);
    },
    handleChangeRowsPerPage: (event) => {
      setSize(event.target.value);
      setPage(1);
    },
    handleChangePage: (event, newPage) => {
      setPage(newPage + 1);
    },
  };

  const handleClose = () => setModalOpen(false);
  const handleOpen = () => setModalOpen(true);

  // UI Rendering
  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const renderAddButton = () => (
    <div className="flex flex-row justify-end items-center  pb-4 gap-2">
      <Link to="/km/resulting-function-type">
        <Button
          variant="contained"
          color="secondary"
          className="self-center"
          onClick={handleOpen}
        >
          สูตรการแสดงผล
        </Button>
      </Link>

      <Button variant="contained" className="self-center" onClick={handleOpen}>
        เพิ่ม
      </Button>
    </div>
  );

  const renderSearch = () => (
    <Card>
      <div className="p-4 flex flex-row">
        <div className="w-full md:w-1/2">
          <TextField
            label="ค้นหา"
            fullWidth
            size={'small'}
            id="outlined-start-adornment"
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <i className="fas fa-search"></i>
                </InputAdornment>
              ),
            }}
          />
        </div>
      </div>
    </Card>
  );

  const renderTable = () => (
    <div className="my-2">
      <MeasurementTypeTable
        {...tableProps}
        measurementTypes={measurementType}
      />
    </div>
  );

  const renderModal = () => (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalOpen}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={modalOpen}>
          <Card sx={style} className="max-w-4xl">
            <div className="py-2 font-semibold font-display">
              เพิ่มตัวแปรวัดค่า
            </div>
            <div className="py-2">
              <form onSubmit={handleSubmit(onSubmit)}>
                <MeasurementTypeForm
                  control={control}
                  units={unit}
                  watch={watch}
                  errors={errors}
                />
                <div className="flex flex-row justify-end gap-1 py-4">
                  <Button variant="contained" type="submit">
                    บันทึก
                  </Button>
                  <Button variant="outlined" onClick={handleClose}>
                    ยกเลิก
                  </Button>
                </div>
              </form>
            </div>
          </Card>
        </Fade>
      </Modal>
    </div>
  );

  if (measurementType.isLoading) {
    return <Loading />;
  }
  if (!measurementType.isLoading && measurementType.isCompleted) {
    return (
      <div>
        <div className="flex justify-between">
          <div>{renderTitle()}</div>
          {renderAddButton()}
        </div>

        {renderSearch()}
        {renderTable()}
        {renderModal()}
      </div>
    );
  }
  return <Error message={measurementType?.message} />;
}

SettingMeasurementType.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

SettingMeasurementType.defaultProps = {
  title: '',
  subtitle: '',
};
