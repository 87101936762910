// db1 represent 1 db any
// can be 1-db2  or 1-db12 , etc.

import db1 from "./2-1DB.png";
import db2 from "./2-2DB.png";
import db3 from "./2-3DB.png";
import db4 from "./2-4DB.png";
import db5 from "./2-5DB.png";
import db6 from "./2-6DB.png";
import db7 from "./2-7DB.png";
import db8 from "./2-8DB.png";
import db9 from "./2-9DB.png";
import db10 from "./2-10DB.png";
import db11 from "./2-11DB.png";
import db12 from "./2-12DB.png";

const twoJacket = {
  db1,
  db2,
  db3,
  db4,
  db5,
  db6,
  db7,
  db8,
  db9,
  db10,
  db11,
  db12,
};

export default twoJacket;
