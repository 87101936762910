import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { gql } from 'graphql-request';
import { useHistory, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Button, Card, CardContent } from '@mui/material';

import {
  ViewTitle,
  Loading,
  Error,
  BackButton,
  WarehouseApproveRequestForm,
} from '../../components';
import * as actions from '../../redux/actions';
import graphqlClient from '../../configs/graphqlClient';
import { config } from '../../configs';

/**
 * @function ApproveAddWarehouseStock
 * @memberof WMS
 * @description ดำเนินการเพิ่มสินค้าเข้าคลังสินค้าจากรายการที่ขออนุมัติมา
 * จะสามาระเลือกคลังสินค้าได้ ใส่เป็นล็อตแยกๆ กันก็ได้
 * @author theethawat
 */

export default function ApproveAddWarehouseStock({ title, subtitle }) {
  const dispatch = useDispatch();
  const warehouseStockRequest = useSelector(
    (state) => state.warehouseStockRequest,
  );
  const warehouse = useSelector((state) => state.warehouse);
  const productTransactionType = useSelector(
    (state) => state.productTransactionType,
  );
  const me = useSelector((state) => state.me);
  const history = useHistory();
  const params = useParams();
  const { control, setValue, watch, handleSubmit } = useForm();

  const query = gql`
    query FindWarehouseStockRequest(
      $warehouseStockRequestInput: WarehouseStockRequestInput
      $warehouseInput: WarehouseInput
      $productTransactionTypeInput: ProductTransactionTypeInput
    ) {
      findOneWarehouseStockRequest(input: $warehouseStockRequestInput) {
        _id
        requester {
          _id
          firstname
          lastname
        }
        approver {
          _id
          firstname
          lastname
        }
        manufacturing_order {
          _id
          running_number
        }
        process {
          _id
        }
        products {
          product {
            _id
            type_code
            name
            use_unit_conversion
            unit
            conversion_from {
              unit_a
              unit_b
              conversion_factor
            }
            conversion_to {
              unit_b
              unit_a
              conversion_factor
            }
            unit_input {
              _id
              short_sign
              name
            }
            unit_output {
              _id
              short_sign
              name
            }
          }
          quantity
        }
        status
        createdAt
      }
      findWarehouses(input: $warehouseInput) {
        rows {
          _id
          name
        }
      }
      findProductTransactionTypes(input: $productTransactionTypeInput) {
        rows {
          _id
          name
          direction
        }
      }
    }
  `;

  const queryDataFromServer = async () => {
    try {
      const queryResult = await graphqlClient.request(query, {
        warehouseStockRequestInput: { id: params.id },
        warehouseInput: { page: 1, size: config.maxFetchSize },
        productTransactionTypeInput: { page: 1, size: config.maxFetchSize },
      });
      const warehouseStockReqData = queryResult?.findOneWarehouseStockRequest;
      const warehouseData = queryResult?.findWarehouses;
      const productTransactionTypeData =
        queryResult?.findProductTransactionTypes;
      dispatch(actions.warehouseStockRequestStateSet(warehouseStockReqData));
      dispatch(actions.warehouseStateSet(warehouseData));
      dispatch(
        actions.productTransactionTypeStateSet(productTransactionTypeData),
      );
    } catch (error) {
      console.error(error);
      dispatch(actions.warehouseStockRequestError());
    }
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [params]);

  useEffect(() => {
    if (
      warehouseStockRequest &&
      !warehouseStockRequest?.isLoading &&
      !warehouseStockRequest?.rows &&
      warehouseStockRequest?.products
    ) {
      setValue('products', warehouseStockRequest?.products);
    }

    return () => {};
  }, [warehouseStockRequest]);

  const handleApproveToWarehouse = async (data) => {
    try {
      const payload = {
        ...data,
        insertStockLot: true,
        manufacturing_order: warehouseStockRequest?.manufacturing_order?._id,
        process: warehouseStockRequest?.process?._id,
        approver: me?.userData,
      };
      console.log('Data', payload);
      await dispatch(actions.warehouseStockRequestPut(params.id, payload));
      history.goBack();
    } catch (error) {
      alert(`ไม่สามารถอนุมัติการเพิ่มเข้าคลังสินค้าได้ ${error?.message}`);
    }
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (warehouseStockRequest.isLoading) {
    return <Loading />;
  }

  if (!warehouseStockRequest.isLoading && warehouseStockRequest.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div>
          <BackButton />
        </div>
        <div className="my-2">
          <Card>
            <CardContent>
              <form onSubmit={handleSubmit(handleApproveToWarehouse)}>
                <WarehouseApproveRequestForm
                  control={control}
                  setValue={setValue}
                  watch={watch}
                  warehouse={warehouse}
                  productTransactionType={productTransactionType}
                />
                <div className="flex justify-end my-2">
                  <Button variant="contained" type="submit">
                    บันทึก
                  </Button>
                </div>
              </form>
            </CardContent>
          </Card>
        </div>
      </div>
    );
  }
  return <Error />;
}

ApproveAddWarehouseStock.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

ApproveAddWarehouseStock.defaultProps = {
  title: '',
  subtitle: '',
};
