/* eslint-disable import/no-named-as-default-member */
import React, { useEffect, useState } from 'react';
import {
  Monitor as MonitorIcon,
  RefreshCw,
  Smartphone as PhoneIcon,
} from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import {
  Button,
  Card,
  InputAdornment,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import { gql } from 'graphql-request';
import _ from 'lodash';
import PropTypes from 'prop-types';

import {
  CardMOList,
  DeleteManufacOrderModal,
  Error,
  Loading,
  LoadingDialogWithTimeout,
  ManufacturingOrderTable,
  SocketIOLoading,
  ViewTitle,
} from '@/components';

import graphqlClient from '../../../configs/graphqlClient';
import * as actions from '../../../redux/actions';
import * as CONSTANT from '../../../utils/constants';

/**
 * @function ManufactoringOrders
 * @memberof MMS/ManufacturingOrder
 * @description หน้าลิสต์รายการคำสั่งผลิต จะลิสต์มาที่ละ 5 อัน (Default) แต่ผู้ใช้สามารถเพิ่มได้
 * ใช้งานได้สองแบบจะมี Card Mode ที่ใช้ใทรศัพท์ กับ Table Mode ที่ใช้ใน Computer
 * Browser จะ Detect ให้เอง หน้านี้มีปัญหาด้านความช้าอยู่พอสมควร มีการใช้หลักการ Query Graphql
 * ไปที่ Server 2 รอบ รอบแรกเอาแค่ข้อมูล MO คร่าวๆ ส่วนครั้งที่2 ถึงไปใสั่งให้เอาข้อมูล Product
 * ต่างๆ มา อย่างร้อย User จะได้เห็นบางอย่างก่อน
 */
function ManufactoringOrders({ title, subtitle }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const manufacturingOrder = useSelector((state) => state.manufacturingOrder);
  const information = useSelector((state) => state.information);
  const me = useSelector((state) => state.me);

  const [searchTerm, setSearchTerm] = useState('');
  const [name, setName] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(5);
  const [total, setTotal] = useState(undefined);
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
  const [selectManufacOrder, setSelectManufacOrder] = useState();
  const [deleteLoadingOpen, setIsDeleteLoadingOpen] = useState(false);
  const [deleteReference, setDeleteReference] = useState();
  const [fullDataMO, setFullDataMO] = useState(null);
  const [useRedis, setIsUseRedis] = useState(true);
  const [selectedView, setSelectedView] = useState(
    window.screen.width >= 768
      ? CONSTANT.DISPLAY_TYPE.TABLE.status_code
      : CONSTANT.DISPLAY_TYPE.CARD.status_code,
  );

  useEffect(() => {
    dispatch(actions.manufacturingOrderReset());

    return () => {};
  }, []);

  const query = gql`
    query FindManufacturingOrder($input: ManufacturingOrderInput) {
      findManufacturingOrders(input: $input) {
        currPage
        lastPage
        total
        rows {
          _id
          id
          assigner {
            firstname
            lastname
          }
          running_number
          customer {
            type_code
            name
            address
            phone_number
          }
          process {
            product {
              type_code
              name
            }
            produce_material_instead
            produce_base_project_instead
            base_project {
              type_code
              name
              unit
            }
            product_as_material {
              type_code
              name
            }
            current {
              index
              step {
                name
              }
            }
            current_step_name
            customer {
              type_code
              name
            }
            other_customers {
              name
            }
            quantity
            price_per_unit
            additional_unit
            remark
          }
          discount
          completed
          deleted
          start_date
          expected_date
          remark
        }
      }
    }
  `;

  const queryDataFromServer = async () => {
    try {
      const queryResult = await graphqlClient.request(query, {
        input: {
          page,
          size,
          name,
          enableFetchInside: false,
          fetchCurrentStep: false,
          fetchCustomer: false,
          fetchMaterial: false,
          fetchProduct: false,
          stage: 'NORMAL',
          fetchProcess: false,
          forceWithoutCache: !useRedis,
        },
      });

      const manufacturingOrderData = queryResult?.findManufacturingOrders;

      dispatch(actions.manufacturingOrderStateSet(manufacturingOrderData));
    } catch (err) {
      console.error('Mo,  Have an errors');
      dispatch(actions.manufacturingOrderReset());
    }
  };

  const callInsideFunction = async () => {
    try {
      console.log('Call Function Inside');
      const insideQuery = await graphqlClient.request(query, {
        input: {
          page,
          size,
          name,
          enableFetchInside: false,
          fetchCurrentStep: false,
          fetchCustomer: true,
          fetchMaterial: true,
          fetchProduct: true,
          fetchBaseProject: true,
          stage: 'NORMAL',
          fetchProcess: true,
          forceWithoutCache: !useRedis,
        },
      });

      const insidedQueryResult = insideQuery?.findManufacturingOrders;
      setFullDataMO(insidedQueryResult);
    } catch (error) {
      console.error(error?.message);
      dispatch(actions.manufacMaterialError());
    }
  };

  useEffect(() => {
    queryDataFromServer();
    console.log('Waiting for time to fetch inside');
    const fetchInsideTimeout = setTimeout(() => {
      callInsideFunction();
    }, 1000);
    return () => {
      console.log('Clear Fetch inside Timeout');
      clearTimeout(fetchInsideTimeout);
    };
  }, [name, page, size, useRedis]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchTerm);
      setPage(1);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  useEffect(() => {
    setTotal(manufacturingOrder?.total);
    return () => {};
  }, [manufacturingOrder]);

  useEffect(() => {
    console.log('Full Data MOs is Toggle');
    if (fullDataMO && manufacturingOrder?.rows) {
      dispatch(actions.manufacturingOrderStateSet(fullDataMO));
    } else {
      console.log('แฮ่ แกเซ็ทไม่ได้หรอก');
    }
    return () => {};
  }, [fullDataMO]);

  const handleSocketIOFunction = {
    onSuccess: () => {
      setIsDeleteLoadingOpen(false);
      window.alert('ยกเลิกคำสั่งผลิตสั่งผลิตสำเร็จ');
      dispatch(actions.manufacturingOrderReset());
      queryDataFromServer();
      setDeleteReference();
    },
    onFail: (args) => {
      setIsDeleteLoadingOpen(false);
      console.log('Error', args?.error);
      window.alert(args?.message);
      dispatch(actions.manufacturingOrderReset());
      queryDataFromServer();
      setDeleteReference();
    },
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;
  const renderSearch = () => (
    <Card>
      <div className="p-4 flex flex-row justify-between">
        <div className="w-8/12 md:w-1/2">
          <TextField
            label="ค้นหา"
            fullWidth
            size={'small'}
            id="outlined-start-adornment"
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <i className="fas fa-search"></i>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className="w-1/4 px-1 flex justify-end">
          <ToggleButtonGroup
            value={selectedView}
            exclusive
            size="small"
            onChange={(event, value) => setSelectedView(value)}
          >
            <ToggleButton value={CONSTANT.DISPLAY_TYPE.TABLE.status_code}>
              <MonitorIcon size="16" />
            </ToggleButton>
            <ToggleButton value={CONSTANT.DISPLAY_TYPE.CARD.status_code}>
              <PhoneIcon size="16" />
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
      </div>
    </Card>
  );

  const handleToInfoPage = (id) => {
    history.push(`manufacturing-orders/view/${id}`);
  };

  const handlePushToEditPage = (id) => {
    history.push(`manufacturing-orders/edit/${id}`);
  };

  const handleToDistributeMaterial = (id) => {
    history.push(`material-distribute/${id}`);
  };

  const handleModalDeleteOpen = (selectManufac) => {
    setSelectManufacOrder(selectManufac);
    setIsModalDeleteOpen(true);
  };

  const handleModalDeleteClose = () => {
    setIsModalDeleteOpen(false);
    setSelectManufacOrder();
  };

  const handleDelete = async (id) => {
    const tempManufacOrder = _.find(
      manufacturingOrder?.rows,
      (_manufacOrder) => _manufacOrder?._id === id,
    );

    handleModalDeleteOpen(tempManufacOrder);
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleCreateProductionLine = (manufacOrderId, productId) => {
    history.push(`create-production-line/${manufacOrderId}/${productId}`);
  };

  const handleStartProcess = async (processId) => {
    const confirm = window.confirm('ยืนยันการเริ่มการผลิต');
    if (confirm) {
      try {
        await dispatch(
          actions.processPut(processId, {
            status: CONSTANT.PROCESS_STATUS.PROCESS_IN_PROGRESS.status_code,
            emplyoeeId: me?.userData?._id,
          }),
        );
        await dispatch(actions.manufacturingOrderAll({ name, page, size }));
      } catch (error) {
        console.error(error);
      }
    }
  };

  const tableProps = {
    handleChangePage,
    handleChangeRowsPerPage,
    handleCreateProductionLine,
    handleDelete,
    handlePushToEditPage,
    handleStartProcess,
    handleToDistributeMaterial,
    handleToInfoPage,
    manufacturingOrder,
    page,
    size,
    total,
  };

  const renderTable = () => (
    <div>
      {selectedView === CONSTANT.DISPLAY_TYPE.TABLE.status_code ? (
        <ManufacturingOrderTable {...tableProps} />
      ) : (
        <CardMOList {...tableProps} />
      )}
    </div>
  );

  const renderDeleteModal = () => (
    <DeleteManufacOrderModal
      handleClose={handleModalDeleteClose}
      isOpen={isModalDeleteOpen}
      manufacturingOrder={selectManufacOrder}
      successHandle={(referenceNumber) => {
        handleModalDeleteClose();
        setIsDeleteLoadingOpen(true);
        setDeleteReference(referenceNumber);
      }}
    />
  );

  const renderAddButton = () => (
    <div className="flex flex-row justify-end pb-4 gap-1">
      <div>
        <Link to="manufacturing-orders/create">
          <Button variant="contained">เพิ่ม</Button>
        </Link>
      </div>
      <div>
        <Link to="manufacturing-orders/plan">
          <Button variant="contained" color="secondary">
            วางแผน
          </Button>
        </Link>
      </div>
      <Button
        variant="contained"
        color="teal"
        startIcon={<RefreshCw size={16} />}
        onClick={() => {
          setIsUseRedis(false);
        }}
      >
        รีเฟรช
      </Button>
    </div>
  );

  if (manufacturingOrder?.isLoading) {
    return <Loading />;
  }

  if (!manufacturingOrder?.isLoading && manufacturingOrder?.isCompleted) {
    return (
      <div>
        <div className="flex justify-between">
          <div>{renderTitle()}</div>
          <div className="mt-6">{renderAddButton()}</div>
        </div>
        <LoadingDialogWithTimeout
          isLoading={deleteLoadingOpen}
          label="กำลังลบคำสั่งผลิต"
          action="REFRESH"
        />
        <SocketIOLoading
          taskCode={CONSTANT.MQ_TASK.UPDATE_MANUFAC_ORDER.status_code}
          referenceNumber={deleteReference}
          handleSuccess={handleSocketIOFunction.onSuccess}
          handleFail={handleSocketIOFunction.onFail}
        />
        {renderDeleteModal()}
        {renderSearch()}
        {renderTable()}
      </div>
    );
  }
  return <Error />;
}

ManufactoringOrders.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

ManufactoringOrders.defaultProps = {
  title: '',
  subtitle: '',
};

export default ManufactoringOrders;
