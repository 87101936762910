import React, { useState } from 'react';
import { Controller, useFieldArray } from 'react-hook-form';
import { useSelector } from 'react-redux';
import {
  Autocomplete,
  Button,
  Chip,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { currencyFormatter } from '@/utils/functions';

import MaterialQuantityDisplay from '../../Box/Material/MaterialQuantityDisplay';
import { MaterialWithOrWithoutLotQuantityInput } from '../../Inputs';

const MaterialWithdrawForm = ({
  control,
  materialTransactionType,
  errors,
  requestFromProcess,
  requireConfirmation,
  watch,
  material,
  setValue,
  showRemark = true,
}) => {
  const information = useSelector((state) => state.information);
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'materialLots',
  });

  const showDesktopMode = window.screen.width >= 768;

  const {
    setting: { ims: imsSetting },
  } = information;
  const [reload, setReload] = useState(false);

  const renderDesktopMode = () => (
    <div className="overflow-x-auto">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>ลำดับที่</TableCell>
            <TableCell>วัตถุดิบ</TableCell>
            <TableCell>
              {/** ถ้ามันเป็นการทำแบบร้องขอ Confirm ไม่ต้องมีการเลือก Stock lots */}
              {!(requestFromProcess && requireConfirmation) &&
                !imsSetting?.usingFIFO && <div>ล็อตวัตถุดิบ</div>}
              {imsSetting?.usingFIFO && <div>จำนวนคงเหลือ</div>}
            </TableCell>

            <TableCell>จำนวนที่เบิก</TableCell>
            <TableCell>ดำเนินการ</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {console.log('Watched Material Lots', watch('materialLots'))}
          {_.map(fields, (_field, index) => (
            <TableRow key={_field?.id}>
              <TableCell>{index + 1}</TableCell>
              <TableCell>
                <div className="w-64">
                  {_field?.fromDatabase === true ? (
                    <div>{_field?.material?.name}</div>
                  ) : (
                    <div>
                      {watch(`materialLots[${index}].tag`) && (
                        <div className="my-2">
                          Tag {watch(`materialLots[${index}].tag`)}
                        </div>
                      )}{' '}
                      <Controller
                        control={control}
                        name={`materialLots[${index}].runOutOff`}
                        defaultValue={false}
                        render={({ field }) => <input {...field} hidden />}
                      />{' '}
                      <Controller
                        control={control}
                        name={`materialLots[${index}].originalMaterial`}
                        render={({ field }) => <input {...field} hidden />}
                      />
                      {watch(`materialLots[${index}].runOutOff`) && (
                        <div className="rounded-md bg-yellow-100 p-2 my-2">
                          วัตถุดิบที่เลือก{' '}
                          <b>
                            {
                              watch(`materialLots[${index}].originalMaterial`)
                                ?.name
                            }
                          </b>
                          ในคลังหมดแล้ว
                          {watch(
                            `materialLots[${index}].createRequestOfMaterial`,
                          ) && (
                            <div className="my-2">
                              <Button
                                variant="contained"
                                color="warning"
                                size="small"
                                onClick={() => {
                                  setValue(
                                    `materialLots[${index}].material`,
                                    watch(`materialLots[${index}]`)
                                      ?.originalMaterial,
                                  );
                                  setValue(
                                    `materialLots[${index}].force`,
                                    true,
                                  );
                                }}
                              >
                                ยืนยันจะเบิกชิ้นนี้
                              </Button>
                            </div>
                          )}
                        </div>
                      )}
                      <Controller
                        control={control}
                        name={`materialLots[${index}].material`}
                        defaultValue={
                          watch(`materialLots[${index}].material`) ||
                          material?.rows?.[0]
                        }
                        // eslint-disable-next-line no-confusing-arrow
                        render={({ field }) =>
                          !_.isEmpty(material?.rows) ? (
                            <Autocomplete
                              size={'small'}
                              disableClearable
                              defaultValue={''}
                              {...field}
                              onChange={(e, newValue) => {
                                if (newValue?.autofetching) {
                                  setValue(
                                    `materialLots[${index}].autofetching`,
                                    true,
                                  );
                                }
                                field.onChange(newValue);
                                setReload(!reload);
                              }}
                              options={material?.rows}
                              getOptionLabel={(option) => {
                                if (option?.type_code) {
                                  return `${option?.type_code} | ${option?.name}`;
                                }
                                if (option?.autofetching) {
                                  return `${option?.type_code || ''} | ${
                                    option?.name
                                  } (WIP)`;
                                }
                                return `${option?.name}`;
                              }}
                              required
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="วัตถุดิบ"
                                  required
                                />
                              )}
                            />
                          ) : (
                            <div></div>
                          )
                        }
                      />
                      {watch(`materialLots[${index}].begin`) &&
                        watch(`materialLots[${index}].begin`) >= 0 && (
                          <div className="my-2">
                            ต้องการ
                            {watch(
                              `materialLots[${index}].reservedMaterialName`,
                            )}{' '}
                            {currencyFormatter.format(
                              watch(`materialLots[${index}].begin`),
                            )}
                            {watch(`materialLots[${index}]`)?.material
                              ?.use_unit_conversion
                              ? ` ${
                                  watch(`materialLots[${index}]`)?.material
                                    ?.unit_input?.name || ''
                                }`
                              : `${
                                  watch(`materialLots[${index}]`)?.material
                                    ?.unit || ''
                                }`}
                          </div>
                        )}
                    </div>
                  )}
                </div>
              </TableCell>
              {/** ถ้ามันเป็นการทำแบบร้องขอ Confirm ไม่ต้องมีการเลือก Stock lots */}
              <TableCell>
                <MaterialQuantityDisplay
                  control={control}
                  dataField={_field}
                  index={index}
                  material={material}
                  requestFromProcess={requestFromProcess}
                  requireConfirmation={requireConfirmation}
                  usingFIFO={imsSetting?.usingFIFO}
                  watch={watch}
                />
              </TableCell>

              <TableCell>
                <MaterialWithOrWithoutLotQuantityInput
                  control={control}
                  errors={errors}
                  fieldData={_field}
                  imsSetting={imsSetting}
                  index={index}
                  material={material}
                  requestFromProcess={requestFromProcess}
                  requireConfirmation={requireConfirmation}
                  watch={watch}
                  setValue={setValue}
                />
              </TableCell>
              <TableCell>
                <div className="w-16 flex flex-col gap-2">
                  <Button
                    size="small"
                    variant="contained"
                    color="error"
                    disabled={_field?.fromDatabase === true}
                    onClick={() => remove(index)}
                  >
                    ลบ
                  </Button>
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );

  const renderMobileMode = () => (
    <div>
      {_.map(fields, (_field, index) => (
        <div key={_field?.id}>
          <div> รายการที่ {index + 1}</div>
          <div className="w-full my-2">
            {_field?.fromDatabase === true ? (
              <div>{_field?.material?.name}</div>
            ) : (
              <Controller
                control={control}
                name={`materialLots[${index}].material`}
                defaultValue={
                  watch(`materialLots[${index}].material`) ||
                  material?.rows?.[0]
                }
                render={({ field }) => (
                  <Autocomplete
                    size={'small'}
                    disableClearable
                    defaultValue={''}
                    fullWidth
                    {...field}
                    onChange={(e, newValue) => {
                      field.onChange(newValue);
                      setReload(!reload);
                    }}
                    options={material?.rows}
                    getOptionLabel={(option) => {
                      if (option?.type_code) {
                        return `${option?.type_code} | ${option?.name}`;
                      }
                      return `${option?.name}`;
                    }}
                    required
                    renderInput={(params) => (
                      <TextField {...params} label="วัตถุดิบ" required />
                    )}
                  />
                )}
              />
            )}
          </div>

          {/** ถ้ามันเป็นการทำแบบร้องขอ Confirm ไม่ต้องมีการเลือก Stock lots */}
          <div className="w-full py-2">
            <MaterialQuantityDisplay
              control={control}
              dataField={_field}
              index={index}
              material={material}
              requestFromProcess={requestFromProcess}
              requireConfirmation={requireConfirmation}
              usingFIFO={imsSetting?.usingFIFO}
              watch={watch}
            />
          </div>

          <div className="w-full py-2">
            <MaterialWithOrWithoutLotQuantityInput
              control={control}
              errors={errors}
              fieldData={_field}
              imsSetting={imsSetting}
              index={index}
              material={material}
              requestFromProcess={requestFromProcess}
              requireConfirmation={requireConfirmation}
              watch={watch}
              setValue={setValue}
            />
          </div>
          <div>
            <div className="w-16 flex flex-col gap-2">
              <Button
                size="small"
                variant="contained"
                color="error"
                disabled={_field?.fromDatabase === true}
                onClick={() => remove(index)}
              >
                ลบ
              </Button>
            </div>
          </div>
          <hr className="my-2" />
        </div>
      ))}
    </div>
  );
  const filteredTxnType = _.filter(
    materialTransactionType?.rows,
    (_txnType) => _txnType?.direction === 'desc',
  );
  return (
    <div>
      {' '}
      <Controller
        control={control}
        name="material_transaction_type"
        rules={{ require: true }}
        defaultValue={filteredTxnType?.[0]?._id}
        render={({ field }) => (
          <FormControl fullWidth size="small" required>
            <InputLabel>ประเภทการขอเบิก</InputLabel>
            <Select
              size="small"
              required
              {...field}
              label="ประเภทการขอเบิก"
              disabled={_.size(filteredTxnType) === 1}
            >
              {_.map(filteredTxnType, (_txntype, index) => (
                <MenuItem key={index} value={_txntype?._id}>
                  {_txntype?.name}
                </MenuItem>
              ))}
            </Select>
            {errors?.material_transaction_type && (
              <FormHelperText>กรุณาเลือกประเภทการขอเบิก</FormHelperText>
            )}
          </FormControl>
        )}
      />
      <div className="flex flex-row justify-between my-2 items-center">
        <h4 className="mt-4 font-semibold font-display">วัตถุดิบที่ต้องการ</h4>
        <div>
          <Button size="small" variant="contained" color="warning">
            เคลียร์
          </Button>
        </div>
      </div>
      {showDesktopMode ? renderDesktopMode() : renderMobileMode()}
      <div className="my-1">
        <Button
          size="small"
          variant="contained"
          color="secondary"
          onClick={() => {
            try {
              append({
                material: material?.rows?.[0],
                quantity: 0,
                lotId: '',
              });
            } catch (err) {
              console.log(err?.message);
            }
          }}
        >
          เพิ่มวัตถุดิบ
        </Button>
      </div>
      {showRemark && (
        <div className="my-2 mt-6">
          <Controller
            control={control}
            name="remark"
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                multiline
                rows={3}
                fullWidth
                label="หมายเหตุ"
              />
            )}
          />{' '}
        </div>
      )}
    </div>
  );
};

MaterialWithdrawForm.propTypes = {
  control: PropTypes.object,
  materialTransactionType: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  errors: PropTypes.object,
  requestFromProcess: PropTypes.bool,
  requireConfirmation: PropTypes.bool,
  watch: PropTypes.func,
  material: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  setValue: PropTypes.func,
  showRemark: PropTypes.bool,
};

export default MaterialWithdrawForm;
