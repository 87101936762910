import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { gql } from 'graphql-request';
import PropTypes from 'prop-types';

import {
  BackButton,
  Error,
  Loading,
  MMSDashboardRenderingComponent,
  ViewTitle,
} from '@/components';
import graphqlClient from '@/configs/graphqlClient';
import * as actions from '@/redux/actions';

/**
 * @function ViewMMSDashboard
 * @description Display a collections or a list of ViewMMSDashboard from database
 */

export default function ViewMMSDashboard({ title, subtitle }) {
  const dispatch = useDispatch();
  const mmsDashboard = useSelector((state) => state.mmsDashboard);
  const history = useHistory();
  const params = useParams();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);

  const query = gql`
    query FindViewMMSDashboard($mmsDashboardInput: MMSDashboardInput) {
      findOneMMSDashboard(input: $mmsDashboardInput) {
        _id
        name
        is_mo_level
        elements {
          index
          dashboard_element {
            _id
            name
            dashboard_type
            is_mo_level
            elements {
              name
              index
              source_type
            }
          }
          height
          width
        }
      }
    }
  `;

  const queryDataFromServer = async () => {
    try {
      const queryResult = await graphqlClient.request(query, {
        mmsDashboardInput: { id: params.id },
      });
      const mmsDashboardData = queryResult?.findOneMMSDashboard;
      dispatch(actions.mmsDashboardStateOneSet(mmsDashboardData));
    } catch (error) {
      dispatch(actions.mmsDashboardError());
    }
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [params]);

  const renderTitle = () => (
    <ViewTitle
      title={`${title} ${mmsDashboard?.name || ''}`}
      subtitle={subtitle}
    />
  );

  if (mmsDashboard.isLoading) {
    return <Loading />;
  }

  if (!mmsDashboard.isLoading && mmsDashboard.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <BackButton />
        <div>
          <div className="my-2 font-semibold text-lg font-display">
            ตัวอย่างแดชบอร์ด
          </div>
          <MMSDashboardRenderingComponent
            isExample
            isMoLevel={mmsDashboard?.is_mo_level}
            mmsDashboard={mmsDashboard}
          />
        </div>
      </div>
    );
  }
  return <Error />;
}

ViewMMSDashboard.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

ViewMMSDashboard.defaultProps = {
  title: '',
  subtitle: '',
};
