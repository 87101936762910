import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { Card, Button } from '@mui/material';
import { useParams } from 'react-router';
import PropTypes from 'prop-types';
import * as actions from '../../redux/actions';
import { BackButton } from '../../components/Button';
import { ProductTypeForm } from '../../components/Forms';
import { Loading } from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';

export default function EditProductType({ title, subtitle }) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const productType = useSelector((state) => state.productType);
  const {
    formState: { errors },
    handleSubmit,
    control,
  } = useForm({
    defaultValues: {
      name: productType.name,
      description: productType.description,
    },
  });

  useEffect(() => {
    dispatch(actions.productTypeGet(id));
    return () => {};
  }, []);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const onSubmit = async (data) => {
    try {
      console.log(data);
      await dispatch(actions.productTypePut(id, data));
      alert('สำเร็จ');
      await dispatch(actions.productTypeGet(id));
    } catch (error) {
      console.log(error);
    }
  };
  if (productType.isLoading || productType.rows) {
    return <Loading />;
  }
  if (!productType.isLoading && productType.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div className="flex flex-row justify-start pb-4">
          <div>
            <BackButton />
          </div>
        </div>
        <div>
          <Card className="p-4">
            <form onSubmit={handleSubmit(onSubmit)}>
              <ProductTypeForm
                errors={errors}
                productType={productType}
                control={control}
                Controller={Controller}
              />
              <div className="flex flex-row justify-end gap-1 py-4">
                <Button variant="contained" type="submit">
                  บันทึก
                </Button>
              </div>
            </form>
          </Card>
        </div>
      </div>
    );
  }
  return <Error />;
}

EditProductType.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

EditProductType.defaultProps = {
  title: '',
  subtitle: '',
};
