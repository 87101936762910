/* eslint-disable import/no-named-as-default-member */
import React, { useState } from 'react';
import {
  Box as BoxIcon,
  Check as CheckIcon,
  CheckSquare as CheckSquareIcon,
  Clipboard as ClipboardIcon,
  CornerDownLeft as CornerDownLeftIcon,
  GitPullRequest as GitPullRequestIcon,
  Play as PlayIcon,
  SkipForward as SkipForwardIcon,
} from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import {
  Alert,
  AlertTitle,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import dayjs from 'dayjs';
import _ from 'lodash';
import hash from 'object-hash';
import PropTypes from 'prop-types';

import { SocketIOLoading } from '@/components';
import {
  MANUFACTURING_TRANSACTION_TYPE,
  MATERIAL_COMING_METHOD,
  MQ_TASK,
} from '@/utils/constants';
import { HandleNextStep } from '@/views/MMS/Process';

import AllPluginInfo from '../../../plugins';
import * as actions from '../../../redux/actions';
import { MMS } from '../../../utils/functions';
import { DesktopDateTimepicker } from '../../DatePicker';
import { LoadingDialog } from '../../Loading';
import MaterialWithdrawInfoTable from '../../Tables/MMS/MaterialWithdrawInfoTable';

const ProcessControlBox = ({
  process,
  currentStep,
  justify = 'end',
  container = true,
}) => {
  const dispatch = useDispatch();
  const information = useSelector((state) => state.information);
  const me = useSelector((state) => state.me);

  const history = useHistory();
  const {
    setting: { mms: mmsSetting },
  } = information;

  const {
    ProcessStatusCheck,
    handleStartProcess,
    handleCloseWork,
    handleToPurchase,
    MaterialAnalyzer: { unNullManufacMaterialAnalyzedArrayAnalzer },
  } = MMS;

  const [handleProdDialogOpen, setHandleProdDialogOpen] = useState(false);
  const [handleStartOpen, setHandleStartOpen] = useState(false);
  const [startTime, setStartTime] = useState(dayjs().toDate());
  const [referenceNumber, setReferenceNumber] = useState();
  const [isLoadingDialogOn, setIsLoadingDialogOn] = useState(false);
  const processStatus = ProcessStatusCheck(
    process,
    currentStep,
    information,
    me,
  );

  const fetchManufacturingOrder = async () => {
    await dispatch(
      actions.manufacturingOrderGet(
        process?.manufacturing_order?._id || process?.manufacturing_order,
      ),
    );
  };

  const socketioHandleFunction = {
    onSuccess: () => {
      setIsLoadingDialogOn(false);
      fetchManufacturingOrder();
      window.alert('บันทึกข้อมูลให้กระบวนการผลิตสำเร็จ');
    },
    onFail: (args) => {
      setIsLoadingDialogOn(false);
      console.log('Error', args?.error);
      window.alert(`บันทึกข้อมูลไม่สำเร็จ ${args?.message}`);
    },
  };

  const sendServerToStartProcess = async () => {
    await handleStartProcess({
      dispatch,
      currentProcess: process,
      employeeId: me?.userData?._id,
      handleSuccess: fetchManufacturingOrder,
      timestamp: startTime,
    });

    setHandleStartOpen(false);
  };

  const allDisplayPlugin = _.map(process?.steps, (eachStep) => ({
    plugin: eachStep?.plugin,
    use_plugin_instead: eachStep?.use_plugin_instead,
  }));

  const usedPlugin = _.filter(allDisplayPlugin, { use_plugin_instead: true });

  const handleYieldChange = (step, nextStep = null) => {
    if (
      step?.material_associate ||
      !_.isEmpty(step?.measurements) ||
      nextStep?.is_process_splitting_point ||
      step?.is_process_merging_point
    ) {
      console.log(
        'มีการเป็น Splitting Point',
        nextStep?.is_process_splitting_point,
      );
      history.push(
        `/mms/process/handle-next-step/${process?._id}?fetch-txn=true`,
      );
    } else {
      const confirm = window.confirm('ยืนยันการเลื่อนไปขั้นตอนถัดไป');
      const tempRefNo = hash({ user: me?.userData?._id, date: new Date() });
      setReferenceNumber(tempRefNo);
      if (confirm) {
        setIsLoadingDialogOn(true);
        const payload = {
          process: process?._id,
          materials: _.map(
            unNullManufacMaterialAnalyzedArrayAnalzer(
              process?.manufacturing_materials,
            ),
            (_material) => ({
              material: _material?.materialInfo?._id,
              end: _material?.resultAmount,
              wastes: 0,
            }),
          ),
          employeeId: me?.userData?._id,
          manufacturing_transaction_type:
            MANUFACTURING_TRANSACTION_TYPE.WORK_CLOSE.status_code,
          referenceNumber: tempRefNo,
        };
        console.log('Next Step Payload', payload);
        dispatch(actions.stepPost(payload));
      }
    }
  };

  const handleClose = async () => {
    if (
      !processStatus.step.isCurrentStepComplete &&
      (currentStep?.material_associate || !_.isEmpty(currentStep?.measurements))
    ) {
      history.push(
        `/mms/process/handle-next-step/${process?._id}?fetch-txn=true`,
      );
    } else if (
      process?.current?.step &&
      !processStatus.step.isCurrentStepComplete
    ) {
      // ถ้ายังไม่ปิด ให้ปรับให้ Step สุดท้ายเป็น Complete
      await handleCloseWork(dispatch, process, me?.userData?._id, async () => {
        await fetchManufacturingOrder();
        setHandleProdDialogOpen(true);
      });
    } else {
      setHandleProdDialogOpen(true);
    }
  };

  // ปรับเป็นสถานะ รอจำหน่าย
  const handlePurchase = async () => {
    setHandleProdDialogOpen(false);
    await handleToPurchase(
      dispatch,
      process,
      me?.userData?._id,
      fetchManufacturingOrder,
    );
  };

  // เช็คว่าอยู่ใน Step สุดท้ายหรือไม่ Step สุดท้าย Complete แล้วหรือไม่
  const renderLatestStepStatus = () => {
    if (processStatus.processStatus.isGreatestComplete) {
      return (
        <div className="my-2">
          {container ? (
            <Card>
              <CardContent>
                <div className="flex gap-2 justify-center">
                  <div className="bg-green-500 rounded-full p-2 w-min text-white">
                    <CheckIcon size={16} />
                  </div>
                  <div className="text-center self-center">
                    ไลน์การผลิตนี้ดำเนินการสำเร็จแล้ว
                  </div>
                </div>
              </CardContent>{' '}
            </Card>
          ) : (
            <div className="flex gap-2 justify-center">
              <div className="bg-green-500 rounded-full p-2 w-min text-white">
                <CheckIcon size={16} />
              </div>
              <div className="text-center self-center">
                ไลน์การผลิตนี้ดำเนินการสำเร็จแล้ว
              </div>
            </div>
          )}
        </div>
      );
    }
    return <div></div>;
  };

  // สำหรับการยังไม่เริ่มต้น Process
  const renderMaterialRequestInfo = () => {
    if (!processStatus.processStatus.isReceiveAllMaterial) {
      return (
        <div>
          {container ? (
            <Card>
              <CardContent>
                <Alert severity="warning">
                  <AlertTitle>
                    ยังไม่สามารถเริ่มการผลิตได้
                    เนื่องจากการขอเบิกวัตถุดิบยังไม่แล้วเสร็จ
                  </AlertTitle>
                  <Card className="rounded-lg  border p-2">
                    <MaterialWithdrawInfoTable
                      currentMaterialRequest={
                        processStatus.currentMaterialRequest
                      }
                    />
                  </Card>
                </Alert>
              </CardContent>
            </Card>
          ) : (
            <Alert severity="warning">
              <AlertTitle>
                ยังไม่สามารถเริ่มการผลิตได้
                เนื่องจากการขอเบิกวัตถุดิบยังไม่แล้วเสร็จ
              </AlertTitle>
              <Card className="rounded-lg  border p-2">
                <MaterialWithdrawInfoTable
                  currentMaterialRequest={processStatus.currentMaterialRequest}
                />
              </Card>
            </Alert>
          )}
        </div>
      );
    }

    return <div></div>;
  };

  const renderAddAsMaterial = () => {
    if (information?.setting?.mms?.productionHandler?.addAsMaterial) {
      if (information?.setting?.mms?.productionHandler?.addToRightTypeOnly) {
        if (process?.produce_material_instead) {
          return (
            <Button
              variant="contained"
              color="teal"
              type="button"
              startIcon={<i className="fas fa-pallet"></i>}
              onClick={() =>
                history.push(`/mms/process/add-inventory/${process?._id}`)
              }
            >
              <span className="font-display">เพิ่มลงคลังวัตถุดิบ</span>
            </Button>
          );
        }
        return <div></div>;
      }

      return (
        <Button
          variant="contained"
          color="teal"
          type="button"
          startIcon={<i className="fas fa-pallet "></i>}
          onClick={() =>
            history.push(`/mms/process/add-inventory/${process?._id}`)
          }
        >
          <span className="font-display">เพิ่มลงคลังวัตถุดิบ </span>
        </Button>
      );
    }
    return <div></div>;
  };

  const renderAddWarehouse = () => {
    if (information?.setting?.mms?.productionHandler?.addToWarehouse) {
      if (information?.setting?.mms?.productionHandler?.addToRightTypeOnly) {
        if (!process?.produce_material_instead) {
          return (
            <Button
              variant="contained"
              color="primary"
              type="button"
              startIcon={<i className="fas fa-warehouse"></i>}
              onClick={() => {
                setHandleProdDialogOpen(false);
                if (mmsSetting?.verifiedWarehouseAdd) {
                  history.push(
                    `/mms/process/request-add-warehouse/${process?._id}`,
                  );
                } else {
                  history.push(`/mms/process/add-warehouse/${process?._id}`);
                }
              }}
            >
              <span className="font-display">เพิ่มลงในคลังสินค้า</span>
            </Button>
          );
        }
        return <div></div>;
      }

      return (
        <Button
          variant="contained"
          color="primary"
          type="button"
          startIcon={<i className="fas fa-warehouse"></i>}
          onClick={() => {
            setHandleProdDialogOpen(false);
            if (mmsSetting?.verifiedWarehouseAdd) {
              history.push(
                `/mms/process/request-add-warehouse/${process?._id}`,
              );
            } else {
              history.push(`/mms/process/add-warehouse/${process?._id}`);
            }
          }}
        >
          <span className="font-display">เพิ่มลงในคลังสินค้า</span>
        </Button>
      );
    }
    return <div></div>;
  };

  const renderAddAsMaterialOnHandleProductDialog = () => {
    if (information?.setting?.mms?.productionHandler?.addAsMaterial) {
      if (information?.setting?.mms?.productionHandler?.addToRightTypeOnly) {
        if (process?.produce_material_instead) {
          return (
            <Button
              variant="contained"
              color="teal"
              type="button"
              onClick={() =>
                history.push(`/mms/process/add-inventory/${process?._id}`)
              }
              fullWidth
            >
              <span className="font-display">เพิ่มลงคลังวัตถุดิบ </span>
            </Button>
          );
        }
        return <div></div>;
      }

      return (
        <Button
          variant="contained"
          color="teal"
          type="button"
          onClick={() =>
            history.push(`/mms/process/add-inventory/${process?._id}`)
          }
        >
          <span className="font-display">เพิ่มลงคลังวัตถุดิบ </span>
        </Button>
      );
    }
    return <div></div>;
  };
  const renderAddWarehouseOnHandleProductDialog = () => {
    if (information?.setting?.mms?.productionHandler?.addToWarehouse) {
      if (information?.setting?.mms?.productionHandler?.addToRightTypeOnly) {
        if (!process?.produce_material_instead) {
          return (
            <Button
              variant="contained"
              color="success"
              type="button"
              className="w-1/3 px-2"
              onClick={() => {
                setHandleProdDialogOpen(false);
                if (mmsSetting?.verifiedWarehouseAdd) {
                  history.push(
                    `/mms/process/request-add-warehouse/${process?._id}`,
                  );
                } else {
                  history.push(`/mms/process/add-warehouse/${process?._id}`);
                }
              }}
            >
              <span className="font-display">เพิ่มลงในคลังสินค้า</span>
            </Button>
          );
        }
        return <div></div>;
      }

      return (
        <Button
          variant="contained"
          color="success"
          type="button"
          className="w-1/3 px-2"
          onClick={() => {
            setHandleProdDialogOpen(false);
            if (mmsSetting?.verifiedWarehouseAdd) {
              history.push(
                `/mms/process/request-add-warehouse/${process?._id}`,
              );
            } else {
              history.push(`/mms/process/add-warehouse/${process?._id}`);
            }
          }}
        >
          <span className="font-display">เพิ่มลงในคลังสินค้า</span>
        </Button>
      );
    }
    return <div></div>;
  };

  const handleProductDialog = () => (
    <Dialog
      open={handleProdDialogOpen}
      onClose={() => setHandleProdDialogOpen(false)}
    >
      <DialogTitle>
        {information?.setting?.mms?.productionHandler?.productionHandlerName ||
          'ดำเนินการกับผลผลิต'}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          ต้องการดำเนินการกับผลผลิตด้วยวิธีการใด
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <div className="flex justify-center flex-wrap gap-2">
          {information?.setting?.mms?.productionHandler?.addToWarehouse &&
            renderAddWarehouseOnHandleProductDialog()}
          {information?.setting?.mms?.productionHandler?.markAsFinished && (
            <Button
              variant="contained"
              color="success"
              type="button"
              className="w-1/3 px-2"
              onClick={() => handlePurchase()}
            >
              <div className="font-display">ปิดการผลิต</div>
            </Button>
          )}
          {information?.setting?.mms?.productionHandler?.openBill && (
            <Button
              variant="contained"
              color="secondary"
              type="button"
              className="w-1/3 px-2 "
              onClick={() => handlePurchase()}
            >
              <span className="font-display">ยืนยันการเปิดบิล</span>
            </Button>
          )}
          {information?.setting?.mms?.productionHandler?.addAsMaterial &&
            renderAddAsMaterialOnHandleProductDialog()}
          <Button
            variant="contained"
            color="inherit"
            className="w-1/3 px-2"
            onClick={() => {
              setHandleProdDialogOpen(false);
            }}
          >
            <span className="font-display">ยกเลิก</span>
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );

  const handleStartWorkDialog = () => (
    <Dialog open={handleStartOpen} onClose={() => setHandleStartOpen(false)}>
      <DialogTitle>เริ่มการผลิต</DialogTitle>
      <DialogContent>
        {mmsSetting?.timing?.manuallyTimeStamping ? (
          <div className="min-w-60">
            <div className="py-4">ระบุเวลาเริ่มการผลิต</div>
            <DesktopDateTimepicker
              fullWidth
              label="เวลาที่เริ่มทำงาน"
              setValue={setStartTime}
              value={startTime}
            />{' '}
          </div>
        ) : (
          <div>
            {' '}
            <div className="my-2 min-w-60">ยืนยันการเริ่มผลิต</div>
          </div>
        )}
        <div className="my-2">
          <Button
            variant="contained"
            onClick={() => sendServerToStartProcess()}
          >
            ยืนยันการเริ่ม
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );

  const renderMaterialIncomingButton = () => {
    const selectedButton =
      process?.current?.step?.material_incoming_method ===
      MATERIAL_COMING_METHOD.RECIEVE.status_code ? (
        <Link
          to={`/mms/process/recieve-material/${process?._id}/${process?.current?.step?._id}`}
          className="full-width"
        >
          <Button variant="contained" color="cyan" fullWidth>
            <ClipboardIcon size={16} className="mr-2" />
            <span className="font-display"> รับวัตถุดิบ</span>
          </Button>
        </Link>
      ) : (
        <Link
          to={`/mms/process/withdraw-material/${process?._id}`}
          className="full-width"
        >
          <Button variant="contained" color="warning" fullWidth>
            <ClipboardIcon size={16} className="mr-2" />
            <span className="font-display"> เบิกวัตถุดิบ</span>
          </Button>
        </Link>
      );

    return (
      <div>
        {processStatus.allow.allowRequestMaterial &&
          !processStatus.processStatus.isRequireVerifyRequest &&
          selectedButton}
      </div>
    );
  };

  const renderControlBox = () => {
    if (process?.completed === false) {
      return (
        <div
          className={`flex my-4 gap-2 justify-${justify} flex-col md:flex-row`}
        >
          <Button
            onClick={() => setHandleStartOpen(true)}
            disabled={!processStatus.allow.allowHandleStart}
            variant="contained"
            color="success"
          >
            <PlayIcon size={16} className="mr-2" />
            <span className="font-display"> เริ่มการผลิต </span>
          </Button>{' '}
          {renderMaterialIncomingButton()}
          {processStatus.allow.allowRequestMaterial &&
            //     processStatus.processStatus.isReqMaterialInProcess &&
            processStatus.processStatus.isRequireVerifyRequest && (
              <Link
                to={`/mms/process/request-withdraw-material/${process?._id}`}
                className="full-width"
              >
                <Button variant="contained" color="warning" fullWidth>
                  <ClipboardIcon size={16} className="mr-2" />
                  <span className="font-display"> ขอเบิกวัตถุดิบ</span>
                </Button>
              </Link>
            )}
          <Link
            to={`/mms/process/return-material/${process?._id}`}
            className="full-width"
            hidden={!processStatus.allow.allowReturnMaterial}
          >
            <Button
              disabled={!processStatus.allow.allowReturnMaterial}
              variant="contained"
              fullWidth
              color="primary"
            >
              <CornerDownLeftIcon size={16} className="mr-2" />
              <span className="font-display"> คืนของ</span>
            </Button>
          </Link>
          {_.map(usedPlugin, (eachPlugin, index) => {
            const foundedPlugin = _.find(
              AllPluginInfo,
              (eachFounedPlugin) => eachPlugin?.plugin === eachFounedPlugin?.id,
            );

            const pluginOnStep = _.find(
              process?.steps,
              (eachStep) => eachStep?.plugin === eachPlugin?.plugin,
            );

            const foundedPluginIndex = _.findIndex(
              process?.steps,
              (eachStep) => eachStep?.plugin === eachPlugin?.plugin,
            );

            return (
              <Button
                onClick={() => {
                  history.push(
                    `/mms/works/plugin/edit/${eachPlugin?.plugin}/${pluginOnStep?._id}`,
                  );
                }}
                disabled={
                  !processStatus.allow.allowSkipStep ||
                  process?.current?.index < foundedPluginIndex
                }
                variant="contained"
                color="info"
                key={index}
              >
                <BoxIcon size={16} className="mr-2" />
                <span className="font-display">
                  {foundedPlugin?.buttonTitle || ''}
                </span>
              </Button>
            );
          })}
          <Button
            onClick={() =>
              handleYieldChange(
                process?.current?.step,
                _.find(
                  process?.steps,
                  (each) => each?.index === process?.current?.index + 1,
                ),
              )
            }
            disabled={!processStatus.allow.allowSkipStep}
            variant="contained"
            color="error"
          >
            <SkipForwardIcon size={16} className="mr-2" />
            <span className="font-display">
              {' '}
              ไปขั้นตอนถัดไป
              {/* {_.find(
                process?.steps,
                (each) => each?.index === process?.current?.index + 1,
              )?.name || 'ถัดไป'} */}
            </span>
          </Button>
          {process?.is_minor_process ? (
            <Button
              // eslint-disable-next-line no-confusing-arrow
              onClick={() => handleYieldChange(process?.current?.step, null)}
              disabled={!processStatus.allow.allowStopProcess}
              variant="contained"
              color="secondary"
            >
              <CheckSquareIcon size={16} className="mr-2" />
              <span className="font-display">รวมกับไลน์ผลิตหลัก</span>
            </Button>
          ) : (
            <Button
              // eslint-disable-next-line no-confusing-arrow
              onClick={() => handleClose()}
              disabled={!processStatus.allow.allowStopProcess}
              variant="contained"
              color="secondary"
            >
              <CheckSquareIcon size={16} className="mr-2" />
              <span className="font-display">
                {information?.setting?.mms?.productionHandler
                  ?.productionHandlerName || 'ดำเนินการกับผลผลิต'}
              </span>
            </Button>
          )}
          {process?.is_during_minor_process && !process?.is_minor_process && (
            <Button
              variant="contained"
              color="error"
              onClick={() =>
                history.push(`/mms/process/split-more-process/${process?._id}`)
              }
            >
              <GitPullRequestIcon size={16} className="mr-2" />
              <span className="font-display">แบ่งไลน์ผลิตเพิ่ม</span>
            </Button>
          )}
          {mmsSetting?.showHandleProductInEveryStep &&
            process?.completed === false &&
            renderAddAsMaterial()}
          {mmsSetting?.showHandleProductInEveryStep &&
            process?.completed === false &&
            renderAddWarehouse()}
        </div>
      );
    }
    return <div></div>;
  };

  return (
    <div className="mt-2">
      <div>
        <LoadingDialog
          isLoading={isLoadingDialogOn}
          label="กำลังเลื่อนขั้นตอนของงาน"
        />
        <SocketIOLoading
          referenceNumber={referenceNumber}
          taskCode={MQ_TASK.MOVING_STEP_UP.status_code}
          handleFail={socketioHandleFunction.onFail}
          handleSuccess={socketioHandleFunction.onSuccess}
        />
        {handleProductDialog()}
        {handleStartWorkDialog()}
        {renderMaterialRequestInfo()}
        {renderLatestStepStatus()}
        {renderControlBox()}
      </div>
    </div>
  );
};

export default ProcessControlBox;

ProcessControlBox.propTypes = {
  process: PropTypes.object,
  currentStep: PropTypes.object,
  justify: PropTypes.string,
  container: PropTypes.bool,
};
