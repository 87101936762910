import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import {
  Alert,
  AlertTitle,
  Button,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
} from '@mui/material';
import dayjs from 'dayjs';
import BuddhistCalendar from 'dayjs/plugin/buddhistEra';
import _ from 'lodash';
import PropTypes from 'prop-types';

import 'dayjs/locale/th';

import { BackButton } from '../../../components/Button';
import { ViewTitle } from '../../../components/ViewTitle';
import * as actions from '../../../redux/actions';
import { MMS } from '../../../utils/functions';

dayjs.extend(BuddhistCalendar);
dayjs.locale('th');

const SelectEditMaterialUsage = ({ title, subtitle }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const process = useSelector((state) => state.process);

  const {
    MaterialAnalyzer: { unNullManufacMaterialAnalyzedArrayAnalzer },
  } = MMS;
  const analyzedMaterial = unNullManufacMaterialAnalyzedArrayAnalzer(
    process?.manufacturing_materials,
  );

  useEffect(() => {
    const fetchProcess = async () => {
      try {
        await dispatch(actions.processGet(params.processid));
      } catch (error) {
        console.error(error);
      }
    };

    fetchProcess();
    return () => {};
  }, [params]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;
  return (
    <div>
      {renderTitle()}

      <div className="flex flex-row justify-between pb-4">
        <div>
          <BackButton />
        </div>
      </div>
      <Card>
        <CardContent>
          <div>เลือกวัตถุดิบที่ต้องการแก้ไข</div>
          <div className="">
            <List>
              {_.map(analyzedMaterial, (eachMaterial, index) => (
                <div key={index}>
                  <ListItem>
                    <ListItemText
                      primary={`${eachMaterial?.materialInfo?.type_code}
                ${eachMaterial?.materialInfo?.name}`}
                    />{' '}
                    <Link
                      to={`/mms/material-usage/edit/${process?._id}/${eachMaterial?.materialInfo?._id}`}
                    >
                      <Button variant="contained">แก้ไข</Button>
                    </Link>
                  </ListItem>
                </div>
              ))}
            </List>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

SelectEditMaterialUsage.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

SelectEditMaterialUsage.defaultProps = {
  title: '',
  subtitle: '',
};

export default SelectEditMaterialUsage;
