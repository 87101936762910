/* eslint-disable camelcase */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Card,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import axios from 'axios';

import { BackButton } from '../../components/Button';
import { ViewTitle } from '../../components/ViewTitle';
import * as actions from '../../redux/actions';
import Loading from '../../components/Loading';
import { Error } from '../../components/Error';

function ReportProblem({ title, subtitle }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const me = useSelector((state) => state.me);
  const {
    formState: { errors },
    handleSubmit,
    control,
  } = useForm({ defaultValues: me?.userData?.uid });

  useEffect(() => {
    dispatch(actions.meGet());

    return () => {};
  }, []);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const onSubmit = async (data) => {
    try {
      const issuePayload = {
        title: data.title,
        labels: [data.part, 'bug'],
        body: data.detail,
      };
      const { response } = await axios({
        headers: {
          accept: 'application/vnd.github.v3+json',
          Authorization: `token ${process.env.REACT_APP_GITHUB_TOKEN}`,
        },
        method: 'POST',
        url: `/${process.env.REACT_APP_GITHUB_OWNER}/${process.env.REACT_APP_GITHUB_REPO}/issues`,
        baseURL: 'https://api.github.com/repos',
        data: issuePayload,
      });

      window.alert('ขอขอบคุณที่แจ้งปัญหาเข้ามา เราจะรีบแก้ปัญหาให้ท่านโดยเร็ว');

      history.push('/profile/main');
    } catch (error) {
      alert(error.message);
      console.log(error);
    }
  };

  const renderForm = () => (
    <div className="w-full ">
      <Card className="p-4 ">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-wrap">
            <div className="w-full my-2">
              <Controller
                control={control}
                name="title"
                rules={{ require: true }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    size="small"
                    label="หัวข้อปัญหา"
                    required
                  />
                )}
              />
            </div>
            <div className="w-full my-2">
              <Controller
                control={control}
                name="part"
                rules={{ require: true }}
                render={({ field }) => (
                  <FormControl fullWidth>
                    <InputLabel>ส่วนที่พบปัญหา</InputLabel>
                    <Select
                      fullWidth
                      {...field}
                      size="small"
                      label="ส่วนที่พบปัญหา"
                    >
                      <MenuItem value="dashboard">แดชบอร์ด</MenuItem>
                      <MenuItem value="pms">ฝ่ายจัดซื้อ</MenuItem>
                      <MenuItem value="spm">ฝ่ายขาย</MenuItem>
                      <MenuItem value="mms">การผลิต</MenuItem>
                      <MenuItem value="ims">คลังวัตถุดิบ</MenuItem>
                      <MenuItem value="wms">คลังสินค้า</MenuItem>
                      <MenuItem value="crm">ลูกค้าสัมพันธ์</MenuItem>
                      <MenuItem value="hrms">ทรัพยากรบุคคล</MenuItem>
                      <MenuItem value="setting">การตั้งค่าระบบ</MenuItem>
                      <MenuItem value="others">อื่น ๆ</MenuItem>
                    </Select>
                  </FormControl>
                )}
              />
            </div>
            <div className="w-full my-2">
              <Controller
                control={control}
                name="detail"
                rules={{ require: true }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    multiline
                    rows={3}
                    label="รายละเอียดของปัญหา"
                    required
                  />
                )}
              />
            </div>
          </div>

          <Button variant="contained" type="submit">
            บันทึก
          </Button>
        </form>
      </Card>
    </div>
  );

  if (me.isLoading) {
    return <Loading />;
  }
  if (!me.isLoading && me.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div className="flex flex-row justify-start pb-4">
          <div>
            <BackButton />
          </div>
        </div>
        {renderForm()}
      </div>
    );
  }
  return <Error />;
}
ReportProblem.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

ReportProblem.defaultProps = {
  title: '',
  subtitle: '',
};

export default ReportProblem;
