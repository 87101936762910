import { TextField } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

export function MaterialTypeForm({
 errors, materialType, Controller, control
}) {
  return (
    <div className="flex flex-row flex-wrap">
      <div className="w-full px-1 py-2">
        <Controller
          name={'name'}
          control={control}
          defaultValue={materialType ? materialType.name : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="ชื่อประเภทวัตถุดิบ"
              fullWidth
              size={'small'}
              required
              helperText={errors.name && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'description'}
          control={control}
          defaultValue={materialType ? materialType.description : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="รายละเอียด"
              fullWidth
              size={'small'}
              helperText={errors.description && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
    </div>
  );
}

MaterialTypeForm.propTypes = {
  errors: PropTypes.shape({
    name: PropTypes.object,
    description: PropTypes.object,
  }),
  materialType: PropTypes.object,
  Controller: PropTypes.func,
  control: PropTypes.object,
};

MaterialTypeForm.defaultProps = {
  materialType: null,
};

export default MaterialTypeForm;
