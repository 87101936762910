import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import {
  Card,
  CardContent,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import thLocale from 'date-fns/locale/th';
import dayjs from 'dayjs';
import { gql } from 'graphql-request';
import _ from 'lodash';
import PropTypes from 'prop-types';

import {
  Error,
  ExportExcelContainer,
  Loading,
  MultiUnitProdOrMatBox,
  ViewTitle,
} from '../../components';
import graphqlClient from '../../configs/graphqlClient';
import * as actions from '../../redux/actions';
import { MaterialUtil } from '../../utils/modelutils';

const MaterialSummaryOnEachType = ({
  selectedMonth,
  materialTransactionType,
}) => {
  const [page] = useState(1);
  const [size] = useState(10);
  const [selectedResult, setSelectedResult] = useState([]);

  const query = gql`
    query FindMaterialSummaryHistory(
      $materialTransactionInput: MaterialTransactionInput
    ) {
      findMaterialTransactions(input: $materialTransactionInput) {
        currPage
        lastPage
        total
        inAnalysis {
          material {
            _id
            type_code
            name
            unit
            unit_input {
              _id
              name
              short_sign
            }
            unit_output {
              _id
              name
              short_sign
            }
            use_unit_conversion
            conversion_from {
              unit_a
              unit_b
              conversion_factor
            }
            conversion_to {
              unit_a
              unit_b
              conversion_factor
            }
          }
          quantity
        }
        outAnalysis {
          material {
            _id
            type_code
            name
            unit
            unit_input {
              _id
              name
              short_sign
            }
            unit_output {
              _id
              name
              short_sign
            }
            use_unit_conversion
            conversion_to {
              unit_a
              unit_b
              conversion_factor
            }
            conversion_from {
              unit_a
              unit_b
              conversion_factor
            }
          }
          quantity
        }
      }
    }
  `;

  const queryDataFromServer = async () => {
    const queryResult = await graphqlClient.request(query, {
      materialTransactionInput: {
        page,
        size,
        month: dayjs(selectedMonth)?.format('YYYY-MM-DD'),
        selectType: materialTransactionType?._id,
      },
    });
    const materialTransactionData = queryResult?.findMaterialTransactions;
    if (materialTransactionType?.direction === 'add') {
      setSelectedResult(materialTransactionData.inAnalysis);
    } else {
      setSelectedResult(materialTransactionData.outAnalysis);
    }
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [page, size, selectedMonth]);

  const renderMaterialTable = (materialQuantityArray) => (
    <Paper>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <div className="font-bold">ลำดับที่</div>
            </TableCell>
            <TableCell>
              <div className="font-bold">วัตถุดิบ</div>
            </TableCell>
            <TableCell>
              <div className="font-bold">จำนวน</div>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {_.map(materialQuantityArray, (eachMaterialQuantity, index) => (
            <TableRow key={index}>
              <TableCell>{index + 1}</TableCell>
              <TableCell>
                {eachMaterialQuantity?.material?.type_code || ''}{' '}
                {eachMaterialQuantity?.material?.name || ''}{' '}
              </TableCell>
              <TableCell>
                <MultiUnitProdOrMatBox
                  foundMaterial={eachMaterialQuantity?.material}
                  quantity={eachMaterialQuantity?.quantity}
                />{' '}
              </TableCell>
            </TableRow>
          ))}
          {_.isEmpty(materialQuantityArray) && (
            <TableRow>
              <TableCell colSpan={4}>
                <div className="text-center">ไม่มีรายการวัตถุดิบ</div>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Paper>
  );

  return (
    <div>
      <div className="flex gap-2 my-2">
        <div className="font-display font-semibold text-lg  ">
          <div>{materialTransactionType?.name}</div>
        </div>
        <div className="self-center">
          <ExportExcelContainer
            columnList={MaterialUtil.columnWithMaterialDouble}
            currentData={selectedResult}
            sheetName="MaterialTransactionOnType"
            disableAllExport
            buttonSize="small"
          />
        </div>
      </div>

      {renderMaterialTable(selectedResult)}
    </div>
  );
};

/**
 * @function MaterialSummaryReport
 * @description Display a collections or a list of MaterialSummaryHistory from database
 */

export default function MaterialSummaryReport({ title, subtitle }) {
  const dispatch = useDispatch();
  const materialTransactionType = useSelector(
    (state) => state.materialTransactionType,
  );

  const [selectedMonth, setSelectedMonth] = useState(dayjs().toDate());

  useEffect(() => {
    dispatch(actions.materialTransactionTypeAll({}));

    return () => {};
  }, []);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const renderSearch = () => (
    <div className="my-2">
      <Card>
        <CardContent>
          เลือกเดือน
          <div className="flex">
            <div className="w-full  md:w-5/12 md:pl-1 my-2 md:my-0">
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                locale={thLocale}
              >
                <DatePicker
                  views={['year', 'month']}
                  label="เดือน"
                  minDate={new Date('2012-03-01')}
                  maxDate={new Date('2030-06-01')}
                  value={selectedMonth}
                  onChange={(newValue) => {
                    setSelectedMonth(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField fullWidth {...params} size={'small'} />
                  )}
                />
              </LocalizationProvider>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );

  if (materialTransactionType.isLoading) {
    return <Loading />;
  }
  if (
    !materialTransactionType.isLoading &&
    materialTransactionType.isCompleted
  ) {
    return (
      <div>
        {renderTitle()}
        {renderSearch()}
        <div className="flex flex-wrap  py-2">
          {_.map(
            _.orderBy(materialTransactionType?.rows, 'direction'),
            (eachTransactionType, index) => (
              <div className="w-full lg:w-1/2  px-2 py-1" key={index}>
                <MaterialSummaryOnEachType
                  materialTransactionType={eachTransactionType}
                  selectedMonth={selectedMonth}
                />
              </div>
            ),
          )}
        </div>
      </div>
    );
  }
  return <Error />;
}

MaterialSummaryReport.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

MaterialSummaryReport.defaultProps = {
  title: '',
  subtitle: '',
};

MaterialSummaryOnEachType.propTypes = {
  selectedMonth: PropTypes.object,
  materialTransactionType: PropTypes.object,
};
