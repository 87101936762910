import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import {
  Button,
  Card,
  CardContent,
  ListItem,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import {
  BackButton,
  BrachingStepTimeline,
  Error,
  Loading,
  StepBox,
  VerticleBranchingStepTimeline,
  ViewTitle,
} from '@/components';

import * as actions from '../../../redux/actions';

const DetailProcessTemplate = ({ title, subtitle }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const processTemplate = useSelector((state) => state.processTemplate);
  const [chartView, setChartView] = useState('horizontal');

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  useEffect(() => {
    const getProcessTemplate = async () => {
      try {
        await dispatch(actions.processTemplateGet(params.id));
      } catch (error) {
        console.error(error);
      }
    };

    getProcessTemplate();
    return () => {};
  }, [params]);

  if (processTemplate?.isLoading) {
    return <Loading />;
  }

  if (!processTemplate?.isLoading && processTemplate?.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div className="flex flex-row justify-between items-center pb-4">
          <div>
            <BackButton />
          </div>
          <div>
            <Link to={`../edit/${params.id}`}>
              <Button color="warning" variant="contained">
                แก้ไข
              </Button>
            </Link>
          </div>
        </div>
        <div>
          <Card className="p-4">
            {' '}
            <div className="flex flex-wrap ">
              <div className="w-full md:w-1/6  py-2 px-2 font-semibold font-display ">
                {processTemplate?.name}
              </div>
            </div>
          </Card>
          <div className="flex justify-between my-2">
            <div className="my-4 font-semibold font-display ">
              แผนผังการทำงาน
            </div>
            <div>
              <div className="flex justify-end">
                <ToggleButtonGroup
                  value={chartView}
                  size="small"
                  exclusive
                  onChange={(event, newChartView) => {
                    if (newChartView !== null) {
                      setChartView(newChartView);
                    }
                  }}
                  aria-label="text alignment"
                >
                  <ToggleButton value="horizontal" aria-label="horizontal">
                    แนวนอน
                  </ToggleButton>
                  <ToggleButton value="verticle" aria-label="verticle">
                    แนวตั้ง
                  </ToggleButton>
                </ToggleButtonGroup>
              </div>
            </div>
          </div>

          {!_.isEmpty(processTemplate?.steps) && (
            <Card className="my-2">
              <CardContent>
                <div className="min-h-full border">
                  {chartView === 'horizontal' ? (
                    <BrachingStepTimeline
                      processSteps={processTemplate?.steps}
                    />
                  ) : (
                    <VerticleBranchingStepTimeline
                      processSteps={processTemplate?.steps}
                    />
                  )}
                </div>
              </CardContent>
            </Card>
          )}

          <div className="my-4 font-semibold font-display ">ขั้นตอนการทำ</div>

          <Card>
            <CardContent>
              <ListItem>
                <div className="w-full">
                  {!_.isEmpty(processTemplate?.steps) &&
                    processTemplate?.steps?.map((eachStep, index) => (
                      <div
                        className="w-full flex border rounded-md my-3"
                        key={index}
                      >
                        <StepBox
                          eachStep={eachStep}
                          index={index}
                          deleteAble={false}
                        />
                      </div>
                    ))}
                  {_.isEmpty(processTemplate?.steps) && (
                    <div>
                      <div className="text-center">
                        ยังไม่ได้ระบุวิธีการผลิตสินค้าสำหรับสินค้าชิ้นนี้
                      </div>
                    </div>
                  )}
                </div>
              </ListItem>
            </CardContent>
          </Card>
        </div>
      </div>
    );
  }

  return <Error />;
};

DetailProcessTemplate.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

DetailProcessTemplate.defaultProps = {
  title: '',
  subtitle: '',
};
export default DetailProcessTemplate;
