import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useParams } from 'react-router';
import PropTypes from 'prop-types';
import _ from 'lodash';
import dayjs from 'dayjs';
import * as actions from '../../redux/actions';
import { BackButton } from '../../components/Button';
import { Loading } from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';

export default function DetailOrder({ title, subtitle }) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const order = useSelector((state) => state.order);

  useEffect(() => {
    dispatch(actions.orderGet(id));
    return () => {};
  }, []);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const displayProduct = () => (
    <TableContainer>
      <Table size="medium" className="border-lg mt-2">
        <TableHead className="bg-blue-100">
          <TableRow>
            <TableCell>
              <div className="font-bold">ลำดับที่</div>
            </TableCell>
            <TableCell>
              <div className="font-bold"> ชื่อสินค้า</div>
            </TableCell>
            <TableCell>
              <div className="font-bold"> จำนวน</div>
            </TableCell>
            <TableCell>
              <div className="font-bold"> ราคาขาย</div>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!_.isEmpty(order?.product_of_order) &&
            order?.product_of_order.map((_product, index) => (
              <TableRow key={index}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>
                  <b>{_product?.product?.type_code}</b>{' '}
                  {_product?.product?.name}
                </TableCell>
                <TableCell>
                  {_product?.quantity || 0} {_product?.product?.unit}
                </TableCell>
                <TableCell> {_product?.price || 0} บาท</TableCell>
              </TableRow>
            ))}
          {!_.isEmpty(order?.material_of_order) &&
            order?.material_of_order.map((_material, index) => (
              <TableRow key={index}>
                <TableCell>
                  {index + _.size(order?.product_of_order) + 1}
                </TableCell>
                <TableCell>
                  <b>{_material?.material?.type_code}</b>{' '}
                  {_material?.material?.name}
                </TableCell>
                <TableCell>
                  {_material?.quantity || 0} {_material?.material?.unit}
                </TableCell>
                <TableCell> {_material?.price || 0} บาท</TableCell>
              </TableRow>
            ))}
          {_.isEmpty(order?.product_of_order) &&
            _.isEmpty(order?.material_of_order) && (
              <TableRow key={''}>
                <TableCell colSpan={4}>
                  <div className="text-center">ไม่มีข้อมูล</div>
                </TableCell>
              </TableRow>
            )}
        </TableBody>
        <TableBody>
          <TableCell colSpan={3}>
            {' '}
            <div className="flex justify-end">ทั้งหมด</div>
          </TableCell>
          <TableCell>{order?.commerce?.price} บาท</TableCell>
        </TableBody>
        <TableBody>
          <TableCell colSpan={3}>
            {' '}
            <div className="flex justify-end">ส่วนลด</div>
          </TableCell>
          <TableCell>{order?.commerce?.discount} บาท</TableCell>
        </TableBody>
        <TableBody>
          <TableCell colSpan={3}>
            {' '}
            <div className="flex justify-end">ราคารวม</div>
          </TableCell>
          <TableCell>{order?.commerce?.total_price} บาท</TableCell>
        </TableBody>
      </Table>
    </TableContainer>
  );

  if (order.isLoading || order.rows) {
    return <Loading />;
  }
  if (!order.isLoading && order.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div className="flex flex-row justify-start pb-4">
          <div>
            <BackButton />
          </div>
        </div>
        <div>
          <Card className="p-4 ">
            <div className="flex flex-wrap divide-y">
              <div className="w-full py-4 text-lg font-semibold font-display ">
                {'รายละเอียด'}
              </div>
              <div className="w-1/2 py-4 px-2 font-semibold font-display ">
                {'วันที่ขาย'}
              </div>
              <div className="w-1/2 py-4 ">
                {' '}
                {dayjs(order?.commerce?.sale_date)
                  .locale('th')
                  .format('DD MMM BBBB')}
              </div>
              <div className="w-1/2 py-4 px-2 font-semibold font-display ">
                {'วันที่กำหนดจัดส่ง'}
              </div>
              <div className="w-1/2 py-4 ">
                {dayjs(order?.expected_date).locale('th').format('DD MMM BBBB')}
              </div>
              <div className="w-1/2 py-4 px-2 font-semibold font-display ">
                {'เลขที่คำสั่งซื้อ'}
              </div>
              <div className="w-1/2 py-4 ">{`${
                order?.running_number || '-'
              }`}</div>
              <div className="w-1/2 py-4 px-2 font-semibold font-display ">
                {'ประเภท'}
              </div>
              <div className="w-1/2 py-4 ">{`${order?.product_transaction_type?.name}`}</div>

              <div className="w-1/2 py-4 px-2 font-semibold font-display ">
                {'ลูกค้า'}
              </div>
              <div className="w-1/2 py-4">
                <div className="bg-gray-100 p-3 rounded-md text-sm">
                  <div>
                    <b>ชื่อ : </b> {order?.customer?.name || ' - '}
                  </div>
                  <div>
                    <b>อีเมล : </b>
                    {order?.customer?.email || ' - '}
                  </div>
                  <div>
                    <b>โทร : </b>
                    {order?.customer?.phone_number || ' - '}
                  </div>
                  <div>
                    <b>ที่อยู่ : </b>
                    {order?.customer?.address || ' - '}
                  </div>
                </div>
              </div>
              <div className="w-1/2 py-4 px-2 font-semibold font-display ">
                {'การชำระเงิน'}
              </div>
              <div className="w-1/2 py-4 ">{`${order?.commerce?.payment?.name}`}</div>

              <div className="w-1/2 py-4 px-2 font-semibold font-display ">
                {'ประเภทการจัดส่ง'}
              </div>
              <div className="w-1/2 py-4 ">{`${order?.delivery?.shipping?.name}`}</div>
              {order?.tracking_number ? (
                <>
                  <div className="w-1/2 py-4 px-2 font-semibold font-display ">
                    {'หมายเลขติดตามสินค้า'}
                  </div>
                  <div className="w-1/2 py-4 ">{`${order?.delivery?.tracking_number}`}</div>
                </>
              ) : (
                <></>
              )}
              {order?.tracking_number ? (
                <>
                  <div className="w-1/2 py-4 px-2 font-semibold font-display ">
                    {'ค่าจัดส่ง'}
                  </div>
                  <div className="w-1/2 py-4 ">{`${order?.delivery?.shipping_cost}`}</div>
                </>
              ) : (
                <></>
              )}
              <div className="w-1/2 py-4 px-2 font-semibold font-display ">
                {'ชื่อพนักงานขาย'}
              </div>
              <div className="w-1/2 py-4 ">{`${order?.commerce?.seller}`}</div>
              <div className="w-1/2 py-4 px-2 font-semibold font-display ">
                {'หมายเหตุ'}
              </div>
              <div className="w-1/2 py-4 ">{`${order?.remark}`}</div>
            </div>
            <div className="w-full  py-4 ">{displayProduct()}</div>
          </Card>
        </div>
      </div>
    );
  }
  return <Error />;
}

DetailOrder.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

DetailOrder.defaultProps = {
  title: '',
  subtitle: '',
};
