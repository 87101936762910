import React, { useEffect, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import {
  Autocomplete,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { MATERIAL_STATUS } from '../../../utils/constants';

export function SelectMaterialForm({
  material,
  setSelectMaterial,
  materialRequest,
}) {
  const [reload, setReload] = useState(false);
  const materialOrder = materialRequest?.material
    ? _.map(materialRequest?.material, function (mapMaterial) {
        return {
          material: mapMaterial.material,
          status: mapMaterial.status,
          quantity: mapMaterial.quantity,
        };
      })
    : [
        {
          material: material[0],
          status: MATERIAL_STATUS.PENDING_PURCHASE.status_code,
          quantity: 0,
        },
      ];
  const {
    control,
    formState: { errors },
    getValues,
  } = useForm({
    defaultValues: {
      select_material: materialOrder || [
        {
          material: material[0],
          status: MATERIAL_STATUS.PENDING_PURCHASE.status_code,
          quantity: 0,
        },
      ],
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'select_material',
  });

  useEffect(() => {
    setSelectMaterial(getValues('select_material'));
    return () => {};
  }, [reload, fields]);

  const displayMaterial = () => (
    <TableContainer>
      <Table size="small" className="border-lg">
        <colgroup>
          <col width="10%" />
          <col width="50%" />
          <col width="30%" />
          <col width="10%" />
        </colgroup>
        <TableHead className="bg-blue-100">
          <TableRow>
            <TableCell>
              <div className="font-bold">ลำดับที่</div>
            </TableCell>
            <TableCell>
              <div className="font-bold"> วัตถุดิบ</div>
            </TableCell>
            <TableCell>
              <div className="font-bold"> จำนวน</div>
            </TableCell>
            <TableCell>
              <div className="font-bold"> ดำเนินการ</div>
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {_.size(fields) ? (
            fields.map((_field, index) => (
              <TableRow key={_field.id}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>
                  <div className="w-full px-1 py-2">
                    <div className="w-full px-1">
                      <Controller
                        name={`select_material.[${index}].material`}
                        control={control}
                        rules={{ required: true }}
                        defaultValue={material[0]}
                        render={({ field }) => (
                          <Autocomplete
                            size={'small'}
                            disableClearable
                            {...field}
                            onChange={(e, newValue) => {
                              field.onChange(newValue);
                              setReload(!reload);
                            }}
                            options={material}
                            getOptionLabel={(option) =>
                              `${option?.type_code} | ${option?.name}`
                            }
                            required
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="วัตถุดิบ"
                                required
                              />
                            )}
                          />
                        )}
                      />
                    </div>
                  </div>
                </TableCell>
                <TableCell>
                  <Controller
                    name={`select_material.[${index}].quantity`}
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        onChange={(e) => {
                          field.onChange(e.target.value);
                          setReload(!reload);
                        }}
                        label="จำนวน"
                        fullWidth
                        type={'Number'}
                        required
                        inputProps={{
                          min: 0,
                          style: { textAlign: 'center' },
                        }}
                        size={'small'}
                        helperText={errors.quantity && 'กรุณาใส่ข้อมูล'}
                      />
                    )}
                  />
                </TableCell>
                <TableCell>
                  <Button
                    color={'error'}
                    variant="contained"
                    size={'small'}
                    onClick={() => remove(index)}
                  >
                    ลบ
                  </Button>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={4}>
                <div className="text-center py-2">ไม่มีรายการ</div>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );

  return (
    <div>
      {displayMaterial()}{' '}
      <div className="px-2 py-2">
        <Button
          color={'success'}
          variant="outlined"
          size={'small'}
          onClick={() =>
            append({
              material: material[0],
              status: MATERIAL_STATUS.PENDING_PURCHASE.status_code,
              quantity: 0,
            })
          }
        >
          เพิ่มรายการ
        </Button>
      </div>
    </div>
  );
}

SelectMaterialForm.propTypes = {
  material: PropTypes.object,
  setSelectMaterial: PropTypes.func,
  materialRequest: PropTypes.object,
};

SelectMaterialForm.defaultProps = {
  material: null,
  setSelectMaterial: null,
  materialRequest: null,
};

export default SelectMaterialForm;
