import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  TextField,
} from '@mui/material';
import { gql } from 'graphql-request';
import _ from 'lodash';
import hash from 'object-hash';
import PropTypes from 'prop-types';

import {
  BackButton,
  Error,
  Loading,
  LoadingDialogWithTimeout,
  MultiUnitProdOrMatBox,
  ProdOrMatQuantityInput,
  SocketIOLoading,
  ViewTitle,
} from '@/components';
import { config } from '@/configs';
import graphqlClient from '@/configs/graphqlClient';
import * as actions from '@/redux/actions';
import { MANUFACTURING_TRANSACTION_TYPE, MQ_TASK } from '@/utils/constants';
import { MMS } from '@/utils/functions';

/**
 * @function SplitMoreProcess
 * @description Display a collections or a list of HandleNextStep from database
 */

export default function SplitMoreProcess({ title, subtitle }) {
  const dispatch = useDispatch();
  const selectedProcess = useSelector((state) => state.process);
  const machine = useSelector((state) => state.machine);
  const me = useSelector((state) => state.me);
  const information = useSelector((state) => state.information);
  const history = useHistory();
  const [loadingDialogOn, setIsLoadingDialogOn] = useState(false);
  const [referenceNumber, setReferenceNumber] = useState('');

  const params = useParams();
  const query = gql`
    query FindHandleNextStep(
      $processInput: ProcessInput
      $machineInput: MachineInput
    ) {
      findOneProcess(input: $processInput) {
        _id
        current {
          step {
            _id
            index
            name
            completed
            deleted
            responsible {
              employee {
                _id
                firstname
                lastname
              }
              department {
                _id
                name
              }
            }
            material_incoming_method
            material_associate
            is_process_merging_point
            is_process_splitting_point
            measurements {
              _id
              measurement_type {
                _id
                name
                description
                data_type
                type_code
                default_value
                label_left
                label_right
                default_boolean_value
                label_on_additional_text
                required
              }
              value
            }
          }
          index
        }
        manufacturing_materials {
          _id
          material {
            _id
            name
            type_code
            use_unit_conversion
            unit
            conversion_from {
              unit_a
              unit_b
              conversion_factor
            }
            conversion_to {
              unit_b
              unit_a
              conversion_factor
            }
            unit_input {
              _id
              short_sign
              name
            }
            unit_output {
              _id
              short_sign
              name
            }
            common_price
          }
          transaction_type
          begin
          quantity
          amount
        }
        completed
        deleted
        product {
          steps {
            name
          }
        }
        main_process
        is_minor_process
      }
      findMachines(input: $machineInput) {
        rows {
          _id
          name
        }
      }
    }
  `;

  const {
    MaterialAnalyzer: { unNullManufacMaterialAnalyzedArrayAnalzer },
  } = MMS;

  const queryDataFromServer = async () => {
    const queryResult = await graphqlClient.request(query, {
      processInput: {
        id: params.id,
        fetchInsideProcess: true,
        fetchCurrentStep: true,
      },
      machineInput: {
        page: 1,
        size: config.maxFetchSize,
      },
    });
    const processData = queryResult?.findOneProcess;
    const machineData = queryResult?.findMachines;
    dispatch(actions.processStateOneSet(processData));
    dispatch(actions.machineStateSet(machineData));
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, []);

  const { control, handleSubmit, watch, setValue } = useForm();

  useEffect(() => {
    const newManufacMatAnalyze = unNullManufacMaterialAnalyzedArrayAnalzer(
      selectedProcess?.manufacturing_materials,
    );

    const newMaterials = _.map(newManufacMatAnalyze, (_material) => {
      const eachNewMaterialData = {
        begin: _material?.resultAmount,
        end: _material?.resultAmount,
        return: _material?.returnAmount,
        material: _material?.materialInfo,
      };
      return eachNewMaterialData;
    });

    const filterNewMaterial = _.filter(
      newMaterials,
      (each) => each?.begin !== 0,
    );
    setValue('materials', filterNewMaterial);
    // setValue('materials', newMaterials);

    return () => {};
  }, [selectedProcess?.manufacturing_materials]);

  const handleSocketIOFunction = {
    onSuccess: () => {
      setIsLoadingDialogOn(false);

      window.alert('บันทึกข้อมูลให้กระบวนการผลิตสำเร็จ');
      history.goBack();
      queryDataFromServer();
    },
    onFail: (args) => {
      setIsLoadingDialogOn(false);
      console.log('Error', args?.error);
      window.alert(`บันทึกข้อมูลไม่สำเร็จ ${args?.message}`);
      queryDataFromServer();
    },
  };

  const handleCreateMoreLine = async (data) => {
    try {
      const tempRefNo = hash({
        date: new Date(),
        user: me?.userData?._id,
      });

      setReferenceNumber(tempRefNo);
      setIsLoadingDialogOn(true);

      const payload = {
        process: selectedProcess?._id,
        materials: _.map(
          unNullManufacMaterialAnalyzedArrayAnalzer(
            selectedProcess?.manufacturing_materials,
          ),
          (_material) => ({
            material: _material?.materialInfo?._id,
            end: _material?.resultAmount,
            wastes: 0,
          }),
        ),
        employeeId: me?.userData?._id,
        manufacturing_transaction_type:
          MANUFACTURING_TRANSACTION_TYPE.PROCESS_UPDATE.status_code,
        referenceNumber: tempRefNo,
        ...data,
      };
      console.log('Next Step Payload', payload);
      await dispatch(actions.processSplitMoreProcess(payload));
      // await dispatch(actions.processGet(selectedProcess?._id));
    } catch (err) {
      alert(`เกิดข้อผิดพลาดเกิดขึ้น ${err?.message}`);
      setIsLoadingDialogOn(false);
    }
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (selectedProcess.isLoading) {
    return <Loading />;
  }

  if (!selectedProcess.isLoading && selectedProcess.isCompleted) {
    return (
      <div>
        <LoadingDialogWithTimeout
          action="REFRESH"
          label="กำลังสร้างไลน์การผลิตใหม่"
          isLoading={loadingDialogOn}
        />
        {renderTitle()}
        <div className="my-2">
          <BackButton />
        </div>
        <SocketIOLoading
          taskCode={MQ_TASK.PROCESS_MORE_SPLITTING.status_code}
          referenceNumber={referenceNumber}
          handleSuccess={handleSocketIOFunction.onSuccess}
          handleFail={handleSocketIOFunction.onFail}
        />
        <div className="my-2">
          <Card>
            <CardContent>
              <div className="font-semibold font-display">
                วัตถุดิบที่มีอยู่
              </div>
              <div className="grid grid-cols-2 lg:grid-cols-5 my-2">
                {_.map(watch('materials'), (eachMaterial, index) => (
                  <div key={index} className="p-1">
                    <div className="border p-2 rounded-md">
                      <div>
                        {eachMaterial?.material?.type_code}{' '}
                        {eachMaterial?.material?.name}
                      </div>
                      <div>
                        <MultiUnitProdOrMatBox
                          foundMaterial={eachMaterial?.material}
                          quantity={eachMaterial?.end}
                          noWrap
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="font-semibold font-display">
                ไลน์การผลิตใหม่ที่จะสร้างขึ้น
              </div>
              <div className=" my-2">
                <form onSubmit={handleSubmit(handleCreateMoreLine)}>
                  <div className="flex flex-wrap">
                    <div className="w-full">
                      <div className="my-2 font-semibold">เครื่องจักร</div>
                      {!_.isEmpty(machine?.rows) && (
                        <Controller
                          control={control}
                          name="machine"
                          render={({ field }) => (
                            <Autocomplete
                              {...field}
                              disablePortal
                              options={machine?.rows}
                              size="small"
                              placeholder="เลือกเครื่องจักร"
                              onChange={(e, newValue) => {
                                field.onChange(newValue);
                              }}
                              className="z-40"
                              // prettier-ignore
                              getOptionLabel={(option) => `${option?.name || ''}`
                    }
                              renderInput={(inputParams) => (
                                <TextField
                                  label="เครื่องจักร"
                                  {...inputParams}
                                />
                              )}
                            />
                          )}
                        />
                      )}
                    </div>
                    <div className="w-full my-2">
                      <div className="my-2 font-semibold">
                        วัตถุดิบที่จะได้รับ
                      </div>
                      {_.map(watch('materials'), (material, materialIndex) => (
                        <div
                          key={materialIndex}
                          className="flex items-center my-2"
                        >
                          <div className="w-1/3">
                            {material?.material?.name}
                            <div className="hidden">
                              <Controller
                                control={control}
                                name={`splitted_line_material[${materialIndex}].material`}
                                defaultValue={material?.material?._id}
                                render={(field) => <input {...field} />}
                              />
                            </div>
                          </div>
                          <div className="w-1/2">
                            {/** FIXME: ใส่การลิมิตจำนวน */}
                            <ProdOrMatQuantityInput
                              controllerName={`splitted_line_material[${materialIndex}].amount`}
                              artificialControllerName={`splitted_line_material[${materialIndex}].artificial`}
                              selectedUnitControllerName={`splitted_line_material[${materialIndex}].selected_unit`}
                              setValue={setValue}
                              watch={watch}
                              control={control}
                              selectedProduct={material?.material}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="flex justify-end">
                    <Button variant="contained" type="submit">
                      บันทึก
                    </Button>
                  </div>
                </form>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    );
  }
  return <Error />;
}

SplitMoreProcess.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

SplitMoreProcess.defaultProps = {
  title: '',
  subtitle: '',
};
