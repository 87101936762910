import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Button, Card, CardContent } from '@mui/material';
import dayjs from 'dayjs';
import BuddhistCalendar from 'dayjs/plugin/buddhistEra';
import { gql } from 'graphql-request';
import _ from 'lodash';
import PropTypes from 'prop-types';

import 'dayjs/locale/th';

import { BackButton } from '../../../components/Button';
import { Error } from '../../../components/Error';
import { EmployeeAssignmentForm } from '../../../components/Forms';
import { Loading } from '../../../components/Loading';
import { ViewTitle } from '../../../components/ViewTitle';
import { app as appConfig } from '../../../configs';
import * as actions from '../../../redux/actions';
import { MMS } from '../../../utils/functions';

dayjs.extend(BuddhistCalendar);
dayjs.locale('th');

/**
 * @function AssignManufacturingOrder
 * @memberof MMS/ManufacturingOrder
 * @description หน้าสำหรับการ Assign งานให้กับพนักงานแต่ละคน หรือ เป็นหน้าที่ใช้
 * ในการ Adjust อีกทีว่า Step งานเป็นยังไง มันจะเข้ามาถึงทันที
 * หลังจากที่สร้างคำสั่งผลิตเสร็จ
 */

const AssignManufacturingOrder = ({ title, subtitle }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();
  const { handleProcessWorkAssignment } = MMS;
  const manufacturingOrder = useSelector((state) => state.manufacturingOrder);
  const department = useSelector((state) => state.department);
  const employee = useSelector((state) => state.employee);
  const me = useSelector((state) => state.me);
  const information = useSelector((state) => state.information);
  const measurementType = useSelector((state) => state.measurementType);
  const resultingFunctionType = useSelector(
    (state) => state.resultingFunctionType,
  );

  const {
    setting: { mms: mmsSetting },
  } = information;

  useEffect(() => {
    const getManufacturingOrder = async () => {
      try {
        await dispatch(actions.manufacturingOrderGet(params.id));
      } catch (error) {
        console.error(error);
      }
    };

    const fetchEmployee = async () => {
      try {
        await dispatch(actions.employeeAll({}));
      } catch (error) {
        console.error(error);
      }
    };

    const fetchDepartment = async () => {
      try {
        await dispatch(actions.departmentAll({}));
      } catch (error) {
        console.error(error);
      }
    };

    const fetchMeasurementType = async () => {
      try {
        await dispatch(
          actions.measurementTypeAll({
            name: '',
            page: 1,
            size: appConfig.maxFetchSize,
          }),
        );
      } catch (error) {
        console.error('Measurement type fetch all error', error);
      }
    };

    const fetchResultingFunctionType = async () => {
      try {
        const query = gql`
        query FindResultingFunctionTypes {
          findResultingFunctionTypes(input:{page:1,size:${appConfig.maxFetchSize}}){
            rows {
              _id
              name
            }
          }
        }
        `;
        await dispatch(actions.resultingFunctionTypeAllQuery({ query }));
      } catch (error) {
        console.error(error);
      }
    };

    fetchResultingFunctionType();
    getManufacturingOrder();
    fetchEmployee();
    fetchDepartment();
    fetchMeasurementType();
    return () => {};
  }, [params]);

  // UI Rendering

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const RenderEachProcess = ({ selectedProcess }) => {
    const { control, handleSubmit, watch, setValue } = useForm({
      defaultValues: {
        steps: _.isEmpty(selectedProcess?.steps)
          ? _.map(selectedProcess?.product?.steps, (_step) => ({
              ..._step,
              _id: undefined,
            }))
          : _.orderBy(selectedProcess?.steps, ['index'], ['asc']),
      },
    });
    const [isAssigned, setIsAssigned] = useState(false);

    const onSubmit = async (data) => {
      try {
        await handleProcessWorkAssignment(
          dispatch,
          selectedProcess?._id,
          data,
          me?.userData?._id,
        );
        setIsAssigned(true);
      } catch (error) {
        console.error(error);
      }
    };

    if (isAssigned) {
      return (
        <div className="my-2">
          <Card>
            <CardContent>
              {' '}
              <div className="flex justify-between flex-wrap">
                {selectedProcess?.produce_base_project_instead &&
                  (selectedProcess?.produce_material_instead ? (
                    <h4 className="text-lg mx-2">
                      <span className="bg-gray-200 p-1 px-2 rounded-md font-bold">
                        {selectedProcess?.product_as_material?.type_code}{' '}
                        {selectedProcess?.product_as_material?.name}
                      </span>{' '}
                      {selectedProcess?.quantity > 0 && (
                        <span className="">
                          จำนวน {selectedProcess?.quantity}{' '}
                          {selectedProcess?.additonal_unit ||
                            selectedProcess?.product_as_material?.unit}{' '}
                        </span>
                      )}
                    </h4>
                  ) : (
                    <h4 className="text-lg mx-2">
                      <span className="bg-gray-200 p-1 px-2 rounded-md font-bold">
                        {selectedProcess?.product?.type_code}{' '}
                        {selectedProcess?.product?.name}
                      </span>{' '}
                      {selectedProcess?.quantity > 0 && (
                        <span className="">
                          จำนวน {selectedProcess?.quantity}{' '}
                          {selectedProcess?.additonal_unit ||
                            selectedProcess?.product?.unit}{' '}
                        </span>
                      )}
                    </h4>
                  ))}{' '}
                {selectedProcess?.produce_base_project_instead && (
                  <h4 className="text-lg mx-2">
                    <span className="bg-gray-200 p-1 px-2 rounded-md font-bold">
                      {selectedProcess?.base_project?.type_code}{' '}
                      {selectedProcess?.base_project?.name}
                    </span>{' '}
                    {selectedProcess?.quantity > 0 && (
                      <span className="">
                        จำนวน {selectedProcess?.quantity}{' '}
                        {selectedProcess?.base_project?.unit}{' '}
                      </span>
                    )}
                  </h4>
                )}
              </div>
              <div className="p-4 flex justify-center  text-lg">
                <div className="text-center">
                  <p className="text-xl text-green-500 font-semibold font-display">
                    มอบหมายงานเรียบร้อย
                  </p>
                  <p
                    className="text-sm text-blue-500 hover:text-blue-700 cursor-pointer hover:underline"
                    onClick={() => setIsAssigned(false)}
                  >
                    มอบหมายงานใหม่
                  </p>
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
      );
    }
    return (
      <div className="my-2 ">
        <Card>
          <CardContent>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="flex justify-between flex-wrap">
                {!selectedProcess?.produce_base_project_instead &&
                  (selectedProcess?.produce_material_instead ? (
                    <h4 className="text-lg mx-2">
                      <span className="bg-gray-200 p-1 px-2 rounded-md font-bold">
                        {selectedProcess?.product_as_material?.type_code}{' '}
                        {selectedProcess?.product_as_material?.name}
                      </span>{' '}
                      {selectedProcess?.quantity > 0 && (
                        <span className="">
                          จำนวน {selectedProcess?.quantity}{' '}
                          {selectedProcess?.additonal_unit ||
                            selectedProcess?.product_as_material?.unit}{' '}
                        </span>
                      )}
                    </h4>
                  ) : (
                    <h4 className="text-lg mx-2">
                      <span className="bg-gray-200 p-1 px-2 rounded-md font-bold">
                        {selectedProcess?.product?.type_code}{' '}
                        {selectedProcess?.product?.name}
                      </span>{' '}
                      {selectedProcess?.quantity > 0 && (
                        <span className="">
                          จำนวน {selectedProcess?.quantity}{' '}
                          {selectedProcess?.additonal_unit ||
                            selectedProcess?.product?.unit}{' '}
                        </span>
                      )}
                    </h4>
                  ))}
                {selectedProcess?.produce_base_project_instead && (
                  <h4 className="text-lg mx-2">
                    <span className="bg-gray-200 p-1 px-2 rounded-md font-bold">
                      {selectedProcess?.base_project?.type_code}{' '}
                      {selectedProcess?.base_project?.name}
                    </span>{' '}
                    {selectedProcess?.quantity > 0 && (
                      <span className="">
                        จำนวน {selectedProcess?.quantity}{' '}
                        {selectedProcess?.base_project?.unit}{' '}
                      </span>
                    )}
                  </h4>
                )}
              </div>

              <EmployeeAssignmentForm
                selectedProcess={selectedProcess}
                control={control}
                watch={watch}
                employee={employee}
                department={department}
                steps={selectedProcess?.steps}
                measurementType={measurementType}
                setValue={setValue}
                resultingFunctionTypes={resultingFunctionType}
              />
              <div className="flex justify-end">
                <Button variant="contained" color="success" type="submit">
                  มอบหมายงาน
                </Button>
              </div>
            </form>
          </CardContent>
        </Card>
      </div>
    );
  };

  if (
    manufacturingOrder?.isLoading ||
    department?.isLoading ||
    employee?.isLoading ||
    me?.isLoading ||
    information?.isLoading
  ) {
    return <Loading />;
  }

  if (
    manufacturingOrder?.isLoading === false &&
    manufacturingOrder?.isCompleted === true &&
    department?.isLoading === false &&
    department?.isCompleted === true &&
    employee?.isLoading === false &&
    employee?.isCompleted === true
  ) {
    return (
      <div>
        {renderTitle()}
        <div className="flex flex-row justify-between pb-4">
          <BackButton />

          {mmsSetting?.dashboard?.showDashboardA && (
            <Link to={`/mms/manufacturing-orders/view/${params.id}`}>
              <Button variant="contained">ไปที่คำสั่งผลิต</Button>
            </Link>
          )}

          {mmsSetting?.dashboard?.showDashboardB && (
            <Link to={`/mms/manufacturing-orders-b/view/${params.id}`}>
              <Button variant="contained">ไปที่คำสั่งผลิต</Button>
            </Link>
          )}

          {mmsSetting?.dashboard?.showDashboardC && (
            <Link to={`/mms/manufacturing-orders-c/view/${params.id}`}>
              <Button variant="contained">ไปที่คำสั่งผลิต</Button>
            </Link>
          )}

          {mmsSetting?.dashboard?.showBomDashboard && (
            <Link to={`/mms/manufacturing-orders/bom-checking/${params.id}`}>
              <Button variant="contained">BOM วัตถุดิบ</Button>
            </Link>
          )}
        </div>
        {_.map(manufacturingOrder?.process, (_process, index) => (
          <RenderEachProcess key={index} selectedProcess={_process} />
        ))}
        <div className="my-2">
          <Card>
            <CardContent>
              <div className="flex justify-center">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    history.push('/mms/manufacturing-orders');
                  }}
                >
                  กลับหน้าหลัก
                </Button>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    );
  }

  return <Error />;
};

AssignManufacturingOrder.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  selectedProcess: PropTypes.object,
};

AssignManufacturingOrder.defaultProps = {
  title: '',
  subtitle: '',
};

export default AssignManufacturingOrder;
