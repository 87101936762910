import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Card, CardContent } from '@mui/material';
import { gql } from 'graphql-request';
import PropTypes from 'prop-types';

import {
  BackButton,
  BomCheckingForm,
  Error,
  Loading,
  MultiUnitProdOrMatBox,
  ViewTitle,
} from '../../../components';
import graphqlClient from '../../../configs/graphqlClient';
import * as actions from '../../../redux/actions';

/**
 * @function ProcessBom
 * @description Display a collections or a list of ProcessBom from database
 */

export default function ProcessBom({ title, subtitle }) {
  const dispatch = useDispatch();
  const bomChecking = useSelector((state) => state.bomChecking);
  const history = useHistory();
  const params = useParams();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      bill_of_materials: bomChecking?.bill_of_materials,
    },
  });

  const query = gql`
    query FindSettingEditProductBom($bomCheckingInput: BomCheckingInput) {
      findOneBomChecking(input: $bomCheckingInput) {
        _id
        manufacturing_order {
          _id
          running_number
          prefix
        }
        product {
          _id
          name
          type_code
          use_unit_conversion
          unit
          conversion_from {
            unit_a
            unit_b
            conversion_factor
          }
          conversion_to {
            unit_b
            unit_a
            conversion_factor
          }
          unit_input {
            _id
            short_sign
            name
          }
          unit_output {
            _id
            short_sign
            name
          }
        }
        amount
        process {
          _id
        }
        bill_of_materials {
          prefer_amount
          actual_amount
          deposit_amount
          material {
            _id
            name
            type_code
            use_unit_conversion
            unit
            conversion_from {
              unit_a
              unit_b
              conversion_factor
            }
            conversion_to {
              unit_b
              unit_a
              conversion_factor
            }
            unit_input {
              _id
              short_sign
              name
            }
            unit_output {
              _id
              short_sign
              name
            }
            common_price
          }
        }
      }
    }
  `;

  const queryDataFromServer = async () => {
    const queryResult = await graphqlClient.request(query, {
      bomCheckingInput: { id: params.id },
    });
    const bomCheckingData = queryResult?.findOneBomChecking;
    dispatch(actions.bomCheckingStateOneSet(bomCheckingData));
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [params]);

  useEffect(() => {
    if (bomChecking && bomChecking?.bill_of_materials) {
      setValue('bill_of_materials', bomChecking?.bill_of_materials);
    }

    return () => {};
  }, [bomChecking]);

  const handleEditBomChecking = async (data) => {
    try {
      await dispatch(actions.bomCheckingPut(params.id, data));
      alert('สำเร็จ');
      history.goBack();
    } catch (error) {
      alert(
        `ไม่สามารถแก้ไขรายการวัตถุดิบส่วนผสมที่ใช้จริงได้ เนื่องจาก ${error?.message}`,
      );
    }
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const renderProcessInfo = () => (
    <div className="my-2">
      <Card>
        <CardContent>
          <div>
            <div className="font-semibold text-lg font-display">
              ไลน์ผลิตสินค้า
            </div>
            <div className="flex flex-wrap w-full">
              <div className="w-1/3 py-1 font-semibold font-display">
                สินค้า
              </div>
              <div className="w-2/3 py-1">
                {bomChecking?.product?.type_code || ''}{' '}
                {bomChecking?.product?.name || '-'}
              </div>
              <div className="w-1/3  py-1 font-semibold font-display">
                หมายเลขคำสั่งผลิต
              </div>
              <div className="w-2/3 py-1 ">
                {bomChecking?.manufacturing_order?.prefix || ''}
                {bomChecking?.manufacturing_order?.running_number || '-'}
              </div>
              <div className="w-1/3  py-1 font-semibold font-display">
                จำนวน
              </div>
              <div className="w-2/3 py-1 ">
                <MultiUnitProdOrMatBox
                  foundMaterial={bomChecking?.product}
                  quantity={bomChecking?.amount}
                />
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );

  const renderBomCheckingForm = () => (
    <div className="my-2">
      <Card>
        <CardContent>
          <form onSubmit={handleSubmit(handleEditBomChecking)}>
            <BomCheckingForm
              control={control}
              errors={errors}
              setValue={setValue}
              watch={watch}
            />
            <div className="flex w-full justify-end">
              <Button variant="contained" type="submit">
                บันทึก
              </Button>
            </div>
          </form>
        </CardContent>
      </Card>
    </div>
  );

  if (bomChecking.isLoading) {
    return <Loading />;
  }

  if (!bomChecking.isLoading && bomChecking.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div className="my-2">
          <BackButton />
        </div>
        {renderProcessInfo()}
        {renderBomCheckingForm()}
      </div>
    );
  }
  return <Error />;
}

ProcessBom.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

ProcessBom.defaultProps = {
  title: '',
  subtitle: '',
};
