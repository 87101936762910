/* eslint-disable arrow-body-style */
import React from 'react';
import PropTypes from 'prop-types';
import { TextField, Button } from '@mui/material';
import { Controller, useFieldArray } from 'react-hook-form';
import _ from 'lodash';
import dayjs from 'dayjs';

import { decodeTimeValue } from '../../../utils/functions';

const OverTimeForm = ({ watch, index, control, setValue, costPerHour }) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: `arr[${index}].overtimes`,
  });

  const LATE_THRESHOLD = 16;
  const BRUSH_OF_THRESHOLD = 40;
  const OT_RATE = 1.5;

  const calculateAllOTCost = () => {
    const otList = _.map(
      watch(`arr[${index}].overtimes`),
      (otItem) => otItem?.cost,
    );

    return _.sum(otList);
  };

  const calculateManHour = (
    start,
    finish,
    { brushOfThreshold, lateThreshold },
  ) => {
    const manMin = dayjs(finish).diff(dayjs(start), 'minute');
    const manHour = dayjs(finish).diff(dayjs(start), 'hour');

    // ลงตัวเป็นชั่วโมง  หรือ ทำงานไม่ถึง 20 นาที (สายเกิน 40 นาที) ถือว่าไม่ได้ทำงาน ปัดทิ้ง
    if (manMin % 60 < 60 - brushOfThreshold) {
      return manHour;
    }

    if (manMin % 60 < 60 - lateThreshold) {
      return manHour + 0.5; // ทำงานสาย / คิดเรทครั่งชั่วโมง
    }

    return manHour + 1; // น้อยกว่าเรทสาย ถือว่าไม่สาย
  };

  const changeOTCost = (start, finish, basedControllerName, fieldIndex) => {
    const manHour = calculateManHour(start, finish, {
      brushOfThreshold: BRUSH_OF_THRESHOLD,
      lateThreshold: LATE_THRESHOLD,
    });

    const cost = costPerHour * manHour * OT_RATE; // Main Cost
    setValue(`${basedControllerName}.overtimes[${fieldIndex}].cost`, cost);

    const totalCost =
      (parseFloat(watch(`${basedControllerName}.main_cost`)) || 0) +
      calculateAllOTCost(); // Cost with OT

    // console.log('Total Cost', totalCost);
    setValue(`${basedControllerName}.total_cost`, totalCost);
  };

  return (
    <div>
      {!_.isEmpty(fields) && (
        <div className="text-center my-2">
          {' '}
          รวมค่าล่วงเวลา{' '}
          <span className="px-1 font-semibold">{calculateAllOTCost()}</span>
          บาท
        </div>
      )}
      {_.map(fields, (eachField, fieldIndex) => (
        <div key={eachField?.id} className="py-2">
          <div className="flex ">
            <div className="w-1/2 px-2">
              <Controller
                name={`arr[${index}].overtimes[${fieldIndex}].start_time_input`}
                control={control}
                defaultValue={
                  eachField?.start_time
                    ? dayjs(eachField?.start_time).format('HH:mm')
                    : dayjs().hour(0).minute(0).format('HH:mm')
                }
                render={({ field }) => (
                  <TextField
                    type="time"
                    size="small"
                    {...field}
                    onChange={(event) => {
                      field.onChange(event.target.value);
                      setValue(
                        `arr[${index}].overtimes[${fieldIndex}].start_time`,
                        decodeTimeValue(event.target.value),
                      );
                      changeOTCost(
                        decodeTimeValue(event.target.value),
                        watch(
                          `arr[${index}].overtimes[${fieldIndex}].end_time`,
                        ),
                        `arr[${index}]`,
                        fieldIndex,
                      );
                    }}
                    fullWidth
                    label="เวลาเริ่มงาน"
                  />
                )}
              />
            </div>
            <div className="w-1/2 px-2">
              <Controller
                name={`arr[${index}].overtimes[${fieldIndex}].end_time_input`}
                control={control}
                defaultValue={
                  eachField?.start_time
                    ? dayjs(eachField?.end_time).format('HH:mm')
                    : dayjs().hour(0).minute(0).format('HH:mm')
                }
                render={({ field }) => (
                  <TextField
                    type="time"
                    size="small"
                    {...field}
                    onChange={(event) => {
                      field.onChange(event.target.value);
                      setValue(
                        `arr[${index}].overtimes[${fieldIndex}].end_time`,
                        decodeTimeValue(event.target.value),
                      );
                      changeOTCost(
                        watch(
                          `arr[${index}].overtimes[${fieldIndex}].start_time`,
                        ),
                        decodeTimeValue(event.target.value),
                        `arr[${index}]`,
                        fieldIndex,
                      );
                    }}
                    fullWidth
                    label="เวลาเลิกงาน"
                  />
                )}
              />
            </div>
          </div>
          <div className="flex  my-2">
            <div className="w-3/4 px-2">
              <Controller
                name={`arr[${index}].overtimes[${fieldIndex}].cost`}
                control={control}
                defaultValue={0}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="ค่าตอบแทน"
                    fullWidth
                    size="small"
                    onChange={(event) => {
                      field.onChange(event.target.value);
                    }}
                  />
                )}
              />
            </div>
            <div className="w-1/4 px-2 self-center">
              <Button
                size="small"
                color="error"
                variant="contained"
                onClick={() => remove(fieldIndex)}
              >
                ลบ
              </Button>
            </div>
          </div>
        </div>
      ))}
      <div>
        <Button size="small" variant="outlined" onClick={() => append({})}>
          เพิ่ม
        </Button>
      </div>
    </div>
  );
};

OverTimeForm.propTypes = {
  watch: PropTypes.func,
  index: PropTypes.number,
  control: PropTypes.object,
  setValue: PropTypes.func,
  costPerHour: PropTypes.number,
};

export default OverTimeForm;
