/* eslint-disable no-return-assign */
/* eslint-disable no-confusing-arrow */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Button, Chip } from '@mui/material';
import dayjs from 'dayjs';
import BuddhistCalendar from 'dayjs/plugin/buddhistEra';
import { gql } from 'graphql-request';
import _ from 'lodash';
import hash from 'object-hash';
import PropTypes from 'prop-types';

import {
  BackButton,
  DeleteManufacOrderModal,
  Error,
  InfoManufacturingOrder,
  Loading,
  LoadingDialogWithTimeout,
  MaterialSplittingProcessActionContainerBox,
  ProcessActionContainerBox,
  SocketIOLoading,
  ViewTitle,
} from '@/components';
import { ManufacturingOrderReport, ReportRendering } from '@/components/Report';
import { config } from '@/configs';
import graphqlClient from '@/configs/graphqlClient';
import * as actions from '@/redux/actions';
import * as CONSTANT from '@/utils/constants';
import { MMS as MMSMiddleWare } from '@/utils/middleware';

import 'dayjs/locale/th';

dayjs.extend(BuddhistCalendar);
dayjs.locale('th');

/**
 * @function DetailManufacOrderC
 * @memberof MMS/ManufactoringOrder
 * @description หน้าจอแสดงรายละเอียด MO แบบแรก ที่เน้นดู Process / Line การผลิตที่ละไลน์
 * มองเห็นการผลิตของไลน์ผลิตนั้นในภาพรวม
 */

const DetailManufacOrderC = ({ title, subtitle }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const manufacturingOrder = useSelector((state) => state.manufacturingOrder);
  const me = useSelector((state) => state.me);
  const information = useSelector((state) => state.information);
  const [modalCancleOpen, setModalCancleOpen] = useState(false);
  const [referenceNumber, setReferenceNumber] = useState('');
  const [dialogLoadingOpen, setDialogLoadingOpen] = useState(false);

  const openReport = () => {
    try {
      ManufacturingOrderReport(manufacturingOrder, information);
    } catch (error) {
      window.alert(`Error on Print ${error?.message}`);
      console.log('Detech as Error on Open MO Report', error);
      ManufacturingOrderReport(manufacturingOrder, information, true);
    }
  };

  const [tab, setTab] = useState(
    params.tab
      ? _.findIndex(
          manufacturingOrder?.process,
          (_process) => _process?._id === params.tab,
        ) || 0
      : 0,
  );

  const getManufacturingOrder = async () => {
    try {
      await dispatch(actions.manufacturingOrderGet(params.id, {}));
    } catch (error) {
      console.error(error);
    }
  };

  const customerQuery = gql`
    query FindCustomers($customerInput: CustomerInput) {
      findCustomers(input: $customerInput) {
        rows {
          _id
          type_code
          name
        }
      }
    }
  `;

  const queryDataFromServer = async () => {
    try {
      const queryResult = await graphqlClient.request(customerQuery, {
        customerInput: {
          page: 1,
          size: config.maxFetchSize,
        },
      });

      const customerData = queryResult?.findCustomers;

      dispatch(actions.customerStateSet(customerData));
    } catch (error) {
      console.error('Fetching Error', error);
      dispatch(actions.bomCheckingError());
    }
  };

  useEffect(() => {
    getManufacturingOrder();
    queryDataFromServer();
    return () => {};
  }, [params]);

  useEffect(() => {
    const selectedTab = params.tab
      ? _.findIndex(
          manufacturingOrder?.process,
          (_process) => _process?._id === params.tab,
        ) || 0
      : 0;
    setTab(selectedTab);
    return () => {};
  }, [manufacturingOrder]);

  useEffect(() => {
    const fetchMeasurements = async () => {
      const tabbedProcess = manufacturingOrder?.process?.[tab];
      if (tabbedProcess?._id && tabbedProcess?._id !== '') {
        try {
          await dispatch(
            actions.measurementAll({ currentProcess: tabbedProcess?._id }),
          );
        } catch (error) {
          console.error('Measurement Fetch All Error', error);
        }
      } else {
        console.log('Not found Tabbed Process', tabbedProcess?._id);
      }
    };

    fetchMeasurements();
    return () => {};
  }, [tab]);

  useEffect(() => {
    const tempHash = hash({
      employee: me?.userData?._id,
      date: new Date(),
    });
    setReferenceNumber(tempHash);

    return () => {};
  }, []);

  const handleSocketIOFunction = {
    onSuccess: () => {
      setDialogLoadingOpen(false);
      window.alert('ยกเลิกไลน์การผลิตสำเร็จ');
      getManufacturingOrder();
    },
    onFail: (args) => {
      setDialogLoadingOpen(false);
      console.log('Error', args?.error);
      window.alert(args?.message);
    },
  };

  const handleEditManufacturingOrder = async (data) => {
    try {
      await dispatch(
        actions.manufacturingOrderPut(
          params.id,
          MMSMiddleWare.ManufacturingOrder(data),
        ),
      );

      setDialogLoadingOpen(true);
    } catch (error) {
      console.error('Edit ManufacturingOrder Fail', error);
      window.alert('ไม่สามารถแก้ไขคำสั่งผลิตได้', error?.message);
    }
  };

  const generatePathForMOView = (processId) => {
    if (information?.setting?.mms?.dashboard?.showDashboardC) {
      return `/mms/manufacturing-orders-c/view/${params.id}/${processId}`;
    }
    return `/mms/manufacturing-orders/view/${params.id}/${processId}`;
  };
  const renderTitle = () => (
    <ViewTitle
      title={`${title} ${manufacturingOrder?.running_number || ''} `}
      subtitle={subtitle}
    />
  );

  const renderPrintButton = () => (
    <div className="self-center flex gap-2">
      <Button variant="contained" color="teal" onClick={() => openReport()}>
        พิมพ์
      </Button>
      <Link
        to={`/mms/manufacturing-orders/edit/${manufacturingOrder?._id}`}
        hidden={
          me?.userData?.role?.level < 1 ||
          manufacturingOrder?.completed ||
          manufacturingOrder?.deleted
        }
      >
        <Button
          variant="contained"
          color="warning"
          disabled={
            me?.userData?.role?.level < 1 ||
            manufacturingOrder?.completed ||
            manufacturingOrder?.deleted
          }
        >
          แก้ไข
        </Button>
      </Link>
    </div>
  );

  const renderDeleteModal = () => (
    <DeleteManufacOrderModal
      isOpen={modalCancleOpen}
      handleClose={() => setModalCancleOpen(false)}
      manufacturingOrder={manufacturingOrder}
      successHandle={() => setModalCancleOpen(false)}
    />
  );

  const renderProductTabs = () => (
    <ul className="flex cursor-pointer overflow-x-auto my-2">
      {_.map(
        _.orderBy(manufacturingOrder?.process, ['batch_no'], ['asc']),
        (_process, index) =>
          index === tab ? (
            <li
              className={`py-2 px-6 bg-white rounded-t-lg ${
                _process?.deleted ? 'line-through' : ''
              }`}
              key={index}
            >
              <b>
                {_process?.produce_material_instead
                  ? _process?.product_as_material?.type_code
                  : _process?.product?.type_code}{' '}
              </b>
              {_process?.produce_material_instead
                ? _process?.product_as_material?.name
                : _process?.product?.name}
              {_process?.produce_base_project_instead && (
                <span>
                  <b>{_process?.base_project?.type_code || ''}</b>{' '}
                  {_process?.base_project?.name || ''}
                </span>
              )}
              {_process?.is_minor_process && (
                <Chip
                  label="ไลน์ย่อย"
                  size="small"
                  color="primary"
                  className="mx-2"
                ></Chip>
              )}
              {information?.setting?.mms?.showBatchNumber && (
                <Chip
                  label={`Batch ${_process?.batch_no || '-'}`}
                  className="mx-2"
                  size="small"
                >
                  {' '}
                </Chip>
              )}
            </li>
          ) : (
            <Link
              key={index}
              className={`py-2 px-6  rounded-t-lg text-gray-500 bg-gray-200 ${
                _process?.deleted ? 'line-through' : ''
              }`}
              to={generatePathForMOView(_process?._id)}
              onClick={() => {
                setTab(index);
              }}
            >
              <b>
                {_process?.produce_material_instead
                  ? _process?.product_as_material?.type_code
                  : _process?.product?.type_code}{' '}
              </b>
              {_process?.produce_material_instead
                ? _process?.product_as_material?.name
                : _process?.product?.name}
              {_process?.is_minor_process && (
                <Chip label="ไลน์ย่อย" size="small" className="mx-2"></Chip>
              )}
              {information?.setting?.mms?.showBatchNumber && (
                <Chip
                  label={`Batch ${_process?.batch_no || '-'}`}
                  className="mx-2"
                  size="small"
                >
                  {' '}
                </Chip>
              )}
            </Link>
          ),
      )}
      <Link
        className="py-2 px-6  rounded-t-lg text-gray-500 bg-gray-200"
        to={`/mms/manufacturing-orders/create-new-process/${manufacturingOrder?._id}`}
      >
        <b>
          <i className="fas fa-plus" />
        </b>{' '}
        เพิ่ม
      </Link>
    </ul>
  );

  const renderActionController = (currentProcess, processIndex) => {
    if (
      currentProcess?.process_type ===
      CONSTANT.PROCESS_TEMPLATE_TYPE.MATERIAL_SPLITTING.status_code
    ) {
      return (
        <MaterialSplittingProcessActionContainerBox
          selectedProcess={currentProcess}
          key={processIndex}
          manufacturingOrder={manufacturingOrder}
          referenceNumber={referenceNumber}
          getManufacturingOrder={getManufacturingOrder}
          setDialogLoadingOpen={setDialogLoadingOpen}
        />
      );
    }
    return (
      <div>
        <ProcessActionContainerBox
          selectedProcess={currentProcess}
          key={processIndex}
          manufacturingOrder={manufacturingOrder}
          referenceNumber={referenceNumber}
          getManufacturingOrder={getManufacturingOrder}
          setDialogLoadingOpen={setDialogLoadingOpen}
        />
      </div>
    );
  };

  if (manufacturingOrder?.isLoading || manufacturingOrder?.rows) {
    return <Loading />;
  }

  if (manufacturingOrder?.isCompleted && !manufacturingOrder?.isLoading) {
    return (
      <div>
        {<LoadingDialogWithTimeout isLoading={dialogLoadingOpen} />}
        {renderDeleteModal()}
        <SocketIOLoading
          taskCode={CONSTANT.MQ_TASK.UPDATE_PROCESS.status_code}
          handleSuccess={handleSocketIOFunction.onSuccess}
          referenceNumber={referenceNumber}
          handleFail={handleSocketIOFunction.onFail}
        />
        <div className="flex flex-row justify-between">
          {renderTitle()}
          <div className="flex gap-2">{renderPrintButton()}</div>
        </div>
        <div>
          <BackButton />
        </div>
        <div className="my-2">
          <InfoManufacturingOrder
            manufacturingOrder={manufacturingOrder}
            me={me}
            information={information}
            handleMOToSuccess={() =>
              handleEditManufacturingOrder({ completed: true })
            }
            handleMOToCancle={() =>
              handleEditManufacturingOrder({ deleted: true })
            }
          />
          <div>
            {renderProductTabs()}
            {/* eslint-disable-next-line no-confusing-arrow */}
            {_.map(manufacturingOrder?.process, (_process, index) =>
              index === tab ? (
                renderActionController(_process, index)
              ) : (
                <div key={index}></div>
              ),
            )}
          </div>
        </div>
      </div>
    );
  }

  return <Error />;
};

DetailManufacOrderC.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  selectedProcess: PropTypes.object,
};

DetailManufacOrderC.defaultProps = {
  title: '',
  subtitle: '',
};
export default DetailManufacOrderC;
