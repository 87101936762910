import React, { useState } from 'react';
import { ZoomIn, ZoomOut } from 'react-feather';
import { Button, ButtonGroup, Tooltip } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import ConveyorSketchGeneraror from '../../../components/Box/Machine/ConveyorSketchGeneraror';
import { currencyFormatter } from '../../../utils/functions';

import AddMetalToWarehouseModal from './AddMetalToWarehouseModal';

const MetalDivideIllustrator = ({
  machine,
  metalDivides,
  lostHead = 0,
  lostTail = 0,
  manufacturingOrder,
  currentProcess,
  metalDivideId,
}) => {
  const basedWidth = 48;
  const [maxWidth, setMaxWidth] = useState(1080);
  const lengthPerWidthRate = machine?.conveyor_length / machine?.conveyor_width;

  const [addWarehouseOpen, setAddWatehouseOpen] = useState(false);
  const [selectedDivided, setSelectedDivided] = useState({});
  const [divideIndex, setDivideIndex] = useState(0);

  const colorList = ['green', 'blue', 'red', 'purple', 'pink'];
  let ratio = lengthPerWidthRate;
  if (48 * lengthPerWidthRate > maxWidth) {
    ratio = maxWidth / machine?.conveyor_length;
  }

  const handleZoomIn = () => {
    setMaxWidth(maxWidth + 200);
  };

  const handleZoomOut = () => {
    setMaxWidth(maxWidth - 200);
  };

  const usedArray = _.map(
    metalDivides,
    (eachMetalDivide) =>
      eachMetalDivide?.length * eachMetalDivide?.amount +
      (eachMetalDivide?.lost || 0),
  );

  const sumUsed = _.sum(usedArray);
  const remaining =
    (machine?.conveyor_length || 0) -
    (lostHead || 0) -
    (lostTail || 0) -
    sumUsed;

  const renderUsedMetal = () => (
    <div className="w-full flex">
      {_.map(metalDivides, (eachMetalDivide, index) => (
        <div className="flex" key={index}>
          <Tooltip
            style={{
              width: ratio * eachMetalDivide?.length * eachMetalDivide?.amount,
            }}
            title={`${
              eachMetalDivide?.customer?.name || ''
            } จำนวน ${currencyFormatter.format(
              eachMetalDivide?.length * eachMetalDivide?.amount,
            )} เมตร`}
          >
            <div
              className={`h-full text-sm bg-${
                colorList[index % 5]
              }-100  hover:bg-${
                colorList[index % 5]
              }-200 hover:shadow-md cursor-pointer`}
              style={{
                width:
                  ratio * eachMetalDivide?.length * eachMetalDivide?.amount,
              }}
              onClick={() => {
                setSelectedDivided(eachMetalDivide);
                setDivideIndex(index);
                setAddWatehouseOpen(true);
              }}
            >
              <div className="whitespace-nowrap overflow-hidden text-center">
                {eachMetalDivide?.customer?.name}
                <div>
                  {currencyFormatter.format(
                    eachMetalDivide?.length * eachMetalDivide?.amount,
                  )}{' '}
                  ม.
                </div>
              </div>
            </div>
          </Tooltip>
          {eachMetalDivide?.lost > 0 && (
            <Tooltip
              style={{
                width: ratio * (eachMetalDivide?.lost || 0),
              }}
              title={`ส่วนสูญเสีย ${currencyFormatter.format(
                eachMetalDivide?.lost,
              )} เมตร`}
            >
              <div
                className="h-full text-sm bg-yellow-100  hover:bg-yellow-200 hover:shadow-md cursor-pointer"
                style={{
                  width: ratio * (eachMetalDivide?.lost || 0),
                }}
              >
                เสีย
                <div className="whitespace-nowrap overflow-hidden">
                  {currencyFormatter.format(eachMetalDivide?.lost)} ม.
                </div>
              </div>
            </Tooltip>
          )}
        </div>
      ))}
      <div className="w-full">
        <div
          className="text-center text-xs  whitespace-nowrap overflow-hidden"
          style={{
            width: ratio * (remaining || 0),
          }}
        >
          เหลือ <br />
          {remaining.toFixed(2)} ม.
        </div>
      </div>
    </div>
  );

  const referencePoint = machine?.conveyor_reference_point?.enable
    ? parseInt(machine?.conveyor_reference_point?.reference_every, 10)
    : 1;

  const referenceBoxWidth = (basedWidth * lengthPerWidthRate) / referencePoint;
  return (
    <div className="p-2 ">
      <AddMetalToWarehouseModal
        isOpen={addWarehouseOpen}
        selectedDivided={selectedDivided}
        conveyorWidth={machine?.conveyor_width}
        handleClose={() => {
          setAddWatehouseOpen(false);
          setSelectedDivided({});
        }}
        divideIndex={divideIndex}
        currentProcess={currentProcess}
        manufacturingOrder={manufacturingOrder}
        overAllMetalDivide={metalDivides}
        metalDivideId={metalDivideId}
      />
      <div className="w-full flex justify-end">
        <ButtonGroup size="small">
          <Button onClick={handleZoomIn}>
            <ZoomIn size="16" />
          </Button>
          <Button onClick={handleZoomOut}>
            <ZoomOut size="16" />
          </Button>
        </ButtonGroup>
      </div>
      <div className="w-full overflow-x-auto py-4 pb-8">
        <ConveyorSketchGeneraror
          basedWidth={basedWidth}
          numberOfParallel={machine?.conveyor_parallel_number}
          width={machine?.conveyor_width}
          length={machine?.conveyor_length}
          maxWidth={maxWidth}
          referencePoint={referencePoint}
        >
          <div className="flex justify-between absolute">
            <div
              className="h-full text-sm whitespace-nowrap overflow-hidden bg-yellow-100 text-center hover:bg-yellow-200 hover:shadow-md cursor-pointer"
              style={{ width: ratio * lostHead }}
            >
              สูญเสียหัว <br /> {lostHead?.toString()} ม.
            </div>
            {renderUsedMetal()}
            <div
              className="h-full text-sm whitespace-nowrap overflow-hidden bg-yellow-100 text-center hover:bg-yellow-200 hover:shadow-md cursor-pointer"
              style={{ width: ratio * lostTail }}
            >
              สูญเสียหาง <br /> {lostTail?.toString()} ม.
            </div>
          </div>
          {/*
          <div className="absolute flex flex-col w-full ">
            {_.map(
              _.filter(metalDivides, (each) => each?.lost > 0),
              (eachMetalDivide, index) => (
                <div>
                  <Tooltip
                    style={{
                      width: ratio * (eachMetalDivide?.lost || 0),
                      position: 'absolute',
                      marginLeft:
                        ratio *
                        (eachMetalDivide?.failure_index || 0) *
                        referenceBoxWidth,
                      height: basedWidth,
                    }}
                    title={`ส่วนสูญเสีย ${currencyFormatter.format(
                      eachMetalDivide?.lost,
                    )} เมตร`}
                  >
                    <div
                      className="h-full  text-sm bg-yellow-100  hover:bg-yellow-200 hover:shadow-md cursor-pointer z-4"
                      style={{
                        marginLeft: referenceBoxWidth * ratio,
                        // marginLeft:
                        //   (eachMetalDivide?.failure_index || 0) *
                        //   referenceBoxWidth,
                        width: ratio * (eachMetalDivide?.lost || 0),
                        height: basedWidth,
                        maxWidth,
                      }}
                    >
                      เสีย
                      <div className="whitespace-nowrap overflow-hidden">
                        {currencyFormatter.format(eachMetalDivide?.lost)} ม.
                      </div>
                    </div>
                  </Tooltip>
                </div>
              ),
            )}{' '}
          </div> */}
        </ConveyorSketchGeneraror>
      </div>
    </div>
  );
};

MetalDivideIllustrator.propTypes = {
  machine: PropTypes.object,
  metalDivides: PropTypes.arrayOf(PropTypes.object),
  lostHead: PropTypes.number,
  lostTail: PropTypes.number,
  manufacturingOrder: PropTypes.object,
  currentProcess: PropTypes.object,
};

export default MetalDivideIllustrator;
