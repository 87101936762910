/* eslint-disable import/no-unresolved */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import {
  Button,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import dayjs from 'dayjs';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { BackButton } from '../../components/Button';
import { Error } from '../../components/Error';
import { Loading } from '../../components/Loading';
import { ProductLotReport } from '../../components/Report/index';
import { ViewTitle } from '../../components/ViewTitle';
import * as actions from '../../redux/actions';
import {
  converseMaterialOrProductUnit,
  currencyFormatter,
} from '../../utils/functions';

export default function DetailProductStockLot({ title, subtitle }) {
  const dispatch = useDispatch();
  const { id, warehouseId } = useParams();
  const product = useSelector((state) => state.product);
  const warehouse = useSelector((state) => state.warehouse);
  const information = useSelector((state) => state.information);
  const {
    setting: { wms: wmsSetting },
  } = information;

  const displaySetting = wmsSetting?.display;

  useEffect(() => {
    if (warehouseId) {
      dispatch(actions.warehouseGet(warehouseId));
    }
    dispatch(actions.productGet(id, { warehouseId, fetchStockLot: true }));
    dispatch(actions.informationAll());
    return () => {};
  }, []);

  const renderTitle = () => (
    <ViewTitle
      title={`${title}  ${warehouseId ? warehouse?.name : ''}`}
      subtitle={subtitle}
    />
  );

  const handlePrintPdf = async () => {
    try {
      await ProductLotReport(product, information);
    } catch (error) {
      await ProductLotReport(product, information, true);
    }
  };

  const totalBadgeColSpan =
    4 +
    (displaySetting?.lot_number ? 1 : 0) +
    (displaySetting?.production_date ? 1 : 0) +
    (displaySetting?.expiration_date ? 1 : 0);

  const renderPrintButton = () => (
    <div className="flex flex-row justify-end pb-4 gap-2">
      <Button variant="contained" color="teal" onClick={() => handlePrintPdf()}>
        พิมพ์
      </Button>
    </div>
  );

  const displayProductLot = () => (
    <Card>
      <TableContainer>
        <Table className="border-lg mb-4">
          <colgroup>
            <col width="5%" />
            <col width="8%" />
            <col width="8%" />
            {displaySetting?.lot_number && <col width="10%" />}
            <col width="10%" />
            {displaySetting?.production_date && <col width="10%" />}
            {displaySetting?.expiration_date && <col width="10%" />}
            <col width="7%" />
            <col width="7%" />
            <col width="5%" />
            {displaySetting?.price && <col width="5%" />}
            {displaySetting?.price && <col width="5%" />}
            <col width="5%" />
            <col width="15%" />
          </colgroup>
          <TableHead>
            <TableRow>
              <TableCell>
                <div className="font-bold">ลำดับที่</div>
              </TableCell>{' '}
              <TableCell>
                <div className="font-bold">เลขอ้างอิง</div>
              </TableCell>
              <TableCell>
                <div className="font-bold"> วันที่เข้าคลัง</div>
              </TableCell>
              {displaySetting?.lot_number && (
                <TableCell>
                  <div className="font-bold"> หมายเลขล็อต</div>
                </TableCell>
              )}
              <TableCell>
                <div className="font-bold"> เลขที่สั่งผลิต</div>
              </TableCell>
              {displaySetting?.production_date && (
                <TableCell>
                  <div className="font-bold"> วันที่ผลิต</div>
                </TableCell>
              )}
              {displaySetting?.expiration_date && (
                <TableCell>
                  <div className="font-bold"> วันที่หมดอายุ</div>
                </TableCell>
              )}
              <TableCell>
                <div className="font-bold"> ลูกค้า</div>
              </TableCell>
              {displaySetting?.price && (
                <TableCell>
                  <div className="font-bold"> ราคาต่อหน่วย</div>
                </TableCell>
              )}
              {displaySetting?.price && (
                <TableCell>
                  <div className="font-bold"> ราคารวม</div>
                </TableCell>
              )}
              <TableCell>
                <div className="font-bold"> จำนวน</div>
              </TableCell>
              <TableCell>
                <div className="font-bold"> คงเหลือ</div>
              </TableCell>{' '}
              <TableCell>
                <div className="font-bold">ผู้นำเข้าคลัง</div>
              </TableCell>
              <TableCell>
                <div className="font-bold"> หมายเหตุ</div>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!_.isEmpty(product?.stocklots) ? (
              product?.stocklots.map((_lot, index) => (
                <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{_lot?.running_number}</TableCell>

                  <TableCell>
                    {dayjs(_lot?.inventory_date)
                      .locale('th')
                      .format('DD MMM BBBB')}
                  </TableCell>
                  {displaySetting?.lot_number && (
                    <TableCell>{_lot?.lot_number || '-'}</TableCell>
                  )}
                  <TableCell>
                    <b>{_lot?.production_number?.running_number || '-'}</b>
                  </TableCell>
                  {displaySetting?.production_date && (
                    <TableCell>
                      <b className="text-green-500">
                        {dayjs(_lot?.production_date)
                          .locale('th')
                          .format('DD MMM BBBB')}
                      </b>
                    </TableCell>
                  )}
                  {displaySetting?.expiration_date && (
                    <TableCell>
                      <b className="text-red-500">
                        {dayjs(_lot?.expiration_date)
                          .locale('th')
                          .format('DD MMM BBBB')}
                      </b>
                    </TableCell>
                  )}
                  <TableCell>
                    <div>
                      {_lot?.supplier?.name ? (
                        <div className="font-semibold">
                          {_lot?.supplier?.name}
                        </div>
                      ) : (
                        <div>{'-'}</div>
                      )}
                    </div>
                  </TableCell>

                  {displaySetting?.price && (
                    <TableCell>
                      {currencyFormatter.format(_lot?.cost_per_unit)} บาท/{' '}
                      {product?.use_unit_conversion
                        ? product?.unit_input?.short_sign
                        : product?.unit}
                      {product?.use_unit_conversion && (
                        <div>
                          (
                          {currencyFormatter.format(
                            _lot?.cost_price /
                              converseMaterialOrProductUnit(
                                product,
                                _lot?.quantity,
                              ),
                          )}{' '}
                          บาท / {product?.unit_output?.short_sign})
                        </div>
                      )}
                    </TableCell>
                  )}
                  {displaySetting?.price && (
                    <TableCell>
                      {currencyFormatter.format(_lot?.cost_price)} บาท
                    </TableCell>
                  )}
                  <TableCell>
                    {currencyFormatter.format(_lot?.quantity)}{' '}
                    {product?.use_unit_conversion
                      ? product?.unit_input?.short_sign
                      : product?.unit}
                    {product?.use_unit_conversion && (
                      <div>
                        (
                        {currencyFormatter.format(
                          converseMaterialOrProductUnit(
                            product,
                            _lot?.quantity,
                          ),
                        )}
                        {product?.unit_output?.short_sign})
                      </div>
                    )}
                  </TableCell>
                  <TableCell>
                    {currencyFormatter.format(_lot?.amount)}{' '}
                    {product?.use_unit_conversion
                      ? product?.unit_input?.short_sign
                      : product?.unit}
                    {product?.use_unit_conversion && (
                      <div>
                        (
                        {currencyFormatter.format(
                          converseMaterialOrProductUnit(product, _lot?.amount),
                        )}
                        {product?.unit_output?.short_sign})
                      </div>
                    )}
                  </TableCell>
                  <TableCell>{_lot?.consignee_name || ' - '}</TableCell>
                  <TableCell>{_lot?.remark || ' - '}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow key={''}>
                <TableCell colSpan={11}>
                  <div className="text-center">ไม่มีข้อมูล</div>
                </TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell colSpan={totalBadgeColSpan} align="right">
                รวม / เฉลี่ย
              </TableCell>
              {displaySetting?.price && (
                <TableCell>
                  {currencyFormatter.format(product?.average || 0)} บาท /{' '}
                  {product?.use_unit_conversion
                    ? product?.unit_input?.short_sign
                    : product?.unit}
                  {product?.use_unit_conversion && (
                    <div>
                      (
                      {currencyFormatter.format(
                        product?.total_value /
                          converseMaterialOrProductUnit(
                            product,
                            product?.total,
                          ),
                      )}
                      บาท/{product?.unit_output?.short_sign})
                    </div>
                  )}
                </TableCell>
              )}
              {displaySetting?.price && (
                <TableCell>
                  {currencyFormatter.format(product?.total_value || 0)} บาท
                </TableCell>
              )}
              <TableCell>
                {currencyFormatter.format(product?.total_quantity || 0)}{' '}
                {product?.use_unit_conversion
                  ? product?.unit_input?.short_sign
                  : product?.unit}
                {product?.use_unit_conversion && (
                  <div>
                    (
                    {currencyFormatter.format(
                      converseMaterialOrProductUnit(
                        product,
                        product?.total_quantity,
                      ),
                    )}
                    {product?.unit_output?.short_sign})
                  </div>
                )}
              </TableCell>
              <TableCell>
                {currencyFormatter.format(product?.total || 0)}{' '}
                {product?.use_unit_conversion
                  ? product?.unit_input?.short_sign
                  : product?.unit}
                {product?.use_unit_conversion && (
                  <div>
                    (
                    {currencyFormatter.format(
                      converseMaterialOrProductUnit(product, product?.total),
                    )}
                    {product?.unit_output?.short_sign})
                  </div>
                )}
              </TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );

  if (product.isLoading || product.rows) {
    return <Loading />;
  }
  if (!product.isLoading && product.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div className="flex justify-between">
          <div>
            <BackButton />
          </div>
          <div className="mt-2 text-xl">
            <b>{product?.type_code}</b> {product?.name}
          </div>
          <div>{renderPrintButton()}</div>
        </div>{' '}
        <div>{displayProductLot()}</div>
      </div>
    );
  }
  return <Error />;
}

DetailProductStockLot.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

DetailProductStockLot.defaultProps = {
  title: '',
  subtitle: '',
};
