import React from 'react';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
} from '@mui/material';
import { useHistory } from 'react-router-dom';
import Proptypes from 'prop-types';
import _ from 'lodash';
import dayjs from 'dayjs';
import 'dayjs/locale/th';
import { OrderReport } from '../../Report/index';
import { MMS } from '../../../utils/Proptypes';
import * as CONFIG from '../../../utils/constants';

export default function OrderTable({
  orders,
  page,
  size,
  total,
  handlePushToEditPage,
  handleDelete,
  handleChangeRowsPerPage,
  handleChangePage,
  handleSuccess,
  handlePending,
  handlePacking,
  information,
}) {
  const history = useHistory();
  const findStatusWord = (statusCode) => {
    const selectedStatus = CONFIG.ORDER_STATUS[statusCode];
    if (
      selectedStatus.status_code ===
      CONFIG.ORDER_STATUS.ORDER_CREATE.status_code
    ) {
      return <b className="text-yellow-500">{selectedStatus?.description}</b>;
    }
    if (
      selectedStatus.status_code ===
      CONFIG.ORDER_STATUS.ORDER_PENDING.status_code
    ) {
      return <b className="text-blue-500">{selectedStatus?.description}</b>;
    }
    if (
      selectedStatus.status_code === CONFIG.ORDER_STATUS.ORDER_PACK.status_code
    ) {
      return <b className="text-indigo-600">{selectedStatus?.description}</b>;
    }
    if (
      selectedStatus.status_code ===
      CONFIG.ORDER_STATUS.ORDER_SUCCESS.status_code
    ) {
      return <b className="text-green-700">{selectedStatus?.description}</b>;
    }
    if (
      selectedStatus.status_code ===
      CONFIG.ORDER_STATUS.ORDER_CANCEL.status_code
    ) {
      return <b className="text-red-500">{selectedStatus?.description}</b>;
    }
  };

  const handlePrintPdf = (row) => {
    OrderReport(row, information);
  };

  const handlePushToDetailOrder = async (id) => {
    history.push(`order/detail/${id}`);
  };

  const showProcessButton = (statusCode, id) => {
    if (statusCode === CONFIG.ORDER_STATUS.ORDER_CREATE.status_code) {
      return (
        <>
          <Button
            variant="contained"
            color={'info'}
            size={'small'}
            onClick={() => handlePending(id)}
          >
            เตรียมสินค้า
          </Button>
          <Button
            variant="contained"
            color={'warning'}
            size={'small'}
            onClick={() => handlePushToEditPage(id)}
          >
            แก้ไข
          </Button>
          <Button
            variant="contained"
            color={'error'}
            size={'small'}
            onClick={() => {
              handleDelete(id);
            }}
          >
            ยกเลิก
          </Button>
        </>
      );
    }
    if (statusCode === CONFIG.ORDER_STATUS.ORDER_PENDING.status_code) {
      return (
        <>
          <Button
            variant="contained"
            color={'primary'}
            size={'small'}
            onClick={() => handlePacking(id)}
          >
            ตัดสต๊อก
          </Button>
          <Button
            variant="contained"
            color={'warning'}
            size={'small'}
            onClick={() => handlePushToEditPage(id)}
          >
            แก้ไข
          </Button>
          <Button
            variant="contained"
            color={'error'}
            size={'small'}
            onClick={() => {
              handleDelete(id);
            }}
          >
            ยกเลิก
          </Button>
        </>
      );
    }
    if (statusCode === CONFIG.ORDER_STATUS.ORDER_PACK.status_code) {
      return (
        <>
          <Button
            variant="contained"
            color={'success'}
            size={'small'}
            onClick={() => handleSuccess(id)}
          >
            สำเร็จ
          </Button>
          <Button
            variant="contained"
            color={'warning'}
            size={'small'}
            onClick={() => handlePushToEditPage(id)}
          >
            แก้ไข
          </Button>
          <Button
            variant="contained"
            color={'error'}
            size={'small'}
            onClick={() => {
              handleDelete(id);
            }}
          >
            ยกเลิก
          </Button>
        </>
      );
    }
  };
  return (
    <div className="my-2">
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <colgroup>
              <col width="8%" />
              <col width="10%" />
              <col width="15%" />
              <col width="20%" />
              <col width="5%" />
              <col width="10%" />
              <col width="30%" />
            </colgroup>
            <TableHead>
              <TableRow>
                <TableCell>
                  <div className="font-bold">ลำดับที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">วันที่กำหนดจัดส่ง</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">ลูกค้า</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">สินค้า</div>
                </TableCell>

                <TableCell>
                  <div className="font-bold">สถานะ</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">หมายเหตุ</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">ดำเนินการ</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(orders.rows) ? (
                orders.rows.map((row, index) => (
                  <TableRow
                    key={row.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {(page - 1) * size + index + 1}
                    </TableCell>
                    <TableCell>
                      {dayjs(row?.expected_date)
                        .locale('th')
                        .format('DD MMM BBBB')}
                    </TableCell>
                    <TableCell>{`${row?.customer?.name}`}</TableCell>
                    <TableCell>
                      {_.map(
                        row?.product_of_order,
                        (eachProdOfOrder, prodIndex) => (
                          <div key={prodIndex}>
                            <span>
                              {prodIndex + 1}
                              {'. '}
                            </span>
                            <span className=" font-semibold font-display">
                              {eachProdOfOrder?.product?.type_code}
                            </span>{' '}
                            <span>{eachProdOfOrder?.product?.name}</span>{' '}
                            {eachProdOfOrder?.quantity}{' '}
                            {eachProdOfOrder?.product?.unit}
                          </div>
                        ),
                      )}
                      {_.map(
                        row?.material_of_order,
                        (eachMatOfOrder, matIndex) => (
                          <div key={matIndex}>
                            <span>
                              {matIndex + _.size(row?.product_of_order) + 1}
                              {'. '}
                            </span>
                            <span className=" font-semibold font-display">
                              {eachMatOfOrder?.material?.type_code}
                            </span>{' '}
                            <span>{eachMatOfOrder?.material?.name}</span>{' '}
                            {eachMatOfOrder?.quantity}{' '}
                            {eachMatOfOrder?.material?.unit}
                          </div>
                        ),
                      )}
                    </TableCell>

                    <TableCell>
                      <div className="w-64">
                        <div className="py-1 bg-gray-100 rounded-md  text-center">
                          {findStatusWord(row.status)}
                        </div>
                      </div>
                    </TableCell>
                    <TableCell>{`${row?.remark}`}</TableCell>
                    <TableCell>
                      <div className="flex flex-row flex-wrap gap-1">
                        {showProcessButton(row.status, row.id)}
                        <div>
                          <Button
                            variant="contained"
                            color={'secondary'}
                            size={'small'}
                            onClick={() => handlePushToDetailOrder(row._id)}
                          >
                            รายละเอียด
                          </Button>
                        </div>
                        <Button
                          variant="contained"
                          color={'teal'}
                          size={'small'}
                          onClick={() => {
                            handlePrintPdf(row);
                          }}
                        >
                          พิมพ์
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <div className="text-center">ไม่มีข้อมูล</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          rowsPerPageOptions={[]}
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={page - 1}
          count={total || 1}
          rowsPerPage={size}
          onPageChange={handleChangePage}
        />
      </Paper>
    </div>
  );
}

OrderTable.propTypes = {
  orders: MMS.orderTemplateArray,
  page: Proptypes.number,
  size: Proptypes.number,
  total: Proptypes.number,
  handlePushToEditPage: Proptypes.func.isRequired,
  handleDelete: Proptypes.func.isRequired,
  handleChangeRowsPerPage: Proptypes.func.isRequired,
  handleChangePage: Proptypes.func.isRequired,
  handleSuccess: Proptypes.func.isRequired,
  handlePending: Proptypes.func.isRequired,
  handlePacking: Proptypes.func.isRequired,
  information: Proptypes.object,
};
