import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import {
  Button,
  Card,
  CardContent,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { gql } from 'graphql-request';
import _ from 'lodash';
import hash from 'object-hash';
import PropTypes from 'prop-types';

import {
  BackButton,
  Error,
  Loading,
  LoadingDialogWithTimeout,
  MaterialWithdrawForm,
  MMSSelectMaterialForm,
  SocketIOLoading,
  ViewTitle,
} from '@/components';
import { app as appConfig } from '@/configs';
import graphqlClient from '@/configs/graphqlClient';
import * as actions from '@/redux/actions';
import { MATERIAL_STOCK_STATUS, MQ_TASK } from '@/utils/constants';

/**
 * @function TransformMaterial
 * @description Display a collections or a list of WithdrawMaterial from database
 */

const useQuery = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};

export default function TransformMaterial({ title, subtitle }) {
  const dispatch = useDispatch();
  const information = useSelector((state) => state.information);
  const me = useSelector((state) => state.me);
  const material = useSelector((state) => state.material);
  const materialTransactionType = useSelector(
    (state) => state.materialTransactionType,
  );
  const browserQuery = useQuery();
  const history = useHistory();
  const [dialogLoading, setOpenDialogLoading] = useState(false);
  const [referenceNumber, setReferenceNumber] = useState('');
  const [statusMessage, setStatusMessage] = useState('');
  const [materialWithoutStock, setMaterialWithoutStock] = useState();

  const params = useParams();

  const {
    setting: { ims: imsSetting },
  } = information;

  useEffect(() => {
    const refNo = hash({
      date: new Date(),
      user: me?.userData?._id,
    });

    setReferenceNumber(refNo);

    return () => {};
  }, []);

  const query = gql`
    query FindWithdrawMaterial($materialInput: MaterialInput) {
      findMaterials(input: $materialInput) {
        total
        rows {
          _id
          name
          type_code
          use_unit_conversion
          unit
          conversion_from {
            unit_a
            unit_b
            conversion_factor
          }
          conversion_to {
            unit_b
            unit_a
            conversion_factor
          }
          unit_input {
            _id
            short_sign
            name
          }
          unit_output {
            _id
            short_sign
            name
          }
          total
          stocklots {
            _id
            amount
            issue_date
            lot_number
            quantity
            material {
              _id
              name
              type_code
              use_unit_conversion
              unit
              conversion_from {
                unit_a
                unit_b
                conversion_factor
              }
              conversion_to {
                unit_b
                unit_a
                conversion_factor
              }
              unit_input {
                _id
                short_sign
                name
              }
              unit_output {
                _id
                short_sign
                name
              }
            }
            place {
              _id
              wip_place
              name
            }
          }
        }
      }
    }
  `;

  const materialWithoutStockQuery = gql`
    query FindWithdrawMaterial($materialInput: MaterialInput) {
      findMaterials(input: $materialInput) {
        total
        rows {
          _id
          name
          type_code
          use_unit_conversion
          unit
          conversion_from {
            unit_a
            unit_b
            conversion_factor
          }
          conversion_to {
            unit_b
            unit_a
            conversion_factor
          }
          unit_input {
            _id
            short_sign
            name
          }
          unit_output {
            _id
            short_sign
            name
          }
          total
        }
      }
    }
  `;

  const queryDataFromServer = async () => {
    try {
      const queryResult = await graphqlClient.request(query, {
        materialInput: {
          fetchStockLot: true,
          status: MATERIAL_STOCK_STATUS.IN_STOCK.status_code,
          page: 1,
          size: appConfig.maxFetchSize,
          place: params.id,
        },
      });
      const materialWithoutStockResult = await graphqlClient.request(
        materialWithoutStockQuery,
        {
          materialInput: {
            fetchStockLot: false,
            page: 1,
            size: appConfig.maxFetchSize,
          },
        },
      );
      const materialData = queryResult?.findMaterials;
      const materialwithoutStockData =
        materialWithoutStockResult?.findMaterials;
      dispatch(actions.materialStateSet(materialData));
      setMaterialWithoutStock(materialwithoutStockData?.rows);
    } catch (error) {
      console.error('Query Error', error);
    }
  };

  const getMaterialTransactionType = async () => {
    try {
      await dispatch(actions.materialTransactionTypeAll({}));
    } catch (error) {
      console.error('Process Get Error', error);
    }
  };

  useEffect(() => {
    queryDataFromServer();
    getMaterialTransactionType();

    return () => {};
  }, []);

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      materialLots: [],
      processes: [
        {
          materials: [{}],
        },
      ],
    },
  });

  const outFilterTxnType = _.filter(
    materialTransactionType?.rows,
    (each) => each?.direction === 'add',
  );

  const handleSocketIOFunction = {
    onSuccess: () => {
      setOpenDialogLoading(false);
      history.goBack();
    },
    onFail: (args) => {
      setOpenDialogLoading(false);
      alert(args?.message);
    },
  };

  const handleRequestMaterial = async (data) => {
    try {
      setOpenDialogLoading(true);
      // ถ้ามันเป็นการขอแบบส่ง Request
      //   console.log('Data', data);
      const payload = {
        materialOut: data?.materialLots,
        materialIn: _.map(data?.processes?.[0]?.materials, (each) => ({
          ...each,
          quantity: each?.begin,
          amount: each?.begin,
        })),
        in_txn: data?.out_material_transaction_type,
        out_txn: data?.material_transaction_type,
        place: params.id,
        referenceNumber,
        employee: me?.userData?._id,
      };

      console.log('Payload', payload);
      await dispatch(actions.materialStockLotTransform(payload));
    } catch (error) {
      alert(`เกิดความผิดพลาด ${error?.message}`);
    }
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (
    materialTransactionType?.isLoading ||
    !materialTransactionType?.rows ||
    material?.isLoading
  ) {
    return <Loading />;
  }

  if (
    !materialTransactionType.isLoading &&
    materialTransactionType.isCompleted &&
    materialTransactionType?.rows &&
    material?.isCompleted &&
    !material?.isLoading
  ) {
    return (
      <div>
        {renderTitle()}
        <div className="my-2">
          <BackButton />
        </div>
        <LoadingDialogWithTimeout
          isLoading={dialogLoading}
          label={`กำลังดำเนินการเปลี่ยนวัตถุดิบ ${statusMessage || ''}`}
        />
        <SocketIOLoading
          taskCode={MQ_TASK.TRANSFORM_MATERIAL.status_code}
          handleSuccess={handleSocketIOFunction.onSuccess}
          handleFail={handleSocketIOFunction.onFail}
          referenceNumber={referenceNumber}
          setStatusMessage={setStatusMessage}
        />

        <div>
          <form onSubmit={handleSubmit(handleRequestMaterial)}>
            <div className="w-full">
              <div className="w-full font-semibold text-lg my-2 p-2 bg-red-300 font-display rounded-md">
                วัตถุดิบออก
              </div>

              <div className="my-2">
                <Card>
                  <CardContent>
                    <MaterialWithdrawForm
                      control={control}
                      errors={errors}
                      material={material}
                      materialTransactionType={materialTransactionType}
                      requestFromProcess={false}
                      requireConfirmation={false}
                      setValue={setValue}
                      watch={watch}
                      showRemark={false}
                    />{' '}
                  </CardContent>
                </Card>
              </div>

              <div className="w-full font-semibold text-lg my-2 p-2 bg-green-300 font-display rounded-md">
                วัตถุดิบเข้า
              </div>
              <div className="my-2">
                <Card>
                  <CardContent>
                    <Controller
                      control={control}
                      name="out_material_transaction_type"
                      rules={{ require: true }}
                      defaultValue={outFilterTxnType?.[0]?._id}
                      render={({ field }) => (
                        <FormControl fullWidth size="small" required>
                          <InputLabel>ประเภทการขอเบิก</InputLabel>
                          <Select
                            size="small"
                            required
                            {...field}
                            label="ประเภทการขอเบิก"
                            disabled={_.size(outFilterTxnType) === 1}
                          >
                            {_.map(outFilterTxnType, (_txntype, index) => (
                              <MenuItem key={index} value={_txntype?._id}>
                                {_txntype?.name}
                              </MenuItem>
                            ))}
                          </Select>
                          {errors?.material_transaction_type && (
                            <FormHelperText>
                              กรุณาเลือกประเภทการขอเบิก
                            </FormHelperText>
                          )}
                        </FormControl>
                      )}
                    />
                    <MMSSelectMaterialForm
                      control={control}
                      errors={errors}
                      material={materialWithoutStock}
                      productIndex={0}
                      setValue={setValue}
                      watch={watch}
                    />
                  </CardContent>
                </Card>
              </div>
            </div>
            <div className="w-full"></div>
          </form>{' '}
          <div className="flex justify-end gap-2 my-4">
            <Button
              type="button"
              onClick={handleSubmit(handleRequestMaterial)}
              color="primary"
              variant="contained"
            >
              ยืนยัน
            </Button>
          </div>
        </div>
      </div>
    );
  }
  return <Error />;
}

TransformMaterial.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

TransformMaterial.defaultProps = {
  title: '',
  subtitle: '',
};
