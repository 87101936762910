import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Card, CardContent } from '@mui/material';
import { gql } from 'graphql-request';
import _ from 'lodash';
import PropTypes from 'prop-types';

import {
  BackButton,
  EmployeeAssignmentForm,
  Error,
  Loading,
  ViewTitle,
} from '../../../components';
import { config } from '../../../configs';
import graphqlClient from '../../../configs/graphqlClient';
import * as actions from '../../../redux/actions';
import { MMS } from '../../../utils/functions';
/**
 * @function ProcessAssignment
 * @description Display a collections or a list of ProcessAssignment from database
 */

export default function ProcessAssignment({ title, subtitle }) {
  const dispatch = useDispatch();
  const currentProcess = useSelector((state) => state.process);
  const employee = useSelector((state) => state.employee);
  const department = useSelector((state) => state.department);
  const measurementType = useSelector((state) => state.measurementType);
  const resultingFunctionType = useSelector(
    (state) => state.resultingFunctionType,
  );
  const information = useSelector((state) => state.information);
  const me = useSelector((state) => state.me);
  const { handleProcessWorkAssignment } = MMS;

  const history = useHistory();
  const params = useParams();

  const { control, handleSubmit, setValue, watch } = useForm({
    defaultValues: {
      steps: _.isEmpty(currentProcess?.steps)
        ? _.orderBy(
            _.map(currentProcess?.product?.steps, (_step) => ({
              ..._step,
              _id: undefined,
            })),
            ['index'],
            ['asc'],
          )
        : _.orderBy(currentProcess?.steps, ['index'], ['asc']),
    },
  });

  // ถ้าหากมีค่าใน Step อยู่แล้ว ให้ยึดตามนั้นเป็นหลัก ถ้าไม่มีจึงไปดูใน Product step
  useEffect(() => {
    if (_.isEmpty(currentProcess?.steps)) {
      setValue(
        'steps',
        _.orderBy(
          _.map(currentProcess?.product?.steps, (_step) => ({
            ..._step,
            _id: undefined,
          })),
          ['index'],
          ['asc'],
        ),
      );
    } else {
      setValue('steps', _.orderBy(currentProcess?.steps, ['index'], ['asc']));
    }

    return () => {};
  }, [currentProcess]);

  const query = gql`
    query FindProcess(
      $processInput: ProcessInput
      $employeeInput: EmployeeInput
      $depertmentInput: DepartmentInput
      $measurementTypeInput: MeasurementTypeInput
      $resultingFunctionTypeInput: ResultingFunctionTypeInput
    ) {
      findOneProcess(input: $processInput) {
        _id
        current {
          index
          step {
            _id
            name
          }
        }
        product {
          steps {
            name
            index
            responsible {
              department {
                _id
                name
              }
            }
          }
        }
        steps {
          _id
          name
          index
          responsible {
            employee {
              _id
              firstname
              lastname
              image {
                url
              }
            }
            department {
              _id
              name
            }
          }
          material_associate
          measurements {
            _id
            measurement_type {
              _id
              name
            }
          }
          resultingFunctionTypes {
            _id
            name
          }
          completed
          deleted
        }
      }
      findEmployees(input: $employeeInput) {
        total
        rows {
          _id
          firstname
          lastname
          image {
            url
          }
        }
      }
      findDepartments(input: $depertmentInput) {
        total
        rows {
          _id
          name
        }
      }
      findMeasurementTypes(input: $measurementTypeInput) {
        total
        rows {
          _id
          name
        }
      }
      findResultingFunctionTypes(input: $resultingFunctionTypeInput) {
        total
        rows {
          _id
          name
        }
      }
    }
  `;

  const queryDataFromServer = async () => {
    try {
      const queryResult = await graphqlClient.request(query, {
        processInput: {
          id: params.id,
          fetchCurrentStep: true,
          fetchInsideProcess: true,
        },
        employeeInput: {
          page: 1,
          size: config.maxFetchSize,
        },
        departmentInput: {
          page: 1,
          size: config.maxFetchSize,
        },
        resultingFunctionTypeInput: {
          page: 1,
          size: config.maxFetchSize,
        },
        measurementTypeInput: {
          page: 1,
          size: config.maxFetchSize,
        },
      });
      const processData = queryResult?.findOneProcess;
      const employeeData = queryResult?.findEmployees;
      const departmentData = queryResult.findDepartments;
      const measurementTypeData = queryResult?.findMeasurementTypes;
      const resultingFunctionTypeData = queryResult?.findResultingFunctionTypes;
      dispatch(actions.processStateOneSet(processData));
      dispatch(actions.employeeStateSet(employeeData));
      dispatch(actions.departmentStateSet(departmentData));
      dispatch(actions.measurementTypeStateSet(measurementTypeData));
      dispatch(
        actions.resultingFunctionTypeStateSet(resultingFunctionTypeData),
      );
    } catch (error) {
      alert('เกิดข้อผิดพลาดในการดึงข้อมูล ');
      console.error('Error on Query', error);
    }
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [params]);

  const onSubmit = async (data) => {
    try {
      console.log('Data on Process Assignment', data);
      await handleProcessWorkAssignment(
        dispatch,
        currentProcess?._id,
        data,
        me?.userData?._id,
      );
      history.goBack();
    } catch (error) {
      alert(`ไม่สามารถมอบหมายงานได้ ${error?.message}`);
    }
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (currentProcess.isLoading) {
    return <Loading />;
  }

  if (!currentProcess.isLoading && currentProcess.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div>
          <BackButton />
        </div>
        <div className="my-2">
          <Card>
            <CardContent>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="flex gap-2 justify-between">
                  <h3 className="text-lg font-semibold">แจกจ่ายงาน</h3>
                </div>
                <div
                  className="overflow-y-auto "
                  style={{ maxHeight: '760px' }}
                >
                  <EmployeeAssignmentForm
                    control={control}
                    employee={employee}
                    department={department}
                    steps={currentProcess?.steps}
                    measurementType={measurementType}
                    setValue={setValue}
                    watch={watch}
                    resultingFunctionTypes={resultingFunctionType}
                  />
                </div>
                <div className="flex justify-end">
                  <Button variant="contained" color="success" type="submit">
                    มอบหมายงาน
                  </Button>
                </div>
              </form>
            </CardContent>
          </Card>
        </div>
      </div>
    );
  }
  return <Error />;
}

ProcessAssignment.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

ProcessAssignment.defaultProps = {
  title: '',
  subtitle: '',
};
