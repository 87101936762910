import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import dayjs from 'dayjs';
import _ from 'lodash';
import PropTypes from 'prop-types';

import MultiUnitProdOrMatBox from '../../Box/Rendering/MultiUnitProdOrMatBox';
import {
  DetailMaterialHTypeAddReport,
  DetailMaterialHTypeDescReport,
} from '../../Report';

const MaterialTransactionTable = ({
  transaction,
  page,
  setPage,
  size,
  setSize,
  total,
  handleDelete = () => {},
  disableDelete = true,
}) => {
  const information = useSelector((state) => state.information);
  const me = useSelector((state) => state.me);

  const imsSetting = information?.setting?.ims;

  const handlePrintPdfTypeAdd = async (row) => {
    try {
      await DetailMaterialHTypeAddReport(row, information);
    } catch (error) {
      await DetailMaterialHTypeAddReport(row, information, true);
    }
  };

  const handlePrintPdfTypeDesc = async (row) => {
    try {
      await DetailMaterialHTypeDescReport(row, information);
    } catch (error) {
      await DetailMaterialHTypeAddReport(row, information, true);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  return (
    <div>
      <div className="my-2">
        <Paper>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }}>
              <colgroup>
                <col width="5%" />
                <col width="5%" />
                <col width="10%" />
                <col width="5%" />
                {imsSetting?.display?.lot_number && <col width="10%" />}
                <col width="8%" />
                <col width="8%" />
                <col width="8%" />
                <col width="8%" />
                <col width="5%" />
                <col width="5%" />
                <col width="5%" />
                <col width="20%" />
              </colgroup>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <div className="font-bold">ลำดับที่</div>
                  </TableCell>{' '}
                  <TableCell>
                    <div className="font-bold">เลขรายการ</div>
                  </TableCell>
                  <TableCell>
                    <div className="font-bold"> วันที่ดำเนินการ</div>
                  </TableCell>
                  <TableCell>
                    <div className="font-bold"> ประเภท</div>
                  </TableCell>
                  <TableCell>
                    <div className="font-bold"> เลขอ้างอิงล็อต</div>
                  </TableCell>
                  {imsSetting?.display?.lot_number && (
                    <TableCell>
                      <div className="font-bold"> หมายเลขล็อต</div>
                    </TableCell>
                  )}
                  <TableCell>
                    <div className="font-bold"> วัตถุดิบ</div>
                  </TableCell>
                  <TableCell>
                    <div className="font-bold"> ชื่อคลัง</div>
                  </TableCell>
                  <TableCell>
                    <div className="font-bold"> จำนวน</div>
                  </TableCell>
                  <TableCell>
                    <div className="font-bold"> คงเหลือ</div>
                  </TableCell>
                  <TableCell>
                    <div className="font-bold"> ผู้ดำเนินการ</div>
                  </TableCell>
                  <TableCell>
                    <div className="font-bold"> หมายเหตุ</div>
                  </TableCell>{' '}
                  <TableCell>
                    <div className="font-bold"> วันที่บันทึกรายการ</div>
                  </TableCell>
                  <TableCell>
                    <div className="font-bold"> รายละเอียด</div>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!_.isEmpty(transaction.rows) ? (
                  transaction.rows.map((row, index) => (
                    <TableRow
                      key={row._id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {(page - 1) * size + index + 1}
                      </TableCell>{' '}
                      <TableCell>{row?.running_number}</TableCell>
                      <TableCell>
                        {dayjs(row?.createdAt)
                          .locale('th')
                          .format('DD MMM BBBB')}
                      </TableCell>
                      <TableCell>
                        {row?.material_transaction_type?.direction === 'add' ? (
                          <div className="w-32">
                            <div className="bg-gray-100 p-1 text-center rounded-md">
                              <p className="text-green-500 ">{`${row?.material_transaction_type?.name}`}</p>
                            </div>
                          </div>
                        ) : (
                          <div className="w-32">
                            <div className="bg-gray-100 p-1 text-center rounded-md">
                              <p className="text-red-500 ">{`${row?.material_transaction_type?.name}`}</p>
                            </div>
                          </div>
                        )}
                      </TableCell>
                      <TableCell>
                        <div>
                          {_.map(
                            row?.material_stock_lot,
                            (_material, _index) => (
                              <div key={_index}>
                                {_material?.lotId?.running_number || '-'}
                              </div>
                            ),
                          )}
                        </div>
                      </TableCell>{' '}
                      {imsSetting?.display?.lot_number && (
                        <TableCell>
                          <div>
                            {_.map(
                              row?.material_stock_lot,
                              (_material, _index) => (
                                <div key={_index}>
                                  {_material?.lotId?.lot_number || '-'}
                                </div>
                              ),
                            )}
                          </div>
                        </TableCell>
                      )}
                      <TableCell colSpan={4}>
                        <Table size="small">
                          <colgroup>
                            <col width="20%" />
                            <col width="20%" />
                            <col width="30%" />
                            <col width="30%" />
                          </colgroup>

                          {_.map(
                            row?.material_stock_lot,
                            (_material, _index) => (
                              <TableRow key={_index}>
                                <TableCell sx={{ borderBottom: 0 }}>
                                  <div className="py-2">
                                    {_material?.lotId?.material?.type_code}{' '}
                                    {_material?.lotId?.material?.name}
                                  </div>
                                </TableCell>
                                <TableCell sx={{ borderBottom: 0 }}>
                                  <div className="py-2">
                                    {_material?.lotId?.place?.name}
                                  </div>
                                </TableCell>
                                <TableCell sx={{ borderBottom: 0 }}>
                                  <div className="py-2">
                                    <MultiUnitProdOrMatBox
                                      foundMaterial={_material?.lotId?.material}
                                      quantity={_material?.quantity}
                                    />
                                  </div>
                                </TableCell>
                                <TableCell sx={{ borderBottom: 0 }}>
                                  <div>
                                    <MultiUnitProdOrMatBox
                                      foundMaterial={_material?.lotId?.material}
                                      quantity={_material?.amount}
                                    />
                                  </div>
                                </TableCell>
                              </TableRow>
                            ),
                          )}
                        </Table>
                      </TableCell>
                      <TableCell>
                        {row?.material_transaction_type?.direction === 'add' ? (
                          <div>
                            <b>{`${
                              row?.material_stock_lot[0]?.lotId
                                ?.recipient_name || '-'
                            }`}</b>
                          </div>
                        ) : (
                          <div>
                            <b>{`${row?.request_name || '-'}`}</b>
                          </div>
                        )}
                      </TableCell>
                      <TableCell>{row?.remark}</TableCell>
                      <TableCell>
                        {dayjs(row?.recording_date)
                          .locale('th')
                          .format('DD MMM BBBB')}
                        <p className="text-sm">
                          เวลา {dayjs(row?.recording_date).format('HH:mm:ss')}
                        </p>
                      </TableCell>
                      <TableCell>
                        <div className="flex flex-wrap ">
                          <div className="mr-2 mb-2">
                            <Link to={`/ims/history/detail/${row?._id}`}>
                              <Button
                                variant="contained"
                                color={'info'}
                                size={'small'}
                              >
                                รายละเอียด
                              </Button>
                            </Link>
                          </div>
                          <div className="mb-2">
                            <Button
                              variant="contained"
                              color={'secondary'}
                              size={'small'}
                              onClick={() => {
                                if (
                                  row?.material_transaction_type?.direction ===
                                  'desc'
                                ) {
                                  handlePrintPdfTypeDesc(row);
                                } else {
                                  handlePrintPdfTypeAdd(row);
                                }
                              }}
                            >
                              พิมพ์
                            </Button>
                          </div>
                          {me?.userData?.role?.level >= 1 && !disableDelete && (
                            <div className="mb-2">
                              <Button
                                variant="contained"
                                color={'error'}
                                size={'small'}
                                onClick={() => {
                                  handleDelete(row);
                                }}
                              >
                                {' '}
                                ลบ{' '}
                              </Button>
                            </div>
                          )}
                        </div>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={6}>
                      <div className="text-center">ไม่มีข้อมูล</div>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            // rowsPerPageOptions={[]}
            onRowsPerPageChange={handleChangeRowsPerPage}
            page={page - 1}
            count={total || 1}
            rowsPerPage={size}
            onPageChange={handleChangePage}
          />
        </Paper>
      </div>
    </div>
  );
};

MaterialTransactionTable.propTypes = {
  transaction: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  page: PropTypes.number,
  setPage: PropTypes.func,
  size: PropTypes.number,
  setSize: PropTypes.func,
  total: PropTypes.number,
  handleDelete: PropTypes.func,
  disableDelete: PropTypes.bool,
};

export default MaterialTransactionTable;
