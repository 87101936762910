/* eslint-disable no-nested-ternary */
import React from 'react';
import {
  Check as CheckIcon,
  Info as InfoIcon,
  PauseCircle as PauseCircleIcon,
  X as XIcon,
} from 'react-feather';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Card, CardContent, Skeleton } from '@mui/material';
import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import _ from 'lodash';
import Proptypes from 'prop-types';

import 'dayjs/locale/th';

import { timeDurationWord } from '../../utils/functions/durationTimeDisplay';
import { processStatusCheck } from '../../utils/functions/MMS/processStatusCheck';

dayjs.locale('th');
dayjs.extend(buddhistEra);

/**
 *
 *
 * @function CardManufacturingOrder
 * @memberof Component/Card
 * @description การ์ดรายละเอียดของคำสั่งผลิต อยู่ในหน้าของรายการคำสั่งผลิตเหมือนกัน
 * @returns {React.Component} React Component
 */
export default function CardManufacturingOrder({
  manufacturingOrder,
  handleDelete,
  displayCancleButton = true,
  displayStatusBadge = true,
}) {
  const information = useSelector((state) => state.information);
  const me = useSelector((state) => state.me);
  const isOnHistoryPage = !displayCancleButton;

  const {
    setting: { mms: mmsSetting },
  } = information;

  const isAdminPosition = me?.userData?.role?.level >= 1;
  const renderProcessData = (process) => {
    if (!displayStatusBadge) {
      return <div></div>;
    }

    // เสร็จแล้ว
    if (process?.completed) {
      return (
        <div className="mx-2 rounded-full  text-white  py-1 px-1 my-1 max-w-max">
          <CheckIcon color="green" size={18} />
        </div>
      );
    }

    // ยกเลิก
    if (process?.deleted) {
      return (
        <div className="mx-2 rounded-full  text-white  py-1 px-1 my-1 max-w-max">
          <XIcon color="red" size={18} />
        </div>
      );
    }

    // ยังไม่เริ่ม
    if (
      process?.current?.index === null ||
      process?.current?.index === undefined ||
      process?.current?.index === -1
    ) {
      return (
        <div className="mx-2 rounded-full  text-white  py-1 px-1 my-1 max-w-max">
          <InfoIcon color="gray" size={18} />
        </div>
      );
    }

    return (
      <div>
        <div className="mx-2 rounded-full  text-white  py-1 px-1 my-1 max-w-max"></div>
      </div>
    );
  };

  const renderTimeStatus = (expectedDate, calculatedDate) => {
    const timeDuration = timeDurationWord(expectedDate, calculatedDate);
    return (
      <div className={`${timeDuration.cssClass}`}>{timeDuration.word}</div>
    );
  };

  const renderDeleteBadge = (isDeleted, deletedReason) => {
    if (isDeleted) {
      return (
        <div>
          <div className=" text-red-500 font-bold rounded-md bg-gray-100 px-2 py-1 text-center   mt-1">
            ยกเลิก
          </div>
          <div className=" text-red-500">สาเหตุการยกเลิก: {deletedReason} </div>
        </div>
      );
    }
    return <div></div>;
  };

  const renderStepAndStatus = (process) => {
    const processStatus = processStatusCheck(process, process?.current?.step);

    if (process?.current?.index === -1) {
      // ในกรณีที่ยังไม่เริ่มการผลิต
      return (
        <div className="flex">
          <div className="text-gray-500 bg-gray-50 rounded-md p-1 px-2 ">
            {'ยังไม่เริ่มการผลิต'}
          </div>
        </div>
      );
    }
    if (process?.deleted === true) {
      // ในกรณีที่ยกเลิก
      return (
        <div className="flex">
          <div className="text-red-500 bg-gray-50 rounded-md p-1 px-2 ">
            ยกเลิกไลน์การผลิต
            {process?.delete_note ? (
              <p>สาเหตุการยกเลิก : {process?.delete_note}</p>
            ) : (
              <></>
            )}
          </div>
        </div>
      );
    }

    if (processStatus.processStatus.isGreatestComplete) {
      // ในกรณีที่ถึง step สุดท้ายแล้ว สำเร็จ ให้ขึ้นสถานะสำเร็จของการผลิต
      return (
        <div className="flex">
          <div className="text-green-500 bg-gray-50 rounded-md p-1 px-2 ">
            {'การผลิตเสร็จสิ้น'}
          </div>
        </div>
      );
    }
    return (
      <div className="flex">
        <div className="text-yellow-500   bg-gray-50 rounded-md p-1 px-2 ">
          {`${process?.current?.step?.name}`}
        </div>
      </div>
    );
  };

  const calculatedDate = manufacturingOrder?.completed
    ? dayjs(manufacturingOrder.updatedAt)
    : dayjs();

  return (
    <div className="my-2">
      <Card>
        <CardContent>
          <div className="text-lg font-display my-1">
            คำสั่ง <b>{manufacturingOrder?.running_number || '-'}</b>
          </div>
          <div>
            {renderDeleteBadge(
              manufacturingOrder?.deleted,
              manufacturingOrder?.delete_note,
            )}
          </div>
          <div className="w-full flex">
            <div className="w-1/2 pr-1">
              <div className="text-sm">สั่งผลิต</div>
              <div>
                {mmsSetting?.timing?.showTime
                  ? dayjs(manufacturingOrder?.start_date).format(
                      'D MMM BBBB HH:mm น.',
                    )
                  : dayjs(manufacturingOrder?.start_date).format('D MMM BBBB ')}
              </div>
            </div>
            {mmsSetting?.usingAsDefault?.showExpectedDate && (
              <div className="w-1/2 pr-1">
                <div className="text-sm">กำหนดส่ง</div>
                <div>
                  {mmsSetting?.timing?.showTime
                    ? dayjs(manufacturingOrder?.start_date).format(
                        'D MMM BBBB HH:mm น.',
                      )
                    : dayjs(manufacturingOrder?.start_date).format(
                        'D MMM BBBB ',
                      )}
                </div>
              </div>
            )}
          </div>
          <div>
            {mmsSetting?.usingAsDefault?.showExpectedDate &&
              !isOnHistoryPage &&
              renderTimeStatus(
                manufacturingOrder?.expected_date,
                calculatedDate,
              )}
          </div>
          <hr className="my-2" />
          <div>
            {!manufacturingOrder?.process && <Skeleton variant="text" />}
            <div className="mx-2 ml-4">
              {_.map(manufacturingOrder?.process, (_process, procIndex) => (
                <div key={procIndex}>
                  <div className="flex gap-1 ">
                    <div className="self-center">
                      <span className=" font-semibold font-display">
                        {_process?.produce_material_instead
                          ? _process?.product_as_material?.type_code
                          : _process?.product?.type_code}
                      </span>{' '}
                      <span>
                        {_.truncate(
                          _process?.produce_material_instead
                            ? _process?.product_as_material?.name
                            : _process?.product?.name,
                        )}
                      </span>{' '}
                      <span className=" font-semibold font-display">
                        {_process?.produce_base_project_instead &&
                          _process?.base_project?.type_code}
                      </span>{' '}
                      <span>
                        {_.truncate(
                          _process?.produce_base_project_instead &&
                            _process?.base_project?.name,
                        )}
                      </span>{' '}
                      <span>
                        {_process?.quantity > 0 ? _process?.quantity : ''}{' '}
                      </span>
                      <span>
                        {_process?.quantity > 0
                          ? _process?.additional_unit || _process?.product?.unit
                          : ''}
                      </span>
                      <div>
                        {_process?.customer?.type_code || ''}{' '}
                        {_process?.customer?.name || ''}
                      </div>
                    </div>
                    <div className="self-center">
                      {renderProcessData(_process)}{' '}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <hr className="my-2" />
          <div>
            <div className="text-sm">ผู้สั่งผลิต</div>
            <div>
              {manufacturingOrder?.assigner?.firstname}{' '}
              {manufacturingOrder?.assigner?.lastname}
            </div>
          </div>{' '}
          <hr className="my-2" />
          <div>
            <div className="flex  flex-wrap gap-1 justify-center">
              {information?.setting?.mms?.dashboard?.showDashboardA && (
                <Link
                  to={`/mms/manufacturing-orders/view/${manufacturingOrder?.id}`}
                  component={Button}
                  variant="contained"
                  color="info"
                  size={'small'}
                  className="w-24"
                >
                  รายละเอียด{' '}
                </Link>
              )}{' '}
              {information?.setting?.mms?.dashboard?.showDashboardB && (
                <Link
                  to={`/mms/manufacturing-orders-b/view/${manufacturingOrder?.id}`}
                  component={Button}
                  variant="contained"
                  color="info"
                  size={'small'}
                  className="w-24"
                >
                  รายละเอียด
                </Link>
              )}
              {information?.setting?.mms?.dashboard?.showDashboardC && (
                <Link
                  to={`/mms/manufacturing-orders-c/view/${manufacturingOrder?.id}`}
                  component={Button}
                  variant="contained"
                  color="info"
                  size={'small'}
                  className="w-24"
                >
                  รายละเอียด
                </Link>
              )}
              {information?.setting?.mms?.dashboard?.showBomDashboard && (
                <Link
                  to={`/mms/manufacturing-orders/bom-checking/${manufacturingOrder?.id}`}
                  component={Button}
                  variant="contained"
                  color="secondary"
                  size={'small'}
                  className="w-24"
                >
                  วัตถุดิบส่วนผสม (BOM)
                </Link>
              )}{' '}
              {_.map(
                information?.setting?.mms?.customDashboard,
                (eachCustomDashboard, ecdIndex) => (
                  <Link
                    key={`dashboard-${ecdIndex}`}
                    to={`/mms/manufacturing-orders/custom/${eachCustomDashboard?._id}/${manufacturingOrder?.id}`}
                  >
                    <Button
                      color="info"
                      variant="contained"
                      size="small"
                      className="w-24"
                    >
                      {eachCustomDashboard?.name}
                    </Button>
                  </Link>
                ),
              )}
              {displayCancleButton && (
                <Button
                  variant="contained"
                  color={'error'}
                  size={'small'}
                  className="w-24"
                  onClick={() => handleDelete(manufacturingOrder.id)}
                  disabled={!isAdminPosition}
                >
                  ยกเลิก
                </Button>
              )}{' '}
            </div>
          </div>{' '}
        </CardContent>
      </Card>
    </div>
  );
}

CardManufacturingOrder.propTypes = {
  manufacturingOrder: Proptypes.object,
  handleDelete: Proptypes.func,
  displayCancleButton: Proptypes.bool,
  displayStatusBadge: Proptypes.bool,
};
