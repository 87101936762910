import React from 'react';
import {
  Avatar,
  Chip,
  ListItem,
  ListItemAvatar,
  ListItemButton,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { pluginList } from '../../../plugins';
import * as CONSTANT from '../../../utils/constants';

const StepBox = ({ index, eachStep }) => (
  <ListItemButton>
    <div className="flex flex-col lg:w-1/6  px-2 ">
      <div className="w-full">
        <ListItem alignItems="flex-center">
          <ListItemAvatar>
            <Avatar>{index + 1}</Avatar>
          </ListItemAvatar>
        </ListItem>
      </div>
    </div>
    <div className="flex flex-col w-4/6">
      <div className="text-xl font-semibold font-display">
        {eachStep?.name}{' '}
        <Chip
          size="small"
          label={`แผนก${eachStep?.responsible?.department?.name}`}
        ></Chip>
      </div>
      {eachStep?.material_associate && (
        <div className="my-2 text-semibold text-theme-500">
          มีการใช้วัตถุดิบ{' '}
        </div>
      )}
      <div className="flex flex-wrap text-gray-500 text-opacity-75">
        <label className="px-2">{eachStep?.detail}</label>
      </div>
      <div className="font-body text-sm mb-2">
        {_.isEmpty(eachStep?.measurementTypes) ? (
          ''
        ) : (
          <div>
            <p className=" font-bold">การวัดค่า</p>
            {_.map(
              eachStep?.measurementTypes,
              (eachMeasurement, measurementIndex) => (
                <li key={measurementIndex} className="mr-2 my-2">
                  {eachMeasurement?.name}{' '}
                  <Chip
                    label={
                      CONSTANT.DATA_TYPE[eachMeasurement?.data_type]
                        ?.description
                    }
                    size="small"
                    className="mx-2"
                  ></Chip>
                  <br />
                  {eachMeasurement?.description}
                </li>
              ),
            )}
          </div>
        )}
      </div>
      <div className="font-body text-sm mb-2">
        {_.isEmpty(eachStep?.resultingFunctionTypes) ? (
          ''
        ) : (
          <div>
            <p className=" font-bold">การแสดงค่า</p>
            {_.map(
              eachStep?.resultingFunctionTypes,
              (eachResultFn, resultFnIndex) => (
                <li key={resultFnIndex} className="mr-2 my-2">
                  {eachResultFn?.name}{' '}
                </li>
              ),
            )}
          </div>
        )}
      </div>{' '}
      <div className="font-body text-sm mb-2">
        {(!_.isEmpty(eachStep?.materials) || eachStep?.main_material) && (
          <div>
            <p className=" font-bold">วัตถุดิบ</p>
            <ul className="list-inside list-disc my-2">
              {eachStep?.use_set_of_material ? (
                _.map(eachStep?.materials, (eachMaterial, materialIndex) => (
                  <li key={materialIndex}>
                    {' '}
                    {eachMaterial?.material?.type_code || ''}{' '}
                    {eachMaterial?.material?.name || '-'}{' '}
                  </li>
                ))
              ) : (
                <li className="items-center list-item">
                  {eachStep?.main_material?.type_code || ' '}{' '}
                  {eachStep?.main_material?.name || ''}{' '}
                  {eachStep?.main_material?.is_workin_process && (
                    <Chip color="warning" size="small" label="WIP" />
                  )}
                </li>
              )}
            </ul>
            <div>
              <span className=" font-bold">การนำเข้ามาของวัตถุดิบ </span>
              {
                CONSTANT.MATERIAL_COMING_METHOD[
                  eachStep?.material_incoming_method
                ]?.description
              }
            </div>
          </div>
        )}
      </div>
      {eachStep?.use_plugin_instead && (
        <div className="font-body text-sm mb-2">
          <div className="font-semibold">ปลั๊กอิน </div>
          <div>
            {' '}
            {_.find(
              pluginList,
              (eachPlugin) => eachPlugin?.id === eachStep?.plugin,
            )?.name || 'ไม่พบปลํกอินที่เลือกใช้'}{' '}
          </div>
        </div>
      )}
      <div className="font-body text-sm mb-2">
        {(eachStep?.bookmark?.add_warehouse ||
          eachStep?.bookmark?.add_inventory ||
          eachStep?.bookmark?.withdraw_material) && (
          <div>
            <p className=" font-bold">การดำเนินการหลังจากสิ้นสุดขั้นตอนนี้</p>
            <ul className="list-inside list-disc my-2">
              {eachStep?.bookmark?.add_warehouse && <li>เพิ่มลงคลังสินค้า</li>}
              {eachStep?.bookmark?.add_inventory && (
                <li>เพิ่มลงคลังวัตถุดิบ</li>
              )}
              {eachStep?.bookmark?.withdraw_material && <li>เบิกวัตถุดิบ</li>}
            </ul>
          </div>
        )}
      </div>{' '}
      <div className="text-sm mb-2">
        {(eachStep?.is_process_merging_point ||
          eachStep?.is_process_splitting_point) && (
          <div>
            <p className=" font-bold">การแยกหรือรวมไลน์การผลิต</p>
            <ul className="list-inside list-disc my-2">
              {eachStep?.is_process_splitting_point && (
                <li>เป็นจุดเริ่มต้นของการแยก</li>
              )}
              {eachStep?.is_process_merging_point && <li>จุดสิ้นสุดการแยก</li>}
            </ul>
          </div>
        )}
      </div>
    </div>
  </ListItemButton>
);

export default StepBox;

StepBox.propTypes = {
  index: PropTypes.number,
  eachStep: PropTypes.object,
};
