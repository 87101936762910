import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  Switch,
} from '@mui/material';
import dayjs from 'dayjs';
import BuddhistCalendar from 'dayjs/plugin/buddhistEra';
import PropTypes from 'prop-types';

import 'dayjs/locale/th';

import { BackButton } from '../../components/Button';
import { ViewTitle } from '../../components/ViewTitle';
import * as actions from '../../redux/actions';

dayjs.extend(BuddhistCalendar);

const SettingIMS = ({ title, subtitle }) => {
  const dispatch = useDispatch();
  const information = useSelector((state) => state.information);
  const { control, handleSubmit } = useForm({
    defaultValues: { setting: information?.setting },
  });

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const handleChangeSetting = async (data) => {
    console.log('Data', data);
    const confirm = window.confirm('ยืนยันการปรับการตั้งค่า');
    if (confirm) {
      try {
        await dispatch(
          actions.informationPut(information?._id, {
            ...data,
            setting: {
              ...data.setting,
              ims: {
                ...data.setting.ims,
              },
            },
          }),
        );
        await dispatch(actions.informationAll());
        window.alert('แก้ไขการตั้งค่าสำเร็จ');
      } catch (error) {
        window.alert('แก้ไขการตั้งค่าไม่สำเร็จ');
        console.error('แก้ไขการตั้งค่าไม่สำเร็จ', error);
      }
    }
  };

  const renderGeneralSetting = () => (
    <Card className="p-4">
      <div className="flex flex-wrap w-full ">
        <div className="w-full py-2 px-2 font-semibold font-display ">
          กระบวนการทำงาน
        </div>
        <div className="w-1/2 py-2 px-2  ">การตัดวัตถุดิบออกจากสต็อก</div>
        <div className="w-1/2 py-2 px-2">
          <Controller
            control={control}
            name="setting.ims.usingFIFO"
            render={({ field }) => (
              <FormControl>
                <div className="flex gap-2">
                  <div className="self-center text-black font-system">
                    เลือกตามล็อตวัตถุดิบ
                  </div>
                  <FormControlLabel
                    control={
                      <Switch
                        {...field}
                        defaultChecked={information?.setting?.ims?.usingFIFO}
                      />
                    }
                    label="เลือกโดยใช้ First In First Out"
                  />
                </div>
              </FormControl>
            )}
          />
        </div>
        <div className="w-1/2 py-2 px-2  ">เพิ่มให้ใส่เป้าหมายของการเบิก</div>
        <div className="w-1/2 py-2 px-2">
          <Controller
            control={control}
            name="setting.ims.allowWithdrawDestination"
            render={({ field }) => (
              <FormControl>
                <div className="flex gap-2">
                  <div className="self-center text-black font-system">ปิด</div>
                  <FormControlLabel
                    control={
                      <Switch
                        {...field}
                        defaultChecked={information?.setting?.ims?.usingFIFO}
                      />
                    }
                    label="เปิด"
                  />
                </div>
              </FormControl>
            )}
          />
        </div>{' '}
        <div className="w-1/2 py-2 px-2  ">
          อนุญาตให้การผลิตเบิกได้จากคลัง WIP เท่านั้น
        </div>
        <div className="w-1/2 py-2 px-2">
          <Controller
            control={control}
            name="setting.ims.allowManufacturingUseWIPOnly"
            render={({ field }) => (
              <FormControl>
                <div className="flex gap-2">
                  <div className="self-center text-black font-system">ปิด</div>
                  <FormControlLabel
                    control={
                      <Switch
                        {...field}
                        defaultChecked={
                          information?.setting?.ims
                            ?.allowManufacturingUseWIPOnly
                        }
                      />
                    }
                    label="เปิด"
                  />
                </div>
              </FormControl>
            )}
          />
        </div>
        <div className="w-1/2 py-2 px-2  ">อนุญาตให้มีการแปลงวัตถุดิบ</div>
        <div className="w-1/2 py-2 px-2">
          <Controller
            control={control}
            name="setting.ims.allowMaterialTransform"
            render={({ field }) => (
              <FormControl>
                <div className="flex gap-2">
                  <div className="self-center text-black font-system">ปิด</div>
                  <FormControlLabel
                    control={
                      <Switch
                        {...field}
                        defaultChecked={
                          information?.setting?.ims?.allowMaterialTransform
                        }
                      />
                    }
                    label="เปิด"
                  />
                </div>
              </FormControl>
            )}
          />
        </div>
        <div className="w-full py-2 px-2 font-semibold font-display ">
          รายละเอียดในคลังวัตถุดิบ
        </div>
        <div className="w-1/2 py-2 px-2">
          ส่วนประกอบในฟอร์มการเพิ่มลดวัตถุดิบ
        </div>
        <div className="w-1/2 py-2 px-2">
          <div className="flex flex-col w-full">
            {/* <Controller
              control={control}
              name="setting.ims.display.receipt_date"
              render={({ field }) => (
                <FormControlLabel
                  {...field}
                  control={<Checkbox defaultChecked={field.value} />}
                  label="วันที่รับวัตถุดิบ"
                />
              )}
            /> */}
            <Controller
              control={control}
              name="setting.ims.display.purchase_date"
              render={({ field }) => (
                <FormControlLabel
                  {...field}
                  control={<Checkbox defaultChecked={field.value} />}
                  label="วันที่ซื้อ"
                />
              )}
            />
            <Controller
              control={control}
              name="setting.ims.display.production_date"
              render={({ field }) => (
                <FormControlLabel
                  {...field}
                  control={<Checkbox defaultChecked={field.value} />}
                  label="วันผลิต"
                />
              )}
            />
            <Controller
              control={control}
              name="setting.ims.display.expiration_date"
              render={({ field }) => (
                <FormControlLabel
                  {...field}
                  control={<Checkbox defaultChecked={field.value} />}
                  label="วันหมดอายุ"
                />
              )}
            />
            <Controller
              control={control}
              name="setting.ims.display.lot_number"
              render={({ field }) => (
                <FormControlLabel
                  {...field}
                  control={<Checkbox defaultChecked={field.value} />}
                  label="หมายเลขล็อต"
                />
              )}
            />
            <Controller
              control={control}
              name="setting.ims.display.source"
              render={({ field }) => (
                <FormControlLabel
                  {...field}
                  control={<Checkbox defaultChecked={field.value} />}
                  label="แหล่งวัตถุดิบ"
                />
              )}
            />
          </div>
        </div>
        <div className="w-1/2 py-2 px-2">ส่วนประกอบในรายละเอียดวัตถุดิบ</div>
        <div className="w-1/2 py-2 px-2">
          <div className="flex flex-col w-full">
            <Controller
              control={control}
              name="setting.ims.materialAttribute.net"
              render={({ field }) => (
                <FormControlLabel
                  {...field}
                  control={<Checkbox defaultChecked={field.value} />}
                  label="ปริมาตรสุทธิ"
                />
              )}
            />
            <Controller
              control={control}
              name="setting.ims.materialAttribute.register_number"
              render={({ field }) => (
                <FormControlLabel
                  {...field}
                  control={<Checkbox defaultChecked={field.value} />}
                  label="เลขทะเบียนวัตถุดิบ"
                />
              )}
            />
            <Controller
              control={control}
              name="setting.ims.materialAttribute.common_price"
              render={({ field }) => (
                <FormControlLabel
                  {...field}
                  control={<Checkbox defaultChecked={field.value} />}
                  label="ราคาทั่วไป"
                />
              )}
            />
          </div>
        </div>
      </div>
    </Card>
  );

  return (
    <div>
      {renderTitle()}
      <div className="flex flex-row justify-start pb-4">
        <div>
          <BackButton />
        </div>
      </div>
      <form onSubmit={handleSubmit(handleChangeSetting)}>
        <div>{renderGeneralSetting()}</div>
        <div className="flex justify-end mt-2">
          <Button color="primary" variant="contained" type="submit">
            บันทึก
          </Button>
        </div>
      </form>
    </div>
  );
};

SettingIMS.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

SettingIMS.defaultProps = {
  title: '',
  subtitle: '',
};
export default SettingIMS;
