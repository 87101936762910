import React from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import {
  Autocomplete,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import dayjs from 'dayjs';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { MultiUnitProdOrMatBox } from '../../Box';
import { ProdOrMatQuantityInput } from '../../Inputs';

function MoveMaterialForm({
  material,
  place,
  defaultValue,
  handleMoveMaterial = () => {},
  currentPlaceId,
}) {
  const { control, watch, setValue, handleSubmit } = useForm();
  const { append, remove, fields } = useFieldArray({
    control,
    name: 'items',
  });
  return (
    <form onSubmit={handleSubmit(handleMoveMaterial)}>
      <div className="flex flex-wrap">
        <div className="w-full px-1 py-2">
          <Controller
            name="place"
            control={control}
            defaultValue={defaultValue?._id}
            render={({ field }) => (
              <FormControl fullWidth size="small">
                <InputLabel>คลังวัตถุดิบเป้าหมาย</InputLabel>
                <Select
                  {...field}
                  onChange={(e) => {
                    field.onChange(e.target.value);
                  }}
                  label="คลังวัตถุดิบเป้าหมาย"
                  required
                >
                  {!_.isEmpty(place?.rows) &&
                    place?.rows?.map((row, index) => (
                      <MenuItem key={index} value={row?._id}>
                        {row?.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            )}
          />
        </div>
        <div className="w-full px-1 py-2 font-semibold">
          วัตถุดิบที่ต้องการย้าย
        </div>
        <div className="w-full px-1 py-2">
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }}>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <div className="font-bold">ลำดับที่</div>
                  </TableCell>
                  <TableCell>
                    <div className="font-bold"> วัตถุดิบ</div>
                  </TableCell>{' '}
                  <TableCell>
                    <div className="font-bold"> ล็อต</div>
                  </TableCell>
                  <TableCell>
                    <div className="font-bold">ปริมาณที่ต้องการย้าย</div>
                  </TableCell>{' '}
                  <TableCell>
                    <div className="font-bold">ลบ</div>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!_.isEmpty(fields) ? (
                  _.map(fields, (row, index) => (
                    <TableRow
                      key={row.id}
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        <div className="text-xs">{index + 1}</div>
                      </TableCell>
                      <TableCell>
                        {!_.isEmpty(material?.rows) && (
                          <Controller
                            control={control}
                            name={`items.${index}.material`}
                            defaultValue={row?.material}
                            render={({ field }) => (
                              <Autocomplete
                                {...field}
                                disablePortal
                                options={material?.rows}
                                size="small"
                                placeholder="เลือกวัตถุดิบ"
                                onChange={(e, newValue) => {
                                  field.onChange(newValue);
                                }}
                                className="z-40"
                                // prettier-ignore
                                getOptionLabel={(option) => `${option?.type_code || ''} ${option?.name || ''}`
                                }
                                renderInput={(params) => (
                                  <TextField label="วัตถุดิบ" {...params} />
                                )}
                              />
                            )}
                          />
                        )}
                      </TableCell>
                      <TableCell>
                        {!_.isEmpty(
                          watch(`items.${index}.material`)?.stocklots,
                        ) && (
                          <Controller
                            control={control}
                            name={`items.${index}.material_stock_lot`}
                            render={({ field }) => (
                              <Autocomplete
                                {...field}
                                disablePortal
                                options={_.filter(
                                  watch(`items.${index}.material`)?.stocklots,
                                  (each) => each?.place === currentPlaceId,
                                )}
                                size="small"
                                placeholder="ล็อตวัตถุดิบ"
                                onChange={(e, newValue) => {
                                  field.onChange(newValue);
                                }}
                                className="z-40"
                                // prettier-ignore
                                getOptionLabel={(option) => `ล็อตหมายเลข${option?.lot_number || ''} วันที่รับ ${dayjs(option?.recording_date).format('D MMM BBBB')} คงเหลือ ${option?.amount || ''} ${watch(`items.${index}.material`)?.use_unit_conversion ? watch(`items.${index}.material`)?.unit_input?.short_sign : watch(`items.${index}.material`)?.unit}`
                                }
                                renderInput={(params) => (
                                  <TextField label="ล็อตวัตถุดิบ" {...params} />
                                )}
                              />
                            )}
                          />
                        )}
                        <div className="flex my-1 gap-1">
                          <div>ปริมาณเดิม</div>
                          <MultiUnitProdOrMatBox
                            foundMaterial={watch(`items.${index}.material`)}
                            quantity={
                              watch(`items.${index}.material_stock_lot`)?.amount
                            }
                            noWrap
                          />
                        </div>
                      </TableCell>
                      <TableCell>
                        {
                          <ProdOrMatQuantityInput
                            controllerName={`items.${index}.quantity`}
                            control={control}
                            artificialControllerName={`items.${index}.quantity_artificial`}
                            setValue={setValue}
                            selectedUnitControllerName={`items.${index}.selected_unit`}
                            index={index}
                            selectedProduct={watch(`items.${index}.material`)}
                            watch={watch}
                            onChangeFunction={() => {
                              const value = watch(`items.${index}.quantity`);
                              console.log('Value', value);
                              if (
                                parseFloat(value) !==
                                parseFloat(
                                  watch(`items.${index}.material_stock_lot`)
                                    ?.amount,
                                )
                              ) {
                                setValue(`items.${index}.full`, false);
                              }
                            }}
                          />
                        }{' '}
                        <Controller
                          control={control}
                          name={`items.${index}.full`}
                          render={({ field }) => (
                            <FormControlLabel
                              label="ย้ายทั้งล็อต"
                              control={
                                <Checkbox
                                  {...field}
                                  checked={field.value}
                                  onChange={(e) => {
                                    field.onChange(e.target.checked);
                                    if (e.target.checked) {
                                      setValue(
                                        `items.${index}.quantity`,
                                        watch(
                                          `items.${index}.material_stock_lot`,
                                        )?.amount,
                                      );
                                    }
                                  }}
                                />
                              }
                            />
                          )}
                        />
                        {watch(`items.${index}.quantity`) >
                          watch(`items.${index}.material_stock_lot`)
                            ?.amount && (
                          <div className="text-red-500 font-semibold">
                            เกินปริมาณที่มีอยู่
                          </div>
                        )}
                      </TableCell>
                      <TableCell>
                        <Button
                          color="error"
                          size="small"
                          variant="contained"
                          onClick={() => remove(index)}
                        >
                          ลบ
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={6}>
                      <div className="text-center">ไม่มีข้อมูล</div>
                    </TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => append({})}
                    >
                      เพิ่ม
                    </Button>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div className="w-full flex justify-end my-2">
          <Button variant="contained" color="primary" type="submit">
            บันทึก
          </Button>
        </div>
      </div>
    </form>
  );
}

export default MoveMaterialForm;

MoveMaterialForm.propTypes = {
  material: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  place: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
  }),
  defaultValue: PropTypes.object,
  handleMoveMaterial: PropTypes.func,
  currentPlaceId: PropTypes.string,
};
