import React, { useState } from 'react';
import { Controller, useFieldArray } from 'react-hook-form';
import { useSelector } from 'react-redux';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import {
  converseMaterialOrProductUnit,
  notNan,
} from '../../../utils/functions';
import { ProdOrMatQuantityInput } from '../../Inputs';

import MeasurementFilledForm from './MeasurementFilledForm';

const ProcessMaterialYieldForm = ({
  step,
  control,
  setValue,
  watch,
  errors,
  disableEditing,
}) => {
  const information = useSelector((state) => state.information);
  const {
    setting: { mms: mmsSetting },
  } = information;

  const { fields } = useFieldArray({
    control,
    name: 'materials',
  });

  const [reload, setReload] = useState(false);
  const handleFullButton = (index, beginAmount) => {
    setValue(`materials[${index}].end`, beginAmount);
    if (watch(`materials[${index}].material`)?.use_unit_conversion) {
      const conversedValue = converseMaterialOrProductUnit(
        watch(`materials[${index}].material`),
        beginAmount,
      );
      setValue(`materials[${index}].end_artificial`, conversedValue);
    }
  };

  const renderTableElementUsingRemaining = (_field, index) => (
    <TableRow key={_field?.id}>
      <TableCell>{index + 1}</TableCell>
      <TableCell>
        <div>
          {_field?.material?.type_code} {_field?.material?.name}
        </div>
      </TableCell>
      <TableCell>{notNan(_field?.begin)}</TableCell>
      <TableCell>
        <div className="flex gap-1 ">
          <ProdOrMatQuantityInput
            control={control}
            controllerName={`materials[${index}].end`}
            artificialControllerName={`materials[${index}].end_artificial`}
            index={index}
            reload={reload}
            setReload={setReload}
            selectedUnitControllerName={`materials[${index}].end_selected_unit`}
            setValue={setValue}
            watch={watch}
            selectedProduct={_field?.material}
          />

          <Button
            variant="contained"
            size="small"
            color="warning"
            className="self-center"
            onClick={() => {
              handleFullButton(index, _field?.begin);
            }}
          >
            หมด
          </Button>
        </div>
      </TableCell>
      <TableCell>
        {notNan(
          watch(`materials[${index}].begin`) - watch(`materials[${index}].end`),
        )}
      </TableCell>

      <Controller
        control={control}
        name={`materials[${index}].wastes`}
        render={({ field }) => (
          <input
            type="hidden"
            {...field}
            value={
              watch(`materials[${index}].begin`) -
              watch(`materials[${index}].end`)
            }
          />
        )}
      />

      <Controller
        control={control}
        name={`materials[${index}].material`}
        defaultValue={_field?.materialInfo}
        render={({ field }) => <input type="hidden" {...field} />}
      />
      <Controller
        control={control}
        name={`materials[${index}].begin`}
        render={({ field }) => (
          <input type="hidden" {...field} value={_field?.resultAmount} />
        )}
      />
      <Controller
        control={control}
        name={`materials[${index}].return`}
        render={({ field }) => (
          <input type="hidden" {...field} value={_field?.returnAmount} />
        )}
      />
    </TableRow>
  );

  const renderTableElementUsingWaste = (_field, index) => (
    <TableRow key={_field?.id}>
      <TableCell>{index + 1}</TableCell>
      <TableCell>
        <div>
          {_field?.material?.type_code} {_field?.material?.name}
        </div>
      </TableCell>
      <TableCell>{notNan(_field?.begin)}</TableCell>
      <TableCell>
        <Controller
          control={control}
          defaultValue={_field?.wastes}
          name={`materials[${index}].wastes`}
          rules={{
            required: true,
            min: 0,
            // max:
            //   watch(`materials[${index}].begin`) -
            //   watch(`materials[${index}].return`),
          }}
          render={({ field }) => (
            <TextField
              {...field}
              label="ของเสีย"
              size="small"
              disabled={disableEditing}
              className="w-28"
              onChange={(e) => {
                field.onChange(e.target.value);
                setReload(!reload);
              }}
              helperText={
                errors?.materials?.[index]?.wastes &&
                'ต้องใส่ค่าและค่าต้องน้อยกว่าปริมาณวัตถุดิบที่ใช้ได้'
              }
            />
          )}
        />
      </TableCell>
      <TableCell>
        {notNan(
          watch(`materials[${index}].begin`) -
            watch(`materials[${index}].wastes`),
        )}
      </TableCell>

      <Controller
        control={control}
        name={`materials[${index}].end`}
        render={({ field }) => (
          <input
            type="hidden"
            {...field}
            value={
              parseFloat(watch(`materials[${index}].begin`)) -
              parseFloat(watch(`materials[${index}].wastes`))
            }
          />
        )}
      />

      <Controller
        control={control}
        name={`materials[${index}].material`}
        defaultValue={_field?.materialInfo}
        render={({ field }) => <input type="hidden" {...field} />}
      />
      <Controller
        control={control}
        name={`materials[${index}].begin`}
        render={({ field }) => (
          <input type="hidden" {...field} value={_field?.resultAmount} />
        )}
      />
      <Controller
        control={control}
        name={`materials[${index}].return`}
        render={({ field }) => (
          <input type="hidden" {...field} value={_field?.returnAmount} />
        )}
      />
    </TableRow>
  );

  const renderMaterialTable = () => (
    <div className="overflow-x-auto">
      <h5 className="font-semibold py-4">การใช้วัตถุดิบ</h5>

      <Table size="small" sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow className="bg-blue-100">
            <TableCell>ลำดับที่</TableCell>
            <TableCell>วัตถุดิบ</TableCell>
            <TableCell>ใช้ได้</TableCell>
            <TableCell>
              {mmsSetting?.fillYieldUsingWaste
                ? 'ของเสีย'
                : 'วัตถุดิบที่ใช้ / ส่งต่อ'}
            </TableCell>
            <TableCell>
              {mmsSetting?.fillYieldUsingWaste ? 'คงเหลือ' : 'ของเสีย'}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {_.map(fields, (_field, index) => {
            if (mmsSetting?.fillYieldUsingWaste) {
              return renderTableElementUsingWaste(_field, index);
            }
            return renderTableElementUsingRemaining(_field, index);
          })}
        </TableBody>
      </Table>
    </div>
  );

  return (
    <div>
      {step?.material_associate === true ? renderMaterialTable() : <div></div>}
      <MeasurementFilledForm control={control} watch={watch} />{' '}
    </div>
  );
};

export default ProcessMaterialYieldForm;

ProcessMaterialYieldForm.propTypes = {
  step: PropTypes.object,
  manufacturingMaterials: PropTypes.arrayOf(PropTypes.object),
  control: PropTypes.object,
  setValue: PropTypes.func,
  watch: PropTypes.func,
  errors: PropTypes.object,
  disableEditing: PropTypes.bool,
};
