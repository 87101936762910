/* eslint-disable import/no-named-as-default-member */
import React, { useEffect, useState } from 'react';
import {
  Box as BoxIcon,
  Check as CheckIcon,
  CheckSquare as CheckSquareIcon,
  Clipboard as ClipboardIcon,
  CornerDownLeft as CornerDownLeftIcon,
} from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import {
  Alert,
  AlertTitle,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import dayjs from 'dayjs';
import _ from 'lodash';
import hash from 'object-hash';
import PropTypes from 'prop-types';

import { SocketIOLoading } from '@/components';
import { config } from '@/configs';
import { MQ_TASK } from '@/utils/constants';

import AllPluginInfo from '../../../plugins';
import * as actions from '../../../redux/actions';
import { MMS } from '../../../utils/functions';
import { DesktopDateTimepicker } from '../../DatePicker';
import { LoadingDialog, LoadingDialogWithTimeout } from '../../Loading';
import MaterialWithdrawInfoTable from '../../Tables/MMS/MaterialWithdrawInfoTable';

const MaterialSplitterControlBox = ({
  process,
  currentStep,
  justify = 'end',
  container = true,
  materialSplittingProcess = {},
}) => {
  const dispatch = useDispatch();
  const information = useSelector((state) => state.information);

  const me = useSelector((state) => state.me);

  const history = useHistory();
  const {
    setting: { mms: mmsSetting },
  } = information;

  const {
    ProcessStatusCheck,
    handleStartProcess,
    handleCloseWork,
    handleToPurchase,
  } = MMS;

  const [handleProdDialogOpen, setHandleProdDialogOpen] = useState(false);
  const [handleStartOpen, setHandleStartOpen] = useState(false);
  const [startTime, setStartTime] = useState(dayjs().toDate());
  const [referenceNumber, setReferenceNumber] = useState();
  const [isLoadingDialogOn, setIsLoadingDialogOn] = useState(false);

  const processStatus = ProcessStatusCheck(
    process,
    currentStep,
    information,
    me,
  );

  const fetchManufacturingOrder = async () => {
    await dispatch(
      actions.manufacturingOrderGet(
        process?.manufacturing_order?._id || process?.manufacturing_order,
      ),
    );
  };

  useEffect(() => {
    const tempRefNo = hash({ user: me?.userData?._id, date: new Date() });
    setReferenceNumber(tempRefNo);

    return () => {};
  }, []);

  const socketioHandleFunction = {
    onSuccess: () => {
      setIsLoadingDialogOn(false);
      fetchManufacturingOrder();
      dispatch(actions.materialSplittingGetByProcess(process?._id));
      window.alert('บันทึกข้อมูลให้กระบวนการผลิตสำเร็จ');
    },
    onFail: (args) => {
      setIsLoadingDialogOn(false);
      console.log('Error', args?.error);
      window.alert(`บันทึกข้อมูลไม่สำเร็จ ${args?.message}`);
    },
  };

  const sendServerToStartProcess = async () => {
    await handleStartProcess({
      dispatch,
      currentProcess: process,
      employeeId: me?.userData?._id,
      handleSuccess: fetchManufacturingOrder,
      timestamp: startTime,
    });

    setHandleStartOpen(false);
  };

  const handleRecieveMaterial = async (data) => {
    try {
      await dispatch(
        actions.processRecieveMaterial({
          ...data,
          materials: [
            {
              material: data?.material?._id,
              quantity: data?.quantity,
            },
          ],
          employee: me?.userData?._id,
          process: process?._id,
          referenceNumber,
          material_splitting_process: materialSplittingProcess?._id,
          index: 0,
        }),
      );

      setIsLoadingDialogOn(true);
    } catch (error) {
      alert(`ไม่สามารถแก้ไขได้ ${error?.message}`);
    }
  };

  const allDisplayPlugin = _.map(process?.steps, (eachStep) => ({
    plugin: eachStep?.plugin,
    use_plugin_instead: eachStep?.use_plugin_instead,
  }));

  const usedPlugin = _.filter(allDisplayPlugin, { use_plugin_instead: true });

  const handleClose = async () => {
    if (
      !processStatus.step.isCurrentStepComplete &&
      (currentStep.material_associate || !_.isEmpty(currentStep?.measurements))
    ) {
      history.push(
        `/mms/process/handle-next-step/${process?._id}?fetch-txn=true`,
      );
    } else if (!processStatus.step.isCurrentStepComplete) {
      // ถ้ายังไม่ปิด ให้ปรับให้ Step สุดท้ายเป็น Complete
      await handleCloseWork(dispatch, process, me?.userData?._id, async () => {
        await fetchManufacturingOrder();
        setHandleProdDialogOpen(true);
      });
    } else {
      setHandleProdDialogOpen(true);
    }
  };

  // ปรับเป็นสถานะ รอจำหน่าย
  const handlePurchase = async () => {
    setHandleProdDialogOpen(false);
    await handleToPurchase(
      dispatch,
      process,
      me?.userData?._id,
      fetchManufacturingOrder,
    );
  };

  // const handleGoNext = async () => {
  //   try {
  //     await dispatch(
  //       actions.materialSplittingPut(materialSplittingProcess?._id, {
  //         current_hover_index:
  //           materialSplittingProcess?.current_hover_index + 1,
  //       }),
  //     );

  //     await dispatch(
  //       actions.manufacturingOrderGet(process?.manufacturing_order),
  //     );

  //     alert('อัพเดทการทำงานสำเร็จ');
  //   } catch (error) {
  //     alert(`ไม่สามารถเลื่อนขั้นตอนได้ ${error?.message}`);
  //   }
  // };

  // เช็คว่าอยู่ใน Step สุดท้ายหรือไม่ Step สุดท้าย Complete แล้วหรือไม่
  const renderLatestStepStatus = () => {
    if (processStatus.processStatus.isGreatestComplete) {
      return (
        <div className="my-2">
          {container ? (
            <Card>
              <CardContent>
                <div className="flex gap-2 justify-center">
                  <div className="bg-green-500 rounded-full p-2 w-min text-white">
                    <CheckIcon size={16} />
                  </div>
                  <div className="text-center self-center">
                    ไลน์การผลิตนี้ดำเนินการสำเร็จแล้ว
                  </div>
                </div>
              </CardContent>{' '}
            </Card>
          ) : (
            <div className="flex gap-2 justify-center">
              <div className="bg-green-500 rounded-full p-2 w-min text-white">
                <CheckIcon size={16} />
              </div>
              <div className="text-center self-center">
                ไลน์การผลิตนี้ดำเนินการสำเร็จแล้ว
              </div>
            </div>
          )}
        </div>
      );
    }
    return <div></div>;
  };

  // สำหรับการยังไม่เริ่มต้น Process
  const renderMaterialRequestInfo = () => {
    if (!processStatus.processStatus.isReceiveAllMaterial) {
      return (
        <div>
          {container ? (
            <Card>
              <CardContent>
                <Alert severity="warning">
                  <AlertTitle>
                    ยังไม่สามารถเริ่มการผลิตได้
                    เนื่องจากการขอเบิกวัตถุดิบยังไม่แล้วเสร็จ
                  </AlertTitle>
                  <Card className="rounded-lg  border p-2">
                    <MaterialWithdrawInfoTable
                      currentMaterialRequest={
                        processStatus.currentMaterialRequest
                      }
                    />
                  </Card>
                </Alert>
              </CardContent>
            </Card>
          ) : (
            <Alert severity="warning">
              <AlertTitle>
                ยังไม่สามารถเริ่มการผลิตได้
                เนื่องจากการขอเบิกวัตถุดิบยังไม่แล้วเสร็จ
              </AlertTitle>
              <Card className="rounded-lg  border p-2">
                <MaterialWithdrawInfoTable
                  currentMaterialRequest={processStatus.currentMaterialRequest}
                />
              </Card>
            </Alert>
          )}
        </div>
      );
    }

    return <div></div>;
  };

  const renderAddAsMaterialOnHandleProductDialog = () => {
    if (information?.setting?.mms?.productionHandler?.addAsMaterial) {
      if (information?.setting?.mms?.productionHandler?.addToRightTypeOnly) {
        if (process?.produce_material_instead) {
          return (
            <Button
              variant="contained"
              color="teal"
              type="button"
              onClick={() =>
                history.push(`/mms/process/add-inventory/${process?._id}`)
              }
              fullWidth
            >
              <span className="font-display">เพิ่มลงคลังวัตถุดิบ </span>
            </Button>
          );
        }
        return <div></div>;
      }

      return (
        <Button
          variant="contained"
          color="teal"
          type="button"
          onClick={() =>
            history.push(`/mms/process/add-inventory/${process?._id}`)
          }
        >
          <span className="font-display">เพิ่มลงคลังวัตถุดิบ </span>
        </Button>
      );
    }
    return <div></div>;
  };
  const renderAddWarehouseOnHandleProductDialog = () => {
    if (information?.setting?.mms?.productionHandler?.addToWarehouse) {
      if (information?.setting?.mms?.productionHandler?.addToRightTypeOnly) {
        if (!process?.produce_material_instead) {
          return (
            <Button
              variant="contained"
              color="success"
              type="button"
              className="w-1/3 px-2"
              onClick={() => {
                setHandleProdDialogOpen(false);
                if (mmsSetting?.verifiedWarehouseAdd) {
                  history.push(
                    `/mms/process/request-add-warehouse/${process?._id}`,
                  );
                } else {
                  history.push(`/mms/process/add-warehouse/${process?._id}`);
                }
              }}
            >
              <span className="font-display">เพิ่มลงในคลังสินค้า</span>
            </Button>
          );
        }
        return <div></div>;
      }

      return (
        <Button
          variant="contained"
          color="success"
          type="button"
          className="w-1/3 px-2"
          onClick={() => {
            setHandleProdDialogOpen(false);
            if (mmsSetting?.verifiedWarehouseAdd) {
              history.push(
                `/mms/process/request-add-warehouse/${process?._id}`,
              );
            } else {
              history.push(`/mms/process/add-warehouse/${process?._id}`);
            }
          }}
        >
          <span className="font-display">เพิ่มลงในคลังสินค้า</span>
        </Button>
      );
    }
    return <div></div>;
  };

  const handleProductDialog = () => (
    <Dialog
      open={handleProdDialogOpen}
      onClose={() => setHandleProdDialogOpen(false)}
    >
      <DialogTitle>
        {information?.setting?.mms?.productionHandler?.productionHandlerName ||
          'ดำเนินการกับผลผลิต'}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          ต้องการดำเนินการกับผลผลิตด้วยวิธีการใด
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <div className="flex justify-center flex-wrap gap-2">
          {information?.setting?.mms?.productionHandler?.addToWarehouse &&
            renderAddWarehouseOnHandleProductDialog()}
          {information?.setting?.mms?.productionHandler?.markAsFinished && (
            <Button
              variant="contained"
              color="success"
              type="button"
              className="w-1/3 px-2"
              onClick={() => handlePurchase()}
            >
              <div className="font-display">ปิดการผลิต</div>
            </Button>
          )}
          {information?.setting?.mms?.productionHandler?.openBill && (
            <Button
              variant="contained"
              color="secondary"
              type="button"
              className="w-1/3 px-2 "
              onClick={() => handlePurchase()}
            >
              <span className="font-display">ยืนยันการเปิดบิล</span>
            </Button>
          )}
          {information?.setting?.mms?.productionHandler?.addAsMaterial &&
            renderAddAsMaterialOnHandleProductDialog()}
          <Button
            variant="contained"
            color="inherit"
            className="w-1/3 px-2"
            onClick={() => {
              setHandleProdDialogOpen(false);
            }}
          >
            <span className="font-display">ยกเลิก</span>
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );

  const handleStartWorkDialog = () => (
    <Dialog open={handleStartOpen} onClose={() => setHandleStartOpen(false)}>
      <DialogTitle>เริ่มการผลิต</DialogTitle>
      <DialogContent>
        {mmsSetting?.timing?.manuallyTimeStamping ? (
          <div className="min-w-60">
            <div className="py-4">ระบุเวลาเริ่มการผลิต</div>
            <DesktopDateTimepicker
              fullWidth
              label="เวลาที่เริ่มทำงาน"
              setValue={setStartTime}
              value={startTime}
            />{' '}
          </div>
        ) : (
          <div>
            {' '}
            <div className="my-2 min-w-60">ยืนยันการเริ่มผลิต</div>
          </div>
        )}
        <div className="my-2">
          <Button
            variant="contained"
            onClick={() => sendServerToStartProcess()}
          >
            ยืนยันการเริ่ม
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );

  const renderControlBox = () => {
    if (process?.completed === false) {
      return (
        <div
          className={`flex my-4 gap-2 justify-${justify} flex-col md:flex-row`}
        >
          {processStatus.allow.allowRequestMaterial &&
            //     processStatus.processStatus.isReqMaterialInProcess &&
            processStatus.processStatus.isRequireVerifyRequest && (
              <Link
                to={`/mms/process/request-withdraw-material/${process?._id}`}
                className="full-width"
              >
                <Button variant="contained" color="warning" fullWidth>
                  <ClipboardIcon size={16} className="mr-2" />
                  <span className="font-display"> ขอเบิกวัตถุดิบ</span>
                </Button>
              </Link>
            )}
          <Link
            to={`/mms/process/return-material/${process?._id}`}
            className="full-width"
            hidden={!processStatus.allow.allowReturnMaterial}
          >
            <Button
              disabled={!processStatus.allow.allowReturnMaterial}
              variant="contained"
              fullWidth
              color="primary"
            >
              <CornerDownLeftIcon size={16} className="mr-2" />
              <span className="font-display"> คืนของ</span>
            </Button>
          </Link>
          {_.map(usedPlugin, (eachPlugin, index) => {
            const foundedPlugin = _.find(
              AllPluginInfo,
              (eachFounedPlugin) => eachPlugin?.plugin === eachFounedPlugin?.id,
            );

            const pluginOnStep = _.find(
              process?.steps,
              (eachStep) => eachStep?.plugin === eachPlugin?.plugin,
            );

            const foundedPluginIndex = _.findIndex(
              process?.steps,
              (eachStep) => eachStep?.plugin === eachPlugin?.plugin,
            );

            return (
              <Button
                onClick={() => {
                  history.push(
                    `/mms/works/plugin/edit/${eachPlugin?.plugin}/${pluginOnStep?._id}`,
                  );
                }}
                disabled={
                  !processStatus.allow.allowSkipStep ||
                  process?.current?.index < foundedPluginIndex
                }
                variant="contained"
                color="info"
                key={index}
              >
                <BoxIcon size={16} className="mr-2" />
                <span className="font-display">
                  {foundedPlugin?.buttonTitle || ''}
                </span>
              </Button>
            );
          })}

          <div className="full-width">
            <Button
              variant="contained"
              color="cyan"
              fullWidth
              onClick={() => {
                history.push(
                  `/mms/process/splitting-recieve-material/${process?._id}/${materialSplittingProcess?._id}`,
                );
              }}
            >
              <ClipboardIcon size={16} className="mr-2" />
              <span className="font-display"> รับวัตถุดิบจากผู้ผลิต</span>
            </Button>
          </div>

          <Button
            //      disabled={!processStatus.allow.allowStopProcess}
            variant="contained"
            color="success"
            onClick={() => {
              history.push(
                `/mms/process/splitting-close/${process?._id}/${materialSplittingProcess?._id}`,
              );
            }}
          >
            <CheckSquareIcon size={16} className="mr-2" />
            <span className="font-display">ปิดงานและใส่ของในคลังวัตถุดิบ</span>
          </Button>
        </div>
      );
    }
    return <div></div>;
  };

  return (
    <div className="mt-2">
      <div>
        <LoadingDialogWithTimeout
          isLoading={isLoadingDialogOn}
          label="กำลังเลื่อนขั้นตอนของงาน"
          action="REFRESH"
        />
        <SocketIOLoading
          referenceNumber={referenceNumber}
          taskCode={MQ_TASK.MOVING_STEP_UP.status_code}
          handleFail={socketioHandleFunction.onFail}
          handleSuccess={socketioHandleFunction.onSuccess}
        />{' '}
        <SocketIOLoading
          referenceNumber={referenceNumber}
          taskCode={MQ_TASK.RECIEVE_MINOR_MATERIAL.status_code}
          handleFail={socketioHandleFunction.onFail}
          handleSuccess={socketioHandleFunction.onSuccess}
        />{' '}
        <SocketIOLoading
          referenceNumber={referenceNumber}
          taskCode={MQ_TASK.RECIEVE_MATERIAL.status_code}
          handleFail={socketioHandleFunction.onFail}
          handleSuccess={socketioHandleFunction.onSuccess}
        />
        {handleProductDialog()}
        {handleStartWorkDialog()}
        {renderMaterialRequestInfo()}
        {renderLatestStepStatus()}
        {renderControlBox()}
      </div>
    </div>
  );
};

export default MaterialSplitterControlBox;

MaterialSplitterControlBox.propTypes = {
  process: PropTypes.object,
  currentStep: PropTypes.object,
  justify: PropTypes.string,
  container: PropTypes.bool,
};
