import React, { useState } from 'react';
import Chart from 'react-apexcharts';
import { ChevronDown, ChevronUp } from 'react-feather';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import {
  converseMaterialOrProductUnit,
  currencyFormatter,
  MMS,
} from '../../../utils/functions';
import MultiUnitProdOrMatBox from '../Rendering/MultiUnitProdOrMatBox';

const BoxRemainingMaterial = ({
  manufacturingMaterials,
  showGraph = true,
  selectedProcess,
  showTotal = false,
  showPercent = false,
  mainMaterial = null,
  displayDetailOnlyMainMaterial = false,
}) => {
  const {
    MaterialAnalyzer: { unNullManufacMaterialAnalyzedArrayAnalzer },
  } = MMS;

  const totalAmount = _.sumBy(
    unNullManufacMaterialAnalyzedArrayAnalzer(manufacturingMaterials),
    'resultAmount',
  );

  const attachedManufacMaterial = _.map(manufacturingMaterials, (each) => ({
    ...each,
    material: {
      ...each?.material,
      main_material: each?.material?._id === mainMaterial,
    },
  }));

  const materialAnalyzer = unNullManufacMaterialAnalyzedArrayAnalzer(
    attachedManufacMaterial,
  );

  const materialAnalyzerWithMergedFlag = _.map(materialAnalyzer, (each) => ({
    ...each,
    isMerged: each?.resultAmount === 0 && each?.mergedAmount > 0,
  }));

  const orderedMaterial = _.orderBy(
    materialAnalyzerWithMergedFlag,
    ['isMerged', 'materialInfo.main_material', 'materialInfo.name'],
    ['asc', 'desc', 'asc'],
  );

  const mergeOnlyMaterial = _.filter(
    orderedMaterial,
    (each) => each?.isMerged === false,
  );

  const isMergedMaterialContain = _.some(orderedMaterial, ['isMerged', true]);
  const [openMergedContain, setOpenMergedContain] = useState(false);

  const renderMaterialInfo = (_material, index) => (
    <div key={index} className="m-1 p-2 rounded-md shadow-md">
      <div className="flex flex-wrap justify-between">
        <div>
          <div className="text-sm text-gray-600">
            <span>{_material?.materialInfo?.type_code}</span>{' '}
            {_material?.materialInfo?.name}
          </div>
          <div className="font-semibold  text-gray-600">
            <MultiUnitProdOrMatBox
              foundMaterial={_material?.materialInfo}
              quantity={_material?.resultAmount}
              noWrap
            />
          </div>
          {showPercent && (
            <div className="font-semibold  text-yellow-500">
              {currencyFormatter.format(
                (_material?.resultAmount / totalAmount) * 100,
              )}{' '}
              %
            </div>
          )}
        </div>
        <div className="self-center">
          <Link
            className="self-center"
            to={`/mms/manufacturing-orders/material/${selectedProcess?._id}/${_material?.materialInfo?._id}`}
          >
            <Button size="small" variant="outlined">
              รายละเอียด
            </Button>
          </Link>
        </div>
      </div>
      {showGraph &&
        (!displayDetailOnlyMainMaterial ||
          (displayDetailOnlyMainMaterial &&
            _material?.materialInfo?.main_material)) && (
          <Chart
            type="bar"
            series={[
              {
                name: 'สูญเสีย',
                data: [_material?.wastesAmount || 0],
              },
              {
                name: 'ถูกรวมไปกับวัตถุดิบอื่น',
                data: [_material?.mergedAmount || 0],
              },
              {
                name: 'เบิกมา',
                data: [
                  _material?.requestAmount -
                    _material?.mergedAmount -
                    _material?.wastesAmount +
                    _material?.mergingAmount || 0,
                ],
              },
            ]}
            options={{
              chart: {
                type: 'bar',
                stacked: true,
                stackType: '100%',
                toolbar: {
                  show: false,
                },
                offsetX: 0,
                offsetY: 0,
              },

              colors: ['#008FFB', '#FF4560', '#00E396', '#FEB019', '#FF9800'],

              plotOptions: {
                bar: {
                  horizontal: true,
                },
              },
              xaxis: {
                categories: [_material?.materialInfo?.name || ''],
                labels: {
                  show: false,
                },
              },
              yaxis: {
                show: false,
              },
              legend: {
                show: false,
              },
              tooltip: {
                y: {
                  formatter: (val) =>
                    ` ${val.toFixed(3)} ${
                      _material?.materialInfo?.use_unit_conversion
                        ? `${
                            _material?.materialInfo?.unit_input?.short_sign ||
                            ''
                          } (${converseMaterialOrProductUnit(
                            _material?.materialInfo,
                            val,
                          )} ${
                            _material?.materialInfo?.unit_output?.short_sign ||
                            ''
                          }) `
                        : _material?.materialInfo?.unit
                    }`,
                },
              },
              grid: {
                show: false,
                xaxis: {
                  lines: {
                    show: false,
                  },
                },
                yaxis: {
                  lines: {
                    show: false,
                  },
                },
              },
              padding: {
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
              },
            }}
            height={80}
          />
        )}
    </div>
  );

  return (
    <div>
      <div className="my-2 ml-2">
        {showTotal && (
          <div className="my-2">
            <div className="text-sm">น้ำหนักรวม</div>
            <div className="text-xl font-semibold ">
              {currencyFormatter.format(totalAmount)}
            </div>
          </div>
        )}
        {isMergedMaterialContain && !openMergedContain ? (
          <div>
            {_.map(mergeOnlyMaterial, (_material, materialIndex) =>
              renderMaterialInfo(_material, materialIndex),
            )}
            <div className="mx-1">
              <Button
                variant="contained"
                color="inherit"
                fullWidth
                startIcon={<ChevronDown />}
                onClick={() => setOpenMergedContain(!openMergedContain)}
              >
                แสดงรายการที่ถูกรวมไปแล้ว
              </Button>
            </div>
          </div>
        ) : (
          <div>
            {_.map(orderedMaterial, (_material, materialIndex) =>
              renderMaterialInfo(_material, materialIndex),
            )}
          </div>
        )}
        {isMergedMaterialContain && openMergedContain && (
          <div className="mx-1 my-2">
            <Button
              variant="contained"
              color="inherit"
              fullWidth
              startIcon={<ChevronUp />}
              onClick={() => setOpenMergedContain(!openMergedContain)}
            >
              ซ่อนรายการที่ถูกรวมไปแล้ว
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

BoxRemainingMaterial.propTypes = {
  manufacturingMaterials: PropTypes.arrayOf(PropTypes.object),
  showGraph: PropTypes.bool,
  selectedProcess: PropTypes.object,
  showTotal: PropTypes.bool,
  showPercent: PropTypes.bool,
  mainMaterial: PropTypes.bool,
  displayDetailOnlyMainMaterial: PropTypes.bool,
};

export default BoxRemainingMaterial;
