/* eslint-disable import/no-unresolved */
/* eslint-disable import/prefer-default-export */
import pdfMake from 'addthaifont-pdfmake/build/pdfmake';
import 'addthaifont-pdfmake/build/vfs_fonts';
import _ from 'lodash';
import dayjs from 'dayjs';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';

import currencyFormatterTH from '../../utils/functions/currencyFormatterTH';
import { ReportHeader } from './common';

dayjs.locale('th');
dayjs.extend(buddhistEra);
dayjs.extend(LocalizedFormat);

pdfMake.fonts = {
  Sarabun: {
    normal: 'Sarabun-Light.ttf',
    bold: 'Sarabun-Regular.ttf',
    italics: 'Sarabun-LightItalic.ttf',
    bolditalics: 'Sarabun-Italic.ttf',
  },
};

const genProductTransaction = (data) => {
  return _.map(data, (_data, index) => [
    {
      text: index + 1,
      border: [true, false, true, true],
      fontSize: 10,
      colSpan: 1,
    },
    {
      text: `${dayjs(_data?.createdAt)
        .locale('th')
        .format('DD MMM BBBB')}\n เวลา ${new Date(
        _data?.createdAt,
      ).toLocaleTimeString('th')} น.`,
      border: [true, false, true, true],
      fontSize: 10,
      colSpan: 1,
    },
    {
      text: `${_data?.product_transaction_type?.name}`,
      border: [true, false, true, true],
      fontSize: 10,
      colSpan: 1,
    },
    {
      text: _.map(
        _data?.product_stock_lot,
        (lot) =>
          `${lot?.lotId?.lot_number || ''}${lot?.lotId?.lot_number || '-'}\n`,
      ),
      border: [true, false, true, true],
      fontSize: 10,
      colSpan: 1,
    },
    {
      text: _.map(
        _data?.product_stock_lot,
        (lot) =>
          `${lot?.lotId?.production_number?.prefix || ''}${
            lot?.lotId?.production_number?.running_number || '-'
          }\n`,
      ),
      border: [true, false, true, true],
      fontSize: 10,
      colSpan: 1,
    },
    {
      text: _.map(
        _data?.product_stock_lot,
        (lot) => `${lot?.lotId?.warehouse?.name}\n`,
      ),
      border: [true, false, true, true],
      fontSize: 10,
      colSpan: 1,
    },
    {
      text: _.map(
        _data?.product_stock_lot,
        (lot, index2) =>
          `${index2 + 1}. ${lot?.lotId?.product?.type_code || ''} ${
            lot?.lotId?.product?.name || ''
          }\n`,
      ),
      border: [true, false, true, true],
      fontSize: 10,
      colSpan: 1,
    },
    {
      text: _.map(
        _data?.product_stock_lot,
        (lot) =>
          `${currencyFormatterTH.format(lot?.lotId?.quantity || '0')} ${
            lot?.lotId?.product?.unit || ''
          }\n`,
      ),
      border: [true, false, true, true],
      fontSize: 10,
      colSpan: 1,
    },
    {
      text: _.map(
        _data?.product_stock_lot,
        (lot) =>
          `${currencyFormatterTH.format(lot?.lotId?.amount || '0')} ${
            lot?.lotId?.product?.unit || ''
          }\n`,
      ),
      border: [true, false, true, true],
      fontSize: 10,
      colSpan: 1,
    },
    {
      text: `${
        _data?.seller
          ? _data?.seller || '-'
          : _data?.product_stock_lot[0]?.lotId?.consignee_name || '-'
      } `,
      border: [true, false, true, true],
      fontSize: 10,
      colSpan: 1,
    },
    {
      text: `${_data?.remark}`,
      border: [true, false, true, true],
      fontSize: 10,
      colSpan: 1,
    },
  ]);
};

export const ProductHReport = (
  productTransaction,
  information,
  monthChang,
  isError,
) => {
  console.log('productTransaction', productTransaction);
  console.log('information', information);

  const productData = genProductTransaction(productTransaction);

  const docDefinition = {
    pageSize: 'A4',
    pageOrientation: 'landscape',
    pageMargins: [20, 40, 40, 20],
    defaultStyle: {
      font: 'Sarabun',
      fontSize: '16',
    },
    info: {
      title: 'ประวิติการจัดการสินค้า',
    },
    content: [
      isError ? ReportHeader(information, false) : ReportHeader(information),
      {
        margin: [0, 30, 30, 0],
        style: 'tableExample',
        layout: {
          fillColor(rowIndex) {
            return rowIndex === 0 ? '#c5cae9' : null;
          },
        },
        table: {
          widths: [
            '5%',
            '12%',
            '5%',
            '10%',
            '10%',
            '10%',
            '17%',
            '7%',
            '7%',
            '15%',
            '10%',
          ],
          headerRows: 1,
          dontBreakRows: true,
          body: [
            [
              {
                text: 'ลำดับ',
                colSpan: 1,
                fontSize: 10,
                bold: true,
              },
              {
                text: 'วันที่ดำเนินการ',
                colSpan: 1,
                fontSize: 10,
                bold: true,
              },
              {
                text: 'ประเภท',
                colSpan: 1,
                fontSize: 10,
                bold: true,
              },
              {
                text: 'หมายเลขล็อต',
                colSpan: 1,
                fontSize: 10,
                bold: true,
              },
              {
                text: 'เลขที่สั่งผลิต',
                colSpan: 1,
                fontSize: 10,
                bold: true,
              },
              {
                text: 'ชื่อคลัง',
                colSpan: 1,
                fontSize: 10,
                bold: true,
              },
              {
                text: 'สินค้า',
                colSpan: 1,
                fontSize: 10,
                bold: true,
              },
              {
                text: 'จำนวน',
                colSpan: 1,
                fontSize: 10,
                bold: true,
              },
              {
                text: 'คงเหลือ',
                colSpan: 1,
                fontSize: 10,
                bold: true,
              },
              {
                text: 'ผู้ดำเนินการ',
                colSpan: 1,
                fontSize: 10,
                bold: true,
              },
              {
                text: 'หมายเหตุ',
                colSpan: 1,
                fontSize: 10,
                bold: true,
              },
            ],
            ...productData,
          ],
        },
      },
    ],
    images: {
      logo: information?.logo?.url,
    },
  };
  pdfMake.createPdf(docDefinition).open();
};
