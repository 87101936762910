import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Button } from '@mui/material';
import { gql } from 'graphql-request';
import PropTypes from 'prop-types';

import {
  BackButton,
  DashboardElementForm,
  ViewTitle,
} from '../../../components';
import graphqlClient from '../../../configs/graphqlClient';
import * as actions from '../../../redux/actions';

/**
 * @function CreateDashboardElement
 * @description Display a collections or a list of CreateDashboardElement from database
 */

export default function CreateDashboardElement({ title, subtitle }) {
  const dispatch = useDispatch();
  const measurementType = useSelector((state) => state.measurementType);
  const resultingFunctionType = useSelector(
    (state) => state.resultingFunctionType,
  );
  const history = useHistory();
  const params = useParams();
  const page = 1;
  const [size, setSize] = useState(10);
  const {
    control,
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const query = gql`
    query FindMeasurement(
      $measurementTypeInput: MeasurementTypeInput
      $resultingFunctionTypeInput: ResultingFunctionTypeInput
    ) {
      findMeasurementTypes(input: $measurementTypeInput) {
        total
        rows {
          _id
          name
          type_code
        }
      }
      findResultingFunctionTypes(input: $resultingFunctionTypeInput) {
        total
        rows {
          _id
          name
        }
      }
    }
  `;

  const queryDataFromServer = async () => {
    try {
      const queryResult = await graphqlClient.request(query, {
        measurementTypeInput: { page, size },
        resultingFunctionTypeInput: { page, size },
      });
      const resultingFunctionTypeData = queryResult?.findResultingFunctionTypes;
      const measurementTypesData = queryResult?.findMeasurementTypes;
      dispatch(
        actions.resultingFunctionTypeStateSet(resultingFunctionTypeData),
      );
      dispatch(actions.measurementTypeStateSet(measurementTypesData));
    } catch (error) {
      alert('การดึงข้อมูลมีความผิดพลาด', error?.message);
      console.error(error);
    }
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [params, page, size]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const handleCreateDashboardElement = async (data) => {
    try {
      await dispatch(actions.dashboardElementCreate(data));
      history.goBack();
    } catch (error) {
      alert(`การสร้างส่วนประกอบของแดชบอร์ดผิดพลาด ${error?.message}`);
    }
  };

  return (
    <div>
      {renderTitle()}
      <BackButton />
      <form onSubmit={handleSubmit(handleCreateDashboardElement)}>
        <div className="my-2">
          <DashboardElementForm
            control={control}
            errors={errors}
            setValue={setValue}
            watch={watch}
            resultingFuctionType={resultingFunctionType}
            measurementType={measurementType}
            size={size}
            setSize={setSize}
          />
        </div>
        <div className="flex justify-end">
          <Button color="success" variant="contained" type="submit">
            บันทึก
          </Button>
        </div>
      </form>
    </div>
  );
}

CreateDashboardElement.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

CreateDashboardElement.defaultProps = {
  title: '',
  subtitle: '',
};
