import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { gql } from 'graphql-request';
import { useHistory, useParams } from 'react-router-dom';
import { Card, CardContent } from '@mui/material';
import dayjs from 'dayjs';

import {
  ViewTitle,
  Loading,
  Error,
  BackButton,
  ManufacturingOrderTable,
} from '../../../components';
import * as actions from '../../../redux/actions';
import graphqlClient from '../../../configs/graphqlClient';
import { currencyFormatter } from '../../../utils/functions';

/**
 * @function OnMonthReportManufacturingOrder
 * @description Display a collections or a list of OnMonthReportManufacturingOrder from database
 */

export default function OnMonthReportManufacturingOrder({ title, subtitle }) {
  const dispatch = useDispatch();
  const manufacturingOrder = useSelector((state) => state.manufacturingOrder);
  const history = useHistory();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const params = useParams();
  const selectedMonthDate = dayjs()
    .year(parseInt(params.year, 10))
    .month(parseInt(params.month, 10) - 1);

  const query = gql`
    query FindOnMonthReportManufacturingOrder(
      $manufacturingOrderinput: ManufacturingOrderInput
    ) {
      findManufacturingOrders(input: $manufacturingOrderinput) {
        total
        success
        incumbent
        fail
        allProcess
        successProcess
        failProcess
        incumbentProcess
        totalRevenue
        unSuccessRevenue
        cancledRevenue
        successButCancleMORevenue
        produceMaterial
        produceProduct
        rows {
          _id
          id
          assigner {
            firstname
            lastname
          }
          running_number
          customer {
            type_code
            name
            address
            phone_number
          }
          process {
            product {
              type_code
              name
            }
            produce_material_instead
            product_as_material {
              type_code
              name
            }
            current {
              index
              step {
                name
              }
            }
            customer {
              type_code
              name
            }
            quantity
            price_per_unit
            additional_unit
            remark
          }
          discount
          completed
          deleted
          start_date
          expected_date
          remark
        }
      }
    }
  `;

  const queryDataFromServer = async () => {
    try {
      const queryResult = await graphqlClient.request(query, {
        manufacturingOrderinput: {
          page,
          size,
          startDate: dayjs(selectedMonthDate)
            .startOf('month')
            .format('YYYY-MM-DD'),
          endDate: dayjs(selectedMonthDate).endOf('month').format('YYYY-MM-DD'),
          fetchProcess: true,
          analyzed: true,
          fetchMaterial: true,
          fetchProduct: true,
        },
      });

      const manufacturingOrderData = queryResult?.findManufacturingOrders;
      dispatch(actions.manufacturingOrderStateSet(manufacturingOrderData));
    } catch (err) {
      dispatch(actions.manufacMaterialError());
      console.error('Error on Fetching Manufacturing Order', err);
    }
  };

  useEffect(() => {
    dispatch(actions.manufacturingOrderReset());
    return () => {};
  }, []);

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [page, size, params]);

  const renderTitle = () => (
    <ViewTitle
      title={`${title} ${dayjs(selectedMonthDate)?.format('MMMM')} ${dayjs(
        selectedMonthDate,
      )?.format('BBBB')}`}
      subtitle={subtitle}
    />
  );

  const renderAnalysis = () => (
    <Card>
      <CardContent>
        <div className="font-display text-lg font-semibold ">
          รายงานประจำเดือน {dayjs(selectedMonthDate).format('MMMM BBBB')}
        </div>
        <div className="font-display pt-2 font-semibold">คำสั่งผลิต</div>
        <div className="flex flex-wrap my-2">
          <div className="w-1/4">
            <div className="text-sm">คำสั่งผลิตทั้งหมด</div>
            <div className="flex gap-2">
              <div className="text-3xl font-semibold">
                {manufacturingOrder?.total || 0}
              </div>
              <div className="self-end">รายการ</div>
            </div>
          </div>
          <div className="w-1/4">
            <div className="text-sm">สำเร็จ</div>
            <div className="flex gap-2">
              <div className="text-3xl font-semibold">
                {manufacturingOrder?.success || 0}
              </div>
              <div className="self-end">รายการ</div>
            </div>
          </div>
          <div className="w-1/4">
            <div className="text-sm">ยกเลิก</div>
            <div className="flex gap-2">
              <div className="text-3xl font-semibold">
                {manufacturingOrder?.fail || 0}
              </div>
              <div className="self-end">รายการ</div>
            </div>
          </div>
          <div className="w-1/4">
            <div className="text-sm">อยู่ระหว่างการดำเนินการ</div>
            <div className="flex gap-2">
              <div className="text-3xl font-semibold">
                {manufacturingOrder?.incumbent || 0}
              </div>
              <div className="self-end">รายการ</div>
            </div>
          </div>
        </div>
        <div className="font-display pt-2 font-semibold">ผลผลิต</div>
        <div className="flex flex-wrap my-2 ">
          <div className="w-1/4">
            <div className="text-sm">ผลิตสินค้า/วัตถุดิบรวม</div>
            <div className="flex gap-2">
              <div className="text-3xl font-semibold">
                {manufacturingOrder?.allProcess || 0}
              </div>
              <div className="self-end">รายการ</div>
            </div>
          </div>
          <div className="w-1/4">
            <div className="text-sm">สำเร็จ</div>
            <div className="flex gap-2">
              <div className="text-3xl font-semibold">
                {manufacturingOrder?.successProcess || 0}
              </div>
              <div className="self-end">รายการ</div>
            </div>
          </div>
          <div className="w-1/4">
            <div className="text-sm">ยกเลิก</div>
            <div className="flex gap-2">
              <div className="text-3xl font-semibold">
                {manufacturingOrder?.failProcess || 0}
              </div>
              <div className="self-end">รายการ</div>
            </div>
          </div>
          <div className="w-1/4">
            <div className="text-sm">อยู่ระหว่างการดำเนินการ</div>
            <div className="flex gap-2">
              <div className="text-3xl font-semibold">
                {manufacturingOrder?.incumbentProcess || 0}
              </div>
              <div className="self-end">รายการ</div>
            </div>
          </div>
        </div>
        <div className="flex flex-wrap my-2 py-2">
          <div className="w-2/4">
            <div className="text-sm">ผลิตเป็นสินค้า</div>
            <div className="flex gap-2">
              <div className="text-3xl font-semibold">
                {manufacturingOrder?.produceProduct || 0}
              </div>
              <div className="self-end">รายการ</div>
            </div>
          </div>
          <div className="w-2/4">
            <div className="text-sm">ผลิตเป็นวัตถุดิบ</div>
            <div className="flex gap-2">
              <div className="text-3xl font-semibold">
                {manufacturingOrder?.produceMaterial || 0}
              </div>
              <div className="self-end">รายการ</div>
            </div>
          </div>
        </div>
        <div className="font-display pt-2 font-semibold">รายได้</div>

        <div className="flex flex-wrap my-2 py-2">
          <div className="w-1/4">
            <div className="text-sm">รายได้รวมจากงานที่สำเร็จ</div>
            <div className="flex gap-2">
              <div className="text-3xl font-semibold">
                {currencyFormatter.format(
                  manufacturingOrder?.totalRevenue || 0,
                )}
              </div>
              <div className="self-end">บาท</div>
            </div>
          </div>
          <div className="w-1/4">
            <div className="text-sm">รายได้รวมในงานที่ยกเลิก</div>
            <div className="flex gap-2">
              <div className="text-3xl font-semibold">
                {currencyFormatter.format(
                  manufacturingOrder?.cancledRevenue || 0,
                )}
              </div>
              <div className="self-end">บาท</div>
            </div>
          </div>
          <div className="w-1/4">
            <div className="text-sm">รายได้รวมในงานที่ระหว่างดำเนินการ</div>
            <div className="flex gap-2">
              <div className="text-3xl font-semibold">
                {currencyFormatter.format(
                  manufacturingOrder?.unSuccessRevenue || 0,
                )}
              </div>
              <div className="self-end">บาท</div>
            </div>
          </div>
          <div className="w-1/4">
            <div className="text-sm">
              รายได้จากงานที่สำเร็จแต่คำสั่งผลิตไม่สำเร็จ
            </div>
            <div className="flex gap-2">
              <div className="text-3xl font-semibold">
                {currencyFormatter.format(
                  manufacturingOrder?.successButCancleMORevenue || 0,
                )}
              </div>
              <div className="self-end">บาท</div>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );

  const renderTable = () => (
    <div>
      <ManufacturingOrderTable
        page={page}
        size={size}
        manufacturingOrder={manufacturingOrder}
        handleChangePage={(event, newPage) => {
          setPage(newPage + 1);
        }}
        handleChangeRowsPerPage={(event) => {
          setSize(event.target.value);
          setPage(1);
        }}
        displayCancleButton={false}
        displayStatusBadge={true}
        total={manufacturingOrder?.total}
        showInsideStatus={false}
      />
    </div>
  );

  if (manufacturingOrder.isLoading) {
    return <Loading />;
  }

  if (!manufacturingOrder.isLoading && manufacturingOrder.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div className="flex">
          <BackButton />
        </div>
        <div className="my-2">
          {renderAnalysis()}
          {renderTable()}
        </div>
      </div>
    );
  }
  return <Error />;
}

OnMonthReportManufacturingOrder.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

OnMonthReportManufacturingOrder.defaultProps = {
  title: '',
  subtitle: '',
};
