/* eslint-disable arrow-body-style */
import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { backConverseMaterialOrProductUnit } from '../../../utils/functions';
import MultiUnitProdOrMatBox from '../Rendering/MultiUnitProdOrMatBox';

const ProductBomBox = ({ product, selectedUnit, isInverse = false }) => {
  const reversedBillOfMaterial = (productBom) =>
    _.map(productBom, (eachBom) => {
      const backwardBom = backConverseMaterialOrProductUnit(
        product,
        eachBom?.amount,
      );
      return { ...eachBom, amount: backwardBom };
    });

  return (
    <div>
      <div className="border rounded-md p-2">
        <div className="font-display px-4 my-2">
          ปริมาณวัตถุดิบที่ใช้ต่อ {product?.name} 1 {selectedUnit}
        </div>
        <div className="flex flex-wrap mx-2">
          {_.map(_.groupBy(product?.bill_of_materials, 'tag'), (eachBom, k) => (
            <div key={k} className="w-full md:w-1/2 lg:w-1/3 p-1">
              <div className="p-4 rounded-md shadow-md">
                <div className="my-2 font-semibold">
                  กลุ่ม {eachBom?.[0]?.tag}
                </div>
                <div className="divide-y">
                  {_.map(
                    isInverse ? reversedBillOfMaterial(eachBom) : eachBom,
                    (eachMaterial, index) => (
                      <div
                        key={index}
                        className="my-1 py-2 flex justify-between"
                      >
                        <div> {eachMaterial?.material?.name} </div>
                        <div>
                          <MultiUnitProdOrMatBox
                            noWrap
                            foundMaterial={eachMaterial?.material}
                            quantity={eachMaterial?.amount}
                          />
                        </div>
                      </div>
                    ),
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
        {_.isEmpty(product?.bill_of_materials) && (
          <div className="text-center my-4">ยังไม่มีข้อมูลส่วนผสม</div>
        )}
      </div>
    </div>
  );
};

ProductBomBox.propTypes = {
  product: PropTypes.object,
  selectedUnit: PropTypes.string,
  isInverse: PropTypes.bool,
};

export default ProductBomBox;
