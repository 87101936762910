import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { Button, Card } from '@mui/material';
import { useParams, useHistory } from 'react-router';
import PropTypes from 'prop-types';
import _ from 'lodash';

import * as actions from '../../redux/actions';
import { BackButton } from '../../components/Button';
import {
  OrderForm,
  OrderSelectMaterialForm,
  SelectProductForm,
  OrderTotalSummaryForm,
} from '../../components/Forms';
import { Loading } from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';

const EditOrder = ({ title, subtitle }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const order = useSelector((state) => state.order);
  const customer = useSelector((state) => state.customer);
  const shippingType = useSelector((state) => state.shippingType);
  const paymentType = useSelector((state) => state.paymentType);
  const product = useSelector((state) => state.product);
  const me = useSelector((state) => state.me);
  const productTransactionType = useSelector(
    (state) => state.productTransactionType,
  );
  const material = useSelector((state) => state.material);
  const materialTransactionType = useSelector(
    (state) => state.materialTransactionType,
  );
  const history = useHistory();

  const [name] = useState('');
  const [page] = useState(1);
  const [size] = useState('');
  const [selectProduct, setSelectProduct] = useState([]);
  const [selectMaterial, setSelectMaterial] = useState([]);
  const [discount, setDiscount] = useState(0);
  const [reload, setReload] = useState(false);

  const {
    formState: { errors },
    handleSubmit,
    control,
    getValues,
    setValue,
    watch,
  } = useForm({
    defaultValues: {
      status: order?.status,
    },
  });

  useEffect(() => {
    const getOrder = async () => {
      try {
        await dispatch(actions.orderGet(id));
      } catch (error) {
        console.error('Cannot Get Order');
      }
    };

    const fetchCustomers = async () => {
      try {
        await dispatch(actions.customerAll({ name, page, size }));
      } catch (error) {
        console.error('Customers get all error', error);
      }
    };
    const fetchShippingType = async () => {
      try {
        await dispatch(actions.shippingTypeAll({ name, page, size }));
      } catch (error) {
        console.error('Cannot Fetch Shipping Type');
      }
    };

    const fetchPaymentType = async () => {
      try {
        await dispatch(actions.paymentTypeAll({ name, page, size }));
      } catch (error) {
        console.error('Cannot Fetch Payment Type');
      }
    };

    const fetchProducts = async () => {
      try {
        await dispatch(actions.productAll({ name: '', page, size: '' }));
      } catch (error) {
        console.error('Cannot Fetch Product');
      }
    };

    const fetchMaterial = async () => {
      try {
        await dispatch(actions.materialAll({ name: '', page, size: '' }));
      } catch (error) {
        console.error('Cannot Fetch Material');
      }
    };

    const fetchProductTransactionTypes = async () => {
      try {
        await dispatch(
          actions.productTransactionTypeAll({ name: '', page, size: '' }),
        );
      } catch (error) {
        console.error('Cannot Fetch Product');
      }
    };

    const fetchMaterialTxnType = async () => {
      try {
        await dispatch(
          actions.materialTransactionTypeAll({ name: '', page, size: '' }),
        );
      } catch (error) {
        console.error('Cannot Fetch Material Txn Type');
      }
    };

    getOrder();
    fetchCustomers();
    fetchShippingType();
    fetchPaymentType();
    fetchProducts();
    fetchMaterial();
    fetchProductTransactionTypes();
    fetchMaterialTxnType();
    return () => {};
  }, [id]);

  useEffect(() => {
    if (order?.product_of_order) {
      const productOfOrder = _.map(
        order?.product_of_order,
        function (mapProduct) {
          return {
            product: mapProduct.product,
            quantity: mapProduct.quantity,
            price: mapProduct.price,
          };
        },
      );

      setSelectProduct(productOfOrder);
      setValue('product_of_order', productOfOrder);
    }

    if (order?.material_of_order) {
      const materialOfOrder = _.map(
        order?.material_of_order,
        function (mapMaterial) {
          return {
            material: mapMaterial.material,
            quantity: mapMaterial.quantity,
            price: mapMaterial.price,
          };
        },
      );

      setSelectMaterial(materialOfOrder);
      setValue('material_of_order', materialOfOrder);
    }

    return () => {};
  }, [order]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const onSubmit = async (data, event) => {
    try {
      data.commerce.discount = parseInt(discount, 10);
      data.commerce.price =
        _.sumBy(selectProduct, (_product) => parseInt(_product.price, 10)) +
        _.sumBy(selectMaterial, (_material) => parseInt(_material.price, 10));
      data.commerce.total_price =
        _.sumBy(selectProduct, (_product) => parseInt(_product.price, 10)) +
        _.sumBy(selectMaterial, (_material) => parseInt(_material.price, 10)) -
        parseInt(discount, 10);
      data.status = order?.status;
      const preprocessedData = {
        ...data,
        product_of_order: selectProduct,
        material_of_order: selectMaterial,
      };
      event.preventDefault();
      await dispatch(actions.orderPut(id, preprocessedData));
      await dispatch(actions.orderGet(id));
      history.goBack();
      alert('สำเร็จ');
    } catch (error) {
      console.log(error);
    }
  };

  const isPageLoading = () =>
    customer.isLoading ||
    order.isLoading ||
    shippingType.isLoading ||
    paymentType.isLoading ||
    productTransactionType.isLoading ||
    product.isLoading;

  const isPageCompleted = () =>
    customer.isCompleted &&
    order.isCompleted &&
    shippingType.isCompleted &&
    paymentType.isCompleted &&
    productTransactionType.isCompleted &&
    product.isCompleted &&
    !order.rows;

  const renderForm = () => (
    <Card className="p-6">
      <OrderForm
        Controller={Controller}
        control={control}
        errors={errors}
        customers={customer}
        paymentType={paymentType}
        shippingType={shippingType}
        order={order}
        productTransactionType={productTransactionType.rows}
        me={me}
        materialTransactionType={materialTransactionType.rows}
      />
    </Card>
  );

  const renderSelectProduct = () => (
    <div>
      <div className="my-2">
        <Card>
          <SelectProductForm
            product={product.rows}
            setSelectProduct={setSelectProduct}
            discount={discount}
            order={order}
            control={control}
            errors={errors}
            getValues={getValues}
            reload={reload}
            setReload={setReload}
            setValue={setValue}
            watch={watch}
          />
        </Card>
      </div>
      <div className="my-2">
        <Card>
          <OrderSelectMaterialForm
            control={control}
            discount={discount}
            errors={errors}
            getValues={getValues}
            order={order}
            material={material?.rows}
            reload={reload}
            setReload={setReload}
            setSelectMaterial={setSelectMaterial}
            setValue={setValue}
            watch={watch}
          />
        </Card>
      </div>
      <div className="my-2">
        <Card>
          <OrderTotalSummaryForm
            control={control}
            errors={errors}
            getValues={getValues}
            order={order}
            reload={reload}
            setDiscount={setDiscount}
            setReload={setReload}
          />
        </Card>
      </div>
    </div>
  );

  if (isPageLoading()) {
    return <Loading />;
  }

  if (isPageCompleted()) {
    return (
      <div>
        {renderTitle()}
        <div className="flex flex-row justify-start pb-4">
          <div>
            <BackButton />
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-row flex-wrap">
            <div className="w-full lg:w-1/3 px-1 py-2">{renderForm()}</div>
            <div className="w-full lg:w-2/3 px-1 py-2">
              {renderSelectProduct()}
            </div>
          </div>
          <div className="flex flex-row justify-end gap-1 py-4">
            <Button variant="contained" type="submit">
              บันทึก
            </Button>
          </div>
        </form>
      </div>
    );
  }
  return <Loading />;
};

EditOrder.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

EditOrder.defaultProps = {
  title: '',
  subtitle: '',
};

export default EditOrder;
