import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
  Backdrop,
  Button,
  Card,
  Fade,
  Modal,
  TextField,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Alert,
  AlertTitle,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import * as actions from '../../redux/actions';
import Loading from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';
import { CSVUpload } from '../../components/CSVUpload';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90vw',
  boxShadow: 24,
  p: 4,
};

function Customers({ title, subtitle }) {
  const dispatch = useDispatch();
  const customer = useSelector((state) => state.customer);
  const customerType = useSelector((state) => state.customerType);
  const paymentType = useSelector((state) => state.paymentType);

  const history = useHistory();
  const { control } = useForm();
  const [searchTerm, setSearchTerm] = useState('');
  const [name, setName] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(undefined);
  const [csvUploadOpen, setCsvUploadOpen] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [selectCustomerType, setSelectCustomerType] = useState('');
  const handleCSVOpen = () => setCsvUploadOpen(true);
  const handleCSVClose = () => {
    setCsvUploadOpen(false);
    setCsvData([]);
  };
  useEffect(() => {
    dispatch(actions.customerAll({
 name, page, size, selectCustomerType
}));
    dispatch(actions.customerTypeAll({ name: '' }));
    dispatch(actions.paymentTypeAll({ name: '' }));
    return () => {};
  }, [name, page, size, selectCustomerType]);

  useEffect(() => {
    setTotal(customer?.total);
    return () => {};
  }, [customer]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchTerm);
      setPage(1);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const handleDeleteCustomer = async (id) => {
    const confirm = window.confirm('ยืนยันการลบข้อมูล');
    if (confirm) {
      try {
        await dispatch(actions.customerDelete(id));
        await dispatch(actions.customerAll({ name, page, size }));
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handlePushToDetailCustomer = async (id) => {
    history.push(`/crm/customer/detail/${id}`);
  };

  const handlePushToCreateCustomer = () => {
    history.push('/crm/customer/create');
  };

  const handlePushToEditCustomer = async (id) => {
    history.push(`/crm/customer/edit/${id}`);
  };

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleSelectCustomerType = (type) => {
    setSelectCustomerType(type);
  };

  const renderAddButtom = () => (
    <div className="flex flex-row justify-end pb-4 gap-2">
      <Button
        variant="contained"
        onClick={() => {
          handlePushToCreateCustomer();
        }}
      >
        เพิ่ม
      </Button>
      <Button variant="contained" onClick={handleCSVOpen} color="success">
        อัพโหลด
      </Button>
    </div>
  );

  const handleAddFromFile = async () => {
    if (!_.isEmpty(csvData)) {
      const customerCSVData = _.map(csvData, (_csvData) => ({
        ..._csvData,
        contact: _.filter(
          [
            {
              name: _csvData['contact[0].name'],
              phone_number: _csvData['contact[0].phone_number'],
            },
            {
              name: _csvData['contact[1].name'],
              phone_number: _csvData['contact[1].phone_number'],
            },
            {
              name: _csvData['contact[2].name'],
              phone_number: _csvData['contact[2].phone_number'],
            },
          ],
          (_contact) => _contact?.name !== '',
        ),
      }));
      console.log('Customer CSV Data', customerCSVData);
      try {
        await dispatch(actions.customerCreate({ arr: customerCSVData }));
        alert('สำเร็จ');
        handleCSVClose();
        await dispatch(actions.customerAll({ name, page, size }));
      } catch (error) {
        alert('เพิ่มลูกค้า / คู่ค้าไม่สำเร็จ');
        console.error(error);
      }
    } else {
      alert('ไม่สามารถอ่านไฟล์รายการลูกค้าได้');
    }
  };

  const renderSearch = () => (
    <Card>
      <div className="p-4 flex flex-row gap-2">
        <div className="w-full md:w-1/2">
          <TextField
            label="ค้นหา"
            fullWidth
            size={'small'}
            id="outlined-start-adornment"
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <i className="fas fa-search"></i>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className="w-full md:w-1/2">
          <Controller
            name={'status_code'}
            control={control}
            defaultValue={''}
            rules={{ required: true }}
            render={({ field }) => (
              <FormControl sx={{ minWidth: 120 }} fullWidth={true}>
                <InputLabel id="type" size={'small'}>
                  ค้นหาด้วยประเภทลูกค้า
                </InputLabel>
                <Select
                  {...field}
                  label="ค้นหาด้วยประเภทลูกค้า"
                  size={'small'}
                  fullWidth
                  onChange={(e) => {
                    field.onChange(e.target.value);
                    handleSelectCustomerType(e.target.value);
                  }}
                >
                  <MenuItem value="">ทั้งหมด</MenuItem>
                  {_.map(customerType.rows, (_customerType) => (
                      <MenuItem
                        value={_customerType?._id}
                        key={_customerType?._id}
                      >
                        {_customerType?.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            )}
          />
        </div>
      </div>
    </Card>
  );

  const renderCSVUploadModal = () => (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={csvUploadOpen}
      onClose={handleCSVClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={csvUploadOpen}>
        <Card sx={style} className="max-w-4xl">
          <div className="pt-2">อัพโหลดข้อมูล</div>
          <div className="py-2 font-system">
            อัพโหลดไฟล์ .csv โดยมีโครงสร้างตามไฟล์เทมเพลต แถวที่ 1-2
            และแถวสุดท้าย จะไม่ถูกนำมาคิดในระบบ <br /> <br />
            * ในลูกค้าแต่ละรายการ บนไฟล์ .csv ท่านสามารถเพิ่มผู้ติดต่อได้ 1 - 3
            คน ต่อลูกค้า/คู่ค้า 1 ราย <br />
            ถ้าหากท่านต้องการเพิ่มผู้ติดต่อมากกว่า 3 ราย
            สามารถมาเพิ่มเติมได้อีกครั้งในระบบ
            <br />
            <Button>
              <Link to="/filetemplate/Customer.csv" target="_blank">
                ดาวน์โหลดเทมเพลต
              </Link>
            </Button>
          </div>
          <div className="my-2">
            <Alert severity="info">
              <div className="flex flex-wrap">
                <div className="w-full md:w-1/2 px-4">
                  {' '}
                  <AlertTitle>
                    โปรดแทนที่ข้อมูลใน <strong>ประเภทของลูกค้า</strong>{' '}
                    ด้วยรหัสดังต่อไปนี้
                  </AlertTitle>
                  <div className="border rounded-sm h-80 overflow-y-auto">
                    <Table
                      sx={{
                        '&:last-child td, &:last-child th': { border: 1 },
                      }}
                    >
                      <TableHead>
                        <TableCell>ชื่อประเภทของลูกค้า</TableCell>
                        <TableCell>รหัส</TableCell>
                      </TableHead>
                      <TableBody>
                        {_.map(customerType?.rows, (_customerType, index) => (
                          <TableRow key={index}>
                            <TableCell>{_customerType?.name} </TableCell>
                            <TableCell>{_customerType?._id} </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </div>
                </div>
                <div className="w-full md:w-1/2 px-4">
                  {' '}
                  <AlertTitle>
                    โปรดแทนที่ข้อมูลใน <strong>รูปแบบการชำระเงิน</strong>{' '}
                    ด้วยรหัสดังต่อไปนี้
                  </AlertTitle>
                  <div className="border rounded-sm h-80 overflow-y-auto">
                    <Table
                      sx={{
                        '&:last-child td, &:last-child th': { border: 1 },
                      }}
                    >
                      <TableHead>
                        <TableCell>ชื่อรูปแบบการชำระเงิน</TableCell>
                        <TableCell>รหัส</TableCell>
                      </TableHead>
                      <TableBody>
                        {_.map(paymentType?.rows, (_paymentType, index) => (
                          <TableRow key={index}>
                            <TableCell>{_paymentType?.name} </TableCell>
                            <TableCell>{_paymentType?._id} </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </div>
                </div>
              </div>
            </Alert>
          </div>
          <div className="py-2">
            <div className="flex justify-center">
              <div>
                <CSVUpload setCsvData={setCsvData} />
                {!_.isEmpty(csvData) && (
                  <div>
                    <p className="font-system my-1">
                      พบข้อมูล {_.size(csvData)} รายการ
                    </p>
                    <Button
                      color="primary"
                      variant="contained"
                      type="button"
                      onClick={handleAddFromFile}
                    >
                      บันทึก
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Card>
      </Fade>
    </Modal>
  );

  const renderTable = () => (
    <div className="my-2">
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <div className="font-bold">ลำดับที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> รหัสลูกค้า</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ชื่อ</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ประเภท</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ดำเนินการ</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(customer.rows) ? (
                customer.rows.map((row, index) => (
                  <TableRow
                    key={row.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {(page - 1) * size + index + 1}
                    </TableCell>
                    <TableCell>{`${row.type_code || '-'}`}</TableCell>
                    <TableCell>{`${row.name}`}</TableCell>
                    <TableCell>{`${row?.type?.name}`}</TableCell>
                    <TableCell>
                      <div className="flex flex-row flex-wrap gap-1">
                        <Button
                          variant="contained"
                          color={'info'}
                          size={'small'}
                          onClick={() => handlePushToDetailCustomer(row.id)}
                        >
                          รายละเอียด
                        </Button>
                        <Button
                          variant="contained"
                          color={'warning'}
                          size={'small'}
                          onClick={() => handlePushToEditCustomer(row.id)}
                        >
                          แก้ไข
                        </Button>
                        <Button
                          variant="contained"
                          color={'error'}
                          size={'small'}
                          onClick={() => {
                            handleDeleteCustomer(row?.id);
                          }}
                        >
                          ลบ
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <div className="text-center">ไม่มีข้อมูล</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          rowsPerPageOptions={[]}
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={page - 1}
          count={total || 1}
          rowsPerPage={size}
          onPageChange={handleChangePage}
        />
      </Paper>
    </div>
  );

  if (customer.isLoading) {
    return <Loading />;
  }
  if (!customer.isLoading && customer.isCompleted) {
    return (
      <div>
        {renderCSVUploadModal()}
        <div className="flex justify-between">
          <div>{renderTitle()}</div>
          <div className="mt-6">{renderAddButtom()}</div>
        </div>
        {renderSearch()}
        {renderTable()}
      </div>
    );
  }
  return <Error />;
}

Customers.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

Customers.defaultProps = {
  title: '',
  subtitle: '',
};

export default Customers;
