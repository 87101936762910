import React from 'react';
import PropTypes from 'prop-types';

import { DATA_TYPE } from '../../utils/constants';

import {
  BooleanGauge,
  CheckAndDescribeGauge,
  DateGauge,
  DatetimeGauge,
  NumberGauge,
  ParagraphGauge,
  PersonGauge,
  RadioAndDescribeGauge,
  StringGauge,
  TimeGauge,
} from './MeasurementDisplay';

export default function MeasurementDisplayRenderingComponent({ measurement }) {
  const props = { measurementData: measurement };
  switch (measurement?.measurement_type?.data_type) {
    case DATA_TYPE.BOOLEAN.type_code:
      return <BooleanGauge {...props} />;
    case DATA_TYPE.CHECK_AND_DESCRIBE.type_code:
      return <CheckAndDescribeGauge {...props} />;
    case DATA_TYPE.DATE.type_code:
      return <DateGauge {...props} />;
    case DATA_TYPE.DATETIME.type_code:
      return <DatetimeGauge {...props} />;
    case DATA_TYPE.NUMBER.type_code:
      return <NumberGauge {...props} />;
    case DATA_TYPE.PARAGRAPH.type_code:
      return <ParagraphGauge {...props} />;
    case DATA_TYPE.RADIO_AND_DESCRIBE.type_code:
      return <RadioAndDescribeGauge {...props} />;
    case DATA_TYPE.STRING.type_code:
      return <StringGauge {...props} />;
    case DATA_TYPE.SWITCH.type_code:
      return <BooleanGauge {...props} />;
    case DATA_TYPE.TIME.type_code:
      return <TimeGauge {...props} />;
    case DATA_TYPE.PERSON.type_code:
      return <PersonGauge {...props} />;
    default:
      return <StringGauge {...props} />;
  }
}

MeasurementDisplayRenderingComponent.propTypes = {
  measurement: PropTypes.object,
};
