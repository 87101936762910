import React from 'react';
import { useFieldArray } from 'react-hook-form';
import {
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import ImageUpload from '../../ImageUpload/ImageUpload';

export function EmployeeForm({
  errors,
  department,
  roletype,
  employee,
  Controller,
  control,
  addUser,
  setAddUser,
  employeeImage,
  setEmployeeImage,
}) {
  const { append, fields, remove } = useFieldArray({
    control,
    name: 'other_departments',
  });
  return (
    <div className="flex flex-row flex-wrap h-96  lg:h-full lg:max-h-132 overflow-x-auto">
      {addUser != null ? (
        <div className="w-full px-1 py-1">
          <FormControlLabel
            defaultValue={addUser}
            control={<Checkbox color="primary" defaultChecked={addUser} />}
            label="สร้างพนักงานพร้อมบัญชีผู้ใช้"
            labelPlacement="end"
            name="addUser"
            onChange={() => {
              setAddUser(!addUser);
            }}
          />
        </div>
      ) : (
        <></>
      )}

      {addUser === true ? (
        <>
          <div className="w-full px-1 py-4">
            <div className="font-semibold my-2">ชื่อผู้ใช้ (Username)</div>
            <Controller
              name={'username'}
              control={control}
              defaultValue={employee ? employee.username : ''}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="ชื่อผู้ใช้ / Username"
                  fullWidth
                  size={'large'}
                  required
                  helperText={
                    errors.username
                      ? 'กรุณาใส่ข้อมูล'
                      : 'ควรจะใช้ตัวอักษรภาษาอังกฤษพิมพ์เล็ก เพื่อกันความผิดพลาดจากทั้งการลืม และการพิมพ์'
                  }
                />
              )}
            />
          </div>
          <div className="font-semibold my-2">รหัสผ่าน (Password)</div>
          <div className="flex w-full flex-wrap">
            <div className="w-full lg:w-1/2 px-1 py-1">
              <Controller
                name={'password'}
                control={control}
                defaultValue={employee ? employee.password : ''}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="รหัสผ่าน"
                    type="password"
                    fullWidth
                    size="large"
                    required
                    helperText={errors.password && 'กรุณาใส่ข้อมูล'}
                  />
                )}
              />
            </div>
            <div className="w-full lg:w-1/2 px-1 py-1">
              <Controller
                name={'confirmPassword'}
                control={control}
                defaultValue={employee ? employee.password : ''}
                rules={{ required: true }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="ยืนยันรหัสผ่าน"
                    type="password"
                    fullWidth
                    size="large"
                    required
                    helperText={errors.password && 'กรุณาใส่ข้อมูล'}
                  />
                )}
              />
            </div>
          </div>
          <div className="my-4 w-full">
            <Divider className=" w-full" />
          </div>
        </>
      ) : (
        <></>
      )}
      <div className="font-semibold my-2 w-full">แผนกและบทบาท</div>
      <div className="w-full md:w-1/2 self-center px-1 py-2">
        <Controller
          name={'department'}
          control={control}
          defaultValue={employee ? employee?.department?._id : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <FormControl sx={{ minWidth: 120 }} fullWidth={true} required>
              <InputLabel id="type" size={'small'}>
                แผนกหลัก
              </InputLabel>
              <Select {...field} label="แผนกหลัก" size={'small'} fullWidth>
                {_.size(department?.rows) ? (
                  _.map(department.rows, (row) => (
                    <MenuItem key={row._id} value={row._id}>
                      {row.name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem>
                    <MenuItem disabled value="">
                      <em>ไม่มีข้อมูล</em>
                    </MenuItem>
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          )}
        />
      </div>
      <div className="w-full md:w-1/2 px-1 py-2">
        <Controller
          name={'role'}
          control={control}
          defaultValue={employee ? employee?.role?._id : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <FormControl sx={{ minWidth: 120 }} fullWidth="true" required>
              <InputLabel id="type" size={'small'}>
                บทบาท
              </InputLabel>
              <Select {...field} label="บทบาท" size={'small'} fullWidth>
                {_.size(roletype?.rows) ? (
                  _.map(roletype.rows, (row) => (
                    <MenuItem key={row._id} value={row._id}>
                      {row.name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem>
                    <MenuItem disabled value="">
                      <em>ไม่มีข้อมูล</em>
                    </MenuItem>
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          )}
        />
      </div>
      <div className="font-semibold my-2 w-full">ข้อมูลเบื้องต้นของพนักงาน</div>
      <div className="w-1/2 px-1 py-1">
        <Controller
          name={'firstname'}
          control={control}
          defaultValue={employee ? employee.firstname : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="ชื่อ"
              fullWidth
              size={'small'}
              required
              helperText={errors.firstname && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-1/2 px-1 py-1">
        <Controller
          name={'lastname'}
          control={control}
          defaultValue={employee ? employee.lastname : ''}
          render={({ field }) => (
            <TextField
              {...field}
              label="นามสกุล"
              fullWidth
              size={'small'}
              helperText={errors.lastname && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full md:w-1/2 px-1 py-1">
        <Controller
          name={'phone_number'}
          control={control}
          defaultValue={employee ? employee.phone_number : ''}
          render={({ field }) => (
            <TextField
              {...field}
              label="เบอรโทรศัพท์"
              fullWidth
              size={'small'}
              helperText={errors.phone_number && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>

      <div className="w-full px-1 ">
        <div className="flex gap-2 py-2">
          <div>แผนกอื่น ๆ</div>
          <div className="self-center">
            <Button
              variant="outlined"
              color="success"
              size="small"
              onClick={() => {
                append({});
              }}
            >
              เพิ่ม
            </Button>
          </div>
        </div>
        <div className="flex flex-wrap">
          {_.map(fields, (_field, index) => (
            <div className="w-full py-1 lg:w-1/2" key={_field?.id}>
              <div className="w-full  flex gap-2">
                <div className="w-3/5">
                  <Controller
                    name={`other_departments[${index}]._id`}
                    control={control}
                    rules={{ required: false }}
                    render={({ field }) => (
                      <FormControl sx={{ minWidth: 120 }} fullWidth={true}>
                        <InputLabel id="type" size={'small'}>
                          แผนกอื่น ๆ
                        </InputLabel>
                        <Select
                          {...field}
                          label="แผนกอื่น ๆ"
                          size={'small'}
                          fullWidth
                        >
                          {_.size(department?.rows) ? (
                            _.map(department.rows, (row) => (
                              <MenuItem key={row.id} value={row.id}>
                                {row.name}
                              </MenuItem>
                            ))
                          ) : (
                            <MenuItem>
                              <MenuItem disabled value="">
                                <em>ไม่มีข้อมูล</em>
                              </MenuItem>
                            </MenuItem>
                          )}
                        </Select>
                      </FormControl>
                    )}
                  />
                </div>
                <div className="w-1/5 self-center">
                  <Button
                    size="small"
                    color="error"
                    variant="contained"
                    onClick={() => remove(index)}
                  >
                    ลบ
                  </Button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {employee?.image && (
        <div className="w-full px-1 py-2 ">
          <img src={employee?.image?.url} className="h-64" />
        </div>
      )}

      <div className="w-full px-1 py-2">
        <ImageUpload
          preview_size="250"
          maxNumber={1}
          images={employeeImage}
          setImages={setEmployeeImage}
          title={employee?.rows ? 'อัพโหลดรูปภาพ' : 'แก้ไขรูปภาพ'}
        />
      </div>
      {/* <div className="w-full px-1 py-2 font-display font-semibold">
        การจ่ายค่าตอบแทน
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'earnings.is_pay_monthy'}
          control={control}
          defaultValue={employee ? employee?.earnings?.is_pay_monthy : false}
          rules={{ required: false }}
          render={({ field }) => (
            <FormControl>
              <FormControlLabel
                control={<Switch {...field} defaultChecked={field.value} />}
                label="ให้ค่าตอบแทนเป็นรายเดือน"
              />
            </FormControl>
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'earnings.amount'}
          control={control}
          defaultValue={employee ? employee?.earnings?.amount : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField {...field} fullWidth size="small" label="ค่าตอบแทน" />
          )}
        />
      </div> */}
    </div>
  );
}

EmployeeForm.propTypes = {
  department: PropTypes.object,
  roletype: PropTypes.object,
  errors: PropTypes.shape({
    username: PropTypes.object,
    password: PropTypes.object,
    firstname: PropTypes.object,
    lastname: PropTypes.object,
    phone_number: PropTypes.object,
  }),
  employeeImage: PropTypes.arrayOf(PropTypes.object),
  setEmployeeImage: PropTypes.func.isRequired,
  addUser: PropTypes.object,
  setAddUser: PropTypes.func,
  employee: PropTypes.object,
  Controller: PropTypes.func,
  control: PropTypes.object,
};

EmployeeForm.defaultProps = {
  employee: null,
  addUser: null,
};

export default EmployeeForm;
