import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Card, CardContent } from '@mui/material';
import { gql } from 'graphql-request';
import PropTypes from 'prop-types';

import {
  BackButton,
  Error,
  Loading,
  ProcessInfoForm,
  ViewTitle,
} from '../../../components';
import { config } from '../../../configs';
import graphqlClient from '../../../configs/graphqlClient';
import * as actions from '../../../redux/actions';

/**
 * @function EditProcess
 * @description Display a collections or a list of EditProcess from database
 */

export default function EditProcess({ title, subtitle }) {
  const dispatch = useDispatch();
  const currentProcess = useSelector((state) => state.process);
  const information = useSelector((state) => state.information);
  const customer = useSelector((state) => state.customer);
  const machine = useSelector((state) => state.machine);
  const history = useHistory();
  const params = useParams();

  const {
    handleSubmit,
    watch,
    control,
    setValue,
    formState: { errors },
  } = useForm();

  const query = gql`
    query FindSettingEditProductBom(
      $customerInput: CustomerInput
      $machineInput: MachineInput
    ) {
      findCustomers(input: $customerInput) {
        total
        rows {
          _id
          type_code
          name
        }
      }
      findMachines(input: $machineInput) {
        total
        rows {
          _id
          name
        }
      }
    }
  `;

  const queryDataFromServer = async () => {
    try {
      const queryResult = await graphqlClient.request(query, {
        customerInput: {
          page: 1,
          size: config.maxFetchSize,
        },
        machineInput: {
          page: 1,
          size: config.maxFetchSize,
        },
      });

      const customerData = queryResult?.findCustomers;
      const machineData = queryResult?.findMachines;
      dispatch(actions.processGet(params.id));
      dispatch(actions.customerStateSet(customerData));
      dispatch(actions.machineStateSet(machineData));
    } catch (err) {
      console.error('Error on query', err);
    }
  };

  useEffect(() => {
    dispatch(actions.processReset());
    queryDataFromServer();
    return () => {};
  }, [params]);

  const onSubmit = async (data) => {
    try {
      await dispatch(actions.processPut(params.id, data));
      history.goBack();
    } catch (error) {
      alert(`ไม่สามารถแก้ไขไลน์การผลิตได้ ${error?.message}`);
    }
  };

  useEffect(() => {
    if (
      !currentProcess?.isLoading &&
      currentProcess?.isCompleted &&
      currentProcess?._id
    ) {
      setValue('customer', currentProcess?.customer);
      setValue('other_customers', currentProcess?.other_customers || []);
      setValue('remark', currentProcess?.remark);
      setValue('quantity', currentProcess?.quantity);
    }
    return () => {};
  }, [currentProcess]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (currentProcess.isLoading) {
    return <Loading />;
  }

  if (!currentProcess.isLoading && currentProcess.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div>
          <BackButton />
        </div>
        <div className="my-2">
          <Card>
            <CardContent>
              <div className="w-full">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <ProcessInfoForm
                    control={control}
                    errors={errors}
                    information={information}
                    customer={customer}
                    watch={watch}
                    currentProcess={currentProcess}
                    machine={machine}
                  />
                  <div className="flex gap-2 justify-end">
                    <Button variant="contained" color="success" type="submit">
                      บันทึก
                    </Button>
                  </div>
                </form>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    );
  }
  return <Error />;
}

EditProcess.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

EditProcess.defaultProps = {
  title: '',
  subtitle: '',
};
