import React from 'react';
import { Avatar } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

export default function NameBox({ user, showDepartment = true, bold = true }) {
  return (
    <div className="flex justify-start gap-2">
      <div className="self-center">
        <Avatar src={user?.image?.url} className="mr-2 uppercase">
          {_.first(user?.firstname)}
          {_.first(user?.lastname)}
        </Avatar>
      </div>
      <div className="self-center">
        <h4 className={`${bold ? 'font-bold font-display' : ''} `}>
          {user?.firstname}
          {'  '}
          {user?.lastname}
        </h4>
        {showDepartment && <h6> {user?.department?.name}</h6>}
      </div>
    </div>
  );
}

NameBox.propTypes = {
  user: PropTypes.shape({
    image: PropTypes.object,
    firstname: PropTypes.string,
    lastname: PropTypes.string,
    department: PropTypes.object,
    bold: PropTypes.bool,
  }),
  showDepartment: PropTypes.bool,
};
