import React from 'react';
import { FlowAnalysisGraph } from '@ant-design/graphs';
import { useTheme } from '@mui/styles';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { MATERIAL_COMING_METHOD } from '@/utils/constants';
import { MMS } from '@/utils/functions';

const BrachingStepTimeline = ({
  processSteps,
  allowDrag = true,
  currentProcess,
}) => {
  const theme = useTheme();
  const { findIsStepActive } = MMS;

  const stepPreProcess = _.reduce(
    processSteps,
    (result, each, key) => {
      if (
        processSteps?.[key + 1]?.material_incoming_method ===
        MATERIAL_COMING_METHOD.MERGING.status_code
      ) {
        _.map(each?.materials, (eachMaterial, materialIndex) => {
          result.push({
            ...each,
            index:
              materialIndex === 0
                ? `${each?.index}`
                : `${each?.index}.${materialIndex}`,
            use_set_of_material: false,
            main_material: eachMaterial?.material,
            merged_step: true,
          });
        });
      } else {
        result.push(each);
      }

      return result;
    },
    [],
  );

  const modifiedStep = {
    nodes: _.map(stepPreProcess, (each) => ({
      id: each?.index?.toString(),
      value: {
        title: `${each?.name} ${
          each?.is_process_merging_point
            ? 'สิ้นสุดแยกไลน์'
            : each?.is_process_splitting_point
            ? 'เริ่มต้นแยกไลน์'
            : ''
        }`,
        active: findIsStepActive(currentProcess, each),
        items: [
          {
            text: 'วัตถุดิบ',
            value: each?.use_set_of_material
              ? _.map(
                  each?.materials,
                  (eachMaterial) =>
                    `${eachMaterial?.material?.type_code || ''} ${
                      eachMaterial?.material?.name || '-'
                    }`,
                )
              : each?.main_material?.name || '-',
          },
          {
            text: 'แผนก',
            value: _.truncate(each?.responsible?.department?.name || '-', {
              length: 12,
            }),
          },
        ],
      },
    })),
    edges: _.map(stepPreProcess, (each) => {
      if (each?.merged_step) {
        return {
          source: each?.index?.toString(),
          target: (Math.floor(parseFloat(each?.index)) + 1)?.toString(),
        };
      }
      if (each?.splited_step) {
        return {
          source: each?.from_index?.toString(),
          target: each?.index?.toString(),
        };
      }
      return {
        source: each?.index?.toString(),
        target: (each?.index + 1)?.toString(),
      };
    }),
  };

  console.log('Modified Step: ', modifiedStep);

  const config = {
    data: modifiedStep,

    nodeCfg: {
      size: [180, 25],
      style: (cfg, group, type) => {
        const styles = {
          fill: '#E6EAF1',
          stroke: cfg?.value?.active
            ? theme?.palette?.primary?.light
            : '#B2BED5',
          lineWidth: cfg?.value?.active ? 2 : 1,
          radius: [2, 2, 2, 2],
          text: {
            fontFamily: ['Noto Sans Thai Looped', 'Noto Sans'],
          },
        };
        return styles;
      },

      title: {
        containerStyle: {
          fill: 'transparent',
        },
        style: {
          fill: '#000',
          fontSize: 12,
          fontFamily: ['Noto Sans Thai Looped', 'Noto Sans'],
        },
      },
      items: {
        containerStyle: {
          fill: '#fff',
        },
        padding: 6,
        style: (cfg, group, type) => {
          const styles = {
            icon: {
              width: 12,
              height: 12,
            },
            text: {
              fill: '#aaa',
              fontFamily: ['Noto Sans Thai Looped', 'Noto Sans'],
            },
            value: {
              fontFamily: ['Noto Sans Thai Looped', 'Noto Sans'],
            },
          };
          return styles[type];
        },
      },
    },
    edgeCfg: {
      type: 'polyline',
      endArrow: true,
      label: {
        style: {
          fill: '#aaa',
          fontSize: 12,
          fillOpacity: 1,
          fontFamily: ['Noto Sans Thai Looped', 'Noto Sans'],
        },
      },
    },
    markerCfg: (cfg) => {
      const { edges } = modifiedStep;
      return {
        position: 'right',
        show: edges.find((item) => item.source === cfg.id),
      };
    },
    behaviors: [
      allowDrag ? 'drag-canvas' : '',
      allowDrag ? 'zoom-canvas' : '',

      //   'drag-node'
    ],
  };

  return <FlowAnalysisGraph {...config} />;
};

export default BrachingStepTimeline;

BrachingStepTimeline.propTypes = {
  processSteps: PropTypes.arrayOf(PropTypes.object),
  allowDrag: PropTypes.bool,
  currentProcess: PropTypes.object,
};
