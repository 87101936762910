import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Chip } from '@mui/material';
import PropTypes from 'prop-types';

import { PassportAuth } from '../../contexts/AuthContext';
import theme from '../../theme';

export function HomeNavbar({ onMobileNavOpen, isShowMobileNav = true }) {
  const information = useSelector((state) => state.information);
  const { handleSignout } = useContext(PassportAuth);
  const history = useHistory();
  const onSignOut = async () => {
    await handleSignout();
    history.push('/login');
  };
  const [navbarOpen] = React.useState(false);

  return (
    <nav className="top-0 fixed z-10 w-full flex flex-wrap items-center justify-between px-2 py-1 navbar-expand-lg bg-white shadow-xl print-disable">
      <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
        <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start font-sans">
          <div className="flex gap-2">
            <Link
              to="/"
              className="text-gray-600 text-base font-bold leading-relaxed mr-4 py-2 whitespace-no-wrap flex"
            >
              {information?.logo?.url && (
                <img src={information?.logo?.url} className="h-10 rounded-md" />
              )}
              <div className="my-auto pl-4">
                {information?.name || 'E-Manufac'}{' '}
                {process.env.NODE_ENV === 'development' && (
                  <Chip label="Dev" color="warning" />
                )}
              </div>
            </Link>
          </div>

          <button
            className="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
            type="button"
            onClick={() => onMobileNavOpen()}
          >
            <i className="fas fa-bars text-gray-600"></i>
          </button>
        </div>
      </div>
    </nav>
  );
}
HomeNavbar.propTypes = {
  onMobileNavOpen: PropTypes.func,
  isShowMobileNav: PropTypes.bool,
};

HomeNavbar.defaultProps = {
  isShowMobileNav: true,
};

export default HomeNavbar;
