import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { MMS, notNan } from '../../../utils/functions';
import MultiUnitProdOrMatBox from '../../Box/Rendering/MultiUnitProdOrMatBox';

const MaterialToProductAsMaterialTable = ({ materialData }) => {
  const materialAnalyzedData = MMS.MaterialAnalyzer.materialByMaterialAnalyzer(
    materialData,
  );

  if (_.isEmpty(materialAnalyzedData.resultAsMaterials)) {
    return <div></div>;
  }

  return (
    <div>
      <h4 className="text-lg my-2 font-semibold">วัตถุดิบที่ผลิตได้</h4>
      <div className="overflow-x-auto">
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell>
                <div className="font-bold">ลำดับที่</div>
              </TableCell>
              <TableCell>
                <div className="font-bold"> วัตถุดิบที่ออกมา</div>
              </TableCell>
              <TableCell>
                <div className="font-bold"> ล็อตวัตถุดิบ</div>
              </TableCell>
              <TableCell>
                <div className="font-bold"> จำนวน</div>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {_.map(
              materialAnalyzedData.resultAsMaterials,
              (_resultProd, index) => {
                const sumProd = _.sum(
                  _.map(_resultProd, (_prod) => _prod?.quantity),
                );
                return (
                  <TableRow key={index}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>
                      {_resultProd?.[0]?.material?.type_code}{' '}
                      {_resultProd?.[0]?.material?.name}
                    </TableCell>
                    <TableCell>
                      {_.map(_resultProd, (_prod, prodIndex) => (
                        <div key={prodIndex}>{_prod?.lot_number} </div>
                      ))}
                    </TableCell>
                    <TableCell>
                      <MultiUnitProdOrMatBox
                        foundMaterial={_resultProd?.[0]?.material}
                        quantity={sumProd}
                      />
                    </TableCell>
                  </TableRow>
                );
              },
            )}
          </TableBody>
        </Table>
      </div>{' '}
    </div>
  );
};

MaterialToProductAsMaterialTable.propTypes = {
  materialData: PropTypes.object,
};

export default MaterialToProductAsMaterialTable;
