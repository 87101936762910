import React from 'react';
import {
  FormHelperText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useFieldArray } from 'react-hook-form';
import PropTypes from 'prop-types';
import _ from 'lodash';

import MultiUnitProdOrMatBox from '../../Box/Rendering/MultiUnitProdOrMatBox';
import MultiUnitInput from '../../Inputs/ProdOrMatQuantityInput';
import { currencyFormatter } from '../../../utils/functions';

const BomCheckingForm = ({ control, watch, setValue, errors }) => {
  const { fields } = useFieldArray({ control, name: 'bill_of_materials' });
  const calculateFormularCost = () => {
    const eachPriceList = _.map(
      fields,
      (eachField) =>
        eachField?.material?.common_price * eachField?.prefer_amount,
    );
    return _.sum(eachPriceList);
  };

  const calculateDepositCost = () => {
    const eachPriceList = _.map(
      watch('bill_of_materials'),
      (eachWatchField) =>
        eachWatchField?.material?.common_price * eachWatchField?.deposit_amount,
    );
    return _.sum(eachPriceList);
  };

  const calculateActualCost = () => {
    const eachPriceList = _.map(
      watch('bill_of_materials'),
      (eachWatchField) =>
        eachWatchField?.material?.common_price * eachWatchField?.actual_amount,
    );
    return _.sum(eachPriceList);
  };

  const renderEachField = (eachField, index) => (
    <TableRow key={eachField?.id}>
      <TableCell>{index + 1}</TableCell>
      <TableCell>
        {eachField?.material?.type_code} {eachField?.material?.name}
      </TableCell>
      <TableCell>
        <MultiUnitProdOrMatBox
          foundMaterial={eachField?.material}
          quantity={eachField?.prefer_amount}
        />
      </TableCell>
      <TableCell>
        {currencyFormatter.format(eachField?.material?.common_price)}
      </TableCell>{' '}
      <TableCell>
        <MultiUnitInput
          controllerName={`bill_of_materials[${index}].deposit_amount`}
          artificialControllerName={`bill_of_materials[${index}].deposit_amount_artiticial`}
          index={index}
          selectedUnitControllerName={`bill_of_materials[${index}].selected_unit`}
          setValue={setValue}
          watch={watch}
          control={control}
          selectedProduct={eachField?.material}
        />
        {errors?.bill_of_materials?.index?.deposit_amount && (
          <FormHelperText>กรุณาใสข้อมูล </FormHelperText>
        )}

        {parseFloat(watch(`bill_of_materials[${index}].deposit_amount`)) >
          eachField?.prefer_amount && (
          <div className="my-1 font-semibold text-red-500">
            {' '}
            มากกว่าจำนวนในสูตร
          </div>
        )}

        {parseFloat(watch(`bill_of_materials[${index}].deposit_amount`)) <
          eachField?.prefer_amount && (
          <div className="my-1"> น้อยกว่าจำนวนในสูตร</div>
        )}
      </TableCell>
      <TableCell>
        <MultiUnitInput
          controllerName={`bill_of_materials[${index}].actual_amount`}
          artificialControllerName={`bill_of_materials[${index}].actual_amount_artiticial`}
          index={index}
          selectedUnitControllerName={`bill_of_materials[${index}].selected_unit`}
          setValue={setValue}
          watch={watch}
          control={control}
          selectedProduct={eachField?.material}
        />
        {errors?.bill_of_materials?.index?.actual_amount && (
          <FormHelperText>กรุณาใสข้อมูล </FormHelperText>
        )}
        {parseFloat(watch(`bill_of_materials[${index}].actual_amount`)) >
          parseFloat(watch(`bill_of_materials[${index}].deposit_amount`)) && (
          <div className="my-1 font-semibold text-red-500">
            {' '}
            มากกว่าจำนวนที่เบิก
          </div>
        )}

        {parseFloat(watch(`bill_of_materials[${index}].actual_amount`)) <
          parseFloat(watch(`bill_of_materials[${index}].deposit_amount`)) && (
          <div className="my-1"> น้อยกว่าจำนวนที่เบิก</div>
        )}
      </TableCell>
    </TableRow>
  );

  return (
    <div className="w-full mx-1">
      <div className="my-2">รายการวัตถุดิบส่วนผสม</div>
      <div className="my-2">
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <div className="font-semibold">ลำดับที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-semibold">ชื่อวัตถุดิบ</div>
                </TableCell>
                <TableCell>
                  <div className="font-semibold">ปริมาณที่ต้องการ</div>
                </TableCell>{' '}
                <TableCell>
                  <div className="font-semibold">ราคาต่อหน่วย</div>
                </TableCell>
                <TableCell>
                  <div className="font-semibold">ปริมาณที่เบิก</div>
                </TableCell>
                <TableCell>
                  <div className="font-semibold">ปริมาณจริงที่ใช้</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {_.map(fields, (eachField, index) =>
                renderEachField(eachField, index),
              )}
              <TableRow>
                <TableCell colSpan={3}>
                  <div className="font-semibold">รวม</div>
                </TableCell>
                <TableCell>
                  <div className="font-semibold">
                    {currencyFormatter.format(calculateFormularCost())}{' '}
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className="font-semibold">
                    {currencyFormatter.format(calculateDepositCost())}{' '}
                  </div>
                </TableCell>
                <TableCell align="center">
                  <div className="font-semibold">
                    {currencyFormatter.format(calculateActualCost())}{' '}
                  </div>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

BomCheckingForm.propTypes = {
  control: PropTypes.object,
  watch: PropTypes.func,
  setValue: PropTypes.func,
  errors: PropTypes.object,
};

export default BomCheckingForm;
