import React from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import dayjs from 'dayjs';
import BuddhistCalendar from 'dayjs/plugin/buddhistEra';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { DATA_TYPE } from '@/utils/constants';

import 'dayjs/locale/th';

import { BackButton } from '../../components/Button';
import { ViewTitle } from '../../components/ViewTitle';
import * as actions from '../../redux/actions';

dayjs.extend(BuddhistCalendar);

const SettingWMS = ({ title, subtitle }) => {
  const dispatch = useDispatch();
  const information = useSelector((state) => state.information);
  const { control, handleSubmit } = useForm({
    defaultValues: { setting: information?.setting },
  });

  const { append, fields, remove } = useFieldArray({
    control,
    name: 'setting.wms.additionalAttribute',
  });

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const handleChangeSetting = async (data) => {
    console.log('Data', data);
    const confirm = window.confirm('ยืนยันการปรับการตั้งค่า');
    if (confirm) {
      try {
        await dispatch(
          actions.informationPut(information?._id, {
            ...data,
            setting: {
              ...data.setting,
              wms: {
                ...data.setting.wms,
              },
            },
          }),
        );
        await dispatch(actions.informationAll());
        window.alert('แก้ไขการตั้งค่าสำเร็จ');
      } catch (error) {
        window.alert('แก้ไขการตั้งค่าไม่สำเร็จ');
        console.error('แก้ไขการตั้งค่าไม่สำเร็จ', error);
      }
    }
  };

  const renderGeneralSetting = () => (
    <Card className="p-4">
      <div className="flex flex-wrap w-full ">
        <div className="w-full py-2 px-2 font-semibold font-display ">
          รายละเอียดในคลังสินค้า
        </div>
        <div className="w-1/2 py-2 px-2">ส่วนประกอบในฟอร์มการเพิ่มลดสินค้า</div>
        <div className="w-1/2 py-2 px-2">
          <div className="flex flex-col w-full">
            <Controller
              control={control}
              name="setting.wms.display.cost_price"
              render={({ field }) => (
                <FormControlLabel
                  {...field}
                  control={<Checkbox defaultChecked={field.value} />}
                  label="ราคาต้นทุน"
                />
              )}
            />
            <Controller
              control={control}
              name="setting.wms.display.production_date"
              render={({ field }) => (
                <FormControlLabel
                  {...field}
                  control={<Checkbox defaultChecked={field.value} />}
                  label="วันผลิต"
                />
              )}
            />
            <Controller
              control={control}
              name="setting.wms.display.expiration_date"
              render={({ field }) => (
                <FormControlLabel
                  {...field}
                  control={<Checkbox defaultChecked={field.value} />}
                  label="วันหมดอายุ"
                />
              )}
            />
            <Controller
              control={control}
              name="setting.wms.display.lot_number"
              render={({ field }) => (
                <FormControlLabel
                  {...field}
                  control={<Checkbox defaultChecked={field.value} />}
                  label="หมายเลขล็อต"
                />
              )}
            />
            <Controller
              control={control}
              name="setting.wms.display.shipping"
              render={({ field }) => (
                <FormControlLabel
                  {...field}
                  control={<Checkbox defaultChecked={field.value} />}
                  label="ประเภทการจัดส่ง"
                />
              )}
            />
            <Controller
              control={control}
              name="setting.wms.display.shipping_cost"
              render={({ field }) => (
                <FormControlLabel
                  {...field}
                  control={<Checkbox defaultChecked={field.value} />}
                  label="ค่าจัดส่ง"
                />
              )}
            />
            <Controller
              control={control}
              name="setting.wms.display.tracking_number"
              render={({ field }) => (
                <FormControlLabel
                  {...field}
                  control={<Checkbox defaultChecked={field.value} />}
                  label="หมายเลขติดตามสินค้า"
                />
              )}
            />
            <Controller
              control={control}
              name="setting.wms.display.sale_date"
              render={({ field }) => (
                <FormControlLabel
                  {...field}
                  control={<Checkbox defaultChecked={field.value} />}
                  label="วันที่ขาย"
                />
              )}
            />
            <Controller
              control={control}
              name="setting.wms.display.price"
              render={({ field }) => (
                <FormControlLabel
                  {...field}
                  control={<Checkbox defaultChecked={field.value} />}
                  label="ราคา"
                />
              )}
            />
            <Controller
              control={control}
              name="setting.wms.display.payment"
              render={({ field }) => (
                <FormControlLabel
                  {...field}
                  control={<Checkbox defaultChecked={field.value} />}
                  label="ช่องทางการชำระเงิน"
                />
              )}
            />
          </div>
        </div>
        <div className="w-1/2 py-2 px-2">ส่วนประกอบในรายละเอียดสินค้า</div>
        <div className="w-1/2 py-2 px-2">
          <div className="flex flex-col w-full">
            <Controller
              control={control}
              name="setting.wms.productAttribute.net"
              render={({ field }) => (
                <FormControlLabel
                  {...field}
                  control={<Checkbox defaultChecked={field.value} />}
                  label="ปริมาตรสุทธิ"
                />
              )}
            />
            <Controller
              control={control}
              name="setting.wms.productAttribute.package_size"
              render={({ field }) => (
                <FormControlLabel
                  {...field}
                  control={<Checkbox defaultChecked={field.value} />}
                  label="ขนาดบรรจุภัณฑ์"
                />
              )}
            />
            <Controller
              control={control}
              name="setting.wms.productAttribute.package_unit"
              render={({ field }) => (
                <FormControlLabel
                  {...field}
                  control={<Checkbox defaultChecked={field.value} />}
                  label="หน่วยบรรจุพันธ์"
                />
              )}
            />
          </div>
        </div>
        <div className="w-1/2 py-2 px-2">ส่วนประกอบเพิ่มเติม</div>
        <div className="w-1/2 py-2 px-2">
          <div className="flex flex-col w-full">
            {_.map(fields, (eachField, index) => (
              <div key={eachField.id} className="flex my-2">
                <div className="w-1/2 px-1">
                  <Controller
                    control={control}
                    name={`setting.wms.additionalAttribute.[${index}].name`}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="ชื่อ"
                        variant="outlined"
                        size="small"
                        fullWidth
                      />
                    )}
                  />
                </div>
                <div className="w-1/4 px-1">
                  <Controller
                    control={control}
                    name={`setting.wms.additionalAttribute.[${index}].type`}
                    render={({ field }) => (
                      <FormControl fullWidth>
                        <InputLabel>รูปแบบข้อมูล</InputLabel>
                        <Select {...field} size="small" fullWidth>
                          {_.map(
                            _.filter(DATA_TYPE, { is_basic: true }),
                            (eachDataType, idx) => (
                              <MenuItem
                                key={idx}
                                value={eachDataType?.type_code}
                              >
                                {eachDataType?.description}
                              </MenuItem>
                            ),
                          )}
                        </Select>
                      </FormControl>
                    )}
                  />
                </div>
                <div className="w-1/4 px-1">
                  <Button
                    color="error"
                    variant="contained"
                    size="small"
                    onClick={() => remove(index)}
                  >
                    ลบ
                  </Button>
                </div>
              </div>
            ))}
            <div>
              <Button
                size="small"
                variant="contained"
                color="primary"
                onClick={() => append({})}
              >
                เพิ่ม
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );

  return (
    <div>
      {renderTitle()}
      <div className="flex flex-row justify-start pb-4">
        <div>
          <BackButton />
        </div>
      </div>
      <form onSubmit={handleSubmit(handleChangeSetting)}>
        <div>{renderGeneralSetting()}</div>
        <div className="flex justify-end mt-2">
          <Button color="primary" variant="contained" type="submit">
            บันทึก
          </Button>
        </div>
      </form>
    </div>
  );
};

SettingWMS.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

SettingWMS.defaultProps = {
  title: '',
  subtitle: '',
};
export default SettingWMS;
