import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import {
  Button,
  Card,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from '@mui/material';
import { gql } from 'graphql-request';
import _ from 'lodash';
import PropTypes from 'prop-types';

import {
  Error,
  Loading,
  MultiUnitProdOrMatBox,
  ViewTitle,
} from '../../../components';
import graphqlClient from '../../../configs/graphqlClient';
import * as actions from '../../../redux/actions';

/**
 * @function SettingProductBOM
 * @description Display a collections or a list of SettingProductBOM from database
 */

export default function SettingProductBOM({ title, subtitle }) {
  const dispatch = useDispatch();
  const product = useSelector((state) => state.product);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState();
  const [searchTerm, setSearchTerm] = useState('');
  const [name, setName] = useState('');

  const query = gql`
    query FindSettingProductBOM($productInput: ProductInput) {
      findProducts(input: $productInput) {
        currPage
        lastPage
        total
        rows {
          _id
          type_code
          name
          bill_of_materials {
            material {
              _id
              name
              type_code
              use_unit_conversion
              unit
              conversion_from {
                unit_a
                unit_b
                conversion_factor
              }
              conversion_to {
                unit_b
                unit_a
                conversion_factor
              }
              unit_input {
                _id
                short_sign
                name
              }
              unit_output {
                _id
                short_sign
                name
              }
            }
            amount
            tag
          }
        }
      }
    }
  `;

  const queryDataFromServer = async () => {
    const queryResult = await graphqlClient.request(query, {
      productInput: { page, size, fetchStockLot: false, name, fetchBOM: true },
    });
    const productData = queryResult?.findProducts;
    dispatch(actions.productStateSet(productData));
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [page, size, name]);

  useEffect(() => {
    setTotal(product?.total);

    return () => {};
  }, [product]);

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchTerm);
      setPage(1);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const renderSearch = () => (
    <Card>
      <div className="p-4 flex flex-row">
        <div className="w-full md:w-1/2">
          <TextField
            label="ค้นหา"
            fullWidth
            size={'small'}
            id="outlined-start-adornment"
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <i className="fas fa-search"></i>
                </InputAdornment>
              ),
            }}
          />
        </div>
      </div>
    </Card>
  );

  if (product.isLoading) {
    return <Loading />;
  }
  if (!product.isLoading && product.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div className="my-2">{renderSearch()}</div>
        <div>
          <TableContainer component={Paper}>
            <Paper>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <div className="font-bold"> ลำดับที่ </div>{' '}
                    </TableCell>
                    <TableCell>
                      <div className="font-bold"> ชื่อสินค้า</div>{' '}
                    </TableCell>
                    <TableCell>
                      <div className="font-bold"> ส่วนผสม</div>{' '}
                    </TableCell>
                    <TableCell>
                      <div className="font-bold"> ดำเนินการ</div>{' '}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {_.map(product?.rows, (eachProduct, index) => (
                    <TableRow key={index}>
                      <TableCell> {(page - 1) * size + index + 1}</TableCell>
                      <TableCell>
                        {' '}
                        {eachProduct?.type_code || ''} {eachProduct?.name}{' '}
                      </TableCell>
                      <TableCell>
                        {!_.isEmpty(eachProduct?.bill_of_materials) &&
                          _.map(
                            _.take(eachProduct?.bill_of_materials, 5),
                            (eachMaterial, materialIndex) => (
                              <div
                                key={`mat${materialIndex}`}
                                className="lg:flex gap-2"
                              >
                                <div>
                                  {materialIndex + 1}.{' '}
                                  {eachMaterial?.material?.name}{' '}
                                </div>
                                <MultiUnitProdOrMatBox
                                  foundMaterial={eachMaterial?.material}
                                  noWrap
                                  quantity={eachMaterial?.amount}
                                />
                              </div>
                            ),
                          )}
                        {_.size(eachProduct?.bill_of_materials) > 5 && (
                          <div>
                            และอีก {_.size(eachProduct?.bill_of_materials) - 5}{' '}
                            รายการ
                          </div>
                        )}
                        {_.isEmpty(eachProduct?.bill_of_materials) && (
                          <div>ไม่มีรายการส่วนผสม</div>
                        )}
                      </TableCell>
                      <TableCell>
                        <div className="flex gap-2">
                          <Link
                            to={`/setting/mms/product-bom/view/${eachProduct?._id}`}
                          >
                            <Button
                              variant="contained"
                              color="secondary"
                              size="small"
                            >
                              รายละเอียด
                            </Button>
                          </Link>
                          <Link
                            to={`/setting/mms/product-bom/edit/${eachProduct?._id}`}
                          >
                            <Button
                              variant="contained"
                              color="warning"
                              size="small"
                            >
                              แก้ไข
                            </Button>
                          </Link>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>{' '}
              <TablePagination
                component="div"
                onRowsPerPageChange={handleChangeRowsPerPage}
                page={page - 1}
                count={total || 1}
                rowsPerPage={size}
                onPageChange={handleChangePage}
              />
            </Paper>
          </TableContainer>
        </div>
      </div>
    );
  }
  return <Error />;
}

SettingProductBOM.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

SettingProductBOM.defaultProps = {
  title: '',
  subtitle: '',
};
