import React, { useEffect } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Autocomplete,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { BackButton, Error, Loading, ViewTitle } from '@/components';
import { config } from '@/configs';
import * as actions from '@/redux/actions';

/**
 * @function CreateMultipleMateria
 * @description Display a collections or a list of CreateMultipleMaterial  from database
 */

export default function CreateMultipleMaterial({ title, subtitle }) {
  const dispatch = useDispatch();
  const materialType = useSelector((state) => state.materialType);
  const history = useHistory();
  const { handleSubmit, control } = useForm();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'arr',
  });

  const queryDataFromServer = async () => {
    try {
      dispatch(actions.materialTypeAll({ page: 1, size: config.maxFetchSize }));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, []);

  const handleCreateMaterials = async (data) => {
    try {
      await dispatch(actions.materialCreate(data));
      history.goBack();
    } catch (error) {
      alert(`เพิ่มวัตถุดิบไม่สำเร็จ ${error?.message}`);
    }
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  return (
    <div>
      {renderTitle()}
      <div>
        <BackButton />
      </div>
      <div>
        <form onSubmit={handleSubmit(handleCreateMaterials)}>
          <Paper className="my-2">
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }}>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <div className="font-bold">ลำดับที่</div>
                    </TableCell>
                    <TableCell>
                      <div className="font-bold">รหัสวัตถุดิบ</div>
                    </TableCell>
                    <TableCell>
                      <div className="font-bold">ชื่อวัตถุดิบ</div>
                    </TableCell>
                    <TableCell>
                      <div className="font-bold">หมวดหมู่ของวัตถุดิบ</div>
                    </TableCell>
                    <TableCell>
                      <div className="font-bold">หน่วย</div>
                    </TableCell>
                    <TableCell>
                      <div className="font-bold">ดำเนินการ</div>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!_.isEmpty(fields) ? (
                    _.map(fields, (row, index) => (
                      <TableRow
                        key={row.id}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          <div className="text-xs">{index + 1}</div>
                        </TableCell>{' '}
                        <TableCell>
                          <Controller
                            name={`arr[${index}].type_code`}
                            defaultValue={row?.type_code || ''}
                            control={control}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                fullWidth
                                size="small"
                                label="รหัสวัตถุดิบ"
                              />
                            )}
                          />
                        </TableCell>
                        <TableCell>
                          <Controller
                            name={`arr[${index}].name`}
                            defaultValue={row?.name || ''}
                            control={control}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                fullWidth
                                size="small"
                                label="ชื่อวัตถุดิบ"
                              />
                            )}
                          />
                        </TableCell>
                        <TableCell>
                          {!_.isEmpty(materialType?.rows) && (
                            <Controller
                              name={`arr[${index}].material_type`}
                              defaultValue={row?.material_type}
                              control={control}
                              render={({ field }) => (
                                <Autocomplete
                                  {...field}
                                  disablePortal
                                  options={materialType?.rows}
                                  size="small"
                                  placeholder="ประเภทวัตถุดิบ"
                                  onChange={(e, newValue) => {
                                    field.onChange(newValue);
                                  }}
                                  className="z-40"
                                  // prettier-ignore
                                  getOptionLabel={(option) => `${option?.name || ''}`
                       }
                                  renderInput={(params) => (
                                    <TextField
                                      label="เลือกประเภทวัตถุดิบ"
                                      {...params}
                                    />
                                  )}
                                />
                              )}
                            />
                          )}
                        </TableCell>
                        <TableCell>
                          <div className="w-full py-2 px-1">
                            <Controller
                              name={`arr[${index}].unit`}
                              defaultValue={row?.unit}
                              control={control}
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  fullWidth
                                  size="small"
                                  label="หน่วย"
                                />
                              )}
                            />
                          </div>
                        </TableCell>
                        <TableCell>
                          <Button
                            variant="contained"
                            size="small"
                            color="error"
                            onClick={() => remove(index)}
                          >
                            ลบ
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={6}>
                        <div className="text-center">ไม่มีข้อมูล</div>
                      </TableCell>
                    </TableRow>
                  )}{' '}
                  <TableRow
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row" colSpan={3}>
                      <Button
                        variant="contained"
                        size="small"
                        onClick={() => append({})}
                      >
                        เพิ่ม
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
          <div className="flex justify-end my-4">
            <Button variant="contained" type="submit">
              บันทึก
            </Button>
          </div>{' '}
        </form>
      </div>
    </div>
  );
}

CreateMultipleMaterial.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

CreateMultipleMaterial.defaultProps = {
  title: '',
  subtitle: '',
};
