import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Card, CardContent, ListItem } from '@mui/material';
import _ from 'lodash';

import { BackButton } from '../../../components/Button';
import { StepBox } from '../../../components/Box';
import { ViewTitle } from '../../../components/ViewTitle';
import * as actions from '../../../redux/actions';

const DetailProductProcess = ({ title, subtitle }) => {
  const dispatch = useDispatch();
  const params = useParams();
  const product = useSelector((state) => state.product);
  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        await dispatch(actions.productGet(params.id));
      } catch (error) {
        console.error(error);
      }
    };

    fetchProduct();
    return () => {};
  }, [params]);

  return (
    <div>
      {renderTitle()}
      <div className="flex flex-row justify-start pb-4">
        <div>
          <BackButton />
        </div>
      </div>
      <div>
        <Card className="p-4">
          {' '}
          <div className="flex flex-wrap ">
            <div className="w-full md:w-1/6  py-2 px-2 font-semibold font-display ">
              {product?.name}
            </div>
            <div className="w-5/6 py-2 px-2 ">{product?.detail || ''}</div>
          </div>
        </Card>

        <div className="my-4 font-semibold font-display ">ขั้นตอนการทำ</div>

        <Card>
          <CardContent>
            <ListItem>
              <div className="w-full">
                {!_.isEmpty(product?.steps) &&
                  product?.steps?.map((eachStep, index) => (
                    <div
                      className="w-full flex border rounded-md my-3"
                      key={index}
                    >
                      <StepBox
                        eachStep={eachStep}
                        index={index}
                        deleteAble={false}
                      />
                    </div>
                  ))}
                {_.isEmpty(product?.steps) && (
                  <div>
                    <div className="text-center">
                      ยังไม่ได้ระบุวิธีการผลิตสินค้าสำหรับสินค้าชิ้นนี้
                    </div>
                  </div>
                )}
              </div>
            </ListItem>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

DetailProductProcess.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

DetailProductProcess.defaultProps = {
  title: '',
  subtitle: '',
};
export default DetailProductProcess;
