/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { gql } from 'graphql-request';
import { useHistory } from 'react-router-dom';

import { ViewTitle, Loading, Error, BackButton } from '../../components';
import * as actions from '../../redux/actions';
import graphqlClient from '../../configs/graphqlClient';
import { MaterialOrProductStockLotTable } from '../../components/Tables';

function InventoryAndWarehouseDashboard({ title, subtitle }) {
  const dispatch = useDispatch();
  const material = useSelector((state) => state.material);
  const product = useSelector((state) => state.product);
  const history = useHistory();
  const [materialPage, setMaterialPage] = useState(1);
  const [productPage, setProductPage] = useState(1);
  const [materialSize, setMaterialSize] = useState(5);
  const [productSize, setProductSize] = useState(5);

  const query = gql`
    query FindProductAndMaterial {
          findProducts(input:{page:${productPage},size:${productSize},fetchStockLot:true,status:"IN_STOCK"}){
            currPage,
            lastPage,
            total,
            rows {
                _id
                type_code
                name
                unit 
                unit_input {
                  _id
                  name
                  short_sign
                }
                unit_output {
                  _id
                  name
                  short_sign
                }
                use_unit_conversion
                total
            }
          }
          findMaterials(input:{page:${materialPage},size:${materialSize},fetchStockLot:true,status:"IN_STOCK"}){
            currPage,
            lastPage,
            total,
            rows {
                _id
                name
                type_code
                unit
                unit_input {
                  _id
                  name
                  short_sign
                }
                unit_output {
                  _id
                  name
                  short_sign
                }
                stocklots {
                  quantity
                  amount
                }
                use_unit_conversion
                total
            }
          }
        } 
  `;

  const queryDataFromServer = async () => {
    const queryResult = await graphqlClient.request(query);
    const materialData = queryResult?.findMaterials;
    const productData = queryResult?.findProducts;
    dispatch(actions.materialStateSet(materialData));
    dispatch(actions.productStateSet(productData));
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [materialPage, materialSize, productPage, productSize]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const materialStockFunction = {
    payload: material,
    title: 'วัตถุดิบ',
    handlePushToDetail: (materialId) => {
      history.push(`/ims/material-stock/${materialId}/`);
    },
    handleChangeRowsPerPage: (event) => {
      setMaterialSize(event?.target?.value);
      setMaterialPage(1);
    },
    handleChangePage: (event, newPage) => {
      setMaterialPage(newPage + 1);
    },
    page: materialPage,
    size: materialSize,
    total: material?.total,
    showActionButton: false,
  };

  const productStockFunction = {
    payload: product,
    title: 'สินค้า',
    handlePushToDetail: (productId) => {
      history.push(`/wms/product-stock/${productId}/`);
    },
    handleChangeRowsPerPage: (event) => {
      setProductSize(event?.target?.value);
      setProductPage(1);
    },
    handleChangePage: (event, newPage) => {
      setProductPage(newPage + 1);
    },
    page: productPage,
    size: productSize,
    total: product?.total,
    showActionButton: false,
  };

  if (material.isLoading || product.isLoading) {
    return <Loading />;
  }
  if (
    !material.isLoading &&
    material.isCompleted &&
    !product.isLoading &&
    product.isCompleted
  ) {
    return (
      <div>
        {renderTitle()}
        <div>
          <BackButton />
        </div>
        <div>
          <div className="w-full px-2 my-2">
            <div className="flex flex-wrap">
              <div className="w-full lg:w-1/2 px-2">
                <div className="font-display text-2xl my-2"> คลังวัตถุดิบ </div>
                <MaterialOrProductStockLotTable {...materialStockFunction} />
              </div>{' '}
              <div className="w-full lg:w-1/2 px-2">
                <div className="font-display text-2xl my-2"> คลังสินค้า </div>
                <MaterialOrProductStockLotTable {...productStockFunction} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return <Error />;
}
InventoryAndWarehouseDashboard.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

InventoryAndWarehouseDashboard.defaultProps = {
  title: '',
  subtitle: '',
};

export default InventoryAndWarehouseDashboard;
