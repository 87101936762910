import GeneralTableRendering from './common/GeneralTableRendering';
import PayrollTable from './HRMS/PayrollTable';
import MaterialOrProductStockLotTable from './IMS-WMS/MaterialStockLotTable';
import MaterialStockLotWithoutMaterialNameTable from './IMS-WMS/MaterialStockLotWithOutMaterialNameTable';
import MaterialTransactionTable from './IMS-WMS/MaterialTransactionTable';
import ProductStockLotWithOutProductNameTable from './IMS-WMS/ProductStockLotWithOutProductNameTable';
import MaterialOnProcessTable from './MMS/backup/MaterialOnProcessTable';
import FullManufacMaterialTable from './MMS/FullManufacMaterialTable';
import ManufacturingMaterialTable from './MMS/ManufacturingMaterialTable';
import ManufacturingOrderTable from './MMS/ManufacturingOrderTable';
import MaterialSplittingTable from './MMS/MaterialSplittingTable';
import MaterialWithdrawInfoTable from './MMS/MaterialWithdrawInfoTable';
import MeasurementDataTable from './MMS/MeasurementDataTable';
import MeasurementTypeTable from './MMS/MeasurementTypeTable';
import MOMaterialBomTable from './MMS/MOMaterialBomTable';
import ProcessTable from './MMS/ProcessTable';
import ProcessTemplateTable from './MMS/ProcessTemplateTable';
import ProcessWithMeasurementTable from './MMS/ProcessWithMeasurementTable';
import ReportMaterialProductAsMaterial from './MMS/ReportMaterialProductAsMaterial';
import ReportMaterialProductResultTable from './MMS/ReportMaterialProductResultTable';
import ReportMaterialProgressTable from './MMS/ReportMaterialProgressTable';
import StepTable from './MMS/StepTable';
import TotalMaterialBomTable from './MMS/TotalMaterialBomTable';
import OrderTable from './SPM/OrderTable';

export {
  FullManufacMaterialTable,
  GeneralTableRendering,
  ManufacturingMaterialTable,
  ManufacturingOrderTable,
  MaterialOnProcessTable,
  MaterialOrProductStockLotTable,
  MaterialSplittingTable,
  MaterialStockLotWithoutMaterialNameTable,
  MaterialTransactionTable,
  MaterialWithdrawInfoTable,
  MeasurementDataTable,
  MeasurementTypeTable,
  MOMaterialBomTable,
  OrderTable,
  PayrollTable,
  ProcessTable,
  ProcessTemplateTable,
  ProcessWithMeasurementTable,
  ProductStockLotWithOutProductNameTable,
  ReportMaterialProductAsMaterial,
  ReportMaterialProductResultTable,
  ReportMaterialProgressTable,
  StepTable,
  TotalMaterialBomTable,
};

export default {
  FullManufacMaterialTable,
  ManufacturingMaterialTable,
  ManufacturingOrderTable,
  MaterialOnProcessTable,
  MaterialOrProductStockLotTable,
  MaterialWithdrawInfoTable,
  MeasurementDataTable,
  MeasurementTypeTable,
  MOMaterialBomTable,
  OrderTable,
  PayrollTable,
  ProcessTable,
  ProcessTemplateTable,
  ProcessWithMeasurementTable,
  ReportMaterialProductAsMaterial,
  ReportMaterialProductResultTable,
  ReportMaterialProgressTable,
  StepTable,
  TotalMaterialBomTable,
  GeneralTableRendering,
  MaterialTransactionTable,
  ProductStockLotWithOutProductNameTable,
  MaterialStockLotWithoutMaterialNameTable,
  MaterialSplittingTable,
};
