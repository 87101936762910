import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { Button, Card } from '@mui/material';
import { gql } from 'graphql-request';
import _ from 'lodash';
import PropTypes from 'prop-types';

import {
  BackButton,
  Error,
  Loading,
  MaterialForm,
  ViewTitle,
} from '@/components';
import graphqlClient from '@/configs/graphqlClient';
import * as actions from '@/redux/actions';

import { app as appConfig } from '../../configs';

export default function EditMaterial({ title, subtitle }) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const material = useSelector((state) => state.material);
  const materialType = useSelector((state) => state.materialType);
  const customerType = useSelector((state) => state.customerType);
  const customer = useSelector((state) => state.customer);
  const unit = useSelector((state) => state.unit);

  const {
    formState: { errors },
    handleSubmit,
    control,
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      name: material.name,
      unit: material.unit,
      net: material.net,
      unit_net: material.unit_net,
      detail: material.detail,
      type_code: material.type_code,
      minimum_amount: material.minimum_amount,
      register_number: material.register_number,
      use_unit_conversion: material.use_unit_conversion,
    },
  });
  const history = useHistory();
  const [selectCustomerType, setSelectCustomerType] = useState();
  const [selectCustomer, setSelectCustomer] = useState([]);
  const [otherMaterialList, setOtherMaterialList] = useState([]);

  const query = gql`
    query FindMaterialRelatedElement(
      $unitInput: UnitInput
      $otherMaterialInput: MaterialInput
    ) {
      findUnits(input: $unitInput) {
        rows {
          _id
          name
        }
      }
      findMaterials(input: $otherMaterialInput) {
        rows {
          _id
          type_code
          name
        }
      }
    }
  `;

  const queryDataFromServer = async () => {
    try {
      const queryResult = await graphqlClient.request(query, {
        unitInput: { page: 1, size: appConfig.maxFetchSize },
        otherMaterialInput: { page: 1, size: appConfig.maxFetchSize },
      });
      const unitResult = queryResult?.findUnits;
      const otherMaterialResult = queryResult?.findMaterials;
      setOtherMaterialList(otherMaterialResult?.rows);
      dispatch(actions.unitStateSet(unitResult));
    } catch (error) {
      dispatch(actions.materialError());
    }
  };

  useEffect(() => {
    try {
      dispatch(
        actions.materialGet(id, { fetchStockLot: false, stockStatus: '' }),
      );
      dispatch(actions.materialTypeAll(''));
      dispatch(actions.customerTypeAll({}));
      queryDataFromServer();
    } catch (error) {
      dispatch(actions.materialError());
    }

    return () => {};
  }, []);

  useEffect(() => {
    if (selectCustomerType) {
      dispatch(actions.customerAll({ customerTypeName: selectCustomerType }));
    }
    return () => {};
  }, [selectCustomerType]);

  useEffect(() => {
    setValue('use_unit_conversion', material?.use_unit_conversion);
    setValue('input_materials', material?.input_materials);
    return () => {};
  }, [material]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const onSubmit = async (data) => {
    try {
      if (_.isEmpty(selectCustomer)) {
        await dispatch(actions.materialPut(id, data));
      } else {
        const supplier = _.map(selectCustomer, (select) => select?._id);
        await dispatch(actions.materialPut(id, { ...data, supplier }));
      }
      alert('สำเร็จ');
      await dispatch(
        actions.materialGet(id, { fetchStockLot: false, stockStatus: '' }),
      );
      setSelectCustomer([]);
      setSelectCustomerType('');
      history.goBack();
    } catch (error) {
      console.log(error);
    }
  };
  if (material.isLoading || material.rows) {
    return <Loading />;
  }
  if (!material.isLoading && material.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div className="flex flex-row justify-start pb-4">
          <div>
            <BackButton />
          </div>
        </div>
        <div>
          <Card className="p-4">
            <form onSubmit={handleSubmit(onSubmit)}>
              <MaterialForm
                errors={errors}
                material={material}
                control={control}
                Controller={Controller}
                materialType={materialType}
                customerType={customerType}
                selectCustomerType={selectCustomerType}
                setSelectCustomerType={setSelectCustomerType}
                customer={customer}
                setSelectCustomer={setSelectCustomer}
                selectCustomer={selectCustomer}
                unit={unit}
                watch={watch}
                otherMaterialList={otherMaterialList}
              />
              <div className="flex flex-row justify-end gap-1 py-4">
                <Button variant="contained" type="submit">
                  บันทึก
                </Button>
              </div>
            </form>
          </Card>
        </div>
      </div>
    );
  }
  return <Error />;
}

EditMaterial.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

EditMaterial.defaultProps = {
  title: '',
  subtitle: '',
};
