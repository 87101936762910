/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import { Calendar, dateFnsLocalizer, Views } from 'react-big-calendar';
import { Avatar, Card } from '@mui/material';
import format from 'date-fns/format';
import getDay from 'date-fns/getDay';
import th from 'date-fns/locale/th';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import _ from 'lodash';
import PropTypes from 'prop-types';

import ManufacturingOrderToEvent from '../../utils/functions/ManufacturingOrderToEvent';

import '../../assets/styles/calendar.css';

const MMSDashboard = ({ periodText, manufacturingOrder, step, employee }) => {
  const locales = {
    th,
  };

  const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
  });

  const [events, setEvents] = useState([]);
  const [workLoad, setWorkLoad] = useState(null);

  useEffect(() => {
    if (manufacturingOrder.rows) {
      setEvents(ManufacturingOrderToEvent(manufacturingOrder.rows));
    }
    if (step.rows) {
      const workByEmployee = step.rows.reduce((result, currentValue) => {
        (result[`${currentValue.responsible.employee._id}`] =
          result[`${currentValue.responsible.employee._id}`] || []).push(
          currentValue,
        );

        return result;
      }, {});
      const employeeWork = _.map(workByEmployee, (work, index) => {
        const owner = _.find(employee.rows, (row) => row._id === index);
        return { employee: owner, work };
      });
      // sort data from work amount
      const sort = _.sortBy(employeeWork, (work) => _.size(work.work));
      setWorkLoad(_.reverse(sort));
    }
    return () => {};
  }, [step, manufacturingOrder]);

  const eventStyleGetter = (event) => {
    let backgroundColor = '#D6EAF8';
    if (event.type === 'pending') {
      backgroundColor = '#2541B2';
    } else if (event.type === 'deleted') {
      backgroundColor = '#FECACA';
    } else if (event.type === 'completed') {
      backgroundColor = '#10B981';
    }
    const style = {
      backgroundColor,
      borderRadius: '4px',
      opacity: 0.8,
      color: 'white',
      border: '0px',
      display: 'block',
    };
    return {
      style,
    };
  };

  return (
    <div>
      <div className="my-4 mx-2 text-3xl font-display">การผลิต</div>
      <div className="flex flex-wrap  gap-4">
        <div className="w-full">
          <Card className="p-6">
            <div className="flex flex-wrap">
              <div className="w-full font-display">
                <div>คำสั่งผลิต{periodText}</div>
                <div className="text-5xl text-right p-4">
                  {manufacturingOrder.total || 0}{' '}
                  <span className="text-xl px-4">รายการ</span>
                </div>
              </div>
            </div>
            <div className="flex flex-wrap my-4">
              <div className="w-full">
                <div className="font-display">
                  ตารางปฏิทินคำสั่งผลิต{periodText}
                </div>
                <div>
                  <div style={{ height: '200vh' }}>
                    <Calendar
                      culture={'th'}
                      localizer={localizer}
                      events={events}
                      views={{ month: true, week: true }}
                      startAccessor="start"
                      endAccessor="end"
                      eventPropGetter={eventStyleGetter}
                      style={{ height: '200vh' }}
                      dayLayoutAlgorithm="no-overlap"
                      popup={true}
                      defaultView={Views.MONTH}
                    />
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div className="flex flex-wrap">
              <div className="w-full font-display">
                <div>งานของพนักงาน{periodText}</div>
                <div className="text-5xl text-right p-4">
                  <span className="text-xl px-4">สำเร็จแล้ว</span>
                  <span className="text-green-500">
                    {_.reduce(
                      step.rows,
                      (result, row) => {
                        if (row.completed) {
                          result += 1;
                        }
                        return result;
                      },
                      0,
                    )}{' '}
                  </span>
                  <span className="text-xl px-4">จากทั้งหมด</span>{' '}
                  {step.total || 0} <span className="text-xl px-4">รายการ</span>
                </div>
              </div>
            </div>
            <hr />
            <div className="flex flex-wrap my-4">
              <div className="w-full max-h-64 overflow-y-scroll px-2">
                {workLoad &&
                  _.map(workLoad, (work, index) => (
                    <Card className="p-2 my-2" key={index}>
                      <div className="flex flex-wrap justify-between gap-2">
                        <div className="flex flex-wrap gap-4">
                          <Avatar
                            src={work?.employee?.image?.url || ''}
                            sx={{
                              width: 48,
                              height: 48,
                              boxShadow: 2,
                            }}
                          />
                          <span>
                            <div className="font-bold font-display">
                              {work?.employee?.firstname ||
                                'ไม่ระบุผู้รับผิดชอบ'}{' '}
                              {work?.employee?.lastname}
                            </div>
                            <div className="text-sm">
                              {work?.employee?.department?.name || '-'}
                            </div>
                          </span>
                        </div>
                        <div className="flex flex-wrap gap-y-4">
                          <div className="w-0 lg:w-full"></div>
                          <span>
                            <div>
                              {'สำเร็จแล้ว '}{' '}
                              <span className="text-green-500">
                                {_.reduce(
                                  work.work,
                                  (result, eachWork) => {
                                    if (eachWork.completed) {
                                      result += 1;
                                    }
                                    return result;
                                  },
                                  0,
                                )}
                              </span>
                              {' จากทั้งหมด '}
                              {_.size(work.work)}
                              {' รายการ'}
                            </div>
                          </span>
                        </div>
                      </div>
                    </Card>
                  ))}
              </div>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default MMSDashboard;

MMSDashboard.propTypes = {
  periodText: PropTypes.string,
  manufacturingOrder: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
    total: PropTypes.number,
  }),
  step: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
    total: PropTypes.number,
  }),
  employee: PropTypes.shape({
    rows: PropTypes.arrayOf(PropTypes.object),
    total: PropTypes.number,
  }),
};
