import React, { Fragment, useRef, useState } from 'react';
import {
  ChevronDown,
  Edit as EditIcon,
  Printer,
  Repeat as RepeatIcon,
  Trash,
} from 'react-feather';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import {
  Button,
  ButtonGroup,
  Chip,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@mui/material';
import PropTypes from 'prop-types';

import {
  converseMaterialOrProductUnit,
  currencyFormatter,
} from '../../../utils/functions';
import MultiUnitProdOrMatBox from '../Rendering/MultiUnitProdOrMatBox';

/**
 * @function ProcessNoInfoBox
 * @memberof MMS
 * @description แสดงรายละเอียดโดยทั่วไปของ Process หรือไลน์การผลิตไลน์หนึ่ง ๆ
 */
const ProcessNoInfoBox = ({
  me,
  selectedProcess,
  handlePrint,
  handleCancle,
}) => {
  const anchorRef = useRef(null);
  const [buttonOpen, setIsButtonOpen] = useState(false);
  const history = useHistory();
  const information = useSelector((state) => state.information);

  const renderProdAsMaterialQuantityDisplay = () => {
    if (selectedProcess?.quantity > 0) {
      if (selectedProcess?.product_as_material?.use_unit_conversion) {
        return (
          <span>
            จำนวน {currencyFormatter.format(selectedProcess?.quantity)}{' '}
            {selectedProcess?.product_as_material?.unit_input?.short_sign} (
            {currencyFormatter.format(
              converseMaterialOrProductUnit(
                selectedProcess?.product_as_material,
                selectedProcess?.quantity,
              ),
            )}{' '}
            {selectedProcess?.product_as_material?.unit_output?.short_sign})
          </span>
        );
      }
      return (
        <span>
          จำนวน {currencyFormatter.format(selectedProcess?.quantity)}{' '}
          {selectedProcess?.additonal_unit ||
            selectedProcess?.product_as_material?.unit}{' '}
        </span>
      );
    }
    return <span></span>;
  };

  const renderProdQuantityDisplay = () => {
    if (selectedProcess?.quantity > 0) {
      if (selectedProcess?.product?.use_unit_conversion) {
        return (
          <span>
            จำนวน {currencyFormatter.format(selectedProcess?.quantity)}{' '}
            {selectedProcess?.product?.unit_input?.short_sign} (
            {currencyFormatter.format(
              converseMaterialOrProductUnit(
                selectedProcess?.product,
                selectedProcess?.quantity,
              ),
            )}{' '}
            {selectedProcess?.product?.unit_output?.short_sign})
          </span>
        );
      }
      return (
        <span>
          จำนวน {currencyFormatter.format(selectedProcess?.quantity)}{' '}
          {selectedProcess?.additional_unit || selectedProcess?.product?.unit}{' '}
        </span>
      );
    }
    return <span></span>;
  };

  return (
    <div>
      <div className="flex justify-between flex-wrap  px-4 ">
        {!selectedProcess?.produce_base_project_instead &&
          (selectedProcess?.produce_material_instead ? (
            <h4 className="text-lg mx-2 flex gap-2 my-2">
              <span className="bg-gray-200 p-1 px-2 rounded-md font-bold">
                {selectedProcess?.product_as_material?.type_code}{' '}
                {selectedProcess?.product_as_material?.name}
              </span>{' '}
              {renderProdAsMaterialQuantityDisplay()}{' '}
              {information?.setting?.mms?.showBatchNumber && (
                <Chip
                  color="primary"
                  label={`Batch ${selectedProcess?.batch_no || '-'}`}
                ></Chip>
              )}
            </h4>
          ) : (
            <h4 className="text-lg mx-2 flex gap-2">
              <span className="bg-gray-200 p-1 px-2 rounded-md font-bold">
                {selectedProcess?.product?.type_code}{' '}
                {selectedProcess?.product?.name}
              </span>{' '}
              {renderProdQuantityDisplay()}
              {information?.setting?.mms?.showBatchNumber && (
                <Chip
                  color="primary"
                  label={`Batch ${selectedProcess?.batch_no || '-'}`}
                ></Chip>
              )}
            </h4>
          ))}
        {selectedProcess?.produce_base_project_instead && (
          <h4 className="text-lg mx-2 flex gap-2">
            <span className="bg-gray-200 p-1 px-2 rounded-md font-bold self-center">
              {selectedProcess?.base_project?.type_code}{' '}
              {selectedProcess?.base_project?.name}
            </span>{' '}
            <div className="self-center">
              {selectedProcess?.quantity > 0 && (
                <MultiUnitProdOrMatBox
                  foundMaterial={selectedProcess?.base_project}
                  noWrap
                  quantity={selectedProcess?.quantity}
                />
              )}
            </div>
            {information?.setting?.mms?.showBatchNumber && (
              <Chip
                color="primary"
                label={`Batch ${selectedProcess?.batch_no || '-'}`}
              ></Chip>
            )}
          </h4>
        )}
        <div>
          <div className="flex gap-2 flex-wrap">
            <Fragment>
              <ButtonGroup
                variant="contained"
                ref={anchorRef}
                aria-label="split button"
                color="warning"
                size="small"
              >
                <Button
                  onClick={() => {
                    history.push(`/mms/process/edit/${selectedProcess?._id}`);
                  }}
                  startIcon={<EditIcon size={16} />}
                  hidden={
                    me?.userData?.role?.level < 1 ||
                    selectedProcess?.completed ||
                    selectedProcess?.deleted
                  }
                >
                  แก้ไข
                </Button>

                <Button
                  size="small"
                  aria-controls={buttonOpen ? 'split-button-menu' : undefined}
                  aria-expanded={buttonOpen ? 'true' : undefined}
                  aria-label="select merge strategy"
                  aria-haspopup="menu"
                  onClick={() => setIsButtonOpen((prevOpen) => !prevOpen)}
                >
                  <ChevronDown size={16} />
                </Button>
              </ButtonGroup>
              <Popper
                sx={{
                  zIndex: 1,
                }}
                open={buttonOpen}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === 'bottom' ? 'center top' : 'center bottom',
                    }}
                  >
                    <Paper>
                      <ClickAwayListener
                        onClickAway={() => setIsButtonOpen(false)}
                      >
                        <MenuList id="split-button-menu" autoFocusItem>
                          <MenuItem
                            key="1"
                            onClick={() => {
                              history.push(
                                `/mms/material-usage/edit/${selectedProcess?._id}`,
                              );
                            }}
                          >
                            แก้การใช้วัตถุดิบ
                          </MenuItem>
                          <MenuItem
                            key="1"
                            onClick={() => {
                              history.push(
                                `/mms/process/measurement/pre-edit/${selectedProcess?._id}`,
                              );
                            }}
                          >
                            แก้ค่าวัดผล
                          </MenuItem>

                          <MenuItem
                            key="1"
                            onClick={() => {
                              history.push(
                                `/mms/process/select-current-step/${selectedProcess?._id}`,
                              );
                            }}
                          >
                            ปรับขั้นตอนงานปัจจุบัน
                          </MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </Fragment>
            <Link
              to={`/mms/process/assign/${selectedProcess?._id}`}
              hidden={
                me?.userData?.role?.level < 1 ||
                selectedProcess?.completed ||
                selectedProcess?.deleted
              }
            >
              <Button
                size="small"
                variant="contained"
                color="secondary"
                disabled={
                  me?.userData?.role?.level < 1 ||
                  selectedProcess?.completed ||
                  selectedProcess?.deleted
                }
                startIcon={<RepeatIcon size={16} />}
              >
                แจกจ่ายงาน
              </Button>
            </Link>

            <Button
              size="small"
              variant="contained"
              color="teal"
              startIcon={<Printer size={16} />}
              onClick={() => handlePrint(selectedProcess)}
            >
              พิมพ์
            </Button>
            <Button
              size="small"
              variant="contained"
              disabled={
                me?.userData?.role?.level < 1 ||
                selectedProcess?.deleted ||
                selectedProcess?.completed
              }
              startIcon={<Trash size={16} />}
              color="error"
              onClick={() => handleCancle()}
            >
              ยกเลิก
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

ProcessNoInfoBox.propTypes = {
  me: PropTypes.object,
  information: PropTypes.object,
  selectedProcess: PropTypes.object,
  handlePrint: PropTypes.func,
  handleCancle: PropTypes.func,
};

export default ProcessNoInfoBox;
