import React, { useEffect, useState } from 'react';
import { Plus as PlusIcon } from 'react-feather';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import {
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { gql } from 'graphql-request';
import _ from 'lodash';
import PropTypes from 'prop-types';

import {
  BackButton,
  Error,
  Loading,
  ProcessTemplateNewForm,
  ViewTitle,
} from '@/components';
import { app as appConfig } from '@/configs';
import * as actions from '@/redux/actions';
import { PROCESS_TEMPLATE_TYPE } from '@/utils/constants';
import { MMS as MMSMiddleware } from '@/utils/middleware';

const blankStep = {
  name: '',
  detail: '',
};

const EditProcessTemplate = ({ title, subtitle }) => {
  const dispatch = useDispatch();
  const department = useSelector((state) => state.department);
  const information = useSelector((state) => state.information);
  const processTemplate = useSelector((state) => state.processTemplate);
  const material = useSelector((state) => state.material);
  const history = useHistory();
  const params = useParams();

  // Setting React hook form
  const {
    formState: { errors },
    handleSubmit,
    control,
    setValue,
    watch,
  } = useForm({});

  const { fields, remove, append, insert, move } = useFieldArray({
    control,
    name: 'steps',
  });

  useEffect(() => {
    const fetchDepartment = async () => {
      try {
        await dispatch(actions.departmentAll({ name: '' }));
      } catch (error) {
        console.error('Error on Fetch Departments', error);
      }
    };

    const fetchMaterial = async () => {
      try {
        const query = gql`
        query findMaterials {
          findMaterials(input:{page:1,size:${appConfig.maxFetchSize}}){
            rows {
              _id
              type_code
              name
            }
          }
        }
        `;
        await dispatch(actions.materialAllQuery({ query }));
      } catch (error) {
        console.error(error);
      }
    };

    fetchDepartment();
    fetchMaterial();

    return () => {};
  }, []);

  useEffect(() => {
    dispatch(actions.processTemplateGet(params.id));
    return () => {};
  }, [params]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const onSubmit = async (data, event) => {
    try {
      event.preventDefault();
      console.log('Raw Data', data);
      const preprocessedData = {
        ...data,
        steps: MMSMiddleware.Step(data.steps),
      };
      console.log('Pre Step Data', preprocessedData);
      await dispatch(actions.processTemplatePut(params.id, preprocessedData));
      alert('สำเร็จ');
      history.goBack();
      await dispatch(
        actions.processTemplateAll({ name: '', page: 1, size: 10 }),
      );
    } catch (error) {
      console.error(error);
    }
  };

  const renderForm = () => (
    <form>
      <Card className="my-2">
        <CardContent>
          <div className="w-full px-1 py-2">
            <Controller
              name={'name'}
              control={control}
              defaultValue={processTemplate?.name}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="ชื่อสูตรการผลิต / ชื่อสินค้า"
                  fullWidth
                  size="small"
                  required
                  helperText={errors.name}
                />
              )}
            />
          </div>
          <div className="w-full px-1 py-2">
            <Controller
              name="process_template_type"
              control={control}
              defaultValue={processTemplate?.process_template_type}
              render={({ field }) => (
                <FormControl fullWidth>
                  <InputLabel>รูปแบบโพรเซสการผลิต</InputLabel>
                  <Select
                    fullWidth
                    size="small"
                    label="รูปแบบโพรเซสการผลิต"
                    {...field}
                  >
                    {_.map(PROCESS_TEMPLATE_TYPE, (each, index) => (
                      <MenuItem key={index} value={each.status_code}>
                        {each?.description}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
            <div className="w-full px-1 py-2">
              <Controller
                name="split_and_mergeable"
                control={control}
                defaultValue={processTemplate?.split_and_mergeable}
                render={({ field }) => (
                  <FormControlLabel
                    label="สามารถแยกออกมาเป็นไลน์การผลิตคู่ขนาด แล้วกลับมารวมกันได้"
                    control={
                      <Checkbox {...field} defaultChecked={field.value} />
                    }
                  />
                )}
              />
            </div>
          </div>
        </CardContent>
      </Card>

      <ProcessTemplateNewForm
        control={control}
        errors={errors}
        fields={fields}
        remove={remove}
        move={move}
        watch={watch}
        setValue={setValue}
        product={processTemplate}
        material={material}
        append={append}
        submitButton={
          <div className="flex my-4">
            <Button
              onClick={handleSubmit(onSubmit)}
              fullWidth
              variant="contained"
            >
              บันทึก
            </Button>
          </div>
        }
      />

      <div className="flex flex-row justify-between gap-1 py-4">
        <Button
          variant="contained"
          type="button"
          color="secondary"
          onClick={() => {
            const arraySize = _.size(fields);
            // last index will be fixed last step
            if (information?.setting?.mms?.fixedLastStep?.enable === true) {
              if (_.last(fields)?.is_fixed_step === true) {
                const insertedIndex = arraySize - 1;
                insert(insertedIndex, blankStep);
              } else {
                append(blankStep);
              }
            } else {
              append(blankStep);
            }
          }}
        >
          <PlusIcon className="mr-2" size={20} />
          เพิ่ม
        </Button>
        <div>
          <Button
            onClick={handleSubmit(onSubmit)}
            fullWidth
            variant="contained"
          >
            บันทึก
          </Button>
        </div>
      </div>
    </form>
  );

  console.log('watch', watch('steps'));
  if (
    department.isLoading ||
    processTemplate?.isLoading ||
    processTemplate?.rows
  ) {
    return <Loading />;
  }
  if (
    !processTemplate?.isLoading &&
    processTemplate.isCompleted &&
    !department.isLoading &&
    department.isCompleted
  ) {
    return (
      <div>
        <div className="flex justify-between">
          <div>{renderTitle()}</div>
        </div>

        <div className="flex flex-row justify-between pb-4">
          <div>
            <BackButton />
          </div>
          <div className="flex gap-2">
            <Button variant="contained" onClick={handleSubmit(onSubmit)}>
              บันทึก
            </Button>
          </div>
        </div>
        {renderForm()}
      </div>
    );
  }

  return <Error message={process?.message} />;
};

EditProcessTemplate.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

EditProcessTemplate.defaultProps = {
  title: '',
  subtitle: '',
};

export default EditProcessTemplate;
