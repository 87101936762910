import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Card, CardContent } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';

import * as actions from '../../../redux/actions';

import MaterialLotTable from './MaterialLotTable';

function MaterialStockTab({ selectedMaterial }) {
  const information = useSelector((state) => state.information);
  const materialStockLot = useSelector((state) => state.materialStockLot);
  const {
    setting: { ims: imsSetting },
  } = information;
  const params = useParams();
  const [size, setSize] = useState(25);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      actions.materialStockLotAll({
        material: params.id,
        place: params.placeId,
        size,
        page,
      }),
    );

    return () => {};
  }, [params, page, size]);

  useEffect(() => {
    setTotal(materialStockLot?.total);

    return () => {};
  }, [materialStockLot]);

  const chartData = _.sortBy(materialStockLot?.rows, ['receipt_date'], ['asc']);

  return (
    <div className="my-2">
      <div className="my-2">
        <Card>
          <CardContent>
            <div className="flex flex-wrap">
              <div className="w-full md:w-1/2">
                <Chart
                  options={{
                    xaxis: {
                      type: 'datetime',
                      categories: _.map(
                        chartData,
                        (each) => each?.receipt_date,
                      ),
                    },
                    title: {
                      text: 'ปริมาณที่เข้ามาในแต่ละช่วงเวลา',
                    },
                    colors: ['#008FFB'],
                  }}
                  series={[
                    {
                      name: 'ปริมาณ',
                      data: _.map(chartData, (each) => each?.quantity),
                    },
                  ]}
                  type="area"
                  width="600"
                />
              </div>
              <div className="w-full md:w-1/2">
                <Chart
                  options={{
                    xaxis: {
                      type: 'datetime',
                      categories: _.map(
                        chartData,
                        (each) => each?.receipt_date,
                      ),
                    },
                    title: {
                      text: 'ราคาต่อหน่วยในแต่ละช่วงเวลา',
                    },
                    colors: ['#00E396'],
                  }}
                  series={[
                    {
                      name: 'ราคาต่อหน่วย',
                      data: _.map(
                        chartData,
                        (each) => each?.price / each?.quantity,
                      ),
                    },
                  ]}
                  type="area"
                  width="600"
                />
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
      {console.log({
        materialStockLot,
        imsSetting,
        page,
        selectedMaterial,
        setPage: () => {},
        setSize: () => {},
        size,
        total,
      })}
      <MaterialLotTable
        materialStockLot={materialStockLot}
        imsSetting={imsSetting}
        page={page}
        selectedMaterial={selectedMaterial}
        setPage={setPage}
        setSize={setSize}
        size={size}
        total={total}
      />
    </div>
  );
}

export default MaterialStockTab;

MaterialStockTab.propTypes = {
  selectedMaterial: PropTypes.object,
};
