import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Card,
  CardContent,
} from '@mui/material';

import { notNan } from '../../../utils/functions';

const ReportMaterialProgressTable = ({ productData: processData }) => {
  const productInfo = processData?.[0]?.product;
  const materialArrays = _.map(processData, (_process) => _process?.materials);
  const materialsUnbox = _.union(...materialArrays);
  const materialUnboxByMaterial = _.values(
    _.groupBy(materialsUnbox, 'material._id'),
  );
  // console.log('Material Unboxs', materialsUnbox);
  // console.log('Material Unbox By Material', materialUnboxByMaterial);

  const stepArrays = _.map(processData, (_process) => _process?.steps);
  const stepsUnbox = _.union(...stepArrays);
  // console.log('Step Unboxs', stepsUnbox);

  const stepUnboxByIndex = _.values(_.groupBy(stepsUnbox, 'index'));
  // console.log('StepUnbox By Index', stepUnboxByIndex);

  return (
    <div className="my-4">
      <Card>
        <CardContent>
          <div className="text-lg font-semibold">{productInfo?.name}</div>
          <div className="">
            จำนวน {_.sum(_.map(processData, 'quantity'))} {productInfo?.unit}
          </div>
          <div className="overflow-x-auto">
            <Table sx={{ minWidth: 650 }}>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <div className="font-bold">ลำดับที่</div>
                  </TableCell>
                  <TableCell>
                    <div className="font-bold"> วัตถุดิบ</div>
                  </TableCell>
                  <TableCell>
                    <div className="font-bold">
                      {' '}
                      เบิกมา <br /> (รวม)
                    </div>
                  </TableCell>

                  {_.map(stepUnboxByIndex, (_step, index) => (
                    <TableCell key={index}>
                      <div className="font-bold "> {_step?.[0]?.name}</div>
                      <div className="flex w-80 justify-around h-full">
                        <div className="w-1/6 h-full ">เริ่ม</div>
                        <div className="w-1/6 h-full ">ผล</div>
                        <div className="w-1/6 h-full ">เสีย</div>
                        <div className="w-1/6 h-full">คืน</div>
                        <div className="w-3/12">% ขั้น</div>
                        <div className="w-3/12">% รวม</div>
                      </div>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {!_.isEmpty(materialUnboxByMaterial) ? (
                  materialUnboxByMaterial?.map((row, index) => {
                    const totalBegin = _.sum(_.map(row, 'begin'));

                    return (
                      <TableRow
                        key={index}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          <div className="text-xs">{index + 1}</div>
                        </TableCell>

                        <TableCell>{row?.[0]?.material?.name} </TableCell>

                        <TableCell>
                          {totalBegin} {row?.[0]?.material?.unit}
                        </TableCell>

                        {_.map(stepUnboxByIndex, (_step, sIndex) => {
                          const stepMaterials = _.union(
                            ..._.map(_step, 'materials'),
                          );
                          const filteredMaterials = _.filter(
                            stepMaterials,
                            (_material) =>
                              _material?.material?._id ===
                              row?.[0]?.material?._id,
                          );

                          const allBegins = _.sum(
                            _.map(
                              filteredMaterials,
                              (_material) => _material?.begin,
                            ),
                          );
                          const allEnd = _.sum(
                            _.map(
                              filteredMaterials,
                              (_material) => _material?.end,
                            ),
                          );
                          const allWastes = _.sum(
                            _.map(
                              filteredMaterials,
                              (_material) => _material?.wastes,
                            ),
                          );
                          const allReturn = _.sum(
                            _.map(
                              filteredMaterials,
                              (_material) => _material?.return,
                            ),
                          );
                          return (
                            <TableCell key={sIndex}>
                              <div className="flex w-80 justify-around h-full">
                                <div className="w-1/6 h-full ">
                                  {notNan(allBegins)}
                                </div>{' '}
                                <div className="w-1/6 h-full">
                                  {notNan(allEnd)}
                                </div>{' '}
                                <div className="w-1/6 h-full">
                                  {' '}
                                  {notNan(allWastes)}
                                </div>{' '}
                                <div className="w-1/6 h-full">
                                  {notNan(allReturn)}
                                </div>
                                <div className="w-3/12">
                                  {notNan(
                                    (
                                      (allEnd / (allBegins - allReturn)) *
                                      100
                                    ).toFixed(2),
                                  )}
                                </div>
                                <div className="w-3/12">
                                  {notNan(
                                    (
                                      (allEnd / (totalBegin - allReturn)) *
                                      100
                                    ).toFixed(2),
                                  )}
                                </div>
                              </div>
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={6}>
                      <div className="text-center">ไม่มีข้อมูล</div>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>

          {/* <TablePagination
          component="div"
          rowsPerPageOptions={[]}
          page={page - 1}
          // count={total || 1}
          rowsPerPage={size}
          // onPageChange={handleChangePage}
        /> */}
        </CardContent>
      </Card>
    </div>
  );
};

ReportMaterialProgressTable.propTypes = {
  productData: PropTypes.arrayOf(PropTypes.object),
};

export default ReportMaterialProgressTable;
