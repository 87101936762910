import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Button } from '@mui/material';
import { gql } from 'graphql-request';
import PropTypes from 'prop-types';

import {
  BackButton,
  Error,
  Loading,
  ProcessTemplateTableForm,
  ViewTitle,
} from '@/components';
import { config as appConfig } from '@/configs/';
import graphqlClient from '@/configs/graphqlClient';
import * as actions from '@/redux/actions';

/**
 * @function EditProcessTemplateTableView
 * @description Display a collections or a list of EditProcessTemplateTableView from database
 */

export default function EditProcessTemplateTableView({ title, subtitle }) {
  const dispatch = useDispatch();
  const processTemplate = useSelector((state) => state.processTemplate);
  const material = useSelector((state) => state.material);
  const history = useHistory();
  const params = useParams();

  const { control, setValue, watch, handleSubmit } = useForm();

  const handleEditProcessTemplate = async (data) => {
    try {
      await dispatch(
        actions.processTemplatePut(params.id, { steps: data?.steps }),
      );
    } catch (error) {
      alert(`ไม่สามารถแก้ไขได้่ ${error?.message}`);
    }
  };
  const query = gql`
    query FindMaterials($materialInput: MaterialInput) {
      findMaterials(input: $materialInput) {
        total
        rows {
          _id
          name
          type_code
          use_unit_conversion
          unit
          conversion_from {
            unit_a
            unit_b
            conversion_factor
          }
          conversion_to {
            unit_b
            unit_a
            conversion_factor
          }
          unit_input {
            _id
            short_sign
            name
          }
          unit_output {
            _id
            short_sign
            name
          }
        }
      }
    }
  `;

  const queryDataFromServer = async () => {
    try {
      dispatch(actions.processTemplateGet(params.id));
      const queryResult = await graphqlClient.request(query, {
        materialInput: {
          page: 1,
          size: appConfig.maxFetchSize,
          fetchStockLot: false,
        },
      });
      const materialData = queryResult?.findMaterials;
      dispatch(actions.materialStateSet(materialData));
    } catch (error) {
      dispatch(actions.processTemplateError());
    }
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [params]);

  useEffect(() => {
    if (processTemplate?._id) {
      setValue('steps', processTemplate?.steps);
    }

    return () => {};
  }, [processTemplate]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (processTemplate.isLoading) {
    return <Loading />;
  }

  if (!processTemplate.isLoading && processTemplate.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <BackButton />
        <form onSubmit={handleSubmit(handleEditProcessTemplate)}>
          <div className="my-2">
            <ProcessTemplateTableForm
              control={control}
              setValue={setValue}
              watch={watch}
              material={material}
            />
          </div>
          <div>
            <div className="flex justify-end">
              <Button color="primary" variant="contained" type="submit">
                บันทึก
              </Button>
            </div>
          </div>
        </form>
      </div>
    );
  }
  return <Error />;
}

EditProcessTemplateTableView.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

EditProcessTemplateTableView.defaultProps = {
  title: '',
  subtitle: '',
};
