import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { io } from 'socket.io-client';

import {
  ContentNavbar,
  DefaultMenu,
  Diprom1,
  Diprom2,
  Diprom3,
  Diprom4,
  LeftMenu,
  Loading,
  LowerMenu,
  MainFooter,
  RightMenu,
} from '@/components';
import { FRONT_TEMPLATE } from '@/utils/constants';

import * as actions from '../redux/actions';

export default function Home() {
  const dispatch = useDispatch();
  const information = useSelector((state) => state.information);
  const me = useSelector((state) => state.me);
  const availableModule = useSelector((state) => state.availableModule);
  const [healthcheckOk, setIsHealthCheckOk] = useState(false);
  useEffect(() => {
    const fetchMe = async () => {
      try {
        dispatch(actions.meGet());
      } catch (error) {
        console.error('Fetch Me Error', error);
      }
    };

    const fetchInformation = async () => {
      try {
        dispatch(actions.informationAll());
      } catch (error) {
        console.error('Fetch Info Error', error);
      }
    };

    const fetchAvailableModule = async () => {
      try {
        dispatch(actions.availableModuleAll());
      } catch (error) {
        console.error('Fetch Available Module Error', error);
      }
    };

    fetchMe();
    fetchInformation();
    fetchAvailableModule();

    return () => {};
  }, []);

  const socket = useRef();

  useEffect(() => {
    socket.current = io(process.env.REACT_APP_SOCKET_IO_URL);
    if (me?.userData) {
      socket.current.emit('health-check', {
        referenceNumber: me?.userData?._id,
        client: navigator.userAgent,
      });
    }
  }, [me]);

  useEffect(() => {
    socket.current.on(me?.userData?._id, (data) => {
      if (data?.success) {
        setIsHealthCheckOk(true);
      }
    });
  }, [me]);

  if (!me?.userData) {
    return <Loading />;
  }

  const menuProps = { availableModule, information, me };

  const renderMenu = () => {
    switch (information?.setting?.main_page_type) {
      case FRONT_TEMPLATE.DEFAULT.status_code:
        return <DefaultMenu {...menuProps} />;
      case FRONT_TEMPLATE.LEFT_MENU.status_code:
        return <LeftMenu {...menuProps} />;
      case FRONT_TEMPLATE.RIGHT_MENU.status_code:
        return <RightMenu {...menuProps} />;
      case FRONT_TEMPLATE.LOWER_MENU.status_code:
        return <LowerMenu {...menuProps} />;
      case FRONT_TEMPLATE.DIPROM_1.status_code:
        return <Diprom1 {...menuProps} />;
      case FRONT_TEMPLATE.DIPROM_2.status_code:
        return <Diprom2 {...menuProps} />;
      case FRONT_TEMPLATE.DIPROM_3.status_code:
        return <Diprom3 {...menuProps} />;
      case FRONT_TEMPLATE.DIPROM_4.status_code:
        return <Diprom4 {...menuProps} />;
      default:
        return <DefaultMenu {...menuProps} />;
    }
  };

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{information?.name || 'E-Manufac'} - E-Manufac</title>
      </Helmet>
      <div className="min-h-screen ">
        <ContentNavbar isShowMobileNav={false} />
        {renderMenu()}

        <div className="w-full">
          <MainFooter />
          <div className="mx-12 text-sm text-gray-500">
            Healthcheck {healthcheckOk ? 'OK' : "Socket.IO doesn't detect."}
          </div>
        </div>
      </div>
    </div>
  );
}
