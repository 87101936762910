/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { gql } from 'graphql-request';
import { useHistory, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Button, Card, CardContent } from '@mui/material';
import _ from 'lodash';
import dayjs from 'dayjs';

import * as CONSTANT from '../../utils/constants';

import {
  ViewTitle,
  Loading,
  Error,
  BackButton,
  MultiPayrollForm,
  DesktopDatepicker,
} from '../../components';
import * as actions from '../../redux/actions';
import graphqlClient from '../../configs/graphqlClient';
import { currencyFormatter } from '../../utils/functions';

/**
 * @function CreatePersonalPayrollRecord
 * @description Display a collections or a list of CreatePersonalPayrollRecord from database
 */

export default function CreatePersonalPayrollRecord({ title, subtitle }) {
  const dispatch = useDispatch();
  const employee = useSelector((state) => state.employee);
  const information = useSelector((state) => state.information);
  const history = useHistory();
  const params = useParams();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [selectedDate, setSelectedDate] = useState('');

  const {
    setting: { pams: pamsSetting },
  } = information;

  const {
    control,
    watch,
    formState: { errors },
    setValue,
    handleSubmit,
  } = useForm({});

  useEffect(() => {
    // ส่งไปภายใต้ Object arr แล้วให้ BE วนสร้างทีเดียวหลาย ๆ รายการ
    setValue('arr', [
      {
        enable: true,
        employee,
        daily_attribute: {
          start_time: dayjs().hour(8).minute(0).toDate(),
          end_time: dayjs().hour(17).minute(0).toDate(),
        },
      },
    ]);

    return () => {};
  }, [employee]);

  const query = gql`
    query FindCreatePersonalPayrollRecord($input: EmployeeInput) {
      findOneEmployee(input: $input) {
        _id
        firstname
        lastname
        phone_number
        other_departments {
          name
        }
        department {
          name
        }
        earnings {
          amount
          is_pay_monthy
        }
        payroll_total
      }
    }
  `;

  const queryDataFromServer = async () => {
    const queryResult = await graphqlClient.request(query, {
      input: {
        id: params.id,
      },
    });
    const employeeData = queryResult?.findOneEmployee;
    dispatch(actions.employeeStateOneSet(employeeData));
  };

  useEffect(() => {
    queryDataFromServer();
    return () => {};
  }, [page, size]);

  const onSubmit = async (data) => {
    try {
      const filteredPayload = _.filter(
        data?.arr,
        (eachPayroll) => eachPayroll?.enable === true,
      );
      const editedPayload = _.map(filteredPayload, (eachPayroll) => ({
        ...eachPayroll,
        date: dayjs(selectedDate).format('YYYY-MM-DDTHH:mm'),
        payroll_type: employee?.earnings?.is_pay_monthy
          ? CONSTANT.PAYROLL_TYPE.MONTHY.status_code
          : CONSTANT.PAYROLL_TYPE.DAILY.status_code,
      }));
      console.log('Edtied Payroll Payload', editedPayload);
      await dispatch(actions.payrollCreate({ arr: editedPayload }));
      alert('สร้างการจ่ายค่าตอบแทนสำเร็จ');
      history.goBack();
    } catch (error) {
      alert(`การสร้างการจ่ายค่าตอบแทนมีปัญห่า ${error?.message}`);
      console.error('Error Create Payroll', error);
    }
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (employee.isLoading) {
    return <Loading />;
  }
  if (!employee.isLoading && employee.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div className="my-2">
          <BackButton />
        </div>
        <div className="my-2">
          <Card>
            <CardContent>
              <div className="flex gap-2">
                <div className="mx-2">
                  <DesktopDatepicker
                    value={selectedDate}
                    setValue={setSelectedDate}
                    label="วันที่ต้องการจ่าย"
                    inputFormat="DD MMM BBBB"
                  />
                </div>
                <div className="mx-4">
                  {' '}
                  <div className="flex gap-2">
                    <div className="font-semibold font-display">พนักงาน</div>
                    <div>
                      {employee?.firstname} {employee?.lastname}{' '}
                    </div>
                  </div>
                  <div className="flex gap-2">
                    <div className="font-semibold font-display">แผนก</div>
                    <div>{employee?.department?.name}</div>
                  </div>
                  <div className="flex gap-2">
                    <div className="font-semibold font-display">
                      ประเภทการจ่ายเงิน
                    </div>
                    <div>
                      {employee?.earnings?.is_pay_monthy
                        ? 'รายเดือน'
                        : 'รายวัน'}{' '}
                      {currencyFormatter.format(employee?.earnings?.amount)} บาท
                    </div>
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
        <div className="my-2">
          <form onSubmit={handleSubmit(onSubmit)}>
            <MultiPayrollForm
              control={control}
              errors={errors}
              isDaily={!employee?.earnings?.is_pay_monthy}
              setValue={setValue}
              watch={watch}
              hourPerDay={pamsSetting?.daily_payroll?.working_hour}
              breakPerDay={pamsSetting?.daily_payroll?.break_hour}
              brushOfThreshold={pamsSetting?.daily_payroll?.brushoff_threshold}
              lateThreshold={pamsSetting?.daily_payroll?.late_threshold}
            />
            <div className=" my-2 flex justify-end">
              <Button variant="contained" type="submit">
                บันทึก
              </Button>
            </div>
          </form>
        </div>
      </div>
    );
  }
  return <Error />;
}

CreatePersonalPayrollRecord.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

CreatePersonalPayrollRecord.defaultProps = {
  title: '',
  subtitle: '',
};
