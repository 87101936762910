import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { gql } from 'graphql-request';
import { useHistory, useParams } from 'react-router-dom';
import { Card, CardContent, Button } from '@mui/material';
import { useForm } from 'react-hook-form';
import _ from 'lodash';

import {
  ViewTitle,
  Loading,
  Error,
  ReturnMaterialForm,
  BackButton,
} from '../../../components';
import * as actions from '../../../redux/actions';
import graphqlClient from '../../../configs/graphqlClient';
import { MMS } from '../../../utils/functions';

/**
 * @function ReturnMaterial
 * @description Display a collections or a list of ReturnMaterial from database
 */

export default function ReturnMaterial({ title, subtitle }) {
  const dispatch = useDispatch();
  const currentProcess = useSelector((state) => state.process);
  const history = useHistory();
  const params = useParams();

  const material = useSelector((state) => state.material);
  const materialTransactionType = useSelector(
    (state) => state.materialTransactionType,
  );
  const me = useSelector((state) => state.me);

  const {
    handleReturnMaterial,
    MaterialAnalyzer: { manufacMaterialAnalyzedArrayAnalzer },
  } = MMS;

  const query = gql`
    query FindReturnMaterialElement(
      $processInput: ProcessInput
      $materialInput: MaterialInput
    ) {
      findOneProcess(input: $processInput) {
        _id
        current {
          step {
            _id
            process {
              _id
            }
          }
        }
        manufacturing_materials {
          _id
          material {
            _id
            type_code
            name
            unit
            use_unit_conversion
            conversion_from {
              unit_a
              unit_b
              conversion_factor
            }
            conversion_to {
              unit_b
              unit_a
              conversion_factor
            }
            unit_input {
              _id
              short_sign
              name
            }
            unit_output {
              _id
              short_sign
              name
            }
          }
          begin
          quantity
          amount
          transaction_dir
          transaction_type
        }
      }
      findMaterials(input: $materialInput) {
        rows {
          _id
          type_code
          name
          unit
          use_unit_conversion
          conversion_from {
            unit_a
            unit_b
            conversion_factor
          }
          conversion_to {
            unit_b
            unit_a
            conversion_factor
          }
          unit_input {
            _id
            short_sign
            name
          }
          unit_output {
            _id
            short_sign
            name
          }
          stocklots {
            _id
            amount
            issue_date
            lot_number
            quantity
            material {
              _id
              name
              type_code
              use_unit_conversion
              unit
              conversion_from {
                unit_a
                unit_b
                conversion_factor
              }
              conversion_to {
                unit_b
                unit_a
                conversion_factor
              }
              unit_input {
                _id
                short_sign
                name
              }
              unit_output {
                _id
                short_sign
                name
              }
            }
          }
        }
        total
      }
    }
  `;

  const fetchMaterialTxnType = async () => {
    try {
      await dispatch(actions.materialTransactionTypeAll({}));
    } catch (error) {
      console.error('Error on Fetch Material Txn Type', error);
    }
  };

  const queryDataFromServer = async () => {
    try {
      const queryResult = await graphqlClient.request(query, {
        processInput: {
          id: params.id,
          fetchMaterial: true,
          fetchInsideProcess: true,
        },
        materialInput: {
          fetchStockLot: true,
          status: 'IN_STOCK',
        },
      });
      const processData = queryResult?.findOneProcess;
      const materialData = queryResult?.findMaterials;
      dispatch(actions.processStateOneSet(processData));
      dispatch(actions.materialStateSet(materialData));
    } catch (error) {
      console.error('Error on Query', error);
    }
  };

  useEffect(() => {
    queryDataFromServer();
    fetchMaterialTxnType();
    return () => {};
  }, [params]);

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      material: _.map(currentProcess?.step?.materials, (_mat) => ({
        ..._mat,
        return: 0,
      })),
    },
  });

  useEffect(() => {
    if (currentProcess) {
      const manufacMatAnalyze = _.filter(
        manufacMaterialAnalyzedArrayAnalzer(
          currentProcess?.manufacturing_materials,
        ),
        (_material) => _material?.materialInfo?._id !== undefined,
      );

      setValue(
        'material',
        _.map(manufacMatAnalyze, (_mat) => ({
          ..._mat,
          return: 0,
        })),
      );
    }

    return () => {};
  }, [currentProcess]);

  const onSubmit = async (data) => {
    try {
      await handleReturnMaterial({
        dispatch,
        data,
        step: currentProcess?.current?.step?._id,
        me,
        currentProcess,
        successHandle: () => {},
        handleClose: () => {},
      });
      reset({});
      history.goBack();
    } catch (error) {
      alert(`ไม่สามารถคืนวัตถุดิบได้ ${error?.message}`);
    }
  };

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  if (currentProcess.isLoading) {
    return <Loading />;
  }

  if (!currentProcess.isLoading && currentProcess.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div>
          <BackButton />
        </div>
        <div className="my-2">
          <Card>
            <CardContent>
              <form onSubmit={handleSubmit(onSubmit)}>
                <ReturnMaterialForm
                  control={control}
                  errors={errors}
                  material={material}
                  materialTransactionType={materialTransactionType}
                />
                <div className="flex gap-2 justify-end my-2">
                  <Button type="submit" color="primary" variant="contained">
                    ยืนยัน
                  </Button>
                </div>
              </form>
            </CardContent>
          </Card>
        </div>
      </div>
    );
  }
  return <Error />;
}

ReturnMaterial.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

ReturnMaterial.defaultProps = {
  title: '',
  subtitle: '',
};
