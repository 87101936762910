import React from 'react';
import PropTypes from 'prop-types';

const SettingDetailMOTemplate = (props) => {
  return <div>SettingDetailMOTemplate</div>;
};

SettingDetailMOTemplate.propTypes = {};

export default SettingDetailMOTemplate;
