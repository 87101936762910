import api from '../../../configs/api';
import {
  PRODUCT_STOCK_LOT_ALL,
  PRODUCT_STOCK_LOT_DECREASE,
  PRODUCT_STOCK_LOT_DEL,
  PRODUCT_STOCK_LOT_ERROR,
  PRODUCT_STOCK_LOT_GET,
  PRODUCT_STOCK_LOT_LOADING,
  PRODUCT_STOCK_LOT_POST,
  PRODUCT_STOCK_LOT_PUT,
} from '../types';

export const productStockLotCreate = (payload) => async (dispatch) => {
  try {
    dispatch({ type: PRODUCT_STOCK_LOT_LOADING });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/product-stock-lot`,
      {
        ...payload,
      },
    );
    dispatch({ type: PRODUCT_STOCK_LOT_POST, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: PRODUCT_STOCK_LOT_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};

export const productStockLotDecrease = (payload) => async (dispatch) => {
  try {
    dispatch({ type: PRODUCT_STOCK_LOT_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/product-stock-lot`,
      {
        ...payload,
      },
    );
    dispatch({ type: PRODUCT_STOCK_LOT_DECREASE, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: PRODUCT_STOCK_LOT_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};

export const productStockLotAll = ({
  productName = '',
  size = '',
  page = 1,
  stockStatus = 'IN_STOCK',
  warehouse = '',
  selectedProcess = '',
}) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/product-stock-lot?productName=${productName}&size=${size}&page=${page}&status=${stockStatus}&warehouse=${warehouse}&process=${selectedProcess}`,
    );
    if (status === 200) {
      dispatch({ type: PRODUCT_STOCK_LOT_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: PRODUCT_STOCK_LOT_ERROR });
    throw new Error(error);
  }
};

export const productStockLotGet = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/product-stock-lot/${id}`,
    );
    if (status === 200) {
      dispatch({ type: PRODUCT_STOCK_LOT_GET, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: PRODUCT_STOCK_LOT_ERROR });
    throw new Error(error);
  }
};

export const productStockLotStateSet = (data) => async (dispatch) => {
  dispatch({ type: PRODUCT_STOCK_LOT_ALL, payload: data });
};

export const productStockLotPut = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: PRODUCT_STOCK_LOT_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/product-stock-lot/${id}`,
      payload,
    );
    dispatch({ type: PRODUCT_STOCK_LOT_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: PRODUCT_STOCK_LOT_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};

export const productStockLotUpdateRunningNumber = (id, payload) => async (
  dispatch,
) => {
  try {
    dispatch({ type: PRODUCT_STOCK_LOT_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/product-stock-lot/generate-number/${id}`,
      payload,
    );
    dispatch({ type: PRODUCT_STOCK_LOT_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: PRODUCT_STOCK_LOT_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};

export const productStockLotDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: PRODUCT_STOCK_LOT_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/product-stock-lot/${id}`,
    );
    dispatch({ type: PRODUCT_STOCK_LOT_DEL, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: PRODUCT_STOCK_LOT_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};
