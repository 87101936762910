import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import { NotAuthorized, NotFound } from '../components/Error';
import { MainFooter } from '../components/Footers';
import { Loading } from '../components/Loading';
import { HomeNavbar } from '../components/Navbars';
import { MainSidebar } from '../components/Sidebar';
import * as actions from '../redux/actions';
import accessRight from '../utils/functions/accessRight';
import {
  CreateMachine,
  DetailMachine,
  EditMachine,
  Machines,
} from '../views/MAMS';
// Machinery Management
export const MAMS = () => {
  const module = 'MAMS';
  const prefix = '/mams';
  const name = 'เครื่องจักร / รางการผลิต';
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const me = useSelector((state) => state.me);
  const availableModule = useSelector((state) => state.availableModule);
  const information = useSelector((state) => state.information);

  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const handleOnMobileNavOpen = () => {
    setMobileNavOpen(true);
  };

  const handleOnMobileNavClose = () => {
    setMobileNavOpen(false);
  };

  useEffect(() => {
    console.log('Layout: MAMS');
    dispatch(actions.meGet());
    dispatch(actions.informationAll());
    dispatch(actions.availableModuleAll());
    return () => {};
  }, []);

  if (!me?.userData) {
    return <Loading />;
  }

  if (accessRight(me, module, 0, availableModule)) {
    return (
      <div className="min-h-screen">
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            {name || 'MAMS'} - {information?.name || 'E-Manufac'}
          </title>
        </Helmet>
        <MainSidebar
          onMobileClose={handleOnMobileNavClose}
          openMobile={isMobileNavOpen}
          me={me}
        />
        <HomeNavbar onMobileNavOpen={handleOnMobileNavOpen} />
        <div className="lg:ml-64  min-h-screen  pt-16 px-8 ">
          <div className="py-4">
            <Switch>
              <Redirect exact from={`${prefix}`} to={`${prefix}/machine`} />
              <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
              <Route exact path={`${prefix}/machine`}>
                <Machines title="เครื่องจักร/รางการผลิต" subtitle={name} />
              </Route>
              <Route exact path={`${prefix}/machine/create`}>
                <CreateMachine
                  title="สร้างเครื่องจักร / รางการผลิต"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/machine/edit/:id`}>
                <EditMachine
                  title="แก้ไขเครื่องจักร / รางการผลิต"
                  subtitle={name}
                />
              </Route>
              <Route exact path={`${prefix}/machine/view/:id`}>
                <DetailMachine
                  title="รายละเอียดเครื่องจักร / รางการผลิต"
                  subtitle={name}
                />
              </Route>
              <Route path="*">
                <NotFound />
              </Route>
            </Switch>
          </div>
        </div>
        <div className="lg:ml-64">
          <MainFooter />
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="min-h-screen">
        <MainSidebar
          onMobileClose={handleOnMobileNavClose}
          openMobile={isMobileNavOpen}
          me={me}
        />
        <HomeNavbar onMobileNavOpen={handleOnMobileNavOpen} />
        <NotAuthorized />
        <div className="lg:ml-64">
          <MainFooter />
        </div>
      </div>
    </div>
  );
};

export default MAMS;
